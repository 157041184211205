import { DatePipe } from '@angular/common';
import { AfterContentInit, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { CommonService } from 'app/jollyjupiter/service/common.service';
@Component({
  selector: 'app-date-input',

  templateUrl: './date-input.component.html',
  styleUrl: './date-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true
    },
  ]
})
export class DateInputComponent implements ControlValueAccessor, OnInit, AfterContentInit, OnChanges{
  

  constructor(
    private datePipe: DatePipe, 
    private commonService: CommonService
  ){}


  @Input() id : string | number | null = null;
  @Input() label: string= "";
  @Input() placeholder: string= '';
  @Input() selectedDate: Date | string | null = null;
  @Input() minDate;
  @Input() maxDate;
  @Input() disabled;
  @Input() parent : string | null = null;
  @Input() info: string | null = null;

  @Input() error: boolean | null = false;
  

  


  @Output() dateChange: EventEmitter<Date | string > = new EventEmitter<Date | string >();

  onChange: (value:any) => {};
  onTouch = () => {};

  ngOnInit(): void {

  }


  ngAfterContentInit(): void {
 /*    const date = new Date(this.selectedDate)
    const newDate = formatInTimeZone(date, 'Europe/Paris', 'yyyy-MM-dd HH:mm:ss zzz', { locale: enGB })
 */
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedDate'] && changes['selectedDate'].currentValue) {
    /*   const date = new Date(this.selectedDate);
      const newDate = formatInTimeZone(date, 'Europe/Paris', 'yyyy-MM-dd HH:mm:ss zzz', { locale: enGB }); */
    }
  }


  writeValue(value: any): void {

    //console.log('dateInput:', value)

    if(!this.parent){
      this.selectedDate = value;
    }

    if(this.parent === 'booking'){
      if(value){
        this.selectedDate = value;
      }
    }

    if(this.parent === 'generalSettings'){

      if(value){
        //console.log('dateInput:', value)
        if(value.endsWith("Z")){
          this.selectedDate =  new Date(value)
        } else {
          this.selectedDate = new Date(value+'Z');
          //console.log('this.selectedDate', this.selectedDate)
        }
      } else {
        this.selectedDate = value;
      }

    }

  }

  registerOnChange(fn: any): void {
    //console.log('fn', fn)
   this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
   this.disabled = isDisabled;
  }

  resetValue(){

    if(!this.disabled){
      this.selectedDate = null;
      this.onChange(this.selectedDate);
      this.dateChange.emit(this.selectedDate);
      this.onTouch();
    }
  }


  formatDate(date:Date){
    return this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss');
  }

  onDateChange(event:any){
    //console.log('onDateChange',event)
    const date = event.value;
    //console.log('date', date)

    if(!this.disabled){
      this.selectedDate = date;

      this.onChange(date);
      this.dateChange.emit(date);
      this.onTouch();
    }
  
  }

  formatDateForInput(date:Date):string{
    return date.toISOString().slice(0,16);
  }




}

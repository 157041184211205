<div class="everleadMobileHeader">
    <div class="everleadMobileHeader__image">
        <img src="assets/NEW_EVERLEAD_ICONS/navigation/everlead_navigation_logo_wide.png">
    </div>
    <div class="everleadMobileHeader__settings" *ngIf="applicationInfoService.navigationExpandedMobile == false">
        <app-user-avatar component="menu" [mobile]="true"></app-user-avatar>
    </div>
    <div class="everleadMobileHeader__menu" (click)="expandMobileNavigation()" *ngIf="applicationInfoService.navigationExpandedMobile == false">
        <app-jj-icons [type]="'list'" [width]="32" [height]="32" color="primaryLight"></app-jj-icons>
    </div>
    <div class="everleadMobileHeader__menu" (click)="applicationInfoService.navigationExpandedMobile = false" 
        *ngIf="applicationInfoService.navigationExpandedMobile == true">
        <app-jj-icons [type]="'x'" [width]="32" [height]="32" color="primaryLight"></app-jj-icons>
    </div>
</div>
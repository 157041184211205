import { Component, ViewChild,ElementRef, OnInit, ViewChildren, QueryList, OnDestroy} from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { CustomDiagramComponent } from 'app/jollyjupiter/controls/custom/custom-diagram/custom-diagram.component';
import { SplitCamelcasePipe } from 'app/jollyjupiter/pipe/split-camelcase.pipe';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { ThemeService } from 'ng2-charts';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { DatePipe } from '@angular/common';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';

@Component({
  selector: 'app-leadscoring-detail',
  templateUrl: './leadscoring-detail.component.html',
  styleUrls: ['./leadscoring-detail.component.scss', './new-leadscoring-detail.component.scss']
})
export class LeadscoringDetailComponent implements OnInit, OnDestroy  {
  updateControlDataEventSubscription: Subscription = new Subscription();
  events = [];
  accountPotentialValues = [];
  selectedAccountPotentialValue = null;
  scoringParameters = [];
  scoringContentTypes = [];
  labelsContentDiagramm = ['Content'];
  labelsActivityDiagramm = ['Activity'];
  displayedColumns = ['type',  'score', 'url'];
  contactData = null;
  showSummary = true;
  tab= 1

  @ViewChild('contentDiagram') contentDiagram:CustomDiagramComponent;
  @ViewChild('activityDiagram') activityDiagram:CustomDiagramComponent;
  @ViewChildren('eventActivityDiagrams') eventActivityDiagrams!: QueryList<CustomDiagramComponent>;
  @ViewChildren('eventContentDiagrams') eventContentDiagrams!: QueryList<CustomDiagramComponent>;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('chart-leadindex')
  private chartRef: ElementRef;
  private distinctValueList = [];
  private distinctValueIdList = [];
  public chartReady = false;
  public activityData = {};
  public contentData = {};
  eventDiagramOptions= {};
  bgColorContent = '#5465FF';
  bgColorActivity = '#788BFF';

  leadScoreMainchart = { data:[], type:'bar' };
  leadScorechartId = 'leadScoreMainChart'

  apiEventsData:any[] = [];
  chartData:any[]=[];  
  featureId = null;
  featureNotFound = null;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private entityService: EntityService,
    private eventService: EventService,
    private splitCamelcasePipe: SplitCamelcasePipe,
    private axivasTranslateService: AxivasTranslateService,
    private paginatorIntl: MatPaginatorIntl,
    private datePipe: DatePipe,
    public packagefeatureService: PackagefeatureService
  ) {
  }

  ngOnInit() {
    this.featureId = Number(this.applicationInfoService.applicationSettings['featureId_leadscoring']);    
    if (this.packagefeatureService.selectedPackageHasFeature(this.featureId) == false) {
      this.featureNotFound = true;
    } else {
      this.featureNotFound = false;
    }   

    this.paginatorIntl.itemsPerPageLabel = this.axivasTranslateService.getTranslationTextForToken('General.Label.Paginator')
    this.updateControlDataEventSubscription = this.eventService.updateControlDataEvent.subscribe(event => {
      if (event.target == 'leadscoring-detail') {
        this.getAccountPotentialValues();
        this.entityService.loadEntity([this.applicationInfoService.contactEntityId, this.applicationInfoService.currentContact.id])
        this.initData();
      }
    });

    if (this.applicationInfoService.currentContact == null) {
      return;
    }

    this.getAccountPotentialValues();

    Promise.all([
      this.getScoringParameters(),
      this.getScoringContentTypes(),
      this.getContactValues(),
      this.getEvents(),      
    ])
    .then(() => {
      //this.initData(), 
      this.createDataForChart()
    })
    .catch(() => { 
      this.showSummary = false; 
      this.chartReady = true;
    });
  }

  ngOnDestroy() {
    if (this.updateControlDataEventSubscription) { this.updateControlDataEventSubscription.unsubscribe(); }
  }

  initData(){
    for (let [key, element] of Object.entries( this.dataSource.data)) {
    let type = element.type.charAt(0).toUpperCase() +  element.type.substr(1);
    let caption = this.axivasTranslateService.getTranslationTextForToken("Leadscoring.Event.Type." + type);
    element["caption"] = caption ;
    }

    this.loadDiagram();
  }

  loadDiagram(){
    this.chartReady= true;
    //console.log('chart Ready', this.chartReady)
   /*  this.loadActivityDiagram();
    this.loadContentDiagram(); */
  }
  
  // * API Scoring Parameters

  getScoringParameters(): Promise<any> {
    return new Promise((resolve, reject ) => {
      this.externaldatasourceService.executeExternalDataSource(338, [])
      .then(getScoringParametersResult => {
        this.scoringParameters = getScoringParametersResult;
        resolve(null)
      }).catch(()=>reject(null));
    });
  }

  // * API scoring Content types 

  getScoringContentTypes(){
    return new Promise((resolve, reject ) => {
      this.externaldatasourceService.executeExternalDataSource(391, [])
      .then(getScoringContentTypesResult => {
        this.scoringContentTypes = getScoringContentTypesResult;
        resolve(null)
      }).catch(()=>reject(null));
    });
  }


  // * TRACKING DATA SCORES FOR ACTIVITY AND CONTENT 
  getScoreValues(element){
    //console.log('element', element)
    let activityValue = 0;
    let contentValue = 0;

    if (!this.checkDistinct(element)){
      // ! activity 
      let activityType ="activity_score_" + this.splitCamelcasePipe.transform(element.type, "_").toLowerCase();

      // ! Get Scores
      let contentScore = this.getContentScore(element.type)
      let activityScore = this.getActivityScore(activityType);

      // ! values 
      activityValue = (activityScore) ? activityScore.floatValue : 0;
      contentValue = 0;
      
      if(contentScore) {
        contentValue  = contentScore.points;
        if(element.type == "pageView") {

          this.getSubUrls().forEach(element => {
            let targetUrl =  this.getBaseUrl() + element.subUrl;
            if(targetUrl == element.url) {
              contentValue += element.points;
            }
          });
        }
      }

      const dataForChart ={ 
      type:'bar', 
      data: [ 
        { name:'LeadScoringDetail.Label.Content', value: contentValue.toFixed(1) ,color: this.bgColorContent },
        { name:'LeadScoringDetail.Label.Activity', value: activityValue.toFixed(1) , color: this.bgColorActivity }
      ],
    }

    return dataForChart
      
    } else {
      return {
        type:'bar',
        data: [ 
          { name:'LeadScoringDetail.Label.Content', value: contentValue +'%'},
          { name:'LeadScoringDetail.Label.Activity', value: activityValue + '%'}
        ],
        }
      }
    
  }


  // ! NOT IN USE
/*   getContentScoreValue(element) {
    if (!this.checkDistinct(element)) {
      let contentScore = this.getContentScore(element.type);
      let value = 0;
      if(contentScore) {
        value  = contentScore.points;
        if(element.type == "pageView") {

          this.getSubUrls().forEach(element => {
            let targetUrl =  this.getBaseUrl() + element.subUrl;
            if(targetUrl == element.url) {
              value += element.points;
            }
          });
        }
      }
      return this.wrapDataset(value, this.bgColorContent);
    } else {
      return this.wrapDataset(0, this.bgColorContent);
    }
  }
 */

  // ! NOT IN USE
/*   getActivityScoreValue(element){

    if (!this.checkDistinct(element)) {
      let type = "activity_score_" + this.splitCamelcasePipe.transform(element.type, "_").toLowerCase();
      let activityScore = this.getActivityScore(type);
      let value = (activityScore) ? activityScore.floatValue : 0;

      return this.wrapDataset(value, this.bgColorActivity);
    } else {
      return this.wrapDataset(0, this.bgColorActivity);
    }

  } */

  checkDistinct(element): boolean {
    let returnValue = true;
    var d = new Date(element.timestampDate)
    const eventDistinctString = element.type.concat(element.url, d.getDate(), d.getMonth(), d.getFullYear());
    if (this.commonService.checkIfItemIsInArray(this.distinctValueList, eventDistinctString)) {
      if (this.commonService.checkIfItemIsInArray(this.distinctValueIdList, element.uniqueId)) {
        returnValue = false;
      }
    } else {
      this.distinctValueList.push(eventDistinctString);
      this.distinctValueIdList.push(element.uniqueId);
      returnValue = false;
    }
    return returnValue;
  }





  getContentScore(type){
     return this.scoringContentTypes.find( record => record.type  === type);
  }

  getActivityScore(type){
    return this.scoringParameters.find( record => record.name === type);
  }

  getBaseUrl(){
    let element = this.scoringParameters.find( record => record.name === "base_url");
    return (element) ? element.textValue : "" ;
  }

  getSubUrls(){
    let elements =  this.scoringContentTypes.filter( record => record.subUrl  != null);
    return (elements)? elements : [];
  }

  getPercentage(type): string {
    let returnValue = '';
    this.scoringParameters.forEach(param => {
      if (param.name === type) {
        returnValue = (param.floatValue * 100) + '%';
      }
    });
    return returnValue;
  }

  getAccountPotentialValue() {
    if (this.selectedAccountPotentialValue != null) {
      return (this.selectedAccountPotentialValue.value * 100);
    }
  }

  getAccountPotentialValues() {
    this.externaldatasourceService.executeExternalDataSource(400, []).then(result => {
      this.accountPotentialValues = result;
      //console.log('potentialValues', this.accountPotentialValues)
      this.accountPotentialValues.forEach(value => {
        if (value.id == this.applicationInfoService?.currentContact?.leadPotentialId) {
          this.selectedAccountPotentialValue = value;
        }
      });
    });
  }


  // * DATA FOR MAIN CHART
  getContactValues() {
    return new Promise((resolve, reject ) => {
      this.externaldatasourceService.executeExternalDataSource(360, [])
      .then(getContactValuesResult => {
        //console.log('contactValuesResult',getContactValuesResult)
        this.contactData = getContactValuesResult;
        this.contactData.activityPoints = this.contactData.activityPoints.toFixed(1);
        this.contactData.contentPoints = this.contactData.contentPoints.toFixed(1);

        // ! new echart data
        const content = { name: 'LeadScoringDetail.Label.Content', value: this.contactData.contentPoints, color: this.bgColorContent }
        const activity = { name: 'LeadScoringDetail.Label.Activity', value: this.contactData.activityPoints, color:this.bgColorActivity}
        this.leadScoreMainchart.data = [ content, activity,]
   
        resolve(null)
      }).catch(()=>reject(null));
    });
  }

  getCalculatedValue(type) {
    let returnValue;
    let parameter = null;


    this.scoringParameters.forEach(param => {
      if (param.name === type) {
        parameter = param;
      }
    });

    if (parameter === null || this.contactData === null) {
      return 0;
    }
    switch (type) {
      case 'activity_score_weight':
        returnValue = (this.contactData.activityPoints * parameter.floatValue);
        break;
      case 'content_score_weight':
        returnValue = (this.contactData.contentPoints * parameter.floatValue);
        break;
    }
    if (this.commonService.isNullOrUndefined(returnValue)) { return null; }
    returnValue = returnValue.toFixed(1);
    return returnValue;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getEvents() {
    return new Promise((resolve, reject ) => {
    this.externaldatasourceService.executeExternalDataSource(335, [])
    .then(result => {
      //console.log('get events result Leadscoring', result)
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.apiEventsData = result;

   /*    if(result.length === 0){
        this.showSummary = false;
      } */

     resolve(null)
      }).catch(()=>reject(null));
    });


  }

  getContactFinalValue(floorValue = false) {
    let returnValue = this.applicationInfoService.entities.Item(this.applicationInfoService.contactEntityId).data.leadScoring;
    if (floorValue) {
      returnValue = Math.floor(returnValue);
    }
    if (this.commonService.isNullOrUndefined(returnValue)) { return null; }
    returnValue = returnValue.toFixed(1);
    return returnValue;
  }

  getContactFinalCalculatedValue(floorValue = false) {
    let returnValue = this.applicationInfoService.entities.Item(this.applicationInfoService.contactEntityId).data.leadScoringCalculated;
    if (floorValue) {
      returnValue = Math.floor(returnValue);
    }
    if (this.commonService.isNullOrUndefined(returnValue)) { return null; }
    returnValue = returnValue.toFixed(1);
    return returnValue;
  }

  showPotentialPopup() {
    this.eventService.showJjPopup('Lead-Potential', 'leadscoringdetailpotential', 50);
  }



  // * DATA For charts LINE 

  createDataForChart(){    
   this.chartReady = true;
   
   if(this.apiEventsData.length === 0 ){
      //this.chartReady = false;      
      return

    } else {

      this.chartReady = true;


      for (let [key, element] of Object.entries( this.apiEventsData)) {
        let type = element?.type?.charAt(0).toUpperCase() +  element?.type?.substr(1);
        let caption = this.axivasTranslateService.getTranslationTextForToken("Leadscoring.Event.Type." + type);
        element["caption"] = caption ;
        }
  
        const sortDate = this.apiEventsData.sort((a,b) => new Date(a.timestampDate).getTime() - new Date(b.timestampDate).getTime());
        let totalsum = 0
  
        const datawithPercentage= sortDate.map((item) =>{
          // ! content block
          const content =  this.getContentValueWithoutPercentage(item);
          const contentPercentageValue = this.getPercentageForLineChart("content_score_weight",content);
  
          // ! activity block
          const activity = this.getActivityValueWithoutPercentage(item);
          const activityPercentageValue =  this.getPercentageForLineChart("activity_score_weight", activity)
  
          // ! sum
          const sum = Number(contentPercentageValue) + Number(activityPercentageValue);
  
          return{
            ...item,
            timestampDate: this.datePipe.transform(item.timestampDate, 'dd-MM-yyyy HH:mm:ss'),
            groupScore: {content: content, activity: activity},
            value:sum,
            icon:'assets/images/svg/warnings/warning.svg'
          }
        });
  
        this.chartData = datawithPercentage
    }
  }


  getContentValueWithoutPercentage(item) {

    if (!this.checkDistinct(item)) {
      let contentScore = this.getContentScore(item.type);
      let value = 0;
      if(contentScore) {
        value  = contentScore.points;
        if(item.type == "pageView") {

          this.getSubUrls().forEach(element => {
            let targetUrl =  this.getBaseUrl() + element.subUrl;
            if(targetUrl == item.url) {
              value += element.points;
            }
          });
        }
      }
      return value;
    } else {
      return 0;
    }
  }

  getActivityValueWithoutPercentage(element){

    if (!this.checkDistinct(element)) {
      let type = "activity_score_" + this.splitCamelcasePipe.transform(element.type, "_").toLowerCase();
      let activityScore = this.getActivityScore(type);
      let value = (activityScore) ? activityScore.floatValue : 0;

      return value;
    } else {
      return 0
    }

  }

  getPercentageForLineChart(type, value){
    let returnValue;
    let parameter = null;

    this.scoringParameters.forEach(param => {
      if (param.name === type) {
        parameter = param;
      }
    });

    if (parameter === null || this.contactData === null) {
      return 0;
    }

    returnValue = (value * parameter.floatValue);

    if (this.commonService.isNullOrUndefined(returnValue)) { return null; }
    returnValue = returnValue.toFixed(1);
    return returnValue;

  }


}

import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';

@Component({
  selector: 'app-quicksearchitem',
  templateUrl: './quicksearchitem.component.html',
  styleUrl: './quicksearchitem.component.scss'
})

export class QuicksearchitemComponent implements OnInit {
  @Input() itemType = 0;  // 0 account 1 contact 2 document
  @Input() item = null;
  @Input() displayText = '';
  @Input() accountText = null;
  @Input() isOdd = false;
  
  constructor(
    private methodService: MethodService,
    private eventService: EventService,
    public applicationInfoService: ApplicationInfoService,
    private projectService: ProjectService
  ) {}

  ngOnInit(): void {
    
  }

  loadAccount(account: any) {
    this.methodService.launchInternalMethod(InternalMethodType.ShowAccountData, [account.id])
    .then(() => {
      this.eventService.updateQuestionaireDataEvent.emit();
      this.eventService.updateControlAttributes();
      this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
    })
    .catch(error => {
      console.error('QuickSearch - loadAccount', error);
    });
  }

  loadContact(data: any) {  
    this.applicationInfoService.currentTask = null;  
    this.projectService.getCurrentAccount(data['account.id']).then(() => {
      this.projectService.getCurrentContact(data.id).then(() => {
        this.eventService.updateQuestionaireDataEvent.emit();
        this.eventService.updateControlAttributes();
        this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['account', 'account']);
        this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);        
      })  
    })
    .catch(error => {
      console.error('QuickSearch - loadAccount', error);
    });
  }

  loadItem() {
    switch(this.itemType) {
      case 0:
        this.loadAccount(this.item);
        break;
      case 1:
        this.loadContact(this.item);
        break;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EntityService, ValueType } from 'app/jollyjupiter/service/entity.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { OpportunityService } from 'app/jollyjupiter/service/opportunity.service';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss']
})
export class OpportunityComponent implements OnInit {
  innerHTMLContent = '';
  innerHTMLContentEdit = '';
  innerHTML = null;
  innerHTMLEdit = null;
  createNew = true;
  controlIdNew = 247201;
  controlIdEdit = 247202;
  
  oldOpportunityEntity = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private domSanitizer: DomSanitizer,
    private methodService: MethodService,
    private externaldatasourceService: ExternaldatasourceService,
    private opportunityService: OpportunityService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item('99').data.id)) {
      this.oldOpportunityEntity = JSON.parse(JSON.stringify(this.applicationInfoService.entities.Item('99').data));      
      console.warn(this.oldOpportunityEntity);
    }    
    this.showOpportunityControl();
  }

  showOpportunityControl() {
    this.createNew = !this.applicationInfoService.miscSettings['opportunityEditExistingOpportunity'];
    this.innerHTMLContent = '<jj-container controluiid="'.concat(this.controlIdNew.toString(), '"> </jj-container>');
    this.innerHTMLContentEdit = '<jj-container controluiid="'.concat(this.controlIdEdit.toString(), '"> </jj-container>');
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
    this.innerHTMLEdit = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContentEdit);
  }

  applyOpportunity() {
    this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
  }

  canUseOpportunity() {
    // if (this.changeRequiresRandomBooking()) {
    //   return false;
    // }
    let returnValue = this.opportunityService.checkIfTempOpportunityIsValid()        
    return returnValue;
  }
  
  saveExistingOpportunity () {
    this.opportunityService.updateOpportunity(this.opportunityService.getTempOpportunity()).then(() => {
      this.methodService.launchInternalMethod(InternalMethodType.CloseJJPopup, []);
    });
  }

  changeRequiresRandomBooking() {
    let returnValue = false;
    if (this.oldOpportunityEntity != null) {
      if (this.oldOpportunityEntity.statusId != this.applicationInfoService.entities.Item('99').data.statusId) {
        return true;
      }
    }
    return returnValue;
  }
}

<div class="navigationItem" [tippy]="getMenuItemText(menuItem) | texttransform" 
    [tippyOptions]="{theme:'everleadNav', placement:'right' }" 
    [ngClass]="{ 
        dropDownMenu: menuItem.isExtendable, 
        fingerCursor: menuItem.clickType,
        navigationTopLevel: !menuItem.isChild && menuItem.clickType != 'loadNavigation',
        navigationChildLevel: menuItem.isChild,
        navigationParentLevel: !menuItem.isChild,
        navigationParentLevelBorder: navigationExpanded && !menuItem.isChild,
        navigationItem__isSelected: isItemSelected(),
        navigationSubmenu: menuItem.clickType == 'loadNavigation'
}" 
    (click)="jsonMenuItemClicked(menuItem)" *ngIf="jsonItemVisible(menuItem)">
    <div class="packageFeatureAvailable" *ngIf="packagefeatureService.navigationItemHasNonActiveFeature(menuItem)">
        <i class="fas fa-star packageFeatureAvailable__icon"></i>
    </div>

    <div class="navigationItem__inner" [ngClass]="{ 
        fingerCursor: menuItem.clickType        
    }">
        <div class="navigationItem__hoverIcon">
            <div class="navigationItem__hoverIcon__inner" [ngClass]="{
                'd-flex': !menuItem.isChild && navigationExpanded && isSublevel
            }">
                <svg width="8" height="32" viewBox="0 0 8 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0C4.41828 0 8 3.58172 8 8V24C8 28.4183 4.41828 32 0 32V0Z" fill="#788BFF"/></svg>
            </div>
        </div>
        <div class="navigationItem__iconContainer">
            <app-jj-icons [type]="menuItem.icon" [width]="32" [height]="32" color="primaryLight" *ngIf="menuItem.iconType == 'jjicon'"></app-jj-icons>
            <svg-icon [src]="getJsonImage(menuItem)" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }" [ngClass]="{ navigationStroke: menuItem.stroke == true }"
            *ngIf="menuItem.iconType == 'svg'">
        </svg-icon>
            
        </div>
        <label class="navigationItem__inner__label fingerCursor" *ngIf="navigationExpanded" [ngClass]="{ 
            'navigationItem__inner__label__child': menuItem.isChild
        }">            
            {{ getMenuItemText(menuItem) | texttransform }}            
        </label>    
        
        <div *ngIf="packagefeatureService.navigationItemHasNonActiveFeature(menuItem) && navigationExpanded" class="playIcon fingerCursor">
            <app-jj-icons type="lock-simple" [width]="16" [height]="16" color="primaryLight"></app-jj-icons>
        </div>

        <div *ngIf="menuItem.isExtendable && navigationExpanded && !isSublevel" class="playIcon fingerCursor">
            <app-jj-icons type="caret-right" [width]="32" [height]="32" color="primaryLight"></app-jj-icons>
        </div>
    </div>
</div>

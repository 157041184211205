<div class="followup-list-component">
    <div class="row" *ngIf="listType !== 'personal'">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div class="container">
                <div class="filter">
                    <div class="filterWrapper">
                        <app-jj-icons type="magnifying-glass" [width]="20" [height]="20"></app-jj-icons>
                        <input [placeholder]="'FollowUp.Label.SearchByName' | texttransform" [(ngModel)]="searchString"
                        (ngModelChange)="inputChange($event)" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [ngClass]=" listType != 'personal' ? 'followup-list-component__table' : 'followup-list-component__personalTable'">
        <table mat-table #table [dataSource]="dataSource" matSort (matSortChange)="sortOnChange($event)"
            matSortActive="user_name" matSortDirection="asc" [@.disabled]="true" class=followup-table>
            <ng-container matColumnDef="user_name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="followup-sort">Name</th>
                <td mat-cell *matCellDef="let element">{{element.user_name}}</td>
            </ng-container>
            <ng-container matColumnDef="pool_name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="followup-sort">Pool Name</th>
                <td mat-cell *matCellDef="let element">{{element.pool_name}}</td>
            </ng-container>
            <ng-container matColumnDef="overdue">
                <th mat-header-cell *matHeaderCellDef>{{'FollowUp.Label.Overdue' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">{{element.overdue }}</td>
            </ng-container>
            <ng-container matColumnDef="overdue_today">
                <th mat-header-cell *matHeaderCellDef>{{'FollowUp.Label.OverdueToday' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">{{element.overdue_today}}</td>
            </ng-container>
            <ng-container matColumnDef="current">
                <th mat-header-cell *matHeaderCellDef>{{'FollowUp.Label.Current' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">{{element.current}}</td>
            </ng-container>
            <ng-container matColumnDef="total_today">
                <th mat-header-cell *matHeaderCellDef>{{'FollowUp.Label.TotalToday' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">{{element.total_today}}</td>
            </ng-container>

            <ng-container matColumnDef="ft1">
                <th mat-header-cell *matHeaderCellDef><span [tippy]="'FollowUp.Label.FutureTasks' | texttransform "
                        [tippyOptions]="{placement:'bottom'}">{{'FollowUp.Label.Day' | texttransform}} 1</span></th>
                <td mat-cell *matCellDef="let element">{{element.ft1}}</td>
            </ng-container>

            <ng-container matColumnDef="ft2">
                <th mat-header-cell *matHeaderCellDef><span [tippy]="'FollowUp.Label.FutureTasks' | texttransform "
                        [tippyOptions]="{placement:'bottom'}">{{'FollowUp.Label.Day' | texttransform }} 2</span></th>
                <td mat-cell *matCellDef="let element">{{element.ft2}}</td>
            </ng-container>
            <ng-container matColumnDef="ft3">
                <th mat-header-cell *matHeaderCellDef><span [tippy]="'FollowUp.Label.FutureTasks' | texttransform "
                        [tippyOptions]="{placement:'bottom'}">{{'FollowUp.Label.Day' | texttransform }} 3</span></th>
                <td mat-cell *matCellDef="let element">{{element.ft3}}</td>
            </ng-container>

            <ng-container matColumnDef="ft4">
                <th mat-header-cell *matHeaderCellDef><span [tippy]="'FollowUp.Label.FutureTasks' | texttransform "
                        [tippyOptions]="{placement:'bottom'}">{{'FollowUp.Label.Day' | texttransform}} 4</span></th>
                <td mat-cell *matCellDef="let element">{{element.ft4}}</td>
            </ng-container>

            <ng-container matColumnDef="ft5">
                <th mat-header-cell *matHeaderCellDef><span [tippy]="'FollowUp.Label.FutureTasks' | texttransform "
                        [tippyOptions]="{placement:'bottom'}">{{'FollowUp.Label.Day' | texttransform }} 5</span></th>
                <td mat-cell *matCellDef="let element">{{element.ft5}}</td>
            </ng-container>


            <ng-container matColumnDef="ft6">
                <th mat-header-cell *matHeaderCellDef><span [tippy]="'FollowUp.Label.FutureTasks' | texttransform "
                        [tippyOptions]="{placement:'bottom'}">{{'FollowUp.Label.Day' | texttransform }} 6</span></th>
                <td mat-cell *matCellDef="let element">{{element.ft6}}</td>
            </ng-container>

            <ng-container matColumnDef="ft7">
                <th mat-header-cell *matHeaderCellDef><span [tippy]="'FollowUp.Label.FutureTasks' | texttransform "
                        [tippyOptions]="{placement:'bottom'}">{{'FollowUp.Label.Day' | texttransform }} 7</span></th>
                <td mat-cell *matCellDef="let element">{{element.ft7}}</td>
            </ng-container>

            <ng-container matColumnDef="ft8">
                <th mat-header-cell *matHeaderCellDef><span [tippy]="'FollowUp.Label.FutureTasks' | texttransform "
                        [tippyOptions]="{placement:'bottom'}">{{'FollowUp.Label.Day' | texttransform }} 8</span></th>
                <td mat-cell *matCellDef="let element">{{element.ft8}}</td>
            </ng-container>

            <ng-container matColumnDef="ft9">
                <th mat-header-cell *matHeaderCellDef><span [tippy]="'FollowUp.Label.FutureTasks' | texttransform "
                        [tippyOptions]="{placement:'bottom'}">{{'FollowUp.Label.Day' | texttransform }} 9</span></th>
                <td mat-cell *matCellDef="let element">{{element.ft9}}</td>
            </ng-container>

            <ng-container matColumnDef="ft10">
                <th mat-header-cell *matHeaderCellDef><span [tippy]="'FollowUp.Label.FutureTasks' | texttransform "
                        [tippyOptions]="{placement:'bottom'}">{{'FollowUp.Label.Day' | texttransform }} 10</span></th>
                <td mat-cell *matCellDef="let element">{{element.ft10}}</td>
            </ng-container>

            <ng-container matColumnDef="future">
                <th mat-header-cell *matHeaderCellDef><span [tippy]="'FollowUp.Label.FutureTasksAfter10' | texttransform "
                        [tippyOptions]="{placement:'bottom'}">{{ 'FollowUp.Label.Future' | texttransform }}</span></th>
                <td mat-cell *matCellDef="let element">{{element.future}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns"
                [ngStyle]="highlightRowId === element.follow_up_user_id && element.pool_name == hightlightPoolName && listType !== 'personal' ? styleRow() : { color: '#1c3a56' , backgroundColor: 'white'} "
                (mouseenter)="highlightRow(element)">
        </table>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { LicensingService } from '../licensing.service';

@Component({
  selector: 'app-licensing-userlist',
  templateUrl: './licensing-userlist.component.html',
  styleUrl: './licensing-userlist.component.scss'
})

export class LicensingUserlistComponent implements OnInit {
  innerHTMLContent = ` <jj-container controluiid="267184"> </jj-container>`;
  innerHTML = null;
  
  constructor(
    private domSanitizer: DomSanitizer,
    private eventService: EventService,
    private applicationInfoService: ApplicationInfoService,
    public licensingService: LicensingService
  ) {}

  ngOnInit(): void {
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLContent);
    this.getLicenses();
  }

  getLicenses() {
    this.licensingService.getLicenses().then(() => {});
  }

  addUser() {
    this.applicationInfoService.miscSettings['popupFullSize'] = true;
    if (this.licensingService.licenseInfo.freeLicenses == 0) {
      this.applicationInfoService.miscSettings['licensingAdduserMode'] = 1;
      this.eventService.showJjPopup('', 'licenseadduser', this.licensingService.mode1Size);  
    } else {
      this.applicationInfoService.miscSettings['licensingAdduserMode'] = 4;
      this.eventService.showJjPopup('Licensing.Label.AddUserLicenseHeader', 'licenseadduser', this.licensingService.mode4Size);  
    }
  }

  addLicense() {
    this.applicationInfoService.miscSettings['popupFullSize'] = true;
    this.applicationInfoService.miscSettings['licensingAdduserMode'] = 2;
    this.eventService.showJjPopup('Licensing.Label.AddUserLicenseHeader', 'licenseadduser', this.licensingService.mode2Size);  
  }
}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';

@Component({
  selector: 'app-navigationdesign',
  templateUrl: './navigationdesign.component.html',
  styleUrls: ['./navigationdesign.component.scss']
})
export class NavigationdesignComponent implements OnInit {
  navigationTable = {
    fields: []
  };
  roleId = 1;
  roles = [];
  settingId = null;
  controlTemplateList = null;
  controlHeaderTemplateList = null;

  tableLayoutListSelectedItem = 'navigationLayout';
  tableLayoutList = [];
  svgIcons = [];
  jjIcons = [];
  headerTemplates = [];
  controlTemplates = [];
  controlTemplatesSettings = [];
  headerTemplatesSettings = [];

  constructor(
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    private projectService: ProjectService,
    private eventService: EventService,
    private userService: UserService
  ) { }

  ngOnInit(): void {  
    this.controlTemplates = JSON.parse(this.applicationInfoService.applicationSettings['navigationEditorControlTemplates']);
    this.controlTemplatesSettings = JSON.parse(this.applicationInfoService.applicationSettings['navigationEditorControlTemplatesSettings']);
    this.headerTemplates = JSON.parse(this.applicationInfoService.applicationSettings['navigationEditorControlTemplatesHeaders']);
    this.headerTemplatesSettings = JSON.parse(this.applicationInfoService.applicationSettings['navigationEditorControlTemplatesSettingsHeaders']);
    this.tableLayoutList = JSON.parse(this.applicationInfoService.applicationSettings['navigationEditorSections']);
    this.svgIcons = JSON.parse(this.applicationInfoService.applicationSettings['navigationEditorSvgs']);
    this.jjIcons = this.applicationInfoService.applicationSettings['navigationEditorJJIcons'].split(';');
    this.commonService.sortArrayWithTranslationToken(this.controlTemplates, 'defaultName');
    this.commonService.sortArrayWithTranslationToken(this.controlTemplatesSettings, 'defaultName');
    this.commonService.sortArrayWithTranslationToken(this.headerTemplates, 'defaultName');
    this.commonService.sortArrayWithTranslationToken(this.headerTemplatesSettings, 'defaultName');
    this.commonService.sortArrayWithTranslationToken(this.svgIcons, 'iconName');
    this.controlTemplateList = this.controlTemplates;
    this.controlHeaderTemplateList = this.headerTemplates;
    this.roleId = this.applicationInfoService.currentUserRoleId;

    this.roles = this.applicationInfoService.securityRoles;
    this.getTableLayout();    
  }

  getTableLayout() {
    let compareValue = '';
    switch (this.tableLayoutListSelectedItem) {
      case 'navigationLayout':
        this.controlTemplateList = this.controlTemplates;
        this.controlHeaderTemplateList = this.headerTemplates;
        compareValue = this.tableLayoutListSelectedItem + this.roleId.toString();
        break;
      case 'navigationLayoutSettings':
        this.controlTemplateList = this.controlTemplatesSettings;
        this.controlHeaderTemplateList = this.headerTemplatesSettings;
        compareValue = this.tableLayoutListSelectedItem;
        break;
    }
    
    this.projectService.getApplicationSettings().then(applicationSettings => {      
      const applicationSetting = applicationSettings.find(setting => setting.setting == compareValue);
      if (applicationSetting) {
        this.settingId = applicationSetting.id;
        this.navigationTable = JSON.parse(applicationSetting.value);
      } else {
        this.navigationTable = { fields: [] };
        this.settingId = null;
      }
    });
  }

  moveInList(event: CdkDragDrop<string[]>, item: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  addControl(controlTemplate) {
    this.newField(controlTemplate);
  }

  newField(controlTemplate) {
    if (this.commonService.isNullOrUndefined(controlTemplate.name)) { controlTemplate.name = 'Control' }
    if (this.commonService.isNullOrUndefined(controlTemplate.clickType)) { controlTemplate.clickType = null; }
    if (this.commonService.isNullOrUndefined(controlTemplate.translationen)) { controlTemplate.translationen = ''; }
    if (this.commonService.isNullOrUndefined(controlTemplate.translationde)) { controlTemplate.translationde = ''; }
    if (this.commonService.isNullOrUndefined(controlTemplate.target)) { controlTemplate.target = null; }
    if (this.commonService.isNullOrUndefined(controlTemplate.iconType)) { controlTemplate.iconType = null; }
    if (this.commonService.isNullOrUndefined(controlTemplate.icon)) { controlTemplate.icon = null; }
    if (this.commonService.isNullOrUndefined(controlTemplate.isActive)) { controlTemplate.isActive = true; }
    if (this.commonService.isNullOrUndefined(controlTemplate.isVisible)) { controlTemplate.isVisible = true; }

    if (this.commonService.isNullOrUndefined(controlTemplate.blockedPoolIds)) { controlTemplate.blockedPoolIds = ''; }
    if (this.commonService.isNullOrUndefined(controlTemplate.blockedCampaignIds)) { controlTemplate.blockedCampaignIds = ''; }
    if (this.commonService.isNullOrUndefined(controlTemplate.requiredRights)) { controlTemplate.requiredRights = ''; }
    if (this.commonService.isNullOrUndefined(controlTemplate.requiredPoolIds)) { controlTemplate.requiredPoolIds = ''; }
    if (this.commonService.isNullOrUndefined(controlTemplate.blockedProjectIds)) { controlTemplate.blockedProjectIds = ''; }
    if (this.commonService.isNullOrUndefined(controlTemplate.requiredProjectIds)) { controlTemplate.requiredProjectIds = ''; }

    if (this.commonService.isNullOrUndefined(controlTemplate.requiresAccount)) { controlTemplate.requiresAccount = false; }
    if (this.commonService.isNullOrUndefined(controlTemplate.requiresContact)) { controlTemplate.requiresContact = false; }
    if (this.commonService.isNullOrUndefined(controlTemplate.developerOnly)) { controlTemplate.developerOnly = false; }
    if (this.commonService.isNullOrUndefined(controlTemplate.extended)) { controlTemplate.extended = false; }
    if (this.commonService.isNullOrUndefined(controlTemplate.adminSection)) { controlTemplate.adminSection = null; }
    controlTemplate.guid = this.commonService.createGuid();
    this.navigationTable.fields.push(JSON.parse(JSON.stringify(controlTemplate)));
  }

  deleteField(field) {
    this.commonService.removeItemFromArray(this.navigationTable.fields, field);
  }

  toNumber(value) {
    return Number(value);
  }

  saveNavigationLayout() {
    let externalDatasourceId = 674;
    let tableConfig = { 
      projectId: this.applicationInfoService.projectID, 
      setting: this.tableLayoutListSelectedItem,  
      value: ''     
    }

    if (this.tableLayoutListSelectedItem == 'navigationLayout') {
      tableConfig.setting =  this.tableLayoutListSelectedItem + this.roleId.toString();
    } else {
      tableConfig.projectId = null;
      externalDatasourceId = 673;
    }

    if (this.settingId) {
      externalDatasourceId = 673;
      tableConfig['id'] = this.settingId;
    }
    tableConfig.value = JSON.stringify(this.navigationTable);

    this.externaldatasourceService.executeExternalDataSource(externalDatasourceId, [
      this.commonService.getModifyArrayBody(tableConfig, [])
    ]).then(() => {
      this.eventService.customEvent.emit({ 
        id: 'editNavigationJson',
        value: this.navigationTable
      });
    });
  }

  showJsonMenuItem(menuItem) {
    let returnValue = true;
    if (!this.applicationInfoService.isDeveloper) {
      // Blocked
      if (this.commonService.checkJsonMenuVisibilityBlockedComponent(menuItem.blockedUserIds, this.applicationInfoService.userID) == false) { returnValue = false; }
      if (this.commonService.checkJsonMenuVisibilityBlockedComponent(menuItem.blockedPoolIds, this.applicationInfoService.poolId) == false) { returnValue = false; }
      if (this.commonService.checkJsonMenuVisibilityBlockedComponent(menuItem.blockedProjectIds, this.applicationInfoService.projectID) == false) { returnValue = false; }
      if (this.commonService.checkJsonMenuVisibilityBlockedComponent(menuItem.blockedCampaignIds, this.applicationInfoService.campaingnModeId) == false) { returnValue = false; }

      // Required
      if (this.commonService.checkJsonMenuVisibilityRequiredComponent(menuItem.requiredProjectIds, this.applicationInfoService.projectID) == false) { returnValue = false; }
      if (this.commonService.checkJsonMenuVisibilityRequiredComponent(menuItem.requiredPoolIds, this.applicationInfoService.poolId) == false) { returnValue = false; }
      if (this.commonService.checkJsonMenuVisibilityRequiredComponent(menuItem.requiredCampaignIds, this.applicationInfoService.campaingnModeId) == false) { returnValue = false; }
    }

    // Rights
    if (!this.commonService.isNullOrUndefined(menuItem.requiredRights) && menuItem.requiredRights != '') {
      const details = menuItem.requiredRights.split(';');
      details.forEach(detail => {
        if (!this.userService.hasPermission(detail)) { 
          returnValue = false; 
        }
      });
    }
    return returnValue;
  }

  getSvgPreview(svg) {
    return 'assets/'.concat(svg);
  }

  toggleSubelement(field, value) {
    field[value] = !field[value];
  }
}

<div class="leadPotentialList">
    <div *ngFor="let leadPotential of leadPotentials" class="potentialItem fingerCursor" (click)="selectItem(leadPotential)">
        <div class="potentialItem__row">
            <div class="potentialItem__colOne">
                <app-status [width]="25" [height]="25" [green]="leadPotential.id == applicationInfoService?.currentContact?.leadPotentialId"></app-status>
            </div>
            <div class="potentialItem__colTwo">{{ leadPotential.displayName | texttransform }}</div>
            <div class="potentialItem__colThree">
                <app-bar-leadscore [value]="leadPotential.value * 100"></app-bar-leadscore></div>
        </div>
    </div>
</div>

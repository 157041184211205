import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-licensing-deactivate-user',
  templateUrl: './licensing-deactivate-user.component.html',
  styleUrl: './licensing-deactivate-user.component.scss'
})
export class LicensingDeactivateUserComponent implements OnInit {
  userInfo = null;
  newUserId = null;
  projectUsers = [];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private externaldatasourceService: ExternaldatasourceService
  ) {}

  ngOnInit(): void {
    this.userInfo = this.applicationInfoService.miscSettings['deactivateSecurityUser'];
    this.projectUsers = this.applicationInfoService.projectUsers.toArray();
  }

  closePopup() {
    this.eventService.closeJjPopup();
  }

  deactivateUser() {
    this.externaldatasourceService.executeExternalDataSource(975, [this.userInfo._1354_userid, this.newUserId]).then(() => {
      this.closePopup();
    });
  }
}

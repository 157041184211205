import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IoTThingsGraph } from 'aws-sdk';

@Component({
  selector: 'app-counter-input',
  templateUrl: './counter-input.component.html',
  styleUrl: './counter-input.component.scss',
  providers:[
{
  provide:NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CounterInputComponent),
  multi: true,
}
  ]
})


export class CounterInputComponent implements ControlValueAccessor, OnInit{

  // ! example on parent <app-counter-input  [(ngModel)]="count" (valueChange)="exper()"></app-counter-input> 

  constructor(){}


  @Input() fire: boolean = true
  @Input() errorMessage : string | null = 'CounterInput.Label.OnlyNumbers';
  @Input() disabled: boolean | null;
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
  
  isTyping= false;
  count: number = 0;
  error: boolean = false;  
  onChange = (value:number) => {}
  onTouched = () => {};
 

  ngOnInit(): void {

  }


  toggleField(){
    this.isTyping = !this.isTyping;
  }

  writeValue(value: number): void {
    this.count = value;
  }

  registerOnChange(fn: any): void {
   this.onChange = fn
  }

  registerOnTouched(fn: any): void {
   this.onTouched=fn
  }

  setDisabledState?(isDisabled: boolean): void {
  }


  increment(){
    this.error =false;
    this.count++
    this.onChange(this.count)
    this.valueChange.emit(+this.count);
  }

  decrement(){
    this.error= false;
    if(this.count > 0){
      this.count--
    }
    this.onChange(this.count)
    this.valueChange.emit(+this.count);
  }

  handleInput(value){
    //console.log('value on change', value, typeof value)
    this.error= false;
    if(!value){
      this.count = 0;
      this.error=false;
      this.onChange(this.count);
      this.valueChange.emit(this.count);
      return
    }

    if(value.toString().match(/^\d+$/)){
      const  number = +value
      this.onChange(number)
      this.valueChange.emit(number);
    } else {
      this.error = true;
    }
  }


}

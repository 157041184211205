import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Subscription } from 'rxjs';
import { WizardMode, WizardService } from '../wizard/wizard.service';
import { DatarightsService } from './datarights.service';

@Component({
  selector: 'app-datarights',
  templateUrl: './datarights.component.html',
  styleUrls: ['./datarights.component.scss']
})
export class DatarightsComponent implements OnInit, OnDestroy {
  refreshDataRightGroupListEventSubscription: Subscription = new Subscription();
  wizardFinishedEventSubscription: Subscription = new Subscription();
  refreshDataRightFiltersEventSubscription: Subscription = new Subscription();
  dataRightGroups = [];
  users = [];
  availableFilters = [];
  expandAll = false;
  generalFilterString = '';
  generalFilterUser = null;
  generalFilterAccessRights = 'all';
  project = null;
  
  constructor(
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    public datarightsService: DatarightsService,
    private loaderService: LoaderService,
    private wizardService: WizardService,
    private axivasTranslateService: AxivasTranslateService
  ) { }

  ngOnInit(): void {
    this.getDataRightGroups();
    this.getUsers();
    this.getProjectInfo();
    this.getAvailableFilters();

    this.refreshDataRightFiltersEventSubscription = this.datarightsService.refreshDataRightFiltersEvent.subscribe(() => {
      this.getAvailableFilters();
    });

    this.wizardFinishedEventSubscription = this.wizardService.wizardFinishedEvent.subscribe(event => {
      this.getDataRightGroups();
    });

    this.refreshDataRightGroupListEventSubscription = this.datarightsService.refreshDataRightGroupListEvent.subscribe(() => {
      this.getDataRightGroups();
      this.getAvailableFilters();
    });
  }

  handle(event){
    console.log('event', event)
    const vari= this.generalFilterAccessRights
    console.log('this vari', vari)
  }

  ngOnDestroy(): void {
    if (this.refreshDataRightGroupListEventSubscription) { this.refreshDataRightGroupListEventSubscription.unsubscribe(); }
    if (this.wizardFinishedEventSubscription) { this.wizardFinishedEventSubscription.unsubscribe(); }
    if (this.refreshDataRightFiltersEventSubscription) { this.refreshDataRightFiltersEventSubscription.unsubscribe(); }    
  }

  getDataRightGroups() {
    this.externaldatasourceService.executeExternalDataSource(572, []).then(result => { 
      this.dataRightGroups = result; 
      if (this.datarightsService.selectedGroup) {
        const selectedGroup = this.dataRightGroups.find(group => group.id == this.datarightsService.selectedGroup.id);
        if (selectedGroup) {
          this.datarightsService.selectedGroup = selectedGroup;
        }  
      }
    });
  }

  getAvailableFilters() {
    this.externaldatasourceService.executeExternalDataSource(581, [4523])
    .then(getAvailableFiltersResult => { 
      this.availableFilters = getAvailableFiltersResult;
    })
  }

  createDataRightsGroup(parent = null) {
    this.loaderService.display(true);
    let parentId = null;
    let name = this.axivasTranslateService.getTranslationTextForToken('DataRights.Label.NewGroupName');
    if (parent) {
      parentId = parent.id;
      name = parent.defaultName.concat(
        ': ', 
        this.axivasTranslateService.getTranslationTextForToken('DataRights.Label.NewSubGroupName'),
        ' ',
        (parent.children.length + 1).toString()
      );
    }
    this.externaldatasourceService.executeExternalDataSource(573, [
      this.commonService.getModifyArrayBody(
        {
          projectId: this.applicationInfoService.projectID,
          defaultName: name,
          parentId: parentId,
          groupLevel: 1,
          isReadOnly: true
        }
        , []
      )
    ]).then(createDataRightsGroupResult => { 
      this.getDataRightGroups();
      this.loaderService.display(false);
      this.editDataRightGroup(createDataRightsGroupResult);
    }).catch(() => { this.loaderService.display(false); });
  }

  getDataRightGroupsForParent() {
    return this.dataRightGroups.filter(x => x.parentId == null)
  }

  getUsers() {
    this.externaldatasourceService.executeExternalDataSource(139, []).then(result => { this.users = result; })
  }

  editDataRightGroup(group) {    
    this.wizardService.showWizard(WizardMode.DataRightGroup, JSON.parse(JSON.stringify(group)), '80vw', '80vh')
  }

  getProjectInfo() {
    this.externaldatasourceService.executeExternalDataSource(24, [this.applicationInfoService.currentProject.id]).then(project => {
       this.project = project;
    });
   }
 
   saveProjectInfo(){
     this.externaldatasourceService.executeExternalDataSource(260, [
       this.commonService.getModifyArrayBody(this.project, [])
     ])
     .then(executeExternalDataSourceResult => {
       this.loaderService.display(false);
     })
     .catch(() => {
       this.loaderService.display(false);
     });
   }

   valueStringToBoolean(value){

    switch(value){
      case 'all':
        return null
      case 'readonly':
        return true
      case 'full':
        return false
        default:
          return
    }
   }
}

<div class="mailCampaign__list" *ngIf="page === 0">
    <div class="mailCampaign__list__header">
        <div class="mailCampaign__list__header__name"><app-filter type="tableHeader"
                placeholder="CampaignDashboard.Label.Name"
                (keyUp)="filterRows($event)"></app-filter>
        </div>
        <div class="mailCampaign__list__header__status"><app-dropdown type="sortHeaderLookUpTable" [value]="sortStatus"
                [options]="statusLookUpTable"
                (onClick)="sortStatus= $event; sortData()"></app-dropdown>
        </div>
        <div class="mailCampaign__list__header__date">{{ 'CampaignDashboard.Label.StartedAt' | texttransform }}</div>
        <div class="mailCampaign__list__header__progress">{{ 'CampaignDashboard.Label.Progress' | texttransform }}</div>
        <div class="mailCampaign__list__header__actions">{{ 'CampaignDashboard.Label.Actions' | texttransform }}</div>
    </div>
    <div class="mailCampaign__list__content">
        <div *ngFor="let item of filterData" class="mailCampaign__row">

            <div class="mailCampaign__cell">
                <div class="mailCampaign__cell__name">
                    <div class="mailCampaign__cell__name__name">
                        {{item.batchName | texttransform }}
                    </div>
                </div>
            </div>

            <div class="mailCampaign__cell">
                <div class="mailCampaign__cell__status">
                    <app-status [color]="getStatusColor(item.status, 'color')" [width]="12" [height]="12"
                        [label]="getStatus(item.status, 'label')" [noLabelColor]="true"></app-status>
                </div>
            </div>


            <div class="mailCampaign__cell">
                <div class="mailCampaign__cell__date">
                    <div class="mailCampaign__cell__date__name">
                        <span *ngIf="item.createdBy">{{item.createdBy}}</span> 
                        <span *ngIf="!item.createdBy"> ... </span>
                    </div>
                    <div *ngIf="item.startedAt">{{item.startedAt | date }}</div>
                </div>
            </div>

            <div class="mailCampaign__cell">
                <div class="mailCampaign__cell__progress">
                    <app-jj-icons type="user" color="secondary" [width]="20" [height]="20"></app-jj-icons>
                    <div class="mailCampaign__cell__progress__value"><span>
                        {{item?.wizardValueArray?.receivers ? item.wizardValueArray?.receivers : 0 }}</span>
                        {{'CampaignDashboard.Label.Recipients' | texttransform }}</div>
                </div>
            </div>

            <div class="mailCampaign__cell">
                <div class="mailCampaign__cell__progress">
                    <div class="mailCampaign__cell__progress__percent">
                        <app-jj-icons type="paper-plane-tilt" color="secondary" [width]="20" [height]="20"></app-jj-icons>
                        <span>{{getCampaignProgressPercentage(item?.wizardValueArray.receivers, item.countSent)}} %</span>
                    </div>
                    <div class="mailCampaign__cell__progress__value"><span>{{item.countSent ? item.countSent : 0 }}</span>
                        {{'CampaignDashboard.Label.Sentrate' | texttransform}}</div>
                </div>
            </div>

            <div class="mailCampaign__cell">
                <div class="mailCampaign__cell__progress">
                    <div class="mailCampaign__cell__progress__percent">
                        <app-jj-icons type="envelope-open" color="secondary" [width]="20" [height]="20"></app-jj-icons>
                        <span>{{getCampaignProgressPercentage(item.countSent, item.countOpen)}} %</span>
                    </div>
                    <div class="mailCampaign__cell__progress__value"><span>{{item.countOpen ? item.countOpen : 0 }}</span> 
                        {{'CampaignDashboard.Label.Openrate' | texttransform}}</div>
                </div>
            </div>

            <div class="mailCampaign__cell">
                <div class="mailCampaign__cell__progress">
                    <div class="mailCampaign__cell__progress__percent">
                        <app-jj-icons type="cursor" color="secondary" [width]="20" [height]="20"></app-jj-icons>
                        <span>{{getCampaignProgressPercentage(item.countOpen, item.countClicked)}} %</span>
                    </div>
                    <div class="mailCampaign__cell__progress__value"><span>{{item.countClicked ? item.countClicked : 0 }}</span>
                        {{'CampaignDashboard.Label.Clickrate' | texttransform }}</div>
                </div>
            </div>

            <div class="mailCampaign__cell">
                <div class="mailCampaign__cell__progress">
                    <div class="mailCampaign__cell__progress__percent">
                        <app-jj-icons type="warning" color="secondary" [width]="20" [height]="20"></app-jj-icons>
                        <span>{{ getCampaignProgressPercentage(item.countSent, item.countBounced) }} %</span>
                    </div>
                    <div class="mailCampaign__cell__progress__value"><span>{{item.countBounced ? item.countBounced : 0 }}</span>
                        {{'CampaignDashboard.Label.Bouncerate' | texttransform}}</div>
                </div>
            </div>           

            <div class="mailCampaign__cell">
                <div class="mailCampaign__cell__progress">
                    <div class="mailCampaign__cell__progress__percent">
                        <app-jj-icons type="thumbs-down" color="secondary" [width]="20" [height]="20"></app-jj-icons>
                        <span>{{getCampaignProgressPercentage(item?.wizardValueArray.receivers, item.countFailure)}} %</span>
                    </div>
                    <div class="mailCampaign__cell__progress__value"><span>{{item.countFailure ? item.countFailure : 0 }}</span>
                        {{'CampaignDashboard.Label.FailureRate' | texttransform }}</div>
                </div>
            </div>

          <!--   <div class="mailCampaign__cell">
                <div class="mailCampaign__cell__progress">
                    <div class="mailCampaign__cell__progress__percent">
                        <app-jj-icons type="receipt-x" color="secondary" [width]="20" [height]="20"></app-jj-icons>
                        <span>{{getCampaignProgressPercentage(item)}}</span>
                    </div>
                    <div><span>{{item.countFailure ? item.countFailure : 0 }} </span>Failure rate</div>
                </div>
            </div> -->


          <!--   <div class="mailCampaign__cell">
                <div class="mailCampaign__cell__progress">
                    <div class="mailCampaign__cell__progress__percent">
                        <app-jj-icons type="calendar-dots" color="secondary" [width]="20" [height]="20"></app-jj-icons>
                        <span>{{getCampaignProgressPercentage(item)}}</span>
                    </div>
                    
                    <div class="mailCampaign__cell__progress__value"><span>{{item.countPlanned ? item.countPlanned : 0 }} </span> Planned rate</div>
                </div>
            </div> -->

            <div class="mailCampaign__cell">
                <div class="mailCampaign__cell__actions">
                    <app-action-button svg="arrow-right" [tippy]="'show campaign details'"
                        (onClick)="showDetailMailCampaign(item)"></app-action-button>
                    <app-action-button svg="dots-three-outline-fill" [data]="item" [buttons]="[
                                {id:1, svg: 'play', disabled: item.startedAt != null, label: 'Actions.Label.Play', callback:startMailCampaign },
                                {id:2, svg: 'stop', disabled: item.startedAt == null || item.status == 8821, label:'Actions.Label.Stop', callback:stopMailCampaign },
                                {id:3, svg: 'pencil-simple', disabled:item.startedAt !== null , label:'Actions.Label.Edit', callback:editMailCampaign },
                                {id:4, svg: 'copy', label:'Actions.Label.Duplicate', disabled: !this.userService.hasPermission('CanCreateMailshotList'), callback:duplicateMailCampaign}
                            ]"></app-action-button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="campaignDashboard__listcontainer" *ngIf="page === 1">
    <div class="campaignDashboard__listcontainer__listcontent">
            <div *ngFor="let campaignOverviewItem of data" class="campaignDashboard__listrow"
            [ngClass]="{
                hideInDom: isFiltered(campaignOverviewItem.campaignName, microCampaignSearchString),
                'campaignDashboard__listrow__selected': campaignOverviewItem == selectedMicroCampaign
            }">
            <div class="campaignDashboard__listrow__marker">
                <app-row-marker [blue]="campaignOverviewItem == selectedMicroCampaign"></app-row-marker>
            </div>
            <div class="campaignDashboard__listrow__name">
                <div *ngIf="!campaignOverviewItem.nameTranslationTokenId">{{
                    campaignOverviewItem.campaignName | texttransform }}</div>
                <div *ngIf="campaignOverviewItem.nameTranslationTokenId">{{
                    axivasTranslateService.getTranslationTextForId(campaignOverviewItem.nameTranslationTokenId)
                    | texttransform }}</div>
            </div>
            <div class="campaignDashboard__listrow__progress">
                <div class="campaignDashboard__listrow__progress__items">
                    <div class="campaignDashboard__listrow__progress__items__item"
                    [tippy]="'UserDashboard.Label.YourCampaignsTasks' | texttransform">
                    <app-jj-icons [pointer]="false" type="pulse" [width]="20" [height]="20"></app-jj-icons>
                        {{ campaignOverviewItem.taskCount }}</div>
                    <div class="campaignDashboard__listrow__progress__items__item"
                        [tippy]="'UserDashboard.Label.YourCampaignsContacts' | texttransform">
                        <app-jj-icons [pointer]="false" type="address-book-tabs" [width]="20" [height]="20"></app-jj-icons>
                            {{ campaignOverviewItem.contactCount }}</div>
                    <div class="campaignDashboard__listrow__progress__items__item"
                        [tippy]="'UserDashboard.Label.YourCampaignsWins' | texttransform">
                        <app-jj-icons [pointer]="false" type="ranking" [width]="20" [height]="20"></app-jj-icons>
                        {{ campaignOverviewItem.positiveResultCount }}
                    </div>
                </div>
            </div>
            <div class="campaignDashboard__listrow__actions">
                <div class="campaignDashboard__listrow__actions__button"
                    (click)="sendMicroCampaignToParent(campaignOverviewItem)">
                    <app-jj-icons [type]="campaignOverviewItem == selectedMicroCampaign ? 'arrow-circle-right-fill':'caret-circle-right'" [width]="30" [height]="30"></app-jj-icons>
                </div>
            </div>
        </div>
    </div>
</div> 


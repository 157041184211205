<div class="newContact">
<div class="newContact__wrapper">
    <div class="newContact__account">
        <div class="newContact__account__left">
            <div *ngIf="currentAccount == null || currentAccount?.isDummyAccount == true">
                <div class="noAccount">{{ 'NewContact.Label.NoAccount' | texttransform }}</div>
                <div class="noAccountDesc">{{ 'NewContact.Label.NoAccountDesc' | texttransform }}</div>
            </div>
            <div *ngIf="currentAccount != null && currentAccount?.isDummyAccount != true" class="newContact__account__selected">
                <div class="newContact__account__selected__header">{{ 'Account.Label.Header' | texttransform }}</div>
                <div class="newContact__account__selected__value">{{ currentAccount.name1 }}</div>
                <div class="newContact__account__selected__value">{{ currentAccount.zipcode }} {{ currentAccount.city }}</div>
                <div class="newContact__account__selected__value">{{ currentAccount.street }}</div>
            </div>    
        </div>
        <div class="newContact__account__right">
            <app-jj-icons [type]="'plus'" [width]="20" [height]="20" (click)="changeAccount('NewContact.Label.ChangeAccount')"
                *ngIf="currentAccount != null && currentAccount?.isDummyAccount != true" [tippy]="'NewContact.Label.ChangeAccount' | texttransform"></app-jj-icons>
            <app-jj-icons [type]="'arrows-clockwise'" [width]="20" [height]="20" (click)="changeAccount('NewContact.Label.SelectAccount')"
                *ngIf="currentAccount == null"></app-jj-icons>
            <app-jj-icons [type]="'trash'" [width]="20" [height]="20" (click)="removeAccount()" 
                *ngIf="currentAccount != null && currentAccount?.isDummyAccount != true"></app-jj-icons>
           <!-- <app-main-button svg="plus" id="newAccountCreate" (onClick)="changeAccount('NewContact.Label.ChangeAccount')" 
                *ngIf="currentAccount != null && currentAccount?.isDummyAccount != true" [label]="'NewContact.Label.ChangeAccount' | texttransform"></app-main-button>

            <app-main-button svg="user" id="newAccountCreate" (onClick)="changeAccount('NewContact.Label.SelectAccount')" 
                *ngIf="currentAccount == null" [label]="'NewContact.Label.SelectAccount' | texttransform"></app-main-button>

            <app-main-button svg="trash" id="newAccountRemove"(onClick)="removeAccount()" 
                *ngIf="currentAccount != null && currentAccount?.isDummyAccount != true" [label]="'NewContact.Label.RemoveAccount' | texttransform"></app-main-button> -->
        </div>
    </div>
    <div class="newContact__data">
        <app-custom-container controluiid="6134"></app-custom-container>        
    </div>    
    <div class="newContact__footer">
        <app-main-button type="small" [svg]="'plus'" [disabled]="!checkIfContactCanBeSaved()" (onClick)="createContact()" [label]="newContactLabel | texttransform "></app-main-button>
        <app-main-button type="small" [svg]="'plus'" [disabled]="!checkIfContactCanBeSaved()" (onClick)="createContact(true)" [label]="newContactAndLoadLabel | texttransform "></app-main-button> 
    </div>
</div>
</div>

<div class="everleadCheckboxWrapper">
    <label [class.disabled]="disabled" [class.required]="required" [class.error]="error">
        <input type="checkbox" [checked]="value" (change)="setValue($event.target.checked)" [disabled]="disabled">
        
        <span *ngIf="labelLeft" class="labelLeft">{{label | texttransform }}</span>

        <svg *ngIf="!value" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5"  [attr.stroke]="disabled ? 'gray' : '#5465FF'"/>
        </svg>

        <svg *ngIf="value" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" [attr.stroke]="disabled ? 'gray' : '#5465FF'" />
            <path d="M2.5 9L6 12.5L14 4.5"  [attr.stroke]="disabled ? 'gray' : '#5465FF'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <span class="labelRight" *ngIf="!labelLeft">{{label | texttransform }}</span>
    </label>
</div>

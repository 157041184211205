import { Component, OnInit } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ApplicationinitService } from 'app/jollyjupiter/service/applicationinit.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';
import * as AWS from 'aws-sdk';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { ApolloMethod, GraphQLService } from 'app/shared/service/graphql.service';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';

enum pageModes {
  internalUser = 1,
  externalUserLogin = 2,
  externalUserNewPasswordRequired = 3,
  externalUserSetupMFA = 4,
  externalUserTotpRequired = 5,
}

@Component({
  selector: 'app-awslogin',
  templateUrl: './awslogin.component.html',
  styleUrls: ['./awslogin.component.scss']
})
export class AwsloginComponent implements OnInit {
  public pageModes = pageModes;
  loading: boolean = false;

  isAGUser = false;
  // internalUserName = '';
  userChecked = false;
  pageMode = pageModes.internalUser;
  newPassword: string = null;
  newPasswordConfirm: string = null;
  passHidden: boolean = true;

  isMfaRequired: boolean = false;
  isMfaEnabled: boolean = false;
  setupMfaSecretCode: string = null;
  setupMfaAnswer: string = null;
  totpCode: string = null;

  cognitoClientSecret = environment.cognitoClientSecret;
  cognitoClientId = environment.cognitoClientId;
  cognitoClientPool = environment.cognitoClientPool;

  cognitoConsentClientSecret = '1aom9mm5bcs0ket9t3saunqqfnu6f8onl520fsrke81sqdmnq33k';
  cognitoConsentClientId = environment.cognitoConsentClientId;
  cognitoConsentUsername = environment.cognitoConsentUsername;
  cognitoConsentEMail = environment.cognitoConsentEMail;
  cognitoConsentPassword = 'ZgX679BYJeI60J!wn';
  
  activationGuid = null;
  activationGuidFailed = false;
  cognitoIdentity;
  awstoken = null;

  constructor(
    private messagingService: MessagingService,
    private awsCognitoService: AwsCognitoService,
    private applicationinitService: ApplicationinitService,
    private commonService: CommonService,
    public applicationInfoService: ApplicationInfoService,
    private graphQLService: GraphQLService,
    private http: HttpClient
  ) {
    this.cognitoIdentity = new AWS.CognitoIdentityServiceProvider({region: 'eu-central-1'});
   }

  ngOnInit(): void {
    if (this.applicationInfoService.userID != 0) {
      return;
    }
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    this.activationGuid = urlParams.get('guid');
    
    // this.isMfaRequired = this.applicationInfoService.applicationSettings['isMfaRequired'] === 'true';
    this.isMfaRequired = false;  //TODO get from backend
    //if mfa required, new user has to setup MFA when first login
    //if no, he can setup mfa optionally, and can do it later in user settings
    this.isMfaEnabled = this.isMfaRequired;

    const awsUserName = localStorage.getItem('awsUserName');
    if (!this.commonService.isNullOrUndefined(awsUserName)) {
      this.applicationInfoService.awsUserName = awsUserName;
      this.checkUserName();
    }
    this.getIp().subscribe(getIpResult => {
      // Default A&G check
      if (
        this.commonService.checkIfStringContainsString(getIpResult.toString(), environment.externalAGIpMain) ||
        this.commonService.checkIfStringContainsString(getIpResult.toString(), environment.externalAGIpBackup)
      ) {
        // this.loginAG();
      }
    });
    
    this.signInUser().then(result => {
      localStorage.setItem('awstoken', result);
      if (!this.commonService.isNullOrUndefined(this.activationGuid)) {
        this.getUserOTPByGuid();
       }
    });  
  }

  nextPage() {
    if (this.isAGUser) {
      this.loginAG();
    } else {
      this.pageMode = pageModes.externalUserLogin;
    }
  }

  loginAG() {
    this.awsCognitoService.showAwsLoginPage();
  }

  getUserOTPByGuid() {
    let query = `query {
      userOTPByGuid(guid: "<0>")
    }`;

    query = query.replace('<0>', this.activationGuid);
    this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query, query, [])
    .then(userOtp => {
      const userInfo = JSON.parse(userOtp.data.userOTPByGuid);
      this.getUserByEmail(userInfo.UserName).then(getUserByEmailResult => {
        if (getUserByEmailResult.data.userLoginByEmail == null) {
          this.messagingService.showDefaultWarning('Login', 'User not found or wrong password')
        } else {
          const userPoolInfo = getUserByEmailResult.data.userLoginByEmail;
          this.applicationInfoService.userLoginByEmail = userPoolInfo;
          localStorage.setItem('awstokentokenurl', userPoolInfo.awsCognitoPool.tokenUrl);
          localStorage.setItem('awstokenclientid', userPoolInfo.awsCognitoPool.clientId);
          this.applicationInfoService.awsUserName = userInfo.UserName;
          this.applicationInfoService.awsPassword = atob(userInfo.Password);
          this.pageMode = pageModes.externalUserNewPasswordRequired;
          this.login(
            this.applicationInfoService.awsUserName,
            this.applicationInfoService.awsPassword,
            userPoolInfo.awsCognitoPool.clientId,
            userPoolInfo.awsCognitoPool.cognitoPoolId
          );  
        }
      });
    }).catch(error => {
      console.error(error);
      this.activationGuidFailed = true;
    });
  }

  getUserByEmail(email): Promise<any> {
    return new Promise((getUserByEmailResolve, getUserByEmailReject) => {
      let query = `query {
        userLoginByEmail(email: "<0>") {
          userName
          email
          awsCognitoPool {
            clientId
            loginUrl
            tokenUrl
            forgotPasswordUrl
            clientIdStaging
            loginUrlStaging
            cognitoPoolId
            defaultName
          }
        }
      }`;
  
      query = query.replace('<0>', email);
      this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query, query, [])
      .then(getUserByEmailResult => {
        getUserByEmailResolve(getUserByEmailResult);
      });
    });
  }

  loginUser() {
    this.getUserByEmail(this.applicationInfoService.awsUserName)
    .then(userInfo => {
      if (userInfo.data.userLoginByEmail == null) {
        this.messagingService.showDefaultWarning('Login', 'User not found or wrong password');
      } else {
        this.applicationInfoService.userLoginByEmail = userInfo.data.userLoginByEmail;
        localStorage.setItem('awstokentokenurl', userInfo.data.userLoginByEmail.awsCognitoPool.tokenUrl);
        localStorage.setItem('awstokenclientid', userInfo.data.userLoginByEmail.awsCognitoPool.clientId);
        let userInfoDetails = userInfo.data.userLoginByEmail;
        if (userInfoDetails != null) {
          let userInfoClientId = userInfoDetails.awsCognitoPool.clientIdStaging;
          if (environment.dataStage == 'urlLive' || environment.dataStage == 'urlLiveBeta') {
            userInfoClientId = userInfoDetails.awsCognitoPool.clientId;
          }
          this.login(
            this.applicationInfoService.awsUserName,
            this.applicationInfoService.awsPassword,
            userInfoClientId,
            userInfoDetails.awsCognitoPool.cognitoPoolId
          );
        } else {
          this.login(
            this.applicationInfoService.awsUserName,
            this.applicationInfoService.awsPassword,
            this.cognitoClientId,
            this.cognitoClientPool
          );
        }  
      }
    });
  }

  login(userName, password, clientId, userPoolId){
    this.loading = true;
    this.applicationInfoService.user = null;
    this.applicationInfoService.userID = 0;
    this.applicationInfoService.userLoginByEmail = null;
    this.awsCognitoService.authenticateAndSetUser(userName, password, clientId, userPoolId, {
      onSuccess: (session) => {
        if (this.isMfaRequired) {
          // if mfa setup not finished
          this.startMfaSetup();
        } else {
          this.finishAuthentication(session);
        }
      },
      onFailure: (err) => {
        this.messagingService.showDefaultError('Login', err.message);
        this.loading = false;      
        this.activationGuid = null;  
      },
      newPasswordRequired: (data) => {
        // this.messagingService.showDefaultWarning('Login', 'You\'re trying to log in with default password. You should set a new one.');
        this.pageMode = pageModes.externalUserNewPasswordRequired;
        this.loading = false;
        this.activationGuid = null;
      },
      totpRequired: () => {
        this.pageMode = pageModes.externalUserTotpRequired;
        this.loading = false;
      },
    });
  }

  updatePassword() {
    if (this.newPassword !== this.newPasswordConfirm) {
      this.messagingService.showDefaultError('Login', 'Passwords don\'t match');
      return;
    }
    
    this.loading = true;
    this.awsCognitoService.cognitoUser.completeNewPasswordChallenge(this.newPassword, {}, {
      onSuccess: () => {
        this.messagingService.showDefaultSuccess('Login', 'You\'ve succesfully set a new password.');
        this.loading = false;
        if (this.isMfaEnabled) {
          this.startMfaSetup();
        } else {
          this.pageMode = pageModes.externalUserLogin;
          this.applicationInfoService.awsPassword = '';
          this.checkUserName();
        }
      },
      onFailure: (err) => {
        this.messagingService.showDefaultError('Login', err.message);
        this.loading = false;
      },
    });
  }

  startMfaSetup() {
    this.pageMode = pageModes.externalUserSetupMFA;
    this.awsCognitoService.setupMfa({
      getSecretCode: (secretCode: string) => {
        this.setupMfaSecretCode = secretCode;
        this.loading = false;
      },
      onFailure: (err: any) => {
        this.messagingService.showDefaultError('Login', err.message);
        this.loading = false;
      },
    })
  }

  finishMfaSetup() {
    this.loading = true;
    this.awsCognitoService.enableMfaByToken(this.setupMfaAnswer, {
      mfaSetupSuccess: () => {
        this.messagingService.showDefaultSuccess('Login', 'MFA is successfully configured');
        this.pageMode = pageModes.externalUserLogin;
        this.checkUserName();
        this.loading = false;
      },
      mfaSetupFailed: (err) => {
        this.messagingService.showDefaultError('Login', err.message);
        this.loading = false;
      },
      onFailure: (err) => {
        this.messagingService.showDefaultError('Login', err.message);
        this.loading = false;
      },
    });
  }

  sendMfaCode() {
    this.awsCognitoService.cognitoUser.sendMFACode(this.totpCode, {
      onSuccess: (session) => {
        this.finishAuthentication(session);
      },
      onFailure: (err) => {
        this.messagingService.showDefaultError('Login', err.message);
      },
    }, 'SOFTWARE_TOKEN_MFA');
  }

  finishAuthentication(userSession) {
    localStorage.setItem('awstoken', userSession.getAccessToken().getJwtToken())
    localStorage.setItem('awsrefreshtoken', userSession.getRefreshToken().getToken())

    this.awsCognitoService.silentRenew().subscribe(result => {
      this.applicationInfoService.showAWsLoginPage = false;
      this.awsCognitoService.setTokenDetails(result, true);
      this.applicationinitService.startApplication();
    }, error => {
      if (this.applicationInfoService.userLoginByEmail == null) {
        this.awsCognitoService.handleLoginError();
      }
      console.warn(error);
    });
  }

  checkUserName() {
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.awsUserName)) {
      if (this.applicationInfoService.awsUserName == '') {
        localStorage.removeItem('awsUserName');
        this.userChecked = false;
        return;
      }
      localStorage.setItem('awsUserName', this.applicationInfoService.awsUserName);
      if (this.commonService.checkIfStringContainsString(this.applicationInfoService.awsUserName, '@')) {
        const userDetails = this.applicationInfoService.awsUserName.split('@');
        if (this.commonService.checkIfStringContainsString(userDetails[1], 'alex-gross.com')) {
          this.isAGUser = true;
          this.pageMode = pageModes.internalUser;
        } else {
          this.isAGUser = false;
        }
      } else {
        this.isAGUser = false;
      }
    }
    this.userChecked = true;
  }

  openPasswordReset() {
    this.getUserByEmail(this.applicationInfoService.awsUserName)
    .then(userInfo => {
      if (userInfo.data.userLoginByEmail != null) {
        let forgotUrl = userInfo.data.userLoginByEmail.awsCognitoPool.forgotPasswordUrl;
        forgotUrl = forgotUrl.replace('<baseUrl>', environment.serverUrl);
        this.commonService.openUrl(forgotUrl, '_new');
      } else {
        this.messagingService.showDefaultWarning('Reset password', 'Username not found');
      }   
    });    
  }

  public async signInUser(): Promise<any> {
    const params = {
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: this.cognitoConsentClientId,
      AuthParameters: {
        "USERNAME": this.cognitoConsentUsername,
        "PASSWORD": this.cognitoConsentPassword,
        "SECRET_HASH": this.generateHash(),
      }
    }

    try{
      const data = await
      this.cognitoIdentity.initiateAuth(params).promise();
      this.awstoken = data["AuthenticationResult"]["AccessToken"];
      return this.awstoken;
    }
    catch (error) {
      console.log(error);
      return null;
    }
  }

  private generateHash(): string{
    const key = CryptoJS.enc.Utf8.parse(this.cognitoConsentClientSecret)
    const userInfo = CryptoJS.enc.Utf8.parse(this.cognitoConsentUsername + this.cognitoConsentClientId)
    const hmac = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(userInfo, key))
    return hmac
  }

  getIp(): Observable<string> {
    return this.http.get(environment.externalIPCheckLink, {responseType: 'text'})
  }
}

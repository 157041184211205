import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';



@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss'
})
export class DropdownComponent implements OnInit, OnChanges, AfterContentInit{

  constructor(
    public commonService: CommonService,
    private elementRef: ElementRef){}
  
  @Input() options: any | null;
  @Input() uiType: string | null;
  @Input() type: string | null;
  @Input() value: any | null;
  @Input() svg: string  | null;

  @Output() onClick = new EventEmitter<void>();
  @Output() valueChange = new EventEmitter<void>();

  uicontentprops = {
    placement:'bottom',
    trigger: 'mouseenter',
    hideOnClick:true,
    theme:'light-border',
    arrow:true,
    touch:true,
    interactive:true,
    appendTo: () => document.body,
  }

  selectedValue: any | null = {};
  tippyInstance: any;


  ngOnInit(): void {
    if(this.options && this.value){
      this.selectedValue = this.options.find((item) => item.id === this.value);
    }
  }

  ngAfterContentInit(): void {
    if(this.type === 'tab'){
      this.uicontentprops = {
        ...this.uicontentprops,
        arrow: false,
      }
    }
    this.selectedValue = this.options.find((item) => item.id === this.value);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(changes['options'] && !changes['options'].firstChange){
      this.options= changes['options'].currentValue
    }

    if(this.value && this.options.length > 0){
      this.selectedValue = this.options.find((item) => item.id === this.value);
    }
  }

  valueChanged(option){
    this.selectedValue= option;
    this.onClick.emit(this.selectedValue.id)
  }

}

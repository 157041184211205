
<div class="counter" [class.error]="error">
    <button class="counter__button counter__button__left" (click)="decrement()" [disabled]="count<= 0" [class.error]="error" [class.disabled]="count<= 0" >
        <app-jj-icons type="minus" [width]="32" [height]="32" color="primaryLight"></app-jj-icons>
    </button>
   <!--  <span *ngIf="!isTyping" (dblclick)="toggleField()" class="counter__field">{{count}}</span> -->
   <input 
   class="counter__field" 
   *ngIf="fire" 
   (dblclick)="toggleField()" 
   type="text" 
   [(ngModel)]="count" 
   (ngModelChange)="handleInput(count)"
   [disabled]="disabled"
   [class.error]="error"/>

   <input 
   class="counter__field" 
   *ngIf="!fire" 
   type="text" 
   [(ngModel)]="count" 
   (change)="handleInput($event.target.value)"
   [class.error]="error"
   [disabled]="disabled" />

    <button class="counter__button counter__button__right" (click)="increment()" [disabled]="undefined" [class.error]="error">
        <app-jj-icons type="plus" [width]="32" [height]="32" color="primaryLight"></app-jj-icons>
    </button>
</div>
<span *ngIf="error ? true : false" class="counter__errorMessage">{{ errorMessage | texttransform }}</span>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DashboardcoreService } from '../../dashboardcore.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MethodService } from 'app/jollyjupiter/service/method.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-defaultdashboard-agentwvl',
  templateUrl: './defaultdashboard-agentwvl.component.html',
  styleUrl: './defaultdashboard-agentwvl.component.scss'
})
export class DefaultdashboardAgentwvlComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription()
  dashboardAccountId = 19;
  dashboardContactId = 20;
  dashboardId = 19;
  loadingData = true;
  dashboardResultData = [];
  tab = 1;
  pools = [];
  poolId = null;
  campaigns = [];
  campaignId = null;
  userId = 0;
  users = [];
  languageId = 2;
  stepId = 0;
  steps = [];
  viewChart = true;
  agentWVLChartDataSum = null;
  startDate = new Date(2024, 8, 1, 0, 0, 0 , 0);
  endDate = new Date(Date.now());
  stepArrayData = [];
  userSumOverallArray = [];
  userSumOverallDetailArray = [];

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private dashboardcoreService: DashboardcoreService,
    private commonService: CommonService,
    private methodService: MethodService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService
  ) {}

  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.getCampaigns();
        this.getUsers();
      }
    });

    this.poolId = this.applicationInfoService.poolId;
    
    this.getDashboardDataArray(this.dashboardId);
    this.getCampaigns();
    this.getPools();
    this.getUsers();
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getUsers() {
    this.users = this.applicationInfoService.projectUsers.toArray();
  }

  getCampaigns() {
    this.campaigns = this.applicationInfoService.campaigns.toArray().filter(campaigns => campaigns.isActive == true);
  }

  getDashboardDataArray(dashboardId) {
    let params = this.fillParams();
    this.loadingData = true;
    this.steps = [];
    this.stepArrayData = [];
    this.dashboardResultData = [];
    this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(getDashboardDataArrayResult => {      
      this.loadingData = false;
      if (getDashboardDataArrayResult) {
        if (getDashboardDataArrayResult[0]) {
          const dashboardResultDataTemp = JSON.parse(JSON.stringify(getDashboardDataArrayResult[0]));      
          dashboardResultDataTemp.forEach(tempItem => {
            if (tempItem.user_name != 'Report.ProjectOverview.Summe' && tempItem.user_name != 'Keine Wiedervorlage') {
              this.dashboardResultData.push(tempItem);
            }
          });          
          this.steps = [...new Set(this.dashboardResultData.map(item => item.step))];          
          this.getStepArraySum();
        }
      }
    }).catch((error)=>{
      this.loadingData = false;
    });
  }

  fillParams() {
    const params = [];
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@pool_id', value: this.poolId });
    params.push({ key: '@campaign_id', value: this.campaignId });
    params.push({ key: '@language_id', value: this.languageId });
    params.push({ key: '@step_id', value: this.stepId });
    params.push({ key: '@user_id', value: this.userId });
    params.push({ key: '@start_date', value:this.commonService.getDateString(this.startDate) });
    params.push({ key: '@end_date', value: this.commonService.getDateString(this.endDate) });
    // params.push({ key: '@start_date', value: '2024-08-04' });
    // params.push({ key: '@end_date', value: '2024-09-17' });
    return params;
  }

  getPools() {
    this.pools = this.applicationInfoService.pools.toArray().filter(pool => pool.isActive == true);
  }

  stepData(step) {
    return this.dashboardResultData.filter(item => item.step == step);
  }

  switchTab(tab) {
    this.tab = tab;
    switch(tab) {
      case 1:
        this.dashboardId = this.dashboardAccountId;
        break;
      case 2:
        this.dashboardId = this.dashboardContactId;
        break;
    }
    this.getDashboardDataArray(this.dashboardId);
  }

  getChartHeight(array) {
    if (array == null) {
      return 60;
    } else {
      return 80 + (array.length * 40);
    }
  }

  getStepArraySum() {
    this.userSumOverallArray = [];
    this.userSumOverallDetailArray = [];
    // User ermitteln
    this.dashboardResultData.forEach(dataItem => {
      const userItemSum = this.userSumOverallDetailArray.find(tempArrayItem => tempArrayItem.user_name == dataItem.user_name);
      if (!userItemSum) {
        this.userSumOverallDetailArray.push({
          user_name: dataItem.user_name,
          past: dataItem.past,
          today: dataItem.today,
          far_future: dataItem.far_future,
          near_future: dataItem.near_future,
          future: dataItem.future,
          anzahl: dataItem.anzahl
        });
      } else {
        userItemSum.past = userItemSum.past + dataItem.past;
        userItemSum.today = userItemSum.today + dataItem.today;
        userItemSum.far_future = userItemSum.far_future + dataItem.far_future;
        userItemSum.near_future = userItemSum.near_future + dataItem.near_future;
        userItemSum.future = userItemSum.future + dataItem.future;
        userItemSum.anzahl = userItemSum.anzahl + dataItem.anzahl;
      }

      const userItem = this.userSumOverallArray.find(tempArrayItem => tempArrayItem.user_name == dataItem.user_name);
      if (!userItem) {
        this.userSumOverallArray.push({
          user_name: dataItem.user_name,
          count: dataItem.anzahl
        });
      } else {
        userItem.count = userItem.count + dataItem.anzahl;
      }
    });
  }

  getStepArray(step) {
    let returnArray = [];
    if (this.commonService.isNullOrUndefined(this.stepArrayData[step])) {
      let array = this.stepData(step);
      array.forEach(item => {
        item.user_name = this.axivasTranslateService.getTranslationTextForToken(item.user_name);
      });
      array.reverse();
      this.stepArrayData[step] = array;
      return returnArray;  
    } else {
      return this.stepArrayData[step];
    }
  }
}

<div class="processvisualpop">
    <div class="processvisualpop__form">
        <div class="row">
            <div class="col-12" *ngIf="type !== 'bookingDays'">
                <app-checkbox label="'ProcessDesign.Label.IsActive'" [(ngModel)]="data.isActive"></app-checkbox>
            </div>
            <div class="col-12" *ngIf="type !== 'bookingDays'">
                <app-text-input [(ngModel)]="data.defaultName" label="ProcessDesign.Label.DefaultName"></app-text-input>
            </div>

            <div class="col-12" *ngIf="type === 'steps'">
                <app-text-area-input [(ngModel)]="data.description"
                    label="ProcessDesign.Label.Description"></app-text-area-input>
            </div>

            <!-- HERE FOR WIZARD ASSETS -->

            <div class="col-12" *ngIf="type  === 'bookingDays'">
                <app-number-input [(ngModel)]="data.bookTaskAfterXDays"
                    label="ProcessVisual.Label.AutomaticbookingafterxDays"></app-number-input>
            </div>

            <div class="col-12">
                <div class="processvisualpop__nodeswrapper" *ngIf="type === 'bookingDays'">
                    <label class="label">
                        {{'ProcessVisual.Label.Result' | texttransform}}
                    </label>
                    <div class="nodesInner">
                        <span
                            [ngClass]="result.nextStepId ? 'resultsChildren' : 'results' ">{{result.defaultName}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="processvisualpop__footer">
        <div class="row">
            <div class="col-12">
                <div class="processvisualpop__buttonContainer">
                    <app-main-button type="border" svg="floppy-disk" (onClick)="saveData()"
                        label="ProcessDesign.Label.Save"></app-main-button>
                    <!--   <button class="everleadCustomButton"(click)="saveData()">{{'ProcessDesign.Label.Save' | texttransform }}</button> -->
                </div>

            </div>
        </div>
    </div>

</div>
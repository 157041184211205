<div>
    <!-- <div class="questionAnswer__question">{{ question.defaultName }}</div> -->
    <div *ngIf="question.isMultiselect" class="questionAnswer">
        <label class="multiSelectWrapper__label">{{ question.defaultName }}</label>
        <div class="multiSelectWrapper">
            <div *ngFor="let answer of answers" [ngClass]="{ hideInDom: answer.isDeleted }">
                <div *ngIf="answer.isDeleted == false">
                    <div class="questionAnswer__checkbox">
                        <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue(questionAnswers[question.id][answer.id])" 
                            *ngIf="questionAnswers[question.id][answer.id].isSelected == true"></svg-icon>
                        <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor" (click)="toggleValue(questionAnswers[question.id][answer.id])" 
                            *ngIf="questionAnswers[question.id][answer.id].isSelected != true"></svg-icon>
                        {{ answer.defaultName }}                
                    </div>
                    <div *ngIf="questionAnswers[question.id][answer.id].isSelected == true && questionAnswers[question.id][answer.id].typeLookupId == 1907">
                        <input [(ngModel)]="questionAnswers[question.id][answer.id].answerText" class="maxWidth"
                            (change)="questionaireControlService.updateQuestionAnswer(questionAnswers[question.id][answer.id])">
                    </div>
                    <div *ngIf="questionAnswers[question.id][answer.id].isSelected == true && questionAnswers[question.id][answer.id].typeLookupId == 1908">
                        <input [(ngModel)]="questionAnswers[question.id][answer.id].answerText" type="number" class="maxWidth"
                            (change)="questionaireControlService.updateQuestionAnswer(questionAnswers[question.id][answer.id])">
                    </div>
                </div>
            </div>    
        </div>
    </div>
    <div *ngIf="!question.isMultiselect && dropdownAnswers[question.id]" class="questionAnswer questionAnswer__select">
        <app-select-input 
            [label]="question.defaultName | texttransform" 
            [(ngModel)]="dropdownAnswers[question.id].answerId" 
            [options]="answers" 
            display="defaultName" 
            bind="id" 
            (valueChange)="changeDropdownAnswerChanged(dropdownAnswers[question.id])"
        ></app-select-input>
    </div>
    <div *ngIf="dropdownAnswers[question.id]">
        <div *ngIf="!question.isMultiselect && isTextControl(dropdownAnswers[question.id].answerId)">
            <input [(ngModel)]="dropdownAnswers[question.id].answerText" class="maxWidth"
                (change)="changeDropdownAnswerChanged(dropdownAnswers[question.id])">
        </div>    
    </div>
</div>

<div class="debugPanel__wrapper">
    <app-jj-icons type="shield-star" (click)="loadAdminPanel()" [tippy]="'Admin Panel'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}"></app-jj-icons>
    <app-jj-icons type="list-checks" [tippy]="'Taskinfo'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="showTaskInfo()"></app-jj-icons>
    <app-jj-icons type="book" [tippy]="'Dictionarys anzeigen'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="showApplicationInfo()"></app-jj-icons>
    <app-jj-icons type="user" [tippy]="'Informationen zum angeldeten User'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="showUserInformation()"></app-jj-icons>
    <app-jj-icons type="tree-structure" [tippy]="'Projekt-Management'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="projectManagement()"></app-jj-icons>
    <app-jj-icons type="globe" [tippy]="'Übersetzungen verwalten'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="showTranslationForm()"></app-jj-icons>
    <app-jj-icons type="file-arrow-up" [tippy]="'Daten importieren'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="importMask()"></app-jj-icons>
   <app-jj-icons type="kanban"  [tippy]="'Vorhandenen Task simulieren'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}"
   (click)="createAccountEnvironmentForProjectSetup()"></app-jj-icons>
   <app-jj-icons type="flag" [tippy]="'Auditverwaltung öffnen'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="openAudit()"></app-jj-icons>
</div>


<!-- <div class="debugPanel__wrapper">
    <i class="fas fa-user-shield debugInfoIcon" [tippy]="'Admin Panel'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}"
        (click)="loadAdminPanel()" style="color: blue; margin-right: 10px;"></i>
    <i class="headerDivider"></i>
    <i class="fas fa-tasks debugInfoIcon" [tippy]="'Taskinfo'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="showTaskInfo()"></i>
    <i class="fas fa-chess debugInfoIcon" [tippy]="'Dictionarys anzeigen'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="showApplicationInfo()"></i>
    <i class="fas fa-user debugInfoIcon" [tippy]="'Informationen zum angeldeten User'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="showUserInformation()"></i>
    <i class="fas fa-project-diagram debugInfoIcon" [tippy]="'Projekt-Management'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="projectManagement()"></i>
    <i class="fas fa-globe debugInfoIcon" [tippy]="'Übersetzungen verwalten'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="showTranslationForm()"></i>
    <i class="fas fa-file-upload debugInfoIcon" [tippy]="'Daten importieren'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="importMask()"></i>
    <i class="fas fa-vials debugInfoIcon" [tippy]="'Vorhandenen Task simulieren'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}"
        (click)="createAccountEnvironmentForProjectSetup()"></i>    
    <i class="fas fa-undo-alt debugInfoIcon" [tippy]="'Auditverwaltung öffnen'" [tippyOptions]="{placement:'bottom', theme: 'everleadBlack'}" (click)="openAudit()"></i>    
</div> -->
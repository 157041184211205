 <div class="deactivateUserWrapper">
    <div class="deactivateUserSource">
        <label class="deactivateUserInfoLabel">{{ 'DeactivateUser.Label.SourceUser' | texttransform }}</label>
        <app-component-header class="agComponentHeader__header" header="DeactivateUser.Label.SourceUserHeader"></app-component-header>        
        <div>
            {{ userInfo._1353_username }}
        </div>
    </div>
    <div class="deactivateUserTarget">
        <app-component-header class="agComponentHeader__header" header="DeactivateUser.Label.TargetUserHeader"></app-component-header>
        <label class="deactivateUserInfoLabel">{{ 'DeactivateUser.Label.TargetUser' | texttransform }}</label>
        <app-select-input label="General._.User" [(ngModel)]="newUserId" 
            [options]="projectUsers" display="userName" bind="id"></app-select-input>
    </div>
    <div class="deactivateUserButtons">
        <app-main-button svg="image" (onClick)="deactivateUser()" label="DeactivateUser.Label.Deactivate" [disabled]="newUserId == null"></app-main-button>
        <app-main-button svg="image" (onClick)="closePopup()" label="General._.Cancel"></app-main-button>
        
    </div>
 </div>
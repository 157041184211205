<div *ngIf="ringcentralwebphoneService?.extension != null && ringcentralwebphoneService.session == null"
    class="ringCentralUserInfo">
    <app-jj-icons type="headset" [width]="40" [height]="40" color="green"></app-jj-icons>
    <div class="ringCentralUserInfo__wrapper">
        <div class="ringCentralWidgetInfoline">
            <!-- <div class="ringCentralWidgetInfoline__label">worker</div> -->
            <div class="ringCentralWidgetInfoline__value">
                {{ ringcentralwebphoneService?.extension.contact?.firstName }}
                {{ ringcentralwebphoneService?.extension.contact?.lastName }}
            </div>
        </div>
        <div class="ringCentralWidgetInfoline">
            <div class="ringCentralWidgetInfoline__label">{{ 'RingCentral.Label.PhoneNumber' | texttransform }}:</div>
            <div class="ringCentralWidgetInfoline__value">{{ ringcentralwebphoneService?.ownCallerNumber }}</div>
        </div>
    </div>
</div>

<div *ngIf="(ringcentralwebphoneService.session || showIncomingCall) && user">
    <div *ngIf="user" class="ringCentralUserInfo">
        <div class="ringCentralWidgetHeader">
            <app-jj-icons type="headset" [width]="40" [height]="40" color="green"></app-jj-icons>
            <div class="ringCentralUserInfo__wrapper">
                <div class="ringCentralWidgetInfoline">
                    <div class="ringCentralWidgetInfoline__label">{{ 'SoftPhone.Label.OtherPartner' | texttransform }}</div>
                    <div class="ringCentralWidgetInfoline__value">{{ user.selectedNumber}}</div>
                </div>    
                <div class="ringCentralWidgetInfoline">
                    <div class="ringCentralWidgetInfoline__label">{{'Webphone.Label.Time' | texttransform }}</div>
                    <div class="ringCentralWidgetInfoline__value">{{
                        ringcentralwebphoneService.getTimeDifferenceString() }}</div>
                </div>               
            </div>
        </div>
        <div class="ringCentralUserInfo__buttons">
            <button *ngIf="ringcentralwebphoneService.incomingCall" (click)="acceptCall()">
                <app-jj-icons type="phone-incoming" color="green" [width]="30" [height]="30" [pointer]="true"></app-jj-icons>
            </button>
            <button *ngIf="ringcentralwebphoneService.incomingCall" (click)="rejectCall()">
                <app-jj-icons type="phone-x" color="red" [width]="30" [height]="30" [pointer]="true"></app-jj-icons>
            </button>
            <button
                *ngIf="
                    ringcentralwebphoneService.session && 
                    !ringcentralwebphoneService.established && 
                    !ringcentralwebphoneService.incomingCall"
                (click)="closeCall()">
                <app-jj-icons type="phone-x" color="red" [width]="20" [height]="20" [pointer]="true"></app-jj-icons>
            </button>
            <button
                *ngIf="ringcentralwebphoneService.session && ringcentralwebphoneService.established || callAccepted"
                (click)="closeCall()">
                <app-jj-icons type="phone-x" color="red" [width]="20" [height]="20" [pointer]="true"></app-jj-icons>
            </button>
        </div>
    </div>
</div>
<div class="everleadColorField" [class.disabled]="disabled">
    <label class="everleadColorField__label" *ngIf="label" [class.disabled]="disabled">
        {{ label | texttransform }}
        <app-wizard-infobutton *ngIf="info" [displayText]="info" [width]="17" [height]="17"></app-wizard-infobutton>
    </label>
    <div class="everleadColorField__value" [class.disabled]="disabled">

        <input
        type="text"
        [(ngModel)]="value"
        (change)="handleInput($event.target.value)">

        <div class="color-picker">
            <input type="color" 
            [placeholder]="placeholder" 
            [(ngModel)]="value"
            (change)="handleInput($event.target.value)"
            [disabled]="disabled">
        </div>

    </div>
</div>

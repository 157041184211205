<div class="questionaireQuestion">
    <div class="">
        <div class="questionaireQuestion__line">
            <div class="questionaireQuestion__symbol">
                <i class="fas fa-question-circle fa-lg"></i>
            </div>
            <div class="questionaireQuestion__defaultName">
                <input [(ngModel)]="question.defaultName" class="maxWidth" (change)="questionaireControlService.updateQuestion(question)">
            </div>    
            <div class="questionaireQuestion__type">
                <!-- <select [(ngModel)]="question.type" class="maxWidth" (change)="questionaireControlService.updateQuestion(question)">
                    <option [ngValue]=1>{{ 'QuestionaireDesigner.Label.Dropdown' | texttransform }}</option>
                    <option [ngValue]=2>{{ 'QuestionaireDesigner.Label.Multiselect' | texttransform }}</option>
                    <option [ngValue]=3>{{ 'QuestionaireDesigner.Label.FreeText' | texttransform }}</option>
                </select> -->
                <input [(ngModel)]="question.isMultiselect" type="checkbox" (change)="questionaireControlService.updateQuestion(question)">
                {{ 'QuestionaireDesigner.Label.IsMultiselect' | texttransform }}
            </div>    
            <div class="questionaireQuestion__newanswers">
                <app-main-button type="small" svg="plus" (click)="addAnswer()" [label]="'QuestionaireDesigner.Label.AddAnswer' | texttransform"></app-main-button>
                <!-- <i class="fas fa-plus fingerCursor" (click)="addAnswer()"></i> -->
                <i class="fas fa-trash fingerCursor" (click)="deleteQuestion(question)"></i>
            </div>                
        </div>
    </div>
    <div class="questionaireQuestion__answerwrapper" *ngIf="question.type != 3">
        <div class="questionaireQuestion__answers">   
            <div cdkDropList [cdkDropListData]="question.answers" (cdkDropListDropped)="moveInList($event, '0')">
                <div *ngFor="let answer of question.answers" cdkDrag [cdkDragData]="answer" data-html="true" [ngClass]="{
                    hideInDom: answer.isDeleted
                }">
                    <div class="questionaireAnswer">
                        <div class="questionaireQuestion__symbol">
                            <i class="fas fa-check fa-lg"></i>
                        </div>                       
                        <div class="questionaireAnswer__defaultName" (click)="$event.stopPropagation()">
                            <input class="maxWidth" [(ngModel)]="answer.defaultName" 
                                (change)="questionaireControlService.updateAnswer(answer)">
                        </div>
                        <div class="questionaireAnswer__type">
                            <select [(ngModel)]="answer.typeLookupId" (change)="questionaireControlService.updateAnswer(answer)">
                                <option [ngValue]=null></option>
                                <option [ngValue]=typeLookup.id *ngFor="let typeLookup of typeLookups">{{ commonService.getTranslationValueFromArray(typeLookup) }}</option>
                            </select>
                        </div>
                        <div class="questionaireAnswer__subQuestions">
                            <app-main-button type="small" svg="plus" (click)="addQuestion(answer)" [label]="'QuestionaireDesigner.Label.AddSubQuestion' | texttransform"></app-main-button>
                            <!-- <i class="fas fa-plus fingerCursor" (click)="addQuestion(answer)"></i>  -->
                            <i class="fas fa-trash fingerCursor" (click)="deleteAnswer(answer)"></i>
                            <i class="fas fa-grip-lines"></i>
                        </div>
                    </div>
                    <div *ngIf="answer.childQuestions.length > 0" class="subQuestions">
                        <div cdkDropList [cdkDropListData]="answer.childQuestions" (cdkDropListDropped)="moveInList($event, '0')">
                            <div *ngFor="let question of answer.childQuestions" cdkDrag [cdkDragData]="questionitem" data-html="true" >
                                <app-questionaire-designer-questioncontrol 
                                    [question]=question 
                                    [parent]="answer.questions"
                                ></app-questionaire-designer-questioncontrol>
                            </div>                        
                        </div>                                   
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
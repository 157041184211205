<div class="newLogicalEntityWindow">
    <h5 class="newLogicalEntityLabel">{{ 'MyLeads.Label.NewLogicalEntityHeader' | texttransform }}</h5>
    <br>
    <app-text-input [fire]="true" [(ngModel)]="newLogicalEntityName" label="MyLeads.Label.NewLogicalEntityName"></app-text-input>
    
    <br>
    <br>
    <app-main-button type="border" svg="plus" label="MyLeads.Label.NewLogicalEntityCreate" [disabled]="newLogicalEntityName.length < 4" (onClick)="createNewLogicalEntity()"></app-main-button>
</div>


<!-- <div><b>{{ 'MyLeads.Label.NewLogicalEntityHeader' | texttransform }}</b></div>
<br>

<label class="newLogicalEntityLabel">{{ 'MyLeads.Label.NewLogicalEntityName' | texttransform }}</label>
<input [(ngModel)]="newLogicalEntityName" class="newLogicalEntityInput">
<br>
<br>

<button [disabled]="newLogicalEntityName.length < 4" class="jjButtonColor jjButtonStyle"
(click)="createNewLogicalEntity()"
>
    {{ 'MyLeads.Label.NewLogicalEntityCreate' | texttransform }}
</button> -->

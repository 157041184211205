import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { ProjectService } from 'app/jollyjupiter/service/project.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { LanguageService } from 'app/shared/translation/language.service';
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-emaileditor-sync',
  templateUrl: './emaileditor-sync.component.html',
  styleUrls: ['./emaileditor-sync.component.scss'],
  providers: []
})

export class EmaileditorSyncComponent implements OnInit {
  subject = '';
  to = '';
  cc= '';
  bcc = '';
  content = null;  
  newMailContent = '';
  templates = [];
  attachments = [];
  selectedCampaign = null;
  campaigns = [];
  selectedCloudConnection = null;
  cloudConnections = [];
  contentMode = 1;
  selectedTemplate = null;
  dropdownTemplate = null;
  languageId = 2;
  showAttachmentPopup = false;
  availableContentLibraryFiles = [];
  doubleOptinMissing = false;
  optOutAvailable = false;
  doubleOptinMissingChecked = false;
  optStatesResult = [];

  constructor(
    private languageService: LanguageService,
    private axivasTranslateService: AxivasTranslateService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    public emailtemplateService: EmailtemplateService,
    private loaderService: LoaderService,
    private messagingService: MessagingService,
    private eventService: EventService,
    private sharedAPI: SharedAPI,
    public commonService: CommonService,
    private projectService: ProjectService,
    private methodService: MethodService
  ) {}

  ngOnInit(): void {    
    this.getCloudConnections();
    this.getEmailTemplates();
    this.getCampaigns();
    this.getOptinStates();
    if (this.applicationInfoService.miscSettings['syncMailTo']) {
      this.to = this.applicationInfoService.miscSettings['syncMailTo'];
      this.applicationInfoService.miscSettings['syncMailTo'] = null;
    }    
    this.attachments = [];
    this.availableContentLibraryFiles = [];
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.currentContact.languageId)) {
      this.languageId = this.applicationInfoService.currentContact.languageId
    } else {
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.projectInfo.languageId)) {
        this.languageId = this.applicationInfoService.projectInfo.languageId;
      }
    }

    if (this.applicationInfoService.applicationSettings['isDemoproject'] == 'true') {
      this.to = this.applicationInfoService.user.email;
    }
  }

  getCampaigns() {
    this.campaigns = this.applicationInfoService.campaigns.toArray();
    this.selectedCampaign = this.campaigns[0].id;
  }

  sendEMail() {
    if (this.applicationInfoService.applicationSettings['isDemoproject'] == 'true') {
      this.to = this.applicationInfoService.user.email;
      this.cc = '';
      this.bcc = '';
    }

    // this.checkDoubleOptin();
    const promiseArray: Promise<any>[] = [];
    // if (this.doubleOptinMissing) {
    //   promiseArray.push(this.methodService.launchInternalMethod(InternalMethodType.AskYesNo, [
    //     'EMailEditorSync.Label.SendEMailWithoutOptinHeader', 
    //     'EMailEditorSync.Label.SendEMailWithoutOptinMessage'
    //   ]));
    // }
    Promise.all(promiseArray).then(() => {
        const mailObject = {
          cloudConnectionId: this.selectedCloudConnection,
          to: this.to,
          cc: this.cc,
          bcc: this.bcc,
          subject: this.subject,
          isHtml: true,
          htmlBody: '',
          emailTemplateId: null,
          templateContentOverride: null
        }
         
        if (this.selectedTemplate != null) {
          mailObject.emailTemplateId = this.selectedTemplate.id;
          mailObject.htmlBody = '';
          // mailObject.htmlBody = this.applicationInfoService.miscSettings['emailEditorLastRenderedContent'];
        } else {
          if (this.content == null) {
            this.messagingService.showDefaultInfo(
              'E-Mail', 'EmailEditorSync.Label.PleaseEnterText'
            );
            return;
          }
          mailObject.htmlBody = this.content.renderedData;      
        }
        if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['templateOverwriteChanges'])) {
          mailObject.templateContentOverride = this.applicationInfoService.miscSettings['templateOverwriteChanges'];
        }
        this.getAttachmentsForMail(mailObject);
    
        this.loaderService.display(true);
        this.sharedAPI.sendSMTPMail(mailObject).subscribe(
          (mailResult) => {
            this.loaderService.display(false);
            this.messagingService.showDefaultSuccess('E-Mail', 'EmailEditorSync.Label.MailSent');      
            mailObject['mailId'] = mailResult.mailId;
            this.createTaskForEmail(mailObject);
            this.eventService.closeJjPopup();
          },
          (error) => {
            this.loaderService.display(false);
            console.error(error);
            this.messagingService.showDefaultError('E-Mail', 'Error');
          }
        );         
      });    
  }

  cantSendMail() {
    let returnValue = false;
    if (this.selectedCloudConnection == null) { returnValue = true; }
    if (this.subject == '') { returnValue = true; }
    if (this.content?.renderedData == '') { returnValue = true; }
    if (this.doubleOptinMissing && this.dropdownTemplate != null) { returnValue = true; }
    return returnValue;
  }

  createTaskForEmail(email) {
    let contactId = null;
    if (this.applicationInfoService.currentContact) {
      contactId = this.applicationInfoService.currentContact.id;
    }

    this.externaldatasourceService.executeExternalDataSource(322, [
      this.commonService.getModifyArrayBody({
        stepId: 1123,
        resultId: 7842,
        subject: this.subject,
        followUpDate: new Date(Date.now()).toUTCString(),
        campaignId: this.selectedCampaign,
        mailId: email.mailId
      }, [])
    ]).then(() => {
      this.eventService.updateCombinedActivityList.emit();
    });
  }

  getCloudConnections() {
    this.externaldatasourceService.executeExternalDataSource(845).then(cloudConnections => {
      this.cloudConnections = cloudConnections.filter(connection => 
        connection.projectId == this.applicationInfoService.projectID &&
        connection.isActive == true &&
        connection.connectionTypeLookupId == 7942
      );
      if (this.cloudConnections.length > 0) {
        this.selectedCloudConnection = this.cloudConnections[0].id;
      }
    });
  }

  getEmailTemplates() {
    this.externaldatasourceService.executeExternalDataSource(719).then(getEmailTemplatesResult => {
      this.templates = getEmailTemplatesResult;
    });
  }

  addTemplate(template) {
    this.loaderService.display(true);
    this.emailtemplateService.getTempalateLanguage(template.id, 2).then(addTemplateResult => {
      let html = addTemplateResult.bodyHtml;
      addTemplateResult.usedEmailTemplateContents.forEach(content => {
        html = html.replace('{{' + content.defaultName + '}}', content.content)
      });
      html = this.emailtemplateService.replaceGeneralTokens(html);
      if (this.subject == '') {
        this.subject = addTemplateResult.subject;
      }
      this.eventService.addTextToHTMLText.emit(
        this.emailtemplateService.renderEMail(html, [])
      );
      this.loaderService.display(false);
      // this.eventService.addImageArrayToHTMLText.emit(template.contentPictures);
    }).catch(() => { this.loaderService.display(false); });
  }

  isValidTemplate(template) {
    return true;
  }

  addAttachment(event) {
    this.loaderService.display(true);    
    const files: FileList = event.target.files;
    const formData = new FormData();
    this.sharedAPI.uploadAttachment(files[0]).subscribe(data => {
      this.attachments.push(data);
      this.loaderService.display(false);    
    });    
  }

  selectTemplate(template) {
    this.selectedTemplate = null;
    setTimeout(() => {
      if (template != null) {
        this.emailtemplateService.getTemplateLanguageSubject(template, this.languageId).then(getTemplateLanguageSubjectResult => {
          this.subject = getTemplateLanguageSubjectResult;
        });
      }      
      this.selectedTemplate = template;
      this.isDoubleOptinMissing(template);
      this.getTemplateAttachments();
    }, 400);             
  }

  removeAttachment(attachment) {
    this.commonService.removeItemFromArray(this.attachments, attachment);
  }

  onFilesSelected(files) {    
    this.uploadFiles(files);
  }

  addContentLibraryFile(file) {    
    if (!this.commonService.checkIfItemIsInArray(this.attachments, file)) {
      this.attachments.push(file);
    }    
  }

  showAttachments() {
    this.externaldatasourceService.executeExternalDataSource(881, []).then(getContentLibraryResult => {
      getContentLibraryResult.forEach(content => {
        content.source = 'contentlibrary'
      });
      this.availableContentLibraryFiles = getContentLibraryResult;
    });
    this.showAttachmentPopup = true;
  }

  getAttachmentsForMail(mailObject) {
    let contentLibraryAttachmentIds = [];
    let attachmentUrls = [];
    this.attachments.forEach(attachment => {      
      if (attachment.source == 'contentlibrary') {
        contentLibraryAttachmentIds.push(attachment.id);
      }
      if (attachment.source == 'upload') {
        attachmentUrls.push(attachment.location);
      }
      if (attachment.source == 'template') {
        attachmentUrls.push(attachment.location);
      }
    });    
    mailObject['ContentLibraryAttachmentIds'] = contentLibraryAttachmentIds;
    mailObject['AttachmentUrls'] = attachmentUrls;
  }

  uploadFiles(files) {
    this.loaderService.display(true);
    this.sharedAPI.uploadAttachment(files)
    .subscribe(
      (result) => { 
        for ( let i = 0; i < files.length; i++) {
          this.attachments.push({ 
            file: files[i],
            fileName: files[i].name,
            size: files[i].size,
            source: 'upload',
            location: result[i]
          });
        }
        this.loaderService.display(false); 
      }, 
      (error) =>  { this.loaderService.display(false); });
  }

  getTemplateAttachments() {
    this.attachments = this.attachments.filter(attachment => attachment.source != 'template');
    this.emailtemplateService.getAttachments(this.selectedTemplate.id).then(getAttachmentsResult => {
      getAttachmentsResult.emailTemplateAttachments.forEach(attachment => {
        if (attachment.languageId == this.languageId) {
          this.attachments.push({ 
            id: attachment.id,
            fileName: attachment.filename,
            size: attachment.size,
            source: 'template',
            location: attachment.attachmentPath
          });  
        }
      });
    })
  }

  getOptinStates() {
    this.externaldatasourceService.executeExternalDataSource(879, [
      this.applicationInfoService.currentAccount.id,
      this.applicationInfoService.currentContact.id
    ]).then(getOptStatesResult => { 
      this.optStatesResult = getOptStatesResult;
      this.checkDoubleOptin();
    });
  }

  checkDoubleOptin(): Promise<any> {
    return new Promise((checkDoubleOptinResolve, checkDoubleOptinReject) => {      
      this.doubleOptinMissing = true;
      this.optOutAvailable = false;
      const emailTypeLookup = this.commonService.getOptinStateForMedia({ id: 47 }, this.optStatesResult, this.applicationInfoService.currentContact);
      const allMediaTypeLookup = this.commonService.getOptinStateForMedia({ id: 51 }, this.optStatesResult, this.applicationInfoService.currentContact);
      if (emailTypeLookup.lookupTypeRefId == 1815 && emailTypeLookup.endDateInPast == false) {  this.doubleOptinMissing = false; }
      if (allMediaTypeLookup.lookupTypeRefId == 1815 && allMediaTypeLookup.endDateInPast == false) { this.doubleOptinMissing = false; }
      if (emailTypeLookup.lookupTypeRefId == 44 && emailTypeLookup.endDateInPast == false) { this.optOutAvailable = true; }
      if (allMediaTypeLookup.lookupTypeRefId == 44 && allMediaTypeLookup.endDateInPast == false) { this.optOutAvailable = true; }

      if (this.optOutAvailable) {
        this.doubleOptinMissing = true;
      }
      checkDoubleOptinResolve(null);
      this.doubleOptinMissingChecked = true;      
    });
  }

  isDoubleOptinMissing(template) {
    this.doubleOptinMissingChecked = false;
    if (template == null) {
      this.doubleOptinMissing = false;
      this.doubleOptinMissingChecked = true;
      return;
    }

    if (this.applicationInfoService.currentContact == null) {
      this.doubleOptinMissing = false;
      this.doubleOptinMissingChecked = true;
      return 
    }

    this.doubleOptinMissing = true;
    if (template.isRequireDoubleOptIn == true) {
      this.checkDoubleOptin();
    } else {
      this.doubleOptinMissing = false;
      this.doubleOptinMissingChecked = true;
    }
  }

  downloadAttachment(attachment) {
    console.warn(attachment);
    switch(attachment.source) {
      case 'contentlibrary':
        this.sharedAPI.contentLibraryDownloadFile(attachment.id, attachment.contentLibraryId).subscribe(result => {      
          let fileDetails = attachment.name.split('.');
          this.commonService.downloadFile(result, 'application/'.concat(fileDetails[fileDetails.length-1]), attachment.name);
        });    
        break;
      case 'upload':
        let fileDetails = attachment.fileName.split('.');
        const blob = new Blob([attachment.file], {type: 'application/'.concat(fileDetails[fileDetails.length-1])});
        FileSaver.saveAs(blob, attachment.fileName);
        break;
      case 'template':
        this.sharedAPI.getTemplateAttachement(attachment.id).subscribe(result => {      
          let fileDetails = attachment.fileName.split('.');
          this.commonService.downloadFile(result, 'application/'.concat(fileDetails[fileDetails.length-1]), attachment.fileName);
        });    
        break;
    }
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { isNullOrUndefined } from 'util';
import { LoaderService } from 'app/shared/service/loader-service';
import { MethodService } from 'app/jollyjupiter/service/method.service';
import { JjtranslationService } from 'app/jollyjupiter/service/jjtranslation.service';
import { Subscription } from 'rxjs';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { WizardMode, WizardService } from '../../static/wizard/wizard.service';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/authentication/services/auth.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';

@Component({
  selector: 'app-admin-entity',
  templateUrl: './admin-entity.component.html',
  styleUrls: ['./admin-entity.component.scss']
})
export class AdminEntityComponent implements OnInit, OnDestroy {
  wizardFinishedEventSubscription: Subscription = new Subscription();
  newLookupTableCreatedEventSubscription: Subscription = new Subscription();
  customEventSubscription: Subscription = new Subscription();
  lookupTableDefinitions = [];
  entities = [];
  expandedMembers = [];
  selectedEntity = null;
  entityToModifyBackup = null;
  expandedMemberTypes = [];
  currentAdditionalMemberDefinitionId = null;
  lookupEntityDynamicalTypes = null;
  translationSourceMember = 'entityMember';
  modifiedEntityMemberForNewLookupTable = null;
  filterString = '';
  projectSpecificMembers = [];
  viewMode = 'Specific';
  excludeEntity = ['lookupEntityDynamicalType'];
  projectSpecificDefinitionId = null;
  translationExcludes = ['externalDataSource', 'memberType'];
  memberAffiliationTypes = [
    { id: 1, defaultName: 'Allgemeine Member', array: 'common' },
    { id: 2, defaultName: 'Projektspezifische Member', array: 'project' }
  ];

  entityDefinitions = [];
  finalMembers = [];
  finalMembersOrig = [];
  maxMemberValue = 0;
  featureId = null;
  featureNotFound = null;

  highlightRowID=null

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    private loaderService: LoaderService,
    private eventService: EventService,
    public commonService: CommonService,
    public methodService: MethodService,
    public jjtranslationService: JjtranslationService,
    private uiService: UiService,
    private wizardService: WizardService,
    private authService: AuthService,
    public axivasTranslateService: AxivasTranslateService,
    private messagingService: MessagingService,
    private packagefeatureService: PackagefeatureService
  ) { }

  ngOnInit() {
    this.featureId = Number(this.applicationInfoService.applicationSettings['featureId_datalist']);    
    if (this.packagefeatureService.selectedPackageHasFeature(this.featureId) == false) {
      this.featureNotFound = true;
    } else {
      this.featureNotFound = false;
    }   

    this.wizardFinishedEventSubscription = this.wizardService.wizardFinishedEvent.subscribe(event => {
      console.warn('wizardFinishedEventSubscription')
      this.getEntitiesForProject(true);      
    });

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.getEntitiesForProject();
      }
    });

    this.newLookupTableCreatedEventSubscription = this.eventService.newLookupTableCreatedEvent.subscribe(event => {
      this.getLookupTables(205, 'main').then(() => {
        this.getLookupTables(193, 'data').then(() => {
          this.modifiedEntityMemberForNewLookupTable.lookupTableId = event;
        });
      });
    });
    this.getEntitiesForProject();
    this.getLookupTables(205, 'main');
    this.getLookupTables(193, 'data');         
  }

  ngOnDestroy(): void {
    if (this.wizardFinishedEventSubscription) { this.wizardFinishedEventSubscription.unsubscribe(); }
    if (this.newLookupTableCreatedEventSubscription) { this.newLookupTableCreatedEventSubscription.unsubscribe(); }
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }    
  }

  getProjectSpecificMembers(entityId) {
    this.externaldatasourceService.executeExternalDataSource(619, [entityId]).then(getProjectSpecificMembersResult => {
      // console.log('getProjectSpecificMember', getProjectSpecificMembersResult);
      this.projectSpecificMembers = getProjectSpecificMembersResult;
    })
  }

  changeEntity() {
    //console.log('this.entity', this.selectedEntity);
    this.getProjectSpecificMembers(this.selectedEntity.id);
    this.getProjectSpecificMemberDefinition();
  }

  checkIfMemberIsProjectSpecific(member) {
    let returnValue = false;
    const projectSpecificMember = this.projectSpecificMembers.find(projectSpecificMember => projectSpecificMember.id == member.id);
    if (projectSpecificMember) {
      returnValue = true;
    }
    return returnValue;
  }

  getEntitiesForProject(refreshCurrentEntity = false) {    
    this.entities = [];
    if (this.applicationInfoService.user.isExternalUser) {
      const validEntities = this.applicationInfoService.applicationSettings['externalUserEntitiesForNewMember'].split(';');
      validEntities.forEach(entity => {
        if (this.applicationInfoService.entities.Item(entity) != undefined) {
          this.entities.push(this.applicationInfoService.entities.Item(entity));
          if(this.entities.length > 0){

            this.entities = this.entities.map((item) => {
              return {
                ...item,
                displayName: item.nameTranslationToken ? item.nameTranslationToken.tokenFullName : item.entityName
              }
            })

          }
        }        
      });
      return;
    } 


    this.externaldatasourceService.executeExternalDataSource(this.applicationInfoService.
      applicationSettings['adminEntityAllEntityExternalDatasourceId'])
    .then(getEntitiesForProjectResult => {
      this.entities = getEntitiesForProjectResult;

      if(this.entities.length > 0){
        this.entities = this.entities.map((item) => {
          return {
            ...item,
            displayName: item.nameTranslationToken ? item.nameTranslationToken.tokenFullName : item.entityName
          }
        })
      }

      this.entities.forEach(entity => {
        entity.entityMembers.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
      });

      if (refreshCurrentEntity) {
        const refreshedEntity = this.entities.find(entity => entity.id == this.selectedEntity.id);
        if (refreshedEntity) {
          this.selectedEntity = refreshedEntity;
        }
      }
    })
    .catch(error => {
    });


    //console.log('entities', this.entities)
  }

  addMember(definition) {
    const newMember = new Object();
    newMember['isMandatory'] = false;
    newMember['definitionId'] = definition.id;
    newMember['defaultName'] = 'Member';
    newMember['memberType'] = 'TEXT';
    this.finalMembers.push(newMember);
    if (!this.commonService.isItemExpanded(this.expandedMemberTypes, definition.id)) {
      this.commonService.toggleItemExpanded(this.expandedMemberTypes, definition.id);
    }
  }

  getLookupTables(externalDataSourceId, source): Promise<any> {
    return new Promise((getLookupTablesResolve, getLookupTablesReject) => {
      this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [])
      .then(getLookupTablesResult => {
        this.lookupTableDefinitions[source] = getLookupTablesResult;
        getLookupTablesResolve(null);
      })
      .catch(error => {
        console.error('getLookupTables', error);
        getLookupTablesReject();
        this.loaderService.display(false, false, 'Admin entity - saveEntityMember');
      });
    });
  }

  checkIfMemberNeedsToBeHidden(member) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(member.defaultName, this.filterString)) {
      returnValue = false;
    }
    if (this.viewMode == 'Specific' && !this.checkIfMemberIsProjectSpecific(member)) {
      returnValue = true;
    }
    if (this.viewMode == 'Universal' && this.checkIfMemberIsProjectSpecific(member)) {
      returnValue = true;
    }
    return returnValue;
  }

  saveEntityMember(member) {
    this.externaldatasourceService.executeExternalDataSource(170, [this.commonService.getModifyArrayBody(member, [])]);
  }

  saveEntity(entity) {
    this.externaldatasourceService.executeExternalDataSource(168, [this.commonService.getModifyArrayBody(entity, [])]);
  }

  getControlHeight(control, substractValue = 0) {
    let tableSize = Number(this.uiService.getDesignSpanPosition(control, substractValue));
    return tableSize; 
  }

  editMember(member) {
    this.wizardService.showWizard(WizardMode.EntityMember, member);
  }

  createNewMember() {
    if (this.maxMemberValue > 0) {      
      if (this.projectSpecificMembers.length >= this.maxMemberValue) {
        this.messagingService.showDefaultInfo('', 'AdminEntity.Message.MaxMembersReached');
        return;
      }
    }

    const newMember = new Object();
    newMember['isMandatory'] = false;
    newMember['defaultName'] = '';
    newMember['memberType'] = 'TEXT';
    newMember['entity'] = this.selectedEntity;
    this.wizardService.showWizard(WizardMode.EntityMember, newMember);
  }

  getProjectSpecificMemberDefinition() {
    if (this.selectedEntity.entityEntityDefinitions) {
      this.applicationInfoService.projectSpecificDefinitionId = null;
      this.selectedEntity.entityEntityDefinitions.forEach(definition => {
        if (definition.entityDefinition.projectId == this.applicationInfoService.projectID) {
          this.applicationInfoService.projectSpecificDefinitionId = definition.entityDefinition.id;        
        }
      });
    }
  }

  entityIsLocked(entity) {
    let returnValue = false;
    if (!entity.isAvailableForUser && !this.applicationInfoService.isDeveloper) {
      returnValue = true;
    }
    return returnValue;    
  } 

  refreshEntityCache() {
    this.authService.AuthGet(environment.apiData + '/AdminRefreshRepositoryCache/refresh/entity/Entity/0').subscribe();
  }

  getMaxEntityMember() {
    if (this.selectedEntity == null) { 
      this.maxMemberValue = 0;
      return; 
    }
    this.maxMemberValue = this.packagefeatureService.getMaxMemberForEntity(this.selectedEntity.id);
  }

  getMaxEntityMemberText() {
    const maxValue = this.getMaxEntityMember();
    if (this.maxMemberValue > 0) {
      return this.axivasTranslateService.getTranslationTextForToken('AdminEntity.Label.MaxEntityMemberValue')
        .replace('<0>', this.projectSpecificMembers.length.toString())
        .replace('<1>', this.maxMemberValue.toString());
    }
  }

  insertDisplayName(array){
    return array.map((item) =>{
      return{
        ...item,
        displayName: item.nameTranslationToken ? item.nameTranslationToken.tokenFullName : item.entityName
      }
    })
  }


  highlighRow(id){
    this.highlightRowID = id;
  }

  showEntityEditor() {
    this.eventService.showJjPopup('AdminEntity.Label.NewEntity', 'newentitymember', '90');
  }
}

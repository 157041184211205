import { Component, OnInit } from '@angular/core';
import { LicensingService } from '../licensing.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-licensing-package-upgrade',
  templateUrl: './licensing-package-upgrade.component.html',
  styleUrl: './licensing-package-upgrade.component.scss'
})

export class LicensingPackageUpgradeComponent implements OnInit {
  packagesSales = [];
  packagesMarketing = [];
  packagesWebhub = [];
  license = null;

  constructor(
    public licensingService: LicensingService,
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private packagefeatureService: PackagefeatureService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {    
    // this.packagesSales = JSON.parse(this.applicationInfoService.applicationSettings['packagesSales']);
    this.licensingService.getAllPackagePrices().then(getAllPackagePricesResult => {
      this.packagesSales = [];
      this.addPackageItem(this.packagesSales, getAllPackagePricesResult.find(item => item.id == 1));
      this.addPackageItem(this.packagesSales, getAllPackagePricesResult.find(item => item.id == 2));
      this.addPackageItem(this.packagesSales, getAllPackagePricesResult.find(item => item.id == 3));  

      this.packagesMarketing = [];
      this.addPackageItem(this.packagesMarketing, getAllPackagePricesResult.find(item => item.id == 7));
      this.addPackageItem(this.packagesMarketing, getAllPackagePricesResult.find(item => item.id == 6));
      this.addPackageItem(this.packagesMarketing, getAllPackagePricesResult.find(item => item.id == 5));  

      this.packagesWebhub = [];
      this.addPackageItem(this.packagesWebhub, getAllPackagePricesResult.find(item => item.id == 10));
      this.addPackageItem(this.packagesWebhub, getAllPackagePricesResult.find(item => item.id == 9));
      this.addPackageItem(this.packagesWebhub, getAllPackagePricesResult.find(item => item.id == 8));  

    })
    this.getLicense();
  }

  addPackageItem(array, packageItem) {    
    if (this.commonService.isNullOrUndefined(packageItem.settings)) {
      packageItem.settingJson = {};
    } else {
      packageItem.settingJson = JSON.parse(packageItem.settings);
    }
    array.push(packageItem);
  }

  getLicense() {
    this.licensingService.getLicenses();
    this.licensingService.getLicensePaymentDetails(this.applicationInfoService.projectInfo.packageId, 1).then(getLicenseCostResult => {
      this.license = getLicenseCostResult;
    });
  }

  addLicenses() {
    this.applicationInfoService.miscSettings['popupFullSize'] = true;
    this.applicationInfoService.miscSettings['licensingAdduserMode'] = 2;
    this.eventService.showJjPopup('Licensing.Label.AddUserLicenseHeader', 'licenseadduser', this.licensingService.mode2Size);  
  }

  cancelSubscription() {
    this.licensingService.cancelSubscription();
  }
}

import { NgxEchartsModule } from 'ngx-echarts';
// import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApolloModule } from 'apollo-angular';

import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { UserService } from './core/authentication/services/user.service';

import { ConfirmationService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';

// import { NgxPermissionsModule } from 'ngx-permissions';
import { LoaderService } from './shared/service/loader-service';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';

import { CustomTranslateLoader } from './shared/translation/translationCustomLoader';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SettingsService } from './shared/service/settings.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { MasterPageService } from './shared/service/masterpage.service';
import { NotificationsService } from './core/notifications.service';
import { LanguageService } from './shared/translation/language.service';
import { SharedAPI } from './shared/service/sharedAPI';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { JollyJupiterModule } from './jollyjupiter/jollyjupiter.module';
import { DashboardComponent } from './shared/dashboard/dashboard.component';
import { GraphQLModule } from './graphql.module';
import { DatePipe } from '@angular/common';
import { ToplevelHeaderComponent } from './shared/header/toplevel-header/toplevel-header.component';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';
import { AjandoHeaderComponent } from './shared/header/ajando-header/ajando-header.component';
import { NavigationComponent } from './shared/navigation/navigation/navigation.component';
import { MaterialModule } from './material/material.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SoftphoneComponent } from './jollyjupiter/controls/static/softphone/softphone.component';
import { SplashscreenComponent } from './jollyjupiter/controls/static/splashscreen/splashscreen.component';

import { TokenResolverService } from './cognito/token-resolver.service';
import { MatTableResponsiveModule } from './jollyjupiter/controls/custom/custom-mattable/mat-table-responsive.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SoftphoneRingcentralwebrtcComponent } from './jollyjupiter/controls/static/softphone-ringcentralwebrtc/softphone-ringcentralwebrtc.component';
import { SoftphoneRingcentralwebrtcExtensionComponent } from './jollyjupiter/controls/static/softphone-ringcentralwebrtc/softphone-ringcentralwebrtc-extension/softphone-ringcentralwebrtc-extension.component';
import { NavigationitemComponent } from './shared/navigation/navigationitem/navigationitem.component';
import { PhoneRingcentralComponent } from './jollyjupiter/controls/static/phone-ringcentral/phone-ringcentral.component';
import { PhoneDashboardComponent } from './jollyjupiter/controls/static/phone-ringcentral/phone-dashboard/phone-dashboard.component';
import { PhoneRingcentralWidgetComponent } from './jollyjupiter/controls/static/phone-ringcentral/phone-widget/phone-ringcentral-widget.component';
import { MainscreenComponent } from './jollyjupiter/controls/static/mainscreen/mainscreen.component';
import { NgxPhosphorIconsModule } from 'ngx-phosphor-icons';
import { ToolbarComponent } from './jollyjupiter/controls/static/toolbar/toolbar.component';
import { ToolbarHeaderComponent } from './jollyjupiter/controls/static/toolbar/toolbar-header/toolbar-header.component';
import { JjIconsComponent } from './jollyjupiter/controls/jj-icons/jj-icons.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { MainButtonComponent } from './jollyjupiter/controls/shared/main-button/main-button.component';
import { Swipe } from 'hammerjs';
import { ApplicationInfoService } from './core/application/application-info.service';
import { PackagefeatureService } from './jollyjupiter/service/packagefeature.service';
import { NavigationShortcutsComponent } from './shared/navigation/navigation-shortcuts/navigation-shortcuts.component';
import { MobileHeaderComponent } from './shared/header/mobile-header/mobile-header.component';
import { EventbookingbatchService } from './jollyjupiter/controls/static/eventbookingbatch/eventbookingbatch.service';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AjandoHeaderComponent,
    MobileHeaderComponent,
    FooterComponent,
    ConfirmDialogComponent,
    DashboardComponent,
    ToplevelHeaderComponent,
    NavigationComponent,
    NavigationShortcutsComponent,
    NavigationitemComponent,
    SoftphoneComponent,
    SoftphoneRingcentralwebrtcComponent,
    SoftphoneRingcentralwebrtcExtensionComponent,
    SplashscreenComponent,
    PhoneRingcentralComponent,
    PhoneDashboardComponent,
    PhoneRingcentralWidgetComponent,
    MainscreenComponent,
    ToolbarHeaderComponent,
  ],
  imports: [
    AngularSvgIconModule.forRoot(),
    BrowserModule,
    MatTableResponsiveModule,
    BrowserAnimationsModule,
    JollyJupiterModule,
    SharedModule,
    MaterialModule,
    CoreModule,
    ApolloModule,
    DialogModule,
    CalendarModule,
    ConfirmDialogModule,
    BrowserAnimationsModule,
    AppRoutes,
    ToastModule,
    FormsModule,
    NgxWebstorageModule.forRoot(),
    // NgxPermissionsModule.forRoot(),
    // TranslateModule.forRoot({
    //   provide: TranslateLoader,
    //   useClass: CustomTranslateLoader
    // }),
    HttpClientModule,
    GraphQLModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxPhosphorIconsModule,
    NgxTippyModule,

    // NgxEchartsModule.forRoot({echarts: chartModule})
  ],
  exports: [
    // TranslateModule,
    MaterialModule,
    MatTableResponsiveModule,


  ],
  providers: [
    LoaderService,
    HttpClient,
    // CookieService,
    ConfirmationService,
    SettingsService,
    MasterPageService,
    LanguageService,
    SharedAPI,
    DatePipe,
    NotificationsService,
    UserService,
    ApplicationInfoService,
    { provide: LOCALE_ID, useValue: 'de-DE' },
    // { provide: CookieOptions, useValue: {} },
    // {
    //     provide: APP_INITIALIZER,
    //     useFactory: AuthInitializer,
    //     deps: [AuthService],
    //     multi: true
    // },
    TokenResolverService,
    CustomTranslateLoader
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="licensingUserlistWrapper">
    <app-layout header="Licensing.Label.UserOverview">
        <div class="licensingUserlistContent">
            <div class="licensingUserlistButtons">
                <app-main-button svg="user" type="small" (onClick)="addUser()" label="Licensing.Label.AddUser"></app-main-button>
                <app-main-button svg="identification-card" type="borderSmall" (onClick)="addLicense()" label="Licensing.Label.AddLicense"></app-main-button>
            </div>
        
            <div class="licensingUserlistHeader">
                <div class="licensingUserlistHeader__item licensingUserlistHeader__border">
                    <div class="licensingUserlistHeader__item__label">{{ 'Licensing.Label.LicenseAmount' | texttransform }}</div>
                    <div class="licensingUserlistHeader__item__value">{{ licensingService.licenseInfo.licenseCount }}</div>
                </div>
                <div class="licensingUserlistHeader__item licensingUserlistHeader__border">
                    <div class="licensingUserlistHeader__item__label">{{ 'Licensing.Label.LicenseUsed' | texttransform }}</div>
                    <div class="licensingUserlistHeader__item__value">{{ licensingService.licenseInfo.usedLicenseCount }}</div>
                </div>
                <div class="licensingUserlistHeader__item">
                    <div class="licensingUserlistHeader__item__label">{{ 'Licensing.Label.LicenseFree' | texttransform }}</div>
                    <div class="licensingUserlistHeader__item__value">{{ licensingService.licenseInfo.freeLicenses }}</div>
                </div>
            </div>
            <div class="licensingUserlistTable">
                <div [innerHTML]="innerHTML" class="everleadMaxHeightOverflow"></div>
            </div>
        </div>
    </app-layout>
</div>
<!-- <div #PowerButtonContainer class="powerButtonContainer powerButtonWrapper">
  <label *ngIf="powerButtons.length == 0 && loadingControlData == false">
    {{ 'PowerButton.Label.NoPowerButtonsAvailable' | texttransform }}
  </label>
  <label *ngIf="loadingControlData == true">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
  </label>
  <label *ngIf="applicationInfoService.accountLocked && loadingControlData == false" class="everleadCoral">
    {{ 'PowerButton.Label.AccountIsLocked' | texttransform }}
  </label>
  <span *ngIf="powerButtons.length > 0 && loadingControlData == false && !applicationInfoService.accountLocked">
    <label *ngFor="let powerButton of powerButtons" (click)="callPowerButton(powerButton.id)"
      class="powerButtonStyle fingerCursor" [ngClass]="{
        'powerButtonPositiveResult': powerButton.result==1,
        'powerButtonNegativeResult': powerButton.result==0,
        powerButtonNew: applicationInfoService.useNewDesign
      }" [class]="getPowerButtonClasses()"
      title="{{ powerButton.name | texttransform }}">
      <app-status [green]="powerButton.result ==1" [red]="powerButton.result==0" [width]="20" [height]="20"></app-status>
      {{ powerButton.name | texttransform }}
    </label>
  </span>
</div> -->

<div #PowerButtonContainer class="newPowerButtonContainer">
  <label *ngIf="powerButtons.length == 0 && loadingControlData == false">
    {{ 'PowerButton.Label.NoPowerButtonsAvailable' | texttransform }}
  </label>
  <label *ngIf="loadingControlData == true">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
  </label>
  <label *ngIf="applicationInfoService.accountLocked && loadingControlData == false" class="everleadCoral">
    {{ 'PowerButton.Label.AccountIsLocked' | texttransform }}
  </label>
  <span *ngIf="powerButtons.length > 0 && loadingControlData == false && !applicationInfoService.accountLocked">
    <label class="newPowerButtonContainer__row" *ngFor="let powerButton of powerButtons" (click)="callPowerButton(powerButton.id)" title="{{ powerButton.name | texttransform }}">
      <app-status [green]="powerButton.result ==1" [red]="powerButton.result==0" [width]="20" [height]="20"></app-status>
      <span>{{ powerButton.name | texttransform }}</span>
    </label>
  </span>
</div>
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApplicationInfoService } from '../../../../core/application/application-info.service';
import { GraphQLService, ApolloMethod } from '../../../../shared/service/graphql.service';
import { Table } from 'primeng/table';
import { EventService } from '../../../../jollyjupiter/service/event.service';
import { EntityService } from '../../../../jollyjupiter/service/entity.service';
import { Subscription } from 'rxjs';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { ExternaldatasourceService, ExternalDataSource_QueryType } from 'app/jollyjupiter/service/externaldatasource.service';
import { DragDropelements, UiService } from 'app/jollyjupiter/service/ui.service';
import { DragdropService } from 'app/jollyjupiter/service/dragdrop.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/authentication/services/auth.service';

@Component({
  selector: 'app-projectusers',
  templateUrl: './projectusers.component.html',
  styleUrls: ['./projectusers.component.scss']
})

export class ProjectusersComponent implements OnInit, OnDestroy {
  updateDropDownListSubscription: Subscription = new Subscription();
  externalUserCreatedEventSubscription: Subscription = new Subscription();
  customEventSubscription: Subscription = new Subscription();
  availableUsers = [];
  assignedUsers = [];
  filterString = '';
  filterStringAssigned = '';
  userRoleSection = '1';
  automaticRecordAssignments = [];
  automaticRecordAssignmentsSelected = [];
  dashboards = []
  dashboardsSelected = [];
  userRights = [];
  userRightsSelected = [];

  public dragdropElements: DragDropelements[] = [];
  public orginalOffsetForButton: number = null;
  projectData: any[] = [];
  roleData: any[] = [];
  userRoleSections = [
    { id: 1, defaultName: 'General.User.UserRoleSectionUser' },
    { id: 2, defaultName: 'General.User.UserRoleSectionRecordAssignment' },
    { id: 3, defaultName: 'General.User.UserRoleSectionRights' },
    { id: 3, defaultName: 'General.User.UserRoleSectionDashboards' },
    { id: 5, defaultName: 'General.User.UserRoleSectionDataRights' },   
  ]
  dropDownProjectSelection: any;
  dropDownRoleSelection: any;

  @ViewChild(Table) dt: Table;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private graphQLService: GraphQLService,
    private eventService: EventService,
    private entityService: EntityService,
    private axivasTranslateService: AxivasTranslateService,
    public dragdropService: DragdropService,
    private loaderService: LoaderService,
    private graphqlqueryService: GraphqlqueryService,
    private externaldatasourceService: ExternaldatasourceService,
    public commonService: CommonService,
    public uiService: UiService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.dragdropElements.push(new DragDropelements());
    this.dragdropElements.push(new DragDropelements());
    this.getAvailableProjects();
    this.getAvailableUsers();
    
    this.updateDropDownListSubscription = this.eventService.updateDropDownListEvent.subscribe(event => {
      if (event.target === 'projectDropDown') {
        this.getAvailableProjects(false);
      }
    });

    this.externalUserCreatedEventSubscription = this.eventService.externalUserCreatedEvent.subscribe(event => {
      this.changeUserTypeDropdownSelectionValue()
    });

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.getAvailableRoles();
      }      
    });
  }

  ngOnDestroy() {
    if (this.externalUserCreatedEventSubscription) { this.externalUserCreatedEventSubscription.unsubscribe(); }
    if (this.updateDropDownListSubscription) { this.updateDropDownListSubscription.unsubscribe(); }
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }    
  }


  isFilterActive(checkString, filterString) {
    return !this.commonService.checkIfStringContainsString(checkString, filterString);
  }
  
  addUser(user) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(527, [user.id, this.dropDownProjectSelection, this.dropDownRoleSelection])
    .then(() => {
      this.refreshUserProjectCache();
      this.getAssignedUsers();
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  refreshUserProjectCache() {
    this.authService.AuthGet(environment.apiUrl + '/AdminRefreshRepositoryCache/refresh/entity/UserProject/0').subscribe();
  }

  removeUser(user) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(528, [user.id])
    .then(() => {
      this.getAssignedUsers();
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }

  checkIfAlreadyAssigned(user) {
    return this.assignedUsers.some(userToCheck => userToCheck.userId === user.id); 
  }

  changeProjectDropdownSelectionValue() {
      this.getAvailableRoles();
      this.dropDownRoleSelection = null;
      this.assignedUsers = [];
  }

  changeUserTypeDropdownSelectionValue() {
    this.getAssignedUsers();
    this.getSubitemInfoBySection();
  }

  getAvailableRoles() {
    this.roleData = [];
    this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.projectUsersRoles)
    .then(result => {      
      this.roleData = [];
      let rolesToModify = result.data.roles.filter(role => 
        role.projectId == null || 
        role.projectId == this.applicationInfoService.projectID
      );
      rolesToModify.forEach(role => {
        if (!this.isRoleHidden(role)) {
          this.roleData.push(role);
        }
      });
    })
    .catch(error => { console.error(error); });
  }

   getAvailableUsers() {
    this.loaderService.display(true);
    let externalDataSourceId = 99;
    if (this.applicationInfoService.user.isExternalUser) {
      externalDataSourceId = 139;
    }

    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.user.customerId)) {
      externalDataSourceId = 961;
    }
    this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [this.applicationInfoService.user.customerId])
    .then(getAvailableUsersResult => {
      getAvailableUsersResult.sort((a, b) => a.userName < b.userName ? -1 : 1);
      this.availableUsers = getAvailableUsersResult;
      if (this.applicationInfoService.user.isExternalUser == true) {
        this.availableUsers = this.commonService.getUserListForExternalUser(this.availableUsers);
      }
      this.loaderService.display(false);
    })
    .catch(error => {
      console.error(error);
      this.loaderService.display(false);
     });
  }

  getAssignedUsers(): Promise<void> {
   /*   console.log('called');
     console.log('this. project Selection', this.dropDownProjectSelection);
     console.log('this. Role Selection', this.dropDownRoleSelection); */

    return new Promise<void>(resolve => {
    let query: string = this.graphqlqueryService.projectUsersUserProject;
    query = query.replace('<projectID>', this.dropDownProjectSelection);
    query = query.replace('<roleID>', this.dropDownRoleSelection);
    this.loaderService.display(true);
    this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, query)
    .then(result => {
      this.loaderService.display(false);
      this.assignedUsers = result.data.userProject;
      resolve();
    })
    .catch(error => { console.error(error); this.loaderService.display(false); });
    });
  }

  getAvailableProjects(updateAvailableRoles: boolean = true) {
    this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.projectUsersProjects)
    .then(result => {
      const results = result.data.projects;
      this.dragdropService.sortArray(results, 'projectName');
      this.projectData = result.data.projects;
      this.projectData.forEach(element => {
      if (element.id === this.applicationInfoService.projectID) {
        this.dropDownProjectSelection = element.id;
          if (updateAvailableRoles) {
            this.getAvailableRoles();
          }
        }
      });
    }).catch(error => {
      console.error('getAvailableProjects error', error);
    });
  }

  createExternalUser() {
    this.applicationInfoService.miscSettings['newExternalUSerGroup'] = this.dropDownRoleSelection;
    this.eventService.showJjPopup('ProjectUsers.Label.CreateExternalUser', 'externalusers', 60, true);
  }
  
  isRoleHidden(role) {     
    if (this.applicationInfoService.currentUserRoleId == 4) {
      return false;
    }
    const myRole = this.applicationInfoService.securityRoles.find(securityRole => securityRole.id == this.applicationInfoService.currentUserRoleId);
    if (myRole) {
      if (role.permissionLevel > myRole.permissionLevel) {
        return true;
      } else {
        return false;
      }  
    } else {
      return true;
    }
  }

  // Subitems
  getSubitemInfoBySection() {
    switch (this.userRoleSection) {
      case '2': // assignment
        this.getAutomaticRecordAssignments();
      break;
      case '3': // rights
        
      break;
      case '4': // dashboard
        this.getDashboards();
      break;
      case '5': // dashboard
        this.getUserRights();
      break;
    }
  }

  //#region Automatic record assignments
  isActiveAssignment(automaticRecordAssignment) {
    const selectedAssignment = this.automaticRecordAssignmentsSelected.find(item => item.followupUserGroupId == automaticRecordAssignment.id);
    if (selectedAssignment) {
      return true;
    } else {
      return false;
    }
  }

  getAutomaticRecordAssignments() {
    this.externaldatasourceService.executeExternalDataSource(733, [this.dropDownProjectSelection])
    .then(getAutomaticRecordAssignmentsResult => {
      this.automaticRecordAssignments = getAutomaticRecordAssignmentsResult;
      this.getSelectedAssignments();
    });  
  }

  getSelectedAssignments() {
    this.externaldatasourceService.executeExternalDataSource(734).then(getSelectedAssignmentsResult => {
      this.automaticRecordAssignmentsSelected = getSelectedAssignmentsResult;
    });  
  }

  toggleAssignment(automaticRecordAssignment) {
    const selectedAssignment = this.automaticRecordAssignmentsSelected.find(item => item.followupUserGroupId == automaticRecordAssignment.id);
    if (selectedAssignment) {
      this.externaldatasourceService.executeExternalDataSource(736, [selectedAssignment.id])
        .then(() => { this.getSelectedAssignments(); })      
    } else {
      this.externaldatasourceService.executeExternalDataSource(735, [this.dropDownRoleSelection, automaticRecordAssignment.id])
        .then(() => { this.getSelectedAssignments(); })
    }
  }
  //#endregion

  //#region Dashboards  
  getDashboards() {
    this.externaldatasourceService.executeExternalDataSource(737).then(getDashboardsResult => {
      this.dashboards = getDashboardsResult.filter(dashboard => dashboard.projectId == this.dropDownProjectSelection);
      this.getSelectedDashboards();
    });  
  }

  getSelectedDashboards() {
    this.externaldatasourceService.executeExternalDataSource(738).then(getSelectedDashboardsResult => {
      this.dashboardsSelected = getSelectedDashboardsResult;
    });  
  }

  
  toggleDashboard(dashboard) {
    const selectedDashboard = this.dashboardsSelected.find(item => item.dashboardId == dashboard.id && item.roleId == this.dropDownRoleSelection);
    if (selectedDashboard) {
      this.externaldatasourceService.executeExternalDataSource(739, [selectedDashboard.id])
        .then(() => { this.getSelectedDashboards(); })      
    } else {
      this.externaldatasourceService.executeExternalDataSource(740, [this.dropDownRoleSelection, dashboard.id])
        .then(() => { this.getSelectedDashboards(); })
    }
  }

  isActiveDashboard(dashboard) {
    const selectedDashboard = this.dashboardsSelected.find(item => item.dashboardId == dashboard.id && item.roleId == this.dropDownRoleSelection);
    if (selectedDashboard) {
      return true;
    } else {
      return false;
    }
  }
  //#endregion

  //#region UserRights
  getUserRights() {
    this.externaldatasourceService.executeExternalDataSource(572).then(getUserRightsResult => {
      this.userRights = getUserRightsResult;
      this.getSelectedUserRights();
    });  
  }

  getSelectedUserRights() {
    this.externaldatasourceService.executeExternalDataSource(741).then(getSelectedUserRightsResult => {
      this.userRightsSelected = getSelectedUserRightsResult;
    });  
  }

  
  toggleUserRight(userRight) {
    const selectedUserRight = this.userRightsSelected.find(item => item.dataRightsGroupId == userRight.id);
    if (selectedUserRight) {
      this.externaldatasourceService.executeExternalDataSource(742, [selectedUserRight.id])
        .then(() => { this.getSelectedUserRights(); })      
    } else {
      this.externaldatasourceService.executeExternalDataSource(743, [this.dropDownRoleSelection, userRight.id])
        .then(() => { this.getSelectedUserRights(); })
    }
  }

  isActiveUserRight(userRight) {
    const selectedUserRight = this.userRightsSelected.find(item => item.dataRightsGroupId == userRight.id);
    if (selectedUserRight) {
      return true;
    } else {
      return false;
    }
  }
  //#endregion

}

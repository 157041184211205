import { Component } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DashboardcoreService } from '../../dashboardcore.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MethodService } from 'app/jollyjupiter/service/method.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-defaultdashboard-consent',
  templateUrl: './defaultdashboard-consent.component.html',
  styleUrl: './defaultdashboard-consent.component.scss'
})
export class DefaultdashboardConsentComponent {
  customEventSubscription: Subscription = new Subscription()
  dashboardId = 21;
  loadingData = true;
  dashboardResultData = [];
  isGroupByEntrySource = true;
  createdDateUntil = new Date(Date.now());
  createdDateFrom = new Date(Date.now());
  mediaArray = [];
  pools = [];
  poolId = 840;
  campaigns = [];
  campaignId = null;
  userId = 0;
  users = [];
  languageId = 2;
  stepId = 0;
  referenceValue = 'medium';
  viewChart = true;
  sortArrayId = 1;
  sortArray = [
    { id: 1, displayName: 'Defaultdashboard.Label.Medium' },
    { id: 2, displayName: 'Defaultdashboard.Label.Typ' },
    { id: 3, displayName: 'Defaultdashboard.Label.Source' },
  ];
  isGroupByEntrySourceArray = [
    { id: 1, displayName: 'Message.General.Yes' },
    { id: 2, displayName: 'Message.General.No' }
  ]
  isGroupByEntrySourceSelect = 2;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private dashboardcoreService: DashboardcoreService,
    private commonService: CommonService,
    private methodService: MethodService,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'getCompleteProjectInformaton') {
        this.getCampaigns();
        this.getUsers();
      }
    });

    this.poolId = this.applicationInfoService.poolId;

    this.createdDateFrom.setDate(this.createdDateFrom.getDate() -7);
    this.getDashboardDataArray(this.dashboardId);
    this.getCampaigns();
    this.getPools();
    this.getUsers();
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  getUsers() {
    this.users = this.applicationInfoService.projectUsers.toArray();
  }

  getCampaigns() {
    this.campaigns = this.applicationInfoService.campaigns.toArray().filter(campaigns => campaigns.isActive == true);
  }

  getDashboardDataArray(dashboardId) {
    switch (this.sortArrayId) {
      case 1:
        this.referenceValue = 'medium';
        break;
      case 2:
        this.referenceValue = 'typ';
        break;
      case 3:
        this.referenceValue = 'entry_source';
        break;        
    }
    let params = this.fillParams();
    this.loadingData = true;
    this.dashboardResultData = null;
    this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(getDashboardDataArrayResult => {      
      this.loadingData = false;
      this.dashboardResultData = null;      
      if (getDashboardDataArrayResult) {
        if (getDashboardDataArrayResult[0]) {
          this.dashboardResultData = JSON.parse(JSON.stringify(getDashboardDataArrayResult[0]));      
          this.mediaArray = [...new Set(this.dashboardResultData.map(item => item[this.referenceValue]))];
          const sumArray = [];
          this.mediaArray.forEach(media => {
            sumArray.push(this.getMediaDataArray(media));
          });
          this.getSumsOfDifferentArrays('typ', 'count', sumArray);
        }
      }
    }).catch((error)=>{
      this.loadingData = false;
    });
  }

  fillParams() {
    const params = [];
    this.isGroupByEntrySource = true;
    if (this.isGroupByEntrySourceSelect == 2) {
      this.isGroupByEntrySource = false;
    }
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@pool_id', value: this.poolId });
    params.push({ key: '@campaign_id', value: this.campaignId });
    params.push({ key: '@language_id', value: this.languageId });
    params.push({ key: '@step_id', value: this.stepId });
    params.push({ key: '@user_id', value: this.userId });
    params.push({ key: '@is_group_by_entry_source', value: this.isGroupByEntrySource });
    params.push({ key: '@created_date_from', value: this.commonService.getDateString(this.createdDateFrom) });
    params.push({ key: '@created_date_until', value: this.commonService.getDateString(this.createdDateUntil) });
    return params;
  }

  getPools() {
    this.pools = this.applicationInfoService.pools.toArray().filter(pool => pool.isActive == true);
  }

  getMediaDataArray(mediaArrayItem, reverse = false) {
    let dataArray = this.dashboardResultData.filter(item => item[this.referenceValue] == mediaArrayItem);
    if (reverse) {
      dataArray.reverse();
    }
    return dataArray;
  }

  getSumsOfDifferentArrays(referenceValue, countValue, arrayArray) {
    const returnValue = [];
    const references = [];

    // Referenzen ermitteln
    arrayArray.forEach(array => {
      array.forEach(arrayItem => {
        if (!this.commonService.checkIfItemIsInArray(references, arrayItem[referenceValue])) {
          references.push(arrayItem[referenceValue]);
        }  
      })
    });
    console.warn('references', references);

    // Nun wird gezählt
    references.forEach(reference => {
      let referenceCount = 0;
      arrayArray.forEach(array => {
        array.forEach(arrayItem => {
          if (arrayItem[referenceValue] == reference) {
            referenceCount = referenceCount + arrayItem[countValue];
          }
        });
      });
      returnValue.push({ 
        referenceValue: reference,
        count: referenceCount
      });      
    });
    console.warn('references', returnValue);
    return returnValue;
  }

  getChartHeight(array) {
    if (array == null) {
      return 60;
    } else {
      return 80 + (array.length * 40);
    }
  }
}
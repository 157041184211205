import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-lead-scoringchart',
  templateUrl: './lead-scoringchart.component.html',
  styleUrl: './lead-scoringchart.component.scss'
})
export class LeadScoringchartComponent implements OnInit , OnChanges{
  
  constructor(
    private axivasTranslateService: AxivasTranslateService,
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private commonService: CommonService,){}


  @Input() controlid: string = "";
  @Input() fontColor: string = '#5465FF';

  // * X - Axis
  @Input() showXaxis:boolean = true;
  @Input() xAxisType;
  @Input() xAxisLabel = {};
  
  // * Y - Axis
  @Input() showYaxis:boolean = true;
  @Input() yAxisType;
  @Input() yAxisLabel= {};

  // * Bars Chart basic config
  @Input() barWidth: number
  @Input() barMaxWidth: number;
  @Input() barGap: number = 0;

  @Input() legend: boolean;
  @Input() legendPosition: {};

  @Input() height: any;
  @Input() width: any;


  @Input() chartData: any;
  
  dataAvailable: boolean = false
  options;


  ngOnInit(): void {
   this.createChart();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData'] && !changes['chartData'].firstChange) {
      this.createChart();
    }
  }


  createChart(){
    if (this.chartData.data.length > 0) {
      this.dataAvailable = true
    } else {
      this.dataAvailable = false
      return;
    }
  
    this.options={
      textStyle: {
        fontFamily:'Poppins',
        color: this.fontColor,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
      grid:{
        top:0,
      },
      legend:{
        ...this.legendPosition,
          textStyle: {
            fontFamily: 'Poppins',
            fontSize: 12,
            color: this.fontColor,
          },
          itemStyle:{
            borderColor:'transparent',
          }
      },
      yAxis:{
        show: this.showXaxis,
        type:'category',
        data:this.chartData.data.map((item) => this.getTranslationValue(item.name)),
        axisLabel: {
          ...this.xAxisLabel,
          color: this.fontColor,
        },
        axisLine: {
          ...this.yAxisLabel,
          lineStyle: { color: this.fontColor}
        },
      },
  
      xAxis:{
        show: this.showYaxis,
        type:'value',
      },
  
      series:[{
        data:this.chartData.data.map((item) => {
          return {
            type:'bar',
            name: this.getTranslationValue(item.name),
            value: item.value,
            itemStyle:{
              color: item.color,
            },
          }
        }),
        type:'bar',
        barWidth: this.barWidth ? `${this.barWidth}%` : '10%',
        barMaxWidth: this.barMaxWidth ? `${this.barMaxWidth}%` : `${this.barWidth}%`,
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'right',
          distance: 22,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 0,
          fontSize: 15,
          color: this.fontColor,
          rich: {
            name: {}
          },
        }
      }]
   
  }
  }

  getTranslationValue(name){
    return this.axivasTranslateService.getTranslationTextForToken(name)
  }
  

  getHeight() {
    if (isNaN(this.height)) {
      return this.height;
    } else {
      return this.height.toString().concat('px');
    }
  }

  getWidth() {
    if (isNaN(this.width)) {
      return this.width;
    } else {
      return this.width.toString().concat('px');
    }
  }

}

<div class="eventBookingBatch">
    <div class="eventBookingBatch__header">
        <app-component-header [header]="applicationInfoService.breadCrumbText"></app-component-header>

        <div class="eventBookingBatch__nav">
            <app-main-button
            svg="crosshair"
            type="small"
            (onClick)="createNewBookingBatch()"
            [label]="batchTypeLookupId == 7422 ? 'EventBookingBatch.Label.NewMicroCampaign' : 'EventBookingBatch.Label.NewMailShot'"></app-main-button>

        <div class="filter">
            <div class="filterWrapper">
                <input [(ngModel)]="filterString">
                <app-jj-icons type="magnifying-glass" [width]="20" [height]="20"></app-jj-icons>
            </div>
        </div>

        </div>
    </div>

    <div class="eventBookingBatch__inner">
        
                  <!-- MicroCampaigns -->
            <div *ngIf="batchTypeLookupId == 7422" class="eventBookingBatch__list">
                <div *ngFor="let eventBookingBatch of eventBookingBatches" class="eventBookingBatch__Item everleadShadowBox everleadShadowBox2"
                    [ngClass]="{
                        hideInDom: isFiltered(eventBookingBatch),
                        fingerCursor: eventBookingBatch.startedAt == null
                    }" (click)="editMicroCampaign(eventBookingBatch)">
                    <div class="eventBookingBatch__Item__id everleadCoral">{{ eventBookingBatch.id }}</div>
                    <div class="eventBookingBatch__Item__name">{{ eventBookingBatch.batchName }}</div>
                    <div class="eventBookingBatch__Item__buttons">
                        <i class="fas fa-stop fingerCursor" [ngClass]="{
                            eventBookingBatchItemDisabled: eventBookingBatch.startedAt == null || eventBookingBatch.stoppedAt != null
                        }" (click)="eventbookingbatchService.stopEventBookingBatch(eventBookingBatch);$event.stopPropagation()"></i>            
                        <i class="fas fa-play fingerCursor" [ngClass]="{
                            eventBookingBatchItemDisabled: eventBookingBatch.startedAt != null
                        }" (click)="eventbookingbatchService.startEventBookingBatch(eventBookingBatch);$event.stopPropagation()"></i>
                    </div>
                </div>    
            </div>

                <!-- E-Mail batch -->
            <div *ngIf="batchTypeLookupId == 7421" class="eventBookingBatch__list">
                <div class="eventBookingBatch__Item eventBookingBatchItem__small">
                    <div class="eventBookingBatch__Item__icon headerCell"></div>
                    <div class="eventBookingBatch__Item__name headerCell">{{ 'EventBookingBatch.Label.Name' | texttransform }}</div>
                    <div class="eventBookingBatch__Item__date headerCell">{{ 'EventBookingBatch.Label.SendDate' | texttransform }}</div>
                    <div class="eventBookingBatch__Item__buttons headerCell"></div>
                </div>
                <div class="eventBookingBatch__body">
                    <div *ngFor="let eventBookingBatch of eventBookingBatches" class="eventBookingBatch__Item highlightRow"
                    [ngClass]="{
                        hideInDom: isFiltered(eventBookingBatch),
                        fingerCursor: eventBookingBatch.startedAt == null
                    }" (click)="editMailshot(eventBookingBatch)">
                    <!-- <div class="eventBookingBatchItem__id everleadCoral">{{ eventBookingBatch.id }}</div> -->
                    <div class="eventBookingBatch__Item__icon">
                        <app-jj-icons type="newspaper-clipping" [tippy]="'NewsLetter'" [width]="25" [height]="25" *ngIf="eventBookingBatch.wizardValueArray.newsletter == true"></app-jj-icons>
                        <app-jj-icons type="crosshair"[tippy]="'Mailshot'" [width]="25" [height]="25"  *ngIf="eventBookingBatch.wizardValueArray.newsletter != true" ></app-jj-icons>
                    </div>
                    <div class="eventBookingBatch__Item__name">{{ eventBookingBatch.batchName }}</div>
                    <div class="eventBookingBatch__Item__date" *ngIf="eventBookingBatch.status == 9139">
                        <app-date-input
                        [(ngModel)]="eventBookingBatch.wizardValueArray.followUpDate"
                        (dateChange)="changeFollowUpDate(eventBookingBatch)"
                        ></app-date-input>
                      <!--   <input
                            [(ngModel)]="eventBookingBatch.wizardValueArray.followUpDate"
                            [owlDateTimeTrigger]="dt7"
                            (dateTimeChange)="changeFollowUpDate(eventBookingBatch)"
                            [owlDateTime]="dt7" readonly class="bookingInput" aria-modal="true">
                        <owl-date-time #dt7></owl-date-time> -->
                    </div>
                    <div class="eventBookingBatch__Item__date" *ngIf="eventBookingBatch.status != 9139">
                        {{ eventBookingBatch.wizardValueArray.followUpDate | date:'medium' }}
                    </div>
                    <div class="eventBookingBatch__Item__buttons">
                        <app-jj-icons 
                        type="chart-line-up"
                        [tippy]="'Mailshot dashboard'" 
                        *ngIf="eventBookingBatch.startedAt != null" 
                        (click)="showStats(eventBookingBatch);$event.stopPropagation()" 
                        [width]="20" [height]="20"></app-jj-icons>

                        <app-jj-icons 
                        type="stop"
                        [width]="20"
                        [height]="20"
                        [pointer]="(eventBookingBatch.startedAt != null || eventBookingBatch.stoppedAt == null)" 
                        [color]="(eventBookingBatch.startedAt == null || eventBookingBatch.stoppedAt != null) ? 'gray0': 'secondary'"
                        (click)="eventbookingbatchService.stopEventBookingBatch(eventBookingBatch);$event.stopPropagation()"></app-jj-icons>
                        

                        <app-jj-icons
                        type="play"
                        [width]="20"
                        [height]="20"
                        [color]="eventBookingBatch.startedAt != null ? 'gray0': 'secondary'"
                        (click)="eventbookingbatchService.finalizeMailshot(eventBookingBatch);$event.stopPropagation()"
                        ></app-jj-icons>
            
                    </div>
                </div>    

                </div> 
            </div>
    </div>
</div>
<div class="vip__wrapper">


<!--     <div class="customTextControlWrapper">
        <div class="customTextControlWrapper__label">
            <label></label>
        </div>
        <div class="customTextControlWrapper__valueWrapper">
            <select [(ngModel)]="currentViewMode" class="select__box">
                <option [ngValue]="viewMode.Agent">{{'Vip.Label.AvailableVipGroups' | texttransform}}</option>
                <option [ngValue]="viewMode.Groups">{{'Vip.Label.Groups' | texttransform}}</option>
                <option [ngValue]="viewMode.Projects" *ngIf="applicationInfoService.applicationSettings['isDemoproject'] != 'true'">
                    {{'Vip.Label.ManageProjects' | texttransform}}</option>
                <option [ngValue]="viewMode.Contacts">{{'Vip.Label.Contacts' | texttransform}}</option>
                <option [ngValue]="viewMode.Accounts">{{'Vip.Label.Accounts' | texttransform}}</option>
                <option [ngValue]="viewMode.Notification" *ngIf="userService.hasPermission('CanCreateVipGroup')">
                    {{'Vip.Label.Notifications' | texttransform}}</option>
            </select>
        </div>
    </div> -->

    <div class="vip__tabs">
        <app-tab svg="list-star" label="Vip.Label.AvailableVipGroups" [active]="currentViewMode === viewMode.Agent" (onClick)="currentViewMode= viewMode.Agent"></app-tab>
        <app-tab svg="address-book-tabs" label="Vip.Label.Groups" [active]="currentViewMode === viewMode.Groups" (onClick)="currentViewMode = viewMode.Groups"></app-tab>
        <app-tab svg="tree-structure" label="Vip.Label.ManageProjects" *ngIf="applicationInfoService.applicationSettings['isDemoproject'] != 'true'"  [active]="currentViewMode === viewMode.Projects" (onClick)="currentViewMode= viewMode.Projects"></app-tab>
        <app-tab svg="user" label="Vip.Label.Contacts" [active]="currentViewMode === viewMode.Contacts" (onClick)="currentViewMode=viewMode.Contacts"></app-tab>
        <app-tab svg="building" label="Vip.Label.Accounts" [active]="currentViewMode === viewMode.Accounts" (onClick)="currentViewMode=viewMode.Accounts"></app-tab>
        <app-tab svg="bell-ringing" label="Vip.Label.Notifications" *ngIf="userService.hasPermission('CanCreateVipGroup')" [active]="currentViewMode === viewMode.Notification" (onClick)="currentViewMode=viewMode.Notification"></app-tab>
        <app-tab svg="eye" label="Vip.Label.AdminVipGroups" [active]="currentViewMode === viewMode.Supervisor" (onClick)="currentViewMode= viewMode.Supervisor"></app-tab>
    </div>


    <div class="vip__content">

        <div *ngIf="currentViewMode === viewMode.Agent" class="margin">
            <div class="vip__component">
                <div class="vip__component__header">
                    <app-component-header header="Vip.Label.AvailableVipGroups"></app-component-header>
                    <app-main-button
                    label="Vip.Label.CreateNew" 
                    type="small"
                    svg="plus"
                    (onClick)="createUserVipSetting()"
                    ></app-main-button>
                </div>

                <div class="vip__component__inner">
                    <div class="vip__description">
                        <app-jj-icons type="bell-ringing" [width]="25" [height]="25"></app-jj-icons>
                        {{ 'Vip.Label.VipSettingDescription' | texttransform }}
                    </div>

                    <ng-container *ngIf="loadingSettings == true">
                        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' |
                        texttransform }}
                    </ng-container>

                    <div class="vip__table" *ngIf="!loadingSettings">
                        <label class="vip__table__labelSpecial"  *ngIf="vipList.length > 0" ><app-jj-icons type="list-checks" [width]="25" [height]="25"></app-jj-icons>
                            {{ 'Vip.Label.ProjectSpecificEntries' | axivastranslate }}</label>

                        <div class="vip__table__content">
                            <div class="vip__table__row" *ngFor="let vipItem of vipList" [ngClass]="{
                                    hideInDom: vipItem.typeRefLookupId == 6640 || vipItem.typeRefLookupId == 8060
                                }">

                                <div class="vip__table__row__vipAgent">
                                    <app-blue-marker label="vip"></app-blue-marker>
                                </div>

                                <div class="vip__table__row__type">
                                    <app-select-input
                                    label="Vip.Label.Type"
                                    [(ngModel)]="vipItem.typeRefLookupId"
                                    (valueChange)="updateVipSetting(vipItem)"
                                    [options]="lookupItems"
                                    bind="id"
                                    display="defaultName"></app-select-input>
                                </div>
                             
                                <div class="vip__table__row__name margin">
                                    <app-text-input
                                    label="Vip.Label.Value"
                                    [(ngModel)]="vipItem.value"
                                    (valueChange)="updateVipSetting(vipItem)"
                                    placeholder="Vip.Label.VipValuePlaceholder"></app-text-input>
                                </div>

                                <div class="vip__table__row__description margin">
                                    <app-text-input
                                    label="Vip.Label.Comment"
                                    [(ngModel)]="vipItem.comment"
                                    (valueChange)="updateVipSetting(vipItem)"></app-text-input>
                                </div>

                                <div class="vip__table__row__agentDelete">
                                    <app-jj-icons type="trash" [width]="25" [height]="25" [tippy]="'Vip.Label.DeletePersonalSetting' | texttransform" (click)="deleteVipSetting(vipItem.id)"></app-jj-icons>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-layout header="Vip.Label.Groups" *ngIf="currentViewMode == viewMode.Groups" class="margin">
            <div class="vip__componentInner">

                <div class="vip__description">
                    <div>{{'Vip.Label.VipGroupDescription' | texttransform }}</div>
                </div>

                <ng-container *ngIf="loadingSettings == true">
                    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' |
                    texttransform }}
                </ng-container>

                
                <div class="vip__table" *ngIf="getGroups(vipGroups, false).length > 0 && loadingSettings == false">
                    <label class="vip__table__label"><app-jj-icons type="list-checks" [width]="25" [height]="25"></app-jj-icons>{{ 'Vip.Label.GlobalGroups' | texttransform }}</label>
                    <div class="vip__table__header">
                        <div class="vip__table__header__vip"></div>
                        <div class="vip__table__header__status"></div>
                        <div class="vip__table__header__name ">{{ 'Vip.Label.Name' | texttransform }}</div>
                        <div class="vip__table__header__description">{{ 'Vip.Label.Description' | texttransform }}</div>
                        <div class="vip__table__header__info"></div>
                    </div>
                    <div class="vip__table__contentGroups">
                        <div *ngFor="let vipGroup of getGroups(vipGroups, false)" class="vip__table__row">

                            <div class="vip__table__row__vip">
                                <app-blue-marker field=true label="vip" [toggle]="checkIfGroupIsActivated(vipGroup)"></app-blue-marker>
                            </div>

                            <div class="vip__table__row__status">
                                <app-status [width]="20" [height]="20" [green]="checkIfGroupIsActivated(vipGroup)"></app-status>
                            </div>

                            <div class="vip__table__row__name">
                                <div class="ellipsisText" [tippy]="vipGroup.defaultName">{{ vipGroup.defaultName }}</div>
                            </div>
                            <div class="vip__table__row__description">
                                <div class="ellipsisText" [tippy]="vipGroup.comment">{{ vipGroup.comment }}</div>
                            </div>

                            <div class="vip__table__row__info" (click)="toggleGroup(vipGroup)">
                                <app-jj-icons [type]="!checkIfGroupIsActivated(vipGroup) ? 'toggle-left' : 'toggle-right-fill'"></app-jj-icons>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="vip__table" *ngIf="getGroups(vipGroups, true).length > 0 && loadingSettings == false">
                    <label class="vip__table__label"><app-jj-icons type="list-checks" [width]="25" [height]="25"></app-jj-icons>{{ 'Vip.Label.ProjectSpecificGroups' | texttransform }}</label>
                    <div class="vip__table__header">
                        <div class="vip__table__header__vip"></div>
                        <div class="vip__table__header__status"></div>
                        <div class="vip__table__header__name">{{ 'Vip.Label.Name' | texttransform }}</div>
                        <div class="vip__table__header__description">{{ 'Vip.Label.Description' | texttransform }}</div>
                        <div class="vip__table__header__info"></div>
                    </div>
                    <div class="vip__table__content">
                        <div *ngFor="let vipGroup of getGroups(vipGroups, true)" class="vip__table__row">

                            <div class="vip__table__row__vip">
                                <app-blue-marker field=true label="vip" [toggle]="checkIfGroupIsActivated(vipGroup)"></app-blue-marker>
                            </div> 

                            <div class="vip__table__row__status">
                                <app-status [width]="20" [height]="20" [green]="checkIfGroupIsActivated(vipGroup)"></app-status>
                            </div>

                            <div class="vip__table__row__name">
                                <div class="ellipsisText" [tippy]="vipGroup.defaultName">{{ vipGroup.defaultName }}</div>
                            </div>
                            <div class="vip__table__row__description">
                                <div class="ellipsisText" [tippy]="vipGroup.comment">{{ vipGroup.comment }}</div>
                            </div>
    
                            <div class="vip__table__row__info" (click)="toggleGroup(vipGroup)">
                                <app-jj-icons [type]="!checkIfGroupIsActivated(vipGroup) ? 'toggle-left' : 'toggle-right-fill'"></app-jj-icons>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-layout>

       <app-layout header="Vip.Label.ManageProjects" *ngIf="currentViewMode == viewMode.Projects" class="margin">
        <div class="vip__description">
            <app-jj-icons type="bell-ringing" [width]="20" [height]="20"></app-jj-icons>{{'Vip.Label.ProjectDescription' | texttransform}}
        </div>
        <div class="vip__table">
            <div class="vip__table__nav">
                <div class="filter">
                    <div class="filterWrapper">
                        <input [(ngModel)]="filterString">
                        <app-jj-icons type="magnifying-glass" [width]="20" [height]="20"></app-jj-icons>
                    </div>
                </div>
            </div>

            <div class="vip__table__contentProjects">
                    <div *ngFor="let project of projects" class="vip__table__row" [ngClass]="{ hideInDom: !isItemFiltered(project) }">
                        <div class="vip__table__row__vip">
                            <app-blue-marker field="true" [toggle]="checkIfActive(projectsVIP, project)" [svg]="checkIfActive(projectsVIP, project) ? 'bell-ringing' : 'bell-slash'"></app-blue-marker>
                        </div>
                        <div class="vip__table__row__projectName">{{project.projectName}}</div>
                        <div class="vip__table__row__projectButtons">
                            <app-main-button label="default" [type]="checkIfActive(projectsDefault, project) ? 'small' :'borderSmall'" [svg]="checkIfActive(projectsDefault, project) ? 'bell-ringing' : 'bell-slash'" (onClick)="toggleArray(projectsDefault, project, 'NormalAlertProjectIds')"></app-main-button>    
                            <app-main-button label="VIP" [type]="checkIfActive(projectsVIP, project) ? 'small': 'borderSmall'" [svg]="checkIfActive(projectsVIP, project) ? 'bell-ringing' : 'bell-slash'" (onClick)="toggleArray(projectsVIP, project, 'VipAlertProjectIds')"></app-main-button>    
                        </div>
                    </div>
            </div>
            
        </div>
    </app-layout>


     <!--        <app-layout header="Vip.Label.Contacts" *ngIf="currentViewMode == viewMode.Contacts" class="margin">
                <div class="vip__componentInner">
             <div class="vip__description">
                        {{ 'Vip.Label.ContactsDesc' | texttransform }}
                    </div> 
                    <app-custom-universal [controlid]="217844"></app-custom-universal>
                </div>
            </app-layout>

            <app-layout header="Vip.Label.Accounts" *ngIf="currentViewMode == viewMode.Accounts">
                <div class="vip__componentInnerOverflow">
                    <div class="vip__description">
                        {{ 'Vip.Label.AccountsDesc' | texttransform }}
                    </div>
                    <app-custom-universal [controlid]="217845"></app-custom-universal>
                </div>
            </app-layout> -->

            <div *ngIf="currentViewMode == viewMode.Contacts" class="margin maxHeight autoOverflowY">
              <!--   <label class="everleadSmallHeader">{{ 'Vip.Label.ContactsDesc' | texttransform }}</label> -->
                <app-custom-universal [controlid]="217844"></app-custom-universal>
            </div>
        
            <div *ngIf="currentViewMode == viewMode.Accounts" class="margin maxHeight autoOverflowY">
                <!-- <label class="everleadSmallHeader">{{ 'Vip.Label.AccountsDesc' | texttransform }}</label> -->
                <app-custom-universal [controlid]="217845"></app-custom-universal>
            </div>
 
    
        <app-layout header="Vip.Label.Notifications" *ngIf="currentViewMode == viewMode.Notification" class="margin">
      
                  <div>{{'Vip.Label.NotificationDesc' | texttransform}}</div>
                  <app-select-input
                  *ngIf="settingsService.userSettings['VipAlertFrequency']"
                  label="UserSettings.Label.VipAlertFrequency"
                  [(ngModel)]="settingsService.userSettings['VipAlertFrequency'].value"
                  (valueChange)="settingsService.setUserSettingEx(settingsService.userSettings['VipAlertFrequency'])"
                  bind="value"
                  display="label"
                  [options]="[
                      {value:'none', label:'UserSettings.Label.NormalVisitorAlertFrequency.None'},
                      {value: 'direct', label: 'UserSettings.Label.NormalVisitorAlertFrequency.Direct'},
                      {value: 'daily', label: 'UserSettings.Label.NormalVisitorAlertFrequency.Daily'},
                      {value: 'weekly', label: 'UserSettings.Label.NormalVisitorAlertFrequency.Weekly'}]"></app-select-input>
      
                  <app-select-input
                  *ngIf="settingsService.userSettings['NormalVisitorAlertFrequency']"
                  label="UserSettings.Label.NormalVisitorAlertFrequency"
                  [(ngModel)]="settingsService.userSettings['NormalVisitorAlertFrequency'].value"
                  (valueChange)="settingsService.setUserSettingEx(settingsService.userSettings['NormalVisitorAlertFrequency'])"
                  bind="value"
                  display="label"
                  [options]="[
                      {value:'none', label: 'UserSettings.Label.NormalVisitorAlertFrequency.None'},
                      {value:'direct', label:'UserSettings.Label.NormalVisitorAlertFrequency.Direct'},
                      {value: 'daily', label: 'UserSettings.Label.NormalVisitorAlertFrequency.Daily'},
                      {value:'weekly', label: 'UserSettings.Label.NormalVisitorAlertFrequency.Weekly'}]"></app-select-input>
      
      
        </app-layout>


        <app-layout header="Vip.Label.AdminVipGroups" class="margin" *ngIf="currentViewMode == viewMode.Supervisor">
            <div class="vip__componentInner">
                    <ng-container *ngIf="loadingGroups == true">
                        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' |
                        texttransform }}
                    </ng-container>

                    <div class="vip__table">
                        <div class="vip__table__tab">
                            <app-tab *ngIf="applicationInfoService.isDeveloper" label="Vip.Label.AdminVipGroups" svg="eye" [active]="supervTab === 1" (onClick)="supervTab= 1"></app-tab>
                            <app-tab label="Vip.Label.AvailableVipGroups" svg="eye" [active]="supervTab === 2" (onClick)="supervTab= 2"></app-tab>
                            <app-tab *ngIf="selectedGroup != null" svg="eye" [label]="selectedGroup.defaultName" [active]="supervTab === 3" (onClick)="supervTab=3"></app-tab>
                        </div>

                        <div class="vip__table__inner" *ngIf="supervTab == 1">
                            <div class="vip__table__nav">
                                <span class="vip__table__nav__label">{{ 'Vip.Label.AdminVipGroups' | texttransform  }}</span>
                                <app-main-button 
                                type="small"
                                svg="plus"
                                label="Vip.Label.CreateNew"
                                (onClick)="createVipGroup()"></app-main-button>
                            </div>
                            <div class="vip__table__content">
                                <div *ngFor="let vipGroup of vipGroups" class="vip__table__row">
                                    <div class="vip__table__row" *ngIf="vipGroup.projectId == null">
                                        <div class="vip__table__row__name margin">
                                            <app-text-input
                                            label="Vip.Label.Value"
                                            [(ngModel)]="vipGroup.defaultName" (valueChange)="saveVipGroup(vipGroup)"></app-text-input>
                                        </div>
        
                                        <div class="vip__table__row__description margin">
                                            <app-text-input label="Vip.Label.Comment" [(ngModel)]="vipGroup.comment" (valueChange)="saveVipGroup(vipGroup)"></app-text-input>
                                        </div>
        
                                        <div class="vip__table__row__agentDelete">
                                            <app-jj-icons type="trash" [width]="20" [height]="20" (click)="editVipGroup(vipGroup)"></app-jj-icons>
                                        </div>
                                    </div>
        
                                </div>
                            </div>    
                        </div>

                        <div class="vip__table__inner" *ngIf="supervTab == 2">
                            <div class="vip__table__nav">
                                <span class="vip__table__nav__label">{{ 'Vip.Label.AvailableVipGroups' | texttransform }}</span>
                                <app-main-button
                                type="small"
                                svg="plus"
                                label="Vip.Label.CreateNew"
                                (onClick)="createVipGroup(true)"></app-main-button>
                            </div>
                            <div class="vip__table__content">
                                <div *ngFor="let vipGroup of vipGroups" class="vip__table__row">
                                    <div class="vip__table__row" *ngIf="vipGroup?.projectId == applicationInfoService.projectID">
                                        <div class="vip__table__row__name margin">
                                            <app-text-input
                                            label="Vip.Label.Value"
                                            [(ngModel)]="vipGroup.defaultName" (valueChange)="saveVipGroup(vipGroup)"></app-text-input>
                                        </div>

                                        <div class="vip__table__row__description margin">
                                            <app-text-input 
                                            label="Vip.Label.Comment" [(ngModel)]="vipGroup.comment" (valueChange)="saveVipGroup(vipGroup)"></app-text-input>
                                        </div>

                                        <div class="vip__table__row__agentDelete">
                                            <app-jj-icons type="trash" [width]="20" [height]="20" (click)="editVipGroup(vipGroup)"></app-jj-icons>
                                        </div>
                                    </div>
                            </div>
                            </div>
                        </div>

                        <div class="vip__table__inner" *ngIf="supervTab == 3">
                            <div class="vip__table__nav">
                                <span class="vip__table__nav__label">{{ selectedGroup.defaultName }}</span>
                                <app-main-button
                                label="Vip.Label.CreateNewGroupAlert"
                                svg="plus"
                                type="small"
                                (onClick)="createGroupVipSetting()"
                                ></app-main-button>
                            </div>
                            <div class="vip__table__description">
                                {{ 'Vip.Label.VipSettingPerGroupDescription' | texttransform }}
                            </div>
                            <ng-container *ngIf="loadingSettings == true">
                                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' |
                                texttransform }}
                            </ng-container>
                            <div class="vip__table__content" *ngIf="!loadingSettings">
                                <div class="vip__table__row" *ngFor="let vipItem of vipListForGroup">
                                    <div class="vip__table__row__type">
                                        <app-select-input
                                        label="Vip.Label.Type"
                                        [(ngModel)]="vipItem.typeRefLookupId"
                                        (valueChange)="updateVipSetting(vipItem)"
                                        bind="id"
                                        display="defaultName"
                                        [options]="lookupItems"
                                        placeholder="Vip.Label.Type"
                                        ></app-select-input>
                                    </div>

                                    <div class="vip__table__row__name margin">
                                        <app-text-input
                                        label="Vip.Label.Value"
                                        [(ngModel)]="vipItem.value" (valueChange)="updateVipSetting(vipItem)"
                                        placeholder="Vip.Label.VipValuePlaceholder"></app-text-input>
                                    </div>

                                    <div class="vip__table__row__description margin">
                                        <app-text-input
                                        label="Vip.Label.Comment"
                                        placelholder="Vip.Label.Comment"
                                        [(ngModel)]="vipItem.comment"
                                        (valueChange)="updateVipSetting(vipItem)"
                                        ></app-text-input>
                                    </div>

                                    <div class="vip__table__row__info">
                                        <app-jj-icons *ngIf="applicationInfoService.isDeveloper" type="arrows-counter-clockwise" (click)="refreshWhois(vipItem)" [width]="20" [height]="20"></app-jj-icons>
                                        <app-jj-icons type="trash" (click)="deleteVipSetting(vipItem.id)" [width]="20" [height]="20"></app-jj-icons> 
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
            </div>
        </app-layout>

</div>
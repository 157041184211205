import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { WizardService , WizardMode } from '../../wizard/wizard.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-toolbar-nav',
  templateUrl: './toolbar-nav.component.html',
  styleUrl: './toolbar-nav.component.scss'
})
export class ToolbarNavComponent implements OnInit, AfterViewInit{

  constructor(
    private methodService: MethodService,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private externaldatasourceService: ExternaldatasourceService,
    private refreshValueService: RefreshValueService,
    private wizardService: WizardService,
    public userService:UserService,
    public ringcentralwebphoneService: RingcentralwebphoneService,
    private uiService: UiService,
    private messagingService: MessagingService,
    private eventService: EventService
  ){}

  @Input() type: string = null;
  @ViewChildren('toolbarItem') toolbarItems!: QueryList<ElementRef>;

  phoneItems = [];
  mode = 1;
  numberToCall = '';
  adressBook = null;
  callHistory = null;
  filterString = '';



  ngOnInit():void{
    this.getPhoneItems();
    this.getRingCentralData();

    if (this.commonService.checkApplicationRoute('account') != true) {
      this.mode = 2;
    }
  }

  ngAfterViewInit(): void {
    
  }


  // ! SECTION PLUS

  newItem(entityId) {
    switch (entityId) {
      case 1:
        // this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'newaccount']);
        this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, [
          'Supervisor.Menu.NewAccount',
          'newaccount',
          '90'
        ]);  
        break;
      case 2:
        this.methodService.launchInternalMethod(InternalMethodType.UpdateEntity, ['6', '-1']).then(() => {
          this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, [
            'Contact.Label.NewContact',
            'newcontact',
            '90'
          ]);  
        });
        break;
      case 4: 
      if (this.applicationInfoService.currentAccount == null) { 
          return; 
        }
        this.methodService.launchInternalMethod(InternalMethodType.UpdateEntity, ['15', '-1']).then(() => {
          this.methodService.launchInternalMethod(InternalMethodType.UpdateMiscSettings, ['bookingMode', '4']).then(() => {
            this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, [
              'AccountFooter.Label.NewActivity',
              'booking'
            ]);  
          });
        });
        break;
    }
  }


  createWorkflow() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(530, [
      this.commonService.getModifyArrayBody({ 
        projectId: this.applicationInfoService.projectID,
        defaultName: 'New workflow',
      }, [])
    ]).then(createWorkflowResult => {
      this.refreshValueService.getWorkflows().then(() => {  
       /*  this.eventService.customEvent.emit({
          id: 'updateProcessVisualWorkflow',
          value: createWorkflowResult.id,
          duplicate: true
        }); */
        const wizardArray = this.applicationInfoService.workflows.Item(createWorkflowResult.id.toString());
        wizardArray.showDuplicateOption = true; 
        this.wizardService.showWizard(WizardMode.Process, wizardArray);
      }).catch(() => { this.loaderService.display(false); });
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }


  createStarterStep(){
    //console.log('click')
  }


  // ! SECTION RESEARCH


  getAccountValue(memberName) {
    if (this.applicationInfoService.entities.Item('1').data == null) {
      return '';
    }
    let accountValue = this.applicationInfoService.entities.Item('1').data[memberName];
    if (this.commonService.isNullOrUndefined(accountValue)) {
      accountValue = '';
    }
    return accountValue;
  }

  modifySearchString(baseString: string): string {
    let resultString = '';
    if (baseString == null) {
      return resultString;
    }
    resultString = baseString.trim();
    resultString = resultString.replace(/ /, '+');
    return resultString;
  }


  openURL(url) {
    this.commonService.openUrl(url, '_new');
  }



  launchGoogleSearch(entityId) {
    let url =  'https://www.google.de/search?source=everlead&q=<0>';
    let replaceString = '';
    const entityDataAccount = this.applicationInfoService.entities.Item('1').data;
    const entityDataContact = this.applicationInfoService.entities.Item('4').data;
    switch(entityId) {
      case 1:
        replaceString = replaceString.concat(
          this.modifySearchString(entityDataAccount.name1), '+',
          this.modifySearchString(entityDataAccount.name2), '+',
          this.modifySearchString(entityDataAccount.street), '+',
          this.modifySearchString(entityDataAccount.zipcode), '+',
          this.modifySearchString(entityDataAccount.city)
        );
        break;
      case 4:
        replaceString = replaceString.concat(
          this.modifySearchString(entityDataContact.firstName), '+',
          this.modifySearchString(entityDataContact.lastName)
        );
        break;
    }
    
    replaceString = replaceString.replace(/&/, '');
    url = url.replace(/<0>/, replaceString);
    window.open(url);
  }

  openLinkedIn(entityId) {
    let isAccount = false;
    const entityData = this.applicationInfoService.entities.Item(entityId).data;
    let value1 = '';
    let value2 = '';
    if (Number(entityId) == 1) {
      value1 = entityData.name1;
      isAccount = true;
    } else {
      value1 = entityData.firstName;
      value2 = entityData.lastName;
    }
    if (this.commonService.isNullOrUndefined(value1)) { value1 = ''; }
    if (this.commonService.isNullOrUndefined(value2)) { value2 = ''; }
    this.commonService.openLinkedIn(value1, value2, isAccount);
  }



  // ! session phone 

  getRingCentralData() {
    this.ringcentralwebphoneService.getAdressBook().then(getAdressbookResult => {        
      getAdressbookResult.records.forEach(getAdressbookResultItem => {
        getAdressbookResultItem['extension'] = getAdressbookResultItem.extensionNumber;
        if (getAdressbookResultItem.phoneNumbers) {
          if (getAdressbookResultItem.phoneNumbers.length > 0) {
            getAdressbookResultItem['phoneNumber'] = getAdressbookResultItem.phoneNumbers[0].formattedPhoneNumber;
          }            
        }
      })

      this.adressBook = getAdressbookResult.records.sort((a:any, b:any) => a.lastName < b.lastName ? -1 : 1);
    });    
    this.ringcentralwebphoneService.getRecentCalls().then(getRecentCallsResult => {
      getRecentCallsResult.records.forEach(getRecentCallsResultItem => {
        if (getRecentCallsResultItem.direction == 'Outbound') {
          getRecentCallsResultItem['phoneNumber'] = getRecentCallsResultItem.to.phoneNumber;
        } else {
          getRecentCallsResultItem['phoneNumber'] = getRecentCallsResultItem.from.phoneNumber;
        }
        getRecentCallsResultItem['fromNumber'] = getRecentCallsResultItem.from.phoneNumber;
        getRecentCallsResultItem['toNumber'] = getRecentCallsResultItem.to.phoneNumber;
        getRecentCallsResultItem['start'] = getRecentCallsResultItem.startTime;
        getRecentCallsResultItem['durationFormatted'] = this.commonService.getDurationString(getRecentCallsResultItem.duration);          
      });
      this.callHistory = getRecentCallsResult.records;
    });

  }

  getPhoneItems() {
    this.phoneItems = [];
    const dialItems = this.applicationInfoService.controls.toArray().filter(control => 
      control.controlType == 9 && 
      (control.uiEntityInstanceId == 1 || control.uiEntityInstanceId == 4) &&
      control.isActive == true
    );
    dialItems.sort((a, b) => a.uiEntityInstanceId < b.uiEntityInstanceId ? -1 : 1);
    dialItems.forEach(dialItem => {
      const foundItem = this.phoneItems.find(phoneItem => phoneItem.value == dialItem.value);
      if (!foundItem) {
        this.phoneItems.push({
          displayText: dialItem.displayText,
          entityId: dialItem.uiEntityInstanceId,
          value: dialItem.value
        })
      }
    });   
  }

  makeCall(number) {
    if (this.applicationInfoService.applicationSettings['isDemoproject'] == 'true' && !this.applicationInfoService.isDeveloper) {
      this.messagingService.showDefaultWarning('', 'Toolbar.Label.NotLoggedInDemo');
      return;
    }
    this.ringcentralwebphoneService.makeCall(number);
    this.eventService.customEvent.emit({ id: 'closeToolbarPopup' });
  }
  
  getPhoneValue(phoneItem) {
    let phoneNumber = this.applicationInfoService.entities.Item(phoneItem.entityId).data[phoneItem.value];
    if (this.commonService.isNullOrUndefined(phoneNumber)) {
      phoneNumber = '';
    }
    return phoneNumber;
  }

  getHeight(elementId, substractValue) {
    if (this.applicationInfoService.isMobile()) {
      return Number(this.uiService.getDesignSpanPositionMobile(elementId, substractValue - 10));
    } else {
      return Number(this.uiService.getDesignSpanPosition(elementId, substractValue));          
    }                
  }

  isItemFiltered(adressBookItem) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(adressBookItem.firstName, this.filterString)) {
      returnValue = false;
    }
    if (this.commonService.checkIfStringContainsString(adressBookItem.lastName, this.filterString)) {
      returnValue = false;
    }
    if (this.commonService.checkIfStringContainsString(adressBookItem.extension, this.filterString)) {
      returnValue = false;
    }
    if (this.commonService.checkIfStringContainsString(adressBookItem.phoneNumber, this.filterString)) {
      returnValue = false;
    }

    return returnValue;
  }


}

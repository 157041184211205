import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-usermanagementuserdetails',
  templateUrl: './usermanagementuserdetails.component.html',
  styleUrl: './usermanagementuserdetails.component.scss'
})

export class UsermanagementuserdetailsComponent implements OnInit {
  user = null;
  tab = 0;

  constructor(
    public applicationInfoService: ApplicationInfoService
  ) {}

  ngOnInit(): void {
    this.user = this.applicationInfoService.miscSettings['userManagementSeletedUser'];
    console.warn(this.user, this.applicationInfoService.miscSettings['userManagementSeletedUser']);
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';

import { environment } from 'environments/environment';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ConfirmationService } from 'primeng/api';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';

@Injectable({
  providedIn: 'root'
})
export class AwsCognitoService {
  refreshTokenSubscription: Subscription = new Subscription();
  cognitoTokenDetails: any;
  cognitoToken: any;
  cognitoRefreshToken = null;
  cognitoRefreshTimer = 300;
  cognitoRefreshThreshold = 100;
  cognitoRefreshDate: any;
  cognitoUser = null;

  constructor(
    private http: HttpClient,
    private axivasTranslateService: AxivasTranslateService,
    private confirmationService: ConfirmationService,
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private eventService: EventService
  ) { }

  public getTokenDetailsFromCognito(callbackCode: string): Observable<any> {
    const details = {
      grant_type: 'authorization_code',
      code: callbackCode,
      scope: 'openid+profile',
      redirect_uri: environment.cognitoRedirectURL.replace('<0>', window.location.origin),
    };
    const authorization = this.generateSecretHeader();
    let formBody = Object.keys(details)
                           .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`)
                           .join('&');

    formBody = formBody + `&client_id=${environment.cognitoClientId}`;
    return this.http.post<any>(environment.cognitoTokenURL,
      formBody, {
        responseType: 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
  }

  handleLoginError() {
    if (!this.applicationInfoService.noAuthKilling) {
      localStorage.removeItem('awstoken');
      localStorage.removeItem('awsrefreshtoken');
      this.showAwsLoginPage();
    }
  }

  setTokenDetails(token, refreshToken = false) {
    if(token.refresh_token !== undefined) {
      this.cognitoRefreshToken = token.refresh_token;
      localStorage.setItem('awsrefreshtoken', token.refresh_token);
    }
    this.cognitoRefreshTimer = token.expires_in;
    this.cognitoRefreshThreshold = token.expires_in / 2;

    this.cognitoRefreshDate = new Date(Date.now());
    this.cognitoRefreshDate.setMinutes(this.cognitoRefreshDate.getMinutes() + 2); // timestamp
    this.cognitoRefreshDate = new Date(this.cognitoRefreshDate); // Date object
    // console.log('Token refresh: ', new Date(Date.now()), '. Next token refresh date: ', this.cognitoRefreshDate, );

    this.cognitoToken = token;
    this.getTokenDetails(token.access_token);
  }

  public logoutUserFromCognito() {
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('AWS.Logout.Message'),
      header: this.axivasTranslateService.getTranslationTextForToken('AWS.Logout.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        localStorage.removeItem('awstoken');
        localStorage.removeItem('awsrefreshtoken');
        localStorage.removeItem('awstokentokenurl');
        localStorage.removeItem('awstokenclientid');
        this.commonService.clearSessionStorageItem('lastproject');
        localStorage.removeItem('lastProject');
        this.applicationInfoService.showAWsLoginPage = true;
        this.applicationInfoService.applicationInitialized = false;
        this.applicationInfoService.projectLoaded = false;
        this.applicationInfoService.awsPassword = '';
        this.eventService.customEvent.emit({ id: 'closeToolbarPopup' });
        this.applicationInfoService.user = null;
        this.applicationInfoService.userID = 0;
        this.applicationInfoService.showLoadProjectSpinner = true;
        this.applicationInfoService.applicationUserInitialized = false;
      },
    });
  }

  public getTokenDetails(token) {
    // console.warn('getTokenDetails', token)
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      this.cognitoTokenDetails = JSON.parse(atob(base64));

      localStorage.setItem('awstoken', token);
      // console.warn('getTokenDetails aws token', token, this.cognitoTokenDetails)
      this.refreshTimer();
    }
  }

  public showAwsLoginPage(initHref = window.location.origin) {
    let loginString = environment.cognitoLoginURL.replace('<0>', initHref)
    window.location.assign(loginString);
  }

  refreshTimer() {
    const responseTimer = timer(1000, 1000);
    this.refreshTokenSubscription = responseTimer.subscribe(() => {
      // console.log('refreshTimer', 'tick!', this.cognitoRefreshTimer, this.cognitoRefreshThreshold, environment.cognitoSilentRenewRefreshTime);
      this.cognitoRefreshTimer = this.cognitoRefreshTimer - 1;
      if (this.cognitoRefreshTimer < Number(this.cognitoRefreshThreshold) || new Date(Date.now()) > new Date(this.cognitoRefreshDate)) {
        this.silentRenew().subscribe(
          result => {
            this.setTokenDetails(result, true);
          },
          error => {
            console.error('silentRenew', error);
            this.applicationInfoService.awsSilentRenewError = true;
          }
        );
      }
    });

  }

  public silentRenew(): Observable<any> {
    this.refreshTokenSubscription.unsubscribe();

    let cognitoTokenURL = environment.cognitoTokenURL;
    let cognitoClientId = environment.cognitoClientId;
    
    if (!this.commonService.isNullOrUndefined(localStorage.getItem('awstokentokenurl'))) {
      cognitoTokenURL = localStorage.getItem('awstokentokenurl');
    }
    if (!this.commonService.isNullOrUndefined(localStorage.getItem('awstokentokenurl'))) {
      cognitoClientId = localStorage.getItem('awstokenclientid');
    }

    if (this.applicationInfoService.userLoginByEmail != null) {
      cognitoTokenURL = this.applicationInfoService.userLoginByEmail.awsCognitoPool.tokenUrl;
      cognitoClientId = this.applicationInfoService.userLoginByEmail.awsCognitoPool.clientId;
    }

    const details = {
      grant_type: 'refresh_token',
      scope: 'openid+profile',
      refresh_token: localStorage.getItem('awsrefreshtoken')
    };
    let formBody = Object.keys(details)
                           .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`)
                           .join('&');

    formBody = formBody + `&client_id=${cognitoClientId}`;
    return this.http.post<any>(cognitoTokenURL,
      formBody, {
        responseType: 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
  }

  generateSecretHeader (): string {
    var clientId = environment.clientId;
    var clientSecret = environment.cognitoClientSecret;
    var encodedData = window.btoa(clientId + ':' + clientSecret);
    return 'Bearer ' + encodedData;
  }

  seCognitoUser(userName, clientId, userPoolId) {
    const poolData = {
      UserPoolId: userPoolId,
      ClientId: clientId
    };

    const userPool = new CognitoUserPool(poolData);
    const userData = { Username: userName, Pool: userPool };

    this.cognitoUser = new CognitoUser(userData);
  }

  authenticateAndSetUser(userName, password, clientId, userPoolId, callbacks = {}) {
    this.seCognitoUser(userName, clientId, userPoolId);
    const authenticationDetails = new AuthenticationDetails({
      Username: userName,
      Password: password,
    });
    this.cognitoUser.authenticateUser(authenticationDetails, callbacks);
  }

  setupMfa(callbacks: {getSecretCode, onFailure}) {
    this.cognitoUser.associateSoftwareToken({
      associateSecretCode: (secretCode: string) => {
        callbacks.getSecretCode(secretCode);
      },
      onFailure: (err: any) => {
        callbacks.onFailure(err);
      },
    })
  }

  setMfaEnabled(value, callbacks: {onSuccess, onError}) {
    const totpMfaSettings = {
      PreferredMfa: value,
      Enabled: value,
    };
    this.cognitoUser.setUserMfaPreference(null, totpMfaSettings, (err, result) => {
      err && callbacks.onError(err);
      if (result) {
        callbacks.onSuccess();
      }
    });
  }

  enableMfaByToken(setupMfaAnswer, callbacks: {mfaSetupSuccess, mfaSetupFailed, onFailure}) {
    this.cognitoUser.verifySoftwareToken(setupMfaAnswer, 'My TOTP device', {
      onSuccess: () => {
        this.setMfaEnabled(true, {onSuccess: callbacks.mfaSetupSuccess, onError: callbacks.mfaSetupFailed})
      },
      onFailure: (err) => {
        callbacks.onFailure(err);
      },
    });
  }
}

<div class="webleadListItem__row" (click)="navigateTo('conversions')" [ngClass]="{
    webleadListItemOdd: isOdd
}">
<!--     <div class="webleadListItemCol__one" *ngIf="parentHasAI">
        <app-blue-marker label="AI" svg="arrow-up-right" *ngIf="getAiStatus()"></app-blue-marker>
    </div> -->
    <div class="webleadListItem__col__two">
        <div class="webleadListItem__card">
            <div class="accountName ellipsis" *ngIf=" data['_462_companyname']" [tippy]="data['_462_companyname']">{{ data['_462_companyname'] }}</div>
            <div class="contactName ellipsis" *ngIf="data['_4_firstname'] || data['_4_lastname']" >{{data['_4_firstname']}} {{ data['_4_lastname']}}</div>
            <div class="assets" *ngIf="data['_462_assettype']">
                <div class="ellipsis" [tippy]="data['_462_url']" >{{ data['_462_assettype'] }} | <span class="noWeight">{{data['_462_assetname']}}</span> </div> 
                <!-- <div class="ellipsis">{{data['_462_assetname']}}</div> -->
            </div>
            <div class="activityStatus">
                <app-status [blue]="true" [green]="false" [yellow]="false" [red]="false">
                   <span>{{'Conversion.Label.ConversionDate' | texttransform}} | <span class="noWeight">{{ data['_462_date'] | date }}</span></span>
                </app-status>
            </div>
        </div>
    </div>
    <div class="webleadListItem__col__three">
        <app-bar-leadscore [value]="leadScoringRound" [label]="'Leadscore'"></app-bar-leadscore>
    </div>
</div>


import { Component, OnInit, Input, HostListener, Injector, Output, EventEmitter } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent extends CustomControlBaseClass implements OnInit {
  @Input() controlDefinition: any = null;
  @Input() controlID: any = null;
  @Output() clickOutput = new EventEmitter<any>();
  

  buttonClickBlocker = false;

  constructor(
    public injector: Injector,
  ) {
    super(injector);
    this.valueType = null;
  }
  ngOnInit() {
    this.checkAttributes();
  }

  buttonClick() {
    if (this.buttonClickBlocker) { return; }
    this.buttonClickBlocker = true;
    setTimeout(() => { 
      this.buttonClickBlocker = false; 
    }, 2000);
    this.clickOutput.emit();
    this.callMethod('onclick');
  }
}

@if (!packagefeatureService.allPackages.length) {
<div style="height: 22px;">
  <i class="fas fa-spinner fa-spin" style="margin-right: 5px;"></i>{{ 'General.Label.LoadingData' | texttransform }}
</div>
}

<div class="featuresPackagessettings">
  <div class="featuresPackagessettings__headerComponent">
      <app-component-header [header]="applicationInfoService.breadCrumbText"></app-component-header>
      <app-main-button
        type="small"
        label="Add Features Package"
        (onClick)="addNewFeaturesPackage()"
        svg="plus"></app-main-button>
  </div>

  <div class="featuresPackagessettings__inner">
    <div class="featuresPackagessettings__content">
      @for (featuresPackage of packagefeatureService.allPackages; track featuresPackage.id; let i = $index) {
        <div class="featuresPackagessettings__container">
          <div class="featuresPackagessettings__header fingerCursor" (click)="toggleExpanded(featuresPackage.id)">
            <h4>{{featuresPackage.defaultName}}</h4>
            <app-jj-icons [type]="isExpanded(featuresPackage.id) ? 'caret-up' : 'caret-down'" [width]="20" [height]="20"></app-jj-icons>
          </div>
          <div class="featuresPackagessettings__body" [ngClass]="{'hideInDom': !isExpanded(featuresPackage.id)}">
            <div class="featuresPackagessettings__field">
              <label for="featuresPackage-name-{{i}}">Feature Name</label>
              <app-text-input class="featuresPackagessettings__field__value" placeholder="Feature Name" [(ngModel)]="featuresPackage.defaultName" id="featuresPackage-name-{{i}}" (valueChange)="updateFeaturesPackage(featuresPackage.id)"></app-text-input>
            </div>
      
            <div class="featuresPackagessettings__field">
              <label for="featuresPackage-active-{{i}}">Is Active</label>
              <app-checkbox [(ngModel)]="featuresPackage.isActive" id="featuresPackage-active-{{i}}"
              (valueChange)="updateFeaturesPackage(featuresPackage.id)"></app-checkbox>
            </div>
      
            <div class="featuresPackagessettings__field">
              <label for="featuresPackage-notes-{{i}}">Notes</label>
              <app-text-area-input class="featuresPackagessettings__field__value" [(ngModel)]="featuresPackage.notes" id="featuresPackage-notes-{{i}}" (valueChange)="updateFeaturesPackage(featuresPackage.id)"></app-text-area-input>
<!--               <textarea [(ngModel)]="featuresPackage.notes" id="featuresPackage-notes-{{i}}" (change)="updateFeaturesPackage(featuresPackage.id)"></textarea> -->
            </div>
      
            <div class="featuresPackagessettings__field">
              <label for="featuresPackage-settings-{{i}}">Settings</label>
              <app-text-area-input class="featuresPackagessettings__field__value" [(ngModel)]="featuresPackage.settings" id="featuresPackage-settings-{{i}}"
              (valueChange)="updateFeaturesPackage(featuresPackage.id)"></app-text-area-input>
            <!--   <textarea [(ngModel)]="featuresPackage.settings" id="featuresPackage-settings-{{i}}" rows="4"
                (change)="updateFeaturesPackage(featuresPackage.id)"></textarea> -->
            </div>
            <div class="featuresPackagessettings__field">
              <label>Features</label>
              <div class="featuresPackagessettings__features-list">
                @for (feature of packagefeatureService.allFeatures; track feature.id; let j = $index) {
                <div class="featuresPackagessettings__features-item">

               <!--    <app-checkbox label="feature.defaultName"  [(ngModel)]="featuresPackage.id" id="feature-{{feature.defaultName}}-{{i}}-{{j}}" (valueChange)="updatePackageHasFeature()"></app-checkbox> -->
                  <input
                    id="feature-{{feature.defaultName}}-{{i}}-{{j}}"
                    type="checkbox"
                    [checked]="isPackageHasFeature(featuresPackage, feature)"
                    (change)="updatePackageHasFeature(featuresPackage, featuresPackage.id, feature.id, $event.target.checked)"
                  >
                  <label for="feature-{{feature.defaultName}}-{{i}}-{{j}}">{{feature.defaultName}}</label>
                </div>
              }
              </div>
            </div>
          </div>
      
        </div>
        }
    </div>
  </div>

</div>

<div class="component" *ngIf="userService.hasPermission('CanUpdateWorkflow') && applicationInfoService?.user?.isExternalUser == false">
    <div class="component__header">
        <app-component-header [header]="'Supervisor.Label.ManageWorkflow' | texttransform"></app-component-header>

        <div class="component__nav">
            <app-checkbox label="ProcessDesign.Label.ShowOnlyActive" [(ngModel)]="showOnlyActive" class="agComponentHeader__item"></app-checkbox>
            <app-main-button
            type="small"
            svg="tree-view"
            (onClick)="createNewWorkflow()"
            label="ProcessDesign.Label.NewWorkflow"
            [tippy]="'General.Label.CreateNewWithWizard' | texttransform" [tippyOptions]="{placement:'bottom'}"
            class="agComponentHeader__item"></app-main-button>
            <app-filter (keyUp)="filterString = $event" class="agComponentHeader__item"></app-filter>
        </div>

    </div>

    <div class="component__inner">
        <ng-container *ngIf="loadingData == true">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData'|texttransform}}
        </ng-container>

        <div class="process" *ngIf="loadingData == false">
            <div class="process__description">
                <app-jj-icons type="tree-view" [width]="20" [height]="20" [pointer]="false"></app-jj-icons>
                <div class="everleadBlueColor" *ngIf="loadingData == false"
                [innerHTML]="'ProcessDesign.Label.ProjectDescription' | texttransform">
            </div>
            </div>

            <div class="process__nav">
                <app-select-input
                *ngIf="!applicationInfoService.expertMode"
                label="ProcessDesign.Label.Workflow"
                [disabled]="applicationInfoService.expertMode"
                [(ngModel)]="selectedWorkflow"
                [options]="workflowList"
                [complex]="true"
                display="defaultName"
                class="agComponentHeader__item"></app-select-input>
                <app-main-button
                type="small"
                label="ProcessDesign.Label.CreateNewStep"
                (onClick)="createNewStep(selectedWorkflow)"
                svg="tree-structure"
                [disabled]="applicationInfoService.expertMode && loadingData == false"
                ></app-main-button>

            <!--     <div class="workflowNonExpert__newItem fingerCursor" (click)="createNewStep(selectedWorkflow)">
                    <i class="fas fa-plus"></i>
                    {{ 'ProcessDesign.Label.CreateNewStep' | texttransform }}
                </div> -->
            </div>

            <div class="process__content">
                <div *ngIf="applicationInfoService.expertMode && loadingData == false" class=" everleadBlueColor">
                    <span id="processDesignSpan"></span>
                    <div class="projectSettings__wrapper" [style.height]="getTableHeight('processDesignSpan')">
                        <table mat-table #table [dataSource]="dataSource" style="width: 100%" matSort>
                            <ng-container matColumnDef="resultName" [sticky]="!applicationInfoService.isMobile()">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.Name' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="element.isInfoLineWorkflow == true" class="projectSettings__section">
                                        <div class="workflowExpertNameLine ">

                                            <i class="fas fa-plus-circle fingerCursor" (click)="createNewStep(element.workflow)"
                                                [tippy]="'ProcessDesign.Label.CreateNewStep' | texttransform" [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}"></i>
                                            <i class="fas fa-edit fingerCursor" 
                                                [tippy]="'General.Label.ShowWizard' | texttransform"
                                                [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}"
                                                (click)="showWizard(element.workflow)"></i>
                                            <label *ngIf="applicationInfoService.isDeveloper"
                                                class="projectSettings__content projectSettings__content__result__id">{{
                                                element.workflow.id }}</label>                            
                                            <label 
                                            [tippy]="element.workflow?.defaultName | texttransform"
                                            [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}"
                                                class="fingerCursor ellipsisText">
                                                {{ 'ProcessDesign.Label.Workflow' | texttransform }}: {{ element.workflow?.defaultName
                                                }}
                                            </label>
                                            <app-wizard-translations [array]="element.workflow" updateBaseArrayExternaldatasourceId=531
                                                [exclude]="excludeItemsWorkflow" mode="3"></app-wizard-translations>
                                        </div>
                                    </div>
            
                                    <div *ngIf="element.isInfoLineWorkflow != true && element.isInfoLine == true"
                                        class="projectSettings__section">
                                        <div class="workflowExpertNameLine ">
                                            <i class="fas fa-plus-circle fingerCursor" (click)="createNewStepResult(element.step)"
                                                [tippy]="'ProcessDesign.Label.CreateNewResultForThisStep' | texttransform" 
                                                [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}"></i>
                                            <i class="fas fa-edit fingerCursor" [tippy]="'General.Label.ShowWizard' | texttransform"
                                                [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}"
                                                (click)="showStepWizard(element.step)"></i>
                                            <label *ngIf="applicationInfoService.isDeveloper"
                                                class="projectSettings__content projectSettings__content__result__id">{{ element.step.id
                                                }}</label>
                                            <label [tippy]="element.step?.defaultName | texttransform" class="ellipsisText" [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}">
                                                {{ 'ProcessDesign.Label.Step' | texttransform }}: {{ element.step?.defaultName }}
                                            </label>
                                            <app-wizard-translations [array]="element.step" updateBaseArrayExternaldatasourceId=568
                                                [exclude]="excludeItemsStep" mode="3"></app-wizard-translations>
                                        </div>                       
                                    </div>
            
                                    <div *ngIf="element.isInfoLine != true" class="projectSettings__section">
                                        <div class="workflowExpertNameLine projectSettings__content__result">
                                            <i class="fas fa-envelope" [ngClass]="{
                                            projectSettings__isActive: element.result?.emailTemplateId != null
                                        }" (click)="scrollIntoView()" *ngIf="applicationInfoService.isDeveloper"></i>
                                            <i class="fas fa-calendar fingerCursor"
                                                (click)="showEventWizard(element.result, element.step);$event.stopPropagation()"
                                                *ngIf="applicationInfoService.isDeveloper && !element.isInfoLine" [ngClass]=" {
                                                projectSettings__isActive: element.result?.workflowStepResultEventTriggers?.length > 0
                                            }"></i>
                                            <i class="fas fa-edit fingerCursor" 
                                            [tippy]="'General.Label.ShowWizard' | texttransform"
                                            [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}"
                                                (click)="showResultWizard(element.result)" *ngIf="element.isInfoLine != true"></i>
                                            <label *ngIf="applicationInfoService.isDeveloper"
                                                class="projectSettings__content projectSettings__content__result__id">{{
                                                element.result.id }}</label>
                                            <label class="projectSettings__content projectSettings__content__result__id">
                                                <div class="workflowNonExpert__result__count">
                                                    <div *ngIf="getResultCount(element.result.id) == 0" class="workflowExpertNameLine__deleteResult">
                                                        <i class="fas fa-trash everleadCoral fingerCursor" (click)="deleteResult(element.step, element.result, dataSource, element)"></i>
                                                    </div>
                                                    <div class="workflowNonExpert__result__count__content" *ngIf="getResultCount(element.result.id) != 0"
                                                        [tippy]="getResultCountTitle(element.result.id)" [tippyOptions]="{theme:'everleadBlue'}">
                                                        {{ getResultCount(element.result.id) }}
                                                    </div>
                                                </div> 
                                            </label>
                                            <input [(ngModel)]="element.result.defaultName" *ngIf="element.result" (click)="showDetails(element)"
                                                class="workflowExpertNameLine__input" (change)="saveResult(element.result)">
                                            <app-wizard-translations [array]="element.result" updateBaseArrayExternaldatasourceId=570
                                                [exclude]="excludeItems" mode="3" *ngIf="!element.isInfoLine"></app-wizard-translations>    
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
            
                            <ng-container matColumnDef="emailTemplate">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.EMailTemplate' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element" id="scrollIntoViewEmailTemplate">
                                    <select [(ngModel)]="element.result.emailTemplateId" (change)="saveResult(element.result)"
                                        *ngIf="element.result" class="projectSettings__dropdown">
                                        <option [ngValue]=null></option>
                                        <option *ngFor="let emailTemplate of emailTemplates" [ngValue]="emailTemplate.id">
                                            {{ emailTemplate.defaultName }}
                                        </option>
                                    </select>
                                </td>
                            </ng-container>
            
                            <ng-container matColumnDef="subResults">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.EditSubResults' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <i class="fingerCursor leadStateStyleMargin leadStateStyleIcons fas fa-poll-h"
                                        (click)="callSubquestionForm(element.result)" *ngIf="!element.isInfoLine"></i>
                                </td>
                            </ng-container>
            
                            <label class="cleanFromMarginAndPadding fingerCursor">{{
                                'ProcessDesign.Label.EditSubResults' | texttransform }}
                            </label>
                            <ng-container matColumnDef="isActive">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.Active' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="element.isInfoLine != true">
                                        <input [(ngModel)]="element.result.isActive" (change)="saveResult(element.result)"
                                            type="checkbox" *ngIf="element.result" class="projectSettings__inputFull">
                                    </div>
                                </td>
                            </ng-container>
            
                            <ng-container matColumnDef="order">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.ResultOrder' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <input [(ngModel)]="element.result.resultOrder" (change)="saveResult(element.result)"
                                        class="projectSettings__inputFull" type="number" *ngIf="element.result">
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="leadState">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.Leadstate' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <select [(ngModel)]="element.result.leadStatusId" (change)="saveResult(element.result)"
                                        *ngIf="element.result" class="projectSettings__dropdown">
                                        <option [ngValue]=null></option>
                                        <option *ngFor="let leadState of leadStates" [ngValue]="leadState.lookupTable.id">
                                            {{ leadState.lookupTable.defaultName }}
                                        </option>
                                    </select>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="leadStateDesc">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.LeadstateDesc' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="element.result?.leadStatusId != null" class="projectSettings__leateStateDesc">
                                        {{ getLeadStateDescription(element.result.leadStatusId) | texttransform }}
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="nextStep">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.NextStep' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="projectSettings__nextStep">
                                        <select *ngIf="element.step != null && element.result != null"
                                            class="projectSettings__dropdown projectSettings__inputFull"
                                            [(ngModel)]="element.result.nextStepWorkflowId" (change)="nextStepChanged(element.result)">
                                            <option [ngValue]=null></option>
                                            <option *ngFor="let workflow of workflowList" [ngValue]="workflow.id">
                                                {{ workflow.defaultName }}
                                            </option>
                                        </select>
                                        <select *ngIf="element.step != null && element.result != null"
                                            class="projectSettings__dropdown projectSettings__inputFull"
                                            [(ngModel)]="element.result.nextStepId" (change)="saveResult(element.result)">
                                            <option *ngFor="let step of getWorkflowSteps(element.result.nextStepWorkflowId)"
                                                [ngValue]="step.id">
                                                {{ step.defaultName }}
                                            </option>
                                        </select>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="nextStepCampaign">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.NextStepCampaign' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <select *ngIf="element.step != null && element.result != null && element.result?.nextStepId != null"
                                        class="projectSettings__dropdown" [(ngModel)]="element.result.nextStepCampaignId"
                                        (change)="saveResult(element.result)">
                                        <option [ngValue]=null></option>
                                        <option *ngFor="let campaign of campaigns" [ngValue]="campaign.id">
                                            {{ campaign.defaultName }}
                                        </option>
                                    </select>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="qm">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.QM' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <select *ngIf="element.step != null && element.result != null" class="projectSettings__dropdown"
                                        [(ngModel)]="element.result.qmUserGroupId" (change)="saveResult(element.result)">
                                        <option [ngValue]=null></option>
                                        <option *ngFor="let qmUserGroup of qmUserGroups" [ngValue]="qmUserGroup.id">
                                            {{ qmUserGroup.defaultName }}
                                        </option>
                                    </select>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="callAttempt">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.IsCallAttempt' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <input *ngIf="element.result" [(ngModel)]="element.result.isCallAttempt" type="checkbox"
                                        (change)="saveResult(element.result)" class="projectSettings__inputFull">
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="netCall">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.IsNetResult' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <input *ngIf="element.result" [(ngModel)]="element.result.isNetResult" type="checkbox"
                                        (change)="saveResult(element.result)" class="projectSettings__inputFull">
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="dueInDays">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.DueInDays' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <input *ngIf="element.result" [(ngModel)]="element.result.defaultDueInDays"
                                        class="projectSettings__inputFull" type="number"
                                        (change)="saveResult(element.result, false,'defaultDueInDays')">
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="maxDueInDays">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.DueInDaysMax' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <input *ngIf="element.result" [(ngModel)]="element.result.dueInDaysMax"
                                        class="projectSettings__inputFull" type="number"
                                        (change)="saveResult(element.result, false, 'dueInDaysMax')">
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="closeAfterXResults">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.CloseTaskAfterXResults' | texttransform }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <input *ngIf="element.result" [(ngModel)]="element.result.closeTaskAfterXResults" class=""
                                        type="number" (change)="saveResult(element.result, false, 'closeTaskAfterXResults')">
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="closingResultAfterXResults">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.CloseTaskAfterXResultsResult' |
                                    texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <select *ngIf="element.step != null && element.result != null" class="projectSettings__dropdown"
                                        [(ngModel)]="element.result.closingResultIdAfterXResults" (change)="saveResult(element.result)">
                                        <option [ngValue]=null></option>
                                        <option *ngFor="let result of getStepResults(element.step.id)" [ngValue]="result.id">
                                            {{ result.defaultName }}
                                        </option>
                                    </select>
                                </td>
                            </ng-container>
            
                            <ng-container matColumnDef="consentRequired">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.ConsentRequired' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <input *ngIf="element.result" [(ngModel)]="element.result.isConsentRequired" type="checkbox"
                                        (change)="saveResult(element.result)" class="projectSettings__inputFull">
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="contactRequired">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.ContactRequired' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <input *ngIf="element.result" [(ngModel)]="element.result.isContactRequired" type="checkbox"
                                        (change)="saveResult(element.result)" class="projectSettings__inputFull">
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="personalFollowUp">
                                <th mat-header-cell *matHeaderCellDef>{{ 'ProcessDesign.Label.PersonalFollowUp' | texttransform }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <input *ngIf="element.result" [(ngModel)]="element.result.isPersonalFollowup" type="checkbox"
                                        (change)="saveResult(element.result)" class="projectSettings__inputFull">
                                </td>
                            </ng-container>
            
                            <ng-container matColumnDef="progress">
                                <th mat-header-cell *matHeaderCellDef>
                                    <!-- {{ 'ProcessDesign.Label.Progress' | texttransform }} -->
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="!element.isInfoLine">
                                        <div class="workflowNonExpert__result__progress__container"
                                            [tippy]="getProgressTitle(element.result, 4, visitedPages)" [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}">
                                            <div class="workflowNonExpert__result__progress__bar customTextControlWrapper__greenBackground"
                                                [style.width]="getProgressWidth(element.result, 4, visitedPages)"></div>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
            
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 
                                projectSettings__infoRowStep: row.isInfoLine == true, 
                                projectSettings__infoRowWorkflow: row.isInfoLineWorkflow == true, 
                                projectSettings__newRow: row.result?.defaultName == 'New',
                                hideInDom: 
                                    ((row.result?.isActive == false || row.step?.isActive == false || row.result?.isActive == false) && showOnlyActive) 
                                    || itemIsFiltered(row) == true || row.workflow?.projectId == null,
                                projectSettings__isInactive: (row.result?.isActive == false || row.step?.isActive == false || row.result?.isActive == false)
                            }"></tr>
                        </table>
                    </div>
                </div>

                <div *ngIf="!applicationInfoService.expertMode && loadingData == false" class=" everleadBlueColor">
                    <div class="" *ngIf="selectedWorkflow">
                        <div class="workflowNonExpert__workflow">
                            <div class="workflowNonExpert__workflow__name ">
                                <label class="flexCenter">
                                    <app-jj-icons type="tree-view" [pointer]="false" [width]="15" [height]="15"
                                    [tippy]="'ProcessDesign.Label.Workflow' | texttransform"
                                    [tippyOptions]="{placement:'bottom'}"></app-jj-icons>
                                 <!--    <svg-icon src="assets/images/svg/process/icon-process_process.svg" class="processSvg"
                                        [tippy]="'ProcessDesign.Label.Workflow' | texttransform"
                                        [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}"
                                        [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon> --> 
                                        {{ selectedWorkflow.defaultName }}
                                </label>
                                <app-wizard-translations [array]="selectedWorkflow" updateBaseArrayExternaldatasourceId=531
                                    [exclude]="excludeItemsWorkflow" mode="3"></app-wizard-translations>
                            </div>
                            <div class="workflowNonExpert__workflow__isActive">
                                <app-checkbox [(ngModel)]="selectedWorkflow.isActive" [disabled]="true" label="ProcessDesign.Label.Active"></app-checkbox>
                              <!--   <input [(ngModel)]="selectedWorkflow.isActive" type="checkbox" disabled>
                                {{ 'ProcessDesign.Label.Active' | texttransform }} -->
                            </div>
                            <div class="workflowNonExpert__workflow__edit">
                  <!--               <i class="fa-lg fas fa-edit fingerCursor" [tippy]="'General.Label.ShowWizard' | texttransform"
                                [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}"
                                    (click)="showWizard(selectedWorkflow)"></i> -->
                                    <app-jj-icons type="editor" [width]="20" [height]="20" 
                                    [tippy]="'General.Label.ShowWizard' | texttransform"
                                    [tippyOptions]="{placement:'bottom'}" (click)="showWizard(selectedWorkflow)"></app-jj-icons>
                            </div>
                            <div (click)="expandOrCollapseAll()" class="fingerCursor">
                                <app-jj-icons [type]=" expandAll ? 'caret-down' : 'caret-up'"></app-jj-icons>
                              <!--   <i class="fa-lg fas " [ngClass]="{
                                    'fa-chevron-down': expandAll,
                                    'fa-chevron-up': !expandAll
                                }"></i> -->
                            </div>
                        </div>
                        <span id="processDesignSpan2"></span>
                        <div class="workflowNonExpert__steps" [style.max-height]="getTableHeight('processDesignSpan2', 50)">
                            <div *ngFor="let step of selectedWorkflow.workflowSteps" class="process__carroselBorder">
                                <div class="workflowNonExpert__step">
                                    <div class="workflowNonExpert__step__name">
                                        <label class="flexCenter">
                                            <app-jj-icons type="tree-structure" [tippy]="'ProcessDesign.Label.Step' | texttransform" [tippyOptions]="{placement:'bottom'}" [width]="20" [height]="20"></app-jj-icons>
<!--                                             <i class="fas fa-shoe-prints"
                                                [tippy]="'ProcessDesign.Label.Step' | texttransform" [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}"></i> -->
                                            {{ step.defaultName }}</label>
                                        <app-wizard-translations [array]="step" updateBaseArrayExternaldatasourceId=568
                                            [exclude]="excludeItemsWorkflow" mode="3"></app-wizard-translations>
                                    </div>
                                    <div class="workflowNonExpert__workflow__isActive">
                                        <app-checkbox [(ngModel)]="step.isActive" [disabled]="true" label="ProcessDesign.Label.Active"></app-checkbox>
<!--                                         <input [(ngModel)]="step.isActive" type="checkbox" disabled>
                                        {{ 'ProcessDesign.Label.Active' | texttransform }} -->
                                    </div>
                                    <div class="workflowNonExpert__workflow__edit">
                                        <app-jj-icons 
                                        type="edit" [width]="20" [height]="20" 
                                        (click)="showStepWizard(step)"
                                        [tippy]="'General.Label.ShowWizard' | texttransform" [tippyOptions]="{placement:'bottom'}"></app-jj-icons>
                                 <!--        <i class="fa-lg fas fa-edit fingerCursor" [tippy]="'General.Label.ShowWizard' | texttransform" [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}"
                                            (click)="showStepWizard(step)"></i> -->
                                    </div>
                                    <div (click)="toggleStepCollapse(step)" class="fingerCursor">
                                        <app-jj-icons [type]="stepIsCollapsed(step) ? 'caret-down' : 'caret-up'"></app-jj-icons>
                                      <!--   <i class="fa-lg fas " [ngClass]="{
                                            'fa-chevron-down': stepIsCollapsed(step),
                                            'fa-chevron-up': !stepIsCollapsed(step)
                                        }"></i> -->
                                    </div>
                                </div>
                                <div class="workflowNonExpert__result__overflow workflowNonExpert__result__margin"
                                    *ngIf="!stepIsCollapsed(step)">
                                    <div class="workflowNonExpert__result__subheader">
                                        <div class="workflowNonExpert__result__subheader__spacer">{{
                                            'ProcessDesign.Label.Results' | texttransform }}</div>
                                        <div class="workflowNonExpert__result__header__leadstate">{{
                                            'ProcessDesign.Label.Leadstate' | texttransform }}</div>
                                        <div class="workflowNonExpert__result__header__nextStep">{{
                                            'ProcessDesign.Label.NextStepWorkflow' | texttransform }}</div>
                                        <div class="workflowNonExpert__result__header__nextStep">{{
                                            'ProcessDesign.Label.NextStepStep' | texttransform }}</div>
                                        <div class="workflowNonExpert__result__header__isActive">{{
                                            'ProcessDesign.Label.Active' | texttransform }}</div>
                                        <div class="workflowNonExpert__result__header__progress">
                                            {{'ProcessDesign.Label.Progress' | texttransform }}
                                        </div>
                                    </div>
            
                                    <div *ngFor="let result of step.results" class="workflowNonExpert__result">
                                        <div class="workflowNonExpert__result__name">
                                            <label class="flexCenter">
                                                <app-jj-icons type="flow-arrow" [width]="20" [height]="20"></app-jj-icons>
                                <!--                 <svg-icon src="assets/images/svg/process/icon-process_result.svg" class="processSvg"
                                                    [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon> -->
                                                <app-jj-icons type="calendar-dots" (click)="showEventWizard(results, step)"
                                                [tippy]="'EventAssignment.Label.Page1' | texttransform"
                                                [tippyOptions]="{placement:'bottom'}" [width]="20" [height]="20"></app-jj-icons>    
                                          <!--       <i class="fas fa-calendar-week fingerCursor" style="margin-right: 5px;"
                                                    [tippy]="'EventAssignment.Label.Page1' | texttransform"
                                                    [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}"
                                                    (click)="showEventWizard(result, step)"></i> -->
                                                <div class="workflowNonExpert__result__name" [tippy]="getResultCountTitle(result.id)" [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}">
                                                    <div class="workflowNonExpert__result__count">
                                                        <div class="workflowNonExpert__result__count__content">
                                                            {{ getResultCount(result.id) }}
                                                        </div>
                                                    </div> 
                                                    <div class="workflowNonExpert__result__count__name">
                                                        {{ result.defaultName }}
                                                    </div>                                       
                                                </div>                                
                                            </label>
                                            <app-wizard-translations [array]="result" updateBaseArrayExternaldatasourceId=570
                                                [exclude]="excludeItemsWorkflow" mode="3"></app-wizard-translations>
                                        </div>
                                        <div class="workflowNonExpert__nextStep">
                                            <select [(ngModel)]="result.leadStatusId" (change)="saveResult(result)" *ngIf="result"
                                                class="projectSettings__dropdown">
                                                <option [ngValue]=null></option>
                                                <option *ngFor="let leadState of leadStates" [ngValue]="leadState.lookupTable.id">
                                                    {{ leadState.lookupTable.defaultName }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="workflowNonExpert__nextStep">
                                           <!--  <app-select-input 
                                            [(ngModel)]="result.nextStepWorkflowId"
                                            (valueChange)="nextStepChanged(result)"
                                            [options]="workflowList"
                                            bind="id"
                                            display="defaultName"></app-select-input> -->

                                          <select [(ngModel)]="result.nextStepWorkflowId" (change)="nextStepChanged(result)">
                                                <option [ngValue]=null></option>
                                                <option *ngFor="let workflow of workflowList" [ngValue]="workflow.id">
                                                    {{ workflow.defaultName }}
                                                </option>
                                            </select> 

                                           <!--  <app-select-input
                                            [(ngModel)]="result.nextStepId"
                                            (valueChange)="saveResult(result)"
                                            [options]="getWorkflowSteps(result.nextStepWorkflowId)"
                                            bind="id"
                                            display="defaultName"></app-select-input> -->

                                            <select [(ngModel)]="result.nextStepId" (change)="saveResult(result)">
                                                <option *ngFor="let step of getWorkflowSteps(result.nextStepWorkflowId)"
                                                    [ngValue]="step.id">
                                                    {{ step.defaultName }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="workflowNonExpert__result__isActive">
                                            <input [(ngModel)]="result.isActive" type="checkbox">
                                            <!-- <app-checkbox [(ngModel)]="result.isActive" ></app-checkbox> -->
                                        </div>
                                        <div class="workflowNonExpert__result__progress">
                                            <div class="workflowNonExpert__result__progress__container"
                                                [tippy]="getProgressTitle(result, 4, visitedPages)" [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}">
                                                <div class="workflowNonExpert__result__progress__bar customTextControlWrapper__greenBackground"
                                                    [style.width]="getProgressWidth(result, 4, visitedPages)"></div>
                                            </div>
                                        </div>
                                        <div class="workflowNonExpert__workflow__edit">
                                            <app-jj-icons type="trash" (click)="deleteResult(step, result)" [width]="20" [height]="20" *ngIf="getResultCount(result.id) == 0"></app-jj-icons>
                                           <!--  <i class="fa-lg fas fa-trash fingerCursor" (click)="deleteResult(step, result)"
                                                *ngIf="getResultCount(result.id) == 0"></i> -->

                                                <app-jj-icons type="editor" (click)="showWorkflowStepResultWizard(result)"
                                                [tippy]="'General.Label.ShowWizard' | texttransform"
                                                [tippyOptions]="{placement:'bottom'}"
                                                [width]="20"
                                                [height]="20"
                                                ></app-jj-icons>
            
                                          <!--   <i class="fa-lg fas fa-edit fingerCursor"
                                                [tippy]="'General.Label.ShowWizard' | texttransform"
                                                [tippyOptions]="{theme:'everleadBlue'}"
                                                (click)="showWorkflowStepResultWizard(result)"></i> -->
                                        </div>
                                    </div>
                                    <div class="workflowNonExpert__newItem">
                                        <app-main-button
                                        type="borderSmall"
                                        svg="plus"
                                        (onClick)="createNewStepResult(step)"
                                        label="ProcessDesign.Label.CreateNewResultForThisStep"></app-main-button>
                                       <!--  <i class="fas fa-plus"></i>
                                        <label class="fingerCursor" (click)="createNewStepResult(step)">{{
                                            'ProcessDesign.Label.CreateNewResultForThisStep' | texttransform }}</label> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                   <!--      <div class="workflowNonExpert__newItem fingerCursor" (click)="createNewStep(selectedWorkflow)">
                            <i class="fas fa-plus"></i>
                            {{ 'ProcessDesign.Label.CreateNewStep' | texttransform }}
                        </div> -->
                    </div>
                </div>

            </div>
        </div>

    </div>

</div>

<button *ngIf="buttons.length === 0" class="everleadActionButton" (mouseenter)="color='primaryLight'"
    (mouseleave)="color='fontDark'" [id]="id" [disabled]="disabled" (click)="buttonClick()"
    [class.disabled]="disabled"><app-jj-icons [type]="svg" [width]="16" [height]="16"
        [color]="color"></app-jj-icons>
</button>

<button *ngIf="buttons.length > 0"
    [ngxTippy]="actionbuttons"
    [tippyProps]="actionbuttonsProps" 
    class="everleadActionButton" (mouseenter)="color='primaryLight'"
    (mouseleave)="color='fontDark'" [id]="id" [disabled]="disabled" [class.disabled]="disabled">
    <app-jj-icons [type]="svg" [width]="16" [height]="16" [color]="color"></app-jj-icons>
</button>

<div #actionbuttons>
    <div class="everleadActionButton__tooltip">
        <button class="everleadActionButton__buttons" [ngClass]="{ hideInDom: button.hide } " *ngFor="let button of buttons" (click)="!button.disabled ? arrayButtonClick(button.callback) : null" [disabled]="button.disabled" [class.disabled]="button.disabled">
            <app-jj-icons [type]="button.svg" [color]="button.disabled ? 'disabled' : 'secondary'" [width]="button.width ? button.width : 20" [height]="button.width ? button.width : 20"></app-jj-icons>
            <label class="everleadActionButton__buttons__label" [class.disabled]="button.disabled">{{ button.label | texttransform }}</label>
        </button>
    </div>
</div>
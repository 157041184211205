
<div [ngClass]="{'fieldText': true , 'fieldText__required': isRequired }">
  <label>{{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}
    <span *ngIf="controlDefinition.isRequired"> *</span>

  </label>

  <div class="fieldText__inner">
    <input *ngIf="inputType == 'text'" type="text" [disabled]="!checkIfControlIsEnabled()"
      [placeholder]="getPlaceholder() | texttransform" (change)="checkInputValue()" [tippy]="controlValue"
      [(ngModel)]="controlValue" (keydown)="keyDown()">

    <input *ngIf="inputType == 'number'" type="number" [disabled]="!checkIfControlIsEnabled()"
      [placeholder]="getPlaceholder() | texttransform" (change)="checkInputValue()" [tippy]="controlValue"
      [(ngModel)]="controlValue" (keydown)="keyDown()" onlynumber>
  </div>
</div>



<!-- <div class="customTextControlWrapper">
  <label class="customTextControlWrapper__label" [ngClass]="{ customTextControlWrapper__coralColor : isRequired }"
  >
    {{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}
    <span *ngIf="controlDefinition.isRequired"> *</span>
  </label>

  <div class="customTextControlWrapper__valueWrapper">
    <input *ngIf="inputType == 'text'"
      class="customTextControlWrapper__textbox"
      type="text"
      [disabled]="!checkIfControlIsEnabled()"
      [placeholder]="getPlaceholder() | texttransform"
      (change)="checkInputValue()"
      [tippy]="controlValue"
      [(ngModel)]="controlValue"
      (keydown)="keyDown()"
    >
    <input *ngIf="inputType == 'number'"
      class="customTextControlWrapper__textbox"
      type="number"
      [disabled]="!checkIfControlIsEnabled()"
      [placeholder]="getPlaceholder() | texttransform"
      (change)="checkInputValue()"
      [tippy]="controlValue"
      [(ngModel)]="controlValue"
      (keydown)="keyDown()"
      onlynumber
    >
  </div>

</div>
 -->

<div class="nonExpertView__wrapper" [ngClass]=" {
    hideInDom: checkIfItemNeedsToBeHidden()
}" (click)="datarightsService.selectedGroup = dataRightGroup">

    <div class="nonExpertView__main " [style.margin-left.px]="30 * level" [ngClass]="{
        dataRightsItem__isSelected: datarightsService.selectedGroup == dataRightGroup
    }">
        <div class="nonExpertView__chevron" *ngIf="level > 0">
            <app-jj-icons pointer=false [type]="datarightsService.isExpanded[dataRightGroup.id] != true ? 'caret-circle-right-fill' : 'caret-circle-right'" [width]="20" [height]="20"></app-jj-icons>
        </div>        
        <div class="nonExpertView__name">            
            <label>{{ dataRightGroup.defaultName }}</label>
        </div>
        <div class="nonExpertView__aditionalInformation">
            <!-- <div class="nonExpertView__bonus" [title]="getAssignedFilterToolTip()"> -->
            <div class="nonExpertView__bonus" >
                <app-jj-icons *ngIf="dataRightGroup.isReadOnly" type="lock-simple-fill" [tippy]="'DataRights.Label.AccessLevelOnlyRead' | texttransform" [tippyOptions]="{placement:'bottom'}" [width]="20" [height]="20"></app-jj-icons>
                <app-jj-icons *ngIf="!dataRightGroup.isReadOnly" type="lock-simple-open" [tippy]="'DataRights.Label.AccessLevelFullAccess' | texttransform" [tippyOptions]="{placement:'bottom'}" [width]="20" [height]="20"></app-jj-icons>
            </div>

            <div class="nonExpertView__bonus">
                <app-jj-icons type="user-list" [tippy]="getAssignedUserToolTip()" [tippyOptions]="{placement:'bottom'}" [width]="20" [height]="20"></app-jj-icons> {{ dataRightGroup.users?.length }}
            </div>
            <div class="nonExpertView__bonus">
                <app-jj-icons type="funnel" [tippy]="getAssignedFilterToolTip()" [tippyOptions]="{placement:'bottom'}" [width]="20" [height]="20"></app-jj-icons> {{ dataRightGroup.filters?.length }}
               <!--  <i class="fas fa-filter" [tippy]="getAssignedFilterToolTip()"[tippyOptions]="{theme:'everleadRed', placement:'bottom'}"></i>{{ dataRightGroup.filters?.length }} -->
            </div>
            <div class="nonExpertView__bonus"  
                (click)="editDataRightGroup(dataRightGroup)">
                <app-jj-icons type="editor" [tippy]="'DataRights.Label.EditGroup' | texttransform" [tippyOptions]="{placement:'bottom'}" [width]="20" [height]="20"></app-jj-icons>
            </div>

            <div class="nonExpertView__bonus">
                <app-jj-icons type="plus" (click)="createDataRightsGroup(dataRightGroup)" [tippy]="'DataRights.Label.CreateNewSubGroup' | texttransform" [tippyOptions]="{placement:'bottom'}" [width]="20" [height]="20"></app-jj-icons>
            </div>

            <div class="nonExpertView__bonus" (click)="deleteDataRightsGroup(dataRightGroup)" [ngClass]="{ 
                hideInDom: dataRightGroup.children?.length > 0 ||
                    dataRightGroup.filters?.length > 0 ||
                    dataRightGroup.users?.length > 0}">
                <app-jj-icons type="trash" [tippy]="'DataRights.Label.DeleteGroup' | texttransform" [tippyOptions]="{placement:'bottom'}" [width]="20" [height]="20"></app-jj-icons>
            </div>


            <div class="nonExpertView__buttons__container dataRightsItem__buttons__container__chevron">
                <app-jj-icons *ngIf="expandAll == false && dataRightGroup?.children?.length > 0" [type]="datarightsService.isExpanded[dataRightGroup.id] != true ? 'caret-down' : 'caret-up'" (click)="toggleExpansion()"></app-jj-icons>
            </div>

        </div>
    </div>
    <!-- <app-main-button
    [style.margin-left.px]="30 * level" 
    type="borderSmall"
    svg="plus"
    label="DataRights.Label.CreateNewSubGroup"
    (onClick)="createDataRightsGroup(dataRightGroup)"
    ></app-main-button> -->
    <div class="nonExpertView__newSubGroup"></div>


    <div class="nonExpertView__expandedArea" *ngIf="datarightsService.isExpanded[dataRightGroup.id] == true || expandAll">
        <app-datarights-item *ngFor="let dataRightGroupChild of dataRightGroup.children"
            [dataRightGroup]="dataRightGroupChild" 
            [level]="newLevel()" 
            [availableUsers]="availableUsers"
            [availableFilters]="availableFilters"
            [expandAll]="expandAll"
            [generalFilterString]="generalFilterString"
            [generalFilterUser]="generalFilterUser"
            [generalFilterAccessRights]="generalFilterAccessRights"
        ></app-datarights-item>
    </div>
</div>
<div class="followupRow" [ngClass]="{
    followupRowIsOdd: isOdd
}">
    <div class="followupCol__one" *ngIf="parentHasAi">
        <app-blue-marker *ngIf="isAi" label="EntityTable.Label.AI" svg="thumbs-up"></app-blue-marker>
    </div>

    <div class="followupCol__two">
        <div class="followup__card">
            <div class="accountName ellipsis">{{ getAccountName(followUp) }}</div>
            <div class="contactName ellipsis">{{ getContactName(followUp) }}</div>
            <div class="followup__card__status">
                <app-status [blue]="isTomorrow" [green]="isToday && !isInPast" [yellow]="isSoon" [red]="isInPast" [isOdd]="isOdd">
                    <div class="ellipsisText" *ngIf="isToday && !isInPast">
                        {{ 'WebsiteDashboard.Label.Today' | texttransform }} | <span class="noWeight">{{ followUp.followUpDate | date: 'medium' }} </span>
                    </div>
                    <div class="ellipsisText" *ngIf="isSoon">
                        {{ 'WebsiteDashboard.Label.Soon' | texttransform }} | <span class="noWeight">{{ followUp.followUpDate | date: 'medium' }}</span>
                    </div>
                    <div class="ellipsisText" *ngIf="isTomorrow">
                        {{ 'WebsiteDashboard.Label.Tomorrow' | texttransform }} | <span class="noWeight">{{ followUp.followUpDate | date: 'medium' }}</span>
                    </div>
                    <div class="ellipsisText" *ngIf="isInPast">
                        {{ 'WebsiteDashboard.Label.InThePast' | texttransform }} | <span class="noWeight">{{ followUp.followUpDate | date: 'medium' }}</span>
                    </div>
                </app-status>
<!--                 <div class="followupdate" [ngClass]="{ isPastDate: isInPast }"> | {{ followUp.followUpDate | date: 'medium' }}</div> -->
            </div>
        </div>
    </div>
    <div class="followupCol__three">
      <div class="iconPlaceholder">
        <app-jj-icons type="phone" color="primaryLight" [width]="26" height="26"></app-jj-icons>
      </div>

    </div>
</div>


<!--
<div class="followUpItem" [ngClass]="{ aiBorder: isAi }">
    <div class="followUpItem__logo " [ngClass]="{ everleadBlueColor: !isAi, aiBox: isAi}">
        <div *ngIf="!isAi">
            <app-blue-marker label="AI" svg="arrow-up-right"></app-blue-marker>
            <app-jj-icons type="building" *ngIf="followUp.contactId == null"></app-jj-icons>
            <app-jj-icons type="user" *ngIf="followUp.contactId != null"></app-jj-icons>
            <svg-icon src="assets/images/svg/account_factory.svg" *ngIf="followUp.contactId == null" [svgStyle]="{ 'width.px': 42, 'height.px': 42 }"></svg-icon>
            <svg-icon src="assets/images/svg/dashboard_contact.svg" *ngIf="followUp.contactId != null" [svgStyle]="{ 'width.px': 42, 'height.px': 42 }"></svg-icon>
        </div>
        <div *ngIf="isAi">
            <app-blue-marker label="AI"></app-blue-marker>
            <svg-icon src="assets/images/svg/ai/everlead_ai_thumbs_up2.svg" class="aiIcon" [svgStyle]="{ 'width.px': 42, 'height.px': 42 }"></svg-icon>
        </div>
    </div>
    <div class="followUpItem__item">
        <div class="ellipsisText followUpItem__item__text followUpItem__item__border">{{
            getAccountName(followUp) }}</div>
        <div class="followUpItem__item__subtext followUpItem__item__border ellipsisText"
            *ngIf="followUp.contactId != null">
            <span class="everleadCoral">AP:</span>
            {{ getContactName(followUp) }}
        </div>
    </div>

    <div class="followUpItem__item">
        <div class="ellipsisText followUpItem__item__date" *ngIf="isToday && !isInPast">{{ 'WebsiteDashboard.Label.Today' | texttransform }}</div>
        <div class="ellipsisText followUpItem__item__date" *ngIf="isSoon">{{ 'WebsiteDashboard.Label.Soon' | texttransform }}</div>
        <div class="ellipsisText followUpItem__item__date dateTomorrow" *ngIf="isTomorrow">{{ 'WebsiteDashboard.Label.Tomorrow' | texttransform }}</div>

        <div class="ellipsisText followUpItem__item__date" *ngIf="isInPast">{{ 'WebsiteDashboard.Label.InThePast' | texttransform }}</div>
        <div class="followUpDate" [ngClass]="{ everleadCoral: isInPast }">{{ followUp.followUpDate | date: 'medium' }}</div>
    </div>

    <div class="followUpItem__type">
        <div class="followUpItem__type__icon">
            <app-jj-icons type="phone" color="primaryLight" [width]="30" height="30"></app-jj-icons>
        </div>
        <div class="followUpItem__type__icon"><svg-icon src="assets/images/svg/dashboard_phone.svg"></svg-icon></div>
    </div>
</div> -->

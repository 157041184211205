<div class="everleadToolBarHeader">
    <div class="everleadToolBarHeader__wrapper">
        <div *ngIf="applicationInfoService.applicationSettings['showFirstSteps'] == 'true' && ringcentralwebphoneService.session == null">
            <app-main-button (onClick)="applicationInfoService.showBanner = true" [label]="'DemoBanner.Label.VisitWebsite' | texttransform "></app-main-button>
        </div>


        <div *ngIf="checkIfTippIsAvailable()!=null" class="toolbar__item fingerCursor" (click)="showHelpContent()" (mouseenter)="showHelpContent()" [ngxTippy]="tooltipcontentInfo" [tippyProps]="{ trigger:'mouseenter click' }">
            <app-jj-icons type="question" [width]="30" [height]="30" [tippy]="'Toolbar.Label.ToggleHelp' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement:'bottom'}"></app-jj-icons>
        </div>

    <!-- <div class="toolbar__item" id="toolbarMail" (click)="callSection('mail')">
        <app-jj-icons type="envelope" [width]="30" [height]="30"></app-jj-icons>
    </div>

    <div class="toolbar__item" id="toolbarBell">
        <app-jj-icons type="bell-ringing" [width]="30" [height]="30"></app-jj-icons>
    </div> -->
    
    <div class="toolbar__item" id="toolbarUserLogo">
        <app-user-avatar component="menu"></app-user-avatar>
    </div>
</div>

</div>


<div class="mailShots">
    <div class="mailShots__header">
        <app-component-header [header]="applicationInfoService.breadCrumbText"></app-component-header>
        <div class="mailShots__nav">
            <app-main-button 
                type="small"
                label="General.Label.CreateNewWithDefault"
                (onClick)="createNewMailshot()"
                [disabled]="!userService.hasPermission('CanCreateMailshotList')"
                svg="plus"
                [tippy]="'General.Label.CreateNewWithDefault' | texttransform" [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}"
            ></app-main-button>

            <div class="filter">
                <div class="filterWrapper">
                    <input [(ngModel)]="filterString">
                    <app-jj-icons type="magnifying-glass" [width]="20" [height]="20"></app-jj-icons>
                </div>
            </div>
        </div>
    </div>

    <div class="mailShots__inner">
        <div class="mailShots__content">
            <ng-container *ngIf="loadingData == true">
                <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData'|texttransform}}
            </ng-container>

            <div *ngIf="loadingData == false" class="maxHeight">
                <div class="mailshotItem mailshotItem__header everleadSmallHeader">
                    <div class="mailshotItem__name">
                        {{ 'Mailshots.Label.Name' | texttransform }}
                    </div>        
                    <div class="mailshotItem__info">{{ 'Mailshots.Label.Info' | texttransform }}</div>        
                    <div class="mailshotItem__pool">{{ 'Mailshots.Label.Pool' | texttransform }}</div>        
                    <div class="mailshotItem__date mailshotItem__date__header">{{ 'Mailshots.Label.Date' | texttransform }}</div>        
                    <!-- <div class="mailshotItem__details mailshotItem__details__header">{{ 'Mailshots.Label.Details' | texttransform }}</div>         -->
                    <div class="mailshotItem__buttons mailshotItem__buttons__header">{{ 'Mailshots.Label.Options' | texttransform }}</div>
                </div>
                <div class="mailshotListWrapper">
                    <div *ngFor="let mailshot of mailshots" class="itemRow fingerCursor mailshotItemWrapper" [ngClass]="{ hideInDom: 
                        itemIsFiltered(mailshot) || mailshotPoolIsCurrentPool(mailshot) == false,
                        highlightRow: mailshot.id === highlightRowID
                    }" (click)="editMailshot(mailshot)">
                        <div class="mailshotItem" (mouseenter)="(highlightRow(mailshot.id))" >
                            <div class="mailshotItem__name">
                                <span class="mailshotItemOnlyMobile">{{ 'Mailshots.Label.Name' | texttransform }}</span>{{ mailshot.batchName }}                   
                            </div> 
                            <div class="mailshotItem__info">
                                <span class="mailshotItemOnlyMobile">{{ 'Mailshots.Label.Info' | texttransform }}</span>
                                <div class="mailshotItem__details__item">
                                    <app-jj-icons type="clock" [width]="20" [height]="20" [tippy]="'Mailshots.Label.CountOpen' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></app-jj-icons>
<!--                                     <i class="fas fa-clock" [tippy]="'Mailshots.Label.CountOpen' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></i> -->
                                    {{ mailshot.countOpen }}
                                </div>
                                <div class="mailshotItem__details__item">
                                    <app-jj-icons type="paper-plane-tilt" [width]="20" [heigth]="20" [tippy]="'Mailshots.Label.CountSend' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></app-jj-icons>
                                    {{ mailshot.countSent }}
                                </div>
                                <div class="mailshotItem__details__item">
                                     <app-jj-icons type="warning" [width]="20" [height]="20" [tippy]="'Mailshots.Label.CountBounced' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></app-jj-icons>
                                    {{ mailshot.countBounced }}
                                </div>
                                <div class="mailshotItem__details__item">
                                    <app-jj-icons type="receipt-x" [width]="20" [height]="20" [tippy]="'Mailshots.Label.CountCanceled' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></app-jj-icons>
                                    {{ mailshot.countCanceled }}
                                </div>
                            </div>        
                            <div class="mailshotItem__pool">
                                <span class="mailshotItemOnlyMobile">{{ 'Mailshots.Label.Pool' | texttransform }}</span>
                                {{ getMailShotPool(mailshot) }}
                            </div>        
                           
                            <div class="mailshotItem__date">
                                <span class="mailshotItemOnlyMobile">{{ 'Mailshots.Label.Date' | texttransform }}</span>
                                {{ mailshot.createdAt | date: 'medium' }}
                            </div>        
                            
                            <div class="mailshotItem__buttons" (click)="$event.stopPropagation()">                
                                <button [tippy]="'Mailshots.Label.CloneItem' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}" [disabled]="!userService.hasPermission('CanCreateMailshotList')"  class="mailShotButtonClone"
                                    (click)="cloneMailshot(mailshot)">
                                    <app-jj-icons type="copy" [width]="20" [height]="20"></app-jj-icons>
                                </button>
                                <button (click)="showMailshotDetails(mailshot)" class="mailShotButtonDetails">
                                    <app-jj-icons type="eye" [width]="20" [height]="20"></app-jj-icons>
                                </button>
                                <button (click)="deleteMailshot(mailshot)" [disabled]="!userService.hasPermission('CanDeleteMailshotList') || mailshot.startedAt != null"
                                    [ngClass]="{ everleadCoral: userService.hasPermission('CanDeleteMailshotList') && mailshot.startedAt == null}">
                                    <app-jj-icons type="trash" [width]="20" [height]="20" [color]="(!userService.hasPermission('CanDeleteMailshotList') || mailshot.startedAt != null) ? 'gray0' : 'secondary'"></app-jj-icons>
                                </button>
            
                                <div class="mailshotItem__buttons__spacer"></div>
            
                                <button (click)="stopMailshot(mailshot, 0)" [tippy]="'Mailshots.Label.StopBatch' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}" class="mailShotButtonStop"
                                    [disabled]="!userService.hasPermission('CanApproveSecurity.Module.MailshotList') || mailshot.startedAt == null">
                                     <app-jj-icons type="stop" [width]="20" [height]="20" [color]="(!userService.hasPermission('CanApproveSecurity.Module.MailshotList') || mailshot.startedAt == null) ? 'gray0' : 'secondary'"></app-jj-icons>         
                                </button>

                                <button (click)="startMailshot(mailshot)" [tippy]="'Mailshots.Label.StartBatch' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"
                                    [disabled]="!userService.hasPermission('CanApproveSecurity.Module.MailshotList') || mailshot.startedAt != null">
                                    <app-jj-icons type="play" [width]="20" [height]="20" [color]="(!userService.hasPermission('CanApproveSecurity.Module.MailshotList') || mailshot.startedAt != null) ? 'gray0' : 'secondary'"></app-jj-icons>
                                </button>
                                
                                <app-jj-icons type="prohibit" [width]="20" [height]="20" [tippy]="'Mailshots.Label.MailshotCanceled' | texttransform" 
                                *ngIf="mailshot.startedAt != null && mailshot.stoppedAt != null"
                                (click)="startMailshot(mailshot)"></app-jj-icons>
                                
                                <app-jj-icons type="seal-check" [width]="20" [height]="20" *ngIf="mailshot.completed == true" color="green"></app-jj-icons>
                           
                            </div>
                        </div>           
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

import { Component, HostListener, Injector, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Dictionary } from 'app/core/dictionary';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { promise } from 'selenium-webdriver';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { SettingsService } from 'app/shared/service/settings.service';

@Component({
  selector: 'app-custom-emaileditor',
  templateUrl: './custom-emaileditor.component.html',
  styleUrls: ['./custom-emaileditor.component.scss']
})
export class CustomEmaileditorComponent extends CustomControlBaseClass  implements OnInit {
  changes: Dictionary<string> = new Dictionary<string>();
  @Input() templateId = 2;
  @Input() taskEntityId = '1';
  @Input() contactId = null;
  @Input() dontReplaceTokens = false;
  selectedContact = null;
  template = null;
  emailTemplate = null;
  selectedContent = null;
  contents = null;
  innerHTML = null;
  editableContentMinWidth = 250;
  originalX = 0;
  grabber = false;
  showPreviewOnly = false;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) { 
    if (!this.grabber) { return; }
    this.resizer(event.clientX - this.originalX); this.originalX = event.clientX;
  }

  @HostListener('document:mouseup', ['$event']) onMouseUp(event: MouseEvent) { 
    this.grabber = false; 
    this.applicationInfoService.userSettings['grabberWidthEmailEditor'] = this.editableContentMinWidth;
    this.settingService.updateSecurityUserSettingsJson();
  }

  resizer(offsetX: number) { 
    this.editableContentMinWidth += offsetX; 
    if (this.editableContentMinWidth < 20) { this.editableContentMinWidth = 20; }    
  }

  constructor(
    private injector: Injector,
    private emailtemplateService: EmailtemplateService,
    private domSanitizer: DomSanitizer,
    private externaldatasourceService: ExternaldatasourceService,
    private settingService: SettingsService
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;    
  }

  ngOnInit(): void {
    this.controlValue = 1;    
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.userSettings['grabberWidthEmailEditor'])) {
      this.editableContentMinWidth = this.applicationInfoService.userSettings['grabberWidthEmailEditor'];
    }

    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['bookingPreviewEMailTemplateId'])) {
      this.templateId = this.applicationInfoService.miscSettings['bookingPreviewEMailTemplateId'];
      this.showPreviewOnly = true;
      this.applicationInfoService.miscSettings['bookingPreviewEMailTemplateId'] = null;
      if (this.applicationInfoService.currentContact != null) {
        this.contactId = this.applicationInfoService.currentContact.id;
      }
    }
    this.getEMailContents();
  }

  getEMailContents() {
    let languageId = this.commonService.getContactLanguageId(2);
    const promiseArray: Promise<any>[] = [];
    if (this.contactId != null) {
      promiseArray.push(this.externaldatasourceService.executeExternalDataSource(483, [this.contactId, 'languageId firstName lastName']).then(result => {
        this.selectedContact = result;
        if (result.languageId != null) {
          languageId = result.languageId;
        }
      }));
    }
    Promise.all(promiseArray).then(() => {
      this.emailtemplateService.getEMailTemplate(this.templateId, languageId, true)
      .then(getEMailTemplateResult => {
        setTimeout(() => { 
          const grabberElement = document.getElementById('grabber');
          if (grabberElement) {
            grabberElement.addEventListener('mousedown', (event) => { 
              this.grabber = true; 
              this.originalX = event.clientX;         
            });  
          }
        }, 2000);
        this.emailTemplate = getEMailTemplateResult;
        this.contents = JSON.parse(JSON.stringify(this.emailTemplate.usedEmailTemplateContents));

        console.log('contents', this.contents);
        
        this.contents.forEach(emailContent => {
          this.renderContent(emailContent, emailContent.content);
          if (this.selectedContact == null) {
            emailContent.content = this.emailtemplateService.renderEMail(emailContent.content, [], false, null, this.dontReplaceTokens);
          } else {
            emailContent.content = this.emailtemplateService.renderEMail(emailContent.content, [], true, this.selectedContact);
          }          
        });

        if (this.contents.length > 0) {
          this.selectedContent = this.contents[0]; 
          this.renderContent();
        }
      });  
    });
  }

  renderContent(content = null, contentItem = null) {    
    this.applicationInfoService.miscSettings['templateOverwriteChanges'] = null;
    if (content != null) {
      if (this.commonService.isNullOrUndefined(this.applicationInfoService.entities.Item(this.taskEntityId).data.uiTemplateContentOverride)) {
        this.applicationInfoService.entities.Item(this.taskEntityId).data.uiTemplateContentOverride = [];
      }
      if (this.changes.ContainsKey(content.defaultName)) {
        this.changes.Set(content.defaultName, contentItem)
      } else {
        this.changes.Add(content.defaultName, contentItem);
      }
      this.applicationInfoService.entities.Item(this.taskEntityId).data.uiTemplateContentOverride = this.changes;
      this.applicationInfoService.miscSettings['templateOverwriteChanges'] = this.changes.items;
    }
    
    let renderedContent = '';
    if (this.selectedContact == null) {      
      renderedContent = this.emailtemplateService.renderEMail(this.emailTemplate.bodyHtml, this.contents, false, null, true)      
    } else {      
      renderedContent = this.emailtemplateService.renderEMail(this.emailTemplate.bodyHtml, this.contents, true, this.selectedContact)    
    } 
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(renderedContent);  
    this.applicationInfoService.miscSettings['emailEditorLastRenderedContent'] = renderedContent;
  }
}

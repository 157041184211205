<div id="breadCrumbAndMenu" class="breadCrumbAndMenu" *ngIf="applicationInfoService.noProject == false">
    <div class="breadCrumbAndMenu__wrapper" [ngClass]="{ breadCrumbAndMenuNavigationExpandedFixed: applicationInfoService?.navigationExpandedFixed }">     
        <div class="breadCrumbAndMenuItem">
            <div class="breadCrumbAndMenu__label">
                <label class="ellipsisText"><span
                    class="breadCrumbAndMenu__label__section">{{
                    applicationInfoService.breadCrumbTextSection |
                    axivastranslate }}</span>
                    <span class="arrow"*ngIf="applicationInfoService.breadCrumbTextSection != null"> >
                </span> 
                {{ applicationInfoService.breadCrumbText | axivastranslate }}
                </label>
            </div>
        </div>

        <div class="breadCrumbAndMenuItem">
            <app-toolbar class="maxWidth"></app-toolbar>
        </div>
    </div>
</div>
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './core/unauthorized/unauthorized.component';
import { AuthGuard } from './core/authentication/guards/auth.guard';
import { DashboardComponent } from './shared/dashboard/dashboard.component';
import { DesignerComponent } from './jollyjupiter/controls/designer/designer.component';
import { LandingpageComponent } from './shared/landingpage/landingpage.component';
import { ApplicationdownComponent } from './core/applicationdown/applicationdown.component';
import { AutomaticRecordAssignmentComponent } from './jollyjupiter/controls/static/automatic-record-assignment/automatic-record-assignment.component';
import { BrowserNotSupportedComponent } from './core/browser-not-supported/browser-not-supported.component';
import { CognitocallbackComponent } from './jollyjupiter/cognito/cognitocallback/cognitocallback.component';
import { TokenResolverService } from './cognito/token-resolver.service';
import { RingcentralRedirectComponent } from './jollyjupiter/controls/static/ringcentral-redirect/ringcentral-redirect.component';
import { MailacceptComponent } from './jollyjupiter/controls/noproject/mailaccept/mailaccept.component';
import { TrialextensionComponent } from './jollyjupiter/controls/noproject/trialextension/trialextension.component';
import { AccountComponent } from './jollyjupiter/controls/static/account/account.component';
import { MyFollowupsComponent } from './jollyjupiter/controls/static/my-followups/my-followups.component';
import { AccountsComponent } from './jollyjupiter/controls/static/accounts/accounts.component';
import { ContactsComponent } from './jollyjupiter/controls/static/contacts/contacts.component';
import { ContainerViewComponent } from './jollyjupiter/controls/static/container-view/container-view.component';
import { MyleadsComponent } from './jollyjupiter/controls/static/myleads/myleads.component';
import { ReportingComponent } from './jollyjupiter/controls/static/reporting/reporting.component';
import { SupervisorMainComponent } from './jollyjupiter/controls/static/supervisor/supervisor-main/supervisor-main.component';
import { ImportMainComponent } from './jollyjupiter/controls/static/supervisor/import/import-main/import-main.component';
import { AdminMainComponent } from './jollyjupiter/controls/admin/admin-main/admin-main.component';
import { ConversionComponent } from './jollyjupiter/controls/static/conversion/conversion.component';
import { MailshotsComponent } from './jollyjupiter/controls/static/mailshots/mailshots.component';
import { SmtpsettingsComponent } from './jollyjupiter/controls/static/supervisor/smtpsettings/smtpsettings.component';
import { UsersettingsComponent } from './jollyjupiter/usersettings/usersettings.component';
import { GeneralsettingsComponent } from './jollyjupiter/controls/static/supervisor/generalsettings/generalsettings.component';
import { CampaignComponent } from './jollyjupiter/controls/static/supervisor/campaign/campaign.component';
import { LeadstateComponent } from './jollyjupiter/controls/static/supervisor/leadstate/leadstate.component';
import { ProcessComponent } from './jollyjupiter/controls/static/supervisor/process/process.component';
import { PoolsComponent } from './jollyjupiter/controls/static/supervisor/pools/pools.component';
import { EmailcontentComponent } from './jollyjupiter/controls/static/supervisor/emailcontent/emailcontent.component';
import { EmailtemplatesComponent } from './jollyjupiter/controls/static/supervisor/emailtemplates/emailtemplates.component';
import { AdminEntityComponent } from './jollyjupiter/controls/admin/admin-entity/admin-entity.component';
import { DatarightsComponent } from './jollyjupiter/controls/static/datarights/datarights.component';
import { UsermanagementComponent } from './jollyjupiter/controls/static/usermanagement/usermanagement.component';
import { ProjectusersComponent } from './jollyjupiter/controls/static/projectusers/projectusers.component';
import { UsergroupsComponent } from './jollyjupiter/controls/static/supervisor/usergroups/usergroups.component';
import { LeadreportsettingsComponent } from './jollyjupiter/controls/static/supervisor/leadreportsettings/leadreportsettings.component';
import { ConsentManagementComponent } from './jollyjupiter/controls/static/supervisor/consent-management/consent-management.component';
import { AjtrackerComponent } from './jollyjupiter/controls/static/supervisor/ajtracker/ajtracker.component';
import { CustomerapiComponent } from './jollyjupiter/controls/static/supervisor/customerapi/customerapi.component';
import { BlacklistComponent } from './jollyjupiter/controls/static/blacklist/blacklist.component';
import { MocoprojectassignmentComponent } from './jollyjupiter/controls/static/mocoprojectassignment/mocoprojectassignment.component';
import { AuditComponent } from './jollyjupiter/controls/static/supervisor/audit/audit.component';
import { ProjectManagementComponent } from './jollyjupiter/controls/static/developer/project-management/project-management.component';
import { EventlistComponent } from './jollyjupiter/controls/static/supervisor/eventlist/eventlist.component';
import { RingcentralWebrtcRedirectComponent } from './jollyjupiter/controls/static/ringcentral-webrtc-redirect/ringcentral-webrtc-redirect.component';
import { VoicescriptcontrolComponent } from './jollyjupiter/controls/static/voicescriptcontrol/voicescriptcontrol.component';
import { LeadmanagementdashboardComponent } from './jollyjupiter/controls/dashboards/leadmanagementdashboard/leadmanagementdashboard.component';
import { WebsitedashboardComponent } from './jollyjupiter/controls/dashboards/websitedashboard/websitedashboard.component';
import { CampaigndashboardComponent } from './jollyjupiter/controls/dashboards/campaigndashboard/campaigndashboard.component';
import { ConsentmanagementdashboardComponent } from './jollyjupiter/controls/dashboards/consentmanagementdashboard/consentmanagementdashboard.component';
import { ContentlibraryComponent } from './jollyjupiter/controls/static/contentlibrary/contentlibrary.component';
import { VipactivityComponent } from './jollyjupiter/controls/static/vipactivity/vipactivity.component';
import { AileadscoringComponent } from './jollyjupiter/controls/static/aileadscoring/aileadscoring.component';
import { StartpagedashboardComponent } from './jollyjupiter/controls/dashboards/startpagedashboard/startpagedashboard.component';
import { AdminAipromptsComponent } from './jollyjupiter/controls/admin/admin-aiprompts/admin-aiprompts.component';
import { ProcessvisualComponent } from './jollyjupiter/controls/static/processvisual/processvisual.component';
import { CloudconnectionsComponent } from './jollyjupiter/controls/static/cloudconnections/cloudconnections.component';
import { CacheresetComponent } from './jollyjupiter/controls/static/cachereset/cachereset.component';
import { EventbookingbatchComponent } from './jollyjupiter/controls/static/eventbookingbatch/eventbookingbatch.component';
import { QuestionaireDesignerControlComponent } from './jollyjupiter/controls/static/questionaire-control/questionaire-designer-control/questionaire-designer-control.component';
import { LookuptablesComponent } from './jollyjupiter/controls/static/lookuptables/lookuptables.component';
import { CustomPaletteEditorComponent } from './jollyjupiter/controls/custom/custom-palette/custom-palette-editor/custom-palette-editor.component';
import { FollowupsComponent } from './jollyjupiter/controls/static/followups/followups.component';
import { AwsloginComponent } from './jollyjupiter/controls/static/awslogin/awslogin.component';
import { DashboardmaincontainerComponent } from './jollyjupiter/controls/dashboards/defaultdashboards/dashboardmaincontainer/dashboardmaincontainer.component';

const routes: Routes = [
  { path: '', pathMatch: 'full' , component: LandingpageComponent ,  canActivate: [AuthGuard]},
  { path: 'callback', component: LandingpageComponent },
  { path: 'ringcentralcallback', component: RingcentralRedirectComponent },
  { path: 'ringcentralwebrtccallback', component: RingcentralWebrtcRedirectComponent },
  { path: 'notsupported', pathMatch: 'full' , component: BrowserNotSupportedComponent ,  canActivate: [AuthGuard]},
  { path: 'main', pathMatch: 'full' , component: DashboardComponent ,  canActivate: [AuthGuard]},
  { path: 'design', pathMatch: 'full' , component: DesignerComponent ,  canActivate: [AuthGuard]},
  { path: 'init', pathMatch: 'full' , component: LandingpageComponent ,  canActivate: [AuthGuard]},
  { path: '401', component: UnauthorizedComponent },
  { path: 'error', component: ApplicationdownComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: 'test', component: AutomaticRecordAssignmentComponent },
  { path: 'mailaccept', component: MailacceptComponent },
  { path: 'trialextension', component: TrialextensionComponent },
  { path: '**', redirectTo: '404' },
  { path: 'account', component: AccountComponent},
  {path: 'followups', component: FollowupsComponent},
  { path: 'myfollowups', component: MyFollowupsComponent },  
  { path: 'accounts', component: AccountsComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'mailaccept', component: MailacceptComponent },
  { path: 'containerview', component: ContainerViewComponent },  
  { path: 'myleads', component: MyleadsComponent },  
  { path: 'reporting', component: ReportingComponent },
  { path: 'supervisor-main', component: SupervisorMainComponent },
  { path: 'import-main', component: ImportMainComponent },
  { path: 'admin-main', component: AdminMainComponent },
  { path: 'conversions', component: ConversionComponent },
  { path: 'mailshots', component: MailshotsComponent },
  { path: 'smtpsettings', component: SmtpsettingsComponent,  },
  { path: 'usersettings', component: UsersettingsComponent },
  { path: 'developerprojectmanagement', component: ProjectManagementComponent },  

    // Menu
    { path: 'generalsettings', component: GeneralsettingsComponent },
    { path: 'process', component: ProcessComponent },
    { path: 'leadstate', component: LeadstateComponent },
    { path: 'campaigns', component: CampaignComponent },
    { path: 'pools', component: PoolsComponent },  
    { path: 'dashboard', component: DashboardComponent },  
    { path: 'emailcontent', component: EmailcontentComponent },
    { path: 'emailtemplate', component: EmailtemplatesComponent },
    { path: 'admin-entity', component: AdminEntityComponent },
    { path: 'datarights', component: DatarightsComponent },
    { path: 'usermanagement', component: UsermanagementComponent },
    { path: 'projectusers', component: ProjectusersComponent },
    { path: 'usergroups', component: UsergroupsComponent },
    { path: 'leadreportsettings', component: LeadreportsettingsComponent },
    { path: 'automaticrecordassignment', component: AutomaticRecordAssignmentComponent },
    { path: 'consent', component: ConsentManagementComponent },
    { path: 'ajtracker', component: AjtrackerComponent },
    { path: 'customerapi', component: CustomerapiComponent },    
    { path: 'blacklist', component: BlacklistComponent },    
    { path: 'mocoprojectassignment', component: MocoprojectassignmentComponent },    
    { path: 'audit', component: AuditComponent },
    { path: 'eventlist', component: EventlistComponent },
    { path: 'voicescript', component: VoicescriptcontrolComponent },
    { path: 'campaigndashboard', component: CampaigndashboardComponent },
    { path: 'websitedashboard', component: WebsitedashboardComponent },
    { path: 'leadmanagementdashboard', component: LeadmanagementdashboardComponent }  ,
    { path: 'consentmanagementdashboard', component: ConsentmanagementdashboardComponent }  ,
    { path: 'contentlibrary', component: ContentlibraryComponent }  ,
    { path: 'vipalert', component: VipactivityComponent },
    { path: 'aileadscore', component: AileadscoringComponent },  
    { path: 'startpagedashboard', component: StartpagedashboardComponent }  ,
    { path: 'admin-aiprompt', component: AdminAipromptsComponent },
    { path: 'processvisual', component: ProcessvisualComponent },
    { path: 'cloudconnection', component: CloudconnectionsComponent }  ,
    { path: 'cachereset', component: CacheresetComponent },
    { path: 'bookingbatch', component: EventbookingbatchComponent },
    { path: 'bookingbatchmailshot', component: EventbookingbatchComponent },
    { path: 'questionaires', component: QuestionaireDesignerControlComponent },             
    { path: 'lookuptables', component: LookuptablesComponent },
    { path: 'paletteeditor', component: CustomPaletteEditorComponent },
    { path: 'activate', component: AwsloginComponent },
    { path : 'defaultdashboards', component: DashboardmaincontainerComponent },
];

export const AppRoutes = RouterModule.forRoot(routes, {});

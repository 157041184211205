import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DashboardcoreService } from '../../dashboardcore.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-defaultdashboard-opportunity',
  templateUrl: './defaultdashboard-opportunity.component.html',
  styleUrl: './defaultdashboard-opportunity.component.scss'
})
export class DefaultdashboardOpportunityComponent implements OnInit {
  dashboardResultData = [];
  funnelValue = { data: [], type: 'funnel' };
  funnelAmount = { data: [], type: 'funnel' };
  tableData = [];

  showFunnelData = false;
  showFunnelAmount = false;
  dashboardId = 16

  poolId = null;
  pools = [];
  campaignId = null;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private dashboardcoreService: DashboardcoreService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {    
    this.getPools();
    this.campaignId = this.applicationInfoService.campaingnModeId;
    this.poolId = this.applicationInfoService.poolId;
    this.getDashboardDataArray(this.dashboardId);    
  }

  getDashboardDataArray(dashboardId) {
    let params = this.fillParams();
    this.dashboardResultData[dashboardId] = null;
    this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(getDashboardDataArrayResult => {
      this.dashboardResultData[dashboardId] = null;
      if (getDashboardDataArrayResult) {
        if (getDashboardDataArrayResult[0]) {
          this.dashboardResultData[dashboardId] = JSON.parse(JSON.stringify(getDashboardDataArrayResult[0]));
          this.tableData = this.dashboardResultData[dashboardId];
          this.getValueFunnelData();
          this.getValueAmountData();
        }
      }
    }).catch((error)=>{
    });
  }

  getPools() {
    this.pools = this.applicationInfoService.pools.toArray().filter(pool => pool.isActive == true);
  }

  fillParams() {
    const params = [];
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@pool_id', value: this.poolId });
    params.push({ key: '@campaign_id', value: this.campaignId });
    return params;
  }

  getValueFunnelData() {
    this.funnelValue.data = [];
    this.dashboardResultData[this.dashboardId].sort((a, b) => a.factor < b.factor ? -1 : 1);
    this.dashboardResultData[this.dashboardId].forEach(dataItem => {
      this.funnelValue.data.push({        
        name:  dataItem.default_name,
        value: dataItem.value
      }); 
    });
    this.showFunnelData = true;
  }

  getValueAmountData() {
    this.funnelAmount.data = [];
    this.dashboardResultData[this.dashboardId].sort((a, b) => a.factor < b.factor ? -1 : 1);
    this.dashboardResultData[this.dashboardId].forEach(dataItem => {
      this.funnelAmount.data.push({        
        name:  dataItem.default_name,
        value: dataItem.amount
      });       
    });
    this.showFunnelAmount = true;
  }

}

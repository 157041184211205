<div class="component">
  <div class="component__header">
    <app-component-header [header]="applicationInfoService.breadCrumbText"></app-component-header>
  </div>

  <div class="component__inner">
    <div class="myLeads">
        <div class="myLeads__header">
          <label *ngIf="!mainLevel?.nameTranslationToken">{{ mainLevel?.defaultName | texttransform }}</label>
          <label  *ngIf="mainLevel?.nameTranslationToken">{{ mainLevel?.nameTranslationToken.tokenFullName | texttransform }}</label>
        </div>


        <div class="myLeads__row">
          <div [ngClass]="{ hideInDom: selectedTabIndex != 1 }">
            <div [ngClass]="{ hideInDom: dataCollectionCollapsed==true }">
              <div class="myLeads__row__inner">

                  <div class="myLeads__selectcontainer" *ngIf="controlSubMode !=3">
                    <div class="customTextControlWrapper">
                      <label class="customTextControlWrapper__label">{{ 'MyLeads.Label.MyLeadLevel' | texttransform }}</label>
                      <div class="customTextControlWrapper__valueWrapper">
                        <select [(ngModel)]="mainLevel" class="customTextControlWrapper__dropdown" (change)="changeMyLeadsLevel()">
                          <option [ngValue]="myLeadLevel" *ngFor="let myLeadLevel of myLeadLevels" [ngClass]="{
                            hideInDom: myLeadLevel.id == 15
                          }">
                            {{ commonService.getNameTranslationOrDefaultName(myLeadLevel) | texttransform }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <app-jj-icons type="copy" [width]="20" [height]="20" (click)="newEntityLevel()"></app-jj-icons>
                  </div>

                  <div class="myLeads__selectcontainer" [ngClass]="{ hideInDom: additionalFilterCollapsed==true }">
                    <app-select-input
                    *ngIf="mainLevel?.id == 1"
                    label="MyLeads.Label.Campaign"
                    [(ngModel)]="selectedCampaign"
                    bind="id"
                    display="defaultName"
                    placeholder="MyLeads.Label.AllCampaign"
                    [options]="campaigns"></app-select-input>


                  </div>

                </div>
              </div>
          </div>

          <div class="myLeads__selectcontainer">
            <app-select-input
            label="Filter"
            [(ngModel)]="selectedFilterId"
            bind="id"
            display="defaultName"
            [options]="availableFilters"
            placeholder="MyLeads.Label.NoFilterSelected"
            ></app-select-input>
            <app-jj-icons type="editor"(click)="editDataFilter();$event.stopPropagation()" [width]="20" [height]="20"></app-jj-icons>
            <app-jj-icons type="trash" *ngIf="applicationInfoService.myLeadsSelectedFilter != null" (click)="clearDataFilter();$event.stopPropagation()" [width]="20" [height]="20"></app-jj-icons>
          </div>

        </div>

        <div *ngIf="filterExpanded">
          <div *ngFor="let filterSegment of applicationInfoService?.myLeadsSelectedFilter?.filterSegments; let i = index">        
            <div *ngIf="i > 0" class="myLeads__row">
                
            </div>
            <div >
              <app-datafilter-groupitem 
                [filterSegment]="filterSegment" 
                [filter]="selectedFilter"
                [filterSegmentOperators]="filterSegmentOperators"
                [memberEntities]="validEntities"
                disabled=true
                [operators]="filterSegmentMemberOperators"
              ></app-datafilter-groupitem>
            </div>
          </div>      
        </div>


        <div class="myLeads__inner">

          <div class="myLeads__row">
            <div [innerHTML]="mainLevel?.descriptionTranslationToken?.tokenFullName | texttransform"></div>
          </div>
  
  
          <!--Results-->
          <div [ngClass]="{hideInDom: selectedTabIndex == 2}" class="needsUItosee">
            <app-accordion 
              id="myLeadsResults" 
              *ngIf="useMyLeadsV2 == false" 
              title="MyLeads.Label.FilterResults" 
              (onClick)="toggleItem('resultsCollapsed');$event.stopPropagation()" 
              [isOpen]="resultsCollapsed == true">
  
              <div *ngIf="mainLevel != null">
                <app-main-button
                type="small"
                label="MyLeads.Label.RefreshMyLeadResults"
                (onClick)="refreshResults();$event.stopPropagation()"
                svg="arrows-counter-clockwise"
                ></app-main-button>
  
                <app-main-button
                label="MyLeads.Label.ModifySelectedRecordsets"
                (onClick)="modifySelectedResults();$event.stopPropagation()"
                [disabled]="lastSelectedRowsCount==0"
                svg="arrow-right"
                ></app-main-button>
  
                <app-main-button
            *ngIf="useMyLeadsV2 == false"
            label="MyLeads.Label.ExportData"
            (onClick)="exportData();$event.stopPropagation()"
            svg="export"></app-main-button>
  
                
  
              </div>
  
              <div [innerHTML]="taskResultInnerHTML" class="zeroIndex" *ngIf="useMyLeadsV2 == false"></div>
              <label *ngIf="mainLevel!=null && useMyLeadsV2 == false" class="" style="width: 100%;">{{ resultBottomText
                }}</label>
  
            </app-accordion>
          </div>
  
          <!--Step 2-->
          <div [ngClass]="{hideInDom: selectedTabIndex != 2}">
            <app-accordion
            id="myLeadsResults" 
            (onClick)="toggleItem('modifyPanelCollapsed');$event.stopPropagation()" 
            title="MyLeads.Label.ModifySelectedRecordsets"
            [isOpen]="modifyPanelCollapsed == false">
  
            <app-main-button 
            *ngIf="mainLevel!=null"
            label="MyLeads.Label.Back"
            (onClick)="backToSelection();$event.stopPropagation()"
            svg="arrow-left"/>
  
            <label>{{ 'MyLeads.Label.RecordsetsToBeModified' | texttransform }}</label>
            <div [innerHTML]="taskResultSummaryInnerHTML" class="zeroIndex" *ngIf="useMyLeadsV2 == false"></div>
            <label>{{ 'MyLeads.Label.ValuesThatCanBeModified' | texttransform }}</label>
  
            <span *ngIf="useMyLeadsV2 == false">
              <div [innerHTML]="taskModifyInnerHTML" class="zeroIndex" *ngIf="useMyLeadsV2 == false"></div>
              <br>
              <app-main-button type="small" label="MyLeads.Label.RefreshMyLeadResults" (onClick)="launchMultiUpdate();$event.stopPropagation()" svg="arrows-counter-clockwise"/>
            </span>
  
            <span *ngIf="useMyLeadsV2 == false">
              <span *ngIf="mainLevel?.entityId == 1 || mainLevel?.entityId == 4">
                <br><app-main-button
                type="small"
                label="MyLeads.Label.CreateFollowUps"
                svg="swap"
                (onClick)="createFollowUps();$event.stopPropagation()"></app-main-button>
   <!--              <button (click)="createFollowUps();$event.stopPropagation()" class="jjButtonColor jjButtonStyle"
                  title="{{ 'MyLeads.Label.CreateFollowUps' | texttransform }}">{{ 'MyLeads.Label.CreateFollowUps' |
                  texttransform }}</button> -->
              </span>
      
              <span *ngIf="mainLevel?.entityId == 1 || mainLevel?.entityId == 4">
                <br>
                <app-main-button type="small" label="MyLeads.Label.CreateTasks" svg="check-square" (onClick)="createTasks();$event.stopPropagation()"></app-main-button>
        <!--         <button (click)="createTasks();$event.stopPropagation()" class="jjButtonColor jjButtonStyle"
                  title="{{ 'MyLeads.Label.CreateTasks' | texttransform }}">{{ 'MyLeads.Label.CreateTasks' | texttransform
                  }}</button> -->
              </span>
            </span>
  
            </app-accordion>
          </div>


          <div *ngIf="useMyLeadsV2" class="maxHeight">
            <div [innerHTML]="taskResultInnerHTML" class="zeroIndex taskResultInnerHTML"></div>
        
            <div *ngIf="selectedTabIndex == 1" style="margin-top: 10px;">
              <label *ngIf="lastSelectedRowsCount > 0 && controlSubMode == 1">{{ 'MyLeads.Label.ValuesThatCanBeModified' | texttransform }}</label>
              <div [innerHTML]="taskModifyInnerHTML" class="zeroIndex " *ngIf="lastSelectedRowsCount > 0 && controlSubMode == 1"></div>
              <br>
              <div class="myLeadsButtonBar">
                <app-main-button 
                  type="small"
                  label="MyLeads.Label.RefreshMyLeadResults"
                  svg="arrows-counter-clockwise"
                  [disabled]="lastSelectedRowsCount == 0"
                  (onClick)="launchMultiUpdate();$event.stopPropagation()"
                  *ngIf="controlSubMode == 1"

                ></app-main-button>
                <div *ngIf="mainLevel?.entityId == 1 || mainLevel?.entityId == 4">
                  <app-main-button
                  *ngIf="controlSubMode == 2"
                  type="small"
                  label="MyLeads.Label.CreateTasks"
                  (onClick)="createTasks(1);$event.stopPropagation()"
                  [disabled]="lastSelectedRowsCount == 0"
                  svg="check-square"
                  ></app-main-button>

                  <app-main-button
                  *ngIf="controlSubMode == 3"
                  [disabled]="applicationInfoService.miscSettings['lastMatTableResultWithoutControlFilters']?.length < 1 || 
                      applicationInfoService.matTableCustomFiltersUsed == true"
                  label="MyLeads.Label.CreateEmails"
                  (onClick)="createEMailShot();$event.stopPropagation()"
                  svg="envelope"
                  ></app-main-button>
                </div>
        
              </div>
            </div>
          </div>
  

        </div>

  


      </div>

    </div>
</div>

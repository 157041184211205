<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <span *ngIf="mode == 1">
            <label class="wizardSubheader__headerLabel">
                {{ wizardService.pages[wizardService.wizardPage - 1].name | texttransform }}
            </label>
            <label class="wizardSubheader__descriptionLabel">
                {{ wizardService.pages[wizardService.wizardPage - 1].desc | texttransform }}
            </label>
        </span>
        <span *ngIf="mode == 2">
            <label class="wizardSubheader__headerLabel">
                {{ 'EntityMemberDesign.Label.DataSource' | texttransform }}
            </label>
            <label class="wizardSubheader__descriptionLabel">
                {{ 'EntityMemberDesign.PageInfo.Page2' | texttransform }}
            </label>
        </span>
    </div>

    <div class="wizardPageContent maxHeight">
        <div *ngIf="mode == 1" class="maxHeight">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">* {{ 'EntityMemberDesign.Label.DefaultName' | texttransform }}
                    <app-wizard-infobutton displayText="EntityMemberDesign.Label.DefaultNameDescription"></app-wizard-infobutton>
                </label>
                <input [(ngModel)]="wizardService.wizardArray.defaultName" class="wizardInput" (change)="checkMemberName()"
                    [disabled]="!commonService.isNullOrUndefined(wizardService.wizardArray.id)">
            </div>
            <div class="wizardDetailItem" *ngIf="wizardService.wizardArray.forbiddenValue == true">
                {{ 'EntityMemberDesign.Label.InvalidMemberName' | texttransform }}
            </div>            

            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">* {{ 'EntityMemberDesign.Label.Type' | texttransform }}<app-wizard-infobutton displayText="EntityMemberDesign.Label.TypeDescription"></app-wizard-infobutton></label>
                <select [(ngModel)]="wizardService.wizardArray.memberType" [disabled]="wizardService.wizardArray.lookupTableSource != null || wizardService.wizardArray.id != null"
                    class="processWorkflowStepresultDetailDropdown">
                    <option [ngValue]=null></option>
                    <option ngValue="TEXT">TEXT</option>
                    <option ngValue="NUMERIC">NUMERIC</option>
                    <option ngValue="DATETIME">DATETIME</option>
                    <option ngValue="BOOL">BOOL</option>
                </select>
            </div>
            <span *ngIf="wizardService.wizardArray.memberType == 'NUMERIC'">
                <br>
                <div class="wizardDetailItem">                    
                    <!-- <button class="jjButtonColor jjButtonStyle" (click)="mode = 2">
                        {{ 'EntityMemberDesign.Label.AddLookuptable' | texttransform }}
                    </button>
                    <br><br> -->
                    <label class="everleadSmallHeader">
                        {{ 'EntityMemberDesign.PageInfo.Page2' | texttransform }}
                    </label>
                    <app-main-button svg="key-return" type="small" (onClick)="mode = 2" label="EntityMemberDesign.Label.AddLookuptable"></app-main-button>
                </div>    
            </span>
        </div>

        <div *ngIf="mode == 2" class="maxHeight">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">{{ 'EntityMemberDesign.Label.LookupTableSource' | texttransform }}
                    <app-wizard-infobutton displayText="EntityMemberDesign.Label.LookupTableSourceDescription"></app-wizard-infobutton></label>
                <select [(ngModel)]="wizardService.wizardArray.lookupTableSource" (change)="sourceChanged(wizardService.wizardArray.lookupTableSource)">
                    <option [ngValue]=null></option>
                    <option ngValue="main">{{ 'EntityMemberDesign.Label.GeneralDatabase' | texttransform }}</option>
                    <option ngValue="data">{{ 'EntityMemberDesign.Label.CustomerDatabase' | texttransform }}</option>
                </select>
            </div>

            <div class="wizardDetailItem" *ngIf="wizardService.wizardArray.lookupTableSource != null">
                <label class="noPadding wizardLabel">{{ 'EntityMemberDesign.Label.LookupTableId' | texttransform }}
                    <app-wizard-infobutton displayText="EntityMemberDesign.Label.LookupTableIdDescription">
                    </app-wizard-infobutton></label>
                <select [(ngModel)]="wizardService.wizardArray.lookupTableId" (change)="getSampleDataFromLookupTable()">
                    <option [ngValue]=null></option>
                    <option [ngValue]="lookupTable.id" *ngFor="let lookupTable of lookupTableDefinitions[wizardService.wizardArray.lookupTableSource]">
                        {{ lookupTable.defaultName }}
                    </option>
                </select>   
            </div>
            <div class="wizardDetailItem wizardButtons">
                <app-main-button svg="key-return" type="small" (onClick)="mode = 1" label="EntityMemberDesign.Label.BackToMember"></app-main-button>
                <app-main-button svg="image" type="small" (onClick)="manageEntityTables()" label="EntityMemberDesign.Label.ManageLookuptables"
                    *ngIf="wizardService.wizardArray.lookupTableSource == 'data'"></app-main-button>
                <!-- <button class="jjButtonColor jjButtonStyle" (click)="manageEntityTables()">
                    {{ 'EntityMemberDesign.Label.ManageLookuptables' | texttransform }}
                </button> -->
            </div>
            <!-- <div class="wizardDetailItem">
                <button class="jjButtonColor jjButtonStyle" (click)="mode = 1">
                    {{ 'EntityMemberDesign.Label.BackToMember' | texttransform }}
                </button>
            </div> -->
            <div *ngIf="wizardService.wizardArray.lookupTableId != null" class="everleadSmallHeader">
                {{ 'Import.Label.ExampleData' | texttransform }}
            </div>
            <div *ngIf="wizardService.wizardArray.lookupTableId != null" class="everleadShadowBox">
                <div *ngFor="let sampleDataItem of sampleData; let i = index" [ngClass]="{ hideInDom: i > 10 }">
                    {{ commonService.getTranslationValueFromArray(sampleDataItem) | texttransform }}
                </div>
            </div>
        </div>
    </div>
</div>
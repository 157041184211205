import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { GraphQLService, ApolloMethod } from 'shared/service/graphql.service';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { LanguageService } from 'app/shared/translation/language.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';

@Component({
  selector: 'app-externalusers',
  templateUrl: './externalusers.component.html',
  styleUrls: ['./externalusers.component.scss']
})
export class ExternalusersComponent implements OnInit {
  newUser = null;
  mode = 1;
  userGroup = null;
  resultUser = null;
  userAlreadyExists = false;
  languages = [];
  dropDownRoleSelection: any;
  roleData: any[] = [];
  selectedRole = null;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private graphQLService: GraphQLService,
    private graphqlqueryService: GraphqlqueryService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService,
    private loaderService: LoaderService,
    private languageService: LanguageService,
    private messagingService: MessagingService,
    private sharedAPI: SharedAPI
  ) { }

  ngOnInit(): void {
    this.languageService.getLanguages().then(getLanguageResult => {
      this.languages = getLanguageResult;
    });

    this.getAvailableRoles();

    this.createNewUser();
  }



  createNewUser() {
    this.newUser = new Object();
    this.newUser['projectId'] = this.applicationInfoService.projectID;
    this.newUser['sendEmailToUser'] = true;
    this.newUser['roleId'] = null;
    this.newUser['languageId'] = 2;
    this.userGroup = this.applicationInfoService.miscSettings['newExternalUSerGroup'];
  }

  createExternalUser() {
    if (this.checkIfUserCanBeCreated()) {
        this.loaderService.display(true);
        this.sharedAPI.createExternalAccount(this.newUser).subscribe(createExternalUserResult => {
        this.mode = 2;
        this.resultUser = createExternalUserResult;
        this.eventService.externalUserCreatedEvent.emit(createExternalUserResult);
        this.loaderService.display(false);
      });
    } else {
      this.messagingService.showDefaultError('New User', 'Please fill out all required fields');
    }
  }

  createAnotherUser() {
    this.createNewUser();
    this.mode = 1;
  }

  checkIfUserCanBeCreated() {
    let returnValue = true;
    if (
      this.commonService.isNullOrUndefined(this.newUser.firstName) ||
      this.commonService.isNullOrUndefined(this.newUser.lastName) ||
      this.commonService.isNullOrUndefined(this.newUser.email) ||
      this.commonService.isNullOrUndefined(this.newUser.roleId)
    ) {
      returnValue = false;
    }
    if (!this.commonService.isNullOrUndefined(this.newUser.email)) {
      if (
        !this.commonService.checkIfStringContainsString(this.newUser.email, '@') ||
        !this.commonService.checkIfStringContainsString(this.newUser.email, '.')
      ) {
        returnValue = false;
      }
    }
    return returnValue;
  }

  getAvailableRoles() {
    this.roleData = [];
    this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.projectUsersRoles)
    .then(result => {
      this.roleData = result['data']['roles'];
      this.dropDownRoleSelection = this.roleData
        .filter(role => this.isRoleHidden(role) === false)
        .map(role => {
          return {
            label: role['nameTranslationToken'],
            value: role['id']
          };
        });
    })
    .catch(error => { console.error(error); });
  }

  isRoleHidden(role) {
    if (this.applicationInfoService.currentUserRoleId == 4) {
      return false;
    }
    const myRole = this.applicationInfoService.securityRoles.find(
      securityRole => securityRole.id === this.applicationInfoService.currentUserRoleId);
    if (myRole) {
      if (role.permissionLevel > myRole.permissionLevel) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

}

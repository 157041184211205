<div class="defaultDashboardMailshotWrapper">
    <app-layout header="Mailshots">
        <app-select-input label="Mailshot" [(ngModel)]="selectedBookingBatchId" [options]="eventBookingBatches" 
            display="batchName" bind="id" (valueChange)="getEventBookingBatchData()"></app-select-input>

      
    
    <div class="defaultDashboardMailshotKPIs" *ngIf="kpis != null" (click)="getDashboardResults()">        
        <app-bar-chart 
            [height]="300" 
            [chartData]="kpis" 
            component="mailshotKPIChart"
            [controlid]="agentwvlChart"
        >
        </app-bar-chart>
    </div>
    </app-layout>
</div>
<div class="adminDashboardWrapper">
    <div class="adminDashboardHeader everleadShadowBox">
    <!--     <label>Modus</label>
        <select [(ngModel)]="showMode">
            <option [ngValue]=0>Alle</option>
            <option [ngValue]=1>Nur universelle Dashboards</option>
            <option [ngValue]=2>Nur projektspezifische Dashboards</option>
        </select> 
        <button class="everleadCustomButton" (click)="createDashboard()">
            Neues universelles Dashboard
        </button> -->
        <app-select-input [(ngModel)]="showMode" bind="id" label="Modus" [options]="[{id:0, name:'Alle' },{id:1, name:'Nur universelle Dashboards'},{id:2, name:'Nur projektspezifische Dashboards'}]"></app-select-input>
        <app-main-button svg="layout" (onClick)="createDashboard()" label="Neues universelles Dashboard"></app-main-button>

    </div>

    <div *ngFor="let dashboard of dashboards">
        <div *ngIf="isDashboardVisible(dashboard)" class="adminDashboardPanel everleadShadowBox">
            <div class="adminDashboardPanel__header">
                <div>
                    <label>{{ dashboard.name }}</label>
                </div>
                <div class="adminDashboardPanel__buttons">
<!--                     <i class="fas fa-clone fingerCursor" (click)="createDashboard(dashboard)" title="Für dieses Dashboard klonen" *ngIf="projectId == null"></i> -->
                    <!-- <i class="fas fa-trash fingerCursor" (click)="deleteDashboard(dashboard)"></i> -->
                    <app-jj-icons type="plus" [width]="20" [height]="10" (click)="createDashboard(dashboard)" title="Für dieses Dashboard klonen" *ngIf="projectId == null" ></app-jj-icons>
                    <app-jj-icons type="trash" [width]="20" [height]="20" (click)="deleteDashboard(dashboard)"></app-jj-icons>
                </div>
            </div>
            <div class="adminDashboardPanel__line">
              <!--   <label>Name</label>
                <input [(ngModel)]="dashboard.name" class="adminDashboardInput" (change)="updateDashboard(dashboard)"> -->
                <app-text-input label="name" [(ngModel)]="dashboard.name" (valueChange)="updateDashboard(dashboard)"></app-text-input>
            </div>            
            <div class="adminDashboardPanel__line">
          <!--       <label>GUID</label>
                <input [(ngModel)]="dashboard.guid" class="adminDashboardInput" (change)="updateDashboard(dashboard)"> -->
                <app-text-input label="GUID" [(ngModel)]="dashboard.guid" (valueChange)="updateDashboard(dashboard)"></app-text-input>
            </div>    

         <!--  todo ivo -->
            <div class="adminDashboardPanel__line">
                <label>ProjectId</label>
                <input [(ngModel)]="dashboard.projectId" type="number" class="adminDashboardInput" (change)="updateDashboard(dashboard)">
            </div>                    
        </div>        
    </div>
</div>
<div class="dataPools">
    <div class="dataPools__header">

        <app-component-header
        [header]="applicationInfoService.breadCrumbText || 'Data Pools'"></app-component-header>


        <div class="dataPools__header__nav">
            <app-checkbox class="dataPoolCheckbox" label="ProcessDesign.Label.ShowOnlyActive" [(ngModel)]="showOnlyActive"></app-checkbox>

            <app-main-button
            type="small"
            *ngIf="userService.hasPermission('CanCreatePool')"
            (onClick)="createNewPool()"
            [tippy]="'General.Label.CreateNewWithDefault' | texttransform"
            label="PoolDesign.Label.NewPool"
            ></app-main-button>

            <div class="filter">
                <div class="filterWrapper">
                    <input [placeholder]="'General.Label.Search' | texttransform" [(ngModel)]="filterString">
                    <app-jj-icons type="magnifying-glass" [width]="20" [height]="20"></app-jj-icons>
                </div>
            </div>


        </div>

    </div>

    <div class="dataPools__inner">
        <span *ngIf="loadingData==true">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
        </span>

        <span id="projectSettingsDesignSpan"></span>
        <div class="dataPools__content" *ngIf="userService.hasPermission('CanReadPool') && loadingData == false">
            <div class="dataPools__content__table">
                <table mat-table #table [dataSource]="dataSource" style="width: 100%" matSort>       
                <ng-container matColumnDef="defaultName" [sticky]="!applicationInfoService.isMobile()">
                    <th mat-header-cell *matHeaderCellDef>{{ 'PoolDesign.Label.DefaultName' | texttransform }}</th>
                    <td mat-cell *matCellDef="let element">  
                        <div class="dataPools__content__name">
                            <span *ngIf="applicationInfoService.isDeveloper" style="margin-right: 10px; color: #5162F6">{{ element.id }}</span>
                            <app-text-input 
                            [(ngModel)]="element.defaultName"
                            (valueChange)="savePool(element)"
                            ></app-text-input>                    
                            <app-wizard-translations [array]="element" updateBaseArrayExternaldatasourceId=583 mode="3" [svgW]="20" [svgH]="20"></app-wizard-translations>    
                        </div>
                    </td>
                </ng-container>

        
                <ng-container matColumnDef="isActive">
                    <th mat-header-cell *matHeaderCellDef>{{ 'PoolDesign.Label.IsActive' | texttransform }}</th>
                    <td mat-cell *matCellDef="let element" class="poolIsActive"> 
                        <app-checkbox [(ngModel)]="element.isActive" (valueChange)="savePool(element)" [tippy]="'CampaignDesign.Label.IsActive' | texttransform"></app-checkbox>                       
                    </td>
                </ng-container>
        
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{             
                    hideInDom: (row.isActive == false && showOnlyActive)  || itemIsFiltered(row) == true,
                    projectSettings__isInactive: row.isActive == false
                }"></tr>
                </table>
            </div>
        </div>

    </div>
</div>

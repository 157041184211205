import { AfterContentInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { EventbookingbatchService } from 'app/jollyjupiter/controls/static/eventbookingbatch/eventbookingbatch.service';
import { WizardMode, WizardService } from 'app/jollyjupiter/controls/static/wizard/wizard.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-campaigndashboardlist',
  templateUrl: './campaigndashboardlist.component.html',
  styleUrls: ['./campaigndashboardlist.component.scss', './mailcampaignList.component.scss']
})
export class CampaigndashboardlistComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy{

  constructor(
    public commonService: CommonService,
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService, 
    public axivasTranslateService: AxivasTranslateService,
    public wizardService: WizardService,
    private eventbookingbatchService: EventbookingbatchService,
    public userService: UserService,
    public messagingService : MessagingService,
    public loaderService: LoaderService,
  ){}

  customEventSubscription: Subscription = new Subscription();

  @Input() page: number | null = 0;
  @Input() data: any | null;
  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();
    // ! new
    sortStatus= 0
    statusLookUpTable=[];
    filterData=[];



  // ! to delete after new microcampaign prototype
  selectedMicroCampaign= null;
  microCampaignSearchString = '';


  ngOnInit(): void {
    this.getStatusLookUpTable();
    this.filterData = this.data;
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      this.getStatusLookUpTable();
      this.filterData = this.data;
    })
  }


  ngAfterContentInit(): void {
   this.getStatusLookUpTable();
   this.filterData = this.data;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data'].currentValue && !changes['data'].firstChange){
      this.filterData = changes['data'].currentValue
    } else {
      this.filterData = changes['data'].currentValue
    }
  }


  // ! METHODS FOR MAIL CAMPAIGN
  filterRows($event){
   const string = $event

    if(!$event){
      this.filterData = this.data
    } else {
      this.filterData = this.data.filter((item) => item.batchName.includes(string))
    }
  }


  sortData(){
    if(this.sortStatus === 0){
      this.filterData = this.data
    } else {
      this.filterData = this.data.filter((item) => item.status === this.sortStatus);
    }
  }

  getStatusLookUpTable(){
    this.statusLookUpTable = this.commonService.getProjectLookupTableValues(95, 'central')
    this.statusLookUpTable = this.statusLookUpTable.map((stat)=> {
      return{
        ...stat,
        color:this.getStatusColor(stat.id),
  
      }
    }).filter((item) => item.isActive === true).sort((a,b) => a.defaultName < b.defaultName ?  -1 : 1);
    this.statusLookUpTable.unshift({id:0, isActive:true, defaultName:'CampaignDashboard.Label.Status', placeholder:true })
  }


  getStatusColor(id){
    switch(id){
      case 7643:
        return '#FF6B00';
      case 7642:
        return '#DFDFDF';
      case 8076:
        return '#3FE163';
      case 8821:
        return '#5162F6';
      case 9139:
        return '#FFD600';
      default:
        return false;
    }

  }

  getStatus(id, definition){
    if(this.statusLookUpTable.length > 0){
      const find = this.statusLookUpTable.find((item) => item.id === id)
      if(find){
        switch(definition){
          case 'label':
            return find?.nameTranslationToken?.tokenFullName ? find?.nameTranslationToken?.tokenFullName : find.defaultName;
          case 'color': 
          return find.color  
        }
      }else{
        return undefined;
      }
    } else {
      return undefined
    }

  }

  startMailCampaign = (campaign) => {
    this.eventbookingbatchService.finalizeMailshot(campaign)
    //this.dataChange.emit();
  }

  stopMailCampaign = (campaign) => {
    this.eventbookingbatchService.stopEventBookingBatch(campaign)
    this.dataChange.emit()
  }

 // todo -> waiting for mutation from michael
  duplicateMailCampaign = (campaign) => {
    // todo -> waiting for mutation from michael
    // this.eventbookingbatchService.cloneMailshot(campaign);
  }

 // ! check this this.applicationInfoService.miscSettings['emailbatch'] = campaign
  showDetailMailCampaign(campaign){
    this.applicationInfoService.miscSettings['eventBookingBatch'] = campaign
    this.eventService.showJjPopup('Mailshots.Label.Details', 'emailbatchdetails', '100');
  }



  /* editMailCampaign(campaign){
    this.wizardService.showWizard(WizardMode.Mailshot, campaign, '90', '90')
  } */


  // todo check with stucky this method from mailBatch
  editMailCampaign = (campaign) => {
    const array = JSON.parse(campaign.wizardValues);
    if (this.commonService.isNullOrUndefined(campaign.startedAt)) {
      array['id'] = campaign.id;
      array['status'] = campaign.status;
      this.wizardService.showWizard(WizardMode.Mailshot, array, '90', '90');
    } else {
      this.applicationInfoService.miscSettings['emailbatch'] = campaign;
      this.eventService.showJjPopup('Mailshots.Label.Details', 'emailbatchdetails', '100');
    }
  }

  
    // ! METHODS FOR MICROCAMPAIGN
    isFiltered(baseString, searchString) {
      return !this.commonService.checkIfStringContainsString(baseString, searchString);
    }
  


  sendMicroCampaignToParent(campaignOverviewItem){
    this.selectedMicroCampaign = campaignOverviewItem;
    this.dataChange.emit(this.selectedMicroCampaign);
  }

  getCampaignProgressPercentage(maxValue, refValue) {
    if (this.commonService.isNullOrUndefined(maxValue)) {
      return 0;
    }
    let percentage = 0;
    if (maxValue != 0) {
      percentage = Math.floor((refValue * 100) / maxValue);
    }
    if (percentage > 100) { percentage = 100; }
    if (percentage < 0) { percentage = 0; }
    return percentage;
  }


  getCampaignIconId(campaignOverviewItem) {
    return 'campaignIcon' + campaignOverviewItem.id;
  }


  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

}

<div class="automaticRecordAssignment__wrapper">
  <app-layout header="Supervisor.Label.AutomaticRecordAssignment">
    <div class="automaticRecordAssignment__header__buttons">
      <button class="everleadCustomButton" *ngIf="selectedRecordAssignment"
        (click)="selectedRecordAssignment = null">{{'AutomaticRecordAssignment.Label.Back' | texttransform }}</button>
      <button class="everleadCustomButton"
        (click)="createNewGroup = true">{{'AutomaticRecordAssignment.Label.NewGroup' | texttransform }}</button>
    </div>

    <div class="pageWrapper">
      <div *ngIf="loadingData">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
      </div>

      <div class="automaticRecordAssignment__list" *ngIf="!createNewGroup && selectedRecordAssignment == null">
        <div class="automaticRecordAssignment__list__item everleadTableRow fingerCursor"
          *ngFor="let followUpUserGroup of followUpUserGroups" (click)="changeUserGroup(followUpUserGroup)">
          <div *ngIf="applicationInfoService.isDeveloper" class="everleadCoral automaticRecordAssignment__list__item__id">
            {{ followUpUserGroup.id }}</div>
          <div class="maxWidth">{{ followUpUserGroup.defaultName | texttransform }}</div>
          <div class="automaticRecordAssignment__list__item__id" *ngIf="applicationInfoService.isDeveloper"
            (click)="refreshCache(followUpUserGroup);$event.stopPropagation()"><i class="fas fa-refresh"></i></div>
        </div>
      </div>

      <div class="automaticRecordAssignment__new everleadShadowBox" *ngIf="createNewGroup">
        <label class="automaticRecordAssignment__headerLabel">{{'AutomaticRecordAssignment.Label.CreateNewUserGroup' |
          texttransform }}</label>
        <div class="automaticRecordAssignment__new__name">
          <label class="normalWhiteSpace" style="width: 30%">{{ 'AutomaticRecordAssignment.Label.UserGroupName' |
            texttransform}}</label>
          <input type="text" [(ngModel)]="newUserGroupName" class minlength="3">
        </div>
        <div class="automaticRecordAssignment__new__buttons">
          <button (click)="createNewUserGroup()" [disabled]="newUserGroupName.length < 3" class="everleadCustomButton">
            {{ 'AutomaticRecordAssignment.Label.CreateGroup' | texttransform}}
          </button>
          <button (click)="createNewGroup = false" class="everleadCustomButton">
            {{ 'AutomaticRecordAssignment.Label.CancelCreation' | texttransform}}
          </button>
        </div>
      </div>

      <div class="automaticRecordAssignmentMenu" *ngIf="!createNewGroup && selectedRecordAssignment != null">
        <app-tab svg="calendar-dots" [active]="tab == 0" label="General.Label.Common" (onClick)="tab = 0"></app-tab>
        <app-tab svg="calendar-dots" [active]="tab == 1" label="AutomaticRecordAssignment.Label.DataFilterHeader" (onClick)="tab = 1"></app-tab>
        <app-tab svg="calendar-dots" [active]="tab == 2" label="AutomaticRecordAssignment.Label.TimeValuesHeaderLabel" (onClick)="tab = 2"></app-tab>
        <app-tab svg="calendar-dots" [active]="tab == 3" label="AutomaticRecordAssignment.Label.WVLPriorityHeader" (onClick)="tab = 3"></app-tab>
        <app-tab svg="calendar-dots" [active]="tab == 4" label="AutomaticRecordAssignment.Label.PriorityOfFilteredItemsHeaderLabel" (onClick)="tab = 4"></app-tab>
        <app-tab svg="calendar-dots" [active]="tab == 5" label="AutomaticRecordAssignment.Label.Users" (onClick)="tab = 5"></app-tab>
        
      </div>

      
      <div class="automaticRecordAssignment__content" *ngIf="!createNewGroup && selectedRecordAssignment != null">
        <div class="automaticRecordAssignmentTab" *ngIf="tab == 0">
          <div class="automaticRecordAssignment__content__textinput">
            <label>{{'AutomaticRecordAssignment.Label.UserGroupName' | texttransform }}</label>
            <input type="text" [(ngModel)]="selectedRecordAssignment.defaultName"
              (change)="saveUserGroup(selectedRecordAssignment)">
            <div class="automaticRecordAssignment__content__textinput__translation">
              <app-translation-button type='nameTranslationToken' source='usergroup'
                [target]=selectedRecordAssignment></app-translation-button>
              <app-translation-button type='descriptionTranslationToken' source='usergroup'
                [target]=selectedRecordAssignment></app-translation-button>
            </div>
          </div>
          <div class="automaticRecordAssignment__content__campaign">
            <label>{{ 'AutomaticRecordAssignment.Label.Campaign' | texttransform }}</label>
            <select [(ngModel)]="selectedRecordAssignment.campaignId" (change)="saveUserGroup(selectedRecordAssignment)">
              <option [ngValue]=null></option>
              <option width="100%" [ngValue]="campaign.id" *ngFor="let campaign of campaigns">{{ campaign.defaultName |
                texttransform }}</option>
            </select>
          </div>
        </div>

        <div class="automaticRecordAssignment__maincontent">
          <div class="automaticRecordAssignmentTab" *ngIf="tab == 1">
            <!-- Filter -->
          
            <div class="automaticRecordAssignment__datafilter">
              <div class="automaticRecordAssignment__datafilter__name">
                <div *ngIf="dataFilterObject != null" class="d-flex">
                  <div *ngIf="applicationInfoService.isDeveloper" class="everleadCoral automaticRecordAssignment__idInfo">{{
                    dataFilterObject.id }}</div>
                  {{ dataFilterObject.defaultName }}
                </div>
                <div *ngIf="dataFilterObject == null">{{'AutomaticRecordAssignment.Label.NoFilterSelected' | texttransform
                  }}</div>
              </div>
              <div class="automaticRecordAssignment__datafilter__buttons">
                <i class="fas fa-trash everleadCoral fingerCursor" *ngIf="dataFilterObject != null"
                  (click)="deleteDataFilter();$event.stopPropagation()"></i>
                <i class="fas fa-edit fingerCursor" (click)="editDataFilter();$event.stopPropagation()"></i>
              </div>
            </div>
          </div>  

          <!-- Zeitangaben -->
          <div class="automaticRecordAssignmentTab" *ngIf="tab == 2">
            <app-number-input label="AutomaticRecordAssignment.Label.TimeValue1Text" [(ngModel)]="selectedRecordAssignment.hoursAutoFollowup" (valueChange)="saveUserGroup(selectedRecordAssignment)"></app-number-input>
            <app-number-input label="AutomaticRecordAssignment.Label.TimeValue3Text" [(ngModel)]="selectedRecordAssignment.hoursNoDialing" (valueChange)="saveUserGroup(selectedRecordAssignment)"></app-number-input>
            <app-number-input label="AutomaticRecordAssignment.Label.TimeValue2Text" [(ngModel)]="selectedRecordAssignment.minutesUntilRelease" (valueChange)="saveUserGroup(selectedRecordAssignment)"></app-number-input>
            <!-- <div class="automaticRecordAssignment__content__textinput">
              <label>{{'AutomaticRecordAssignment.Label.TimeValue1Text' | texttransform }}</label>
              <input type="number" [(ngModel)]="selectedRecordAssignment.hoursAutoFollowup"
                (change)="saveUserGroup(selectedRecordAssignment)">
            </div>
            <div class="automaticRecordAssignment__content__textinput">
              <label>{{'AutomaticRecordAssignment.Label.TimeValue3Text' | texttransform }}</label>
              <input type="number" [(ngModel)]="selectedRecordAssignment.hoursNoDialing"
                (change)="saveUserGroup(selectedRecordAssignment)">
            </div>
            <div class="automaticRecordAssignment__content__textinput">
              <label>{{'AutomaticRecordAssignment.Label.TimeValue2Text' | texttransform }}</label>
              <input type="number" [(ngModel)]="selectedRecordAssignment.minutesUntilRelease"
                (change)="saveUserGroup(selectedRecordAssignment)">
            </div> -->
          </div>
          
          <div class="automaticRecordAssignmentTab" *ngIf="tab == 3">
            <!-- Typen -->
            <div class="automaticRecordAssignment__types">
              
              <div class="automaticRecordAssignment__types__content" cdkDropList [cdkDropListData]="followUpUserGroupTypes"
                (cdkDropListDropped)="moveInList($event, false, true)">
                <div *ngFor="let item of followUpUserGroupTypes" cdkDrag [cdkDragData]="item"
                  [tippy]="commonService.getTooltip(item.followupType)" data-html="true" class="everleadTableRow">
                  <div class="automaticRecordAssignment__types__content__item">
                    <input type="checkbox" class="automaticRecordAssignment__types__content__item__active"
                      [(ngModel)]="item.isActive" (change)="updateUserGroupType(item)">
                    <div class="automaticRecordAssignment__types__content__item__text"
                      *ngIf="!item.followupType.nameTranslationToken">
                      <div *ngIf="applicationInfoService.isDeveloper"
                        class="everleadCoral automaticRecordAssignment__idInfo">{{ item.id }}</div>
                      {{ item.followupType.defaultName }}
                    </div>
                    <div class="automaticRecordAssignment__types__content__item__text"
                      *ngIf="item.followupType.nameTranslationToken">
                      <div *ngIf="applicationInfoService.isDeveloper"
                        class="everleadCoral automaticRecordAssignment__idInfo">{{ item.id }}</div>
                      {{ item.followupType.nameTranslationToken?.tokenFullName | texttransform }}
                    </div>
                    <div class="automaticRecordAssignment__types__content__item__buttons">
                      <i class="fas fa-chevron-up" *ngIf="item.expanded" (click)="item.expanded = false"></i>
                      <i class="fas fa-chevron-down" *ngIf="!item.expanded" (click)="item.expanded = true"></i>
                      <i class="fas fa-grip-lines"></i>
                    </div>
                  </div>
                  <div class="maxWidth" *ngIf="applicationInfoService?.isDeveloper && item.expanded">
                    <input [(ngModel)]="item.settings" class="maxWidth" (change)="updateUserGroupType(item)">
                  </div>
                </div>
              </div>
              <div>
                <button class="everleadCustomButton" (click)="enrichType()">{{'AutomaticRecordAssignment.Label.EnrichTypes'
                  | texttransform }}</button>
              </div>
            </div>
          </div>

          <!-- Prioritäten -->
          <div class="automaticRecordAssignmentTab" *ngIf="tab == 4">
          <div class="automaticRecordAssignment__priority">
            
            <div>{{'AutomaticRecordAssignment.Label.PriorityOfFilteredItemsHeader' | texttransform }}</div>
            <div cdkDropList [cdkDropListData]="followUpUserGroupPriorities"
              (cdkDropListDropped)="moveInList($event, true, false)">
              <div class="">
                <div class="automaticRecordAssignment__priority__item" *ngFor="let item of followUpUserGroupPriorities"
                  cdkDrag [cdkDragData]="item">
                  <div class="automaticRecordAssignment__priority__item__text">
                    <div *ngIf="applicationInfoService.isDeveloper"
                      class="everleadCoral automaticRecordAssignment__idInfo">{{ item.id }}</div>
                    {{'AutomaticRecordAssignment.Label.Priority' | texttransform }}
                  </div>
                  <div class="automaticRecordAssignment__priority__item__selectItem">
                    <select [(ngModel)]="item.entityId" (click)="getEntityMember()">
                      <option [ngValue]="priorityEntity.id" *ngFor="let priorityEntity of priorityEntities">{{
                        priorityEntity.entityName | texttransform }}</option>
                    </select>
                  </div>
                  <div class="automaticRecordAssignment__priority__item__selectItem">
                    <select [(ngModel)]="item.memberId" (change)="updateFollowUpPriority(item)">
                      <option [ngValue]="priorityEntitiyMember.id"
                        *ngFor="let priorityEntitiyMember of getSelecteEntityMember(item.entityId)">{{
                        priorityEntitiyMember.defaultName | texttransform }}</option>
                    </select>
                  </div>
                  <div class="automaticRecordAssignment__priority__item__selectItem">
                    <select [(ngModel)]="item.ascending" (change)="updateFollowUpPriority(item)">
                      <option [ngValue]="true">{{ 'AutomaticRecordAssignment.Priority.Ascending' | texttransform }}
                      </option>
                      <option [ngValue]="false">{{ 'AutomaticRecordAssignment.Priority.Descending' | texttransform }}
                      </option>
                    </select>
                  </div>
                  <div class="automaticRecordAssignment__priority__item__buttons">
                    <i class="fas fa-trash everleadCoral fingerCursor"
                      (click)="deleteUserGroupPriority(item);$event.stopPropagation()"></i>
                    <i class="fas fa-grip-lines"></i>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button (click)="createFollowUpPriority()"
                class="everleadCustomButton">{{'AutomaticRecordAssignment.Label.NewPriotity' | texttransform }}</button>
            </div>
          </div>
          </div>


          <!-- User -->
          <div class="automaticRecordAssignmentTab" *ngIf="tab == 5">
          <div class="automaticRecordAssignment__users">
            
            <div>
              <label class="automaticRecordAssignmentHeaderLabel automaticRecordAssignmentLabel">{{
                'AutomaticRecordAssignment.Label.FollowUpUserGroupUsers' | texttransform }}</label>
              <div id="dropListGroup" class="controlInlineBlock">

                <p-panel [ngStyle]="setControlStyle('dragpanel')"
                  header="{{ 'General.User.AvailableUser' | texttransform }}" class="inline dragColumnDiv">
                  <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon dragDropfilterInfo"><i class="fas fa-filter"></i></span>
                    <input type="text" pInputText class="dragDropfilterInput"
                      [(ngModel)]="dragdropElements[0].filterString"
                      (input)="dragdropService.filterArray('0', dragdropElements)" />
                    <span id="sort2" class="ui-inputgroup-addon dragDropfilterInfo fingerCursor"
                      (click)="dragdropService.sortItems(0, 'userName', dragdropElements)"><i
                        class="fas fa-sort-alpha-down"></i></span>
                  </div>
                  <div class="dragDropitem-list">
                    <div class="userManagement__item__container" *ngFor="let item of dragdropElements[0].itemsUI">
                      <div class="userManagement__item__id">{{ item.id }}</div>
                      <div class="userManagement__item__name ellipsisText">{{ item.userName }}</div>
                      <div class="userManagement__item__move" (click)="addUser(item)">
                        <svg-icon src="assets/images/svg/icon-plus+.svg" class="userManagement__moveIcon"
                          (click)="addUser(user)" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                      </div>
                    </div>
                  </div>
                  <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon fingerCursor dragDropMoveAllItemsButton"
                      (click)="dragdropService.moveAllItems(0, 1, dragdropElements)">{{ 'General._.MoveAll' |
                      texttransform
                      }}<i class="fas fa-angle-double-right iconMargin"></i></span>
                  </div>
                </p-panel>

                <p-panel [ngStyle]="setControlStyle('dragpanel')"
                  header="{{ 'General.User.AssignedUsers' | texttransform }}" class="inline dragColumnDiv">
                  <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon dragDropfilterInfo"><i class="fas fa-filter"></i></span>
                    <input type="text" pInputText class="dragDropfilterInput"
                      [(ngModel)]="dragdropElements[1].filterString"
                      (input)="dragdropService.filterArray('1', dragdropElements)" />
                    <span id="sort1" class="ui-inputgroup-addon dragDropfilterInfo fingerCursor"
                      (click)="dragdropService.sortItems(1, 'userName', dragdropElements)">
                      <i class="fas fa-sort-alpha-down"></i>
                    </span>
                  </div>
                  <div class="dragDropitem-list">
                    <div class="userManagement__item__container userManagement__item__containerreverse"
                      *ngFor="let item of dragdropElements[1].itemsUI">
                      <div class="userManagement__item__id">{{ item.id }}</div>
                      <div class="userManagement__item__name ellipsisText">{{ item.userName }}</div>
                      <div class="userManagement__item__move" (click)="removeUser(item)">
                        <svg-icon src="assets/images/svg/icon-minus+.svg" class="userManagement__moveIcon"
                          [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
                      </div>
                    </div>
                  </div>
                  <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon fingerCursor dragDropMoveAllItemsButton"
                      (click)="dragdropService.moveAllItems(1, 0, dragdropElements)">
                      <i class="fas fa-angle-double-left iconMargin"></i>
                      {{ 'General._.MoveAll' | texttransform }}</span>
                  </div>
                </p-panel>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </app-layout>
</div>
<div *ngIf="viewMode == 0">
    <div class="contactprotection" *ngIf="type !== 'delete'">
        <div class="contactprotection__inner">
            <div class="row">
                <div class="col-12">
                    <app-select-input label="ContactProtection.Label.Contact" [(ngModel)]="newContact.contactId" [options]="accountContactsArray" display="lastName" bind="id"></app-select-input>
                </div>

                <div class="col-12">
                    <app-select-input label="ContactProtection.Label.Medium" [(ngModel)]="newContact.mediumTypeId" [options]="mediumDefinition" display="displayName" bind="id"></app-select-input>
                </div>

                <div class="col-12">
                    <app-select-input 
                    label="ContactProtection.Label.Frequency" 
                    [(ngModel)]="newContact.frequencyLookupId" 
                    [options]="intervalDefinition" 
                    display="displayName" 
                    bind="id"></app-select-input>
                </div>

                <div class="col-12">
                    <app-number-input label="ContactProtection.Label.Quantity" [(ngModel)]="newContact.quantity"></app-number-input>   
                </div>

                <div class="col-12">
                    <app-text-area-input label="ContactProtection.Label.Comments" [(ngModel)]="newContact.comment"></app-text-area-input>
                </div>
            </div>

            <div class="row">
                <div class="buttonsContainer">
                    <app-main-button
                    *ngIf="type==='add'"
                    type="border"
                    (onClick)="createNewContact()"
                    label="ContactProtection.Label.AddContact"
                    svg="user-plus"></app-main-button>

                    <app-main-button
                    *ngIf="type==='update'"
                    type="border"
                    (onClick)="updateContact()"
                    label="ContactProtection.Label.UpdateContact"
                    svg="arrows-counter-clockwise"
                    ></app-main-button>
                </div>
            </div>
    </div>
    </div>

    <div class="contactprotection" *ngIf="type === 'delete'">
        <div class="deleteContainer">
            <span>{{'ContactProtection.Label.DeleteContactConfirm' | texttransform }}</span>
            <span>Please confirm you want to delete this entry</span>
            <div class="buttonsContainer">
                <app-main-button
                type="border"
                label="ContactProtection.Label.Confirm"
                (onClick)="deleteConfirm()"
                svg="trash"></app-main-button>
            </div>
        </div>
        
    </div>
</div>


<div *ngIf="viewMode == 1">
    <div class="contactprotection" *ngIf="type !== 'delete'">
        <div class="contactprotection__inner">
            <div class="row">
                <div class="col-12">
                    <app-select-input 
                    label="ContactProtection.Label.Medium" 
                    [(ngModel)]="project.mediumTypeId" 
                    [options]="mediumDefinition" 
                    display="defaultName" 
                    bind="id"></app-select-input>
                </div>

                <div class="col-12">
                    <app-select-input 
                    label="ContactProtection.Label.Frequency" 
                    [(ngModel)]="project.frequencyTypeId" 
                    [options]="intervalDefinition" 
                    display="defaultName" 
                    bind="id"></app-select-input>
                </div>

                <div class="col-12">
                    <app-number-input label="ContactProtection.Label.Quantity" [(ngModel)]="project.quantity"></app-number-input>                   
                </div>

            </div>

        <div class="row">
            <div class="buttonsContainer">
                <app-main-button
                *ngIf="type==='add'"
                type="border"
                (onClick)="createNewProjectProtection()"
                label="ContactProtection.Label.AddProjectEntry"
                svg="plus"></app-main-button>

                <app-main-button
                *ngIf="type==='update'"
                type="border"
                (onClick)="updateProjectProtection()"
                label="ContactProtection.Label.UpdateProjectEntry"
                svg="arrows-counter-clockwise"
                ></app-main-button>
            </div>
        </div>
    </div>
    </div>
    <div class="contactprotection" *ngIf="type === 'delete'">
        <div class="deleteContainer">
            <span>{{'ContactProtection.Label.DeleteContactConfirm' | texttransform }}</span>
            <!-- <span>Please confirm you want to delete this entry</span> -->
            <div class="buttonsContainer">
                <app-main-button
                type="border"
                label="ContactProtection.Label.Confirm"
                (onClick)="deleteProjectProtection()"
                svg="trash"></app-main-button>
            </div>
        </div>
    </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';

@Component({
  selector: 'app-custom-timeline-item',
  templateUrl: './custom-timeline-item.component.html',
  styleUrl: './custom-timeline-item.component.scss'
})

export class CustomTimelineItemComponent implements OnInit {
  itemDataList = [];
  showDataArray = false;
  
  @Input() itemLabel = null;
  @Input() itemValue = null;
  @Input() isJson = false;
  @Input() isMail = false;
  @Input() isLink = false;
  @Input() isItemData = false;
  @Input() isNotes = false;
  @Input() item = null;
  
  constructor(
    private commonService: CommonService,
    private messagingService: MessagingService
  ) {}

  ngOnInit(): void {
    if (this.isItemData && !this.commonService.isNullOrUndefined(this.itemValue)) {      
      console.warn(this.itemValue);
      Object.keys(this.itemValue).forEach(item => {
        this.itemDataList.push({ defaultName: item, value: this.itemValue[item] })
        console.warn(item);
      })
    }    
  }

  isValid() {
    let returnValue = true;
    if (this.commonService.isNullOrUndefined(this.itemValue) || this.itemValue == '') {
      returnValue = false;
    }
    return returnValue;

  }

  copyToClipBoard(text) {
    this.commonService.copyTextToClipboard(this.itemValue);
      this.messagingService.showDefaultSuccess(
        'General.CopyToClipBoard.Header',
        'General.CopyToClipBoard.Message',
        false
      );
  }

  getCleanNotes() {
    if (this.commonService.isNullOrUndefined(this.itemValue)) {
      return '';
    };
    let tempString = this.itemValue.replace(/\n#/g, '<br><br> <span class="everleadCoral"><b>Historischer Kontakt</b></span><br>');
    tempString = tempString.replace(/#20/g, '<span class="everleadCoral"><b>Historischer Kontakt</b></span><br>20');
    tempString = tempString.replace(/\n/g, '<br>');
    return tempString;
  }

}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.scss'
})
export class AccordionComponent implements OnInit, OnChanges{

  constructor(){}

  @Input() title: string | null = null;
  @Input() isOpen: boolean = false;
  @Input() date: any | null= null;
  @Input() everleadHeader: boolean | null = false;
  @Input() value: number | null = null;
  @Input() valueTwo: number | null = null; 


  // todo 
  @Input() borderBottom: boolean | null = true;


  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
      //console.log('changes', changes);
  }
  
  

  togglePanel(){
    this.isOpen= !this.isOpen;
  }

}

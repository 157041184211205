import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Component({
  selector: 'app-admin-newentitymember',
  templateUrl: './admin-newentitymember.component.html',
  styleUrl: './admin-newentitymember.component.scss'
})

export class AdminNewentitymemberComponent implements OnInit {
  currentMember = null;  
  lookupTableDefinitions = [];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService
  ) {}

  ngOnInit(): void {
    if (this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['entiyMemberToEdit'])) {
      this.currentMember = {
        id: null,
        defaultName: "new_value",
        descriptionTranslationToken: null,
        entityMemberValidities: [],
        externalDataSource: null, 
        isGraphqlQueryable: true,
        lookupTableId: null,
        lookupTableSource: null,
        memberType: "TEXT",
        nameTranslationToken: null,
        nameTranslationTokenId: null
      }
    } else {
      this.currentMember = this.applicationInfoService.miscSettings['entiyMemberToEdit'];
    }
    this.getLookupTables(205, 'main');
    this.getLookupTables(628, 'data');
  }

  getLookupTables(externalDataSourceId, source) {
    this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [])
    .then(getLookupTablesResult => {
      this.lookupTableDefinitions[source] = getLookupTablesResult;
    })
    .catch(error => {
      console.error('getLookupTables', error);
    });
  }

  getHeaderName() {
    if (this.commonService.isNullOrUndefined(this.currentMember.id)) {
      return 'AdminEntity.Label.NewEntity';
    } else {
      return 'AdminEntity.Label.EditEntity';
    }
  }
}

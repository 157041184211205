<div class="accordeon__wrapper">
    <div *ngFor="let control of controlDefinition.childs; let colIndex = index" [ngClass]="{ hideInDom: !isVisible(control) }">
        <div *ngIf="isVisible(control)" class="accordeon__line" [ngClass]="{
            headerBarBorderRadius: colIndex == 0
        }">
            <div class="accordeon__lineHeader fingerCursor" (click)="selectChild(control.id)">
                <label>{{ commonService.getTranslationValueFromArray(control, 'displayText') | texttransform }}</label>
                <!-- <i class="fas " [ngClass]="{
                    'fa-chevron-down': selectedControlId != control.id,
                    'fa-chevron-up': selectedControlId == control.id
                }"></i> -->
                <svg  *ngIf="selectedControlId != control.id" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="" viewBox="0 0 256 256"><path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path></svg>
                <svg  *ngIf="selectedControlId == control.id" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M213.66,165.66a8,8,0,0,1-11.32,0L128,91.31,53.66,165.66a8,8,0,0,1-11.32-11.32l80-80a8,8,0,0,1,11.32,0l80,80A8,8,0,0,1,213.66,165.66Z"></path></svg>
            </div>
            <div class="accordeon__lineContent" *ngIf="selectedControlId == control.id || applicationInfoService.designerMode == true">
                <app-custom-container controluiid={{control?.logicalControlId}}></app-custom-container>
            </div>    
        </div>
    </div>
</div>
import { Component, OnInit, Input, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ApplicationInfoService, VariableType } from '../../../../core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { MethodService, InternalMethodType } from 'app/jollyjupiter/service/method.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { TimelineService } from './timeline.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';

@Component({
  selector: 'app-custom-timeline',
  templateUrl: './custom-timeline.component.html',
  styleUrls: ['./custom-timeline.component.scss'],
  animations: [
    trigger('detailExpand', [
    state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
    state('expanded', style({height: '*'})),
    transition('expanded <=> collapsed', animate('50ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
],
})
export class CustomTimelineComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() element: any[];
  @Input() filter: any;
  @Input() type = 1;  // 1 Activity 2 MicroCampaign
  @Input() newMode = false;
  @Input() microCampaign = null;
  @Input() id = null;
  @Input() customLeadStateMode = false;
  @Input() customLeadStateModeTarget = null;

  isResultViewExpanded = false;
  isGlobalResultViewExpanded = false;
  updateCombinedActivityListSubscription: Subscription = new Subscription();
  updateControlAttributesEventSubscription: Subscription = new Subscription();
  customEventSubscription: Subscription = new Subscription();
  expandedItems: any[] = [];
  loadingData = false;
  lazyLoadingData = false;
  externalDataSourceResult = null;
  itemNeedsToCuddle = [];
  timeDifferenceArray = [];
  numCountAll = 0;
  page = 1;
  pageSize = 10;
  lazyLoading = true;
  offset = 0;
  limit = 20;

  loggedInUserId = null;
  loggedInUserEditMyTaskRight = false;
  loggedInUserEditAllTaskRight = false;
  activityTarget = '1';
  formatArrayCache = [];
  timelineId = '';
  lastActivityResult = null;

  constructor( 
    public applicationInfoService: ApplicationInfoService,
    public userService: UserService,
    public methodService: MethodService,
    public commonService: CommonService,
    public messagingService : MessagingService,
    private emailtemplateService: EmailtemplateService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService,
    public timelineService: TimelineService,
    public uiService: UiService
   ) {

   }

  getElementDataBody(data) {
    let resultValue = '';
    
    if (this.isJsonString(data)) {
      if (data == '') { return ''; }
      if (!this.commonService.isNullOrUndefined(data)) {
        let dataArray = this.commonService.objectToArray(JSON.parse(data));
        dataArray.forEach(item => {
          let textValue = item.toString();
          textValue = textValue.replace(',', ': ')
          resultValue = resultValue.concat(textValue, '\n');
        })      
      }
  
    } else {
      return data.toString();
    }
    return resultValue;
  }

  getEventTranslation(maileventType) {
    return this.axivasTranslateService.getTranslationTextForToken('ActivityList.Label.' + maileventType);
  }

  ngAfterViewInit(): void {
    this.loggedInUserEditMyTaskRight = this.userService.hasPermission(this.applicationInfoService.applicationSettings['editMyTaskRight']);
    this.loggedInUserEditAllTaskRight = this.userService.hasPermission(this.applicationInfoService.applicationSettings['editAllTasksRight']);    
    this.loggedInUserId = this.userService.getUserId();
  }

  ngOnInit(): void {
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['timelineLimit'])) {
      this.limit = Number(this.applicationInfoService.applicationSettings['timelineLimit']);
    }

    this.timelineId = this.commonService.createGuid();
    this.timelineService.filterPanelOpen[this.timelineId] = false;
    this.type = Number(this.type);    
    this.updateCombinedActivityListSubscription  = this.eventService.updateCombinedActivityList.subscribe((event) => {
      this.getData();
    });

    if (this.applicationInfoService.currentContact != null) {
      this.activityTarget = '2';
    }
    if (this.customLeadStateModeTarget == 'account') {
      this.activityTarget = '1';
    }
    this.updateControlAttributesEventSubscription  = this.eventService.updateControlAttributesEvent.subscribe(() => {
      this.getData();
    });
   
    this.customEventSubscription  = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'scrolledToBottom' && this.lazyLoadingData == false && this.loadingData == false) {
        // return; // Kein Pagination bei neuer Version
        this.getData(true);
      }      
    });

    if (this.newMode) {
      this.getData();
    }    

    if (this.applicationInfoService.applicationSettings['isDemoproject'] == 'true' && this.applicationInfoService.demoLandingPage == null) {
      this.externaldatasourceService.executeExternalDataSource(493, []).then(result => { if (result.length > 0) { this.applicationInfoService.demoLandingPage = result[0].domainFilter; }
      });
    }    

    if (this.customLeadStateMode) {
      this.isGlobalResultViewExpanded = true;
    }    
  }
  
  ngOnDestroy(): void {
    if (this.updateCombinedActivityListSubscription) { this.updateCombinedActivityListSubscription.unsubscribe(); }    
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }    
    if (this.updateControlAttributesEventSubscription) { this.updateControlAttributesEventSubscription.unsubscribe(); }    
  }

  toggleActivityTarget() {
    if (this.activityTarget == '1') {
      this.activityTarget = '2'
    } else {
      this.activityTarget = '1';
    }
  }

  isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  cleanTaskAnswers(answers) {
    if (this.commonService.isNullOrUndefined(answers)) {
      return '';
    }
    return answers.replace(/#,#/g, ', ');
  }

  toggleResultView() {
    this.isResultViewExpanded = !this.isResultViewExpanded;
  }

  toggleGlobalExpand() {
    this.isGlobalResultViewExpanded = !this.isGlobalResultViewExpanded;
  }
  
  getMailPreview(element) {
    this.applicationInfoService.miscSettings['emailreview'] = element;
    this.eventService.showJjPopup('EMailReview.Label.Header', 'emailreview', 90);
  }

  toggleDetails(expandedElement,row){
    return (expandedElement != row) ? row : "undefined";
  } 

  canEditNotes(element) {
    let returnValue = false;
    if(this.loggedInUserEditMyTaskRight
       && element.taskResultUserId == this.userService.getUserId()) {
       returnValue = true;
    }
    
    if (this.loggedInUserEditAllTaskRight) {
        returnValue = true;
    }
    return returnValue;
  }

  canEditTask(element) {
    let returnValue = false;
    if(
       this.loggedInUserEditMyTaskRight &&
       element.taskResultUserId == this.loggedInUserId && 
       element.type == 'task'
      ) {
       returnValue = true;
    }
    
    if (this.loggedInUserEditAllTaskRight && element.type == 'task') {
        returnValue = true;
    }
    return returnValue;
  }

  editActivity(element) {
    const activityEntity = '15';

    if (!this.canEditNotes(element)) {
      return;
    }

    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntity, [activityEntity, '-1'])
    .then(() => {
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [activityEntity, 'resultId', element.taskResultId]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [activityEntity, 'stepId', element.taskStepId]);      
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [activityEntity, 'followUpUserId', element.taskResultUserId]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [activityEntity, 'notes', element.taskNotes]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [activityEntity, 'resultDate', element.date]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [activityEntity, 'contactId', element.contactId]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [activityEntity, 'id', element.id]);
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [activityEntity, 'subject', element.taskSubject]);

      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [activityEntity, 'subResults', [element.taskAnswerId]]);
      let textArray = [];
      textArray[element.taskAnswerId] = element.taskAnswerText;
      this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [activityEntity, 'subResultsadditionalText', textArray]);  
      // this.methodService.launchInternalMethod(InternalMethodType.UpdateEntityValue, [activityEntity, 'subResultsadditionalText', 
      //   [{ element.taskAnswerId }]   ]);
      this.applicationInfoService.miscSettings['bookingMode'] = 3;
      this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, ['', 'booking', '80'])
    });
    
  }

  copyToClipBoard(text) {
    this.commonService.copyTextToClipboard(text);
      this.messagingService.showDefaultSuccess(
        'General.CopyToClipBoard.Header',
        'General.CopyToClipBoard.Message',
        false
      );
  }

  isExpanded(id) {
    return this.commonService.checkIfItemIsInArray(this.expandedItems, id);
  }

  toggleItem(id) {
    this.commonService.toggleArrayItem(this.expandedItems, id);
  }

  toggleSearch(value) {
    if (this.type == 1) {
      this.commonService.toggleArrayItem(this.timelineService.activityFilterType, value);
    } else {
      this.commonService.toggleArrayItem(this.timelineService.microcampaignFilterType, value);
    }
    this.isResultViewExpanded = true;    
    // this.getData();
  }

  getItemIcon(item, base) {
    let returnValue = 'fa-envelope';
    if (this.type == 1) {
      switch(item.type) {
        case 'event':
          switch(item.eventType) {
            case 'pageView':
              returnValue = 'fa-eye';    
            break;
            case 'mail_open':
              returnValue = 'fa-envelope-open';    
            break;
            case 'mail_send':
              returnValue = 'fa-paper-plane';    
            break;
            case 'mail_delivery':
              returnValue = 'fa-share';    
            break;
            case 'mail_click':
              returnValue = 'fa-mouse';    
            break;
          }
        break;
        
        case 'conversion':
          returnValue = 'fa-address-card';
        break;

        case 'task':
          returnValue = 'fa-comments';          
        break;
        
        case 'mailevent': {
          switch(item.maileventType) {
            case 'Send':
              returnValue = 'fa-paper-plane';    
            break;
            case 'Delivery':
              returnValue = 'fa-share';    
            break;
            case 'Click':
              returnValue = 'fa-mouse';    
            break;
          }
        }
      }
    }

    if (this.type == 2) {
      switch(item.resultType) {
        case 'conversion': returnValue = 'fa-address-card'; break;
        case 'formsubmission': returnValue = 'fa-share'; break;
        case 'mailsend': returnValue = 'fa-paper-plane'; break;
        case 'mailclick': returnValue = 'fa-mouse'; break;
        case 'maildelivery': returnValue = 'fa-share'; break;
        case 'mailfailure': returnValue = 'fa-exclamation-triangle everleadCoral'; break;
        case 'filedownload': returnValue = 'fa-file-download'; break;
        case 'calender-check': returnValue = 'fa-calendar'; break;
        case 'mailopen': returnValue = 'fa-envelope-open'; break;
        case 'call': returnValue = 'fa-phone'; break;
        case 'mail': returnValue = 'fa-envelope'; break;
      }
    }

    return base.concat(' ', returnValue);
  }

  specialToggle() {
    if (!this.checkSpecialToggle()) {
      this.timelineService.activityFilterType = ['conversion','task','event', 'mailevent'];
    }
    //this.getData();
  }

  getSelectedFilter() { 
    let returnValue = this.axivasTranslateService.getTranslationTextForToken('Timeline.Label.SelectedFilter').concat(': ');
    if (this.type == 1) {
      this.timelineService.activityFilterType.forEach(filter => {
        if (returnValue != this.axivasTranslateService.getTranslationTextForToken('Timeline.Label.SelectedFilter').concat(': ')) { returnValue = returnValue.concat(', '); }
        const filterInfo = this.timelineService.activityFilterOptions.find(option => option.identifier == filter);
        if (filterInfo) {
          returnValue = returnValue.concat(this.axivasTranslateService.getTranslationTextForToken(filterInfo.defaultName));
        }
      });  
    }
    if (this.type == 2) {
      this.timelineService.microcampaignFilterType.forEach(filter => {
        if (returnValue != '') { returnValue = returnValue.concat(', '); }
        const filterInfo = this.timelineService.microcampaignFilterOptions.find(option => option.identifier == filter);
        if (filterInfo) {
          returnValue = returnValue.concat(this.axivasTranslateService.getTranslationTextForToken(filterInfo.defaultName));
        }
      });
  
    }
    return returnValue;
  }

  showAdditionalFilterBar() {
    let returnValue = false;
    if (this.type == 1) {
      if (this.timelineService.activityFilterTypeBase.length != this.timelineService.activityFilterType.length) {
        returnValue = true;
      }  
    }
    if (this.type == 2) {
      if (this.timelineService.microcampaignFilterTypeBase.length != this.timelineService.microcampaignFilterType.length) {
        returnValue = true;
      }  
    }
    if (this.timelineService.filterPanelOpen[this.timelineId] == true) {
      returnValue = false;
    }
    return returnValue;
  }

  newActivity() {
    this.methodService.launchInternalMethod(InternalMethodType.UpdateEntity, ['15', '-1']).then(() => {
      this.methodService.launchInternalMethod(InternalMethodType.UpdateMiscSettings, ['bookingMode', '4']).then(() => {
        this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, [
          'AccountFooter.Label.NewActivity',
          'booking'
        ]);  
      });
    });
  }

  setFilter() {
    // this.getData();
    this.createResultArray();
    this.timelineService.filterPanelOpen[this.timelineId] = false;
  }

  specialToggleMicroCampaign() {
    if (!this.checkSpecialToggleMicroCampaign()) {
      this.timelineService.microcampaignFilterType = ['system', 'contact', 'agent'];
    }
    this.getData();
  }

  checkSpecialToggle() {
    if (
      this.commonService.checkIfItemIsInArray(this.timelineService.activityFilterType, 'conversion') &&
      this.commonService.checkIfItemIsInArray(this.timelineService.activityFilterType, 'task') &&
      this.commonService.checkIfItemIsInArray(this.timelineService.activityFilterType, 'event') &&
      this.commonService.checkIfItemIsInArray(this.timelineService.activityFilterType, 'mailevent')) {
        return true;
    } else {
      return false;
    }
  }

  checkSpecialToggleMicroCampaign() {
    if (
      this.commonService.checkIfItemIsInArray(this.timelineService.microcampaignFilterType, 'system') &&
      this.commonService.checkIfItemIsInArray(this.timelineService.microcampaignFilterType, 'contact') &&
      this.commonService.checkIfItemIsInArray(this.timelineService.microcampaignFilterType, 'agent')) {
        return true;
    } else {
      return false;
    }
  }

  isValid(itemToCheck) {
    let returnValue = true;
    if (this.commonService.isNullOrUndefined(itemToCheck) || itemToCheck == '') {
      returnValue = false;
    }
    return returnValue;
  }

  checkIfContactActivity(item) {
    let returnValue = true;
    if (item.type == 'task') {
      returnValue = false;
    }
    if (item.resultSource == 'agent') {
      returnValue = false;
    }
    if (item.type == 'mail' && item.mailDirection == 'Outgoing') { returnValue = false; }
    return returnValue;
  }

  getData(append = false) {   
    if (this.lazyLoadingData) { return; }
    if (append == false) {
      this.offset = 0;
    }
    let filterString = '';
    let types = this.timelineService.activityFilterType;
    if (this.type == 2) { 
      types = this.timelineService.microcampaignFilterType; 
    }
    types.forEach(element => {
      if (filterString != '') { filterString = filterString.concat(', '); }
      filterString = filterString.concat('\"', element, '\"')
    });


    return new Promise((resolve, reject ) => {
      switch (this.type) {
        case 1:
          if (scroll) { 
            if (this.lazyLoading == false) { return; }
            this.lazyLoadingData = true;
          } else {
            this.page = 1;
            this.offset = 0;
            this.lazyLoading = true;
            this.loadingData = true;
          }
          let externaldatasourceId = 399;
          if (this.activityTarget == '1') {
            externaldatasourceId = 854;
          }
          externaldatasourceId = 948;
          let contactId = null;
          if (this.applicationInfoService.currentContact && this.activityTarget == '2') {
            contactId = this.applicationInfoService.currentContact.id;
          }

          // this.externaldatasourceService.executeExternalDataSource(externaldatasourceId, [this.pageSize, this.page, filterString])
          this.externaldatasourceService.executeExternalDataSource(externaldatasourceId, [2, contactId , this.limit, this.offset ])
          .then(result => {                               
            this.itemNeedsToCuddle = []
            result = JSON.parse(result);
            this.lastActivityResult = result;
            this.createResultArray(append);
            this.loadingData = false;
            this.lazyLoadingData = false;
            this.externalDataSourceResult = result; 
            this.numCountAll = result.numConversions + result.numEvents 
                          + result.numMailconversions + result.numMailevents 
                          + result.numMails + result.numTasks;

            resolve(null);
          }).catch(error => {
            if (scroll) {
              this.loadingData = false;              
            }            
            reject(error);
            this.lazyLoadingData = false; 
          });
          break;

        case 2:
          this.getCampaignResults(filterString);
          break;
      }
    });   
  }

  createResultArray(append = false) {
    let tempArray = [];
    if(!append){
      this.element = []; 
    }

    if (this.commonService.checkIfItemIsInArray(this.timelineService.activityFilterType, 'task')) {
      this.addArrayToList(tempArray, this.lastActivityResult?.tasks, 'task');
    }
    if (this.customLeadStateMode == false) {
      if (this.commonService.checkIfItemIsInArray(this.timelineService.activityFilterType, 'conversion')) {
        this.addArrayToList(tempArray, this.lastActivityResult?.conversions, 'conversion');
      }
      if (this.commonService.checkIfItemIsInArray(this.timelineService.activityFilterType, 'event')) {
        this.addArrayToList(tempArray, this.lastActivityResult?.events, 'event');
      }
      if (this.commonService.checkIfItemIsInArray(this.timelineService.activityFilterType, 'mail')) {
        this.addArrayToList(tempArray, this.lastActivityResult?.mails, 'mail');
      }
      if (this.commonService.checkIfItemIsInArray(this.timelineService.activityFilterType, 'mailevent')) {
        this.addArrayToList(tempArray, this.lastActivityResult?.mailevents, 'mailevent');
      }
  
      if (
        this.commonService.checkIfItemIsInArray(this.timelineService.activityFilterType, 'mailevent') ||
        this.commonService.checkIfItemIsInArray(this.timelineService.activityFilterType, 'conversion')
      ) {
        this.addArrayToList(tempArray, this.lastActivityResult?.mailconversions, 'mailconversion');
      }  
    }    
    tempArray.sort((a, b) => a.rownumber > b.rownumber ? 1 : -1);    
    tempArray.forEach(item => {
      this.element.push(item);
    });    
  }

  addArrayToList(tempArray, array, type) {    
    array.forEach(element => {
      element['type'] = type;
      if (type == 'mailevent') {
        if (element.mailEventData != null) {
          element.mailEventData = JSON.parse(element.mailEventData);
        }
      }
      if (this.customLeadStateMode == true) {        
        const campaignId = this.applicationInfoService.miscSettings['contactLeadStatusCampaign'].campaignId;
        if (element.taskCampaignId == campaignId && element.taskLeadStatusId != null) {
          tempArray.push(element);
        }        
      } else {
        tempArray.push(element);
      }      
    });
    return tempArray;
  }

  getCampaignResults(searchString) {
    let endData = this.microCampaign.currentTaskResultDate;
    if (endData == 'null' || endData == null) {
      endData = new Date(Date.now());
    } else {
      endData = new Date(this.microCampaign.currentTaskResultDate);
    }

    let startDate = new Date(this.microCampaign.firstTaskDate);
    startDate.setHours(0, 0, 0);    
    startDate.setHours(0, 0, 0);    
    this.externaldatasourceService.executeExternalDataSource(427, [
      this.microCampaign.campaignId, 
      this.timelineService.microcampaignFilterType, 
      startDate, 
      endData, 
      this.microCampaign.taskChainGuid
    ])
    .then(campaignResult => {            
      campaignResult.result.sort((a, b) => a.followUpDate > b.followUpDate ? -1 : 1);
      this.externalDataSourceResult = campaignResult;
      this.element = campaignResult.result;
      this.loadingData = false;
    }).catch(error => {
      console.error(error);
      this.loadingData = false 
    });
  }

  editConversion(element) {
    this.applicationInfoService.miscSettings['conversion-editor-conversion'] = element;
    this.eventService.showJjPopup('ConversionEditor.Label.Header', 'conversion-editor', '80');    
  }

  formatArray(stringValue, dataItem) {
    if (!this.commonService.isNullOrUndefined(this.formatArrayCache[dataItem.id])) {
      return this.formatArrayCache[dataItem.id];
    }
    const array = [];
    let jsonValue = stringValue.toString();
    jsonValue = jsonValue.replace(/\'/g, '"');
    jsonValue = JSON.parse(jsonValue);
    const keys = Object.keys(jsonValue);
    keys.forEach(key => {
      array.push({ name: key, value: jsonValue[key] })
    });
    this.formatArrayCache[dataItem.id] = array;
    return array;    
  }

  getModifiedDemoUrl(baseString) {
    if (!this.commonService.isNullOrUndefined(baseString)) {
      if (this.applicationInfoService.demoLandingPage != null) {
        baseString = baseString.replace(
          this.applicationInfoService.demoLandingPage, 
          this.applicationInfoService.applicationSettings['demoLandingPageReplaceString']
        );
      }        
    }
    return baseString;
  }

  dataArrayItem(item) {
    let returnValue = false;
    const dataArrayItems = this.applicationInfoService.applicationSettings['timelineDataArrayItems'].split(';');
    if (this.commonService.checkIfItemIsInArray(dataArrayItems, item.resultType)) {
      returnValue = true;
    }
    return returnValue;
  }

  getQMResultName(resultId) {
    return this.applicationInfoService.results.toArray().find(result => result.id == resultId);
  }

  getTimeDifferenceString(itemDate) {
    if (!this.commonService.isNullOrUndefined(this.timeDifferenceArray[itemDate])) {
      return this.timeDifferenceArray[itemDate];
    }

    this.timeDifferenceArray[itemDate] = this.commonService.getTimeDifferenceString(itemDate, itemDate, "DateDifference.Label.Ago");
    return this.timeDifferenceArray[itemDate];
  }

  setItemAsDeleted(item) {
    this.externaldatasourceService.executeExternalDataSource(418, [
      this.commonService.getModifyArrayBody({ id: item.id, isDeleted: true }, [])
    ]).then(() => {
      this.getData();
    })
  }

  getCleanNotes(taskNotes) {
    if (this.commonService.isNullOrUndefined(taskNotes)) {
      return '';
    } else {
      let finalNotes = taskNotes.replace(/#20/g, '<br><span class="everleadCoral">Historischer Eintrag</span><br>20');
      finalNotes = finalNotes.replace(/\n#/g, '<br><br><span class="everleadCoral">Historischer Eintrag</span><br>');
      finalNotes = finalNotes.replace(/\n/g, '<br>');
      return finalNotes;
    }
  }

  checkBottom(event) {
    const shownItems = this.offset;
    const totalItems = this.numCountAll;
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if ((totalItems > shownItems) && this.loadingData == false) {
        this.lazyLoading = true;
        this.offset = Number(this.offset + this.limit);
        this.getData(true);
      }
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { QuestionaireControlService } from './questionaire-control.service';

@Component({
  selector: 'app-questionaire-control',
  templateUrl: './questionaire-control.component.html',
  styleUrls: ['./questionaire-control.component.scss']
})
export class QuestionaireControlComponent implements OnInit {
  @Input() controlDefinition = null;
  questionaires = null;
  selectedQuestionaire = null;
  selectAvailable = true;
  questions = [];
  refId = null;
  questionsRequested = false;

  constructor(
    private commonService: CommonService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService,
    private questionaireControlService: QuestionaireControlService
  ) { }

  ngOnInit(): void {
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['showQuestionnaire'])) {
      this.changeQuestionaire(this.applicationInfoService.miscSettings['showQuestionnaire']);
      this.selectAvailable = false;
    } else {
      this.getQuestionaires();  
    }    
    
    this.eventService.customEvent.subscribe(event => {
      if (event.id == 'showQuestionnaire') {
        this.selectAvailable = false;
        this.changeQuestionaire(event.value);
      }
    });
  }

  openDesigner() {
    this.eventService.showJjPopup('', 'questionairedesigner', '90')
  }

  getQuestionaires() {
    this.questionaireControlService.getQuestionaires().then(getQuestionairesResult => {
      this.questionaires = getQuestionairesResult;
      if (this.controlDefinition) {
        if (this.controlDefinition.additionalSetting1 != '' && this.controlDefinition.additionalSetting1 != null) {
          const questionaire = this.questionaires.find(ques => ques.id == Number(this.controlDefinition.additionalSetting1));
          if (questionaire) {
            this.selectAvailable = false;                    
            this.changeQuestionaire(questionaire);
          }
        } else {
          if (this.selectedQuestionaire == null) {
            if (this.questionaires.length > 0) {
              this.changeQuestionaire(this.questionaires[0]);              
            }
          }
        }  
      } else {
        if (this.selectedQuestionaire == null) {
          if (this.questionaires.length > 0) {
            this.changeQuestionaire(this.questionaires[0]);              
          }
        }
      }
    })
  }

  changeQuestionaire(questionaire) {    
    this.selectedQuestionaire = questionaire;
    this.refId = this.questionaireControlService.getRefId(questionaire);
    this.questionaireControlService.getQuestionsById(this.questionaireControlService.questionaireLookupId, questionaire.id).then(getQuestionsResult => {
      this.questions = getQuestionsResult;
      this.questionaireControlService.getAnwsersForQuestionaire(questionaire).then(() => { this.questionsRequested = true; })
    })
  }
}

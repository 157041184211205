import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';

@Component({
  selector: 'app-conversioneditor',
  templateUrl: './conversioneditor.component.html',
  styleUrls: ['./conversioneditor.component.scss']
})
export class ConversioneditorComponent implements OnInit {
  conversion = null;
  entity = null;
  members = [];
  event = null;
  eventData =[];
  conversionLoaded = false;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    private loaderService: LoaderService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.conversion = this.applicationInfoService.miscSettings['conversion-editor-conversion'];
    this.getConversionInfo();    
  }


  getConversionInfo() {
    this.entity = this.applicationInfoService.entities.Item('458');
    let memberString = '';
    this.entity.entityMembers.forEach(member => {
      memberString = memberString.concat(' ', member.defaultName);
      this.members.push(member);
    });
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(518, [this.conversion.eventId, memberString]) 
    .then(result => {
      this.applicationInfoService.entities.Item('458').data = result[0];
      this.applicationInfoService.entities.Item('458').data.eventId = this.conversion.eventId;
      this.conversionLoaded = true;
      this.loaderService.display(false);
    })
    .catch(error => {
      this.loaderService.display(false);
    });
  }

  saveConversion() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(519, [
      this.commonService.getModifyArrayBody(this.applicationInfoService.entities.Item('458').data, ['id'])
    ])
    .then(() => {
      this.loaderService.display(false);
    })
    .catch(error => {
      console.error(error);
      this.loaderService.display(false);
    });
  }

  isEditVisible() {
    let returnValue = true;
    const control = this.applicationInfoService.controls.Item('85640');
    if (control) {
      if (control.childs.length == 0) {
        returnValue = false;
      }
    }
    return returnValue;
  }
}

import { Injectable , EventEmitter} from '@angular/core';
import { AuthService } from './auth.service';
// import { User } from 'oidc-client';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { Permission } from '../models/permission';
import { Observable, timer } from 'rxjs';

// import { NgxPermissionsService } from 'ngx-permissions';
import { SessionStorageService } from 'ngx-webstorage';
import { ApplicationInfoService } from '../../application/application-info.service';
import { GraphQLService, ApolloMethod } from '../../../shared/service/graphql.service';
import { environment } from '../../../../environments/environment';
import { GraphqlqueryService } from 'app/jollyjupiter/service/graphqlquery.service';
import { MasterPageService } from 'app/shared/service/masterpage.service';
import { isNullOrUndefined } from 'util';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';

@Injectable()
export class UserService {
  appCurrentUser = 'JJCurrentUser';
  router: Router;
  // user: User;
  permissionSubscription: Subscription;
  responseTimerSubscription: Subscription = new Subscription;
  // public isLoggedIn: Observable<Boolean>;
  public hasPermissonChanged: EventEmitter<Permission[]> = new EventEmitter();
  public recoveringFrom401Event: EventEmitter<boolean> = new EventEmitter();
  public userRoles = [];
  public isLoaded: boolean;
  public permission: Permission[] = [];
  public redirectUrl: string;
  public askAtClosing = true;
  public logLevel = 0;

  constructor(
      private authService: AuthService,
      router: Router
    // , private permissionsService: NgxPermissionsService
    , private sessionStorage: SessionStorageService
    , private applicationInfoService: ApplicationInfoService
    , private graphQLService: GraphQLService
    , private masterPageService: MasterPageService
    , private externaldatasourceService: ExternaldatasourceService
    , private graphqlqueryService: GraphqlqueryService
    , private loaderService: LoaderService
    , private refreshValueService: RefreshValueService
    ) {
    this.router = router;
  }

  hasPermission(permissionToCheck: string): boolean {
    if (permissionToCheck == null || permissionToCheck == undefined) {
      return;
    }
    let returnValue = false;
    if (this.permission.find(m => m.permission.toUpperCase() === permissionToCheck.toUpperCase())) {
      returnValue = true;
    }
    return returnValue;
  }

  getControlPermissionByType(permissionsToCheck: any[], baseValue: any, permissionType: string) {
    if (permissionsToCheck) {
      if (permissionsToCheck.length > 0) {
        permissionsToCheck.forEach(permission => {
          if (permissionType.toUpperCase() === permission.permissionType.toUpperCase()) {
            if (!this.hasPermission(permission.securityPermission)) {
              baseValue = false;
            }
          }
        });
      }
    }
    return baseValue;
  }

  getUserRoles(): Promise<any> {
    return new Promise((getUserRolesResolve, getUserRolesReject) => {
      this.externaldatasourceService.executeExternalDataSource(479, [])
      .then(result => {
        this.applicationInfoService.availableRoles = result;
        this.userRoles = result;
        this.userRoles.sort((a, b) => a.roleId < b.roleId ? -1 : 1);
        if (this.userRoles.length > 0) {
          this.applicationInfoService.currentUserRoleId = this.userRoles[this.userRoles.length - 1].roleId;
          this.getRoleInformation(this.applicationInfoService.currentUserRoleId);
        }
        getUserRolesResolve(null);
        this.getUserPermissions();
      });
    });
  }

  getRoleInformation(id) {
    this.applicationInfoService.availableRoles.forEach(role => {
      let roleString = '';
      if (role.poolId != null) {
        const pool = this.applicationInfoService.projectInfo?.pools.find(pool => pool.id == role.poolId);
        if (pool) {
          roleString = roleString.concat(' - Pool: ', pool.defaultName);
        }
      }
      if (role.campaignId != null) {
        const campaign = this.applicationInfoService.campaigns.Item(role.campaignId);
        if (campaign) {
          roleString = roleString.concat(' - Kampagne: ', campaign.defaultName);
        }
      }

      if (role.roleId == id) {
        if (role.poolId != null) {
          console.log('custom pool found', role.poolId);
          this.applicationInfoService.poolId = role.poolId;
          this.applicationInfoService.blockSettingChange = true;
        } else {
          if (this.applicationInfoService.userSettings['lastSelectedPool'.concat(this.applicationInfoService.projectID.toString())] != undefined) {
            console.log('user setting pool found', this.applicationInfoService.userSettings['lastSelectedPool'.concat(this.applicationInfoService.projectID.toString())]);
            this.applicationInfoService.userSettingsPoolSelected = true;
            this.applicationInfoService.poolId = this.applicationInfoService.userSettings['lastSelectedPool'.concat(this.applicationInfoService.projectID.toString())];
          }
        }
        if (role.campaignId != null) {
          console.log('custom campaignId found', role.campaignId);
          this.applicationInfoService.campaingnModeId = role.campaignId;
          this.applicationInfoService.blockSettingChange = true;
        } else {
          if (this.applicationInfoService.userSettings['lastSelectedCampaignId'.concat(this.applicationInfoService.projectID.toString())] != undefined) {
            console.log('user campaignid pool found', this.applicationInfoService.userSettings['lastSelectedCampaignId'.concat(this.applicationInfoService.projectID.toString())]);
            this.applicationInfoService.userSettingsCampaignSelected = true;
            this.applicationInfoService.campaingnModeId = this.applicationInfoService.userSettings['lastSelectedCampaignId'.concat(this.applicationInfoService.projectID.toString())];
          }
        }
      }
      role['roleText'] = roleString;
    });
  }

  getControlPermission(permissionSign: string, permissionsToCheck: any[], baseValue: any, permissionType: string): boolean {
    if (permissionsToCheck) {
      if (permissionsToCheck.length > 0) {
        permissionsToCheck.forEach(permission => {
          if (permission.permissionType === permissionType) {
            let finalPermission = '';
            if (!isNullOrUndefined(permission.securityPermission)) {
              finalPermission = permissionSign.concat(permission.securityPermission);
            } else {
              finalPermission = permissionSign.concat(permission.securityPermission.name.tokenName);
            }
            if (!this.hasPermission(finalPermission)) {
              baseValue = false;
            }
          }
        });
      }
    }
    return baseValue;
  }

  public getUserId(ignoreFakeUser = false) {
    let returnValue = this.applicationInfoService.userID;
    if (!ignoreFakeUser) {
      if (!isNullOrUndefined(this.applicationInfoService.user)) {
        if (!isNullOrUndefined(this.applicationInfoService.user.fakeUserId)) {
          returnValue = this.applicationInfoService.user.fakeUserId;
        }
      }
    }
    return returnValue;
  }

  public getJollyJupiterUserInformation(): Promise<any> {
    return new Promise<any>((getJollyJupiterUserInformationResolve, getJollyJupiterUserInformationReject) => {
      this.masterPageService.getPageType();
      this.applicationInfoService.ignoreFakeUserId = true;
      this.graphQLService.apolloGQLpromise('main', ApolloMethod.Query, this.graphqlqueryService.userServicecurrentUser)
      .then(result => {
        if (result.data.currentUser == null) {
          getJollyJupiterUserInformationResolve(null);
          return;
        }
        if (result.data.currentUser.trialEndsAt != null) {
          if (new Date(result.data.currentUser.trialEndsAt) < new Date(Date.now())) {
            location.replace(environment.trialexpiredpage);
          }
        }
        this.applicationInfoService.ignoreFakeUserId = false;
        if (this.applicationInfoService.projectSelected) {
          this.getUserRoles();
        }
        // if (this.applicationInfoService.projectID !== 0) {
        //   this.refreshValueService.getProjectFollowUpGroups();
        // }
        localStorage.setItem(this.appCurrentUser, result.data.currentUser.userName);
        this.applicationInfoService.userName = result.data.currentUser.userName;
        this.applicationInfoService.userID = result.data.currentUser.id;
        this.applicationInfoService.user = result.data.currentUser;

        // Usersettings
        if (result.data.currentUser.userSettings == null) {
          // new user
          this.applicationInfoService.userSettings = {
            LanguageIdentifier: 'de',
            VipAlertFrequency: 'none',
            NormalVisitorAlertFrequency: 'direct',
            MatTablePagesize: 20,
            ExpertMode: true,
            VipAlertProjectIds: 'none',
            PhoneType: '2309'
          }
        } else {
          this.applicationInfoService.userSettings = JSON.parse(result.data.currentUser.userSettings);
        }
        getJollyJupiterUserInformationResolve(this.applicationInfoService.user);
        if (result.data.currentUser.fakeUserId != null) {
          this.applicationInfoService.user.fakeUserId = result.data.currentUser.fakeUserId;
          console.warn('faking user');
          this.getUserPermissions();
        }
      })
      .catch(error => {
        this.applicationInfoService.ignoreFakeUserId = false;
        this.applicationInfoService.errorOccured = true;
        console.error('getJollyJupiterUserInformation', error);
        getJollyJupiterUserInformationReject(error);
      });
    });
  }

  public getUserPermissions(): Promise<any> {
    return new Promise<any> ((getUserPermissionsResolve, getUserPermissionsReject) => {
      let url: string = environment.apiUrl + '/User/<userId>/Permission';
      let userId = this.applicationInfoService.userID.toString();
      if (!isNullOrUndefined(this.applicationInfoService.user)) {
        if (!isNullOrUndefined(this.applicationInfoService.user.fakeUserId)) {
          userId = this.applicationInfoService.user.fakeUserId;
        }
      }
      if (isNullOrUndefined(this.applicationInfoService.currentUserRoleId)) {
        getUserPermissionsResolve(null);
        return;
      }
      url = url.replace('<userId>', userId);
      this.loaderService.display(true, false, 'getUserPermissions');
      this.authService.AuthGet(url).subscribe(response => {
        this.permission = response.result.map((data) => new Permission(data));
        this.hasPermissonChanged.emit(this.permission);
        getUserPermissionsResolve(response);
        this.loaderService.display(false, false, 'getUserPermissions');
      }, (error => {
        this.loaderService.display(false, false, 'getUserPermissions');
        getUserPermissionsReject(error);
      }));
    });
  }

  public setSilentRefresh(enable: boolean) {
    if (enable) {
      if (this.responseTimerSubscription) {
        this.responseTimerSubscription.unsubscribe();
      }
      const responseTimer = timer(1000, 1000);
      this.responseTimerSubscription = responseTimer.subscribe(restime => {
        console.log('refreshConnectionTick', restime);
        if (restime > 25) {
          this.askAtClosing = false;
          this.sessionStorage.store('setSilentRefresh', Date.now().toString());
          this.sessionStorage.store('ActivitySilentRefresh', '1');
        }
      });
    } else {
      if (this.responseTimerSubscription) {
        this.responseTimerSubscription.unsubscribe();
      }
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-phone-ringcentral-widget',
  templateUrl: './phone-ringcentral-widget.component.html',
  styleUrls: ['./phone-ringcentral-widget.component.scss']
})
export class PhoneRingcentralWidgetComponent implements OnInit , OnDestroy{

  constructor(
    public commonService: CommonService,
    public ringcentralwebphoneService: RingcentralwebphoneService,
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,

    ) { }

  ringCentralEventSubscription: Subscription = new Subscription();
  customEventSubscription: Subscription = new Subscription();

  isAuthenticated: boolean;
  outgoingCall = false;
  //this.user = { adressBook: true, firstName: 'ivo', lastName:'serra', jobTitle: 'developer', selectedNumber:+491234567890}
  user={} 
  showIncomingCall:boolean=false;
  callAccepted

  ngOnInit(): void {
    this.ringCentralEventSubscription = this.ringcentralwebphoneService.ringCentralWebPhoneEvent.subscribe( event =>{
      if(event.id == 'callTerminated'){
        this.user = {};
        this.showIncomingCall = false;
      /*   console.log('widget', event.id)
        console.log('widget session',this.ringcentralwebphoneService.session) */
      }

      if(event.id === 'callRejected'){
        //console.log('widget', event.id)
        this.user= {};
        this.showIncomingCall = false
      }

      if(event.id == 'hangUp'){
        //console.log('widget', event.id)
        this.user = {};
        
      }
    });
    this.customEventSubscription = this.eventService.customEvent.subscribe((event)=>{
      if (event.id == 'makeCall'){
        //console.log('widget', event.id)
        this.user = {
          selectedNumber: event.number,
          ownNumber: event.ownNumber
        };
      }

      if(event.id == 'phone-dashboardIncomingCallUser'){
        //console.log('widget', event.id)
        this.user = event.user;
        this.showIncomingCall = true;
      }

      if(event.id == 'phone-dashboardOutgoingCall'){
        //console.log('widget', event.id)
        this.user= event.user;
      }
    });

  }

  logInRingCentral() {
    this.ringcentralwebphoneService.login()
  }

  closeCall(){
    this.ringcentralwebphoneService.hangUp()
    this.eventService.customEvent.emit({ id: 'widgetCallClosed' });
    this.user={}
    this.showIncomingCall=false;
    this.callAccepted=false;
  }

  acceptCall(){
    this.eventService.customEvent.emit({id: 'widgetAcceptCall'})
    this.ringcentralwebphoneService.accept()
    this.callAccepted = true

  }

  rejectCall(){
    this.ringcentralwebphoneService.reject();
    this.showIncomingCall= false;
    this.user={};
    this.callAccepted=false;
  }

  ngOnDestroy():void{
   if (this.customEventSubscription) {this.customEventSubscription.unsubscribe();}
   if(this.ringCentralEventSubscription){ this.ringCentralEventSubscription.unsubscribe()}
  }
}

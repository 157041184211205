<div *ngIf="isValid()" class="customTimelineItem">
    <div class="customTimelineItemLabel">
        <div class="dot"></div>
        {{ itemLabel | texttransform }}
        <app-jj-icons class="fingerCursor" *ngIf="isNotes" [type]="'copy'" (click)="copyToClipBoard()" [width]="20" [height]="20"></app-jj-icons>
    </div>
    <div class="customTimelineItemValue">
        <div *ngIf="!isJson && !isLink && !isNotes && !isItemData">{{ itemValue }}</div>
        <div *ngIf="isJson && !isLink && !isNotes && !isItemData">
            {{ itemValue | json }}
        </div>
        <div *ngIf="isLink && !isNotes && !isItemData">
            <a [href]="itemValue" target="_new">{{ itemValue }}</a>
        </div>
        <div *ngIf="isItemData">
            <div (click)="showDataArray = !showDataArray">
                <div *ngIf="showDataArray" class="showDataArrayDiv">
                    <app-jj-icons [type]="'eye'" [width]="20" [height]="20" color="secondary"></app-jj-icons>
                    {{ 'CustomTimelineItem.Label.HideData' | texttransform }}
                </div>
                <div *ngIf="!showDataArray" class="showDataArrayDiv">
                    <app-jj-icons [type]="'eye'" [width]="20" [height]="20" color="boxBorderGrey"></app-jj-icons>
                    {{ 'CustomTimelineItem.Label.ShowData' | texttransform }}
                </div>
            </div>
            <div *ngIf="showDataArray">
                <div *ngFor="let itemDateItem of itemDataList" class="timelineItemDataItem">
                    <div class="timelineItemDataItem__name">{{ itemDateItem.defaultName }}</div>
                    <div class="timelineItemDataItem__value">{{ itemDateItem.value }}</div>
                </div>
            </div>
        </div>
        <div *ngIf="isNotes">
            <div [innerHTML]="getCleanNotes()"></div>
        </div>
    </div>
</div>
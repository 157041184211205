
<div class="toolbar">
    <!-- <div class="toolbar__item fingerCursor" id="toolbarDemoUser" (click)="createDemoUser()"  [tippy]="'Toolbar.Label.CreateDemoUser' | texttransform"
        >  
        <svg-icon src="assets/images/svg/iconset/06icon-kontakte.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>
    </div> --> 
    <div class="toolbar__item fingerCursor" id="toolbarAccount" (click)="showAccountData()" [tippy]="getReturnToText() | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"
        *ngIf="applicationInfoService.currentAccount != null && commonService.checkApplicationRouteIsNotParamValue('account') == true">
        <app-jj-icons [type]="'key-return'" [width]="20" [height]="20"></app-jj-icons>
    </div>    

    <!-- Yammer -->

    <div class="toolbar__item fingerCursor" id="toolbarYammer" (click)="showEVERLEADYammer()" 
        [ngClass]="{ everleadCoral: checkIfThereAreNewsForUser() }"
        [tippy]="'Toolbar.Label.ShowWhatsNew' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"
        *ngIf="applicationInfoService.user?.isExternalUser == false">
        <app-jj-icons [type]="'info'" [width]="20" [height]="20"></app-jj-icons>
        <!-- <i class="fas faYammer fa-info-circle"></i> -->
        <div class="yammerNew" *ngIf="checkIfThereAreNewsForUser()">New!</div>
    </div>    

    <!-- Download reports -->    
    <div class="toolbar__item fingerCursor toolbarDownloadReports" id="toolbarDownloadReports" (click)="downloadReports()" 
        [tippy]="'Report.Message.ReportAvailableForDownload' | axivastranslate" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"
        *ngIf="applicationInfoService.availableReportsToDownload > 0">
        <app-jj-icons [type]="'download-simple'" [width]="20" [height]="20"></app-jj-icons>
        <div class="toolbarDownloadReports__amount">{{ applicationInfoService.availableReportsToDownload }}</div>
    </div>    

    <!-- Demowebseite -->
    <div class="toolbar__item fingerCursor" id="toolbarDemoWebsiteLink" (click)="showDemoWebsite()" 
        [tippy]="'DemoBanner.Label.Button1Text' | axivastranslate" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"
        *ngIf="applicationInfoService.applicationSettings['isDemoproject'] == 'true'">
        <app-jj-icons [type]="'browser'" [width]="20" [height]="20"></app-jj-icons>
    </div>    

    <div class="toolbar__item fingerCursor" id="toolbarExport" (click)="accountExport()" [tippy]="'Toolbar.Label.Leadreport' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement:'bottom'}"
        *ngIf="applicationInfoService.currentAccount != null && commonService.checkApplicationRoute('account') == true">
       <!--  <svg-icon src="assets/images/svg/toolbar/toolbar_export.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon> -->
        <app-jj-icons [type]="'export'"[width]="20" [height]="20"></app-jj-icons>
    </div>    

    <div class="toolbar__item fingerCursor" id="toolbarResearch" (click)="calltoolbarNav('research')" (mouseleave)="component= null" [tippy]="'Toolbar.Label.Research' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"
        *ngIf="applicationInfoService.currentAccount != null && commonService.checkApplicationRoute('account') == true">
        <app-jj-icons [type]="'globe'" [width]="20" [height]="20"></app-jj-icons>
        <app-toolbar-nav [type]="'research'" *ngIf="component === 'research'" (mouseleave)="component= null"></app-toolbar-nav>
    </div>

  <!--   <div class="toolbar__item fingerCursor" id="toolbarResearch" (click)="calltoolbarNav('research')" [tippy]="'Toolbar.Label.Research' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"
    *ngIf="applicationInfoService.currentAccount != null && commonService.checkApplicationRoute('account') == true">
    <app-jj-icons [type]="'globe'" [width]="20" [height]="20"></app-jj-icons>
    <app-toolbar-nav [type]="'research'" *ngIf="component === 'research'"></app-toolbar-nav>
    </div> -->
    
    <div class="toolbar__item fingerCursor" id="toolbarMailing" (click)="prepareMail()" [tippy]="'Toolbar.Label.SendMailToContact' | texttransform" 
        *ngIf="checkIfMailCanBeSentToContact()"
        [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}" >
        <app-jj-icons [type]="'envelope'" [width]="20" [height]="20"></app-jj-icons>    
    </div>

    <div class="toolbar__item toolbarItemHidden fingerCursor" id="toolbarPhone" (click)="calltoolbarNav('phone')" 
        *ngIf="applicationInfoService.applicationSettings['isDemoproject'] != 'true'"
        (mouseleave)="component= null" [tippy]="'Toolbar.Label.Phonemethods' | texttransform" 
        [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}">
        <app-jj-icons [type]="'phone'" [width]="20" [height]="20"></app-jj-icons>
        <app-toolbar-nav [type]="'phone'"*ngIf="component === 'phone'" (mouseleave)="component= null" ></app-toolbar-nav>
     </div>


    <div class="toolbar__item fingerCursor" id="toolbarUnlock" (click)="unlockAccount()" [tippy]="'Toolbar.Label.TooltipUnlock' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}"
        *ngIf="applicationInfoService.currentAccount != null && commonService.checkApplicationRoute('account') == true">
        <app-jj-icons [type]="'lock-simple'" [width]="20" [height]="20"></app-jj-icons>
    </div>


    <div class="toolbar__item fingerCursor" id="toolbarPlus" (click)="calltoolbarNav('plus')" (mouseleave)="component= null" [tippy]="'Toolbar.Label.New' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}">
        <app-jj-icons [type]="'plus'" [width]="20" [height]="20" ></app-jj-icons>
        <app-toolbar-nav [type]="'plus'" *ngIf="component === 'plus'" (mouseleave)="component= null"></app-toolbar-nav>
    </div>


    <div class="toolbar__item fingerCursor" id="toolbarPlus" (click)="openSearch()" [tippy]="'General._.QuickSearch' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement: 'bottom'}">
        <app-jj-icons [type]="'magnifying-glass'" [width]="20" [height]="20" ></app-jj-icons>        
    </div>

   <!--  ! moved this section to toolbar header component -->

 <!--    <div *ngIf="checkIfTippIsAvailable()!=null" class="helpActivator fingerCursor" (click)="showHelpContent()" [tippy]="'Toolbar.Label.ToggleHelp' | texttransform" [tippyOptions]="{theme: 'everleadRed', placement:'bottom'}">
        <i class="fas fa-lg fa-question"></i>
    </div> -->
</div>  


<!-- <app-toolbar-phone [type]="'phone'" *ngIf="component === 'phone'"></app-toolbar-phone> -->




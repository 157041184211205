<div class="aiPrompt__wrapper" *ngIf="userService.hasPermission('CanCreateAiPrompt')">
    <div class="agComponentHeader__wrapper">        
        <app-component-header class="agComponentHeader__header" [header]="'Supervisor.Label.AIPromptDesigner' | texttransform"></app-component-header>
        <div class="agComponentHeader__menu">                    
            <div class="agComponentHeader__item">
                <app-main-button type="small" svg="plus-circle" label="AIPrompts.Label.NewPrompt"
                    (onClick)="createAiPrompt()" [tippy]="'General.Label.CreateNewWithDefault' | texttransform"
                    [tippyOptions]="{placement:'bottom'}"></app-main-button>
            </div>
            <app-filter (keyUp)="filterString = $event" class="agComponentHeader__item"></app-filter>        
        </div>
    </div>    

    
    <div class="aiPromptList" *ngIf="selectedPrompt == null">
        <div *ngFor="let aiPrompt of aiPrompts; let promptIndex = index" class="everleadTableRow aiPrompt__item">
            <div class="aiPrompt__item__header" (click)="selectedPrompt = aiPrompt">
                <div class="everleadCoral aiPrompt__item__id">{{ aiPrompt.id }}</div>
                <label class="maxWidth">{{ aiPrompt.defaultName }}</label>
                <div class="aiPrompt__item__header__delete">
                    <!-- <app-jj-icons [type]="'pencil-simple-line'" [width]="20" [height]="20"></app-jj-icons> -->
                    <app-jj-icons [type]="'trash'" [width]="20" [height]="20" (click)="deleteAiPrompt(aiPrompt);$event.stopPropagation()"
                        *ngIf="userService.hasPermission('CanDeleteAiPrompt')"></app-jj-icons>                    
                </div>
            </div>                
        </div>

    </div>
    <app-layout *ngIf="selectedPrompt != null" [header]="selectedPrompt.defaultName">
        <div class="backButton">
            <app-main-button (onClick)="selectedPrompt=null" type="small" label="General.Label.Back"></app-main-button>
        </div>
    
        <app-text-input label="Name" [(ngModel)]="selectedPrompt.defaultName" (valueChange)="saveAiPrompt(selectedPrompt)"></app-text-input>
        <app-checkbox label="AIPrompts.Label.IsDefault" [(ngModel)]="selectedPrompt.isDefault" (valueChange)="saveAiPrompt(selectedPrompt)"></app-checkbox>
        <app-checkbox label="AIPrompts.Label.IsActive" [(ngModel)]="selectedPrompt.isActive" (valueChange)="saveAiPrompt(selectedPrompt)"></app-checkbox>
        <app-select-input label="AIPrompts.Label.Model" [(ngModel)]="selectedPrompt.modelLookupId" [options]="promptModels" display="defaultName" bind="id" (valueChange)="saveAiPrompt(selectedPrompt)"></app-select-input>
        <app-select-input label="AIPrompts.Label.Response" [(ngModel)]="selectedPrompt.responseLookupId" [options]="responseLookups" display="defaultName" bind="id" (valueChange)="saveAiPrompt(selectedPrompt)"></app-select-input>
        <app-select-input label="AIPrompts.Label.Prompt" [(ngModel)]="selectedPrompt.promptLookupId" [options]="promptLookups" display="defaultName" bind="id" (valueChange)="saveAiPrompt(selectedPrompt)"></app-select-input>
        <app-select-input label="AIPrompts.Label.Language" [(ngModel)]="selectedPrompt.languageId" [options]="languages" display="languageName" bind="id" (valueChange)="saveAiPrompt(selectedPrompt)"></app-select-input>

       
        <app-component-header class="agComponentHeader__header" [header]="'AIPrompts.Label.PromptHeader' | texttransform"></app-component-header>
        <div class="aiPrompt__item__promptRow">
            <!-- <label>{{ 'AIPrompts.Label.PromptHeader' | texttransform }}</label> -->
            <div class="aiPrompt__item__promptRow__text">
                <div class="aiPrompt__item__promptRow__text__prompt">
                    <textarea [(ngModel)]="selectedPrompt.prompt" (change)="saveAiPrompt(selectedPrompt)"></textarea>
                </div>
                <div class="aiPrompt__item__promptRow__text__placeholder">
                    <div class="aiPrompt__item__promptRow__text__placeholder__list">                    
                        <div class="everleadTableRow placeholderItem" *ngFor="let placeholder of placeholders">
                            <app-jj-icons [type]="'plus'" [width]="20" [height]="20" (click)="addMemberToPrompt(placeholder, selectedPrompt)"></app-jj-icons>
                            {{ getPlaceHolderInfo(placeholder) }}
                        </div>    
                    </div>   
                </div>
            </div>
        </div>

        <app-component-header class="agComponentHeader__header" [header]="'AIPrompts.Label.Settings' | texttransform"></app-component-header>
        <div class="aiPrompt__item__settings">
            <!-- <div class="aiPrompt__item__settings__header">{{ 'AIPrompts.Label.Settings' | texttransform }}</div> -->
            <app-number-input label="AIPrompts.Label.Temperature" [(ngModel)]="selectedPrompt.settingsArray.Temperature" (change)="saveAiPrompt(selectedPrompt)" 
                step="0.1" [min]="getMinValue(aiPrompt)" [max]="getMaxValue(aiPrompt)"></app-number-input>
            <label class="valueDescription">{{ 'AIPrompts.Label.TemperatureDesc' | texttransform }}</label>
            <app-number-input label="AIPrompts.Label.TopP" [(ngModel)]="selectedPrompt.settingsArray.TopP" (change)="saveAiPrompt(selectedPrompt)" 
                step="0.1" [min]="getMinValue(aiPrompt)" [max]="getMaxValue(aiPrompt)"></app-number-input>
            <label class="valueDescription">{{ 'AIPrompts.Label.TopPDesc' | texttransform }}</label>
            <app-number-input label="AIPrompts.Label.MaxToken" [(ngModel)]="selectedPrompt.settingsArray.MaxTokens" (change)="saveAiPrompt(selectedPrompt)" ></app-number-input>
            <label class="valueDescription">{{ 'AIPrompts.Label.MaxTokensDesc' | texttransform }}</label>
            <app-number-input label="AIPrompts.Label.PresencePenalty" [(ngModel)]="selectedPrompt.settingsArray.PresencePenalty" (change)="saveAiPrompt(selectedPrompt)"
                step="0.1" [min]="getMinValue(aiPrompt)" [max]="getMaxValue(aiPrompt)"></app-number-input>
            <label class="valueDescription">{{ 'AIPrompts.Label.PresencePenaltyDesc' | texttransform }}</label>
            <app-number-input label="AIPrompts.Label.FrequencyPenalty" [(ngModel)]="selectedPrompt.settingsArray.FrequencyPenalty" (change)="saveAiPrompt(selectedPrompt)"
                step="0.1" [min]="getMinValue(aiPrompt)" [max]="getMaxValue(aiPrompt)"></app-number-input>
            <label class="valueDescription">{{ 'AIPrompts.Label.FrequencyPenaltyDesc' | texttransform }}</label>
            <app-checkbox label="AIPrompts.Label.UseTemperature" [(ngModel)]="selectedPrompt.settingsArray.UseTemperature" (valueChange)="saveAiPrompt(selectedPrompt)"></app-checkbox>
             
            <br>
            <app-component-header class="agComponentHeader__header" [header]="'AIPrompts.Label.Image' | texttransform"></app-component-header>
            <!-- <div class="aiPrompt__item__settings__header">{{ 'AIPrompts.Label.Image' | texttransform }}</div> -->
            <div class="aiPrompt__item__settings__headersmall">{{ 'AIPrompts.Label.ImageDesc' | texttransform }}</div>
            <div class="aiPrompt__item__settings__line">                        
                <div class="aiPromptImage everleadShadowBox">
                    <div class="aiPromptImage__left">
                        <img [src]="selectedPrompt.pictureData" class="aiPromptImagePreview" *ngIf="selectedPrompt.pictureData != '' && selectedPrompt.pictureData != undefined">
                        <i class="fa-4x far fa-image" *ngIf="selectedPrompt.pictureData == '' || selectedPrompt.pictureData == undefined"></i>
                    </div>
                    <div class="aiPromptImage__right">
                        <input type="file" #file accept="image/*" placeholder="Choose file" (change)="uploadLoadingImage($event, aiPrompt)" style="display:none;">
                        <div class="aiPromptImage__buttons">
                            <app-main-button svg="trash" *ngIf="this.applicationInfoService.userAvatar != ''" [label]="'AIPrompts.Label.RemoveLoadingImage' | texttransform" (onClick)="removeLoadingImage(aiPrompt)"></app-main-button>
                            <app-main-button svg="image" (onClick)="file.click()" [label]="'AIPrompts.Label.UploadLoadingImage' | texttransform"></app-main-button>
                        </div>
                    </div>
                </div>
            </div>
            <br>
        </div>        
    </app-layout>

</div>
import { Injectable } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardcoreService {
  userGroupInt = null;
  chartView={};

  constructor(
    private commonService: CommonService,
    private applicationInfoService: ApplicationInfoService,
    private externalDatasourceService: ExternaldatasourceService
  ) {
    
  }

  createParams() {
    const params = new Object();
    params['_project_id'] = this.applicationInfoService.projectID;
    params['_user_email'] = this.applicationInfoService.user.email;
    params['_language_id'] = 2;
    params['_pool_id'] = this.applicationInfoService.poolId;
    params['_user_id'] = this.applicationInfoService.userID;
    if (this.applicationInfoService.campaingnModeId == null) {
      params['_campaign_id'] = 0;
    } else {
      params['_campaign_id'] = this.applicationInfoService.campaingnModeId;
    }      
    return params;        
  }

  getUserGroupInt(): Promise<any> {
    return new Promise((getUserGroupIntResolve, getUserGroupIntReject) => {
      if (this.userGroupInt != null) {
        getUserGroupIntResolve(this.userGroupInt);
      } else {
        this.externalDatasourceService.executeExternalDataSource(603).then(getUserGroupIntResult => {
          this.userGroupInt = getUserGroupIntResult;
          getUserGroupIntResolve(getUserGroupIntResult);
        });    
      }
    })
  }

  getDashboardData(dashboardId, params = null, useDefault = true): Promise<any> {
    return new Promise((getDashboardDataResolve, getDashboardDataReject) => {
      const dashboardData = [];
      let queryParams = null ;
      if (useDefault == true) {
        queryParams = this.createParams();
      } else {
        queryParams = new Object();
      }
      params.forEach(param => {
        queryParams[param.key] = param.value;
      });      
      this.getUserGroupInt().then(() => {
        const queryData = {
          id: dashboardId,
          params: JSON.stringify(queryParams)
        }
        this.externalDatasourceService.executeExternalDataSource(772, [
          this.commonService.getModifyArrayBody(queryData, [])
        ]).then(getDataResult => {
          let dataSet = 0;
          getDataResult.forEach(getDataResultItem => {          
            dashboardData[dataSet] = [];
            getDataResultItem.data.forEach(dataItem => {
              dashboardData[dataSet].push(JSON.parse(dataItem));
            });
            dataSet ++;
          });
          getDashboardDataResolve(dashboardData);
        }).catch(error => {
          console.error(error);
          getDashboardDataReject(error);
        });  
      })
    });    
  }


  getPercentagefromArray(data, string, property, round=1){
      const total = data?.reduce((sum, item) => sum + item[string], 0); 
      return data.map((item) =>{
        const percent= (item[string] / total ) * 100
  
        return{
          ...item,
          [property]:+percent.toFixed(round),
        }
      })

  }

  cleanKeysfromEntityTable(array){
    return array.map((item) =>{
      const newItem={}
      for (const key in item){
          const newKey = key.replace(/^_\d+_/, '')
          newItem[newKey] = item[key];
         
      }
      return newItem;
      })
  }


  getChartData(type){
    switch(type){
      case 'prospect':
        return this.chartView
    }
  }


  createChartData(data:any, type:string){

    switch(type){
      case 'prospect':
        this.chartView['prospect']= this.cleanKeysfromEntityTable(data);
        break;
      case'prospect-detail':
      this.chartView['prospectdetail'] = this.cleanKeysfromEntityTable(data);
    }

    // console.log('createdata', this.chartView)

  }

}

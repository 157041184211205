
<button class="componentTabButton" [disabled]="disabled" (click)="buttonClick()" [class.disabled]="disabled" [class.active]="active">
    <div class="componentTabButton__inner">
        <app-jj-icons *ngIf="svg && svg !== 'status'" [type]="svg ? svg : ''" [width]="svgW ? svgW : 20" [height]="svgH ? svgH : 20"
            [color]="svgC ? svgC : 'secondary'"></app-jj-icons>
        <app-status *ngIf="svg === 'status'" [color]="svgC" [height]="svgH" [width]="svgW"></app-status>
        <span class="label" *ngIf="label">{{ label | texttransform}}</span>
    </div>
    <span [class.active]="active"></span>
</button>

<div *ngIf="options">
    <div *ngFor="option of options">
        <button class="componentTabButton" [disabled]="disabled" (click)="buttonClick(option.value)" [class.active]="active">
            <div class="componentTabButton__inner">
                <app-jj-icons *ngIf="svg" [type]="svg" [width]="svgW ? svgW : 20" [height]="svgH ? svgH : 20"
                    [color]="svgC ? svgC : 'secondary'"></app-jj-icons>
                <span class="label" *ngIf="option.label">{{ option.label | texttransform }}</span>
            </div>
            <span [class.active]="active"></span>
        </button>
    </div>
</div>

<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name | texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc | texttransform }}</label>
    </div>

    <div class="wizardPageContent maxHeight">
        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1" class="maxHeight">
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'MocoDesign.Label.Project' | texttransform }}
                    <app-wizard-infobutton displayText="MocoDesign.Label.ProjectDescription"></app-wizard-infobutton>
                </label>

                <app-select-input
                label="MocoDesign.Label.Project"
                [(ngModel)]="wizardService.wizardArray.mocoProjectId"
                (valueChange)="getTasksOfMocoProject(wizardService.wizardArray.mocoProjectId, wizardService.wizardArray, true)"
                bind="id"
                display="name"
                [options]="mocoProjects"
                ></app-select-input>
       <!--          <select [(ngModel)]="wizardService.wizardArray.mocoProjectId" class="wizardDropDown" (change)="getTasksOfMocoProject(wizardService.wizardArray.mocoProjectId, wizardService.wizardArray, true)">
                    <option *ngFor="let mocoProject of mocoProjects" [ngValue]="mocoProject.id">
                        {{ mocoProject.name }}
                    </option>
                </select>  -->
            </div>
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'MocoDesign.Label.Activity' | texttransform }}
                    <app-wizard-infobutton displayText="MocoDesign.Label.ProjectTaskDescription"></app-wizard-infobutton>
                </label>
           <!--      <select [(ngModel)]="wizardService.wizardArray.mocoProjectTaskId" class="wizardDropDown">
                    <option *ngFor="let mocoProjectTask of assignmentTasks[wizardService?.wizardArray.mocoProjectId]" [ngValue]="mocoProjectTask.id">
                        {{ mocoProjectTask.name }}
                    </option>
                </select> -->
               <app-select-input
               [(ngModel)]="wizardService.wizardArray.mocoProjectTaskId"
               label="MocoDesign.Label.Activity"
               [options]="assignmentTasks[wizardService?.wizardArray.mocoProjectId]"
               bind="id"
               display="name"
               ></app-select-input>
            </div>
            <div class="wizardDetailItem">
                <label class="noPadding wizardLabel">
                    {{ 'MocoDesign.Label.Lookup' | texttransform }}
                    <app-wizard-infobutton displayText="MocoDesign.Label.LookupDescription"></app-wizard-infobutton>
                </label>

                <app-select-input
                [(ngModel)]="wizardService.wizardArray.typeLookupId"
                label="MocoDesign.Label.Lookup"
                [options]="lookupValues"
                bind="id"
                display="defaultName"></app-select-input>
             <!--    <select [(ngModel)]="wizardService.wizardArray.typeLookupId" class="wizardDropDown">
                    <option *ngFor="let lookupValue of lookupValues" [ngValue]="lookupValue.id">
                        {{ lookupValue.defaultName }}
                    </option>
                </select>   --> 
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, Injector, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ClickContext } from 'app/jollyjupiter/service/common.service';
import { Subscription } from 'rxjs';
import { EntitytableService } from './entitytable.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { InternalMethodType } from 'app/jollyjupiter/service/method.service'
import { NgxTippyModule, NgxTippyProps } from 'ngx-tippy-wrapper';
import { MicrocampaignService } from 'app/jollyjupiter/service/microcampaign.service';
import { ConfirmationService } from 'primeng/api';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { DashboardcoreService } from '../../dashboards/dashboardcore.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-entitytable',
  templateUrl: './entitytable.component.html',
  styleUrls: ['./entitytable.component.scss']
})

export class EntitytableComponent extends CustomControlBaseClass implements OnInit, OnDestroy, AfterViewInit {
  compactListRunQueryWithSpecificFilterEventSubscription: Subscription = new Subscription();
  customEventSubscription: Subscription = new Subscription();
  selectCurrentDataFilterEventSubscription: Subscription = new Subscription();
  updateControlContentEventSubscription: Subscription = new Subscription();
  canEditControl = false;
  clickContext = new ClickContext();
  cellClickedEvent = this.clickContext.clickHandler((param) => { this.rowClicked(param); });
  cellDblClickedEvent = this.clickContext.dblClickHandler((param) => { this.rowDblClicked(param); });
  refreshBlocker = false;
  tableLayout = [];
  dataTableItem = null;
  filterId = null;
  filterLookupItem = null;
  dateRangeFilterItem = null;
  headerColumns = [];
  subControls = [];
  dateFilter = false;
  lookupFilter = false;
  tableSettingsJson = [];
  loadingData = false;
  tableSettings = {
    currentPage: 1,
    globalFilter: '',
    pageSize: 20,
    maxItems: 400,
    definitionId: null
  };
  generatedControlId = null;
  filterString = '';
  multiListFilterItems = [];
  columnFilter = {};
  controlDataValues = null;
  contentSearched = false;
  contentFound = false;
  selectedSortCol = {
    id: null,
    orderByOperator: null,
    columnName: null
  };
  defaultSortingUsed = false;
  minLeadScore = 80;
  controlData = {
    "pageNumber": 1,
    "pageSize": 20,
    "pageCount": 0,
    "totalCount": 0,
    "jsonData": []
  };
  atLeastOnRequestDone = false;
  consentDetails = null;
  lazyloading = false;
  userRoles = [];

  // MicroCampaigns
  selectedMicroCampaign = null;
  microcampaigns = null;
  doublicatedFilterIds = [];
  uploadedFilesEntityId = null;
  reducedFeatures = false;
  useMicroCampaignContactsCampaign = null;
  selectedHeaderItemForFiltering = null;
  
  
  // todo if chart in entity table section
/*   chartView:boolean= false;
  activityCount= [];
  chartData
  activity=false; */
  //isAllDataFetched= false;


  constructor(
    private injector: Injector ,
    private axivasTranslateService: AxivasTranslateService,
    private entitytableService: EntitytableService,
    private sharedAPI: SharedAPI,
    public microcampaignService: MicrocampaignService,
    private confirmationService: ConfirmationService,
    private dashboardcoreService: DashboardcoreService,
    private externaldatasourceService: ExternaldatasourceService,
    private datePipe: DatePipe,
   
  ) {
    super(injector);
    this.valueType = ValueType.Nothing;        
  }

  ngAfterViewInit(): void {
    this.appendFilterToBody();
  }

  ngOnInit() {
    this.applicationInfoService.myLeadsSelectedFilter = null;
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.id == 'selectedPoolChanged') { this.getContent(); }
      if (event.id == 'updateEntityTableViewByEntityId' && event.value == this.dataTableItem.mainEntityId) { this.getData(); }
      if (event.id == 'refreshEntityTable') { this.getData(); }
      if (event.eventType == 'updateEntityTableView') { this.getContent(); }
      if (event == 'myLeadsFilterChanged') { this.getContent(); }
      if (event == 'getCompleteProjectInformaton') { this.getContent(); }
      if (event.id == 'updateEntityTableViewConsent') {                
        this.consentDetails = event;
        this.getData();
      }
      if (event.id == 'setEntityTableEntityIdForUploadedFiles') {
        this.uploadedFilesEntityId = event.value;
        this.getData();
      }
    });    
    
    this.updateControlContentEventSubscription = this.eventService.updateControlContentEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        this.getData();
      }
    });

    this.compactListRunQueryWithSpecificFilterEventSubscription = this.eventService.compactListRunQueryWithSpecificFilterEvent.subscribe(event => {
      if (Number(this.controlDefinition.logicalControlId) === Number(event.target)) {
        this.filterId = event.arguments[0];
        this.getData();
      }
    });  

    this.selectCurrentDataFilterEventSubscription = this.eventService.selectCurrentDataFilterEvent.subscribe(() => {
      this.getData();
    }); 

    this.canEditControl = this.userService.hasPermission('CanCreateSecurity.Module.TableLayoutDefinition');
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['newsletterCampaignId'])) {
      this.useMicroCampaignContactsCampaign = this.applicationInfoService.miscSettings['newsletterCampaignId'];
    }
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['presetMicroCampaignId'])) {
      this.useMicroCampaignContactsCampaign = this.applicationInfoService.miscSettings['presetMicroCampaignId'];
    }

    this.getContent();    
  }

  ngOnDestroy(): void {
    if (this.compactListRunQueryWithSpecificFilterEventSubscription) { this.compactListRunQueryWithSpecificFilterEventSubscription.unsubscribe(); }
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
    if (this.selectCurrentDataFilterEventSubscription) { this.selectCurrentDataFilterEventSubscription.unsubscribe(); }
    if (this.updateControlContentEventSubscription) { this.updateControlContentEventSubscription.unsubscribe(); }    
    const filter = document.getElementById(this.getFilterBoxId());    
    if (filter) {
      document.body.removeChild(filter);
    }   
  }

  getUserRoles() {
    this.externalDatasourceService.executeExternalDataSource(819).then(getUserRolesResult => {
      this.userRoles = getUserRolesResult.filter(roleItem => roleItem.projectId == this.applicationInfoService.projectID);
    });    
  }

  getContent() {  
    if (this.applicationInfoService.applicationSettings['isDemoproject'] == 'true' && this.applicationInfoService.demoLandingPage == null) {
      this.externalDatasourceService.executeExternalDataSource(493, []).then(result => {
        if (result.length > 0) {
          this.applicationInfoService.demoLandingPage = result[0].domainFilter;
        }
      });
    }
    this.dataTableItem = this.entitytableService.getEntityTableDefinitionForControl(this.controlDefinition.logicalControlId);

    this.columnFilter = {};
    this.contentSearched = true;
    if (this.dataTableItem) {
      if(this.dataTableItem.mainEntityId == 1354) {
        this.getUserRoles();
      }  
      let lastHeaderItem = null;
      this.tableSettings.definitionId = this.dataTableItem.id;
      this.tableLayout = JSON.parse(this.dataTableItem.jsonLayout);   
      this.tableSettingsJson = JSON.parse(this.dataTableItem.jsonSettings);   
      if (this.commonService.isNullOrUndefined(this.tableSettingsJson['filterBlacklisted'])) {
        this.tableSettingsJson['filterBlacklisted'] = true;
      }
      this.headerColumns = [];   
      this.subControls = [];
      this.tableLayout.forEach(tableLayoutItem => {
        if (tableLayoutItem.visible == true) {
          const member = this.applicationInfoService.entityMember.toArray().find(member => member.id == tableLayoutItem.memberId);
          if (member) {
            if (member.lookupTableId) {
              tableLayoutItem.lookupTableId = member.lookupTableId;
              tableLayoutItem.lookupTableSource = member.lookupTableSource;
            }  
          }
          if (tableLayoutItem.subcontrol != true) {
            this.headerColumns.push(tableLayoutItem);
            lastHeaderItem = tableLayoutItem;
          } else {
            if (lastHeaderItem == null) {
              this.headerColumns.push(tableLayoutItem);
              lastHeaderItem = tableLayoutItem;  
            } else {
              if (this.commonService.isNullOrUndefined(this.subControls[lastHeaderItem.entityId + lastHeaderItem.memberName])) {
                this.subControls[lastHeaderItem.entityId + lastHeaderItem.memberName] = [];
              }
              this.subControls[lastHeaderItem.entityId + lastHeaderItem.memberName].push(tableLayoutItem);
            }
          }  
        }
      });
      this.reducedFeatures = this.entitytableService.reducedFeatures(this.dataTableItem.mainEntityId);

      this.contentFound = true;
      const columnFilter = this.applicationInfoService.miscSettings['entityTableFilter'.concat(this.tableSettings.definitionId)];
      if(!this.commonService.isNullOrUndefined(columnFilter)) {
        this.columnFilter = columnFilter.columnFilter;
        this.headerColumns = columnFilter.headerColumns;
      }
      this.getData();
    } else {
      this.contentFound = false;
    }
  }

  setFilter() {
    this.tableSettings.currentPage = 1;
    this.getData();    
  }

  getDataRowHeight(rowIndex = null) {
    let returnValue = '';
    if (this.applicationInfoService.isMobile()) {
      return 'auto';
    }
    let tableValue = this.tableSettingsJson['fixDataRowHeight'];
    if (this.commonService.isNullOrUndefined(tableValue) || tableValue == '') {
      let defaultTableHeight = '80px';      
      switch(this.dataTableItem.mainEntityId) {
        case 917:
          defaultTableHeight = '140px';
          break;      
      }
        
      return defaultTableHeight;      
    } else {
      returnValue = tableValue;
    }
    return returnValue;
  }

  getConsentData() {
    let consentTypes = [];
    let mediumTypes = [];
    let lastXDays = null;    

    if (this.tableSettingsJson['doubleOptInRequired'] == true) {
      if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['updateEntityTableViewConsent'])) {
        this.consentDetails = this.applicationInfoService.miscSettings['updateEntityTableViewConsent'];
        this.applicationInfoService.miscSettings['updateEntityTableViewConsent'] = null;
      }
      if (this.consentDetails != null) {
        consentTypes = this.consentDetails['consentTypes'];
        mediumTypes = this.consentDetails['mediumTypes'];
        lastXDays = Number(this.consentDetails['lastXDays']);
      }
        
      if (consentTypes.length == 0) {
        consentTypes = [1815];
      }      
      if (mediumTypes.length == 0) {
        mediumTypes = [51, 47];
      }      
    } else {
      if (this.tableSettingsJson['isConsentTable'] == true) {
        mediumTypes = [51, 47];
      }
    }
    let returnValue = {
      consentTypes: consentTypes,
      mediumTypes: mediumTypes,
      lastXDays: lastXDays
    };

    // Sonderfall, wenn die Table im Wizard die ConsentWerte die irgendwo anders festgelegt wurden braucht
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['consentWizardArray'])) {
      if (this.tableSettingsJson['getConsentFromMiscSettings'] == true) {
        const consentTempdata = this.applicationInfoService.miscSettings['consentWizardArray']
        returnValue = {
          consentTypes: consentTempdata.consentTypes,
          mediumTypes: consentTempdata.mediumTypes,
          lastXDays: consentTempdata.lastXDays
        };
      }
    }

    this.applicationInfoService.miscSettings['lastMatTableConsentData'] = returnValue;
    return returnValue;
  }
  
  subControlArray(array) {
    if (this.commonService.isNullOrUndefined(array)) {
      array = [];
    }
    return array;
  }

  getData(appendData = false, fetchAll?:boolean) {   
    if (!this.controlDefinition.isActive) {
      return;
    }
      
    if (appendData == false) {
      this.tableSettings.currentPage = 1;
    }
    if (this.controlData.pageCount != 0) {
      if (this.tableSettings.currentPage < 1) { 
        this.tableSettings.currentPage = 1;
        return;
      }
      if (this.tableSettings.currentPage > this.controlData.pageCount) { 
        this.tableSettings.currentPage = this.controlData.pageCount;
        return;
      }  
    } else {      
      this.tableSettings.currentPage = 1;
      // if (this.dataTableItem.mainEntityId == this.entitytableService.optinEntity) {  
      //   this.applicationInfoService.miscSettings['lastMatTablePage' + this.applicationInfoService.currentContact.id + this.controlDefinition.logicalControlId] = null;
      // } else {
      //   this.applicationInfoService.miscSettings['lastMatTablePage' + this.controlDefinition.logicalControlId] = null;
      // }      
    }    
    
    // VIP
    let vipSettings = 'withVIP: null vipViewName:"view_user_vip_list"';
    if (this.tableSettingsJson['vipActive'] == true) {
      vipSettings = 'withVIP: true vipViewName:"view_user_vip_list"';
    }
    let externalDataSourceId = null;
    if (this.applicationInfoService.applicationSettings['useCampaignModeFilter'] == 'true') {
      externalDataSourceId = 938; 
    } else {
      externalDataSourceId = 763;
    }  
    if (this.dataTableItem.mainEntityId == 4) { 
      if (this.applicationInfoService.applicationSettings['useCampaignModeFilter'] == 'true') {
        externalDataSourceId = 939; 
      } else {
        externalDataSourceId = 765;
      }      
    }
    if (this.dataTableItem.mainEntityId == 2) { externalDataSourceId = 978; }
    if (this.dataTableItem.mainEntityId == 462) { externalDataSourceId = 771; }
    if (this.dataTableItem.mainEntityId == 917) { externalDataSourceId = 839; }
    if (this.dataTableItem.mainEntityId == 934) { externalDataSourceId = 799; }
    if (this.dataTableItem.mainEntityId == this.entitytableService.vipEntity) { externalDataSourceId = 825; }
    if (this.dataTableItem.mainEntityId == this.entitytableService.aiContactEntity) { externalDataSourceId = 836; }
    if (this.dataTableItem.mainEntityId == this.entitytableService.optinEntity) { externalDataSourceId = 857; }
    if (this.dataTableItem.mainEntityId == this.entitytableService.emailEntity) { externalDataSourceId = 885; }
    if (this.dataTableItem.mainEntityId == this.entitytableService.eventsEntity) { externalDataSourceId = 895; }
    if (this.dataTableItem.mainEntityId == this.entitytableService.contentLibraryFileEntity) { externalDataSourceId = 901; }
    if (this.dataTableItem.mainEntityId == this.entitytableService.opportunityEntity) { externalDataSourceId = 959; }    
    if (this.dataTableItem.mainEntityId == this.entitytableService.userProjectEntity) { externalDataSourceId = 967; }    
    if (this.tableSettingsJson['doubleOptInRequired'] == true) {
      externalDataSourceId = 841;
    } else if (this.tableSettingsJson['isConsentTable'] == true) {
      externalDataSourceId = 964;
    }
    if (this.tableSettingsJson['showLostOpportunities'] == true) {
      externalDataSourceId = 928;
    } 

    // CONSENT
    const consentData = this.getConsentData();

    // IncludePendingSteps
    let includePendingSteps = false;
    if (this.dataTableItem.mainEntityId == 2) {
      if (
        this.tableSettingsJson['includePendingSteps'] == true ||
        (this.commonService.isNullOrUndefined(this.tableSettingsJson['includePendingSteps']) && this.dataTableItem.mainEntityName == 'activity')
      ) {
        includePendingSteps = true;
      }
    }
    // KEYWORDSEARCH
    let keyWordsValue = '';
    let keyWordsValueArray = [];
    let keyWordsFieldToCheck = this.applicationInfoService.applicationSettings['keyWordsFieldToCheck'];
    let keyWordsSearchAllValues = false;
    if (this.tableSettingsJson['useMicroCampaignContacts'] == true) {
      externalDataSourceId = 930;
    }    
    if (this.tableSettingsJson['useAccountKeyWordSearch'] == true) {
      externalDataSourceId = 977;
      keyWordsValue = this.applicationInfoService.entities.Item('46').data.keywords;
      keyWordsSearchAllValues = this.applicationInfoService.entities.Item('46').data.allkeywords;
      if (this.applicationInfoService.applicationSettings['eonSpecialSearchForNewAccounts'] == 'true') {
        if (this.commonService.isNullOrUndefined(this.entityService.getEntityValue(46, 'adressid', 1))) {
          keyWordsValue = this.applicationInfoService.entities.Item('46').data.name1;
        } else {
          keyWordsValue = this.applicationInfoService.entities.Item('46').data.adressid;
          keyWordsFieldToCheck =   'adressid';
        }        
      // } else {
      //   if (this.commonService.isNullOrUndefined(keyWordsValue) || keyWordsValue == '') {
      //     keyWordsValue = this.applicationInfoService.entities.Item('46').data.name1;
      //     if (keyWordsValue.length > 3) {
      //       keyWordsValue = keyWordsValue.substr(0, 3);
      //     }
      //   }
      }
      if (this.commonService.isNullOrUndefined(keyWordsValue)) { keyWordsValue = ''; }
      if (this.commonService.isNullOrUndefined(keyWordsSearchAllValues)) { keyWordsSearchAllValues = false; }
      this.applicationInfoService.miscSettings['entityTableKeywords'] = keyWordsValue;

      const keywordDetails = keyWordsValue.split(' ');
      if (keywordDetails.length > 0) {
        keywordDetails.forEach(keywordDetailsItem => {
          keyWordsValueArray.push(keywordDetailsItem);
        });
      }
    }

    let filter = this.filterId;
    if (this.applicationInfoService.myLeadsSelectedFilter != null) {
      filter = this.applicationInfoService.myLeadsSelectedFilter.id;
    }

    if (this.applicationInfoService.matTableTempFilter != null) {
      filter = this.applicationInfoService.matTableTempFilter;
    }

    const sorting = this.getSorting();

    if (this.reducedFeatures) {
      this.tableSettings.pageSize = 5;
      this.tableSettings.currentPage = 1;
    }

    // CampaignId
    let campaignId = this.applicationInfoService.campaingnModeId;
    if (this.tableSettingsJson['onlyForCurrentCampaign'] != true) {
      campaignId = null;
    }

    // todo if fetch all results is needed
  /*   if(fetchAll){
      console.log('this totalCount', this.controlData.totalCount);
      this.tableSettings.currentPage =1;
      this.tableSettings.pageSize= this.controlData.totalCount
    }  */


    if (this.commonService.isNullOrUndefined(this.tableSettings.definitionId)) { return; }
    this.loadingData = true;
    this.externalDatasourceService.executeExternalDataSource(externalDataSourceId, [
      this.tableSettings.definitionId, // <0>
      this.tableSettings.pageSize, // <1>
      this.tableSettings.currentPage, // <2>
      this.getColumnFilter(), // <3>
      sorting, // <4>
      this.getGlobalFilter(), // <5>
      filter, // <6>
      vipSettings, // <7>
      consentData.consentTypes, // <8>
      consentData.mediumTypes, // <9>
      consentData.lastXDays, // <10>
      keyWordsValueArray, // <11> keyWordSearchValue
      keyWordsSearchAllValues, // <12> keyWordSearchAll
      keyWordsFieldToCheck, // 13
      this.getEntityCondition(), // 14
      this.useMicroCampaignContactsCampaign, // 15
      campaignId, // 16
      includePendingSteps // 17
    ])
    .then(result => {
      if (!appendData) {
        this.controlDataValues = [];        
      }      
      if (result != null) {
        this.applicationInfoService.miscSettings['entityTableFilter'.concat(this.tableSettings.definitionId)] = {
          columnFilter: this.columnFilter,
          headerColumns: this.headerColumns
        };
        if (result) {         
          this.controlData = result;
          result.jsonData.forEach(item => {
            item = JSON.parse(item);          
            this.controlDataValues.push(item);
          });

          if(this.dataTableItem.mainEntityId == 780){
            this.eventService.chartDatachange.emit({id: 'entitytable-chart', totalCount:this.controlData.totalCount})
            // todo  if future wants charts in the entity table this is for later
           /*  this.dashboardcoreService.createChartData(this.controlDataValues, 'prospect-detail');
            this.createChartForEntityTable() */
          }

        
          // if (this.dataTableItem.mainEntityId == this.entitytableService.optinEntity) {  
          //   this.applicationInfoService.miscSettings['lastMatTablePage' + this.applicationInfoService.currentContact.id + this.controlDefinition.logicalControlId] = this.tableSettings.currentPage;
          // } else {
          //   this.applicationInfoService.miscSettings['lastMatTablePage' + this.controlDefinition.logicalControlId] = this.tableSettings.currentPage;
          // }          
          this.applicationInfoService.miscSettings['lastMatTableResultWithoutControlFilters'] = this.controlDataValues;
          this.applicationInfoService.miscSettings['lastColumnFilters'] = this.getColumnFilter();
          this.applicationInfoService.miscSettings['lastMatTableResultWithoutControlFiltersCountTotalRecords'] = result.totalCount;
          this.applicationInfoService.miscSettings['lastMatTableResultTableDefinitionId'] = this.tableSettings.definitionId;
          this.applicationInfoService.dataFilterFilteredItemArray[this.controlDefinition.logicalControlId] = this.controlDataValues;
          this.eventService.customEvent.emit({id: 'lastMatTableResultWithoutControlFilters'});  
        }        
      } else {
        this.controlData = { "pageNumber": 1, "pageSize": 20, "pageCount": 0, "totalCount": 0, "jsonData": [] };
        this.applicationInfoService.miscSettings['lastMatTableResultWithoutControlFilters'] = this.controlDataValues;
        this.applicationInfoService.miscSettings['lastMatTableResultWithoutControlFiltersCountTotalRecords'] = 0;
      }
      this.atLeastOnRequestDone = true;
      this.lazyloading = false;
      this.loadingData = false;
    }).catch(() => { this.loadingData = false; }); 
    
  }

  getColumnFilter(forExport = false) {
    const filterArray = [];
    
    Object.keys(this.columnFilter).forEach(filter =>  {      
      if (this.columnFilter[filter] != '' && this.columnFilter[filter] != null) {
        let arrayItem = { columnName: filter, value: this.columnFilter[filter] };      
        filterArray.push(arrayItem);  
      }
    });
    
    let myUserId = this.applicationInfoService.userID;
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.user.fakeUserId)) {
      myUserId = this.applicationInfoService.user.fakeUserId;
    }
    switch (this.dataTableItem.mainEntityId) {
      case 1:
        if (this.tableSettingsJson['onlyForCurrentPool']==true) { 
          filterArray.push({ columnName: 'poolId', value: this.applicationInfoService.poolId.toString() }); }
        if (this.tableSettingsJson['showOnlyAccountVips']==true) { 
          filterArray.push({ columnName: 'userId', operator: 'EQ', value: null, negate: true }); }        
        break;

      case 2:
        if (this.tableSettingsJson['showOnlyDataForCurrentUser']==true) { 
          if (this.dataTableItem.mainEntityName == 'activity') {
            filterArray.push({ columnName: 'resultUserId', value: myUserId.toString() }); 
          } else {
            filterArray.push({ columnName: 'followUpUserId', value: myUserId.toString() }); 
          }          
        }        
        if (this.tableSettingsJson['onlyForCurrentCampaign']==true) {
          if (this.applicationInfoService.campaingnModeId != null) { 
            filterArray.push({ columnName: 'campaignId', value: this.applicationInfoService.campaingnModeId.toString() 
            }); }          
        }        
        if (this.tableSettingsJson['onlyForCurrentPool']==true) { 
          filterArray.push({ columnName: 'poolId', value: this.applicationInfoService.poolId.toString() 
          }); }

        if (this.tableSettingsJson['showOnlyContactVips']==true) { 
          filterArray.push({ columnName: 'userId', operator: 'EQ', value: null, negate: true 
          }); }    

        if (this.tableSettingsJson['showTastsWithoutContact']==true) { 
          filterArray.push({ columnName: 'contactId', operator: 'EQ', value: null, negate: false 
          }); }                
        break;

      case 4:
        if (this.tableSettingsJson['onlyForCurrentPool']==true) { 
          filterArray.push({ columnName: 'poolId', value: this.applicationInfoService.poolId.toString() }); }
        if (this.tableSettingsJson['showContactsForSelectedAccount']==true) { 
          filterArray.push({ columnName: 'accountId', value: this.applicationInfoService.currentAccount.id.toString() }); }
        if (this.tableSettingsJson['aicontactsActive']==true) { 
          filterArray.push({ columnName: 'isAiContact', value: true.toString() }); }
        if (this.tableSettingsJson['aicontactsHighPotentialActive']==true) { 
          filterArray.push({ columnName: 'isAiSuggestion', value: true.toString() }); }
        if (this.tableSettingsJson['showOnlyContactVips']==true) { 
          filterArray.push({ columnName: 'userId', operator: 'EQ', value: null, negate: true }); }        
        if (this.tableSettingsJson['aicontactsActive']==true || this.tableSettingsJson['aicontactsHighPotentialActive']==true) {
          filterArray.push({ columnName: 'leadScoringCalculated', value: this.minLeadScore.toString(), operator: 'GTE' });
        }        
        break;
      
      case this.entitytableService.optinEntity:
        filterArray.push({ columnName: 'accountId', value: this.applicationInfoService.currentAccount.id.toString() });
        if (this.tableSettingsJson['onlyOptinsForContact']==true || this.applicationInfoService.currentAccount.isDummyAccount == true) { 
          if (this.applicationInfoService.currentContact != null) {
            filterArray.push({ 
              columnName: 'contactId', value: this.applicationInfoService.currentContact.id.toString() 
            });   
          }
        }        
        break;

      case 462:
        if (this.tableSettingsJson['onlyForCurrentPool']==true) { 
          filterArray.push({ columnName: 'poolId', value: this.applicationInfoService.poolId.toString() }); }
        break;
      
      case 917:
        if (this.tableSettingsJson['onlyForCurrentPool']==true) { 
          filterArray.push({ columnName: 'poolId', value: this.applicationInfoService.poolId.toString() }); }
        if (this.tableSettingsJson['filterBlacklisted']==true) { 
          filterArray.push({ columnName: 'isBlacklisted', value: 'false' });           
        } 
        if (
          this.tableSettingsJson['showIspItems'] != true && 
          this.applicationInfoService.applicationSettings['useProspectIspForFiltering']=='true' ) { 
          filterArray.push({ columnName: 'isisp', value: 'false' });           
        }         
        if (this.tableSettingsJson['alreadyInEverlead']==true) { 
          filterArray.push({ columnName: 'accountId', value: null, negate: true }); }
        if (this.tableSettingsJson['notInEverlead']==true) { 
          filterArray.push({ columnName: 'accountId', value: null, negate: false }); }
        break;
      
      case this.entitytableService.vipEntity:
        break;    
      
      case this.entitytableService.eventsEntity:
        if (this.tableSettingsJson['showEventsForCurrentProspect']==true) { 
          filterArray.push({ columnName: 'prospectId', value: this.applicationInfoService.miscSettings['currentProspect'].toString() }); 
        }        
        break;

      case this.entitytableService.opportunityEntity:
        if (this.tableSettingsJson['onlyForCurrentPool']==true) { filterArray.push({ columnName: 'poolId', value: this.applicationInfoService.poolId.toString() }); }
        if (this.tableSettingsJson['opportunityLevel']== 'account') { 
          if (this.applicationInfoService.currentAccount.isDummyAccount) {
            filterArray.push({ columnName: 'contactId', value: this.applicationInfoService.currentContact?.id.toString() });
          } else {
            filterArray.push({ columnName: 'accountId', value: this.applicationInfoService.currentAccount?.id.toString() });
          }          
        }
        if (this.tableSettingsJson['showOnlyDataForCurrentUser']==true) { 
            filterArray.push({ columnName: 'createdBy', value: myUserId.toString() }); 
        }       
        break;
      
      case this.entitytableService.userProjectEntity:
        filterArray.push({ columnName: 'projectId', value: this.applicationInfoService.projectID.toString() });
        filterArray.push({ columnName: 'isExternalUser', value: 'true' });
        break;
    }    

    // Automatic filter (setting in designer)
    this.tableLayout.forEach(tableLayoutItem => {
      let addItem = true;
      if(tableLayoutItem.useAutomaticFilter == true) {
        let automaticFilterValue = tableLayoutItem.automaticFilterValue;
        if (tableLayoutItem.useNullAsValue) {
          automaticFilterValue = null;
        }      
        if (automaticFilterValue != null)   {
          // Account
          if (this.applicationInfoService.currentAccount) {
            automaticFilterValue = automaticFilterValue.replace('{accountId}', this.applicationInfoService.currentAccount.id);
          }         
          // Contact 
          if (this.applicationInfoService.currentContact) {
            automaticFilterValue = automaticFilterValue.replace('{contactId}', this.applicationInfoService.currentContact.id);
          }      
          // Task    
          if (this.applicationInfoService.currentTask) {
            automaticFilterValue = automaticFilterValue.replace('{taskId}', this.applicationInfoService.currentTask.id);
          } 
          // User
          if (this.commonService.isNullOrUndefined(this.applicationInfoService.user.fakeUserId)) {
            automaticFilterValue = automaticFilterValue.replace('{userId}', this.applicationInfoService.userID);         
          } else {
            automaticFilterValue = automaticFilterValue.replace('{userId}', this.applicationInfoService.user.fakeUserId);         
          }
          // CampaignModeId
          if (this.commonService.checkIfStringContainsString(automaticFilterValue, '{campaignModeId}') && 
              this.applicationInfoService.campaingnModeId == null) {
            addItem = false;
          }
          automaticFilterValue = automaticFilterValue.replace('{campaignModeId}', this.applicationInfoService.campaingnModeId);

        }
        let negate = false;
        if( tableLayoutItem.negateFilter == true) {
          negate = true;
        }
        if (addItem) {
          filterArray.push({ 
            columnName: tableLayoutItem.memberName, 
            value: automaticFilterValue ,
            negate: negate
          });  
        }
      }

      // Spezialfall AccountGroup      
      if (tableLayoutItem.memberName == 'typeLookupId' && !this.commonService.isNullOrUndefined(
        this.applicationInfoService.miscSettings['accountGroupSubsidaryLookupId']
      )) {
        let accountGroupTypeId = this.applicationInfoService.miscSettings['accountGroupSubsidaryLookupId'];
        if (this.applicationInfoService.miscSettings['accountGroupShowParents'] == 'true') {
          accountGroupTypeId = this.applicationInfoService.miscSettings['accountGroupParentLookupId'];
        }        
        filterArray.push({ 
          columnName: tableLayoutItem.memberName, 
          value: accountGroupTypeId
        });  
      }

    });

    // New userspecific filter
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['entityTableCustomFilter'.concat(this.dataTableItem.logicalControlId).toString()])) {
      const filterList = this.applicationInfoService.miscSettings['entityTableCustomFilter'.concat(this.dataTableItem.logicalControlId).toString()];      
      filterList.forEach(filterListItem => {
        if (this.commonService.isNullOrUndefined(filterListItem.option1)) {
          filterListItem.option1 = '';
        }
        let option1 = filterListItem.option1;
        if (filterListItem.lookupValues?.length > 0) {
          filterListItem.lookupValues.forEach(item => {
            if (item.selected == true) {
              if (filterListItem.operator != 'IN' && filterListItem.operator != 'INCSV') {
                filterListItem.operator = 'IN';
              }              
              if (option1 != '') { option1 = option1.concat(','); }
              option1 = option1.concat(item.defaultName);
            }
          });
        }
        if (option1 != '') {
          filterArray.push({ 
            isSqlFilter: true,
            entityId: filterListItem.entityId,
            columnName: filterListItem.memberName,
            operator: filterListItem.operator,
            value: option1,
            value2: filterListItem.option2,
            memberType: filterListItem.memberType
          });
        }
      });
    }
    if (forExport) {
      return filterArray;
    }
    
    let filterArrayJson = JSON.stringify(filterArray);

    Object.keys(filterArray).forEach(() =>  {      
      filterArrayJson = filterArrayJson.replace('"'.concat('columnName', '"'), 'columnName');
      filterArrayJson = filterArrayJson.replace('"'.concat('value', '"'), 'value');
      filterArrayJson = filterArrayJson.replace('"'.concat('value2', '"'), 'value2');
      filterArrayJson = filterArrayJson.replace('"'.concat('operator', '"'), 'operator');
      filterArrayJson = filterArrayJson.replace('"'.concat('negate', '"'), 'negate');
      filterArrayJson = filterArrayJson.replace('"'.concat('entityId', '"'), 'entityId');
      filterArrayJson = filterArrayJson.replace('"'.concat('isSqlFilter', '"'), 'isSqlFilter');
      filterArrayJson = filterArrayJson.replace('"'.concat('memberType', '"'), 'memberType');
    });
    return filterArrayJson;
  }

  addFilterToArray(array, columnName, value, negate = false) {

  }

  getMaxHeight() {
    if (this.controlDefinition.height != '' && this.controlDefinition.height != null) {
      return Number(this.controlDefinition.height);
    }
    
    let additinalValueToSubstractFromHeight = 0;
    if (this.tableSettingsJson['canStartMicroCampaign'] == true || this.tableSettingsJson['canStartMailshot'] == true) {
      additinalValueToSubstractFromHeight = 50;
    };

    if (!this.commonService.isNullOrUndefined(this.tableSettingsJson['additinalValueToSubstractFromHeight'])) {
      additinalValueToSubstractFromHeight = Number(this.tableSettingsJson['additinalValueToSubstractFromHeight']);
    }
    if (
          this.tableSettingsJson['heightUntilControlId'] != '' 
          && !this.commonService.isNullOrUndefined(this.tableSettingsJson['heightUntilControlId'])
    ) {
      const elementId = this.tableSettingsJson['heightUntilControlId'];
      if (document.getElementById(elementId)) {
        let calculatedHeight = Number(this.uiService.getDesignSpanPositionMobile('getMaxHeightSpan', (additinalValueToSubstractFromHeight)));
        let calculatedHeightBottomElement = this.uiService.getDesignSpanPositionMobile(elementId, (0))
        return calculatedHeight - calculatedHeightBottomElement;
      }
    } else {
      return Number(this.uiService.getDesignSpanPositionMobile('getMaxHeightSpan', (45 + additinalValueToSubstractFromHeight)));
    }    
  }

  pageSizeChangedManualy() {
    if (this.controlData.pageNumber < 1) { this.controlData.pageNumber = 1; }
    if (this.controlData.pageNumber > this.controlData.pageCount) { this.controlData.pageNumber = this.controlData.pageCount; }
    this.getData();
  }

  getResultString() {
    let returnValue = '';
    let first = 1 + ((this.controlData.pageNumber - 1) * this.controlData.pageSize); 
    let last = this.controlData.pageNumber * this.controlData.pageSize;
    if (last > this.controlData.totalCount) {
      last = this.controlData.totalCount;
    }
    returnValue = returnValue.concat(
      first.toString(), ' - ', 
      last.toString(), ' ', 
      this.axivasTranslateService.getTranslationTextForToken('MatTable.Label.Of'), ' ', 
      this.controlData.totalCount.toString(), ' ',
      this.axivasTranslateService.getTranslationTextForToken('MatTable.Label.Rows')
    );
    return returnValue;
  }

  firstPage() {
    this.tableSettings.currentPage = 1;
    this.getData();    
  }

  nextPage() {
    this.tableSettings.currentPage ++;
    this.getData();
  }

  lastPage() {
    this.tableSettings.currentPage = this.controlData.pageCount;
    this.getData();    
  }

  previousPage() {
    this.tableSettings.currentPage --;
    this.getData();
  }

  showEntityTableDesigner() {
    this.applicationInfoService.miscSettings['entityTableLogicalControlId'] = this.controlDefinition.logicalControlId;
    this.eventService.showJjPopup('EntityTableDesigner.Label.Header', 'entitytable-designer', '80');
  }

  showEntityTableFilter() {
    this.applicationInfoService.miscSettings['entityTableLogicalControlId'] = this.controlDefinition.logicalControlId;
    this.eventService.showJjPopup('EntityTableFilter.Label.Header', 'entitytable-filter', '80');
  }

  // ! ivo 

  rowClicked(row) {
    this.refreshBlocker = true;
    this.enrichResults(row);
    this.controlValue = row;
    //this.dashboardcoreService.chartView['prospectdetail'] = row;

    this.applicationInfoService.lastClickedMatTable = this.controlDefinition.id;
    this.callMethod('onClick', this.controlDefinition);    
    setTimeout(() => { 
      this.refreshBlocker = false; 
    }, 2000);
  }

  rowDblClicked(row) {
    this.refreshBlocker = true;
    this.controlValue = row;
    this.callMethod('onDblClick', this.controlDefinition);
    setTimeout(() => { 
      this.refreshBlocker = false; 
    }, 2000);
  }

  sortDataByColIndex(tableLayoutItem, getData = true, defaultSort = false) {        
    if (defaultSort) {
      if (this.commonService.isNullOrUndefined(tableLayoutItem.defaultSort)) {
        tableLayoutItem.defaultSort = 'DESC_NULLS_LAST';
      }
      if (tableLayoutItem.defaultSort == 'DESC_NULLS_LAST') {
        this.selectedSortCol.id = tableLayoutItem.memberName.concat('DESC');
      } else {
        this.selectedSortCol.id = tableLayoutItem.memberName.concat('ASC');
      }
      this.selectedSortCol.columnName = tableLayoutItem.memberName;
      this.selectedSortCol.orderByOperator = tableLayoutItem.defaultSort;
      if (getData) {
        this.tableSettings.currentPage = 1;
        this.getData();
      }  
      return;
    }

    
    if (this.selectedSortCol.columnName == tableLayoutItem.memberName) {
      if (this.selectedSortCol.orderByOperator == 'DESC_NULLS_LAST') {
        this.selectedSortCol.id = this.selectedSortCol.columnName .concat('ASC');
        this.selectedSortCol.orderByOperator = 'ASC_NULLS_LAST';  
      } else {
        this.selectedSortCol.id = this.selectedSortCol.columnName.concat('DESC');
        this.selectedSortCol.orderByOperator = 'DESC_NULLS_LAST';  
      }
    } else {
      this.selectedSortCol.columnName = tableLayoutItem.memberName;
      this.selectedSortCol.id = this.selectedSortCol.columnName.concat('ASC');
      this.selectedSortCol.orderByOperator = 'ASC_NULLS_LAST';
    }
    if (getData) {
      this.tableSettings.currentPage = 1;
      this.getData();
    }    
  }

  getGlobalFilter() {
    if (this.tableSettings.globalFilter == '') {
      return '';
    } else {
      return 'crossColumnFilter: '.concat('"', this.tableSettings.globalFilter, '"');
    }
  }

  getSorting(forExport = false) {
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.miscSettings['entityTableSorting'.concat(this.tableSettings.definitionId)]) 
      && !this.defaultSortingUsed) {
      const selectedSortCol = this.applicationInfoService.miscSettings['selectedSortCol'.concat(this.tableSettings.definitionId)];
      this.defaultSortingUsed = true;      
      let returnValueArray = { 
        columnName: selectedSortCol.columnName,
        orderByOperator: selectedSortCol.orderByOperator 
      }      
      let returnValue = JSON.stringify(returnValueArray);
      this.selectedSortCol.id = selectedSortCol.id;
      this.selectedSortCol.columnName = selectedSortCol.columnName;
      this.selectedSortCol.orderByOperator = selectedSortCol.orderByOperator;
      returnValue = returnValue.replace('"'.concat('columnName', '"'), 'columnName');
      returnValue = returnValue.replace('"'.concat('orderByOperator', '"'), 'orderByOperator');
      returnValue = returnValue.replace('"'.concat('DESC_NULLS_LAST', '"'), 'DESC_NULLS_LAST');
      returnValue = returnValue.replace('"'.concat('ASC_NULLS_LAST', '"'), 'ASC_NULLS_LAST');
      return returnValue;
    }
    let returnValue = null;

    if (this.selectedSortCol.id == null) {
      // Check for default sort
      this.headerColumns.forEach(column => {
        if (column.sortColumn) {
          this.sortDataByColIndex(column, false, true);
        }
      });
    }  

    if (this.selectedSortCol.id == null) {
      this.applicationInfoService.miscSettings['entityTableSorting'.concat(this.tableSettings.definitionId)] = null;
      this.applicationInfoService.miscSettings['selectedSortCol'.concat(this.tableSettings.definitionId)] = null;
      return '';
    }

    
    returnValue = { columnName: this.selectedSortCol.columnName, orderByOperator: this.selectedSortCol.orderByOperator };

    if (forExport) {
      return returnValue;
    }

    returnValue = JSON.stringify(returnValue);
    returnValue = returnValue.replace('"'.concat('columnName', '"'), 'columnName');
    returnValue = returnValue.replace('"'.concat('orderByOperator', '"'), 'orderByOperator');
    returnValue = returnValue.replace('"'.concat('DESC_NULLS_LAST', '"'), 'DESC_NULLS_LAST');
    returnValue = returnValue.replace('"'.concat('ASC_NULLS_LAST', '"'), 'ASC_NULLS_LAST');
    this.applicationInfoService.miscSettings['entityTableSorting'.concat(this.tableSettings.definitionId)] = returnValue;
    this.applicationInfoService.miscSettings['selectedSortCol'.concat(this.tableSettings.definitionId)] = this.selectedSortCol;

    this.defaultSortingUsed = true;
    return returnValue;
  }

  getTableLayoutItemDataFieldName(tableLayoutItem) {
    return '_'.concat(tableLayoutItem.entityId.toString(), '_', tableLayoutItem.memberName.toLowerCase());
  }


  // * DATE FILTER
  editDateFilter(tableLayoutItem) {    
    this.dateRangeFilterItem = tableLayoutItem;
    if (this.commonService.isNullOrUndefined(this.dateRangeFilterItem.dateFilter)) {
      this.dateRangeFilterItem.dateFilter = { dateFrom: null, dateUntil: null};
    }
    //! ivo date filter open old window
    this.dateFilter = true;
  }

  removeDateFilter() {
    this.dateRangeFilterItem.dateFilter = null;
    // ! ivo date filter old window
    this.dateFilter = false;
    this.setDateFilter();
  }

  setDateFilter() {
    this.tableSettings.currentPage = 1;
    if (this.dateRangeFilterItem.dateFilter == null) {
      this.columnFilter[this.dateRangeFilterItem.memberName] = null;
      this.getData();
      return;
    }

    let filterKey = '';
    filterKey = '('.concat(
      this.getDateFilterString(new Date(this.dateRangeFilterItem.dateFilter.dateFrom)),
      ';',
      this.getDateFilterString(new Date(this.dateRangeFilterItem.dateFilter.dateUntil)),
      ')'
    );
    this.columnFilter[this.dateRangeFilterItem.memberName] = filterKey;

    this.dateFilter = false;
    this.getData();    
  }

  getDateFilterString(date: Date) {
    let returnValue = '';
    returnValue = returnValue.concat(
      date.getUTCFullYear().toString(),
      '-',
      (date.getUTCMonth() + 1).toString().padStart(2, '0'),
      '-',
      date.getUTCDate().toString().padStart(2, '0'),
      ' ',
      date.getUTCHours().toString().padStart(2, '0'),
      ':',
      date.getUTCMinutes().toString().padStart(2, '0'),      
      ':',
      date.getUTCSeconds().toString().padStart(2, '0')
    );
    return returnValue;
  }

  // * MULTI FILTER 
  editAlternatefilter(tableLayoutItem, getListOnly = false) {
    this.filterString = '';
    this.filterLookupItem = tableLayoutItem;
    this.filterLookupItem['filterListDisplayValue']= null;
    if (this.commonService.isNullOrUndefined(this.filterLookupItem.filter)) {
      this.filterLookupItem.filter = [];
    }    
    const table = this.commonService.getAlternateTable(tableLayoutItem.alternateLookupTable);
    if (table) {
      this.multiListFilterItems = table.table;      
      this.filterLookupItem['filterListDisplayValue'] = table.alternateDisplayName;      
      if (table.alternateDisplayName == null) {
        this.commonService.sortArrayWithTranslationToken(this.multiListFilterItems);
      }      
    }
    this.prepareFilterDoublicates(table);
    if (getListOnly) {
      return this.multiListFilterItems;
    }
    this.lookupFilter = true;
  }

  prepareFilterDoublicates(table) {
    this.doublicatedFilterIds = [];
    const firstFoundItems = []; 
    let defaultTranslationValue = 'defaultName';
    if (table.alternateDisplayName != null) {
      defaultTranslationValue = table.alternateDisplayName;
    }
    this.multiListFilterItems.forEach(filterItem => {
      let searchName = this.axivasTranslateService.getTranslationTextForToken(
        this.commonService.getTranslationValueFromArray(filterItem, defaultTranslationValue)
      );
      searchName = searchName.toLowerCase();  
      const foundItem = firstFoundItems.find(firstFoundItemsItem => firstFoundItemsItem.checkName == searchName);
      if (foundItem) {
        this.doublicatedFilterIds.push(filterItem.id)
      } else {
        firstFoundItems.push({ checkName: searchName })
      }
    });    
  }

  checkIfFilterItemIsDoublicate(filterItem) {
    return this.commonService.checkIfItemIsInArray(this.doublicatedFilterIds, filterItem.id);
  }


  editMultifilter(tableLayoutItem, getListOnly = false) {
    this.filterString = '';
    this.filterLookupItem = tableLayoutItem;
    this.multiListFilterItems['filterListDisplayValue']= null;
    if (this.commonService.isNullOrUndefined(this.filterLookupItem.filter)) {
      this.filterLookupItem.filter = [];
    }
    const tableData = this.applicationInfoService.projectLookupTables.find(
      projectLookupTable => projectLookupTable.definitionId == Number(tableLayoutItem.lookupTableId) && projectLookupTable.source == tableLayoutItem.lookupTableSource      
    );    
    if (tableData) {
      this.multiListFilterItems = tableData.lookups;
      this.commonService.sortArrayWithTranslationToken(this.multiListFilterItems);
    }
    this.prepareFilterDoublicates(tableData);    
    if (getListOnly) {
      return this.multiListFilterItems;
    }
    this.lookupFilter = true;
  }

  // * remove filters button
  removeMultiFilter() {
    // ! ivo commented
    this.filterLookupItem.filter = [];
    this.setMultiFilter();
    //this.lookupFilter = false;
  }

  // * set Filter button
  setMultiFilter() {
    this.tableSettings.currentPage = 1;
    // ! ivo commented
    //this.multiListFilterItems = [];
    let filterKey = '';
    Object.keys(this.filterLookupItem.filter).forEach(key => {
      if (this.filterLookupItem.filter[key] == true) {
        if (filterKey=='') {
          filterKey = filterKey.concat(key);
        } else {
          filterKey = filterKey.concat(',', key);
        }  
      }
    });
    this.columnFilter[this.filterLookupItem.memberName] = filterKey;
    this.lookupFilter = false; 
    
    // ! call api
    this.getData();
  }

  getMaxWidth(tableLayoutItem) {
    let returnValue = '100%';
    if (this.applicationInfoService.isMobile()) {
      return returnValue;
    }    
    if (tableLayoutItem.maxWidth != '' && !this.commonService.isNullOrUndefined(tableLayoutItem.maxWidth)) {
      returnValue = tableLayoutItem.maxWidth;
    } else {
      if (tableLayoutItem.controlType == 3) { returnValue = '100px'; }
      if (tableLayoutItem.controlType == 4) { returnValue = '130px'; }
      if (tableLayoutItem.controlType == 6) { returnValue = '130px'; }
      if (tableLayoutItem.controlType == 7) { returnValue = '100px'; }
      if (tableLayoutItem.controlType == 9) { returnValue = '70px'; }
      if (tableLayoutItem.controlType == 11) { returnValue = '40px'; }
      if (tableLayoutItem.controlType == 12) { returnValue = '40px'; }
      if (tableLayoutItem.controlType == 15) { returnValue = '130px'; }
      if (tableLayoutItem.controlType == 17) { returnValue = '60px'; }
      if (tableLayoutItem.controlType == 22) { returnValue = '60px'; }
      if (tableLayoutItem.memberName == 'id') { returnValue = '100px'; }        
    }    
    return returnValue;
  }

  getMinWidth(tableLayoutItem, asNumber = false) {
    let returnValue = '150';
    if (this.applicationInfoService.isMobile()) {
      return returnValue;
    }    
    if (tableLayoutItem.minWidth != '' && !this.commonService.isNullOrUndefined(tableLayoutItem.minWidth)) {
      if (asNumber) {
        const numberValue = tableLayoutItem.minWidth.split('px');
        return Number(numberValue[0]);
      } else {
        return tableLayoutItem.minWidth;
      }      
    } else {
      // Member specific
      if (tableLayoutItem.controlType == 3) { returnValue = '100'; }
      if (tableLayoutItem.controlType == 4) { returnValue = '130'; }
      if (tableLayoutItem.controlType == 6) { returnValue = '130'; }
      if (tableLayoutItem.controlType == 7) { returnValue = '100'; }
      if (tableLayoutItem.controlType == 9) { returnValue = '70'; }
      if (tableLayoutItem.controlType == 11) { returnValue = '35'; }
      if (tableLayoutItem.controlType == 12) { returnValue = '35'; }
      if (tableLayoutItem.controlType == 15) { returnValue = '130'; }
      if (tableLayoutItem.controlType == 17) { returnValue = '60'; }
      if (tableLayoutItem.controlType == 22) { returnValue = '60'; }
      if (tableLayoutItem.memberName == 'id') { returnValue = '100'; }   

      if (tableLayoutItem.entityId == 1 && tableLayoutItem.memberName == 'name1') { returnValue = '400'; }
      if (tableLayoutItem.entityId == 1 && tableLayoutItem.memberName == 'calculatedLeadStateId') { returnValue = '200'; }
      if (tableLayoutItem.entityId == 1 && tableLayoutItem.memberName == 'calculatedEditingState ') { returnValue = '200'; }
      if (tableLayoutItem.entityId == 4 && tableLayoutItem.memberName == 'firstName') { returnValue = '200'; }
      if (tableLayoutItem.entityId == 4 && tableLayoutItem.memberName == 'lastName') { returnValue = '200'; }      
      if (tableLayoutItem.entityId == 4 && tableLayoutItem.memberName == 'email') { returnValue = '350'; }
      if (tableLayoutItem.entityId == 4 && tableLayoutItem.memberName == 'leadStateId') { returnValue = '200'; }
      if (tableLayoutItem.entityId == 4 && tableLayoutItem.memberName == 'calculatedEditingState') { returnValue = '200'; }
    }
    if (asNumber) {
      return Number(returnValue);
    } else {
      return returnValue + 'px';
    }    
  }

  isItemFiltered(multiListFilterItemsItem) {
    let translatedValue = '';
    if (this.filterLookupItem['filterListDisplayValue'] == null) {
      translatedValue = this.commonService.getNameTranslationOrDefaultName(multiListFilterItemsItem);
    } else {
      translatedValue = multiListFilterItemsItem[this.filterLookupItem['filterListDisplayValue']];
    }
    translatedValue = this.axivasTranslateService.getTranslationTextForToken(translatedValue);
    if (this.commonService.checkIfStringContainsString(translatedValue, this.filterString)) {
      return false;
    } else {
      return true;
    }
  }

  enrichResults(item) {
    this.enrichResultValue(item, 'accountid', 'accountId');
    this.enrichResultValue(item, 'contactid', 'contactId');
  }

  enrichResultValue(item, source, target) {
    if (!this.commonService.isNullOrUndefined(item[source])) {
      item[target] = item[source];
    }
  }

  entityTableExport() {
    let body = {
      columnHeaders: [],
      isOnlyActive: true
    };
    let filter = this.getColumnFilter(true);
    let sorting = this.getSorting(true);
    body['projectId'] = this.applicationInfoService.projectID;

    if (filter != null) {
      body['columnFilter'] = filter;
    }
    if (sorting != '') {
      body['orderBy'] = [sorting];
    }
    if (this.tableSettings.globalFilter != '') {
      body['crossColumnFilter'] = this.tableSettings.globalFilter;
    }
    
    const consentData = this.getConsentData();
    this.applicationInfoService.miscSettings['lastMatTableConsentData'] = null;
    if (this.tableSettingsJson['isConsentTable'] == true) {
      body['mediumTypes'] = consentData.mediumTypes;
      body['consentTypes'] = [];
      body['lastXDays'] = consentData.lastXDays;      
    }
    if (this.tableSettingsJson['doubleOptInRequired'] == true ) {
      body['mediumTypes'] = consentData.mediumTypes;
      body['consentTypes'] = consentData.consentTypes;
      body['lastXDays'] = consentData.lastXDays;      
    }

    let lookups = [];
    // this.headerColumns.forEach(tableLayoutItem => {
    this.tableLayout.forEach(tableLayoutItem => {
      // Headers
      if (tableLayoutItem.visible && tableLayoutItem.doNotExport != true) {
        body.columnHeaders.push(this.axivasTranslateService.getTranslationTextForToken(tableLayoutItem.displayText));
        // Lookups
        if (tableLayoutItem.filterable && tableLayoutItem.lookupTableId != null && (tableLayoutItem.controlType != 1 && tableLayoutItem.controlType != 2)) {
          const lookupValues = this.editMultifilter(tableLayoutItem, true);
          const lookupsTranslated = [];
          lookupValues.forEach(value => {
            lookupsTranslated.push({
              id: value.id,
              defaultName: this.axivasTranslateService.getTranslationTextForObject(value, 'nameTranslationToken')
            });
          });
          lookups.push(lookupsTranslated);
        } else if (tableLayoutItem.filterable && tableLayoutItem.alternateLookupTable != null && (tableLayoutItem.controlType != 1 && tableLayoutItem.controlType != 2)) {
          const lookupValues = this.editAlternatefilter(tableLayoutItem, true);
          const lookupsTranslated = [];
          lookupValues.forEach(value => {
            let defaultValue = this.axivasTranslateService.getTranslationTextForObject(value, 'nameTranslationToken');
            if (this.filterLookupItem['filterListDisplayValue'] != null) {
              defaultValue = this.axivasTranslateService.getTranslationTextForToken(value[this.filterLookupItem['filterListDisplayValue']]);
            }
            lookupsTranslated.push({
              id: value.id,
              defaultName: defaultValue
            });
          });
          lookups.push(lookupsTranslated);

        } else {
          lookups.push([]);
        }
      }
    });
    if (lookups.length > 0) {
      body['columnLookupValues'] = lookups;
    }

    this.sharedAPI.entityTableExport(this.tableSettings.definitionId, 'export', body).subscribe(entityTableExportResult => {
      this.commonService.downloadFile(entityTableExportResult, 'application/xlsx',
        'Export '.concat(this.applicationInfoService.projectName, ' ',
        this.commonService.getDateTimeString(new Date(Date.now())), '.xlsx')
      );
    })
  }

  toggleValue(value) {
    if (value == 'showMicroCampaignCreation') {
      this.microcampaignService.getMicroCampaignStarter();
      this.tableSettingsJson['additionalToolbar'] = false;
    }

    if (this.commonService.isNullOrUndefined(this.tableSettingsJson[value])) {
      this.tableSettingsJson[value] = true;
    } else {
      this.tableSettingsJson[value] = !this.tableSettingsJson[value];
    }

    // AI Contact Toggle
    if (value == 'aicontactsActive') {
      if (this.tableSettingsJson['aicontactsActive'] == true) {
        this.tableSettingsJson['aicontactsHighPotentialActive'] = false;
      }
    }    
    if (value == 'aicontactsHighPotentialActive') {
      if (this.tableSettingsJson['aicontactsHighPotentialActive'] == true) {
        this.tableSettingsJson['aicontactsActive'] = false;
      }
    }  
    // InEverleadtoggle
    if (value == 'alreadyInEverlead') {
      if (this.tableSettingsJson['alreadyInEverlead'] == true) {
        this.tableSettingsJson['notInEverlead'] = false;
      }
    }    
    if (value == 'notInEverlead') {
      if (this.tableSettingsJson['notInEverlead'] == true) {
        this.tableSettingsJson['alreadyInEverlead'] = false;
      }
    }    
    this.getData();
  }

  showVIP() {
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'vipalert']);
  }

  checkIfScrollbarIsVisible(section) {
    const element = document.getElementById(this.getControlId(section));
    if (element) {
      return element.scrollHeight > element.clientHeight;
    } else {
      return false;
    }
  }

  getMaxHeaderWidth() {
    let returnValue = '100%';
    if (this.checkIfScrollbarIsVisible('entityTableDataContainer')) {
      returnValue = 'calc(100% - 7px)';
    }
    return returnValue;
  }

  getControlId(section) {
    return 'entityTable'.concat(section, this.controlDefinition.id);
  }

  clickFilterItem(multiListFilterItemsItem) {
    if (this.filterLookupItem.filter[multiListFilterItemsItem.id] == true) {
      this.filterLookupItem.filter[multiListFilterItemsItem.id] = false;
    } else {
      this.filterLookupItem.filter[multiListFilterItemsItem.id] = true;
    }
    this.checkEqualItems(multiListFilterItemsItem);
  }

  checkEqualItems(multiListFilterItemsItem) {
    const valueToSet = this.filterLookupItem.filter[multiListFilterItemsItem.id];
    const nameOfValue = this.getDisplayName(multiListFilterItemsItem);
    this. multiListFilterItems.forEach(multiListFilterItem => {
      if (this.getDisplayName(multiListFilterItem).toLowerCase() == nameOfValue.toLocaleLowerCase()) {
        this.filterLookupItem.filter[multiListFilterItem.id] = valueToSet;
      }
    });
  }

  getDisplayName(multiListFilterItemsItem) {
    if (this.filterLookupItem['filterListDisplayValue'] == null) {
      return this.axivasTranslateService.getTranslationTextForToken(this.commonService.getNameTranslationOrDefaultName(multiListFilterItemsItem));
    } else {
      return this.axivasTranslateService.getTranslationTextForToken(multiListFilterItemsItem[this.filterLookupItem['filterListDisplayValue']]);
    }
  }

  getDataRowBackgroundColor() {
    if (this.tableSettingsJson['dataRowBackgroundColor'] != '' && !this.commonService.isNullOrUndefined(this.tableSettingsJson['dataRowBackgroundColor'])) {
      return this.tableSettingsJson['dataRowBackgroundColor'];
    }
  }

  getTableMenuBackgroundColor() {
    if (this.tableSettingsJson['tableMenuBackgroundColor'] != '' && !this.commonService.isNullOrUndefined(this.tableSettingsJson['tableMenuBackgroundColor'])) {
      return this.tableSettingsJson['tableMenuBackgroundColor'];
    }
  }

  createNewOptin() {
    this.applicationInfoService.miscSettings['optinoptouteditorvalue'] = null;
    this.eventService.showJjPopup('OptinOptOutEditor.Label.CreateOptInOptOut', 'optinoptouteditor', '500px;700px')
  }

  getEntityTableId() {
    if (this.generatedControlId == null) {
      this.generatedControlId = 'entityTable' + this.controlDefinition.id + this.commonService.createGuid();
      return this.generatedControlId;
    } else {
      return this.generatedControlId;
    }
  }

  getEntityTableWidth() {
    const tableId = this.getEntityTableId();
    const element = document.getElementById(tableId);
    if (element) {
      return element.clientWidth;
    } else {
      return 10000;
    }
  }

  getEntityCondition() {
    if (this.dataTableItem.mainEntityId == this.entitytableService.contentLibraryFileEntity) {  
      // Defaults
      let entityId = 1;

      if (this.applicationInfoService?.currentContact?.id != null) { entityId = 4; }

      if (this.uploadedFilesEntityId != null) {
        entityId = this.uploadedFilesEntityId;
      }
      const array = [];
      switch(entityId) {
        case 1:
          array.push({ entityId: 1, refId: this.applicationInfoService.currentAccount.id });
          break;
        case 2:
          array.push({ entityId: 2, refId: this.applicationInfoService.currentTask.id });
          break;
        case 4:
          array.push({ entityId: 4, refId: this.applicationInfoService.currentContact.id });
          break;
      }
             
      let arrayJson = JSON.stringify(array);
      Object.keys(arrayJson).forEach(() =>  {      
        arrayJson = arrayJson.replace('"'.concat('entityId', '"'), 'entityId');
        arrayJson = arrayJson.replace('"'.concat('refId', '"'), 'refId');
      });
      return arrayJson;
    } else {
      return '[]';
    }
  }

  getFilterBoxId() {
    return 'entityFilterBox'.concat(this.controlDefinition.id);
  }

  appendFilterToBody() {
    const filter = document.getElementById(this.getFilterBoxId());    
    if (filter) {
      document.body.append(filter);
    }    
  }

  smartStartMicroCampaign(campaign) {
    const body = new Object();    
    const messageText = this.axivasTranslateService.getTranslationTextForToken('MicroCampaign.BatchStart.Message')
      .replace('<0>', this.controlData.totalCount.toString());
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken(messageText),
      header: this.axivasTranslateService.getTranslationTextForToken('MicroCampaign.BatchStart.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const consentData = this.getConsentData();
        let campaignId = campaign.nextStepCampaignId;
        if (this.applicationInfoService.applicationSettings['isDemoproject'] == 'true') {
          campaignId = this.applicationInfoService.campaingnModeId;
        }
        this.loaderService.display(true);
        body['projectId'] = this.applicationInfoService.projectID;
        body['orderBy'] = null;
        body['columnFilter'] = this.getColumnFilter(true);
        body['isOnlyActive'] = true;
        body['tableLayoutId'] = this.tableSettings.definitionId;
        body['crossColumnFilter'] = this.tableSettings.globalFilter;
        body['campaignId'] = campaignId;
        body['stepId'] = campaign.stepId;
        body['resultId'] = campaign.id;
        body['filterId'] = this.filterId;
        body['consentTypes'] = consentData.consentTypes;
        body['mediumTypes'] = consentData.mediumTypes;
        body['lastXDays'] = consentData.lastXDays;
        this.sharedAPI.smartStartMicroCampaign(body)
        .subscribe(
          (next) => { 
            this.messagingService.showDefaultInfo(
              'MicroCampaign.BatchStart.Header', 
              this.axivasTranslateService.getTranslationTextForToken('MicroCampaign.BatchStart.ResultMessage').replace('<0>', next.result.toString())
            );
            this.loaderService.display(false); 
          }, 
          (error) => { 
            console.error(error); this.loaderService.display(false); 
          }
        );
      },
      reject: () => {
        return;
      }
    });    
  }

  getMicroCampaignSubHeader() {
    return this.axivasTranslateService.getTranslationTextForToken('MicroCampaign.Label.EntityTableSubHeader')
      .replace('<0>', this.controlData.totalCount.toString());
  }

  batchStopMicroCampaign() {
    const body = new Object();    
    const messageText = this.axivasTranslateService.getTranslationTextForToken('MicroCampaign.BatchStop.Message')
      .replace('<0>', this.controlData.totalCount.toString());
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken(messageText),
      header: this.axivasTranslateService.getTranslationTextForToken('MicroCampaign.BatchStop.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const consentData = this.getConsentData();

        this.loaderService.display(true);
        body['projectId'] = this.applicationInfoService.projectID;
        body['orderBy'] = null;
        body['columnFilter'] = this.getColumnFilter(true);
        body['isOnlyActive'] = true;
        body['tableLayoutId'] = this.tableSettings.definitionId;
        body['crossColumnFilter'] = this.tableSettings.globalFilter;
        body['microCampaignResultId'] = this.useMicroCampaignContactsCampaign;
        body['filterId'] = this.filterId;
        body['consentTypes'] = consentData.consentTypes;
        body['mediumTypes'] = consentData.mediumTypes;
        body['lastXDays'] = consentData.lastXDays;
        this.sharedAPI.smartStopMicroCampaign(body)
        .subscribe(
          (next) => { 
            this.loaderService.display(false); 
            this.messagingService.showDefaultInfo(
              'MicroCampaign.BatchStop.Header', 
              this.axivasTranslateService.getTranslationTextForToken('MicroCampaign.BatchStop.ResultMessage').replace('<0>', next.result.toString())
            );
            this.getData();
          }, 
          (error) => { 
            console.error(error);         
            this.loaderService.display(false); 
          }
        );
      },
      reject: () => {
        return;
      }
    });    
  }

  changeMicroCampaign() {
    this.eventService.customEvent.emit({ id: 'entityTableMicroCampaignChanged', value: this.useMicroCampaignContactsCampaign });
    this.getData();
  }

  checkMicroCampaignQueue() {
    this.sharedAPI.checkMicroCampaignQueue().subscribe();
  }

  getCellId(rowIndex, itemIndex) {
    return 'cellItem' + rowIndex + '_' + itemIndex;
  }

  getHeaderItemForFilterId(i) {
    return 'headerForFilter'.concat(i.toString());
  }
  
  selectedHeaderItemForFilter(i) {    
    this.selectedHeaderItemForFiltering = i;
    if (i == null) {
      return;
    }

    setTimeout(() => {
      const element = document.getElementById(this.getHeaderItemForFilterId(i));
      if (element) {
        element.focus();
      }        
    }, 500);
  }

  getMinHeaderWidth() { 
    let returnValue = 0;
    this.headerColumns.forEach(columnItem => {
      returnValue = returnValue + Number(this.getMinWidth(columnItem, true));
    });
    return 25 + returnValue + 'px';
  }

  getMultifilterTop() {
      const element = document.getElementById(this.getControlId('entityTableDataContainerHeader'));
      if (element) {
        var rect= element.getBoundingClientRect();
        return rect.bottom + 'px';
      }
  }

  checkBottom(event) {
    const shownItems = this.tableSettings.currentPage * this.tableSettings.pageSize;
    const totalItems = this.controlData.totalCount;
    if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - 10)) {
      if ((totalItems > shownItems) && this.loadingData == false) {
        this.lazyloading = true;
        this.tableSettings.currentPage ++;
        this.getData(true);
      }
    }
  }

  loadMoreChartData(){
    this.lazyloading = true;
    this.tableSettings.currentPage ++;
    this.getData(true);
  }

  getSliderColor() {
    if (this.commonService.isNullOrUndefined(
      this.applicationInfoService.miscSettings['entityTableCustomFilter'.concat(this.dataTableItem.logicalControlId).toString()]
    )) {
      return 'secondary';
    } else {
      return 'green';
    }
  }
}
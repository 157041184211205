import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { userInfo } from 'os';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss']
})
export class UsermanagementComponent implements OnInit {
  users = null;
  filterString = '';
  selectedUser = null;
  projects = [];
  userProjectInfo = null;
  isFullUserInfo = false;
  fullInfoModeProjectId = null;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private applicationInfoService: ApplicationInfoService,
    public uiService: UiService,
    private commonService: CommonService,
    private confirmationService: ConfirmationService,
    private axivasTranslateService: AxivasTranslateService,
    private messagingService: MessagingService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getUsers();
    this.getProjects();
  }


  getUsers() {
    let externalDataSourceId = 99;
    let customerIdCheck = false;
    if (this.applicationInfoService.user.isExternalUser) {
      externalDataSourceId = 139;
    }

    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.user.customerId)) {
      externalDataSourceId = 961;
      customerIdCheck = true;
    }
    this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [this.applicationInfoService.user.customerId])
    .then(result => {      
      if (
        this.applicationInfoService.user.isExternalUser == true ||
        !this.commonService.isNullOrUndefined(this.applicationInfoService.user.customerId)
      ) {
        result = this.commonService.getUserListForExternalUser(result, customerIdCheck);        
      } else {
        result = result.filter(resultItem => resultItem.isActive == true);
      }      
      result.sort((a, b) => a.userName < b.userName ? -1 : 1);
      this.users = result;
    });
  }

  isFilterActive(user) {
    if (this.commonService.checkIfStringContainsString(user.userName, this.filterString)) {
      return false;
    } else {
      return true;
    }
  }

  getUserControlId(user) {
    return 'userControl'+ user.id;
  }

  selectUser(user) {
    this.selectedUser = user;
    this.applicationInfoService.miscSettings['userManagementSeletedUser'] = user;
    this.eventService.showJjPopup('Supervisor.Label.UserManagement', 'usermanagementuserdetails', '90');
    return;
    this.getUserProjectInfo(user.id);
  }

  activateUser() {
    this.confirmationService.confirm({
      message: this.axivasTranslateService.getTranslationTextForToken('ActivateUser.Message.Body'),
      header: this.axivasTranslateService.getTranslationTextForToken('ActivateUser.Message.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.externaldatasourceService.executeExternalDataSource(499, [this.selectedUser.email])
        .then(() => {
          this.messagingService.showDefaultSuccess('ActivateUser.Message.Header', 'ActivateUser.Message.Sended')
        })
        .catch(error => {
          this.messagingService.showDefaultError('ActivateUser.Message.Body', error);
        });
      }
    });
  }

  saveUser() {
    this.externaldatasourceService.executeExternalDataSource(526, [this.commonService.getModifyArrayBody(this.selectedUser, [])]);
  }

  newUser() {
    this.eventService.showJjPopup('ProjectUsers.Label.CreateExternalUser', 'externalusers', 60, true);
  }

  getProjects() {
    this.externaldatasourceService.executeExternalDataSource(2, [])
    .then(getProjectsResult => {
      this.projects = getProjectsResult;
    });
  }

  deleteDataRightGroupOfUser(group, array) {
    this.externaldatasourceService.executeExternalDataSource(577, [group.group.id])
    .then(() => {
      this.commonService.removeItemFromArray(array, group);
    });
  }

  openDataRightsPanel() {
    this.eventService.showJjPopup('UserManagement.Label.DataRightGroups', 'datarights', 80);
  }

  getUserProjectInfo(userId) {
    let externalDataSourceId = 589;
    if (this.isFullUserInfo) {
      externalDataSourceId = 591;
    }
    this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [userId])
    .then(getUserProjectInfoResult => {
      this.userProjectInfo = getUserProjectInfoResult;
    })
  }

  deleteUserGroupAssignment(group, array) {
    this.externaldatasourceService.executeExternalDataSource(102, [group.id])
    .then(() => {
      this.commonService.removeItemFromArray(array, group);
    });
  }

  deleteUserRoleAssignment(group, array) {
    this.externaldatasourceService.executeExternalDataSource(528, [group.id])
    .then(() => {
      this.commonService.removeItemFromArray(array, group);
    });
  }

  deleteFollowUpUserAssignment(group, array) {
    this.externaldatasourceService.executeExternalDataSource(590, [group.followupUserGroup.id])
    .then(() => {
      this.commonService.removeItemFromArray(array, group);
    });
  }

  changeUserInfoMode() {
    this.isFullUserInfo = !this.isFullUserInfo;
    this.getUserProjectInfo(this.selectedUser.id);
  }

  openPopup(popup) {
    this.eventService.showJjPopup('', popup, 80);
  }

  checkIfItemShouldBeHidden(project) {
    let returnValue = false;
    if (this.fullInfoModeProjectId != null) {
      if (this.fullInfoModeProjectId != project.id) {
        returnValue = true;
      }
    }
    return returnValue;
  }
}

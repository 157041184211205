<div class="contentInfo" *ngIf="getContentInfo()" [ngClass]="{ hideInDom: applicationInfoService.showBanner == true }">
    <div class="contentInfo__inner">
        <div class="contentInfo__icon everleadCoralBackground fingerCursor" (click)="changeHelpStatus(false)"><i class="fas fa-lg fa-question"></i></div>
        <div class="contentInfo__close  fingerCursor" (click)="changeHelpStatus(false)">
            {{ 'ContentInfo.Label.Close' | texttransform }}
           <app-jj-icons type="x" [width]="20" [height]="20"></app-jj-icons>
        </div>
       
        <div class="contentInfo__bodywrapper">
            <div class="contentInfo__body" [innerHTML]="axivasTranslateService.getTranslationTextForId(contentItem[tipIndex].nameTranslationTokenId) | texttransform"></div>
        </div>
        

        <div class="contentInfo__footer">
            <div class="contentInfo__footer__left"></div>
            <div class="contentInfo__footer__right">                
                <div class="contentInfo__robo ">
                    <svg-icon src="assets/images/svg/robo/robo_showsdown.svg" [svgStyle]="{ 'width.px': 60, 'height.px': 60 , 'fill': 'white'}" class="contentInfo__robo__down"></svg-icon> 
                    <svg-icon src="assets/images/svg/robo/robo_showleft.svg" [svgStyle]="{ 'width.px': 60, 'height.px': 60, 'fill':'white' }" class="contentInfo__robo__left"></svg-icon> 
                </div>
                <app-checkbox [(ngModel)]="applicationInfoService.userSettings['contentInfoDontShowInfo']" label="ContentInfo.Label.DontShowAgain"></app-checkbox>
                <!-- <label>{{ 'ContentInfo.Label.DontShowAgain' | texttransform }}</label>
                <input [(ngModel)]="applicationInfoService.userSettings['contentInfoDontShowInfo']" type="checkbox"> -->
            </div>
        </div>
    </div>    
</div>

<div class="everleadTextField" [ngClass]="{ maxHeight: maxHeight }" >
    <label class="everleadTextField__label" *ngIf="label" [class.disabled]="disabled" [class.error]="error">
        {{ label | texttransform }}
        <app-wizard-infobutton *ngIf="info" [displayText]="info" [width]="17" [height]="17"></app-wizard-infobutton>
    </label>
    <div class="everleadTextField__value" [class.disabled]="disabled" [class.error]="error" [ngClass]="{ everleadTextFieldMaxHeight: maxHeight }">
        <textarea
            *ngIf="!fire"
            [id]="id" 
            [placeholder]="placeholder" 
            [(ngModel)]="value"
            (change)="handleInput($event.target.value)" 
            [style.height]="getHeight()"
            [disabled]="disabled"
            (keyup)="handleKeyUp(value)">
        </textarea>

        <textarea
            *ngIf="fire"
            [id]="id" 
            [placeholder]="placeholder" 
            [(ngModel)]="value"
            (ngModelChange)="handleInput(value)" 
            [style.height]="getHeight()"
            [disabled]="disabled"
            (keyup)="handleKeyUp(value)">
        </textarea>

    </div>
</div>
<div [ngStyle]="setControlStyle('control')" style="position: relative;" [id]="getMyId()" class="registerMinHeight registerLayout">
  
<!--   <span class="accountFooter__buttonUp" *ngIf="!applicationInfoService.isCardsVisible" (click)="applicationInfoService.resetHeaderCardsPosition()">
    <app-jj-icons type="caret-down" [width]="30" [height]="30"></app-jj-icons>
  </span> -->


  <div class="jjtabWrapper">
    <div class="scroll-button scroll-button__left jjTab" *ngIf="list.isOverflow" [class.disable]="!list.canScrollStart" 
      (click)="list.scroll(-1)" id="scroll-left">
      <app-jj-icons type="caret-left" [width]="24" [height]="24" color="primaryLight"></app-jj-icons>
    </div>
 
    <div id="jjTab" class="jjtab desktop-visible" appScrollable #list="appScrollable" [scrollUnit]="150">    
      <div [ngClass]="{ 
          jjActiveTab: child.logicalControlId == selectedTabIndex,
          jjinactivetab: child.logicalControlId != selectedTabIndex,
          jjfirstInactiveTab:child.logicalControlId != selectedTabIndex && currentIndex === 0,
          jjlastInactiveTab: child.logicalControlId != selectedTabIndex && currentIndex === visibleTabs.length - 1,
          jjFirstActiveButton: child.logicalControlId == selectedTabIndex && currentIndex === 0,
          jjLastActiveButton:  child.logicalControlId == selectedTabIndex && currentIndex === visibleTabs.length - 1,
          noBorderRadiusForActiveOrNonActive: list.isOverflow
        }" *ngFor="let child of visibleTabs; let currentIndex = index" class="tablinks "
        (click)="setCurrentChild(child.logicalControlId)"
        >
        {{ commonService.getTranslationValueFromArray(child, 'displayText') | texttransform }}
      </div>    
    </div>
    <div class="scroll-button scroll-button__right jjTab" *ngIf="list.isOverflow"  [class.disable]="!list.canScrollEnd" (click)="list.scroll(1)">
      <app-jj-icons type="caret-right" [width]="24" [height]="24" color="primaryLight"></app-jj-icons>
    </div>  
  </div>



  <app-select-input uiType="custom-register" [label]="'General.Label.SelectTab' | texttransform" [(ngModel)]="selectedTabIndex" [options]="visibleTabs"
    (valueChange)="this.applicationInfoService.resetHeaderCardsPosition()" 
    display="displayText" bind="logicalControlId" class="tabSelectWrapper"></app-select-input>

  <!-- <select class="tabSelectWrapper" [(ngModel)]="selectedTabIndex"> 
    <option [value]="child.logicalControlId" *ngFor="let child of visibleTabs">{{ commonService.getTranslationValueFromArray(child, 'displayText') | texttransform }}</option>
  </select> -->

  <span id="mainContainerSpan"></span>
  <div class="mainContainer">
    <div class="mainContainer__tab">
      <div *ngFor="let child of visibleTabs; let currentIndex = index" [id]="child.logicalControlId">
        <div *ngIf="child.logicalControlId == selectedTabIndex">
          <app-custom-container controluiid={{child.logicalControlId}}
          [ngStyle]="getSubContainerWidth(child)"></app-custom-container>

          <span class="accountFooter" *ngIf="child.value != null && child.value != ''" (scroll)="applicationInfoService.onWindowScroll($event, 'custom-register-span')">
            
            <div [innerHTML]="innerHTMLs[child.id]" [ngClass]="{
              accountFooter__item: applicationInfoService.isHeaderVisible || applicationInfoService.isCardsVisible,
              accountFooter__hidden: !applicationInfoService.isHeaderVisible || !applicationInfoService.isCardsVisible}"
              (scroll)="applicationInfoService.onWindowScroll($event, 'custom-register-account-footer')"></div>  
          </span>
        </div>
      </div>
    </div>
  </div>
  
  <span [id]="getRegisterId()" class="bottomRegister"></span>
</div>

<span id="designSpan"></span>
<div class="ajTracker__content" [style.height]="getContentHeight()">    
    <label class="ajTrackerSectionHeader everleadCoral">{{ 'AJTracker.Label.YourDMCAccountId' | texttransform }}</label>
    <div *ngIf="applicationInfoService.currentProject.dmcAccountId">
        <div class="ajTrackerHTML">{{ applicationInfoService.currentProject.dmcAccountId }}</div>
        <div>{{ 'AJTracker.Label.YourDMCAccountIdDescription' | texttransform }}</div>
        <div>{{ 'AJTracker.Label.ScriptAlreadyUpdated' | texttransform }}</div>
        <br>
        <div (click)="copyScriptToClipboard()" *ngIf="applicationInfoService.currentProject.dmcAccountId" class="ajTracker__content__dmccontainer__clipboard">
            <i class="fas fa-copy"></i>{{ 'AJTracker.Label.CopyScriptToClipboard' | texttransform }}
        </div>
        <br>
    </div>

    <div *ngIf="!applicationInfoService.currentProject.dmcAccountId">
        {{ 'AJTracker.Label.YourDMCAccountIdNotYetAssigned' | texttransform }}
    </div>
    <div class="jjTab">
        <!-- <button class="tablinks " [ngClass]="{ jjActivetab: section == 1 }" (click)="section = 1">{{ 'AJTracker.Label.Common' | texttransform }}</button> -->
        <button class="tablinks " [ngClass]="{ jjActivetab: section == 5 }" (click)="section = 5">{{
            'AJTracker.Label.Common' | texttransform }}</button>
        <button class="tablinks " [ngClass]="{ jjActivetab: section == 1 }" (click)="section = 1">{{
            'AJTracker.Label.Implementation' | texttransform }}</button>
        <button class="tablinks " [ngClass]="{ jjActivetab: section == 2 }" (click)="section = 2">{{
            'AJTracker.Label.Usage' | texttransform }}</button>    
        <button class="tablinks " [ngClass]="{ jjActivetab: section == 3 }" (click)="section = 3">{{
            'AJTracker.Label.API' | texttransform }}</button>
        <button class="tablinks " [ngClass]="{ jjActivetab: section == 4 }" (click)="section = 4">{{
            'AJTracker.Label.Downloads' | texttransform }}</button>
    </div>
    
    <div class="ajTracker__tabContent">
        <div *ngIf="section == 5">
            <label [innerHTML]="innerHTMLAJTrackerCommon"></label>
        </div>        
        <div *ngIf="section == 1">            
            <label [innerHTML]="innerHTMLAJTrackerImplementation"></label>
            <br>
            <div (click)="copyScriptToClipboard()" *ngIf="applicationInfoService.currentProject.dmcAccountId"
                class="ajTracker__content__dmccontainer__clipboard">
                <i class="fas fa-copy"></i>{{ 'AJTracker.Label.CopyScriptToClipboard' | texttransform }}
            </div>
        </div>
        <div *ngIf="section == 2">
            <div class="ajTracker__footNote"><i class="fas fa-info-circle"></i>{{ 'AJTracker.Label.FootNote' | texttransform }}</div>
            <label [innerHTML]="innerHTMLAJTrackerUsage"></label>
        </div>

        <div *ngIf="section == 3">
            <div class="ajTracker__footNote"><i class="fas fa-info-circle"></i>{{ 'AJTracker.Label.FootNote' | texttransform }}</div>
            <div [innerHTML]="innerHTMLAJTrackerAPI"></div>
        </div>

        <div *ngIf="section == 4">
            <div>{{ 'AJTracker.Label.DownloadsDescription' | texttransform }}</div>
            <br>
            <a class="ajTracker__download" download href="../../../../../../assets/documents/AJ Tracker_de_v2.pdf"><i
                    class="fas fa-file-pdf"></i>
                {{ 'AJTracker.Label.DocumentationGerman' | texttransform }}
            </a>
            <a class="ajTracker__download" download href="../../../../../../assets/documents/AJ Tracker_en_v2.pdf"><i
                    class="fas fa-file-pdf"></i>
                {{ 'AJTracker.Label.DocumentationEnglish' | texttransform }}
            </a>
        </div>
    </div>
</div>
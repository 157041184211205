<!-- <button (click)="openDesigner()">Designer</button> -->

<div class="questionaires" *ngIf="selectAvailable && questionaires?.length > 0">
    <div class="questionaires__select">
        <app-select-input [label]="'Questionaire.Label.SelectQuestionaire' | texttransform" 
            [(ngModel)]="selectedQuestionaire" [returnObject]="true" [complex]="true"
            [options]="questionaires" display="defaultName" (valueChange)="changeQuestionaire(selectedQuestionaire)"></app-select-input>

        <!-- <select [(ngModel)]="selectedQuestionaire" (change)="changeQuestionaire(selectedQuestionaire)" class="maxWidth">            
            <option [ngValue]="questionaire" *ngFor="let questionaire of questionaires">{{ questionaire.defaultName }}</option>
        </select> -->
    </div>    
</div>

<div class="questionaireControl" 
    *ngIf=" questionsRequested &&
           (
                (selectedQuestionaire.entityId == 1 && applicationInfoService.currentAccount != null) ||
                (selectedQuestionaire.entityId == 2 && applicationInfoService.currentTask != null) ||
                (selectedQuestionaire.entityId == 4 && applicationInfoService.currentContact != null) 
           )
">
    <div class="everleadShadowBox everleadShadowBox2" *ngIf="!selectAvailable">{{ selectedQuestionaire?.defaultName }}</div>
    <div *ngFor="let question of questions" [ngClass]="{ hideInDom: question.isDeleted }">
        <app-questionaire-userquestion [questionnaire]="selectedQuestionaire" [question]="question"></app-questionaire-userquestion>
    </div>
</div>

<div *ngIf="selectedQuestionaire">
    <div *ngIf="selectedQuestionaire.entityId == 4 && applicationInfoService.currentContact == null">
        {{ 'Questionaire.Label.PleaseSelectContact' | texttransform }}
    </div>
</div>
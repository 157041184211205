
<div class="customTextControlWrapper" [ngClass]="{
  validationFailed: apiValidationCheckResult?.isValidEmail == false,
  validationSuccess: apiValidationCheckResult?.isValidEmail == true
}">
  
  <label class="customTextControlWrapper__label">
      {{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}
  </label>    

  <div class="customTextControlWrapper__dataRow customTextControlWrapper__valueWrapper" [tippy]="controlValue">    
    <input       
      class="customTextControlWrapper__textbox customTextControlWrapper__oneIcon"
      type="text"
      [tippy]="controlValue"
      [disabled]="isDisabled"
      (change)="callLocalMethod('onchange')"
      [(ngModel)]="controlValue"
      [ngClass]="{
        validationFailed: apiValidationCheckResult?.isValidEmail == false,
        validationSuccess: apiValidationCheckResult?.isValidEmail == true
      }"
    >      
      <div *ngIf="apiValidationCheckResult != null" class="everleadCustomControlMiniButton apiValidationCheckResult">
        <app-jj-icons type="info-fill" [width]="20" [height]="20" [tippy]="generateTippy()" [tippyOptions]="{ allowHTML: true }"></app-jj-icons>
      </div>
      <div class="everleadCustomControlMiniButton everleadCustomEMailButton fingerCursor validateEMailSvg" *ngIf="!hideValidationCheckButton"
        (click)="validateEmailViaAPI()" [tippy]="'CustomEMail.Label.VerifyEmail' | texttransform">
        <app-jj-icons type="envelope" [width]="20" [height]="20"></app-jj-icons>
        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="" viewBox="0 0 256 256"><path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48Zm-96,85.15L52.57,64H203.43ZM98.71,128,40,181.81V74.19Zm11.84,10.85,12,11.05a8,8,0,0,0,10.82,0l12-11.05,58,53.15H52.57ZM157.29,128,216,74.18V181.82Z"></path></svg> -->
     <!--    <svg-icon src="assets/images/svg/validate_email.svg" [svgStyle]="{ 'width.px': 21, 'height.px': 20 }"></svg-icon>            -->
      </div>
      <div class="everleadCustomControlMiniButton everleadCustomEMailButton" *ngIf="!hideChangeEMailButton">
        <app-jj-icons type="editor" [width]="18" [height]="18" (click)="editEMail()"></app-jj-icons>
        <!-- <i class="fas fa-edit fingerCursor" (click)="editEMail()"></i> -->
      </div>
      <div class="everleadCustomControlMiniButton everleadCustomEMailButton" *ngIf="applicationInfoService.applicationSettings['showCustomEMailButton'] == 'true'">
        <app-jj-icons type="envelope" (click)="createEMail()" [ngClass]="{ 
          disabled: !commonService?.validateEMail(controlValue), 
          fingerCursor: commonService?.validateEMail(controlValue) }"></app-jj-icons>
       <!--  <i class="fas fa-envelope" (click)="createEMail()" [ngClass]="{ 
          disabled: !commonService?.validateEMail(controlValue), 
          fingerCursor: commonService?.validateEMail(controlValue) }"
        ></i>  -->   
      </div>    
  </div>  
</div>

<!-- Popup -->
<div class="everleadLocalPopup__background" *ngIf="dataPopupOpen" [id]="getPopupId('background')"></div>
<div class="everleadLocalPopup everleadLocalPopupLocal" *ngIf="dataPopupOpen" [id]="getPopupId()">
  <div class="everleadLocalPopup__header">
    <label>{{ 'CustomEMail.Label.EditEmail' | texttransform }}</label>
    <app-jj-icons type="x"  (click)="dataPopupOpen = false" [width]="20" [height]="20"></app-jj-icons>
    <!-- <i class="fas fa-close fa-lg fingerCursor" (click)="dataPopupOpen = false"></i> -->
  </div>
  <div class="everleadLocalPopupLocal__inner">
    <span *ngIf="loadingData">
      <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </span>

    <div *ngIf="!loadingData">
      <div class="everleadLocalPopupLocal__newMail everleadShadowBox" *ngIf="(checkCount == 1 && !hasConversion()) || checkCount > 1">
        <label class="ellipsisText">{{ 'CustomEMail.Label.NewEMailAdress' | texttransform }}</label>        
        <div class="flexCenterIcons">
          <input [(ngModel)]="tempEmail" class="maxWidth" (keydown)="keyDown()" [tippy]="tempEmail">
        </div>        
      </div>

      <div *ngIf="hasConversion() && emailChanged==false" class="whiteSpaceInitial">
        <label [innerHTML]="'CustomEMail.Label.HasConversion' | texttransform"></label>
      </div>
  
      <div *ngIf="checkIfContactsAreConnected() && emailCheck.length > 0 && checkCount > 1 && emailChanged==false">
        <label class="customEMailContactsHeader">{{ 'CustomEMail.Label.HasContacts' | texttransform }}</label>
        <div *ngFor="let contact of emailCheck" class="everleadShadowBox">
          <div>
            <app-jj-icons type="calendar-dots" [width]="20" [height]="20" [pointer]="false"></app-jj-icons>
<!--             <svg-icon src="assets/images/svg/contact-calendar.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon>   --> 
            {{ contact.firstName }} {{ contact.lastName }} ({{ contact.poolName }})            
          </div>
        </div>
      </div>

      <div class="everleadLocalPopupLocal__button" *ngIf="emailChanged && validateEMail()">
        <button class="everleadCustomButton" (click)="getEMailInformation()">
          {{ 'CustomEMail.Label.CheckEMail' | texttransform }}
        </button>
      </div>

      <div *ngIf="!hasConversion()">
        <div *ngIf="!validateEMail()" class="everleadCoral">
          <label>{{ 'CustomEMail.Label.ValidationFailed' | texttransform }}</label>
        </div>
        <app-main-button 
        type="borderSmall"
        label="CustomEMail.Label.UpdateEMail"
        (onClick)="updateEMail()"
        ></app-main-button>

        <div *ngIf="!checkIfContactsAreConnected() && canUpdateEMail && validateEMail() && emailChanged == false" 
            class="everleadLocalPopupLocal__button">
          <label>{{ 'CustomEMail.Label.CanUpdateAdress' | texttransform }}</label>        
         <!--  <button class="everleadCustomButton" (click)="updateEMail()">
            {{ 'CustomEMail.Label.UpdateEMail' | texttransform }}
          </button> -->
          <app-main-button svg="floppy-disk" type="borderSmall" label="CustomEMail.Label.UpdateEMail" (onClick)="updateEMail()"></app-main-button>
        </div>
      </div>
    </div>
  </div>
</div>
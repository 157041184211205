<button [id]="id" *ngIf="!type" class="everleadMainButton" [disabled]="disabled" (click)="buttonClick()"
    [class.disabled]="disabled" [ngClass]="{ buttonMaxWidth: maxWidth }">

    <app-jj-icons *ngIf="!label && svg" class='everleadMainButton__icon' [type]="svg" [width]="20" [height]="20"
    [color]="'primaryLight'"></app-jj-icons>

    <app-jj-icons *ngIf="position === 'left' && svg && label" class='everleadMainButton__iconLeft' [type]="svg" [width]="20" [height]="20"
        [color]="'primaryLight'"></app-jj-icons>

    <span class="label" *ngIf="label">{{label | texttransform }}</span>

    <app-jj-icons *ngIf="position === 'right' && svg && label" class="everleadMainButton__iconRight" [type]="svg" [width]="20" [height]="20"
        [color]="'primaryLight'"></app-jj-icons>
        <div class="label" *ngIf="!label">
            <ng-content></ng-content>
       </div>
</button>


<button [id]="id" *ngIf="type === 'small'" class="everleadMainButtonSmall" [disabled]="disabled" (click)="buttonClick()"
    [class.disabled]="disabled" [ngClass]="{ buttonMaxWidth: maxWidth }">

    <app-jj-icons *ngIf="svg && !label" class="everleadMainButtonSmall__icon" [type]="svg" [width]="16" [height]="16"
        [color]="'primaryLight'"></app-jj-icons>

    <app-jj-icons *ngIf="position === 'left' && svg && label " class="everleadMainButtonSmall__iconLeft" [type]="svg" [width]="16" [height]="16"
        [color]="'primaryLight'"></app-jj-icons>
    <span class="label" *ngIf="label">{{label | texttransform }}</span>

    <app-jj-icons *ngIf="position === 'right' && svg && label" class='everleadMainButtonSmall__iconRight' [type]="svg" [width]="16" [height]="16" [color]="'primaryLight'"></app-jj-icons>
        <div class="label" *ngIf="!label">
            <ng-content></ng-content>
       </div>
</button>


<button [id]="id" *ngIf="type === 'border'" class="everleadMainButtonBorder" [disabled]="disabled"
    (click)="buttonClick()" [class.disabled]="disabled" [ngClass]="{ buttonMaxWidth: maxWidth }">

    <app-jj-icons *ngIf="svg && !label" class="everleadMainButtonBorder__icon" [type]="svg" [width]="20" [height]="20"
    [color]="disabled ? 'primaryLight': 'secondary'"></app-jj-icons>


    <app-jj-icons *ngIf="position === 'left' && svg && label" class='everleadMainButtonBorder__iconLeft' [type]="svg" [width]="20" [height]="20"
        [color]="disabled ? 'primaryLight': 'secondary'"></app-jj-icons>


    <span class="label" *ngIf="label" [style.font-size]="fontSize">{{label | texttransform }}</span>
    <app-jj-icons *ngIf="position === 'right' && svg && label" class="everleadMainButtonBorder__iconRight" [type]="svg" [width]="20" [height]="20"
        [color]="disabled ? 'primaryLight': 'secondary'"></app-jj-icons>
        <div class="label" *ngIf="!label">
            <ng-content></ng-content>
       </div>
</button>

<button [id]="id" *ngIf="type === 'borderSmall'" class="everleadMainButtonBorderSmall" [disabled]="disabled"
    (click)="buttonClick()" [class.disabled]="disabled" [ngClass]="{ buttonMaxWidth: maxWidth }">

    <app-jj-icons *ngIf="svg && !label" class="everleadMainButtonBorderSmall__icon" [type]="svg" [width]="16" [height]="16"
    [color]="disabled ? 'primaryLight': 'secondary'"></app-jj-icons>
    
    <app-jj-icons *ngIf="position === 'left' && svg && label" class="everleadMainButtonBorderSmall__iconLeft" [type]="svg" [width]="16" [height]="16"
        [color]="disabled ? 'primaryLight': 'secondary'"></app-jj-icons>

    <span class="label" *ngIf="label" >{{label | texttransform }}</span>

    <app-jj-icons *ngIf="position === 'right' && svg && label" class="everleadMainButtonBorderSmall__iconRight" [type]="svg" [width]="16" [height]="16"
        [color]="disabled ? 'primaryLight': 'secondary'"></app-jj-icons>
        <div class="label" *ngIf="!label" [style.font-size]="fontSize">
            <ng-content></ng-content>
       </div>
</button>

<button [id]="id" *ngIf="type === 'underline'" class="everleadMainButtonUnderline" [disabled]="disabled"
    (click)="buttonClick()" [class.disabled]="disabled" [ngClass]="{ buttonMaxWidth: maxWidth }">
    <div class="everleadMainButtonUnderline__inner">

        <app-jj-icons *ngIf="svg" class="everleadMainButtonUnderline__icon" [type]="svg" [width]="20"
            [height]="20"></app-jj-icons>
        <span class="label" *ngIf="label" [style.font-size]="fontSize">{{label | texttransform }}</span>
        <div class="label" *ngIf="!label" [style.font-size]="fontSize">
            <ng-content></ng-content>
        </div>
    </div>
    <span class="line"></span>
</button>

<button [id]="id" *ngIf="type === 'underlineSmall'" class="everleadMainButtonUnderlineSmall" [disabled]="disabled"
    (click)="buttonClick()" [class.disabled]="disabled" [ngClass]="{ buttonMaxWidth: maxWidth }">
    <div class="everleadMainButtonUnderlineSmall__inner">
        <app-jj-icons *ngIf="svg" class="everleadMainButtonUnderlineSmall__icon" [type]="svg" [width]="16"
            [height]="16" ></app-jj-icons>
        <span class="label" *ngIf="label" [style.font-size]="fontSize">{{label | texttransform }}</span>
       <div class="label" *ngIf="!label" [style.font-size]="fontSize">
            <ng-content></ng-content>
       </div>

    </div>
    <span class="line"></span>
</button>



<div *ngIf="type === 'svg'" class="everleadMainButtonSVG" (click)="buttonClick()">
    <button [id]="id" *ngIf="type === 'svg'" class="everleadMainButtonSVG__button" [disabled]="disabled"
        [class.disabled]="disabled">
        <app-jj-icons [type]="svg" [width]="32" [height]="32" [color]="'primaryLight'"></app-jj-icons>
    </button>
    <span class="everleadMainButtonSVG__label" [style.font-size]="fontSize" *ngIf="label">{{label | texttransform }}</span>
</div>

<button (mouseenter)="actioncolorChange='primaryLight'" (mouseleave)="actioncolorChange='fontDark'" [id]="id" *ngIf="type === 'action'" class="everleadMainButtonAction" [disabled]="disabled" (click)="buttonClick()" [class.disabled]="disabled">
    <app-jj-icons [type]="svg" [width]="16" [height]="16" [color]="actioncolorChange"></app-jj-icons>
</button>

<!-- 
// parent

<action-tooltip svg="arrow-up" [parentButtonsArray]="[{svg: 'something', label: 'labelone'. callback:() => parentFunction()}]"></action-tooltip>


// resubale child

<button [ngxTippy]="tooltip" >
    {{ svg }}
</button>

<div class="child example" #tooltip>
    <button *ngFor="let button of parentbuttonsArray" (click)="button.callback()">{{button.label}}</button>
</div> -->


import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-processvisual-popup',
  templateUrl: './processvisual-popup.component.html',
  styleUrls: ['./processvisual-popup.component.scss']
})
export class ProcessvisualPopupComponent implements OnInit , OnDestroy{

  selectedNode;
  inputData;
  inputs;
  data = null;
  type = null;
  result
  duplicateData;
  newDuplicateData;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public commonService: CommonService,
    public eventService:  EventService,
    private axivasTranslationService:  AxivasTranslateService,
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    private refreshValueService: RefreshValueService,   
    ) { }

  ngOnInit(): void {
    //console.log('called')
   this.getInputdata();
  }

  saveData() {

    let externalDataSourceId;

    switch(this.type){
      case 'workflows':
        externalDataSourceId = 531;
        break;
      case 'steps':
        externalDataSourceId = 568;
        break;
      case 'bookingDays':
        externalDataSourceId = 568;
        break;
      case 'results':
        externalDataSourceId = 570;
        break;
      case 'results-children':
        externalDataSourceId = 570
        break;
    }

   this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(externalDataSourceId, [
      this.commonService.getModifyArrayBody(this.data, ['description'])
    ]).then(()=>{
      this.loaderService.display(false)
      this.eventService.closeJjPopup()
      this.eventService.customEvent.emit({ id: 'updateProcessVisual' })
  })


}

  createDuplicate(){
  this.loaderService.display(true);
  this.externaldatasourceService.executeExternalDataSource(530, [
    this.commonService.getModifyArrayBody({
      projectId: this.applicationInfoService.projectID,
      defaultName: this.duplicateData.defaultName,
      ...this.duplicateData
    },[])
  ]).then(createWorkflowResult => {
    // console.log('CREATEWORKFLOW RESULT', createWorkflowResult)
    this.refreshValueService.getWorkflows().then(() =>{
      this.eventService.customEvent.emit({
      id: 'updateProcessVisualWorkflow',
      value: createWorkflowResult.id
    }) 
    }).catch(()=> {this.loaderService.display(false);})
  
    this.loaderService.display(false);

  }).catch(()=> {this.loaderService.display(false);})
  }

  // ? provisory call until api is sorted
/*   createWorkflow(){
    this.loaderService.display(true)
    this.eventService.closeJjPopup()
    this.eventService.customEvent.emit({
      id: 'updateProcessVisualWorkflow',
      value: this.data.id,
      action: 'duplicate'
    })
    this.loaderService.display(false)
    this.eventService.closeJjPopup()


  } */

  getInputdata(){

    this.type = this.applicationInfoService.miscSettings['processVisualObjectType'];

    if(this.type === 'duplicate'){
     this.data= this.applicationInfoService.miscSettings['duplicateVisualObject'];

    } else {
      this.data = this.applicationInfoService.miscSettings['processVisualObject'];
      this.data.defaultName = this.getTranslationValue('name', this.data);
      this.data.description = this.getTranslationValue('description', this.data);

      //console.log('POPUP', this.data)

      if(this.data.bookTaskAfterXDaysResultId){
        this.result = this.applicationInfoService.results
        .toArray()
        .find(
          (resultItem) => resultItem.id === this.data.bookTaskAfterXDaysResultId
        );
  
        this.result.defaultName = this.getTranslationValue('name',this.result)
  
       }

    }

     return;
    
  }


  getTranslationValue(type, data) {
    if(type === 'name'){
      if (data?.nameTranslationToken != null && data?.nameTranslationToken != undefined) {
        return this.axivasTranslationService.getTranslationTextForToken(data?.nameTranslationToken?.tokenFullName);
      } else {
        return data.defaultName;
      }
    }
    if(type === 'description'){
      if (data?.description != null && data?.description != undefined) {
        return this.axivasTranslationService.getTranslationTextForToken(data?.description?.tokenFullName);
      } else {
        return data.description;
      }
    }
  }

  ngOnDestroy(): void {
      
  }

}

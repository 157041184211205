<div class="entityTableDesigner" *ngIf="dataTableItem">
    <div class="entityTableDesignerHeaderLine">
        <div class="entityTableDesignerHeaderLine__label">
            <label>{{ 'EntityTableDesigner.Label.BaseEntity' | texttransform}}: {{ dataTableItem.mainEntityId }}</label>
            <select [(ngModel)]="dataTableItem.mainEntityId" (change)="changeBaseEntity()">
                <option [ngValue]=1>{{ commonService.getNameTranslationOrDefaultName(this.applicationInfoService.entities.Item('1'), 'entityName') | texttransform }}</option>
                <option [ngValue]=2>{{ commonService.getNameTranslationOrDefaultName(this.applicationInfoService.entities.Item('2'), 'entityName') | texttransform }}</option>
                <option [ngValue]=4>{{ commonService.getNameTranslationOrDefaultName(this.applicationInfoService.entities.Item('4'), 'entityName') | texttransform }}</option>
                <option [ngValue]=975>{{ commonService.getNameTranslationOrDefaultName(this.applicationInfoService.entities.Item('975'), 'entityName') | texttransform }}</option>
                <option [ngValue]=992>{{ commonService.getNameTranslationOrDefaultName(this.applicationInfoService.entities.Item('992'), 'entityName') | texttransform }}</option>
                <option [ngValue]=1061>{{ commonService.getNameTranslationOrDefaultName(this.applicationInfoService.entities.Item('1061'), 'entityName') | texttransform }}</option>
                <option [ngValue]=21>{{ commonService.getNameTranslationOrDefaultName(this.applicationInfoService.entities.Item('20'), 'entityName') | texttransform }}</option>
            </select>
        </div>
        <div class="d-flex">
            <button class="everleadCustomButton" (click)="editorMode=2" *ngIf="editorMode==1">{{ 'EntityTableDesigner.Label.TableSettings' | texttransform}}</button>
            <button class="everleadCustomButton" (click)="editorMode=1" *ngIf="editorMode==2">{{ 'EntityTableDesigner.Label.DataFields' | texttransform}}</button>
            <button class="everleadCustomButton" (click)="updateTableLayout()" *ngIf="dataTableItem.id != null">{{ 'EntityTableDesigner.Label.Update' | texttransform}}</button>
            <button class="everleadCustomButton" (click)="createTableLayout()" *ngIf="dataTableItem.id == null">{{ 'EntityTableDesigner.Label.CreateNew' | texttransform}}</button>    
        </div>
    </div>


    <!-- Defining table settings -->
    <div class="entityTableDesigner__wrapper entityTableDesigner__generalSettings" *ngIf="editorMode==2">
        <app-layout header="EntityTableDesigner.Label.TableSettings">
        <div class="entityTableDesignerTableSettingsLine">
            <input type="checkbox" [(ngModel)]="tableSettingsJson.canBeExported">{{ 'EntityTableDesigner.Label.CanBeExported' | texttransform}}
        </div>
                
        <div class="entityTableDesignerTableSettingsLine">
            <input type="checkbox" [(ngModel)]="tableSettingsJson.darkMode">{{ 'EntityTableDesigner.Label.DarkMode' | texttransform}}
        </div>        
        <div class="entityTableDesignerTableSettingsLine">
            <input type="checkbox" [(ngModel)]="tableSettingsJson.onlyForCurrentPool">{{ 'EntityTableDesigner.Label.OnlyForCurrentPool' | texttransform}}
        </div>
        <div class="entityTableDesignerTableSettingsLine">
            <input type="checkbox" [(ngModel)]="tableSettingsJson.onlyForCurrentCampaign">{{ 'EntityTableDesigner.Label.OnlyForCurrentCampaign' | texttransform}}
        </div>
        <hr>
        
        <div *ngIf="dataTableItem.mainEntityId==99">
            <div class="entityTableDesignerTableSettingsLine">                    
                <select [(ngModel)]="tableSettingsJson.opportunityLevel">
                    <option ngValue="account">{{ 'Account.Label.Header' | texttransform}}</option>
                    <option ngValue="project">{{ 'General._.Project' | texttransform}}</option>
                </select>                
                {{ 'EntityTableDesigner.Label.OpportunityLevel' | texttransform}}
            </div>            
            <div class="entityTableDesignerTableSettingsLine">                    
                <input type="checkbox" [(ngModel)]="tableSettingsJson.showLostOpportunitiesSettingVisible">{{ 'EntityTableDesigner.Label.ShowLostOpportunitiesSettingVisible' | texttransform}}
            </div>

            <div class="entityTableDesignerTableSettingsLine">                    
                <input type="checkbox" [(ngModel)]="tableSettingsJson.showLostOpportunities">{{ 'EntityTableDesigner.Label.ShowLostOpportunities' | texttransform}}
            </div>
        </div>

        <div *ngIf="dataTableItem.mainEntityId==2 || dataTableItem.mainEntityId==99">            
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.showOnlyDataForCurrentUser">{{ 'EntityTableDesigner.Label.ShowOnlyDataForCurrentUser' | texttransform}}
            </div>
        </div>

        <div *ngIf="dataTableItem.mainEntityId==2">            
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.showTastsWithoutContactSwitch">{{ 'EntityTableDesigner.Label.ShowTastsWithoutContactSwitch' | texttransform}}
            </div>
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.showTastsWithoutContact">{{ 'EntityTableDesigner.Label.ShowTastsWithoutContact' | texttransform}}
            </div>
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.includePendingSteps">{{ 'EntityTableDesigner.Label.IncludePendingSteps' | texttransform}}
            </div>    
        </div>

        <div *ngIf="dataTableItem.mainEntityId==1">
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.useAccountKeyWordSearch">{{ 'EntityTableDesigner.Label.UseAccountKeyWordSearch' | texttransform}}
            </div>
        </div>

        
        <div *ngIf="dataTableItem.mainEntityId==4">
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.showContactsForSelectedAccount">{{ 'EntityTableDesigner.Label.ShowContactsForSelectedAccount' | texttransform}}
            </div>    
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.isConsentTable">{{ 'EntityTableDesigner.Label.IsConsentTable' | texttransform}}
            </div>    
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.showMicroCampaignCreationSwitch">{{ 'EntityTableDesigner.Label.ShowMicroCampaignCreationSwitch' | texttransform}}
            </div>    
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.useMicroCampaignContacts">{{ 'EntityTableDesigner.Label.UseMicroCampaignContacts' | texttransform}}
            </div>    
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.microCampaignNewslettermode">{{ 'EntityTableDesigner.Label.MicroCampaignNewslettermode' | texttransform}}
            </div>    
        </div>
        
        <div *ngIf="dataTableItem.mainEntityId==780">
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.showEventsForCurrentProspect">{{ 'EntityTableDesigner.Label.ShowEventsForCurrentProspect' | texttransform}}
            </div>    
            <br>
            <br>    
        </div>

        <div class="entityTableDesignerTableSettingsLine">
            <input type="checkbox" [(ngModel)]="tableSettingsJson.additionalToolbar">{{ 'EntityTableDesigner.Label.AdditionalToolbar' | texttransform}}
        </div>

        <!--create buttons -->
 <!--        <div class="entityTableDesignerTableSettingsLine">
            <input type="checkbox" [(ngModel)]="tableSettingsJson.addButton">{{ 'EntityTableDesigner.Label.AddButton' | texttransform}}
        </div> -->
        
        <!-- Account & Contact VIP -->
        <div *ngIf="dataTableItem.mainEntityId==1">
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.showOnlyAccountVipsSettingVisible">{{ 'EntityTableDesigner.Label.ShowOnlyAccountVipsSettingVisible' | texttransform}}
            </div>
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.showOnlyAccountVips">{{ 'EntityTableDesigner.Label.ShowOnlyAccountVips' | texttransform}}
            </div>
        </div>
        <div *ngIf="dataTableItem.mainEntityId==4">
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.showOnlyContactVipsSettingVisible">{{ 'EntityTableDesigner.Label.ShowOnlyContactVipsSettingVisible' | texttransform}}
            </div>
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.showOnlyContactVips">{{ 'EntityTableDesigner.Label.ShowOnlyContactVips' | texttransform}}
            </div>

            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.doubleOptinOptionVisivle">{{ 'EntityTableDesigner.Label.DoubleOptinOptionVisible' | texttransform}}
            </div>    
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.doubleOptInRequired">{{ 'EntityTableDesigner.Label.DoubleOptInRequired' | texttransform}}
            </div>                    
        </div>

        <div *ngIf="dataTableItem.mainEntityId==917">
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.vip">{{ 'EntityTableDesigner.Label.OnlyVipOptionVisible' | texttransform}}
            </div>
            <div class="entityTableDesignerTableSettingsLine entityTableDesignerTableSettingsLine__details">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.vipActive">{{ 'EntityTableDesigner.Label.OnlyVip' | texttransform}}
            </div>    
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.blacklistedSwitch">{{ 'EntityTableDesigner.Label.BlacklistedSwitch' | texttransform}}
            </div>    
            <div class="entityTableDesignerTableSettingsLine entityTableDesignerTableSettingsLine__details">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.filterBlacklisted">{{ 'EntityTableDesigner.Label.FilterBlacklisted' | texttransform}}
            </div>    
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.alreadyInEverleadSwitch">{{ 'EntityTableDesigner.Label.AlreadyInEverleadSwitch' | texttransform}}
            </div>    
            <div class="entityTableDesignerTableSettingsLine entityTableDesignerTableSettingsLine__details">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.alreadyInEverlead">{{ 'EntityTableDesigner.Label.AlreadyInEverlead' | texttransform}}
            </div>    
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.notInEverleadSwitch">{{ 'EntityTableDesigner.Label.NotInEverleadSwitch' | texttransform}}
            </div>    
            <div class="entityTableDesignerTableSettingsLine entityTableDesignerTableSettingsLine__details">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.notInEverlead">{{ 'EntityTableDesigner.Label.NotInEverlead' | texttransform}}
            </div>    
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.showIspItemsSwitch">{{ 'EntityTableDesigner.Label.ShowIspItemsSwitch' | texttransform}}
            </div>    
            <div class="entityTableDesignerTableSettingsLine entityTableDesignerTableSettingsLine__details">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.showIspItems">{{ 'EntityTableDesigner.Label.ShowIspItems' | texttransform}}
            </div>    
        </div>
        <div *ngIf="dataTableItem.mainEntityId == 4">
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.aicontacts">{{ 'EntityTableDesigner.Label.OnlyAIContactsOptionVisible' | texttransform}}
            </div>
            <div class="entityTableDesignerTableSettingsLine entityTableDesignerTableSettingsLine__details">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.aicontactsActive">{{ 'EntityTableDesigner.Label.OnlyAIContacts' | texttransform}}
            </div>    
            <div class="entityTableDesignerTableSettingsLine entityTableDesignerTableSettingsLine__details">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.aicontactsHighPotentialActive">{{ 'EntityTableDesigner.Label.OnlyAIHighPotential' | texttransform}}
            </div>    
        </div>
        <div *ngIf="dataTableItem.mainEntityId == 20">
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.onlyOptinsForContact">{{ 'EntityTableDesigner.Label.OnlyOptinsForContact' | texttransform}}
            </div>
            <div class="entityTableDesignerTableSettingsLine entityTableDesignerTableSettingsLine__details">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.onlyOptinsForContactSwitch">{{ 'EntityTableDesigner.Label.OnlyOptinsForContactSwitch' | texttransform}}
            </div>    
        </div>    

        <div *ngIf="applicationInfoService?.isDeveloper" class="isDeveloper">
            <div class="entityTableDesignerTableSettingsLine">
                <input type="checkbox" [(ngModel)]="tableSettingsJson.getConsentFromMiscSettings">Sonderfall Consent: Falls irgendwo Consent schonmal abgefragt wurde, soll sich diese Tabelle die ConsentIds aus den WizardValues besorgen?
            </div>

            <div class="entityTableDesignerTableSettingsLine" >
                <input [(ngModel)]="tableSettingsJson.additinalValueToSubstractFromHeight">{{ 'EntityTableDesigner.Label.AdditionalValueToSubstractFromHeight' | texttransform}}
            </div>            
            <div class="entityTableDesignerTableSettingsLine" >
                <input [(ngModel)]="tableSettingsJson.heightUntilControlId">{{ 'EntityTableDesigner.Label.HeightTillThisControlId' | texttransform}}
            </div>            
            <div class="entityTableDesignerTableSettingsLine" >
                <input [(ngModel)]="tableSettingsJson.fixDataRowHeight">{{ 'EntityTableDesigner.Label.FixDataRowHeight' | texttransform}}
            </div>            
            <div class="entityTableDesignerTableSettingsLine" >
                <input [(ngModel)]="tableSettingsJson.dataRowBackgroundColor">{{ 'EntityTableDesigner.Label.DataRowBackgroundColor' | texttransform}}
            </div>            
            <div class="entityTableDesignerTableSettingsLine" >
                <input [(ngModel)]="tableSettingsJson.tableMenuBackgroundColor">{{ 'EntityTableDesigner.Label.TableMenuBackgroundColor' | texttransform}}
            </div>            
            <hr>
            <div class="entityTableDesignerTableSettingsLine" >
                <input type="checkbox"  [(ngModel)]="tableSettingsJson.canStartMicroCampaign">{{ 'EntityTableDesigner.Label.CanStartMicroCampaign' | texttransform}}
            </div>            
            <div class="entityTableDesignerTableSettingsLine" >
                <input [(ngModel)]="tableSettingsJson.microCampaignCustomText">{{ 'EntityTableDesigner.Label.MicroCampaignCustomText' | texttransform}}
            </div>            
            <div class="entityTableDesignerTableSettingsLine" >
                <input type="checkbox"  [(ngModel)]="tableSettingsJson.canStartMailshot">{{ 'EntityTableDesigner.Label.CanStartMailshot' | texttransform}}
            </div>            
            <div class="entityTableDesignerTableSettingsLine" >
                <input [(ngModel)]="tableSettingsJson.mailShotCustomText">{{ 'EntityTableDesigner.Label.MailShotCustomText' | texttransform}}
            </div> 
            <hr>
            <div>
                <button class="everleadCustomButton" (click)="updateTableLayout(true)">Als Standard speichern</button>
            </div>
        </div>
        </app-layout>
    </div>


    <!-- Defining table layout -->
    <div class="entityTableDesigner__wrapper" *ngIf="editorMode==1">
        <!-- Entity & Member -->        
        <div class="entityTableDesigner__memberList">
            <app-layout header="UiControlDesign.Label.AvailiableDataFields">
            <div class="entityTableDesigner__memberList__entity">
                <!-- <div class="entityTableDesigner__memberList__entity__header">
                    <label>{{ 'UiControlDesign.Label.AvailiableDataFields' | texttransform}}</label>
                </div>             -->
                <select [(ngModel)]="entityId" class="maxWidth entityTableDesignerDropdown">
                    <option *ngFor="let entity of availableEntities" [ngValue]="entity.uiEntityInstance">
                        {{ commonService.getNameTranslationOrDefaultName(entity, 'entityName') | texttransform }}
                    </option>
                </select>
            </div>
            <div class="entityTableDesigner__memberList__member">
                <div *ngFor="let member of availableMembers()" class="entityTableDesigner__memberList__item everleadTableRow" [ngClass]="{                    
                    hideInDom: checkIfMemberNeedsToBeHidden(member)
                }">
                    <label class="ellipsisText">{{ commonService.getNameTranslationOrDefaultName(member, 'defaultName') | texttransform }}</label>
                    <app-jj-icons [type]="'plus-circle'" [width]="20" [height]="20" (click)="addMember(member, entityId)"></app-jj-icons>
                    <!-- <svg-icon src="assets/images/svg/icon-plus+.svg" (click)="addMember(member, entityId)"
                        [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" class="fingerCursor"></svg-icon>             -->
                </div>
            </div>
            <div class="entityTableDesigner__memberList__specialmember" *ngIf="applicationInfoService.isDeveloper">
                <div class="entityTableDesigner__memberList__item">
                    <label class="ellipsisText">{{ 'EntityTableDesigner.Label.AddCustom' | texttransform}}</label>
                    <app-jj-icons [type]="'plus-circle'" [width]="20" [height]="20" (click)="addMember(null, entityId, 'custom')"></app-jj-icons>
                    <!-- <svg-icon src="assets/images/svg/icon-plus+.svg" (click)="addMember(null, entityId, 'custom')"
                        [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" class="fingerCursor"></svg-icon>             -->
                </div>
            </div>
            </app-layout>
        </div>        

        <div class="entityTableDesigner__designer  entityTableDesignerAssignedMember__wrapper">
            <app-layout header="UiControlDesign.Label.UsedDataFields">
            <!-- <div class="entityTableDesigner__memberList__entity__header entityTableDesignerAssignedMember__header">
                <label>{{ 'UiControlDesign.Label.UsedDataFields' | texttransform}}</label>
            </div>             -->

        <div cdkDropList [cdkDropListData]="tableLayout" (cdkDropListDropped)="moveInList($event, '0')" class=" entityTableDesignerAssignedMember__body">
            <div cdkDrag [cdkDragData]="item" data-html="true" class="entityTableDesigner__designer__item everleadTableRow" [ngClass]="{
                    isHidden: tableLayoutItem.hideInDesigner == true && applicationInfoService.isDeveloper,
                    hideInDom: tableLayoutItem.hideInDesigner == true && !applicationInfoService.isDeveloper,
                    entityTableDesignerSubcontrol: tableLayoutItem.subcontrol == true
                }" *ngFor="let tableLayoutItem of tableLayout">            
                <div class="entityTableDesigner__designer__item__line">                    
                    <label class="entityTableDesignerOverviewlabel">
                        <span *ngIf="applicationInfoService?.isDeveloper" class="everleadCoral entityIdSpan">
                            {{ tableLayoutItem.entityId }}
                        </span>
                        {{ tableLayoutItem.displayText | texttransform }} 
                        <span class="everleadCoral" *ngIf="!applicationInfoService.isDeveloper || tableLayoutItem.memberId != null">{{ tableLayoutItem.memberName }}</span>
                        <input class="everleadCoral" *ngIf="applicationInfoService.isDeveloper && tableLayoutItem.memberId == null" [(ngModel)]="tableLayoutItem.memberName">
                    </label>
                    <div class="entityTableDesigner__designer__item__icons">
                        <app-jj-icons [type]="'arrow-left'" [width]="20" [height]="20" (click)="tableLayoutItem.subcontrol = false"
                            *ngIf="tableLayoutItem.subcontrol == true"></app-jj-icons>
                        <app-jj-icons [type]="'arrow-right'" [width]="20" [height]="20" (click)="tableLayoutItem.subcontrol = true"
                            *ngIf="tableLayoutItem.subcontrol != true"></app-jj-icons>
                        <app-jj-icons [type]="'text-aa'" [width]="20" [height]="20" (click)="tableLayoutItem.showHeaderAsRowDesription = false"
                            color="secondary" *ngIf="tableLayoutItem.showHeaderAsRowDesription == true"></app-jj-icons>
                        <app-jj-icons [type]="'text-aa'" [width]="20" [height]="20" (click)="tableLayoutItem.showHeaderAsRowDesription = true"
                            color="black" *ngIf="tableLayoutItem.showHeaderAsRowDesription != true"></app-jj-icons>

                        <span *ngIf="tableLayoutItem.sortColumn == true" (click)="changeSortDirection(tableLayoutItem)">
                            <app-jj-icons [type]="'sort-ascending'" [width]="20" [height]="20" (click)="setDetaultSort(tableLayoutItem)"
                                *ngIf="tableLayoutItem.defaultSort == 'ASC_NULLS_LAST'"></app-jj-icons>
                            <app-jj-icons [type]="'sort-descending'" [width]="20" [height]="20" (click)="setDetaultSort(tableLayoutItem)"
                                *ngIf="tableLayoutItem.defaultSort == 'DESC_NULLS_LAST'"></app-jj-icons>
                        </span>
                        <span *ngIf="tableLayoutItem.sortColumn != true" (click)="changeSortDirection(tableLayoutItem)"
                            [tippy]="'EntityTableDesigner.Label.DefaultSortThisField' | texttransform">
                            <app-jj-icons [type]="'star'" [width]="20" [height]="20" ></app-jj-icons>
                        </span>
                        <app-jj-icons [type]="'trash'" [width]="20" [height]="20" (click)="removeItem(tableLayoutItem)"></app-jj-icons>
                        <app-jj-icons [type]="'caret-down'" [width]="20" [height]="20" (click)="toggleItem(tableLayoutItem)"
                            *ngIf="toggledItems[tableLayoutItem.entityId + tableLayoutItem.memberName] != true"></app-jj-icons>
                        <app-jj-icons [type]="'caret-up'" [width]="20" [height]="20" (click)="toggleItem(tableLayoutItem)"
                            *ngIf="toggledItems[tableLayoutItem.entityId + tableLayoutItem.memberName] == true"></app-jj-icons>
                        <app-jj-icons [type]="'equals'" [width]="20" [height]="20" cdkDragHandle></app-jj-icons>
                    </div>        
                </div>
                <div *ngIf="toggledItems[tableLayoutItem.entityId + tableLayoutItem.memberName] == true">

                    <!-- Filter & Subcontrol -->
                    <div class="entityTableDesigner__designer__item__line entityTableDesignerDetails">                        
                        <div>
                            <div class="entityTableDesignerLineHeader">{{ 'EntityTableDesigner.Label.Settings' | texttransform}}</div>
                            <div class="entityTableDesigner__designer__item__check"><input [(ngModel)]="tableLayoutItem.visible" type="checkbox">{{ 'EntityTableDesigner.Label.Visible' | texttransform}}</div>
                            <div class="entityTableDesigner__designer__item__check"><input [(ngModel)]="tableLayoutItem.sortable" type="checkbox">{{ 'EntityTableDesigner.Label.Sortable' | texttransform}}</div>
                            <div class="entityTableDesigner__designer__item__check"><input [(ngModel)]="tableLayoutItem.filterable" type="checkbox">{{ 'EntityTableDesigner.Label.Filterable' | texttransform}}</div>
                            <div class="entityTableDesigner__designer__item__check"><input [(ngModel)]="tableLayoutItem.hidefilterbox" type="checkbox">{{ 'EntityTableDesigner.Label.HideFilterBox' | texttransform}}</div>
                            <div class="entityTableDesigner__designer__item__check"><input [(ngModel)]="tableLayoutItem.bold" type="checkbox">{{ 'EntityTableDesigner.Label.Bold' | texttransform}}</div>
                            <div class="entityTableDesigner__designer__item__check"><input [(ngModel)]="tableLayoutItem.italic" type="checkbox">{{ 'EntityTableDesigner.Label.Italic' | texttransform}}</div>                            
                        </div>   
                    </div>    

                    <!-- ControlType -->
                    <div class="entityTableDesigner__designer__item__line entityTableDesignerDetails">
                        <div class="maxWidth">
                            <div class="entityTableDesignerLineHeader">{{ 'EntityTableDesigner.Label.ControlType' | texttransform}}</div>
                            <div>
                                <select [(ngModel)]="tableLayoutItem.controlType" class="maxWidth">
                                    <option [ngValue]=0>{{ 'EntityTableDesigner.Label.ControlTypeText' | texttransform}}</option>
                                    <option [ngValue]=1>{{ 'EntityTableDesigner.Label.ControlTypeDate' | texttransform}}</option>
                                    <option [ngValue]=2>{{ 'EntityTableDesigner.Label.ControlTypeRedDate' | texttransform}}</option>
                                    <option [ngValue]=34>{{ 'EntityTableDesigner.Label.URL' | texttransform}}</option>
                                    <option [ngValue]=3>{{ 'EntityTableDesigner.Label.ControlTypeImage' | texttransform}}</option>
                                    <option [ngValue]=4>{{ 'EntityTableDesigner.Label.ControlQuality' | texttransform}}</option>
                                    <option [ngValue]=5>{{ 'EntityTableDesigner.Label.ControlTypeResearch' | texttransform}}</option>
                                    <option [ngValue]=6>{{ 'EntityTableDesigner.Label.ControlTypeLeadScore' | texttransform}}</option>
                                    <option [ngValue]=15>{{ 'EntityTableDesigner.Label.ControlTypeLeadScore' | texttransform }}  (Prospect)</option> 
                                    <option [ngValue]=7 *ngIf="applicationInfoService?.isDeveloper">{{ 'EntityTableDesigner.Label.ControlTypeDelete' | texttransform}}</option>
                                    <option [ngValue]=8>{{ 'EntityTableDesigner.Label.ControlTypeVisitList' | texttransform}}</option>                                    
                                    <option [ngValue]=9 *ngIf="applicationInfoService?.isDeveloper">{{ 'EntityTableDesigner.Label.AddToAccountGroup' | texttransform}}</option>
                                    <option [ngValue]=10 *ngIf="applicationInfoService?.isDeveloper">{{ 'EntityTableDesigner.Label.DownloadRecording' | texttransform}}</option>
                                    <option [ngValue]=11>{{ 'EntityTableDesigner.Label.AIIndicator' | texttransform}}</option>
                                    <option [ngValue]=12>{{ 'EntityTableDesigner.Label.AIContact' | texttransform}}</option>
                                    <option [ngValue]=13>{{ 'EntityTableDesigner.Label.VIP' | texttransform}}</option> 
                                    <option [ngValue]=21>{{ 'EntityTableDesigner.Label.VIPInfoOnly' | texttransform}}</option> 
                                    <option [ngValue]=14>{{ 'EntityTableDesigner.Label.AssetLink' | texttransform}}</option>
                                    <option [ngValue]=16>{{ 'EntityTableDesigner.Label.ProspectConnectedAccount' | texttransform}}</option>
                                    <option [ngValue]=17>{{ 'EntityTableDesigner.Label.ProspectBlacklisted' | texttransform}}</option>
                                    <option [ngValue]=18>{{ 'EntityTableDesigner.Label.ChangeLeadstate' | texttransform}}</option>
                                    <option [ngValue]=19>{{ 'EntityTableDesigner.Label.EditOptIn' | texttransform}}</option>
                                    <option [ngValue]=20 *ngIf="dataTableItem.mainEntityId == 4">{{ 'EntityTableDesigner.Label.CreateTaskForContact' | texttransform}}</option>
                                    <option [ngValue]=22 *ngIf="dataTableItem.mainEntityId == 1227">{{ 'EntityTableDesigner.Label.DownloadEntityFile' | texttransform}}</option>
                                    <option [ngValue]=23 *ngIf="dataTableItem.mainEntityId == 1227">{{ 'EntityTableDesigner.Label.EntityFileFileSize' | texttransform}}</option>
                                    <option [ngValue]=24 *ngIf="dataTableItem.mainEntityId == 1227">{{ 'EntityTableDesigner.Label.DeleteEntityFile' | texttransform}}</option>
                                    <option [ngValue]=25 *ngIf="dataTableItem.mainEntityId == 99">{{ 'EntityTableDesigner.Label.EditOpportunity' | texttransform}}</option>
                                    <option [ngValue]=26 *ngIf="dataTableItem.mainEntityId == 99">{{ 'EntityTableDesigner.Label.ChangeOpportunityState' | texttransform}}</option>
                                    <option [ngValue]=27>{{ 'EntityTableDesigner.Label.Currency' | texttransform}}</option>
                                    <option [ngValue]=28>{{ 'EntityTableDesigner.Label.OnlyDateNoTime' | texttransform}}</option>
                                    <option [ngValue]=29>{{ 'EntityTableDesigner.Label.UserProjectRoleId' | texttransform}}</option>
                                    <option [ngValue]=30>Reserved</option>
                                    <option [ngValue]=31>{{ 'EntityTableDesigner.Label.UserProjectChangeSecurityUserVale' | texttransform}}</option>
                                    <option [ngValue]=32>{{ 'EntityTableDesigner.Label.UserProjectDisableUser' | texttransform}}</option>
                                    <option [ngValue]=33>{{ 'EntityTableDesigner.Label.EnrichDataWithExternalInterface' | texttransform}}</option>
                                </select>
                                <div class="entityTableDesigner__designer__item__check" *ngIf="tableLayoutItem.controlType == 0">
                                    <input [(ngModel)]="tableLayoutItem.showMultiline" type="checkbox">{{ 'EntityTableDesigner.Label.ShowMultiline' | texttransform}}
                                </div>                            
                            </div>
                            <div class="entityTableDesignerDescription">
                                {{ 'EntityTableDesigner.Label.ControlTypeDescription' | texttransform}}
                            </div>
                            <div class="maxWidth" *ngIf="tableLayoutItem.controlType == '0'">
                                <div>
                                    <div class="entityTableDesigner__designer__item__check">
                                        <input [(ngModel)]="tableLayoutItem.breakWhitespace" type="checkbox">
                                        {{ 'EntityTableDesigner.Label.BreakWhitespace' | texttransform}}
                                    </div>
                                </div>                                                          
                            </div>     
                        </div>
                    </div>

                    <!-- Übersetzungen -->
                    <div class="entityTableDesigner__designer__item__line entityTableDesignerDetails flexColumn">
                        <div class="maxWidth">
                            <div class="entityTableDesignerLineHeader">{{ 'EntityTableDesigner.Label.HeaderText' | texttransform}}</div>
                            <div>
                                <div>{{ 'EntityTableDesigner.Label.QuickText' | texttransform}}</div>
                                <input [(ngModel)]="tableLayoutItem.quickText" type="input" class="maxWidth" (change)="updateHeaderTranslation()">
                            </div>
                            <div class="entityTableDesignerDescription">
                                {{ 'EntityTableDesigner.Label.QuickTextDescription' | texttransform}}
                            </div>
                        </div>
                        <div class="maxWidth">
                            <div>
                                <div class="entityTableDesigner__designer__item__check">
                                    <input [(ngModel)]="tableLayoutItem.showHeaderAsRowDesription" type="checkbox">
                                    {{ 'EntityTableDesigner.Label.ShowHeaderAsRow' | texttransform}}
                                </div>
                            </div>                                
                            <div class="entityTableDesignerDescription">
                                {{ 'EntityTableDesigner.Label.ShowHeaderAsRowDesriptionDescription' | texttransform}}
                            </div>
                        </div>
                        <div class="maxWidth" *ngIf="tableLayoutItem.showHeaderAsRowDesription">
                            <div>
                                <div>{{ 'EntityTableDesigner.Label.AlternateQuickTextForValueControl' | texttransform}}</div>
                                <input [(ngModel)]="tableLayoutItem.alternateQuickTextForValueControl" type="input" class="maxWidth" (change)="updateHeaderTranslation()">
                            </div>
                            <div class="entityTableDesignerDescription">
                                {{ 'EntityTableDesigner.Label.AlternateQuickTextForValueControlDescription' | texttransform}}
                            </div>                            
                        </div>          
                        <div class="maxWidth" *ngIf="tableLayoutItem.showHeaderAsRowDesription">
                            <div>
                                <div class="entityTableDesigner__designer__item__check">
                                    <input [(ngModel)]="tableLayoutItem.breakLineAfterDescription" type="checkbox">
                                    {{ 'EntityTableDesigner.Label.BreakLineAfterDescription' | texttransform}}
                                </div>
                            </div>                                                          
                        </div>               
                    </div>

                    <!-- Subcontrol -->
                    <div class="entityTableDesigner__designer__item__line entityTableDesignerDetails">
                        <div class="maxWidth">
                            <div class="entityTableDesignerLineHeader">{{ 'EntityTableDesigner.Label.Subcontrol' | texttransform}}</div>
                            <div>
                                <div class="entityTableDesigner__designer__item__check"><input [(ngModel)]="tableLayoutItem.subcontrol" type="checkbox">{{ 'EntityTableDesigner.Label.Subcontrol' | texttransform}}</div>
                            </div> 
                            <div class="entityTableDesignerDescription">
                                {{ 'EntityTableDesigner.Label.SubcontrolDescription' | texttransform}}
                            </div>  
                        </div>
                    </div>

                    <!-- Misc settings -->
                    <div class="entityTableDesigner__designer__item__line entityTableDesignerDetails">
                        <div class="maxWidth">
                            <div class="entityTableDesignerLineHeader">{{ 'EntityTableDesigner.Label.MiscSettings' | texttransform}}</div>
                            <div>
                                <div class="entityTableDesigner__designer__item__check"><input [(ngModel)]="tableLayoutItem.maxWidth" type="string">
                                    {{ 'EntityTableDesigner.Label.MaxWidth' | texttransform}}
                                </div>
                                <div class="entityTableDesigner__designer__item__check"><input [(ngModel)]="tableLayoutItem.minWidth" type="string">
                                    {{ 'EntityTableDesigner.Label.MinWidth' | texttransform}}
                                </div>
                                <div class="entityTableDesigner__designer__item__check"><input [(ngModel)]="tableLayoutItem.minHeight" type="string">
                                    {{ 'EntityTableDesigner.Label.MinHeight' | texttransform}}
                                </div>
                                <div>
                                    <div class="entityTableDesigner__designer__item__check"><input [(ngModel)]="tableLayoutItem.doNotExport" type="checkbox">
                                        {{ 'EntityTableDesigner.Label.DoNotExport' | texttransform}}
                                    </div>
                                </div>    
                            </div> 
                        </div>
                    </div>

                    <!-- Alternate dropdown values -->
                    <div class="entityTableDesigner__designer__item__line entityTableDesignerDetails">
                        <div class="maxWidth">
                            <div class="entityTableDesignerLineHeader">{{ 'EntityTableDesigner.Label.AlternateDropdownData' | texttransform}}</div>
                            <div>
                                <div>
                                    <select [(ngModel)]="tableLayoutItem.alternateLookupTable" class="maxWidth">
                                        <option [ngValue]=null></option>
                                        <option ngValue="projectUser">{{ 'EntityTableDesigner.Label.LookupTableProjectUser' | texttransform}}</option>
                                        <option ngValue="workflows">{{ 'EntityTableDesigner.Label.LookupTableWorkflows' | texttransform}}</option>
                                        <option ngValue="campaigns">{{ 'EntityTableDesigner.Label.LookupTableCampaigns' | texttransform}}</option>
                                        <option ngValue="steps">{{ 'EntityTableDesigner.Label.LookupTableSteps' | texttransform}}</option>
                                        <option ngValue="results">{{ 'EntityTableDesigner.Label.LookupTableResults' | texttransform}}</option>
                                        <option ngValue="leadstate">{{ 'EntityTableDesigner.Label.LookupTableLeadstate' | texttransform}}</option>
                                        <option ngValue="projectleadstate">{{ 'EntityTableDesigner.Label.LookupTableLeadstate' | texttransform}} (Project)</option>                                        
                                        <option ngValue="pools">{{ 'EntityTableDesigner.Label.LookupTablePools' | texttransform}}</option>                                                                                
                                        <option ngValue="progresstates">{{ 'EntityTableDesigner.Label.ProgressStates' | texttransform}}</option>                                                                                
                                    </select>
                                </div>                                
                            </div>
                            <div class="entityTableDesignerDescription">
                                {{ 'EntityTableDesigner.Label.AlternateDropdownDataDescription' | texttransform}}
                            </div>
                        </div>
                    </div>

                    <div class="entityTableDesigner__designer__item__line entityTableDesignerDetails isDeveloper" *ngIf="applicationInfoService?.isDeveloper">
                        <div class="maxWidth">
                            <div class="entityTableDesignerLineHeader">{{ 'EntityTableDesigner.Label.AutomaticFiltering' | texttransform}}</div>
                            <div>
                                <div class="entityTableDesigner__designer__item__check"><input [(ngModel)]="tableLayoutItem.useAutomaticFilter" type="checkbox">
                                    {{ 'EntityTableDesigner.Label.UseAutomaticFilter' | texttransform}}
                                </div>
                            </div>
                            <div>
                                <div class="entityTableDesigner__designer__item__check"><input [(ngModel)]="tableLayoutItem.useNullAsValue" type="checkbox">
                                    {{ 'EntityTableDesigner.Label.UseNullAsValue' | texttransform}}
                                </div>
                            </div>
                            <div>
                                <div class="entityTableDesigner__designer__item__check"><input [(ngModel)]="tableLayoutItem.negateFilter" type="checkbox">
                                    {{ 'EntityTableDesigner.Label.NegateFilter' | texttransform}}
                                </div>
                            </div>

                            <div>
                                <div class="entityTableDesigner__designer__item__check">
                                    <input [(ngModel)]="tableLayoutItem.automaticFilterValue" type="text">
                                    <!-- <input [(ngModel)]="tableLayoutItem.automaticFilterValue" type="number" *ngIf="tableLayoutItem.memberType == 'NUMERIC'">
                                    <input [(ngModel)]="tableLayoutItem.automaticFilterValue" type="checkbox" *ngIf="tableLayoutItem.memberType == 'BOOL'"> -->
                                    {{ 'EntityTableDesigner.Label.AutomaticFilterValue' | texttransform}}
                                </div>
                            </div>
                            <div class="entityTableDesignerDescription">
                                {{ 'EntityTableDesigner.Label.AutomaticFilteringDescription' | texttransform}}
                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                    <div class="entityTableDesigner__designer__item__line entityTableDesignerDetails flexColumn isDeveloper" *ngIf="applicationInfoService?.isDeveloper">
                        <div class="maxWidth">
                            <div>
                                <div class="entityTableDesigner__designer__item__check">
                                    <input [(ngModel)]="tableLayoutItem.ignoreForQuery" type="checkbox">
                                    Für das Query ignorieren
                                </div>
                            </div>                                
                        </div> 
                    </div>
                </div>
            </div>
            </div>  
            </app-layout>
        </div>
    </div>
</div>


<!-- No definition yet -->
<div class="entityTableDesigner" *ngIf="!dataTableItem">
    <div class="newDataTableItem__header">
        {{ 'EntityTableDesigner.Label.NoDefinitionYet' | texttransform}}
    </div>    
    <div class="newDataTableItem__content">
        <div id="entityTableDesignerAccount" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ commonService.getNameTranslationOrDefaultName(applicationInfoService.entities.Item('1'), 'entityName') | texttransform }}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item('1'), false)">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item('1'), true)">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>        
        </div>
        <div id="entityTableDesignerTask" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ commonService.getNameTranslationOrDefaultName(applicationInfoService.entities.Item('4'), 'entityName') | texttransform }}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item('4'), false)">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item('4'), true)">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>        
        </div>
        <div id="entityTableDesignerContact" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ commonService.getNameTranslationOrDefaultName(applicationInfoService.entities.Item('2'), 'entityName') | texttransform }}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item('2'), false)">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item('2'), true)">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>                
        </div>
        <div id="entityTableDesignerActivity" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ 'EntityTableDesigner.Label.Activity' | texttransform}}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item('2'), false, 'activity')">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item('2'), true, 'activity')">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>        
        </div>    
        <div id="entityTableDesignerActivity" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ 'EntityTableDesigner.Label.Prospect' | texttransform}}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item('917'), false)">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item('917'), true)">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>        
        </div>    
        <div id="entityTableDesignerActivity" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ 'EntityTableDesigner.Label.Conversion' | texttransform}}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item('462'), false)">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item('462'), true)">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>        
        </div>    
        <div id="entityTableDesignerActivity" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ 'EntityTableDesigner.Label.Recording' | texttransform}}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item('934'), false)">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item('934'), true)">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>        
        </div>    

        <div id="entityTableDesignerActivity" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ 'EntityTableDesigner.Label.Vip' | texttransform}}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.vipEntity.toString()), false)">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.vipEntity.toString()), true)">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>        
        </div>  
        <div id="entityTableDesignerVIP" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ 'EntityTableDesigner.Label.VIPSettingEntity' | texttransform}}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.vipSettingEntity.toString()), false)">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.vipSettingEntity.toString()), true)">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>        
        </div>    

        <div id="entityTableDesignerOptin" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ 'EntityTableDesigner.Label.OptinOptOutSettingEntity' | texttransform}}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.optinEntity.toString()), false)">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.optinEntity.toString()), true)">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>        
        </div>    

        <div id="entityTableDesignerEMail" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ 'EntityTableDesigner.Label.EMailEntity' | texttransform}}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.emailEntity.toString()), false)">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.emailEntity.toString()), true)">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>        
        </div>    
        <div id="entityTableDesignerEMail" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ 'EntityTableDesigner.Label.Events' | texttransform}}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.eventsEntity.toString()), false)">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.eventsEntity.toString()), true)">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>        
        </div>    
        <div id="entityTableDesignerEMail" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ 'EntityTableDesigner.Label.UploadedFiles' | texttransform}}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.contentLibraryFileEntity.toString()), false)">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.contentLibraryFileEntity.toString()), true)">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>        
        </div>   
        <div id="entityTableDesignerEMail" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ 'EntityTableDesigner.Label.Opportunity' | texttransform}}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.opportunityEntity.toString()), false)">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.opportunityEntity.toString()), true)">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>        
        </div>   
        <div id="entityTableDesignerEMail" class="newDataTableItem ">
            <div class="newDataTableItem__label">
                <label>{{ 'EntityTableDesigner.Label.UserProject' | texttransform}}</label>
            </div>
            <div class="newDataTableItem__buttons">
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.userProjectEntity.toString()), false)">{{ 'EntityTableDesigner.Label.Empty' | texttransform}}</button>
                <button class="everleadCustomButton" (click)="createDataTableItem(applicationInfoService.entities.Item(entitytableService.userProjectEntity.toString()), true)">{{ 'EntityTableDesigner.Label.DefaultValues' | texttransform}}</button>
            </div>        
        </div>   
    </div>
</div>

    
<div class="toolbarPopupOuter" *ngIf="showPopup" (click)="closePopup()">
    <div class="toolbarPopup" *ngIf="showPopup">
        <div class="toolbarArrow" [style.right]="arrowRight"></div>
        <div class="toolbarPopup__content">
            <div *ngIf="section == 'showDemoLandingPageNotVisitedInfo'" class="showDemoLandingPageNotVisitedInfo">
                {{ 'Toolbar.Label.ShowDemoLandingPageNotVisitedInfo' | texttransform }}
            </div>
<!-- 
            <div *ngIf="section == 'research'" class="research">
                <div class="research__item" id="researchAccount" *ngIf="
                    applicationInfoService?.currentAccount != null &&
                    applicationInfoService?.currentAccount?.isDummyAccount != true
                ">
                    <div class="research__item__content">
                        <div>
                            <app-jj-icons type="user"></app-jj-icons>
                            {{ applicationInfoService.currentAccount.name1 | texttransform }}
                        </div>

                        <div class="research__item__content__divider"></div>

                        <div class="research__item__content__inner">
                                <div class="research__item__content__search">{{ 'Toolbar.Label.SearchIn' | texttransform }}</div>    
                                <div class="research__item__content__buttons">
                                    <svg-icon src="assets/images/svg/iconset/icon-google.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                                    (click)="launchGoogleSearch(1);$event.stopPropagation()" [tippy]="'Research.Label.Google' | texttransform"></svg-icon>

                                    <svg-icon src="assets/images/svg/iconset/icon-xing.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                                        (click)="openXing(1);$event.stopPropagation()" [tippy]="'Research.Label.Xing' | texttransform"></svg-icon>

                                    <svg-icon src="assets/images/svg/icon-linkedin.svg" class="fingerCursor"
                                        *ngIf="applicationInfoService.applicationSettings['showLinkedIn'] == 'true'" [svgStyle]="{ 'width.px': 13, 'height.px': 13 }"
                                        (click)="openLinkedIn(1);$event.stopPropagation()" [tippy]="'Research.Label.LinkedIn' | texttransform"></svg-icon>
                                </div>
                          
                            <div *ngIf="getAccountValue('url') != ''" class="research__url">
                                <div class="research__item__content__search">{{ 'Toolbar.Label.URL' | texttransform }}</div>
                                <div class="research__item__content__url">
                                    <label class="ellipsisText noMargin">{{ getAccountValue('url') }} </label>
                                    <svg-icon src="assets/images/svg/toolbar/toolbar_openurl.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 32, 'height.px': 32 }"
                                        (click)="openURL(getAccountValue('url'));$event.stopPropagation()"></svg-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="research__item" id="researchAccount" *ngIf="applicationInfoService.currentContact != null">
                    <div class="research__item__icon">
                        <div class="research__item__icon__wrapper"><i class="fas fa-user"></i></div>
                    </div>
                    <div class="research__item__content">
                        <div>{{ applicationInfoService.currentContact.firstName | texttransform }} {{ applicationInfoService.currentContact.lastName | texttransform }}</div>
                        <div class="research__item__content__search">{{ 'Toolbar.Label.SearchIn' | texttransform }}</div>
                        <div class="research__item__content__buttons">
                            <div class="research__item__content__buttons__button">
                                <svg-icon src="assets/images/svg/iconset/icon-google.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                                (click)="launchGoogleSearch(4);$event.stopPropagation()" [tippy]="'Research.Label.Google' | texttransform"></svg-icon>
                            </div>
                            <div class="research__item__content__buttons__button">
                                <svg-icon src="assets/images/svg/iconset/icon-xing.svg" class="fingerCursor" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                                (click)="openXing(4);$event.stopPropagation()" [tippy]="'Research.Label.Xing' | texttransform"></svg-icon>
                            </div>
                            <div class="research__item__content__buttons__button">
                                <svg-icon src="assets/images/svg/icon-linkedin.svg" class="fingerCursor"
                                *ngIf="applicationInfoService.applicationSettings['showLinkedIn'] == 'true'" [svgStyle]="{ 'width.px': 13, 'height.px': 13 }"
                                (click)="openLinkedIn(4);$event.stopPropagation()" [tippy]="'Research.Label.LinkedIn' | texttransform"></svg-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

        </div>
    </div>
</div>



import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-component-header',
  templateUrl: './component-header.component.html',
  styleUrl: './component-header.component.scss'
})
export class ComponentHeaderComponent {

  @Input() header:string = "";
  @Input() ellipsisHeader = false;
}

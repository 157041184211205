<div class="contactProtectionContainer" *ngIf="viewMode == 1">
    <div *ngIf="loader" class="globalFontSize apiResponseContainer">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px; color:white"></i>{{
        'General.Label.LoadingData' | texttransform }}
    </div>

    <div *ngIf="apiError" class="globalFontSize apiResponseContainer">
        {{ 'ContactProtection.Label.NoDataAvailable' | texttransform }}
    </div>

    <div class="contactProtectionContainer__control">

        <div class="row">
            <app-main-button type="small" label="ContactProtection.Label.AddProjectEntry"
                (onClick)="createProjectProtection()" svg="plus"></app-main-button>
        </div>

        <div class="row">
            <div class="projectProtectionTable" *ngIf="!loader && listData && !apiError">
                <table mat-table #table [dataSource]="dataSource" [@.disabled]="true">

                    <ng-container matColumnDef="medium">
                        <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Medium' | texttransform}}</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.mediumType.defaultName }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="frequency">
                        <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Frequency' | texttransform}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.frequencyType.defaultName }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Quantity' | texttransform }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.quantity}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="options">
                        <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Options' | texttransform }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="optionsContainer">
                                <app-jj-icons type="editor" [width]="20" [height]="20"
                                    (click)="editProjectProtection(element)"
                                    [tippy]="'ContactProtection.Label.UpdateProjectEntry' | texttransform"
                                    [tippyOptions]="{placement:'left'}"></app-jj-icons>
                                <app-jj-icons type="trash" [width]="20" [height]="20"
                                    (click)="deleteProjectProtection(element)"
                                    [tippy]="'ContactProtection.Label.DeleteContact' | texttransform"
                                    [tippyOptions]="{placement:'bottom'}"></app-jj-icons>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (mouseenter)="highlightRow(row)"
                        (mouseleave)="deselectRow()" [ngClass]="{'highlightedRow' : highlightRowId === row.id}"></tr>
                </table>
            </div>
        </div>
    </div>

</div>


<div class="contactProtectionContainer" *ngIf="viewMode == 0">
    <div *ngIf="loader" class="globalFontSize apiResponseContainer">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px; color:white"></i>{{
        'General.Label.LoadingData' | texttransform }}
    </div>

    <div *ngIf="apiError" class="globalFontSize apiResponseContainer">
        {{ 'ContactProtection.Label.NoDataAvailable' | texttransform }}
    </div>

    <div class="contactProtectionContainer__component">
        <div class="contactProtectionContainer__component__header">
            <app-component-header header="ContactProtection.Label.ContactProtection"></app-component-header>

            <div class="contactProtectionContainer__component__nav">
                <app-main-button type="small" label="ContactProtection.Label.AddContactEntry"
                    (onClick)="addContactProtection()" svg="user-plus"></app-main-button>

                 <app-main-button
                 label="Refresh"
                 type="borderSmall"
                 svg="arrows-counter-clockwise"
                 (onClick)="getApiData()"></app-main-button>   

                <div class="filter">
                    <div class="filterWrapper">
                        <input [(ngModel)]="searchValue"
                            [placeholder]="'ContactProtection.Label.SearchValue' | texttransform"
                            (change)="filterContactList($event, searchValue)">
                        <app-jj-icons type="magnifying-glass" [width]="20" [height]="20"></app-jj-icons>
                    </div>
                </div>
            </div>
        </div>

        <div class="contactProtectionContainer__component__inner">
            <div class="contactProtectionContainer__component__list">
                <div class="contactProtectionTable" *ngIf="!loader && listData && !apiError">
                    <table mat-table #table [dataSource]="dataSource" [@.disabled]="true">
                        <ng-container matColumnDef="icon">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <app-jj-icons [type]="element.contact ? 'user' : 'building'" pointer="false" [width]="20"
                                    [height]="20"></app-jj-icons>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="contact">
                            <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Contact' | texttransform }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="text-container">
                                    <div class="ellipsisCell" *ngIf="element?.contact"
                                        [tippy]="element?.contact?.firstName + ' ' + element?.contact?.lastName"
                                        [tippyOptions]="{placement: 'bottom'}">{{ element?.contact?.firstName }} {{
                                        element?.contact?.lastName }}</div>
                                    <div class="ellipsisCell" *ngIf="!element?.contact" [tippy]="element?.account?.name1"
                                        [tippyOptions]="{placement: 'bottom'}">{{element?.account?.name1}}</div>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="medium">
                            <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Medium' | texttransform}}</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.mediumForTable }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="frequency">
                            <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Frequency' | texttransform}}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.freqForTable }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Quantity' | texttransform }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.quantity}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="comment">
                            <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Comments' | texttransform }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.comment" class="text-container">
                                    <div class="ellipsisCell" [tippy]="element.comment"
                                        [tippyOptions]="{placement: 'bottom'}">
                                        {{element.comment}}
                                    </div>
                                </span>
                                <span *ngIf="!element.comment" [ngStyle]="{'fontWeight': '700px'}">-</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="options">
                            <th mat-header-cell *matHeaderCellDef>{{'ContactProtection.Label.Options' | texttransform }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="optionsContainer">
                                    <app-jj-icons type="editor" [width]="20" [height]="20" (click)="editContact(element)"
                                        [tippy]="'ContactProtection.Label.UpdateContact' | texttransform"
                                        [tippyOptions]="{placement:'bottom'}"></app-jj-icons>
                                    <app-jj-icons type="trash" [width]="20" [height]="20" (click)="deleteContact(element)"
                                        [tippy]="'ContactProtection.Label.DeleteContact' | texttransform"
                                        [tippyOptions]="{placement:'bottom'}"></app-jj-icons>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (mouseenter)="highlightRow(row)"
                            (mouseleave)="deselectRow()" [ngClass]="{'highlightedRow' : highlightRowId === row.id}"></tr>

                    </table>
                    <div *ngIf="dataSource.data.length === 0"> <span>{{ 'ContactProtection.Label.ResultNotFound' |
                            texttransform }}</span></div>
                </div>
            </div>
        </div>
    </div>
</div>








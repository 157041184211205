<div class="contentLibraryItem__wrapper">
    <div class="contentLibraryItem__wrapper__inner">
        <div *ngIf="currentFolder != null" class="currentFolder everleadCoral">
            <app-jj-icons type="arrow-circle-left" [width]="30" [height]="30" (click)="navigateBack()"></app-jj-icons>
            <app-jj-icons type="folder-simple-fill" [width]="30" [height]="30" [pointer]="false"></app-jj-icons>
            <div class="maxWidth">{{ contentLibrary.defaultName }} > {{ currentFolder.name }}</div>
            <app-jj-icons type="folder-open"  (click)="shareFolder()" [tippy]="'ContentLibrary.Label.ShareFolder' | texttransform" ></app-jj-icons>
        
        </div>
        <div *ngIf="currentFolder == null" class="currentFolder everleadCoral">
            <app-jj-icons type="arrow-circle-left" (click)="navigateBack()" [width]="30" [height]="30" [tippy]="'previous page'"></app-jj-icons>
            <app-jj-icons type="folder-simple-fill" [width]="30" [height]="30" [pointer]="false"></app-jj-icons>
            <div class="maxWidth">{{ contentLibrary.defaultName }}</div>
            
        </div>

        <div class="contentLibraryItem__nav">
            <div class="filter">
                <div class="filterWrapper">
                    <input [(ngModel)]="filterString">
                    <app-jj-icons type="magnifying-glass" [width]="20" [height]="20"></app-jj-icons>
                </div>
             </div>
        </div>



        <div *ngIf="folderContent.length == 0" class="maxHeight">
            {{ 'ContentLibrary.Label.NoContentYet' | texttransform }}
        </div>

        <div class="contentLibraryItem__files__header" *ngIf="folderContent.length != 0">
            <div class="ellipsisText">{{ 'ContentLibrary.Label.Name' | texttransform }}</div>
            <div class="contentLibraryItem__isFile__date">{{ 'ContentLibrary.Label.Date' | texttransform }}</div>
            <div class="contentLibraryItem__isFile__user ellipsisText">{{ 'ContentLibrary.Label.User' | texttransform }}</div>
            <div class="contentLibraryItem__isFile__buttons"></div>
        </div>         
        <div class="contentLibraryItem__files">            
            <div *ngFor="let subChild of getFolderContent(folderContent, true)" class="contentLibraryItem__isDirectory fingerCursor contentLibraryItem__name"
            (click)="showContent(subChild.id)" (mouseenter)="highlightRow(subChild.id)" (mouseleave)="highlightRowID= null" [ngClass]="{ hideInDom: isFiltered(subChild), 'contentLibraryItem__highlightRow': subChild.id === highlightRowID  }">
                <app-jj-icons type="folder-simple-fill" [width]="25" [height]="25"></app-jj-icons>
                <div class="ellipsisText">{{ subChild.name }}</div>
                <div class="contentLibraryItem__isFile__date">{{ subChild.createdAt | date:'medium' }}</div>
                <div class="contentLibraryItem__isFile__user ellipsisText">{{ getUser(subChild.createdBy) }}</div>
                <div class="contentLibraryItem__isFile__buttons">
                    <app-jj-icons type="editor" [width]="20" [height]="20" (click)="editFilename(subChild)"></app-jj-icons>
                    <app-jj-icons type="trash" [width]="20" [height]="20" (click)="deleteContentLibraryFile(subChild);$event.stopPropagation()"></app-jj-icons>
                </div>                
                <!-- <input [(ngModel)]="subChild.name" (change)="saveContrnetLibraryItem(subChild)" (click)="$event.stopPropagation()"> -->
            </div>

            <div *ngFor="let subChild of getFolderContent(folderContent, false)" class="contentLibraryItem__isFile" (mouseenter)="highlightRow(subChild.id)" (mouseleave)="highlightRowID= null" [ngClass]="{ hideInDom: isFiltered(subChild), 'contentLibraryItem__highlightRow': subChild.id === highlightRowID }">
                <app-jj-icons [type]="fileSource(subChild)" [width]="30" [height]="30"></app-jj-icons>
                <div class="ellipsisText" [tippy]="subChild.name">{{ subChild.name }}</div>
                <div class="contentLibraryItem__isFile__date">{{ subChild.createdAt | date:'medium' }}</div>
                <div class="contentLibraryItem__isFile__user ellipsisText">{{ getUser(subChild.createdBy) }}</div>
                <div class="contentLibraryItem__isFile__buttons">
                    <app-jj-icons type="file-arrow-down" [width]="20" [height]="20" (click)="downloadFile(subChild)"></app-jj-icons>
                    <app-jj-icons type="editor" [width]="20" [height]="20" (click)="editFilename(subChild)"></app-jj-icons>
                    <app-jj-icons *ngIf="userService.hasPermission('CanDeleteContentLibraryFile')" type="trash" [width]="20" [height]="20" (click)="deleteContentLibraryFile(subChild);$event.stopPropagation()"></app-jj-icons>
                </div>                
            </div>            
        </div> 

        <app-externalfiledragcontrol class="contentLibraryItem__dragDrop" *ngIf="currentFolder != null"
            (selectedFiles)="onFilesSelected($event)"></app-externalfiledragcontrol>     
    </div>    

    <div class="contentFolderSettings" [ngClass]="{ 'contentFolderSettings__extended': settingsExtended }">
        <div class="contentLibraryItem__headerLine" *ngIf="!settingsExtended">
            <app-jj-icons type="arrow-circle-right" (click)="settingsExtended = true"></app-jj-icons>
        </div>
        <div class="contentLibraryItem__headerLine" *ngIf="settingsExtended">
            <app-jj-icons type="arrow-circle-right-fill" (click)="settingsExtended = false"></app-jj-icons>

            <div class="contentLibraryItem__content" *ngIf="currentFolder != null">
                <app-text-input
                label="ContentLibrary.Label.FolderName"
                [(ngModel)]="currentFolder.name"
                (valueChange)="saveContentLibraryItem(currentFolder)"></app-text-input>
            </div>
            <div class="contentLibraryItem__security" *ngIf="currentFolder != null">
                <label class="ellipsisText everleadCoral">{{ 'ContentLibrary.Label.SecuritySettings' | texttransform }}</label>
                <div *ngFor="let userRole of userRoles">
                    <div *ngIf="userRoleAvailable(userRole) == true" class="contentLibraryItem__security__item">
                        <app-checkbox
                        [label]="userRole?.nameTranslationToken?.tokenFullName" 
                        [(ngModel)]="userRole.isChecked"
                        (valueChange)="changeSecuritySetting(userRole)"
                        [disbaled]="userRole.id == applicationInfoService.currentUserRoleId"
                        ></app-checkbox>
                      <!--   <input type="checkbox" [(ngModel)]="userRole.isChecked" (click)="changeSecuritySetting(userRole)" 
                            [disabled]="userRole.id == applicationInfoService.currentUserRoleId" class="contentFolderSettings__checkbox">
                        <label class="ellipsisText">{{ userRole?.nameTranslationToken?.tokenFullName | texttransform }}</label> -->
                    </div>
                </div>
            </div>
            <div class="contentLibraryItem__upload">
                <input type="file" #file accept="*.*" placeholder="Choose file" (change)="uploadFile($event)" style="display:none;" multiple="true">        
                <label *ngIf="currentFolder == null">{{ 'ContentLibrary.Label.IsRootDirectory' | texttransform }}</label>

                <app-main-button
                    svg="folder-simple-plus"
                    label="ContentLibrary.Label.CreateFolder" 
                    (onClick)="newFolder()"></app-main-button>
                <app-main-button
                *ngIf="currentFolder != nul"
                label="ContentLibrary.Label.UploadFile"
                (onClick)="file.click()"
                svg="file-arrow-up"></app-main-button>       
            </div>
        </div>             
    </div>

    <div class="contentLibraryItemPopup" *ngIf="createNewFolder || editFile">
        <div class="contentLibraryItemPopup__folderName" *ngIf="createNewFolder">
            <app-text-input
            label="ContentLibrary.Label.FolderName"
            id="newFolderNameInput"
            [(ngModel)]="newFolderName"></app-text-input>
<!--             <label class="ellipsisText">{{ 'ContentLibrary.Label.FolderName' | texttransform }}</label>
            <input id="newFolderNameInput" class="maxWidth" [(ngModel)]="newFolderName">   -->          
        </div>
        <div class="contentLibraryItemPopup__folderName" *ngIf="editFile">
            <app-text-input
            label="ContentLibrary.Label.FileName"
            [(ngModel)]="fileToEdit.name"
            id="newFolderNameInput"></app-text-input>
         <!--    <label class="ellipsisText">{{ 'ContentLibrary.Label.FileName' | texttransform }}</label>
            <input id="newFolderNameInput" class="maxWidth" [(ngModel)]="fileToEdit.name">    -->         
            
        </div>
        <div class="contentLibraryItemPopup__buttons">
            <app-main-button
            *ngIf="createNewFolder"
            type="borderSmall"
            label="ContentLibrary.Label.CreateFolder"
            svg="folder-simple-plus"
            (onClick)="createNewDirectory()"
            ></app-main-button>

            <app-main-button
            *ngIf="editFile"
            type="borderSmall"
            svg="folder-open"
            label="ContentLibrary.Label.UpdateFolder"
            (onClick)="updateDirectory()" 
            ></app-main-button>

            <app-main-button
            type="borderSmall"
            svg="x"
            label="ContentLibrary.Label.Cancel"
            (onClick)="closeEditWindow()"
            ></app-main-button>


        </div>
    </div>

    <div class="contentLibraryItemPopup" *ngIf="sharePage">
        <div class="contentLibraryItemPopup__folderName">
            <div class="contentLibraryItemPopup__infoBox">
                <app-jj-icons type="info" [width]="20" [height]="20"></app-jj-icons>
                {{ 'ContentLibrary.Label.ShareFolderInfo' | texttransform }}                
            </div>
            <br>
            <app-text-input id="newFolderNameInput" label="ContentLibrary.Label.ShareFolder" [(ngModel)]="currentPageLink"></app-text-input>

        </div>
        <br>
        <div class="contentLibraryItemPopup__buttons"> 
            <app-main-button
            type="borderSmall"
            label="ContentLibrary.Label.CopyToClipboard"
            (onClick)="copyToClipboard(currentPageLink)"
            svg="copy"
            ></app-main-button>
            
            <app-main-button
            type="borderSmall"
            (onClick)="sharePage = false"
            label="ContentLibrary.Label.Cancel"
            svg="x"
            ></app-main-button>
        </div>
    </div>
</div>

<div *ngIf="applicationInfoService.projectSelected && showOnlySettings == true" class="projectSelectionRedWrapper">
    <div *ngIf="applicationInfoService?.availableUserGroups?.length > 1 && applicationInfoService.applicationSettings['showSpecialRoleSelection'] != 'true'"
        class="projectSelectionRedWrapper__line">
        <label
            class="projectSelectionRedLabel">{{ 'AutomaticRecordAssignment.Label.UserGroup' | texttransform }}</label>
        <select (change)="changeUserGroup()" [(ngModel)]="applicationInfoService.currentUserGroup"
            class="projectSelectionRedDropdown">
            <option *ngFor="let userGroup of applicationInfoService.availableUserGroups"
                class="topLevelHeaderDropDownOption" [ngValue]="userGroup.id">
                <span *ngIf="!userGroup.nameTranslationToken">{{ userGroup.defaultName }}</span>
                <span
                    *ngIf="userGroup.nameTranslationToken">{{ userGroup.nameTranslationToken.tokenFullName | texttransform }}</span>
            </option>
        </select>
    </div>
    <div *ngIf="applicationInfoService.projectInfo?.pools?.length > 1 && applicationInfoService.applicationSettings['showSpecialRoleSelection'] != 'true'"
        class="projectSelectionRedWrapper__line">
        <label class="projectSelectionRedLabel">{{ 'TopLevelHeader.Label.Pool' | texttransform }}</label>
        <select [(ngModel)]="applicationInfoService.poolId" class="projectSelectionRedDropdown" (change)="setSessionItem('lastSelectedPool', applicationInfoService.poolId)">
            <option [ngValue]="pool.id" *ngFor="let pool of applicationInfoService.projectInfo?.pools" [ngClass]="{ hideInDom: pool.isActive == false }"
                class="topLevelHeaderDropDownOption">
                {{ pool.defaultName }}
            </option>campaign
        </select>
    </div>
    <div *ngIf="userService?.userRoles?.length > 1 && applicationInfoService.applicationSettings['showSpecialRoleSelection'] != 'true'"
        class="projectSelectionRedWrapper__line">
        <label class="projectSelectionRedLabel">{{ 'TopLevelHeader.Label.Role' | texttransform }}</label>
        <select [(ngModel)]="applicationInfoService.currentUserRoleId" (change)="changeUserRole()"
            class="projectSelectionRedDropdown">
            <option [ngValue]="role.roleId" *ngFor="let role of userService?.userRoles"
                class="topLevelHeaderDropDownOption">
                {{ role.role.nameTranslationToken.tokenFullName | texttransform }}
            </option>
        </select>
    </div>

    <div *ngIf="
            applicationInfoService.applicationSettings['showCampaignMode'] == 'true' &&  
            applicationInfoService.currentUserRoleId != 5 && 
            applicationInfoService.applicationSettings['showSpecialRoleSelection'] != 'true' &&
            userGroupHasCampaignId() == false
        "
        class="projectSelectionRedWrapper__line">
        <label class="projectSelectionRedLabel">{{ 'TopLevelHeader.Label.CampaignMode' | texttransform }}</label>
        <span *ngIf="!allCampaigns">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px;"></i>{{ 'General.Label.LoadingData' | texttransform }}
        </span>
        <select *ngIf="allCampaigns" [(ngModel)]="applicationInfoService.campaingnModeId" class="projectSelectionRedDropdown"
            [title]="applicationInfoService.campaingnModeId" (change)="setSessionItem('lastSelectedCampaignId', applicationInfoService.campaingnModeId)">
            <option [ngValue]="null" class="topLevelHeaderDropDownOption"></option>
            <option [ngValue]="campaign.id" *ngFor="let campaign of allCampaigns"
                class="topLevelHeaderDropDownOption topLevelHeaderCategoryDropDownOption" [title]=campaign.defaultName>
                {{ campaign.defaultName }}
            </option>
        </select>
    </div>

    <div *ngIf="applicationInfoService.applicationSettings['showSpecialRoleSelection'] == 'true'"
        class="projectSelectionRedWrapper__line">
        <label class="projectSelectionRedLabel">{{ 'TopLevelHeader.Label.Special' | texttransform }}</label>
        <select [(ngModel)]="specialRole" (change)="changeSpecialRole()"
            class="projectSelectionRedDropdown">
            <option [ngValue]="role" *ngFor="let role of applicationInfoService?.availableRoles"
                class="topLevelHeaderDropDownOption">
                {{ role.role.nameTranslationToken.tokenFullName | texttransform }} {{ role.roleText | texttransform }}
            </option>
        </select>
    </div>

    <!-- Spezialfall testing -->
    <div *ngIf="applicationInfoService.applicationSettings['showCampaignMode'] == 'true' && applicationInfoService.currentUserRoleId == 5">
        <label class="projectSelectionRedLabel">{{ 'TopLevelHeader.Label.CampaignMode' | texttransform }}</label>
        <select [(ngModel)]="applicationInfoService.campaingnModeId" class="projectSelectionRedDropdown"
          [title]="applicationInfoService.campaingnModeId">
          <option [ngValue]="null" class="topLevelHeaderDropDownOption"></option>
          <option [ngValue]="campaign.id" *ngFor="let campaign of campaigns"
            class="topLevelHeaderDropDownOption topLevelHeaderCategoryDropDownOption" [title]=campaign.defaultName>
            {{ campaign.defaultName }}
          </option>
        </select>
    </div>
</div>

<app-custom-projectoverview [onlyProjects]=true *ngIf="showOnlySettings == false">
</app-custom-projectoverview>

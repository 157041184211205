import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';


@Component({
  selector: 'app-webleads-list',
  templateUrl: './webleads-list.component.html',
  styleUrl: './webleads-list.component.scss'
})
export class WebleadsListComponent implements OnInit{

  constructor(private commonService: CommonService,private methodService: MethodService,){}

  @Input() data = null;
  @Input() isOdd = false;

  leadScoringRound = null;

  ngOnInit(): void {
    this.data = JSON.parse(this.data)
    this.leadScoringRound = Math.round(this.data['_4_leadscoring'])
  }

  navigateTo(route){
    this.methodService.launchInternalMethod(InternalMethodType.NavigateTo, [route]);
  }
}

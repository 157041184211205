import { Component, OnInit, Input, Injector, OnDestroy } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { Subscription } from 'rxjs';
import { ValueType } from 'app/jollyjupiter/service/entity.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-custom-calculateleadstate',
  templateUrl: './custom-calculateleadstate.component.html',
  styleUrls: ['./custom-calculateleadstate.component.scss']
})
export class CustomCalculateleadstateComponent extends CustomControlBaseClass implements OnInit, OnDestroy {
  customEventSubstription: Subscription = new Subscription();
  @Input() controlDefinition: any = null;
  leadStates = []; 
  
  leadStatesOptions=[];

  constructor(
    public injector: Injector,
    private axivasTranslateService: AxivasTranslateService
  ) {
    super(injector);
    this.valueType = null;
  }

  ngOnInit(): void {
    if (this.controlDefinition.uiEntityInstanceId != null) {
      this.controlValue = this.entityService.getEntityValue(this.controlDefinition.uiEntityInstanceId, this.controlDefinition.value, 1);
    }
    this.customEventSubstription = this.eventService.customEvent.subscribe(event => {
      
      if (event.id == 'updateCalculatedLeadscore' && this.controlDefinition.uiEntityInstanceId == 1) {
        this.controlValue = this.entityService.getEntityValue(this.controlDefinition.uiEntityInstanceId, this.controlDefinition.value, 1);
      }

      if (event.id == 'updateContactLeadstateDone') {
        this.controlValue = event.value;        
      }

      if (event.id == 'bookingFormTaskBooked') {
        if (this.controlDefinition.uiEntityInstanceId == 1) {
          this.getAccountLeadState();
        }
      }

      if (event == 'getCompleteProjectInformaton') {
        this.getLeadStates();
      }
    });
    this.getLeadStates();  
  }

  ngOnDestroy(): void {
    if (this.customEventSubstription) { this.customEventSubstription.unsubscribe(); }
  }

  getLeadStates() {    
    const leadStates = this.applicationInfoService.leadstates.toArray();
    this.createLeadStateArray(leadStates);   
  }

  createLeadStateArray(leadStates) {
    this.leadStates = [];
    leadStates.sort((a, b) => a.lookupTable.order < b.lookupTable.order ? -1 : 1);
    leadStates.forEach(leadState => {
      if (leadState.lookupTable.isActive) {
        this.leadStates.push(leadState);
      }
    });

    this.leadStatesOptions = this.leadStates.map((item) => {
      return{
        id: item.lookupTable.id,
        name:this.axivasTranslateService.getTranslationTextForToken(this.commonService.getTranslationValueFromArray(item.lookupTable))
      }
    })

    //console.log()
  }

  updateLeadState() {    
    if (this.applicationInfoService.currentContact == null) { return; }
    this.loaderService.display(true);
    this.externalDatasourceService.executeExternalDataSource(843, [
      this.commonService.getModifyArrayBody({
        id: this.applicationInfoService.currentContact.id,
        leadStateId: this.controlValue
      }, [])
    ]).then(() => {
      this.loaderService.display(false);
      this.getAccountLeadState();
    }).catch(() => { this.loaderService.display(false); });
  }

  getContactLeadstate() {

  }

  getAccountLeadState() {
    this.externalDatasourceService.executeExternalDataSource(844, [this.applicationInfoService.currentAccount.id, 'calculatedLeadStateId id'])
    .then(accountInfo => {
      this.methodService.bookLeadstatusChange(
        this.applicationInfoService.currentAccount.id,
        this.applicationInfoService.currentContact.id, 
        this.controlValue
      );
      this.entityService.updateValuebyType('1', 'calculatedLeadStateId', accountInfo.calculatedLeadStateId, ValueType.Number);        
      this.eventService.customEvent.emit({
        id: 'updateCalculatedLeadscore'
      });
    });
  }

  getLeadStateName(controlValue) {
    const leadState = this.applicationInfoService.leadstates.toArray().find(leadstate => leadstate.lookupTable.id == controlValue);
    if (leadState) {
      return this.commonService.getTranslationValueFromArray(leadState.lookupTable);
    } else {
      return '-';
    }      
  }
}

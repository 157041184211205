import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { MiddlewareSignalrService, PopupMode } from 'app/jollyjupiter/signalr/middleware-signalr-service.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-custom-webphone-popup',
  templateUrl: './custom-webphone-popup.component.html',
  styleUrls: ['./custom-webphone-popup.component.scss']
})
export class CustomWebphonePopupComponent implements OnInit, OnDestroy {
  phonebookControlId = '';
  phonehistoryControlId = '';
  showPopup = false;
  phoneNumber: any;
  popupTitle = '';
  mode: any;
  popupIsClosable = true;
  public popupMode = PopupMode;

  phoneBookInnerHTML = null;
  phoneBookInnerHTMLBase = '<jj-universal controlid=<0>></jj-universal>';
  phoneHistoryInnerHTML = null;
  phoneHistoryInnerHTMLBase = '<jj-universal controlid=<0>></jj-universal>';

  showSoftPhonePopupSubscription: Subscription = new Subscription;
  callNumberEventSubscription: Subscription = new Subscription;
  closeSoftPhonePopupSubscription: Subscription = new Subscription;

  constructor(
    private eventService: EventService,
    private middlewareSignalrService: MiddlewareSignalrService,
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private domSanitizer: DomSanitizer,
  ) {
    this.showSoftPhonePopupSubscription = this.eventService.softPhonePopupEvent.subscribe(event => {
      this.showPopup = true;
      this.popupTitle = event.target;
      this.mode = event.arguments[0];
      this.phoneNumber = '';
      setTimeout(() => {
        this.initControls();
        this.getPhonebook();
        this.getPhoneHistorie();
        const textbox = document.getElementById('phoneNumberInput');
        textbox.focus();
      }, 100);

      //console.log('this.showPopup', this.showPopup)
    });

    this.eventService.callNumberEvent.subscribe(event => {
      console.warn('callNumberEvent', event);
      this.phoneNumber = event;
      this.startCall();
    });

    this.closeSoftPhonePopupSubscription = this.eventService.closeSoftPhonePopupEvent.subscribe(() => {
      this.showPopup = false;
    });
  }

  ngOnInit() {
  }

  initControls() {
    this.phonebookControlId = this.applicationInfoService.applicationSettings['phoneBookControlId'];
    this.phonehistoryControlId = this.applicationInfoService.applicationSettings['phoneHistoryControlId'];
    this.phoneBookInnerHTML = this.domSanitizer.bypassSecurityTrustHtml(
      this.phoneBookInnerHTMLBase.replace('<0>', this.phonebookControlId.toString())
    );
    this.phoneHistoryInnerHTML = this.domSanitizer.bypassSecurityTrustHtml(
      this.phoneHistoryInnerHTMLBase.replace('<0>', this.phonehistoryControlId.toString())
    );
  }

  handleKeyDown(event: any) {
    if (event.keyCode === 13 || event.keyCode === 14) {
      this.startCall();
    }
  }

  getPhonebook() {
    this.middlewareSignalrService.getPhonebook()
    .then(getPhoneBookResult => {
      //console.log('getPhonebook', getPhoneBookResult);
      this.eventService.updateControlData(this.phonebookControlId, [getPhoneBookResult]);
    })
    .catch(error => { console.error('getPhonebook', error); });
  }

  getPhoneHistorie() {
    const historyItems = [];
    this.middlewareSignalrService.getHistory().then(getHistoryResult => {
      //console.log('getPhoneHistorie', getHistoryResult);
      if (!getHistoryResult) {
        return;
      }
      getHistoryResult.currentCallHistory.forEach((historyCall, index) => {
        if (index < getHistoryResult.maximumNumberOfItems) {
          historyItems.push({
            source: historyCall.fromNumber,
            target: historyCall.toNumber,
            calltime: new Date(historyCall.startTime).toLocaleDateString('de-DE').concat(' ',
              new Date(historyCall.startTime).toLocaleTimeString('de-DE'), ' - ',
              new Date(historyCall.endTime).toLocaleTimeString('de-DE')),
            duration: (historyCall.answerTime === null)
              ? ''
              : this.commonService.msToTime(new Date(historyCall.endTime).valueOf() - new Date(historyCall.answerTime).valueOf()),
            toCall: (historyCall.isOutbound) ? historyCall.toNumber : historyCall.fromNumber,
            direction: (historyCall.isOutbound) ? 'fas fa-arrow-circle-right jjColorPositive' : 'fas fa-arrow-circle-left jjColorNegative'
          });
        }
      });
      this.eventService.updateControlData(this.phonehistoryControlId, [historyItems]);
    })
    .catch(error => { console.error('getPhoneHistorie', error); });
  }

  startCall() {
    switch (this.mode) {
      case PopupMode.Call:
        this.middlewareSignalrService.call(this.phoneNumber).then(() => {
          this.eventService.closeSoftPhonePopup();
        })
        .catch(error => { console.error('startCall', error); });
        break;
      case PopupMode.Consult:
        this.middlewareSignalrService.consult(this.phoneNumber).then(() => {
          this.eventService.closeSoftPhonePopup();
        })
        .catch(error => { console.error('startCall', error); });
        break;
    }
  }

  ngOnDestroy() {
    if (this.showSoftPhonePopupSubscription) {  this.showSoftPhonePopupSubscription.unsubscribe(); }
    if (this.closeSoftPhonePopupSubscription) {  this.closeSoftPhonePopupSubscription.unsubscribe(); }
    if (this.callNumberEventSubscription) {  this.callNumberEventSubscription.unsubscribe(); }
  }

  style() {
    if (this.applicationInfoService.isMobile()) {
      return {
        'width': '100vw',
        'height': '100vh',
        'z-index': '500'
      };
    } else {
      return {
        'width': '80vw',
        'height': '80vh',
        'z-index': '500'
      };
    }
  }

  contentstyle() {
    if (this.applicationInfoService.isMobile()) {
      return {
        'min-height': '95vh',
        'max-height': '95vh',
        'z-index': '500'
      };
    } else {
      return {
        'min-height': '80vh',
        'max-height': '80vh',
        'z-index': '500'
      };
    }
  }
}

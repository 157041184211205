import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { LicensingService } from '../licensing.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { LoaderService } from 'app/shared/service/loader-service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-licensing-package-upgradeitem',
  templateUrl: './licensing-package-upgradeitem.component.html',
  styleUrl: './licensing-package-upgradeitem.component.scss'
})

export class LicensingPackageUpgradeitemComponent implements OnInit, OnDestroy {
  customEventSubscription: Subscription = new Subscription();
  @Input() packages = [];
  @Input() sectionName = '';
  @Input() sectionNameAdditionalText = '';
  @Input() showLicenseIntervall = false;
  @Input() showHighlight = false;
  @Input() license = null;

  monthlySubscription = false;
  processingChange = false;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    public licensingService: LicensingService,
    private commonService: CommonService,
    private sharedAPI: SharedAPI,
    private loaderService: LoaderService,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    this.eventService.customEvent.subscribe(event => {
      if (event.id == 'getLicensePendingStatus') {
        this.getPendingStatus();
      }      
    });

    if (this.showLicenseIntervall) {
      this.getPendingStatus();
    }

    if (this.licensingService.licenseDetails.recurring == '1 month') {
      this.monthlySubscription = true;
    }    
    this.monthlySubscription = this.licensingService.isMonthlySubscription();
  }  

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  orderNow(selectedPackage) {
    this.commonService.openUrl(selectedPackage.settingJson.buttonLink);
  }

  showDetails() {
    this.commonService.openUrl(this.applicationInfoService.applicationSettings['packageWebPageDetails']);
  }

  changeForm(id) {
    this.licensingService.upgradePackageForm = id;
  }

  getHighlightRight() {
    let returnValue = '-400px';
    switch (this.licensingService?.currentLicense?.id) {
      case 1: 
        if (this.monthlySubscription == this.licensingService.isMonthlySubscription()) {
          returnValue = '416px';
        }        
        break;
      case 2: 
        if (this.monthlySubscription == this.licensingService.isMonthlySubscription()) {
          returnValue = '216px';
        }
        break;
      case 4:
        if (this.monthlySubscription == this.licensingService.isMonthlySubscription()) {
          returnValue = '16px';
        }
    }
    return returnValue;
  }

  getButtonWidth() {
    return '168px';
  }

  getPendingStatus() {
    this.licensingService.getPendingState().then(getPendingStateResult => {
      if (getPendingStateResult) {
        this.processingChange = getPendingStateResult;
      }
    });
  }

  changePaymentIntervall() {
    this.monthlySubscription = !this.monthlySubscription;    
  }

  changePackage(selectedPackage) {
    this.applicationInfoService.miscSettings['popupFullSize'] = true;
    this.applicationInfoService.miscSettings['licensingAdduserMode'] = 6;
    this.applicationInfoService.miscSettings['licensingChangePackage'] = {      
      packageSectionName: this.sectionName,
      packageDetails: selectedPackage,
      subscription: this.monthlySubscription
    };
    this.eventService.showJjPopup('PackageUpgrade.Label.Change', 'licenseadduser', this.licensingService.mode6Size);  
  }

  getSetupPrice(packageItem) {
    let licensePrice = null;
    licensePrice = packageItem.setupPrice;
    if (this.commonService.isNullOrUndefined(licensePrice)) {
      licensePrice = '';
    } else {
      licensePrice = licensePrice.toString().concat(' ', packageItem.currency?.toUpperCase());
    }
    if (packageItem.settingJson.forceRequest == true) {
      licensePrice = 'PackageUpgrade.Label.RequestPrice';
    }
    return licensePrice;
  }

  getPackagePrice(packageItem) {
    let licensePrice = null;
    if (this.monthlySubscription) {
      licensePrice = packageItem.pricePerMonth;
    } else {
      licensePrice = packageItem.pricePerYear;
    }
    if (this.commonService.isNullOrUndefined(licensePrice)) {
      licensePrice = 'PackageUpgrade.Label.RequestPrice';
    } else {
      licensePrice = licensePrice.toString().concat(' ', packageItem.currency?.toUpperCase());
    }
    if (packageItem.settingJson.forceRequest == true) {
      licensePrice = 'PackageUpgrade.Label.RequestPrice';
    }
    return licensePrice;
  }
}

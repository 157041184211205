<span id="emailtemplatesDesignSpan"></span>
<!-- <div [style.height.px]="uiService.getDesignSpanPosition('emailtemplatesDesignSpan', 40)" ></div> -->
<div class="component">
    <div class="component__header">
        <app-component-header [header]="applicationInfoService.breadCrumbText"></app-component-header>
        <div class="component__nav">
            <app-main-button
            class="buttonMargin"
            type="small"
            svg="magic-wand"
            label="EMailTemplate.Label.NewTemplate"
            (onClick)="createNewTemplate()"
            [disabled]="!userService.hasPermission('CanCreateEmailTemplate')"></app-main-button>
            <app-filter (keyUp)="filterString= $event"></app-filter>
        </div>
    </div>
    <div class="component__inner">
        <div class="emailTemplates">
            <div class="emailContentList">
                <div class="emailContentList__row" *ngFor="let eMailTemplate of eMailTemplates" [ngClass]="{
                    hideInDom: checkIfItemIsFiltered(eMailTemplate),
                    fingerCursor: userService.hasPermission('CanUpdateEmailTemplate')
                }" (click)="showWizard(eMailTemplate)">
                    <div class="emailContentList__content">  
                        <div class="emailContentList__content__id">{{ eMailTemplate.id }}</div>
                        <div class="emailContentList__content__name">{{ eMailTemplate.defaultName }}</div>
                    </div>
                    <!-- <div class="nonExpertView__bonus" [title]="'General._.Edit' | texttransform" (click)="showWizard(eMailTemplate)">            
                        <i class="fas fa-edit fingerCursor dataRightsItem__buttons__button"></i>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrl: './tab.component.scss'
})
export class TabComponent {
  @Input() label: string = null;
  @Input() disabled: boolean = false;
  @Input() active: boolean = null;

  @Input() svg: string = null;
  @Input() svgW: number = null;
  @Input() svgH: number = null;
  @Input() svgC: string = null;

  @Input() options: string = null;

  @Output() onClick = new EventEmitter<any>();


  buttonClick(value?:any){

    if(value){
      this.onClick.emit(value);
    } else {
      this.onClick.emit();
    }
    
  }
}

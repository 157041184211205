<!-- <div [ngStyle]="setDropdownStyle()" class="customTextControlWrapper">
    <label [class]="uiService.getLabelClasses(controlDefinition)" [ngClass]="{ customTextControlWrapper__coralColor : isRequired }">        
        {{ getDisplayText() | texttransform }}
        <span *ngIf="controlDefinition.isRequired"> *</span>
    </label>
    <div *ngIf="loadingControlData==true" style="height: 22px;">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px;"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </div>
    <div *ngIf="loadingControlData==false" class="customTextControlWrapper__valueWrapper">

        <app-extended-dropdown  [ngStyle]="setDropdownStyle()" [data]="data" (onchanged)="onControlValueChanged($event)" *ngIf="externalDataSource != null"
        [disabled]="!checkIfControlIsEnabled()" [controlValue]="controlValue" [dataKey]="externalDataSource?.dataKey" [controlDefinition]="controlDefinition"
        [dataValue]="externalDataSource?.dataValue"></app-extended-dropdown>

        <app-extended-dropdown [ngStyle]="setDropdownStyle()" [data]="data" (onchanged)="onControlValueChanged($event)" *ngIf="externalDataSource == null"
            [disabled]="!checkIfControlIsEnabled()" [controlValue]="controlValue" [entityMemberlookupTable]="entityMemberlookupTable" dataKey="id"
            [controlDefinition]="controlDefinition"
            dataValue="defaultName"></app-extended-dropdown>
    </div>
</div> -->

<div class="fieldSelect">
    <label [ngClass]="{ customTextControlWrapper__coralColor : isRequired }">        
        {{ getDisplayText() | texttransform }}
        <span *ngIf="controlDefinition.isRequired"> *</span>
    </label>
    <div *ngIf="loadingControlData==true" style="height: 22px;">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px;"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </div>
    <div *ngIf="loadingControlData==false" class="fieldSelect__inner">

        <app-extended-dropdown  [ngStyle]="setDropdownStyle()" [data]="data" (onchanged)="onControlValueChanged($event)" *ngIf="externalDataSource != null"
        [disabled]="!checkIfControlIsEnabled()" [controlValue]="controlValue" [dataKey]="externalDataSource?.dataKey" [controlDefinition]="controlDefinition"
        [dataValue]="externalDataSource?.dataValue"></app-extended-dropdown>

        <app-extended-dropdown [ngStyle]="setDropdownStyle()" [data]="data" (onchanged)="onControlValueChanged($event)" *ngIf="externalDataSource == null"
            [disabled]="!checkIfControlIsEnabled()" [controlValue]="controlValue" [entityMemberlookupTable]="entityMemberlookupTable" dataKey="id"
            [controlDefinition]="controlDefinition"
            dataValue="defaultName"></app-extended-dropdown>
    </div>
</div>
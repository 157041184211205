
<div class="agEverleadBorder">
    <div class="agComponentHeader__wrapper">        
        <app-component-header class="agComponentHeader__header" [header]="'Supervisor.Label.Questionaire' | texttransform"></app-component-header>      
        <div class="agComponentHeader__menu" *ngIf="questionaireControlService.selectedQuestionaire == null">
            <div class="agComponentHeader__item">
                <app-jj-icons [type]="'plus-circle'" (click)="questionaireControlService.createQuestionaire()" class="fingerCursor" [width]="20" [height]="20" 
                    [tippy]="'General.Label.CreateNewWithDefault' | texttransform"></app-jj-icons>
                {{ 'QuestionaireDesigner.Label.NewQuestionaire' | texttransform }}
            </div>
            <div class="agComponentHeader__item">
                <input [(ngModel)]="showOnlyActive" type="checkbox">
                {{ 'CampaignDesign.Label.ShowOnlyActive' | texttransform }}  
            </div>
            <div class="agComponentHeader__item">
                <app-jj-icons [type]="'magnifying-glass'" [width]="20" [height]="20"></app-jj-icons>
                <input [(ngModel)]="filterString">
            </div>
        </div>
    </div>    

<div class="questionaireDesigner__wrapper agEverleadBorderInner" *ngIf="questionaireControlService.selectedQuestionaire == null">
    <!-- <div class="questionaireDesigner__header">
        <div class="projectSettings__header">
            <div class="projectSettings__header__newItem">
                <svg-icon src="assets/images/svg/icon-newitem-default.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
                    (click)="questionaireControlService.createQuestionaire()" class="fingerCursor" [title]="'General.Label.CreateNewWithDefault' | texttransform"></svg-icon> 
                {{ 'QuestionaireDesigner.Label.NewQuestionaire' | texttransform }}
            </div>
            <div class="projectSettings__header__interaction">
                <div class="projectSettings__header__showOnlyActive">
                    <input [(ngModel)]="showOnlyActive" type="checkbox">
                    {{ 'CampaignDesign.Label.ShowOnlyActive' | texttransform }}            
                </div>
                <div class="projectSettings__header__filter">
                    <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon> 
                    <input [(ngModel)]="filterString" class="projectSettings__header__showOnlyActive__filter">
                </div>
            </div>
        </div>
    </div> -->
    <div class="questionaireDesigner__content">
        <div class="questionaireDesignerListItem fingerCursor" 
            *ngFor="let questionaire of questionaireControlService.projectQuestionaires" (click)="selectQuestionaire(questionaire)" [ngClass]="{
                hideInDom: (questionaire.isActive == false && showOnlyActive == false) || isItemFiltered(questionaire)
            }">
            <div class="questionaireDesignerListItem__level">
                <svg-icon src="assets/images/svg/account_factory.svg" [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" *ngIf="questionaire.entityId==1"></svg-icon>
                <svg-icon src="assets/images/svg/file-signature.svg" [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" *ngIf="questionaire.entityId==2"></svg-icon>        
                <svg-icon src="assets/images/svg/dashboard_contact.svg" [svgStyle]="{ 'width.px': 36, 'height.px': 36 }" *ngIf="questionaire.entityId==4"></svg-icon>
            </div>
            <div class="questionaireDesignerListItem__defaultName" (click)="$event.stopPropagation()">
                <input [(ngModel)]="questionaire.defaultName" (change)="questionaireControlService.updateQuestionaire(questionaire)">
            </div>
            <div (click)="$event.stopPropagation()" class="questionaireDesignerListItem__entityId">
                <select [(ngModel)]="questionaire.entityId" (change)="questionaireControlService.updateQuestionaire(questionaire)">
                    <option [ngValue]=1>{{ 'Account.Label.Header' | texttransform }}</option>
                    <option [ngValue]=2>{{ 'Task.Label.Header' | texttransform }}</option>
                    <option [ngValue]=4>{{ 'Contact.Label.Header' | texttransform }}</option>
                </select>
            </div>
            <div class="questionaireDesignerListItem__delete" (click)="$event.stopPropagation()">
                <i class="fas fa-trash fingerCursor everleadCoral" (click)="deleteQuestionnaire(questionaire)"></i>
            </div>
        </div>
    </div>
</div>





<div class="questionaireDesigner__wrapper agEverleadBorderInner" *ngIf="questionaireControlService.selectedQuestionaire != null">
    <div class="questionaireDesigner__header">
        <i class="fas fa-arrow-circle-left fa-lg fingerCursor" (click)="questionaireControlService.selectedQuestionaire = null" [tippy]="'General.Label.Back' | texttransform"></i>
        <b>{{ 'General.Label.Questionaire' | texttransform }}</b>
        <div class="questionaireDesigner__header__questionaire">{{ questionaireControlService.selectedQuestionaire.defaultName }}</div>
        <div class="questionaireDesigner__header__newButton">
            <app-main-button type="small" svg="plus" (click)="addQuestion()" [label]="'QuestionaireDesigner.Label.AddQuestion' | texttransform"></app-main-button>
        </div>
    </div>
    <div class="questionaireDesigner__details">
        <div class="questionaireDetail__sectionHeader">
            {{ 'QuestionaireDesigner.Label.GeneralSettings' | texttransform }}
        </div>
        <div class="">
            <div class="questionaireDetail__line">
                <div class="questionaireDetail__line__label">{{ 'QuestionaireDesigner.Label.DefaultName' | texttransform }}</div>
                <div class="questionaireDetail__line__value">
                    <input [(ngModel)]="questionaireControlService.selectedQuestionaire.defaultName" class="maxWidth" 
                        (change)="questionaireControlService.saveQuestionaire()">
                </div>
            </div>
            <div class="questionaireDetail__line">
                <div class="questionaireDetail__line__label">{{ 'QuestionaireDesigner.Label.Level' | texttransform }}</div>
                <div class="questionaireDetail__line__value">
                    <select [(ngModel)]="questionaireControlService.selectedQuestionaire.entityId" class="maxWidth" 
                        (change)="questionaireControlService.saveQuestionaire()">
                        <option [ngValue]=1>{{ 'Account.Label.Header' | texttransform }}</option>
                        <option [ngValue]=2>{{ 'Task.Label.Header' | texttransform }}</option>
                        <option [ngValue]=4>{{ 'Contact.Label.Header' | texttransform }}</option>
                    </select>
                </div>
            </div>    
            <div class="questionaireDetail__line">
                <div class="questionaireDetail__line__label">{{ 'QuestionaireDesigner.Label.IsActive' | texttransform }}</div>
                <div class="questionaireDetail__line__value">
                    <input [(ngModel)]="questionaireControlService.selectedQuestionaire.isActive" type="checkbox" 
                        (change)="questionaireControlService.saveQuestionaire()">
                </div>
            </div>    
        </div>
        <!-- <div class="questionaireDetail__sectionHeader">
            <app-main-button type="small" svg="plus" (click)="addQuestion()" [label]="'QuestionaireDesigner.Label.AddQuestion' | texttransform"></app-main-button>
        </div> -->
        <div class="questionaireDetail__line__questions" cdkDropList [cdkDropListData]="questionaireControlService.selectedQuestionaire.questions" 
            (cdkDropListDropped)="moveInList($event, '0')">            
            <div *ngFor="let question of questionaireControlService.selectedQuestionaire.questions" cdkDrag [cdkDragData]="question" data-html="true">
                <app-questionaire-designer-questioncontrol 
                    *ngIf="question.isDeleted==false"
                    [question]=question 
                    [parent]="questionaireControlService.selectedQuestionaire.questions"
                ></app-questionaire-designer-questioncontrol>
            </div>
        </div>
    </div>
</div>
</div>
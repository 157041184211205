import { Component, OnInit } from '@angular/core';
import { EntitytableService } from '../entitytable.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { SettingsService } from 'app/shared/service/settings.service';

@Component({
  selector: 'app-entitytable-filter',
  templateUrl: './entitytable-filter.component.html',
  styleUrl: './entitytable-filter.component.scss'
})
export class EntitytableFilterComponent implements OnInit {
  dataTableItem = null;
  filterList = [];

  filterOptions = [];

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private entitytableService: EntitytableService,
    private eventService: EventService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.dataTableItem = this.entitytableService.getEntityTableDefinitionForControl(this.applicationInfoService.miscSettings['entityTableLogicalControlId']);
    this.filterOptions = JSON.parse(this.applicationInfoService.applicationSettings['defaultEntityTableFilterOptions']);
    if (this.dataTableItem != null) {
      const filterData = this.applicationInfoService.applicationSettings['defaultEntityTableFilter'.concat(this.dataTableItem.mainEntityId.toString())];
      this.filterList = JSON.parse(filterData);

      if (!this.commonService.isNullOrUndefined(
        this.applicationInfoService.miscSettings['entityTableCustomFilter'.concat(this.dataTableItem.logicalControlId).toString()])) {
          this.filterList = this.applicationInfoService.miscSettings['entityTableCustomFilter'.concat(this.dataTableItem.logicalControlId).toString()];
      }
    }

    if (!this.commonService.isNullOrUndefined(
      this.applicationInfoService.userSettings['entityTableCustomFilter'.concat(this.dataTableItem.logicalControlId).toString()])
    ) {
      this.filterList = JSON.parse(this.applicationInfoService.userSettings['entityTableCustomFilter'.concat(this.dataTableItem.logicalControlId).toString()]);
    }
  }

  getOptions(filter) {
    return this.filterOptions.filter(item => item.target == filter.memberType);
  }

  getInputValues(selectedOption) {
    if (this.commonService.isNullOrUndefined(selectedOption)) {
      return 0;
    }
    const optionItem = this.filterOptions.find(filteritem => filteritem.operator == selectedOption);
    if (optionItem) {
      return optionItem.fields;
    } else {
      return 0;
    }
  }

  performSearch() {
    this.applicationInfoService.miscSettings['entityTableCustomFilter'.concat(this.dataTableItem.logicalControlId).toString()] = this.filterList;
    this.eventService.customEvent.emit({ id: 'refreshEntityTable' });
    this.eventService.closeJjPopup();
  }

  removeFilter() {
    this.applicationInfoService.miscSettings['entityTableCustomFilter'.concat(this.dataTableItem.logicalControlId).toString()] = null;
    this.eventService.customEvent.emit({ id: 'refreshEntityTable' });
    this.eventService.closeJjPopup();
  }

  saveFilter() {
    this.applicationInfoService.userSettings['entityTableCustomFilter'.concat(this.dataTableItem.logicalControlId).toString()] = JSON.stringify(
      this.filterList
    );
    this.settingsService.updateSecurityUserSettingsJson();
  }
}

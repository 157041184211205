import { Injectable } from '@angular/core';
import { ExternaldatasourceService } from './externaldatasource.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: "root",
})
export class ContactProtectionService {
  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService
  ) {}


  getContactProtections(projectId): Promise<any> {
    return new Promise(
      (getAllContactProtectionsResolve, getAllContactProtectionsReject) => {
        this.externaldatasourceService
          .executeExternalDataSource(937, [projectId])
          .then((getAllContactProtectionResult) => {
            getAllContactProtectionsResolve(getAllContactProtectionResult);
          })
          .catch((error) => {
            getAllContactProtectionsReject(error);
          });
      }
    );
  }


  createNewContactProtection(projectId, contact): Promise<any> {
    return new Promise(
      (createContactProtectionResolve, createContactProtectionReject) => {
        this.externaldatasourceService
        .executeExternalDataSource(934, [
           contact.contactId,
           contact.mediumTypeId,
           contact.frequencyLookupId,
           contact.quantity,
           contact.comment,
           contact.accountId
          ])
          .then((createContactProtectionResult) =>
            createContactProtectionResolve(createContactProtectionResult)
          )
          .catch((error) => {
            createContactProtectionReject(error);
          });
      }
    );
  }


  updateContactProtection(contact): Promise<any> {
    return new Promise(
      (updateContactProtectionResolve, updateContactProtectionReject) => {
        this.externaldatasourceService
          .executeExternalDataSource(935, [this.commonService.getModifyArrayBody(contact, [])
          ])
          .then((updateContactProtectionResult) => {
            updateContactProtectionResolve(updateContactProtectionResult);
          })
          .catch((error) => {
            updateContactProtectionReject(error);
          });
      }
    );
  }


  deleteContactProtection(contactId): Promise<any> {
    return new Promise(
      (deleteContactProtectionResolve, deleteContactProtectionReject) => {
        this.externaldatasourceService
          .executeExternalDataSource(936, [contactId])
          .then((deleteContactProtectionResult) => {
            deleteContactProtectionResolve(deleteContactProtectionResult);
          })
          .catch((error) => {
            deleteContactProtectionReject(error);
          });
      }
    );
  }

  getAccountContacts(projectId) {
    return new Promise(
      (getAccountContactsResolve, getAccountContactsReject) => {
        this.externaldatasourceService
          .executeExternalDataSource(492, [projectId])
          .then((getAccountContactsResult) => {
            getAccountContactsResolve(getAccountContactsResult);
          })
          .catch((error) => {
            getAccountContactsReject(error);
          });
      }
    );
  }


  getUnfilteredMediumDefinition(){
    return this.commonService.getProjectLookupTableValues(16, 'central')
  }

  getFilteredMediumRefDefinition(){
    return this.commonService.getProjectLookupTableValues(16, 'central').filter((item) => this.checkLookupTableTofilter(item.defaultName));
  }

  getIntervalDefinition(){
   return this.commonService.getProjectLookupTableValues(104, 'central')

  }

  checkLookupTableTofilter(name){
    switch (name) {
      case "Umfragen":
        return false;
      case "SocialMedia":
        return false;
      case "News":
        return false;
      case "AllMedia":
        return false;
      default:
        return true;
    }
  }



  // * Contact Protection Projects Api

  /*   getContactProtectionById(id): Promise<any>{
    return new Promise((getContactByIdResolve, getContactByIdReject) => {
      this.externaldatasourceService.executeExternalDataSource(0, [id])
      .then((getContactIdResult) => {
        getContactByIdResolve(getContactIdResult)
      })
      .catch((error) => {
        getContactByIdReject(error);
      });
      }
    );
    } */

  

  getProjectContactProtection(projectId): Promise<any> {
    //console.log('GET Project Protectionservice',projectId)
    return new Promise(
      (getAllContactProtectionsResolve, getAllContactProtectionsReject) => {
        this.externaldatasourceService
          .executeExternalDataSource(944, [projectId])
          .then((getAllContactProtectionResult) => {
            getAllContactProtectionsResolve(getAllContactProtectionResult);
          })
          .catch((error) => {
            getAllContactProtectionsReject(error);
          });
      }
    );
  }


    createProjectProtection(projectId, project): Promise<any> {
      return new Promise(
        (createProjectProtectionResolve, createProjectProtectionReject) => {
          this.externaldatasourceService
          .executeExternalDataSource(945, [
             project.mediumTypeId,
             project.frequencyTypeId,
             project.quantity,
            ])
            .then((createProjectProtectionResult) =>
              createProjectProtectionResolve(createProjectProtectionResult)
            )
            .catch((error) => {
              createProjectProtectionReject(error);
            });
        }
      );
    }

    updateProjectProtection(project): Promise<any> {
      return new Promise((updateProjectProtectionResolve, updateProjectProtectionReject) =>{
        this.externaldatasourceService.executeExternalDataSource(946, [project.id, project.quantity])
        .then((updateProjectProtectionResult) => updateProjectProtectionResolve(updateProjectProtectionResult))
        .catch((error) => {
          updateProjectProtectionReject(error);
        })
      });
    }

    

    deleteProjectProtection(rowId): Promise<any> {
      return new Promise((deleteProjectProtectionResolve, deleteProjectProtectionReject) =>{
        this.externaldatasourceService.executeExternalDataSource(947, [rowId])
        .then((deleteProjectProtectionResult) =>{
          deleteProjectProtectionResolve(deleteProjectProtectionResult)
        }).catch(error =>{
          deleteProjectProtectionReject(error)
        })
      })
    }

}


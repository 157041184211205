import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-blue-marker',
  templateUrl: './blue-marker.component.html',
  styleUrl: './blue-marker.component.scss'
})
export class BlueMarkerComponent {

  @Input() label: string = null;
  @Input() svg: string = null;
  @Input() svgW: number = null;
  @Input() svgH: number = null;
  @Input() field: boolean= false;
  @Input() toggle:boolean = false;
  @Input() disabled: boolean = false;
  @Input() marginTop: string = '-1px'
}

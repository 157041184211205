<div class="dataFilterGroupItemContainer">    
  <div class="dataFilter__criteria__header" *ngIf="!disabled">
    <!-- <div class="dataFilter__criteria__header__buttons">
      <button class="dataFilter__criteria__header__buttons__button" *ngIf="disabled == false"
        (click)="createSegmentMember()"><i class="fas fa-plus-square"></i></button>      
      <label>{{ 'DataFilterGroupItem.Label.Header' | texttransform }}</label>
    </div>       -->
    <div class="dataFilter__criteria__header__operator" *ngIf="disabled == false">

<!--       <app-select-input
      label="operator"
      [(ngModel)]="filterSegment.lookupSegmentOperatorId"
      bind="id"
      display="defaultName"
      [options]="filterSegmentOperators"></app-select-input>  -->
      <!-- <app-jj-icons type="trash"(click)="deleteFilterSegment()" [width]="20" [height]="20"></app-jj-icons>  --> 

    <label>Operator</label>   
      <select (change)="changeOperator()" [(ngModel)]="filterSegment.lookupSegmentOperatorId">
        <option *ngFor="let filterSegmentOperator of filterSegmentOperators" [ngValue]="filterSegmentOperator.id">{{ filterSegmentOperator.defaultName }}</option>
      </select>       
    </div>
    <app-jj-icons type="trash" (click)="deleteFilterSegment()" *ngIf="disabled == false" [width]="20" [height]="20"></app-jj-icons>   
   <!--  <button  (click)="deleteFilterSegment()" class="dataFilter__criteria__header__buttons__button everleadCoral" *ngIf="disabled == false">
      <i class="fas fa-trash" style="margin: 0 10px 0 0;"></i>
    </button> -->
  </div>

  <div *ngFor="let filterSegmentMember of filterSegment.filterSegmentMembers; let i = index">
    <app-datafilter-groupitem-member
      [filterSegmentMember]="filterSegmentMember"
      [filterSegment]="filterSegment"
      [filter]="filter"
      [emailBatches]="emailBatches"
      [memberEntities]="memberEntities"
      [operators]="operators"
      [disabled]="disabled"
    ></app-datafilter-groupitem-member>
  </div>
  <div class="nonExpertView__newSubGroup" *ngIf="disabled == false">

   <!--  <app-main-button 
      type="borderSmall"
      svg="plus"
      (onClick)="createSegmentMember()"
      label="DataFilterMainPanel.Label.NewFilterSegmentMember"
     ></app-main-button> -->
<!--      <app-main-button 
     type="borderSmall"
     label="DataFilterMainPanel.Label.NewFilterSegmentMember" 
     (onClick)="createSegmentMember()"></app-main-button> -->
    <i (click)="createSegmentMember()" class="fas fa-plus fingerCursor"></i> 
    <label (click)="createSegmentMember()" class="fingerCursor">{{ 'DataFilterMainPanel.Label.NewFilterSegmentMember' | texttransform }}</label> 
  </div>
</div>

<div class="projectTarget">
    <app-text-input label="GeneralSettings.Setting.ProjectName" [(ngModel)]="data.projectName"
        (valueChange)="saveSettings()"></app-text-input>

    <app-number-input label="GeneralSettings.Setting.CountOfNetcallsPerHour" [(ngModel)]="data.countOfNetcallsPerHour"
        (valueChange)="saveSettings()"></app-number-input>

    <app-number-input label="GeneralSettings.Setting.NetCallTarget" [(ngModel)]="data.netCallTarget"
        (valueChange)="saveSettings()"></app-number-input>

    <app-number-input label="GeneralSettings.Setting.LeadQuota" [(ngModel)]="data.leadQuota"
        (valueChange)="saveSettings()"></app-number-input>

    <app-text-input label="GeneralSettings.Setting.CountFteOperational" [(ngModel)]="data.countFteOperational"
        (valueChange)="saveSettings()"></app-text-input>

    <app-text-input label="GeneralSettings.Setting.CountProjectHours" [(ngModel)]="data.countProjectHours"
        (valueChange)="saveSettings()"></app-text-input>

    <app-date-input label="GeneralSettings.Setting.TargetStart" [(ngModel)]="data.targetStart"
        (dateChange)="saveSettings()" parent="generalSettings"></app-date-input>

    <app-date-input label="GeneralSettings.Setting.TargetEnd" [(ngModel)]="data.targetEnd" (dateChange)="saveSettings()"
        parent="generalSettings"></app-date-input>

    <app-select-input label="GeneralSettings.Setting.ReportTypeLookup" [(ngModel)]="data.reportTypeLookupId" bind="id"
        display="defaultName" [options]="reportTypeLookups" (valueChange)="saveSettings()"></app-select-input>

    <app-text-area-input label="GeneralSettings.Setting.Comments" [(ngModel)]="data.comments"
        (valueChange)="saveSettings()"></app-text-area-input>

    <app-main-button type="small" label="GeneralSettings.Setting.DeleteTarget" svg="trash"
        (onClick)="deleteTarget(data)"></app-main-button>

</div>
import { Component , Injectable, Input} from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService
  ){}

  @Input() header: string = "";
  @Input() ellipsisHeader = false
  @Input() locked: boolean | null = false;
  @Input() enrich: boolean | null = false;
  @Input() entityId = null;

  tippyLockProps={
    placement:'bottom',
    trigger: 'mouseenter',
    theme:'light-border',
    touch:true,
    interactive:true,
    appendTo:'parent',
  }

  enrichDataWithExternalInterface() {
    this.applicationInfoService.miscSettings['enrichDataWithExternalInterfaceEntityId'] = this.entityId;
    switch (this.entityId) {
      case 1:
        this.applicationInfoService.miscSettings['enrichDataWithExternalInterfaceRefId'] = this.applicationInfoService.currentAccount.id;
        break;
      case 4:
        this.applicationInfoService.miscSettings['enrichDataWithExternalInterfaceRefId'] = this.applicationInfoService.currentContact.id;
        break;
    }    
    this.eventService.showJjPopup('DataInterface.Label.EnrichDataWithExternalInterface', 'echobotinterface', '90');
  }
}

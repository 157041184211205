<div class="contentLibraryPage">
    <div class="contentLibraryPage__header">
        <app-component-header [header]="applicationInfoService.breadCrumbText"></app-component-header>
        <div class="contentLibraryPage__nav">
            <app-main-button type="small" label="ContentLibrary.Label.NewLibrary" svg="folder-simple-plus"
                [tippy]="'General.Label.CreateNewWithDefault' | texttransform"
                [tippyOptions]="{theme:'everleadBlue', placement:'bottom'}" (onClick)="prepareCreation()"
                [disabled]="selectedContentLibrary != null && !userService.hasPermission('CanCreateContentLibrary')"></app-main-button>

            <div class="filter">
                <div class="filterWrapper">
                    <input [(ngModel)]="filterString">
                    <app-jj-icons type="magnifying-glass" [width]="20" [height]="20"></app-jj-icons>
                </div>
            </div>
        </div>
    </div>

    <div class="contentLibraryPage__inner">
        <div class="contentLibraryPage__content">
            <div class="contentLibraryPage__wrapper" *ngIf="selectedContentLibrary == null && 
        userService.hasPermission('CanReadSecurityRoleContentLibraryFile') &&
        userService.hasPermission('CanReadContentLibrary')
    ">
                <div class="contentLibraryPage__selectedTopFolderHeader"> {{ 'ContentLibrary.Label.AvailableLibraries' |
                    texttransform }}</div>
                <div *ngFor="let contentLibrary of contentLibraries" (mouseenter)="highlightRowID = contentLibrary.id"
                    (mouseleave)="highlightRowID = null" (click)="loadContentLibrary(contentLibrary)" [ngClass]="{ 
                fingerCursor: !editMode,
                hideInDom: isItemFiltered(contentLibrary),
                'contentLibraryPage__highlightRow': contentLibrary.id === highlightRowID
        }">
                    <div class="contentLibraryPage__item">
                        <app-jj-icons
                            [type]="(userService.hasPermission('CanReadSecurityRoleContentLibraryFile') && userService.hasPermission('CanReadContentLibrary')) ? 'folder-simple-fill' : 'folder-simple-lock-fill'"
                            [width]="25" [height]="25"></app-jj-icons>
                        <label class="ellipsisText fingerCursor">{{ contentLibrary.defaultName }}</label>
                        <app-jj-icons type="editor" [width]="20" [height]="20"
                            (click)="editContentLibrary(contentLibrary);$event.stopPropagation()"></app-jj-icons>
                    </div>
                </div>
            </div>

            <div class="contentLibraryPage__wrapper__items" *ngIf="libraryItems != null">
                <app-contentlibrary-item class="maxWidth" [start]="libraryItems"
                    [startiid]="startIId"></app-contentlibrary-item>
            </div>

            <div class="contentLibraryItemPopup" *ngIf="createLibrary || editLibrary">
                <div class="contentLibraryItemPopup__folderName">
                    <app-text-input label="ContentLibrary.Label.LibraryName" [(ngModel)]="libraryObject.defaultName"
                        id="newFolderNameInput"></app-text-input>
                </div>
                <div class="contentLibraryItemPopup__buttons">
                    <app-main-button type="borderSmall" *ngIf="createLibrary" svg="folder-simple-plus"
                        label="ContentLibrary.Label.CreateLibrary" [disabled]="libraryObject.defaultName.length < 3"
                        (onClick)="createContentLibrary()"></app-main-button>

                    <app-main-button type="borderSmall" svg="folder-open" *ngIf="editLibrary"
                        label="ContentLibrary.Label.UpdateLibrary"
                        (onClick)="updateContentLibrary(libraryObject)"></app-main-button>

                    <app-main-button type="borderSmall" svg="x" label="ContentLibrary.Label.Cancel"
                        (onClick)="closeEditWindow()"></app-main-button>

                </div>
            </div>

        </div>
    </div>
</div>
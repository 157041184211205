import { Component, OnInit, Injector, AfterViewInit, OnDestroy } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from 'app/jollyjupiter/service/entity.service';

@Component({
  selector: 'app-custom-textarea',
  templateUrl: './custom-textarea.component.html',
  styleUrls: ['./custom-textarea.component.scss']
})
export class CustomTextareaComponent extends CustomControlBaseClass implements OnInit, AfterViewInit, OnDestroy {
  aiReplacementText = null;
  aiGenerating = false;
  popupOpen = false;

  constructor(
    private injector: Injector ,
  ) {
    super(injector);
    this.valueType = ValueType.String;
  }

  ngOnInit() {
    if (this.entity != null) {
      this.controlValue = this.entityService.getEntityValue(this.entity, this.controlDefinition.value, 1);
    }
    this.checkAttributes();
  }
  
  ngAfterViewInit(): void {
    this.appendTextAreaPopupToBody();      
  }

  ngOnDestroy(): void {
    this.removeTextAreaPopupToBody();
  }

  getTextAreaHeight() {    
    return (Number(this.uiService.getControlDimensionWithoutAttribute(this.controlDefinition.height)) - 32) + 'px';
  }

  getContactAiText(promptId) {
    this.aiGenerating = true;
    let accountId = this.applicationInfoService.currentAccount.id;
    let contactId = null;
    let taskId = null;
    if (this.applicationInfoService.currentContact != null) {
      contactId = this.applicationInfoService.currentContact.id;
    }
    if (this.applicationInfoService.currentTask != null) {
      taskId = this.applicationInfoService.currentTask.id;
    }
    this.externalDatasourceService.executeExternalDataSource(780, [
      accountId,
      contactId,
      promptId,
      taskId,
      null
    ])
    .then(result => {
      if (result) {
        if (result.responseText != null) {
          result.responseText = this.commonService.removeQuotes(result.responseText);
        }
        this.aiReplacementText = result.responseText;
        this.aiGenerating = false;
      };
    });  
  }

  acceptAiText() {
    this.controlValue = this.aiReplacementText;
    this.callMethod('onchange');
    this.aiReplacementText = null;
  }

  cancelAiText() {
    this.aiReplacementText = null;
  }

  getFontSize() {
    let returnValue = '16';
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['notesTextareaFontSize'])) {
      returnValue = this.applicationInfoService.applicationSettings['notesTextareaFontSize'];
    }
     return returnValue;
  }

  textAreaPopupId() {
    return 'textArea'.concat(this.controlDefinition.id,'_popup');
  }
  
  removeTextAreaPopupToBody() {
    const popup = document.getElementById(this.textAreaPopupId());    
    document.body.removeChild(popup);
  }
  
  appendTextAreaPopupToBody() {
    const popup = document.getElementById(this.textAreaPopupId());    
    if (popup) {
      document.body.append(popup);
    }    
  }
}

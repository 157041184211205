import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { _getOptionScrollPosition } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
  @Input() refId = 0;
  @Input() entityId = 0;
  @Input() onlySettings = false;
  auditDefinitions = [];
  entities = [];
  member = [];
  auditSource = 1;
  mode = 1;
  users = [];
  auditRefId = null;
  auditEntityId = null;
  loadingData = false;
  displayedColumns = ['field', 'oldValue', 'newValue', 'user', 'source', 'date', 'button'];
  displayedColumnsDefinitions = ['entity', 'member', 'start', 'end', 'button'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  dataSourceDefinitions: MatTableDataSource<any> = new MatTableDataSource<any>();
  filterStringDefinition = '';
  queryAuditsByRefIdExternalDatasourceId = 557;
  auditValues = [];

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private methodService: MethodService,
    public userService: UserService,
    private axivasTranslateService: AxivasTranslateService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.mode = 2;
    this.getEntities();
    this.getAuditDefinitions();
    this.getUserList();
    if (this.applicationInfoService.currentAccount != null) {
      if (this.applicationInfoService.currentAccount.isDummyAccount == true) {
        this.setAuditSource(2);
      } else {
        this.setAuditSource(1);
      }
    } else {
      this.setAuditSource(2);
    }

    if (this.onlySettings) {
      this.mode = 1;
    }
  }

  getEntities() {
    const tempEntityIds = [];
    const tempEntities = this.applicationInfoService.entities.toArray();
    tempEntities.forEach(item => {
      if (!this.commonService.checkIfItemIsInArray(tempEntityIds, item.id)) {
        this.entities.push(item);
        this.entities=this.entities.map((item) => {
          return{
            ...item,
            displayName: item.nameTranslationToken ? item.nameTranslationToken.tokenFullName : item.defaultName
          }
        })
        tempEntityIds.push(item.id);
        this.member[item.id] = item.entityMembers;
      }
    });
  }

  getAuditDefinitions() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(346, [])
    .then(getAuditDefinitionsResult => {
        this.auditDefinitions = getAuditDefinitionsResult;
        this.dataSourceDefinitions = new MatTableDataSource(getAuditDefinitionsResult);
    })
    .finally(() => {
      this.loaderService.display(false);
    });
  }

  getEntityMember(entity) {
    let returnValue = this.member[entity];
    console.log('returnValue', returnValue)
    if (this.commonService.isNullOrUndefined(returnValue)) {
      returnValue = [];
    }

    returnValue = returnValue.map((item)=>{
      return{
        ...item,
        displayName: item.nameTranslationToken ? item.nameTranslationToken.tokenFullName : item.defaultName
      }
    })
    return returnValue;
  }

  saveAuditDefinition(definition) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(347, [
      this.commonService.getModifyArrayBody(definition, ['projectId'])
    ]).finally(() => {
      this.loaderService.display(false);
    });
  }

  createAuditDefinition() {
    const definition = new Object();
    definition['projectId'] = this.applicationInfoService.projectInfo.id;
    definition['entityId'] = 1;
    definition['entityMemberId'] = 1;
    definition['auditStart'] = new Date(Date.now());
    definition['auditEnd'] = new Date('2099-12-24');
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(348, [
      this.commonService.getModifyArrayBody(definition, [])
    ])
    .then(() => {
      this.getAuditDefinitions();
    })
    .finally(() => {
      this.loaderService.display(false);
    });
  }

  deleteAuditDefinition(definition) {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(349, [definition.id])
    .then(() => {
      this.getAuditDefinitions();
    })
    .finally(() => {
      this.loaderService.display(false);
    });
  }

  getAuditValues() {
    this.auditValues = [];
    this.loadingData = true;
    this.externaldatasourceService.executeExternalDataSource(this.queryAuditsByRefIdExternalDatasourceId, [
      this.auditEntityId,
      this.auditRefId
    ]).then(getAuditValuesResult => {
      getAuditValuesResult.sort((a, b) => a.timestamp > b.timestamp ? -1 : 1);
      this.loadingData = false;
      this.dataSource = new MatTableDataSource(getAuditValuesResult);
      this.auditValues = getAuditValuesResult;
    })
    .catch(error => {
      this.loadingData = false;
      console.error('getAuditValues', error);
    });
  }

  getEntityMemberName(entityId, entityMemberId) {
    const entityMember = this.applicationInfoService.entities.Item(entityId.toString()).entityMembers.find(member => member.id == entityMemberId);
    if (this.commonService.isNullOrUndefined(entityMember)) {
      return 'member not found';
    } else {
      if (this.commonService.isNullOrUndefined(entityMember.nameTranslationToken)) {
        return entityMember.defaultName;
      } else {
        return entityMember.nameTranslationToken.tokenFullName
      }
    }
  }

  getUserName(userId) {
    let returnValue = '';
    const user = this.users.find(user => user.id == userId);
    if (user) {
      returnValue = user.userName;
    }

    return returnValue;
  }

  getUserList() {
    this.externaldatasourceService.executeExternalDataSource(139)
    .then(getUserListResult => {
      this.users = getUserListResult;
    })
  }

  revertItem(auditValue) {
    let message = this.axivasTranslateService.getTranslationTextForToken('Audit.RevertQuestion.Message');
    message = message.replace('<0>', this.axivasTranslateService.getTranslationTextForToken(
      this.getEntityMemberName(auditValue.entityId, auditValue.entityMemberId))
    );
    message = message.replace('<1>', auditValue.oldValueText);
    this.confirmationService.confirm({
      message: message,
      header: this.axivasTranslateService.getTranslationTextForToken('Audit.RevertQuestion.Header'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loaderService.display(true);
        this.methodService.launchInternalMethod(InternalMethodType.RollbackAuditValue, [auditValue])
        .then(() => {
          this.loaderService.display(false);
          this.getAuditValues();
        })
        .catch(error => {
          console.error('revertItem', error);
          this.loaderService.display(false);
        });
      }
    });

  }

  setAuditSource(source) {
    if (source == 1 && this.applicationInfoService.currentAccount != null) {
      this.auditEntityId = 1;
      this.auditRefId = this.applicationInfoService.currentAccount.id;
      this.getAuditValues();
    }
    if (source == 2 && this.applicationInfoService.currentContact != null) {
      this.auditEntityId = 4;
      this.auditRefId = this.applicationInfoService.currentContact.id;
      this.getAuditValues();
    }
    if (source == 3) {
      this.auditValues = [];
    }
    this.auditSource = source;
  }

  itemIsFiltered() {
    return false;
  }

  definitionItemIsFiltered(row) {
    let returnValue = false;
    let entity = null;
    let member = null;
    let checkValueEntity = '';
    let checkValueMember = '';

    if (this.filterStringDefinition != '') {
      entity = this.applicationInfoService.entities.Item(row.entityId);
      member = this.applicationInfoService.entityMember.Item(row.entityMemberId);
      if (member) {
        checkValueMember = member.defaultName;
        if (member.nameTranslationToken != null) {
          checkValueMember = this.axivasTranslateService.getTranslationTextForToken(member.nameTranslationToken.tokenFullName);
        }
      }

      if (entity) {
        checkValueEntity = entity.entityName;
        if (entity.nameTranslationToken != null) {
          checkValueEntity = this.axivasTranslateService.getTranslationTextForToken(entity.nameTranslationToken.tokenFullName);
        }
      }
      if (
          !this.commonService.checkIfStringContainsString(checkValueMember, this.filterStringDefinition) &&
          !this.commonService.checkIfStringContainsString(checkValueEntity, this.filterStringDefinition)
        ) {
        returnValue = true;
      }
    }
    return returnValue;
  }
}

import { Component } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrl: './mobile-header.component.scss'
})
export class MobileHeaderComponent {
  constructor(
    public applicationInfoService: ApplicationInfoService
  ) {}

  expandMobileNavigation() {
    this.applicationInfoService.navigationExpandedMobile = true;
    this.applicationInfoService.navigationExpanded = true
  }
}

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { EChartsOption } from 'echarts';

import { Subscription } from 'rxjs';


@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, OnDestroy, OnChanges {
  
  constructor(
    private commonService: CommonService, 
    private axivasTransateService: AxivasTranslateService,
    private eventService: EventService
  ){}
  
  
  customEventSubscription: Subscription = new Subscription();
  @Input() colors: string[]=[];
  @Input() chartData: any=[];
  @Input() height: any;
  @Input() width: any;
  @Input() toolboxInfo: boolean = false;
  @Input() xAxis: boolean = false;
  @Input() yAxis: boolean = true;
  @Input() component: string | null= null;
  @Input() controlid: string | number = null;

  // Consent
  @Input() consentRef: number = 1;

  options:EChartsOption={};
  dataAvailable 
  showInfo 

  chart:any | null

  
  ngOnInit(): void {
    
    this.customEventSubscription =this.eventService.customEvent.subscribe((event) =>{
    })

    if(this.controlid === 'dataclosed'){
      this.createReportProjectOverviewChart()
     }

     if(this.controlid === 'dataprogress'){
      this.createReportProjectOverviewChart()
     }

     if(this.component === 'contact_Objections'){
 /*      console.log('changes ->')
      console.log('this.chartData', this.chartData); */
      this.createReportProjectOverviewContact();
     }
    
     if(this.component === 'mailshotKPIChart'){
      this.createMailshotKPIChart();
     }

     if(this.component === 'agentwvl'){
      this.createAgentWVLChart();
     }

     if(this.component === 'agentwvloverall'){
      this.createAgentWVLChartOverall();
     }

     if(this.component === 'dashboard_activity'){
      this.createReportDashboardActivity()
     }

     if(this.component === 'consentDashboardBar'){
      this.createReportDashboardConsent();
     }

     if(this.component === 'campaignDashboard'){
      if(this.controlid === 'mailcampaign'){
        this.createMailCampaignChart()
      }

      if(this.controlid === 'microcampaign'){
        this.createMicroCampaignChart()
      }
     }

     if(this.component === 'entityTable'){
      if(this.controlid === 'prospects'){
        this.createProspectChart()
      }
     }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData'] && !changes['chartData'].firstChange) {
       this.chartData = changes['chartData'].currentValue
       if(this.controlid === 'dataclosed'){
        this.createReportProjectOverviewChart()
       }

       if(this.controlid === 'dataprogress'){
        this.createReportProjectOverviewChart()
       }

       if(this.component === 'contact_Objections'){
       /*  console.log('changes ->')
        console.log('this.chartData', this.chartData); */
        this.createReportProjectOverviewContact();
       }

       if(this.component === 'agentwvl'){
        this.createAgentWVLChart();
       }
       
       if(this.component === 'dashboard_activity'){
        this.createReportDashboardActivity()
       }

       if(this.component === 'campaignDashboard'){
        if(this.controlid === 'mailcampaign'){
          this.createMailCampaignChart()
        } 
        if(this.controlid === 'microcampaign'){
          this.createMicroCampaignChart()
        }
        
       }

       if(this.component === 'entityTable'){
        if(this.controlid === 'prospects'){
          this.createProspectChart();
        }
       }

      //  if(this.component === 'consentDashboardBar'){
      //   this.createReportDashboardConsent();
      //  }  
    }
  }



  // todo
  createMicroCampaignChart(){}



  createProspectChart(){
    this.options = {
      //...this.colors,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
    xAxis:{
        show:true, 
        type:'value',
        axisTick:{
          show:false,
        },
        axisLine:{
          show:false,
        },
        axisLabel:{
          show:true,
        }
      },

    yAxis:{ 
        show:true,
        type:'category',
        data: this.chartData.map((item) => item.companyname).reverse(),
        axisLabel:{
          show:true,
          color:'#788BFF',
        },
        axisTick:{
          show:false,
        },
      },
      grid:{
        top:10,
        bottom:10,
        left:0,
        containLabel: true
      },
      series:[{
        data: this.chartData?.map((bar:any, index:any) => {
        return{
          name: bar.companyname,
          type:'bar',
          value: Math.floor(bar?.leadscore * 10) / 10 ,
          itemStyle:{
            color:bar?.color ? bar.color : '#5465FF',
            //color: this.colors.length > 0 ? this.colors[index] : '#5162F6'
          }
         }
        }).reverse(),
          type:'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'right',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: 'black',
            rich: {
              name: {}
            },
          },
        }]

  }

  }



  createMailCampaignChart(){

    this.options = {
      //...this.colors,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
    xAxis:{
        show:true, 
        type:'category',
        axisTick:{
          show:false,
        },
        axisLine:{
          show:false,
        },
        axisLabel:{
          show:false,
        }
      },

    yAxis:{ 
        show:true,
        type:'value',
        axisLabel:{
          show:false,
        }
      },
      grid:{
        top:0,
        left:0,
        right:0,
        bottom:0,
      },
      series:[{
        data: this.chartData?.map((bar:any, index:any) => {
        return{
          name: this.axivasTransateService.getTranslationTextForToken(bar?.name),
          type:'bar',
          value: bar?.value,
          itemStyle:{
            color:bar?.color ? bar.color : '#5465FF',
            //color: this.colors.length > 0 ? this.colors[index] : '#5162F6'
          }
         }
        }),
          type:'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'top',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: 'black',
            rich: {
              name: {}
            },
          },
        }]

  }
  }

  createAgentWVLChartGetData(section, arraValue) {
    let dataArray = [];    
    this.chartData.forEach(dataItem => {
      dataArray.push(dataItem[arraValue]);
    })    
    let returnValue = { 
      name: section, 
      type: 'bar', 
      stack: 'total',
      label: { show: true },
      emphasis: { focus: 'series' },
      data: dataArray
    }
    return returnValue;
  }

  createAgentWVLChart() {  
    const dataSeries = [];    
    let yAxisData = [];    
    this.chartData.forEach(dataItem => {
      yAxisData.push(dataItem.user_name);
    });
    dataSeries.push(this.createAgentWVLChartGetData(this.axivasTransateService.getTranslationTextForToken('DashboardWVL.Label.Past'), 'past'));     
    dataSeries.push(this.createAgentWVLChartGetData(this.axivasTransateService.getTranslationTextForToken('DashboardWVL.Label.Today'), 'today'));         
    dataSeries.push(this.createAgentWVLChartGetData(this.axivasTransateService.getTranslationTextForToken('DashboardWVL.Label.NearFuture'), 'near_future'));         
    dataSeries.push(this.createAgentWVLChartGetData(this.axivasTransateService.getTranslationTextForToken('DashboardWVL.Label.Future'), 'future'));         
    dataSeries.push(this.createAgentWVLChartGetData(this.axivasTransateService.getTranslationTextForToken('DashboardWVL.Label.FarFuture'), 'far_future'));         

    this.options = {
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }},
      legend: {},
      grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis:{ show: true,  type: 'value', },
      yAxis:{ show: true, type: 'category', data: yAxisData},
      series: dataSeries
    }
  }

  
  createMailshotKPIChart() {  
    let yAxisData = [];     
    const dataSeriesValue = [];
    this.chartData.forEach(dataItem => {
      yAxisData.push(dataItem.event_type);
      dataSeriesValue.push(dataItem.count);
    });
    
    const dataSeries = [];     
    dataSeries.push({ 
      type: 'bar', 
      stack: 'total',
      label: { show: true },
      emphasis: { focus: 'series' },
      data: dataSeriesValue
    })     

    this.options = {
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }},
      legend: {},
      grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis:{ show: true,  type: 'value', },
      yAxis:{ show: true, type: 'category', data: yAxisData},
      series: dataSeries
    }
  }

  

  createAgentWVLChartOverall() {
    const dataSeries = [];    
    let yAxisData = [];    
    this.chartData.forEach(dataItem => {
      yAxisData.push(dataItem.user_name);
    });
    dataSeries.push(this.createAgentWVLChartGetData(this.axivasTransateService.getTranslationTextForToken('DashboardWVL.Label.Sum'), 'count'));     

    this.options = {
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }},
      legend: {},
      grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis:{ show: true,  type: 'value', },
      yAxis:{ show: true, type: 'category', data: yAxisData},
      series: dataSeries
    }
  }


  createReportDashboardConsent() {
    let yAxisData = [];     
    const dataSeriesValue = [];
    this.chartData.forEach(dataItem => {
      let yName = '';
      if (this.consentRef != 1) { yName = yName.concat(dataItem.medium, ' '); }
      if (this.consentRef != 2) { yName = yName.concat(dataItem.typ, ' '); }
      if (this.consentRef != 3) { yName = yName.concat(dataItem.entry_source, ' '); }
      yAxisData.push(yName);
      dataSeriesValue.push(dataItem.count);
    });
    
    const dataSeries = [];     
    dataSeries.push({ 
      name: this.axivasTransateService.getTranslationTextForToken('Report.ProjectOverview.Anzahl'), 
      type: 'bar', 
      stack: 'total',
      label: { show: true },
      emphasis: { focus: 'series' },
      data: dataSeriesValue
    })     

    this.options = {
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }},
      legend: {},
      grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis:{ show: true,  type: 'value', },
      yAxis:{ show: true, type: 'category', data: yAxisData},
      series: dataSeries
    }
  }

  createReportDashboardActivity(){
    let steps
    let series;
    let data;
    let sum;
    let results;

    let xAxis=undefined;

    let yAxis=undefined;

      if(this.controlid == 'allstepsActivities'){
        data = this.chartData.flat();
        results= undefined;
        steps = Array.from(new Set(data.map((item) => item.step)))

        sum = this.chartData.map((stepGroup) => this.commonService.sumArrayValue(stepGroup, 'anzahl'))
        series = [{
          type:'bar',
          data:sum,
          itemStyle:{
            color:'#5465FF',
          },
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'top',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: '#808080',
            rich: {
              name: {}
            },
          },
        }]

      } else {
        series = undefined;
        results = this.chartData.map((item) => item.result);
        yAxis={
          ...yAxis,
          show:true,
          type:'category',
          data: this.chartData.map((item) => item.result),
          axisLabel:{
            color:'#808080',
            fontSize:16,
          },
        }
        xAxis = {
          ...xAxis,
          show:true,
          type:'value'
        }

      }

    this.options = {
      //...this.colors,
      tooltip: {},
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
      grid:{
        containLabel:true,
        width:'auto',
        bottom:0,
      },
      xAxis:xAxis ? xAxis : {
          show:true, 
          type:'category',
          data:steps ? steps : results,
        },
      yAxis:yAxis ? yAxis : { 
          show:true,
          type:'value',
        },
      series: series ? series : [{
          type:'bar',
          data: this.chartData?.map((bar:any, index:any) => bar.anzahl),
          itemStyle:{
            color:'#5465FF',
          },
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'right',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: '#808080',
            rich: {
              name: {}
            },
          },
          barWidth:'50%',
          barMaxWidth: '50%',
        }]
    }

  }


  createReportProjectOverviewContact(){
    let steps
    let answers
    let series
    let data

    if(this.controlid == 'allsteps'){
        data = this.chartData.flat();
       steps = Array.from(new Set(data.map((item) => item.step)))
       answers = Array.from(new Set(data.map((item) => item.answer)))

       series = answers.map((answer) =>{
        return{
          name: answer,
          type:'bar',
          itemStyle:{
            color:'#5465FF',
          },
          data:steps.map((step) => {
            const item = data.find((item) => item.step === step && item.answer === answer)
            return item ? item.anzahl : null;
          }),
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'top',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: '#808080',
            rich: {
              name: {}
            },
          },
          tooltip:{ formatter: '{a}'},
        }
       })
    } else {
      steps= undefined;
      answers = this.chartData?.map((item:any) => item?.answer)
      series = undefined;
    }

    this.options = {
      //...this.colors,
      tooltip: {},
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
      grid:{
        containLabel:true,
        width:'auto',
        bottom:0,
      },
    xAxis:{
        show:true, 
        type:'category',
        data:steps ? steps : answers,
      },
    yAxis:{ 
        show:true,
        type:'value',
      },
      series: series ? series : [{
          type:'bar',
          data: this.chartData?.map((bar:any, index:any) => bar.value),
          itemStyle:{
            color:'#5465FF',
          },
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'top',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: '#808080',
            rich: {
              name: {}
            },
          },
        }]
    }
  };


  createReportProjectOverviewChart(){

    this.options = {
      //...this.colors,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
      grid:{
        containLabel:true,
        width:'auto',
        bottom:0,
      },
    xAxis:{
        show:true, 
        type:'value',
      },
    yAxis:{ 
        show:true,
        type:'category',
        nameTextStyle:{
          color:'#808080',
        },
        axisLabel:{
          color:'#808080',
          fontSize:16,
        },
        data:this.chartData?.map((item:any) => item.name).reverse(),
      },
      series:[{
        data: this.chartData?.map((bar:any, index:any) => {
        return{
          name: this.axivasTransateService.getTranslationTextForToken(bar.name),
          type:'bar',
          value: bar.value,
          itemStyle:{
            color:bar.color,
            //color: this.colors.length > 0 ? this.colors[index] : '#5162F6'
          }
         }
        }).reverse(),
          type:'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'right',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: '#808080',
            rich: {
              name: {}
            },
          },
          barWidth:'50%',
          barMaxWidth: '50%',
        }]
    }
  };

  createChart(){

    this.options = {
      //...this.colors,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: -10,
          shadowColor: 'rgba(0,0,0, 0.5)'
        }
      },
    xAxis:{
        show:true, 
        type:'value',
      },

    yAxis:{ 
        show:true,
        type:'category',
        data:this.chartData?.map((item:any) => item.name).reverse(),
      },

      series:[{
        data: this.chartData?.map((bar:any, index:any) => {
        return{
          name: this.axivasTransateService.getTranslationTextForToken(bar?.name),
          type:'bar',
          value: bar?.value,
          itemStyle:{
            color:bar?.color ? bar.color : '#FF5050',
            //color: this.colors.length > 0 ? this.colors[index] : '#5162F6'
          }
         }
        }).reverse(),
          type:'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'right',
            distance: 5,
            rotate: 0,
            fontSize: 15,
            color: 'black',
            rich: {
              name: {}
            },
          },
          barWidth:'50%',
          barMaxWidth: '50%',
        }]
    }
  };


  getHeight() {
    if (isNaN(this.height)) {
      return this.height;
    } else {
      return this.height.toString().concat('px');
    }
  };

  getWidth() {    
    if (isNaN(this.width)) {
      return '100%';
    } else {
      return this.width.toString().concat('px');
    }
  }


  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

}

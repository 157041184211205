<!-- 
<div class="customTextControlWrapper">
  <label class="customTextControlWrapper__label">{{ controlDefinition.displayText | texttransform }}</label>
  <div class="customControl__dataLine customControlValueWrapper">
    <input       
      class="customControl__dataLine__dataControl customTextControlWrapper__textbox"
      [disabled]="!checkIfControlIsEnabled()"      
      (change)="urlChanged()"
      [(ngModel)]="controlValue"
      [placeholder]="commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform"
      (keydown)="keyDown()"
      [tippy]="controlValue"
    >  
    <!-- <div class="everleadCustomControlMiniButton">
      <svg-icon src="assets/images/svg/iconset/icon-world.svg" class="fingerCursor customTextControlWrapper__button"  [svgStyle]="{ 'width.px': 17, 'height.px': 16 }" (click)="showURL()"></svg-icon>   
    </div> 
    <div class="" *ngIf="applicationInfoService.applicationSettings['showVipSettings'] == 'true' && controlValue != null && controlValue != ''">
      <i class="fa-2x fingerCursor fa-star" [ngClass]="{ 
          'far isNoVip': !isVip,
          'fas isVip': isVip
        }"       
        [tippy]="'Vip.Notification.AddRemove' | texttransform"
        (click)="toggleVip()"></i>
    </div>    
  </div>  
</div>

 -->

<div class="customTextControlWrapper">
  <label class="customTextControlWrapper__label">{{ controlDefinition.displayText | texttransform }}</label>
  <div class="customControl__dataLine customTextControlWrapper__valueWrapper">

    <input       
      class="customControl__dataLine__dataControl customTextControlWrapper__textbox"
      [disabled]="!checkIfControlIsEnabled()"      
      (change)="urlChanged()"
      [(ngModel)]="controlValue"
      [placeholder]="commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform"
      (keydown)="keyDown()"
      [tippy]="controlValue"
    >  
    <!-- <div class="everleadCustomControlMiniButton">
      <svg-icon src="assets/images/svg/iconset/icon-world.svg" class="fingerCursor customTextControlWrapper__button"  [svgStyle]="{ 'width.px': 17, 'height.px': 16 }" (click)="showURL()"></svg-icon>   
    </div> -->
    <div class="" *ngIf="applicationInfoService.applicationSettings['showVipSettings'] == 'true' && controlValue != null && controlValue != ''">
      <app-blue-marker [toggle]="isVip" label="VIP" field="true" [tippy]="'Vip.Notification.AddRemove' | texttransform"
      (click)="toggleVip()"></app-blue-marker>
    </div>
  </div>  
</div>
  
  
import { Component, Input } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';

@Component({
  selector: 'app-flex-dashboard',
  templateUrl: './flex-dashboard.component.html',
  styleUrl: './flex-dashboard.component.scss'
})
export class FlexDashboardComponent {
  loading = true;
  kpis = null;
  lastXDays = 30;
  maxValue = 0;
  domains = null;
  selectedDomain = '';

  @Input() type: 'myvisitors' | 'myconversions' = 'myvisitors'

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private methodService: MethodService
  ) { }

  ngOnInit(): void {    
    if(this.type === 'myvisitors'){
      this.getDomains(); 
    }

    if(this.type === 'myconversions'){
      this.getMyConversionsKPIs();
    }
  }




  getDomains() {
    this.externaldatasourceService.executeExternalDataSource(338, [])
    .then(getDomainsResult => {
      const domains = getDomainsResult.find(result => result.name == 'domains');
      if (domains) {
        this.domains = domains.textValue.split(';');
        if (this.domains.length > 0) {
          console.log('domains', this.domains)
          this.selectedDomain = this.domains[0];
        }
        this.getMyVisitorsKPIs();
      } else {

      }      
    });
  }

  getMyVisitorsKPIs() {
    let domain = this.selectedDomain
      .replace('https://', '')
      .replace('https//', '')
      .replace('www.', '');
    this.loading = true;
    this.externaldatasourceService.executeExternalDataSource(823, [this.lastXDays, domain])
    .then(getKPIsResult => {
      this.loading = false;
      if (getKPIsResult != null) {
        this.maxValue = 0;
        this.kpis = getKPIsResult[0];
        this.kpis.trend = this.kpis.trend.toFixed(2);
        this.kpis.eventSources.sort((a, b) => a.count < b.count ? -1 : 1);
      }      
    }).catch(() => { this.loading = false; });
  }


  getMyConversionsKPIs() {
    this.loading= true;
    this.externaldatasourceService.executeExternalDataSource(831, [this.lastXDays])
    .then(getKPIsResult => {
      this.loading = false;
      if (getKPIsResult != null) {
        this.maxValue = 0;
        this.kpis = getKPIsResult[0];
        this.kpis.trend = this.kpis.trend.toFixed(2);
        this.kpis.eventSources.sort((a, b) => a.count < b.count ? -1 : 1);
        this.kpis.eventSources.forEach(source => {
          if (source.count > this.maxValue) {
            this.maxValue = source.count;
          }
        });
      }      
    }).catch(() => { this.loading = false; });
  }
  
  getMyVisitorsPercentHeight(source) {
    if (this.maxValue == 0) {
      this.getMaxValue();
    }

    if (this.maxValue == 0) {
      return '0%';
    } else {
      return ((source.count * 100) / this.maxValue ) + '%';
    }    
  }

  getMaxValue() {
    this.kpis.eventSources.forEach(source => {
      if (source.count > this.maxValue) {
        this.maxValue = source.count;
      }
    });
  }

  getMyConversionsPercentHeight(source) {
    if (this.maxValue == 0) {
      return '0%';
    } else {
      return ((source.count * 100) / this.maxValue ) + '%';
    }    
  }


  showVisitors() {
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['prospects','prospects']);
  }

  showConversions() {
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['conversions','conversions']);
  }
}

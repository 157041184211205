<div class="packageUpgradeWrapper">
    <div class="packageUpgradeCurrentPlan">
        <app-layout header="Licensing.Label.CurrentPlan">
            <div class="licensingUserlistHeader">
                <div class="licensingUserlistHeader__item licensingUserlistHeader__border">
                    <div class="licensingUserlistHeader__item__label ellipsisText">{{ license?.defaultName }}</div>
                    <div class="licensingUserlistHeader__item__value ellipsisText">
                        {{ licensingService.licenseInfo.licenseCount }} {{ 'Licensing.Label.LicenseAmount' | texttransform }}
                    </div>
                </div>
                <div class="licensingUserlistHeader__item licensingUserlistHeader__border">
                    <div class="licensingUserlistHeader__item__label
                        licensingUserlistHeader__item__label__uppercase ellipsisText" *ngIf="licensingService.isMonthlySubscription()">
                        {{ license?.pricePerMonth }} {{ license?.currency }}
                    </div>
                    <div class="licensingUserlistHeader__item__label
                        licensingUserlistHeader__item__label__uppercase ellipsisText" *ngIf="!licensingService.isMonthlySubscription()">
                        {{ license?.pricePerYear }} {{ license?.currency }}
                    </div>
                    <div class="licensingUserlistHeader__item__value ellipsisText" *ngIf="licensingService.isMonthlySubscription()">
                        {{ 'PackageUpgrade.Label.LicenseUserMonth' | texttransform }}
                    </div>
                    <div class="licensingUserlistHeader__item__value ellipsisText" *ngIf="!licensingService.isMonthlySubscription()">
                        {{ 'PackageUpgrade.Label.LicenseUserYear' | texttransform }}
                    </div>
                </div>
                <div class="licensingUserlistHeader__item licensingUserlistHeader__item__end">
                    <app-main-button svg="plus" (onClick)="addLicenses()" label="Licensing.Label.AddLicense"></app-main-button>
                    <!-- <app-main-button svg="plus" (onClick)="cancelSubscription()" label="Licensing.Label.CancelSubscription"></app-main-button>                     -->
                </div>
            </div>
        </app-layout>
    </div>
    <div class="packageUpgradeCurrentMore">
        <app-licensing-package-upgradeitem 
            *ngIf="packagesSales.length > 0"
            [packages]="packagesSales"
            [showLicenseIntervall]="true"
            [showHighlight]="true"
            [license]="license"
        ></app-licensing-package-upgradeitem>
        <app-licensing-package-upgradeitem 
            *ngIf="packagesMarketing.length > 0"
            [packages]="packagesMarketing"
            [license]="license"
        ></app-licensing-package-upgradeitem>
        <app-licensing-package-upgradeitem 
            *ngIf="packagesWebhub.length > 0"
            [packages]="packagesWebhub"
            [license]="license"
        ></app-licensing-package-upgradeitem>
    </div>
</div>
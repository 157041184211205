import { Component, OnInit, Input } from "@angular/core";
import { PackagefeatureService } from "app/jollyjupiter/service/packagefeature.service";
import { LoaderService } from "app/shared/service/loader-service";
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from "@angular/cdk/drag-drop";
import { ExtendedDataServices } from "aws-sdk/clients/mediaconvert";
import { ExternalDataSource_Type, ExternaldatasourceService } from "app/jollyjupiter/service/externaldatasource.service";
import { CommonService } from "app/jollyjupiter/service/common.service";
import { EventService } from "app/jollyjupiter/service/event.service";
import { ApplicationInfoService } from "app/core/application/application-info.service";

@Component({
  selector: "app-featuressettings-slider",
  templateUrl: "./featuressettings-slider.component.html",
  styleUrl: "./featuressettings-slider.component.scss",
})
export class FeaturessettingsSliderComponent implements OnInit {
  @Input() featureId = null;

  slider: any[] = [];
  isAddingNew: boolean = false;
  emptyItem = { base64Img: '', projectId: null, settings: { text_de: '', text_en: ''}};
  newItem = this.getItemDeepCopy(this.emptyItem);
  isEditing: boolean = false;
  editedItem = null;
  selectedLanguage
  editedLanguage
  selectProjects

  editImageRequiredMessage:boolean = false;
  createImageRequiredMessage:boolean = false;

  constructor(
    public packagefeatureService: PackagefeatureService,
    private loaderService: LoaderService,
    private externaldatasourceService: ExternaldatasourceService,
    private commonService: CommonService,
    private eventService: EventService,
    private applicationInfoService: ApplicationInfoService
  ) {}

  ngOnInit() {
    if (!this.featureId) return;

    this.loadSlider();
    this.arrangeSelectProjectsarray();

  }

  loadSlider() {
    this.loaderService.display(true)
   
    this.arrangeSelectProjectsarray()
    console.log('selectedProjects', this.selectProjects);

    this.packagefeatureService
      .getFeatureSlides(this.featureId)
      .then((slides) => {
        //console.log('slides', slides)
        this.slider = slides.map(this.parseSettings);
        this.loaderService.display(false)
        //console.log('this.slider', this.slider)
      });
  }

  getFilteredProjects(array){
    return array.reduce((acc, current) => {
      const projectName = current.project.projectName;
      if (!acc.find(item => item.project.projectName === projectName)) {
          acc.push(current);
      }
      return acc;
  }, []);
  }

  parseSettings(item) {
    return { ...item, settings: JSON.parse(item.settings) };
  }

  uploadImage(event) {
    this.createBase64String(event);
  }

  createBase64String(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          if (this.isAddingNew) {
            this.newItem.base64Img = e.target.result;
          }
          if (this.isEditing) {
            this.editedItem.base64Img = e.target.result;
          }
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  getItemDeepCopy(item) {
    if(item.settings.text_de){
      this.editedLanguage = 'text_de'
    } else if(item.settings.text_en){
      this.editedLanguage = 'text_en'
    }else {
      this.editedLanguage = null
    }

    return {... item, settings: {... item.settings}};
  }

  saveNewItem() {

    if(!this.newItem.base64Img){
      this.createImageRequiredMessage = true
      return
    } else {
      this.createImageRequiredMessage= false
    }
    
    if(this.selectedLanguage === 'text_de'){
      this.newItem.settings.text_en = ''
    } else {
      this.newItem.settings.text_de = ''
    }

    this.loaderService.display(true);

/*     console.log('this new Item', this.newItem); */

    this.packagefeatureService.createFeatureSlide(this.featureId, this.newItem)
      .then((result) => {
        
        this.slider.push(this.parseSettings(result));
        this.loaderService.display(false);
        this.isAddingNew = false;
        this.newItem = this.getItemDeepCopy(this.emptyItem);
        // this.selectProjects = this.getFilteredProjects(this.applicationInfoService.user.userProjects); 
        this.arrangeSelectProjectsarray();
      })
      .catch((err) => console.error(err));
  }


  updateItem() {

    if(!this.editedItem.base64Img){
      this.editImageRequiredMessage = true
      return
    } else {
      this.editImageRequiredMessage=false
    }

 /*    console.log('this edited item', this.editedItem); */

    this.loaderService.display(true);
    this.packagefeatureService.updateFeatureSlide(this.featureId, this.editedItem)
      .then((result) => {
        this.loadSlider();
        this.isEditing = false;
        this.editedItem = null;
        this.loaderService.display(false);
        // this.selectProjects = this.getFilteredProjects(this.applicationInfoService.user.userProjects);
        this.arrangeSelectProjectsarray();    
      })
      .catch((err) => console.error(err));
  }

  deleteItem(itemId) {
    this.loaderService.display(true);
    this.packagefeatureService.deleteFeatureSlide(itemId)
      .then(() => {
        this.slider = this.slider.filter(({ id }) => id !== itemId);
        this.loaderService.display(false);
      })
      .catch((err) => console.error(err));
  }

 drop(event:CdkDragDrop<string[]>){
  moveItemInArray(this.slider, event.previousIndex, event.currentIndex)
   this.slider.map((slide, index) => {
    const slideOrder = {...slide, order: index}
    this.packagefeatureService.updateFeatureSlide(this.featureId, slideOrder)
      .then((result) => {
        this.loadSlider();
        this.loaderService.display(false);
      })
      .catch((err) => console.error(err));
  })
 }


 arrangeSelectProjectsarray(){
  this.selectProjects = this.getFilteredProjects(this.applicationInfoService.user.userProjects).map((item) =>{
    return{
      ...item,
      projectName: item.project.projectName
    }
  }); 
 }


}


<!-- viewMode === 2 -->
    <div class="customMicroCampaign" (scroll)="applicationInfoService.onWindowScroll($event, '-custom-micro-campaign')">
        <div class="customMicroCampaign__header">
            <app-component-header [header]="'Micro campaigns'"></app-component-header>

            <div class="customMicroCampaign__header__nav">
          <!--  <app-main-button label="start a Campaign" svg="rocket" type="small" (click)="startCampaign(microcampaign)"></app-main-button> -->

                <app-filter (keyUp)="filterString= $event"></app-filter>
            </div>
        </div>

        <div *ngIf="doubleOptinMissing == true && doubleOptinMissingChecked == true" class="everleadCoral">
            {{ 'MailToContact.Label.MicroCampaignRequiresDoubleOptin' | texttransform }}<br><br>
        </div>
        <!-- <div *ngIf="(dataArray == null || dataArray.length == 0) && loadingData == false" class="microCampaignHeader__infoLabel">
            {{ 'General.Label.NoCampaignFound' | texttransform }}<br>
            <br>
        </div> -->

        <div class="microCampaignList" *ngIf="viewMode == 1 && applicationInfoService.currentContact == null" class="microCampaignEmpty">
            {{ 'MicroCampaign.Label.StartCampaignRequiresSelectedContact' | texttransform }}
        </div>

        <span *ngIf="loadingData" class="loadingDataSpinner">
            <br><i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
        </span>

        <div class="customMicroCampaign__bodyWrapper">
            <div class="customMicroCampaign__bodyWrapperInner"*ngIf="!loadingData && applicationInfoService.currentContact">
                <div class="customMicroCampaign__tabs">
                    <app-tab svg="tree-structure" [active]="viewMode == 1" [label]="'MicroCampaign.Label.Overview'" (onClick)="viewMode = 1"></app-tab>
                    <app-tab svg="flow-arrow" [active]="viewMode == 2" [label]="'MicroCampaign.Label.Detailview'" (onClick)="viewMode = 2"></app-tab>
                </div>


                <div class="customMicroCampaign__innerTypeOne" *ngIf="viewMode == 1 && applicationInfoService.currentContact !== null && microcampaignService.microcampaigns?.length != 0">
                    <div class="microCampaignList" *ngIf="microcampaignService.microcampaigns?.length != 0">
                        <div class="microCampaignList__header">
                            <!-- <div class="microCampaignList__Item__activity">{{ 'MicroCampaign.Label.Status' | texttransform }}</div>             -->
                            <div class="microCampaignList__Item__name">{{ 'MicroCampaign.Label.Name' | texttransform }}</div>
                            <div class="microCampaignList__Item__startDate">{{ 'MicroCampaign.Label.StartDate' | texttransform }}</div>
                            <div class="microCampaignList__Item__currentStep">{{ 'MicroCampaign.Label.CurrentStep' | texttransform }}</div>
                            <div class="microCampaignList__Item__status">{{ 'MicroCampaign.Label.Status' | texttransform }}</div>
                        </div>
                        <div class="microCampaignList__body">
                            <div class="microCampaignList__Item" *ngFor="let microcampaign of getMicroCampaigns(microcampaignService.microcampaigns)" (mouseenter)="highlightRow(microcampaign)"[ngClass]="{
                                microCampaignList__Item__Row: highlightRowId === microcampaign.id,
                                hideInDom: microcampaign.nextStepCampaignId == null && applicationInfoService.applicationSettings['isDemoproject'] != 'true'
                            }">
                                <!-- <div class="microCampaignList__Item__activity">
                                    <app-status
                                        width="20px"
                                        height="20px" 
                                        [green]="microcampaignState[microcampaign.id]"></app-status>
                                </div> -->
                                <div class="microCampaignList__Item__name">{{ microcampaign.defaultName | texttransform }}</div>
                                <div class="microCampaignList__Item__startDate" *ngIf="microcampaignState[microcampaign.id]">
                                    {{ microcampaignState[microcampaign.id]?.startDate | date: 'medium' }}
                                </div>
                                <div class="microCampaignList__Item__currentStep" *ngIf="microcampaignState[microcampaign.id]">
                                    <span *ngIf="microcampaignState[microcampaign.id]?.status == 'open'">{{ getStepName(microcampaignState[microcampaign.id]?.currentStep) | texttransform }}</span>
                                </div>
                                <div class="microCampaignList__Item__status">
                                    <app-jj-icons type="toggle-right-fill" *ngIf="microcampaignState[microcampaign.id]?.status == 'open'"
                                        (click)="cancelMicroCampaign(microcampaignState[microcampaign.id])" [width]="24" [height]="24" [pointer]="true"></app-jj-icons>
                                    <app-jj-icons type="toggle-left" *ngIf="microcampaignState[microcampaign.id]?.status != 'open'"
                                        (click)="startCampaign(microcampaign)" [width]="24" [height]="24" [pointer]="true"></app-jj-icons>
                                    <span *ngIf="microcampaignState[microcampaign.id] != null && microcampaignState[microcampaign.id] != undefined">{{ 'MicroCampaign.Label.StopCampaign' | texttransform }}</span>
                                    <span *ngIf="microcampaignState[microcampaign.id] == null || microcampaignState[microcampaign.id] == undefined">{{ 'MicroCampaign.Label.StartCampaign' | texttransform }}</span>
        
    <!--                                 <svg-icon src="assets/images/svg/radio_on2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor"
                                        (click)="cancelMicroCampaign(microcampaignState[microcampaign.id])"
                                        *ngIf="microcampaignState[microcampaign.id] != null && microcampaignState[microcampaign.id] != undefined"></svg-icon>
                                    <svg-icon src="assets/images/svg/radio_off2.svg" [svgStyle]="{ 'height.px': 30 }" class="fingerCursor"
                                        (click)="startCampaign(microcampaign)"
                                        *ngIf="microcampaignState[microcampaign.id] == null || microcampaignState[microcampaign.id] == undefined"></svg-icon>  -->
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>

                <div class="customMicroCampaign__innerTypeTwo" *ngIf="!loadingData && viewMode == 2">
                    <div class="microCampaignItem" *ngFor="let element of dataArray">
                        <div class="microCampaignItem__header">
                            <div class="microCampaignItem__title">
                                <app-jj-icons [type]="getTypeIcon(element.firstTaskType)" [width]="20" [height]="20" color="black"></app-jj-icons>
                                <span class="campaignName">{{element.firstTaskType}} : {{ element.campaignName | texttransform }} Campaign({{element.campaignId}})</span>
                                <span>{{ 'MicroCampaign.Label.StartActivity' | texttransform }}: {{ element.firstTaskDate | date: 'medium' }}</span>
                                <span *ngIf="element.currentTaskResultDate != null"> <strong>{{ 'MicroCampaign.Label.End' | texttransform }}</strong>: {{ element.currentTaskResultDate | date: 'medium'}}</span>
                              
                            </div>
                        
                            <div class="microCampaignItem__nav">
                                <app-jj-icons type="trash" [width]="20" [height]="20"></app-jj-icons>
                                <app-jj-icons type="arrows-counter-clockwise" [width]="20" [height]="20"  (click)="getDataList()"></app-jj-icons>
                            </div>
                        </div>
        
                        <div class="microCampaignItem__content">
                            <div class="microCampaignItem__content__flowchart">
                                <app-flowchart
                                [chartData]="element"
                                [controlid]="'campaignOverview'"
                                [height]="'25vh'"
                                [treeHeight]="'80%'"
                                [width]="'100%'"
                                [top]="'25%'"
                                [initialTreeDepth]="element.previousTaskStep ? 2 : 1"
                                [toolboxInfo]="false"
                                [toolboxExpand]="true"
                                [dataToolbox]="element"
                                ></app-flowchart>
        
                            </div>
                            <div class="microCampaignItem__content__timeline">
                                <app-custom-timeline [newMode]=true type=2 [microCampaign]="element" [id]="i"></app-custom-timeline>
                            </div>
                            
                        </div>
        
                    </div>
                </div>

            </div>
        </div>
    </div>

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { DashboardcoreService } from '../../../dashboardcore.service';

@Component({
  selector: 'app-dashboard-list-dropdown',
  templateUrl: './dashboard-list-dropdown.component.html',
  styleUrl: './dashboard-list-dropdown.component.scss'
})
export class DashboardListDropdownComponent implements OnInit, OnChanges{

  constructor(
    public commonService: CommonService,
    private dashboardcoreService: DashboardcoreService,
  ){}

  @Input() data: any | null;
  @Input() controlid: string | null = null;
  @Input() component: string | null; 
  @Input() leadstatus: any | null = null;
  @Input() result: any | null = null;

  arrayPercentage;
  resultPercentage;



  ngOnInit(): void {
  /*   console.log('this data', this.data); */

    if(this.component === 'activity' && this.controlid === 'nestedArray'){
      this.createActivitySumPercentage(this.data);
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && !changes['data'].firstChange) {
      this.data = changes['data'].currentValue

      if(this.component === 'activity' && this.controlid === 'nestedArray'){
        this.createActivitySumPercentage(this.data);
      }
    }
  }


  createSum(array, property) {
    return array?.reduce((sum, item) => sum + item[property], 0);
  }

  createSumNested(array, property){
    return array.flat().reduce((sum, item) => sum + item[property], 0);
  }

  createTotalNestedArray(){
    return this.arrayPercentage.reduce((sum, item) => sum + item.percentage, 0);
  }

  createActivitySumPercentage(array){

    let arrayOne=[]
    let sum
  
    let dataArray = this.data.map((array) => {
      sum = this.createSum(array, 'anzahl');

      if(this.component === 'activity'){
        arrayOne.push({id: array[0].step_id, sum: sum})
      }

    })

    let percentage = this.dashboardcoreService.getPercentagefromArray(arrayOne, 'sum', 'percentage')
    this.arrayPercentage = percentage;
    this.resultPercentage = this.createSum(this.arrayPercentage, 'percentage').toFixed(1);

    if(this.resultPercentage > 99.8){
      this.resultPercentage = 100
    }
    
  }

  findPercentageArray(array){

    let arrayId
    if(this.component === 'activity'){
      arrayId = array[0].step_id
    };

    const find = this.arrayPercentage.find((item) => item.id === arrayId)
    
    if(find){
      return find?.percentage + '%'
    } else {
      return
    }
  }


}

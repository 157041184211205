import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DashboardcoreService } from '../../../dashboardcore.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { Subscription } from 'rxjs';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';

@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrl: './dashboard-list.component.scss'
})
export class DashboardListComponent implements OnInit, OnChanges, OnDestroy{

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    public commonService: CommonService,
  ){}

customEventSubscription: Subscription = new Subscription();
  @Input() data: any;
  @Input() type: any="";
  @Input() result:any | null =  null;
  @Input() leadstatus: string | null =  null;
  @Input() component: any | null = null;
  @Input() controlid: any | null = null;

  ngOnInit(): void {
    this.customEventSubscription = this.eventService.customEvent.subscribe((event) =>{
    })

/*     if(this.component === 'dashboard_activity'){
      console.log('this.data', this.data)
      this.data = JSON.stringify(this.data);
      console.log('this data after json', this.data)
    } */

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && !changes['data'].firstChange) {
      this.data = changes['data'].currentValue

   /*    if(this.component === 'dashboard_activity'){
        this.data =  changes['data'].currentValue
      } */
    }
    if (changes['result'] && !changes['result'].firstChange) {
      this.result = changes['result'].currentValue
    }
  }

  ngOnDestroy(): void {
      if(this.customEventSubscription){
        this.customEventSubscription.unsubscribe()
      }
  }

  createSum(array, property) {
    let sumValue = array?.reduce((sum, item) => sum + item[property], 0);
    if (property == 'percentage') {      
      if (sumValue > 99.8) { sumValue = 100; }
    }
    return sumValue;
  }

  getStepName(array){
  }


}

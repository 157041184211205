<div class="visitorsListItem__row" (click)="navigateTo('prospects')" [ngClass]="{
    visitorsListItemOdd: isOdd
}">

    <div class="visitorsListItem__col__one">
        <div class="visitorsListItem__card">
            <div class="companyName ellipsis" *ngIf="data['_917_country']" [tippy]="data['_917_address']">
                {{ data['_917_country'] }}
            </div>
            <div class="country ellipsis" *ngIf="data['_917_companyname']" [tippy]="data['_917_companyname']">
                {{data['_917_companyname']}}                
            </div>
            <div class="activityStatus">
                <app-status [blue]="data['_917_lastvisited']">
                    <span>{{'Visitors.Label.LastActivity' | texttransform}} | <span class="noWeight">{{ data['_917_lastvisited'] | date }}</span></span>
                </app-status>
<!--                 <span>{{'Visitors.Label.Lastactivity' | texttransform}} | {{ data['_917_lastvisited'] | date }}</span> -->
            </div>

        </div>
    </div>

    <div class="visitorsListItem__col__two">
        <div class="visitorsListItem__iconPlaceholder">
            <app-jj-icons type="arrow-right"  color="primaryLight" [width]="20" [height]="20"></app-jj-icons>
        </div>
    </div>

</div>

<div *ngIf="!type" class="everleadFilter"[class.disabled]="disabled">
    <div class="everleadFilter__wrapper">
        <input 
        (change)="handleChange($event.target.value)"
        [placeholder]="placeholder | texttransform"
        (keyup)="handleKeyUp($event.target.value)"
        [disabled]="disabled">
        <app-jj-icons type="magnifying-glass"  [width]="20" [height]="20" [color]="disabled ? 'gray0' : 'secondary'"></app-jj-icons>
    </div>
</div>

<div *ngIf="type === 'tableHeader'" class="everleadFilterTableHeader" [class.disabled]="disabled">
    <div *ngIf="!openFilter" class="everleadFilterTableHeader__closedFilter"(mouseenter)="openFilter= true">
        <span>{{ placeholder | texttransform }}</span>
        <app-jj-icons type="magnifying-glass"  [width]="20" [height]="20" [color]="disabled ? 'gray0' : 'secondary'"></app-jj-icons>
    </div>

    <div class="everleadFilterTableHeader__wrapper" *ngIf="openFilter" [class.open]="openFilter" (mouseleave)="checkFilter()">
        <input
        [class.open] 
        (change)="handleChange($event.target.value)"
        [placeholder]="placeholder | texttransform"
        (keyup)="handleKeyUp($event.target.value)"
        [disabled]="disabled">
        <app-jj-icons type="magnifying-glass"  [width]="20" [height]="20" [color]="disabled ? 'gray0' : 'secondary'"></app-jj-icons>
    </div>
</div>

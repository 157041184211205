<div *ngIf="userService.hasPermission('CanReadCampaign')" class="agEverleadBorder">
    <div class="agComponentHeader__wrapper">        
        <app-component-header class="agComponentHeader__header" [header]="'Supervisor.Label.ManageCampaigns' | texttransform"></app-component-header>
        <div class="agComponentHeader__menu">
        
            <div class="agComponentHeader__item">
                <app-checkbox [(ngModel)]="showOnlyActive" label="CampaignDesign.Label.ShowOnlyActive"></app-checkbox>
            </div>
        
            <div class="agComponentHeader__item">
                <app-main-button type="small" svg="plus-circle" label="CampaignDesign.Label.NewCampaign"
                    (onClick)="createNewCampaign()" [tippy]="'General.Label.CreateNewWithDefault' | texttransform"
                    [tippyOptions]="{placement:'bottom'}"></app-main-button>
        
                <app-main-button type="borderSmall" svg="magic-wand" label="CampaignDesign.Label.NewCampaign"
                    (onClick)="createNewWizardCampaign()" [tippy]="'General.Label.CreateNewWithWizard' | texttransform"
                    [tippyOptions]="{placement: 'bottom'}"></app-main-button>
            </div>
            <app-filter (keyUp)="filterString = $event" class="agComponentHeader__item"></app-filter>        
        </div>
        </div>    
    <div class="agEverleadBorderInner">
    <div class="nonExpertHeader" *ngIf="applicationInfoService.expertMode==false && !applicationInfoService.isMobile()" >

    </div>

    <span id="projectSettingsDesignSpan"></span>

    <span *ngIf="loadingData==true">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </span>

    <div class="projectSettings__wrapper" [style.height]="getTableHeight()" *ngIf="loadingData == false">    
        <table mat-table #table [dataSource]="dataSource" style="width: 100%" matSort>   

            <ng-container matColumnDef="defaultName" [sticky]="!applicationInfoService.isMobile()">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.DefaultName' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">  
                    <div class="projectSettings__name">
                        <app-jj-icons 
                        *ngIf="userService.hasPermission('CanUpdateCampaign')"
                        type="editor"
                        [tippy]="'General.Label.ShowWizard' | texttransform"
                        (click)="showWizard(element)"
                        [width]="20"
                        [height]="20"
                        ></app-jj-icons>
                 <!--        <i class="fas fa-edit fingerCursor" [tippy]="'General.Label.ShowWizard' | texttransform" (click)="showWizard(element)"
                            *ngIf="userService.hasPermission('CanUpdateCampaign')"></i> -->
                        <label *ngIf="applicationInfoService.isDeveloper" class="projectSettings__id everleadCoral">{{ element.id }}</label>
                        <app-text-input [(ngModel)]="element.defaultName" (valueChange)="saveCampaign(element)" class="projectSettings__inputMin"></app-text-input>
            <!--             <input [(ngModel)]="element.defaultName" type="text" (change)="saveCampaign(element)" class="projectSettings__inputMin">     -->                
                        <app-wizard-translations [array]="element" updateBaseArrayExternaldatasourceId=137 mode="3"></app-wizard-translations>    
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.IsActive' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">
                    <app-checkbox [(ngModel)]="element.isActive" (valueChange)="saveCampaign(element)" class="projectSettings__inputFull"></app-checkbox>            
          <!--           <input [(ngModel)]="element.isActive" type="checkbox" (change)="saveCampaign(element)" class="projectSettings__inputFull">          -->           
                </td>
            </ng-container>

            <ng-container matColumnDef="campaignStart">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.CampaignFrom' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">
                    <app-date-input [(ngModel)]="element.startCampaign" (dateChange)="saveCampaign(element)" class="projectSettings__inputFull"></app-date-input>             
                </td>
            </ng-container>

            <ng-container matColumnDef="campaignEnd">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.CampaignUntil' | texttransform }}</th>
                <td mat-cell *matCellDef="let element"> 
                    
                    <app-date-input [(ngModel)]="element.endCampaign" (dateChange)="saveCampaign(element)" class="projectSettings__inputFull"></app-date-input>             
                </td>
            </ng-container>

            <ng-container matColumnDef="nettoCallLimit">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.NettCallLimit' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">
                    <app-number-input [(ngModel)]="element.netcallLimit" (valueChange)="saveCampaign(elememt)" class="projectSettings__inputFull"></app-number-input>          
                </td>
            </ng-container>

            <ng-container matColumnDef="ses">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.SesConfigSetId' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">
                    <app-select-input
                    [(ngModel)]="element.sesConfigSetId" 
                    (valueChange)="saveCampaign(element)"
                    [options]="configSets"
                    bind="id"
                    display="defaultName"
                    class="projectSettings__dropdown"></app-select-input>                         
                </td>
            </ng-container>

            <ng-container matColumnDef="followUps">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.AutoCloseNettCallLimit' | texttransform }}</th>
                <td mat-cell *matCellDef="let element"> 
                <!--     <input [(ngModel)]="element.closeTaskAfterXCallAttempts" type="number" (change)="saveCampaign(element)" class="projectSettings__inputFull"> -->
                    <app-number-input [(ngModel)]="element.closeTaskAfterXCallAttempts" (valueChange)="saveCampaign(element)"></app-number-input>                               
                </td>
            </ng-container>

            <ng-container matColumnDef="campaignId">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.CustomerCampaignId' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">
                    <app-text-input [(ngModel)]="element.customerCampaignId" (valueChange)="saveCampaign(element)" class="projectSettings__inputFull"></app-text-input>          
                    <!-- <input [(ngModel)]="element.customerCampaignId" type="text" (change)="saveCampaign(element)" class="projectSettings__inputFull">   -->        
                </td>
            </ng-container>

            <ng-container matColumnDef="campaignName">
                <th mat-header-cell *matHeaderCellDef>{{ 'CampaignDesign.Label.CustomerCampaignName' | texttransform }}</th>
                <td mat-cell *matCellDef="let element">  
                  <!--   <input [(ngModel)]="element.customerCampaignName" type="text" (change)="saveCampaign(element)" class="projectSettings__inputMin"> -->
                    <app-text-input [(ngModel)]="element.customerCampaignName" (valueChange)="saveCampaign(element)" class="projectSettings__inputMin"></app-text-input>          
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{             
                hideInDom: (row.isActive == false && showOnlyActive)  || itemIsFiltered(row) == true,
                projectSettings__isInactive: row.isActive == false
            }" class="everleadTableRow"></tr>
        </table>
    </div>
    </div>
</div>
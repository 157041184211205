<div class="entityMemberEditorWrapper">
    <div class="entityMemberEditorSections">
            <div>
                <div class="entityMemberEditorExplanation">
                    <!-- {{ 'EntityMemberEditor.Label.NameExplanation' | texttransform }} -->
                      Geben Sie hier den Namen ihrer neuen Variable ein. Ungültige Zeichen sowie Leerzeichen werden 
                      bei der Eingabe automatisch ersetzt. 
                </div>

                <app-text-input [label]="'Wizard.Translation.Name' | texttransform" 
                    [(ngModel)]="currentMember.defaultName"></app-text-input>
            </div>
            
            <div>
                <div class="entityMemberEditorExplanation">
                    Bitte geben Sie hier den Typ ihres neuen Datenbankfelds an. Bitte beachten Sie, dass Wertelisten nur 
                    bei Feldern des Typs 'Numerisch' verwendet werden können. 
                    <!-- {{ 'EntityMemberEditor.Label.TypeExplanation' | texttransform }} -->
                </div>
                <select [(ngModel)]="currentMember.memberType" 
                    [disabled]="currentMember.id != null"
                    class="maxWidth">
                    <option [ngValue]=null></option>
                    <option ngValue="TEXT">TEXT</option>
                    <option ngValue="NUMERIC">NUMERIC</option>
                    <option ngValue="DATETIME">DATETIME</option>
                    <option ngValue="BOOL">BOOL</option>
                </select>
            </div>

            <div *ngIf="currentMember.memberType == 'NUMERIC'">                
                <div class="entityMemberEditorExplanation">
                    {{ 'EntityMemberEditor.Label.TypeExplanation' | texttransform }}
                </div>
                <app-select-input [label]="'EntityMemberDesign.Label.LookupTableId' | texttransform" 
                    [(ngModel)]="currentMember.lookupTableId" [options]="lookupTableDefinitions['data']" 
                    display="defaultName" bind="id"></app-select-input>
            </div>

            <div class="entityMemberEditorSections__buttons">
                Hallo
            </div>
        </div>
</div>
<div *ngIf="loadingData==false && useContactProspect" class="prospectDetail__noprospect">
    <h5> {{ 'Prospect.Label.AccountInformation' | texttransform }}</h5>
    <ng-container *ngIf="conversionInfo != null">
        <label class="prospectDetail__noprospect__conversionheaderlabel">{{ 'Prospect.Label.CompanyByConversion' | texttransform }}</label>
        <label class="prospectDetail__noprospect__conversionheaderlabeldetail" data-html="true" [tippy]=conversionInfoFull>{{ conversionInfo }}</label>
    </ng-container>    
</div>

<div *ngIf="prospect != null" class="prospectDetailWrapper" (click)="callPopup()">
    <mat-label *ngIf="useContactProspect == true && ipProspectFound == false " style="margin-bottom: 2px;"><b>Prospect</b></mat-label>
    <mat-label *ngIf="useContactProspect == true && ipProspectFound == true" style="margin-bottom: 2px;"><b>Ip-Prospect</b></mat-label>
    
    <div class="row prospectDetailRow" *ngIf="useContactProspect == false">
        <i  *ngIf="prospect.url != null && prospect.enriched && prospect.isisp == false 
                && applicationInfoService.applicationSettings['showVipSettings'] == 'true'" 
            [ngClass]="{ isVip: isVip }" 
            class="far fa-star fa-lg prospectDetailRow__vipButton fingerCursor" 
            (click)="addProspectToVipList(prospect)" 
            [tippy]="'Vip.Notification.AddRemove' | texttransform">
        </i>        
        <!-- <button class="everleadButton__ghost" (click)="createAccountFromProspect()"
            [disabled]="prospect.accountId != null">
            <img src="assets/images/svg/default_account.svg" class="prospectDetailButtonIcon">
            {{ 'Prospect.Label.ConvertToAccount' | texttransform }}
        </button> -->
        <button class="everleadButton__ghost prospectDetailButton" (click)="changeProspectBlackList()">
            <i class="prospectButtonIcon fas fa-ban prospectDetailButtonIcon"
                [ngClass]="{ isBlacklisted: prospect.isblacklisted == true }"></i>
            <span *ngIf="!prospect.isblacklisted">{{ 'Prospect.Label.Blacklist' | texttransform }}</span>
            <span *ngIf="prospect.isblacklisted">{{ 'Prospect.Label.RemoveFromBlacklist' | texttransform }}</span>
        </button>
    </div>

    <div class="prospectDetailNotEnriched" [ngClass]="{ hideInDom: prospect.enriched != false || useContactProspect == false }">
        {{ 'Prospect.Label.ProspectIsNotEnriched' | texttransform }}
        <br>
        
    </div>

    <div class="prospectDetailNotEnriched" [ngClass]="{ hideInDom: prospect.isisp != true || forceShow == true }">
        {{ 'Prospect.Label.ProspectIsIsp' | texttransform }}<br>
        <br>
        <button class="jjButtonColor jjButtonStyle editControlDiv__button" (click)="callPopup(true);$event.stopPropagation()">
            {{ 'ProspectDetail.Label.ShowProspect' | texttransform }}
        </button><br>
    </div>

    <div class="prospectDetailRow everleadShadowBox" [ngClass]="{
        hideInDom: (prospect.enriched == false && useContactProspect == true ) || (prospect.isisp && forceShow == false)
    }">
        <div class="prospectInfoItem prospectInfoItemLeft">      
            <div class="prospectInfo">
                <div class="prospectInfo__label">{{ 'Prospect.Label.CompanyName' | texttransform }}</div>
                <div class="prospectInfo__value">{{ prospect.companyName }}</div>
            </div>  
            <div class="prospectInfo">
                <div class="prospectInfo__label">{{ 'Prospect.Label.OrgName' | texttransform }}</div>
                <div class="prospectInfo__value">{{ prospect.orgName }}</div>
            </div>      
            <div class="prospectInfo">
                <div class="prospectInfo__label">{{ 'Prospect.Label.Adress' | texttransform }}</div>
                <div class="prospectInfo__value">{{ prospect.address }}</div>
            </div>
            <div class="prospectInfo">
                <div class="prospectInfo__label">{{ 'Prospect.Label.Country' | texttransform }}</div>
                <div class="prospectInfo__value">{{ prospect.country }}</div>
            </div>
            <div class="prospectInfo">
                <div class="prospectInfo__label">URL</div>
                <div class="prospectInfo__value">{{ prospect.url }}</div>
            </div>    
            <div class="prospectInfo">
                <div class="prospectInfo__label">Id</div>
                <div class="prospectInfo__value">{{ prospect.id }}</div>
            </div>
            <div class="prospectInfo">
                <div class="prospectInfo__label">{{ 'Prospect.Label.LastVisited' | texttransform }}</div>
                <div class="prospectInfo__value">{{ convertToDate(prospect.lastVisited) }}</div>
            </div>                   
        </div>
        <div class="prospectInfoItem prospectInfoItemRight" *ngIf="useContactProspect == false">
            <!-- <div>
                <button class="everleadButton__ghost prospectDetailButton" (click)="changeProspectBlackList()">
                    <i class="prospectButtonIcon fas fa-ban prospectDetailButtonIcon"
                        [ngClass]="{ isBlacklisted: prospect.isblacklisted == true }"></i>
                    <span *ngIf="!prospect.isblacklisted">{{ 'Prospect.Label.Blacklist' | texttransform }}</span>
                    <span *ngIf="prospect.isblacklisted">{{ 'Prospect.Label.RemoveFromBlacklist' | texttransform }}</span>
                </button>
                <i  *ngIf="prospect.url != null && prospect.enriched && prospect.isisp == false 
                    && applicationInfoService.applicationSettings['showVipSettings'] == 'true'" 
                    [ngClass]="{ isVip: isVip }" 
                    class="far fa-star fa-lg prospectDetailRow__vipButton fingerCursor" 
                    (click)="addProspectToVipList(prospect)" 
                    [title]="'Vip.Notification.AddRemove' | texttransform">
                </i>  
                </div> -->
            <div class="prospectInfoAccount__wrapper">
                <div  *ngIf="useContactProspect == false" class="prospectInfoAccount__icon">
                    <img src="{{getImageUrl(prospect.logourl)}}" class="prospectDetailIcon" *ngIf="prospect.logourl != null">
                    <img src="assets/images/svg/default_prospect.svg" class="prospectDetailIcon"
                        *ngIf="prospect.logourl == null">    
                </div>             
                                        
                
                <div *ngIf="accountInfo != null" class="d-flex">                
                    <div class="prospectInfoAccount">
                        <div class="prospectInfoAccount__header">                        
                            {{ 'Prospect.Label.ConnectedAccount' | texttransform }}</div>
                        <div class="prospectInfoAccount__value">
                            <div>{{ accountInfo.name1 }}</div>
                            <div>{{ accountInfo.street }}</div>
                            <div>{{ accountInfo.zipcode }} {{ accountInfo.city }}</div>
                        </div>
                    </div>  
                </div>    
            </div>
            <div *ngIf="accountInfo != null">
                <!-- <button class="everleadCustomButton" (click)="loadProspectAccount()">{{ 'Prospect.Label.LoadConnectedAccount' | texttransform }}</button> -->
                <app-main-button (onClick)="loadProspectAccount()" label="Prospect.Label.LoadConnectedAccount" type="small"></app-main-button>
            </div>
            <div *ngIf="accountInfo == null && useContactProspect == false" class="prospectInfoAccount prospectInfoAccount__data">
                <app-main-button (onClick)="createAccountFromProspect()" label="Prospect.Label.ConvertToAccount" type="small"></app-main-button>
                <!-- <button class="everleadCustomButton" (click)="createAccountFromProspect()">
                    <img src="assets/images/svg/default_account.svg" class="prospectDetailButtonIcon">
                    {{ 'Prospect.Label.ConvertToAccount' | texttransform }}
                </button> -->
            </div>
        </div>
    </div>    


    <div class="row" style="width: 100%;  overflow-x: auto;" *ngIf="useContactProspect == false">
        <div class="col-md-12">
            <div class="buttonsContainer">
                <app-main-button *ngIf="activity" [svg]="'list-bullets'" [type]="chartView ? 'borderSmall' : 'small'" 
                    (onClick)="chartView= false" label="Dashboard.Label.ViewList"></app-main-button>
                <app-main-button *ngIf="activity" [svg]="'chart-line'" [type]="chartView ? 'small' : 'borderSmall'" 
                    (onClick)="chartView=true" label="Dashboard.Label.ViewChart"></app-main-button>
            </div>
        </div>
        
        <div class="col-md-12" *ngIf="chartView">
            <div class="chartPlaceholder">
                <app-lead-scoring-line-chart *ngIf="!loadingChart" type="prospectDetail" [chartData]="prospectDetail"></app-lead-scoring-line-chart> 
            </div>
        </div>
        <div class="col-md-12" *ngIf="!chartView">
            <div [innerHTML]="innerHTMLEvents"></div>
        </div>
    </div>
</div>

<div *ngIf="prospect==null && loadingData==true">
    <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}    
</div>

<div class="prospectConverter" *ngIf="useContactProspect">


    <app-accordion [title]="'ProspectDetail.Label.MoreOptions' | texttransform " [isOpen]="true">
        <div class="newProspectConverter__content">
            <div class="newProspectConverter__content__item">
                <app-main-button
                type="borderSmall" 
                svg="user-plus"
                [label]="'ProspectDetail.Label.Create' | texttransform" 
                (onClick)="createNewAdressAndAssign();$event.stopPropagation()"></app-main-button>
                <div class="newProspectConverter__content__label">
                    {{ 'ProspectDetail.Label.CreateDesc' | texttransform }}                    
                </div>
            </div>
            <div class="newProspectConverter__content__item">
                <app-main-button
                type="borderSmall"
                svg="user-check"
                [label]="'ProspectDetail.Label.Assign' | texttransform"
                (onClick)="assignAccount();$event.stopPropagation()"
                ></app-main-button>
                <div class="newProspectConverter__content__label">
                    {{ 'ProspectDetail.Label.AssignDesc' | texttransform }}                    
                </div>
            </div>
            <div class="newProspectConverter__content__item" *ngIf="prospect?.enriched && !prospect?.isisp">
                <app-main-button
                type="borderSmall"
                svg="arrows-counter-clockwise"
                [label]="'ProspectDetail.Label.Convert' | texttransform"
                (onClick)="convertProspectAndAssignContactToIt();$event.stopPropagation()"
                ></app-main-button>
                <div class="newProspectConverter__content__label">
                    {{ 'ProspectDetail.Label.ConvertDesc' | texttransform }}                   
                </div>
            </div>
        </div>
    </app-accordion>

<!--     <div class="prospectConverter__header">
        <div>
            {{ 'ProspectDetail.Label.MoreOptions' | texttransform }}
        </div>
        <div class="prospectConverter__header__dropdown" (click)="showOptions = !showOptions">
            <i class="fas " [ngClass]="{
                'fa-chevron-down': !showOptions,
                'fa-chevron-up': showOptions
            }"></i>
        </div>
    </div>
    <div class="prospectConverter__content" *ngIf="showOptions">
        <div class="prospectConverter__content__line">
            <div class="prospectConverter__content__line__button" (click)="createNewAdressAndAssign();$event.stopPropagation()">
                {{ 'ProspectDetail.Label.Create' | texttransform }}
            </div>
            <div class="prospectConverter__content__line__content">
                {{ 'ProspectDetail.Label.CreateDesc' | texttransform }}                    
            </div>
        </div>
        <div class="prospectConverter__content__line">
            <div class="prospectConverter__content__line__button" (click)="assignAccount();$event.stopPropagation()">
                {{ 'ProspectDetail.Label.Assign' | texttransform }}
            </div>
            <div class="prospectConverter__content__line__content">
                {{ 'ProspectDetail.Label.AssignDesc' | texttransform }}                    
            </div>
        </div>
        <div class="prospectConverter__content__line" *ngIf="prospect?.enriched && !prospect?.isisp">
            <div class="prospectConverter__content__line__button" (click)="convertProspectAndAssignContactToIt();$event.stopPropagation()">
                {{ 'ProspectDetail.Label.Convert' | texttransform }}
            </div>
            <div class="prospectConverter__content__line__content">
                {{ 'ProspectDetail.Label.ConvertDesc' | texttransform }}                   
            </div>
        </div>
    </div> -->
</div>

<!-- <div class="prospectConverter" *ngIf="useContactProspect">
    <div class="prospectConverter__header">
        <div>
            {{ 'ProspectDetail.Label.MoreOptions' | texttransform }}
        </div>
        <div class="prospectConverter__header__dropdown" (click)="showOptions = !showOptions">
            <i class="fas " [ngClass]="{
                'fa-chevron-down': !showOptions,
                'fa-chevron-up': showOptions
            }"></i>
        </div>
    </div>
    <div class="prospectConverter__content" *ngIf="showOptions">
        <div class="prospectConverter__content__line">
            <div class="prospectConverter__content__line__button" (click)="createNewAdressAndAssign();$event.stopPropagation()">
                {{ 'ProspectDetail.Label.Create' | texttransform }}
            </div>
            <div class="prospectConverter__content__line__content">
                {{ 'ProspectDetail.Label.CreateDesc' | texttransform }}                    
            </div>
        </div>
        <div class="prospectConverter__content__line">
            <div class="prospectConverter__content__line__button" (click)="assignAccount();$event.stopPropagation()">
                {{ 'ProspectDetail.Label.Assign' | texttransform }}
            </div>
            <div class="prospectConverter__content__line__content">
                {{ 'ProspectDetail.Label.AssignDesc' | texttransform }}                    
            </div>
        </div>
        <div class="prospectConverter__content__line" *ngIf="prospect?.enriched && !prospect?.isisp">
            <div class="prospectConverter__content__line__button" (click)="convertProspectAndAssignContactToIt();$event.stopPropagation()">
                {{ 'ProspectDetail.Label.Convert' | texttransform }}
            </div>
            <div class="prospectConverter__content__line__content">
                {{ 'ProspectDetail.Label.ConvertDesc' | texttransform }}                   
            </div>
        </div>
    </div>
</div> -->
<div class="barleadscoreWrapper" (click)="showPopup()" [ngClass]="{ fingerCursor: popupAllowed }">
    <span *ngIf="label" class="barleadscoreWrapper__label">{{label}}</span>
    <div class="barLeadscore">
        <div class="barLeadscore__placeholder" [ngClass]="{
            accountHeaderBar: accountHeader 
        }">
            <div *ngFor="let score of scoreArray" [ngClass]=" getValue(score.range) ?  score.class : 'inactive'"></div>
        </div>
        <span [ngClass]="getNumberColor()">{{ roundUp(value) }}</span>       
    </div>
</div>
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EmailtemplateService } from 'app/jollyjupiter/service/emailtemplate.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MailService } from 'app/jollyjupiter/service/mail.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { SmtpService } from 'app/jollyjupiter/service/smtp.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Subscription } from 'rxjs';
import { WizardMode, WizardService } from '../../wizard/wizard.service';
import { SettingsService } from 'app/shared/service/settings.service';

@Component({
  selector: 'app-smtpsettings',
  templateUrl: './smtpsettings.component.html',
  styleUrls: ['./smtpsettings.component.scss']
})

export class SmtpsettingsComponent implements OnInit, OnDestroy {
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.grabber) { return; }
    this.resizer(event.clientX - this.originalX); this.originalX = event.clientX;
  }

  @HostListener('document:mouseup', ['$event']) onMouseUp(event: MouseEvent) {
    this.applicationInfoService.userSettings['grabberWidthSMTPSettings'] = this.emailListWidth;
    if (this.grabber == true) {
      this.settingsService.updateSecurityUserSettingsJson()
    }
    this.grabber = false;
  }
  resizer(offsetX: number) {
    this.emailListWidth += offsetX;
    if (this.emailListWidth < 20) { this.emailListWidth = 20; }
  }

  customEventSubscription: Subscription = new Subscription();
  filterString = '';
  emailSettings = [];
  mode = 2;
  emails = null;
  selectedMail = null;
  innerHTML = null;
  orderId = 1;
  filterId = null;
  attachments = [];
  grabber = false;
  grabberSet = false;
  originalX = 0;
  emailListWidth = 400;
  emailEntityTableIdForPagination = 197;
  emailPaginationPage = 1;
  emailPaginationPageSize = 30;
  innerHTMLEMailContentSource = ` <jj-container controluiid="221065"> </jj-container>`;
  innerHTMLEMailContent = null;

  // CloudConnection
  cloudConnections = [];
  selectedCloudConnection = null;
  syncingMailbox = false;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    public commonService: CommonService,
    private messagingService: MessagingService,
    private uiService: UiService,
    private wizardService: WizardService,
    public applicationInfoService: ApplicationInfoService,
    private emailtemplateService: EmailtemplateService,
    private domSanitizer: DomSanitizer,
    private methodService: MethodService,
    private eventService: EventService,
    private sharedAPI: SharedAPI,
    private axivasTranslateService: AxivasTranslateService,
    private smtpService: SmtpService,
    private settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.getCloudConnections();
    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event.source == 'mailtoContact' && event.id == 'refreshSMTPMails') {
        this.getEmails();
      };
      if (event.id == 'updateContactOfEmailByEMailAdress') {
        this.updateEmails(event.email, event.contact);
      }
    });

    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.userSettings['grabberWidthSMTPSettings'])) {
      this.emailListWidth = this.applicationInfoService.userSettings['grabberWidthSMTPSettings'];
    }
    this.innerHTMLEMailContent = this.domSanitizer.bypassSecurityTrustHtml(this.innerHTMLEMailContentSource);

  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }

  updateEmails(email, contact) {
    const mailsFromUpdate = this.emails.filter(mail => mail._1130_from == email && mail._1130_type == 'Incoming');
    mailsFromUpdate.forEach(mail => {
      mail._1130_contactid = contact.id;
      mail._4_firstname = contact.firstName;
      mail._4_lastname = contact.lastName;
    });

    const mailsToUpdate = this.emails.filter(mail => mail._1130_to == email && mail._1130_type == 'Outgoing');
    mailsToUpdate.forEach(mail => {
      mail._1130_contactid = contact.id;
      mail._4_firstname = contact.firstName;
      mail._4_lastname = contact.lastName;
    });
  }

  getCloudConnections() {
    this.externaldatasourceService.executeExternalDataSource(845).then(cloudConnections => {
      this.cloudConnections = cloudConnections.filter(connection =>
        connection.projectId == this.applicationInfoService.projectID &&
        connection.isActive == true &&
        connection.connectionTypeLookupId == 7942
      );
      if (this.cloudConnections.length > 0) {
        this.selectedCloudConnection = this.cloudConnections[0].id;
        this.getEmails();
      }
    });
  }

  syncMailbox() {
    this.syncingMailbox = true;
    this.sharedAPI.syncCloucConnetions().subscribe(syncResult => {
      this.getEmails();
    }, error => {
      this.syncingMailbox = false;
    });
  }

  getControlHeight(substractValue = 0) {
    let controlHeight = Number(this.uiService.getDesignSpanPosition('smtpSettingDesignSpan', 40));
    return (controlHeight - substractValue) + 'px';
  }

  getEmails() {
    if (this.commonService.isNullOrUndefined(this.selectedCloudConnection)) {
      return;
    }

    if (this.emails == null) { this.emails = []; }
    this.externaldatasourceService.executeExternalDataSource(885, [
      this.emailEntityTableIdForPagination,
      this.emailPaginationPageSize,
      this.emailPaginationPage,
      this.getFilter(),
      this.getSorting(),
      null,
      null
    ])
    .then(getEmailsResult => {
      getEmailsResult.jsonData.forEach(email => { this.emails.push(JSON.parse(email)); });
      if (this.selectedMail == null && this.emails.length > 0) {
        this.selectedMail = this.emails[0];
        this.renderContent(this.selectedMail);
      }
      // this.changeMode(this.mode);
      this.syncingMailbox = false;
    }).catch(() => {
      this.syncingMailbox = false;
    });

    //console.log('this emails', this.emails)
  }

  newEMailSearch() {
    this.selectedMail = null;
    this.emailPaginationPage = 1;
    this.emails = null;
    this.getEmails();
  }

  getSorting() {
    if (this.orderId == 1) {
      return '{ columnName: "time", orderByOperator: DESC_NULLS_LAST }';
    };
    if (this.orderId == 2) {
      return '{ columnName: "from", orderByOperator: ASC_NULLS_LAST }';
    };
    if (this.orderId == 3) {
      return '{ columnName: "to", orderByOperator: ASC_NULLS_LAST }';
    };
  }

  getFilter() {
    const filter = [];
    filter.push({ columnName: "cloudConnectionId", value: this.selectedCloudConnection.toString() });
    if (this.mode == 2) { filter.push({ columnName: "type", value: "Incoming" }) };
    if (this.mode == 3) { filter.push({ columnName: "type", value: "Outgoing" }) };

    if (this.filterId == 3) { filter.push({ columnName: "contactId", value: null, negate: true }) };
    if (this.filterId == 4) { filter.push({ columnName: "hasAttachements", value: "true" }) };


    let filterArrayJson = JSON.stringify(filter);
    Object.keys(filter).forEach(() =>  {
      filterArrayJson = filterArrayJson.replace('"'.concat('columnName', '"'), 'columnName');
      filterArrayJson = filterArrayJson.replace('"'.concat('value', '"'), 'value');
      filterArrayJson = filterArrayJson.replace('"'.concat('operator', '"'), 'operator');
      filterArrayJson = filterArrayJson.replace('"'.concat('negate', '"'), 'negate');
    });
    return filterArrayJson;
  }

  renderContent(mail) {
    this.getAttachments(mail);
    let renderText = mail._1130_htmlbody;
    if (renderText == null || renderText == undefined) {
      renderText = mail._1130_textbody;
    }
    this.selectedMail = mail;

    renderText = renderText.replace(/target="_self"/g, '');
    renderText = renderText.replace(/target="_parent"/g, '');
    renderText = renderText.replace(/target="_top"/g, '');
    renderText = renderText.replace(/<a /g, '<a target="_blank"');
    this.innerHTML = this.domSanitizer.bypassSecurityTrustHtml(this.emailtemplateService.renderEMail(renderText, []));
    this.addGrabber();
  }

  addGrabber() {
    if (this.grabberSet) { return; }
    setTimeout(() => {
      const grabberElement = document.getElementById('grabber');
      if (grabberElement) {
        grabberElement.addEventListener('mousedown', (event) => {
          this.grabber = true;
          this.originalX = event.clientX;
        });
        this.grabberSet = true;

      }
    }, 2000);
  }

  changeMode(mode) {
    this.mode = mode;
    this.newEMailSearch();
    // let mails = this.getMails(this.emails);
    // if (mails.length > 0) {
    //   this.renderContent(mails[0]);
    // }
  }

  loadContact(contactId) {
    this.methodService.launchInternalMethod(InternalMethodType.LoadConversionContact, [contactId]);
  }

  itemIsFiltered(mail) {
    let returnValue = true;
    if (
      this.commonService.checkIfStringContainsString(mail._1130_from, this.filterString) ||
      this.commonService.checkIfStringContainsString(mail._1130_to, this.filterString) ||
      this.commonService.checkIfStringContainsString(mail._1130_subject, this.filterString) ||
      this.commonService.checkIfStringContainsString(mail._1130_textbody, this.filterString) ||
      this.commonService.checkIfStringContainsString(mail._1130_htmlbody, this.filterString)
    ) {
      returnValue = false;
    }

    // Additional filter
    switch(this.filterId) {
      case 1:
        if (mail._1130_contactid == null) { returnValue = true; }
        break;
      case 2:
        break;
      case 3:
        if (mail._1130_contactid == null) { returnValue = true; }
        break;
      case 4:
        if (mail._1130_hasattachements == false) { returnValue = true; }
        break;
    }

    return returnValue;
  }

  createContact(mail) {
    let mailObject = JSON.parse(JSON.stringify(mail));
    if (this.mode == 3) {
      mail._1130_from = mail._1130_to;
    }
    this.wizardService.showWizard(WizardMode.MailToContact, mailObject, '90', '90');
  }

  getAttachments(mail) {
    this.attachments = [];
    this.externaldatasourceService.executeExternalDataSource(691, [mail.id]).then(getAttachmentsResult => {
      this.attachments = getAttachmentsResult;
    });
  }

  downloadAttachment(attachment) {
    let fileDetails = attachment.filename.split('.');
    this.sharedAPI.downloadAttachment(attachment.id, null).subscribe(result => {
      this.commonService.downloadFile(result, 'application/'.concat(fileDetails[fileDetails.length-1]), this.getAttachmentFileName(attachment.filename));
    })
  }

  calculateSize(value) {
    value = Math.floor(value / 1024);
    if (value < 1) { value = 1; }
    return value;
  }

  getContactTitle(mail) {
    let contactName = '';
    if (mail._4_firstname) {
      contactName = contactName.concat(mail._4_firstname, ' ');
    }
    if (mail._4_lastname) {
      contactName = contactName.concat(mail._4_lastname);
    }
    let message = this.axivasTranslateService.getTranslationTextForToken('SMTPSettings.Label.LoadMailContact');
    message = message.replace('<0>', contactName);
    return message;
  }

  createConversion(mail) {
    this.loaderService.display(true);
    this.smtpService.createEvent(mail).then(event => {
      this.smtpService.createConversion(mail._1130_contactid, event, mail, this.applicationInfoService.campaingnModeId)
      .then(createConversionResult => {
        mail._1130_conversionid = createConversionResult.id;
        this.loaderService.display(false);
      }).catch(() => { this.loaderService.display(false); });
    }).catch(() => { this.loaderService.display(false); });
  }

  toggleVisibility(selectedMail) {
    this.externaldatasourceService.executeExternalDataSource(705, [
      this.commonService.getModifyArrayBody({ id: selectedMail.id, isVisible: !selectedMail._1130_isvisible }, [])
    ]).then(() => {
      selectedMail._1130_isvisible = !selectedMail._1130_isvisible;
    })
  }

  getAttachmentFileName(filename) {
    if (filename == null) { return ''; }
    const fileNameDetails = filename.split('/');
    return fileNameDetails[fileNameDetails.length -1];
  }

  onScroll(event) {
    let referenceHeight = event.target.scrollHeight - event.target.offsetHeight;
    referenceHeight = referenceHeight - 60;
    if( event.target.scrollTop >= (referenceHeight)) {
      if ((this.emailPaginationPage * this.emailPaginationPageSize) == this.emails.length) {
        this.emailPaginationPage = this.emailPaginationPage + 1;
        this.getEmails();
      }
    }
  }

  getMailPartnerAdress(selectedMail) {
    if (this.mode == 2) {
      return selectedMail._1130_from;
    } else {
      if (this.mode == 3) {
        return selectedMail._1130_to;
      }
    }
  }
}

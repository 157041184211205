<div class="navigationShortCutWrapper">
    <div *ngIf="mode==1" class="navigationShortCutMode1">
        <div>
            
        </div>
        <!-- Setting a new shortcut -->
        <div *ngFor="let availableOption of availableOptions; let itemIndex = index">
            <div *ngIf="availableOption?.children?.length > 0">
                <div class="shortCutHeaderline">
                    {{ availableOption.defaultName | texttransform }}
                </div>
                <div class="shortCutChildren">
                    <div *ngFor="let child of availableOption.children" class="childWrapper">
                        <div *ngIf="showJsonMenuItem(child)">
                            <div class="shortCutChild fingerCursor" (click)="setChild(child)" [ngClass]="{
                                shortCutChildHighlighted: childIsSelected(child)
                            }">
                                <div class="shortCutChild__check">
                                    <app-jj-icons [type]="'square'" [width]="20" [height]="20" *ngIf="!childIsSelected(child)"></app-jj-icons>
                                    <app-jj-icons [type]="'check-square-offset'" color="white" [width]="20" [height]="20" *ngIf="childIsSelected(child)"></app-jj-icons>
                                </div>
                                <div class="shortCutChild__icon" *ngIf="!childIsSelected(child)">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g id="Icons Men&#195;&#188;">
                                            <path id="Vector" d="M13 13V26" stroke="#5465FF" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path id="Vector_2" d="M4 13H28" stroke="#5465FF" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path id="Vector_3"
                                                d="M27 6H5C4.44772 6 4 6.44772 4 7V25C4 25.5523 4.44772 26 5 26H27C27.5523 26 28 25.5523 28 25V7C28 6.44772 27.5523 6 27 6Z"
                                                stroke="#5465FF" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </g>
                                    </svg>
                                </div>
                                <div class="shortCutChild__icon" *ngIf="childIsSelected(child)">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g id="Icons Men&#195;&#188;">
                                            <path id="Vector" d="M13 13V26" stroke="#ffffff" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path id="Vector_2" d="M4 13H28" stroke="#ffffff" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path id="Vector_3"
                                                d="M27 6H5C4.44772 6 4 6.44772 4 7V25C4 25.5523 4.44772 26 5 26H27C27.5523 26 28 25.5523 28 25V7C28 6.44772 27.5523 6 27 6Z"
                                                stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </g>
                                    </svg>
                                </div>
                                <div class="shortCutChild__text ellipsisText">
                                    {{ child.defaultName | texttransform }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="customTextControlWrapper">
  <label class="customTextControlWrapper__label">{{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}</label>
  <div class="customControl__dataLine customTextControlWrapper__valueWrapper"> 
    <input class="customControl__dataLine__dataControl customTextControlWrapper__textbox"    
      type="textbox"
      [disabled]="!checkIfControlIsEnabled()"      
      (change)="callMethod('onchange')"
      [placeholder]="commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform"
      [(ngModel)]="controlValue"
      [tippy]="controlValue"
    >  
  </div> 
</div>

<!-- 
<div class="multilist__wrapper" [ngClass]="{ darkMode: settingJSON?.controlSettingGeneralDarkMode == true }">
    <app-documentation-link [infoLink]="documentationPage"></app-documentation-link>
    <div class="multilist__description">
        {{ controlDefinition.displayText | texttransform }}
    </div>
    <div class="multilist__header">
        <div class="multilist__header__label">
            {{ 'MultiList.Label.AvailableValues' | texttransform }}
            <app-jj-icons type="arrow-square-out" [width]="20" [height]="20" (click)="editLookupTable()"></app-jj-icons>
        </div>
        <div class="multilist__header__addline">
            <div class="multilist__header__select">
                <select [(ngModel)]="selectedValue" class="multilist__selection">
                    <option *ngFor="let availableValue of availableValues" [ngValue]="availableValue">
                        {{ availableValue.defaultName }}
                    </option>
                </select>    
            </div>
            <div class="multilist__header__plus" (click)="addItemToMultilist()">
                <app-jj-icons type="plus-bold" [width]="20" [height]="20"></app-jj-icons>
            </div>    
        </div>
    </div>

    <div class="multilist__content" 
        *ngIf="addedDataValues.length > 0">
        <div class="multilist__header__label">
            {{ 'MultiList.Label.AddedValues' | texttransform }}
        </div>
        <div class="multilist__content__item" *ngFor="let addedDataValue of addedDataValues">         
            <div class="multilist__content__item__delete" (click)="deleteItem(addedDataValue)">
                <app-jj-icons type="trash" [width]="20" [height]="20"></app-jj-icons>
            </div>

            <div class="multilist__content__item__name">
                {{ getValueName(addedDataValue) }}
            </div>
        </div>
    </div>
</div> -->


<div class="customMultilist" [ngClass]="{ darkMode: settingJSON?.controlSettingGeneralDarkMode == true }">
    <app-documentation-link [infoLink]="documentationPage"></app-documentation-link>
    <div class="customMultilist__description">
        {{ controlDefinition.displayText | texttransform }}
    </div>
    <div class="customMultilist__input">
        <div class="customMultilist__label">
            <span>{{ 'MultiList.Label.AvailableValues' | texttransform }}</span>
            <span>
                <app-jj-icons type="arrow-square-out" [width]="20" [height]="20" (click)="editLookupTable()"></app-jj-icons>
            </span>
        </div>
        <div class="customMultilist__valueWrapper">
            <div class="select__box">
                <select [(ngModel)]="selectedValue" class="select">
                    <option *ngFor="let availableValue of availableValues" [ngValue]="availableValue">
                        {{ availableValue.defaultName }}
                    </option>
                </select>    
            </div>
            <div class="plus" (click)="addItemToMultilist()">
                <app-jj-icons type="plus-bold" [width]="20" [height]="20"></app-jj-icons>
            </div>    
        </div>
    </div>

    <div class="customMultilist__content" 
        *ngIf="addedDataValues.length > 0">
 <!--        <div class="multilist__header__label">
            {{ 'MultiList.Label.AddedValues' | texttransform }}
        </div> -->
        <div class="customMultilist__content__item" *ngFor="let addedDataValue of addedDataValues">         
            <div class="customMultilist__content__item__svg" (click)="deleteItem(addedDataValue)">
                <app-jj-icons type="x" [width]="20" [height]="20" color="primaryLight"></app-jj-icons>
            </div>

            <div class="customMultilist__content__item__name">
                {{ getValueName(addedDataValue) }}
            </div>
        </div>
    </div>
</div>
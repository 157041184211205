import { Injectable, Injector } from '@angular/core';
import { SignalRBaseClass } from './signalrbaseclass';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportingSignalrService extends SignalRBaseClass  {

  constructor (
    private injector: Injector,
  ) { 
    super(injector);
    this.signalRUrl = environment.signalRUrl;
    this.signalRName = 'Reporting';
  }


  initService(): Promise<any> {
    console.warn('InitService: ', this.signalRName)
    return new Promise((intiServiceResolve, intiServiceReject) => {
      this.InitializeDefaultConnection().then(data => {
        console.warn('InitService:', this.signalRName, '=> init success');
        this.addHubConnectionMethods();
        intiServiceResolve(null);
      })
      .catch(error => {
        console.log('InitService:', this.signalRName, '=> Init error', error);
        intiServiceReject('init ' + error);
      });
    });
  }

  addHubConnectionMethods() {
    this.hubConnection.on('NotifyReportingAvailable', (data: any) => {
      console.warn('NotifyReportingAvailable', data);
    });

    this.hubConnection.on('NotifyReportingFailed', (data: any) => {
      console.warn('NotifyReportingFailed', data);
    });

    this.hubConnection.on('OnNotifyReportingAvailable', (data: any) => {
      console.warn('OnNotifyReportingAvailable', data);
    });

    this.hubConnection.on('OnNotifyReportingFailed', (data: any) => {
      console.warn('OnNotifyReportingFailed', data);
    });

    this.hubConnection.on('onPing', (data: any) => {
      console.warn('onPing', data);
    });

    console.log('ReportingSignalr hubConnections done');
  }
}

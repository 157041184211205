<div *ngIf="eventData.length > 0">
    <app-layout class="agComponentHeader__header" [header]="'ConversionEditor.Label.DataArray' | texttransform">
        <div *ngFor="let eventDataItem of eventData" class="conversionDetail" [ngClass]="{ 'conversionDetail__darkMode': darkMode }">
            <div class="conversionDetail__name">
                {{ eventDataItem.name | texttransform }}
            </div>        
            <div class="conversionDetail__value" [innerHTML]=eventDataItem.value></div>
        </div>    
    </app-layout>
</div>

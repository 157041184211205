import { Component, ElementRef, OnDestroy, OnInit, ViewChild,  } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-phone-dashboard',
  templateUrl: './phone-dashboard.component.html',
  styleUrls: ['./phone-dashboard.component.scss']
})
export class PhoneDashboardComponent implements OnInit , OnDestroy{

ringCentralEventSubscription: Subscription = new Subscription();
customEventSubscription: Subscription = new Subscription();


// ! user objects and numbers

// * call
outgoingCallUser
callingNumber :any= '';
userCall:{}

// * transfer call
transferCallUser:any = {}
transferNumber = '';

// * incomingCall
incomingNumber:string;
showIncomingCall = false;
incomingUser={};


// ! loader -> not in use yet
loadingData = false;

// ! logout 
logoutRequest: boolean = false

// ! input validator
isNumberInvalid = false

// ! arrays from API
adressBook: any = [];
callHistory: any= [];


// !loader
loading:boolean= false;


// ! filterLists and dropdowns
// * filter for adressBook
adressBookDisplay
searchString:string =""

//* filter for input dropdowns
@ViewChild('dropdownList',{read: ElementRef }) dropdownList: ElementRef;
dropdownInputData :any = [];
selectedIndex:number = - 1;
blockTransferInput=false;



// ! tabs
selectedTab: number = 1;
tabs:any = [
    { name: 'Webphone.Label.Call', id: 0},
    { name: 'Webphone.Label.PhoneDirect', id: 1 },
    { name: 'Webphone.Label.TransferCall', id: 2 },
    { name: 'Webphone.Label.CallHistory',  id: 3 },
    { name: 'Webphone.Label.PhoneBook', id: 4 }
  ];

visibleTabs:any =this.tabs


// ! keypad
keyPadMenu = false
onCallKeyPad=false
keypad:any=[
    {id:1, value:1},
    {id:2, value:2},
    {id:3, value:3},
    {id:4, value:4},
    {id:5, value:5},
    {id:6, value:6},
    {id:7, value:7},
    {id:8, value:8},
    {id:9, value:9},
    {id:10, value:'*'},
    {id:11, value:0},
    {id:12, value:'#'},
  ];

  constructor(
    public ringcentralwebphoneService: RingcentralwebphoneService,
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private commonService: CommonService,
    private externalDataSourceService: ExternaldatasourceService,
  ) { }

  ngOnInit(): void {


    // ! ringcentral event subscription coming from the ringcentralwebphone SERVICE .
    // ! this is also being used on the widget
    this.ringCentralEventSubscription = this.ringcentralwebphoneService.ringCentralWebPhoneEvent.subscribe(event =>{
      //console.log('phone', event.id)
      if (event.id == "dashboardOutgoingCall") {
        const findUser = this.adressBookDisplay?.find(
          (item) =>
            item.extension === event.number ||
            item.phoneNumberNF == event.number
        );

        if (findUser) {
          this.userCall = {
            ...findUser,
            selectedNumber: event.number,
            adressBook: true,
          };
        } else {
          this.userCall = { adressBook: false, selectedNumber: event.number };
        }

        if (this.commonService.isNullOrUndefined(this.userCall)) {          
          this.userCall = { selectedNumber: event.number };
        }

        this.visibleTabs = [...this.tabs].filter(
          (item) => item.id != 1 && item.id != 2 && item.id != 3
        );
        this.selectedTab = 0;

        //console.log('this.visibleTabs', this.visibleTabs)

        this.eventService.customEvent.emit({
          id: "phone-dashboardOutgoingCall",
          user: this.userCall,
        });
      }
      

      if(event.id == "expandExtension"){
        //console.log('phone', event.id)
        
        this.getRingCentralAdressBookData()
        this.getRingCentralHistoryCall()
        this.visibleTabs = [...this.tabs].filter(item => item.id !=0 && item.id != 2)
        this.selectedTab=1
        //console.log('this.visible tabs', this.visibleTabs)
      }

      if(event.id == 'hangUp'){
        //console.log('phone', event.id)
        this.resetAllVariables()
      }

      if (event.id === 'callTerminated'){
        //console.log('phone', event.id)
       this.resetAllVariables()
       //console.log('DASHBOARD', event.id)
      }

      if(event.id === 'callRejected'){
        //console.log('phone', event.id)
        this.resetAllVariables()
      }

      if(event.id === 'callAccepted'){
        //console.log('phone', event.id)
        this.eventService.customEvent.emit({ 
          id: 'phone-dashboardCallAccepted',
          user: this.userCall
        });
      }

      if(event.id === 'startTransferCall'){
        //console.log('phone', event.id)
        this.visibleTabs = [...this.tabs].filter(item => item.id != 1 && item.id != 3)
        this.selectedTab = 2
      }

      if(event.id === 'transferCallTerminated'){
        //console.log('phone', event.id)
        this.transferCallUser={}
        this.transferNumber=""
        this.blockTransferInput=false;
        this.selectedTab=0
        this.visibleTabs = [...this.tabs].filter(item => item.id != 1 && item.id != 2 && item.id != 3)
      }

      if(event.id === 'incomingCall'){   
        //console.log('phone', event.id)             
        this.incomingNumber = event.incomingNumber;

        this.eventService.openIncomingCallEvent.emit(this.incomingNumber);

        const findUser = this.adressBook?.find(item =>  
          this.isIncomingNumber(item, this.incomingNumber)
        )
        if(findUser){
         this.userCall = {...findUser, selectedNumber:this.incomingNumber, adressBook: true}
        } else {
          this.userCall= {adressBook:false, selectedNumber: this.incomingNumber}
        }
        this.eventService.customEvent.emit({
          id: 'phone-dashboardIncomingCallUser',
          user: this.userCall
        })
        this.onIncomingCall()

      }

    })

    // ! custom event  communicate only with phone.ringcentral component
    this.customEventSubscription = this.eventService.customEvent.subscribe((event)=>{

      if(event.id == 'widgetLoginRequest') {
        this.visibleTabs = [...this.tabs].filter(item => item.id !=0 && item.id != 2)
      }
      if(event.id == 'widgetCloseCall'){
        this.closeCall()
      }

      if(event.id === 'widgetLogoutRequest'){
        this.logoutRequest = true;
      }

      if(event.id === 'widgetCallClosed'){
        this.resetAllVariables()
      }
      if(event.id ==='widgetMenu'){
        if(event.menuState == false && this.logoutRequest == true){
          this.logoutRequest =  false
        }
      }

    })

  }

  isIncomingNumber(item, phoneNumber) {
    const phoneArray = [
      item.phoneNumber,
      item.extension,
      item.phoneNumberNF 
    ]
    return this.commonService.isPhoneNumberInNumberArray(phoneArray, phoneNumber);
  }

  onIncomingCall(){
    this.showIncomingCall = true;
    this.visibleTabs=[...this.tabs].filter(item => item.id != 2 && item.id !=4)
    this.selectedTab = 0
  }

  acceptCall(){
   this.ringcentralwebphoneService.accept()
  }

  rejectCall(number){
  this.ringcentralwebphoneService.reject()
  }

  logout(){
    this.ringcentralwebphoneService.logout()
  }

  cancelLogoutRequest(){
    this.logoutRequest = false,
    this.selectedTab = 1;
  }

  getRingCentralAdressBookData() {
    this.ringcentralwebphoneService.getAdressBook().then(getAdressbookResult => {
      //console.log('getAddressbookResult', getAdressbookResult)
      this.loading= true;
      getAdressbookResult.records.forEach(getAdressbookResultItem => {
        getAdressbookResultItem['extension'] = getAdressbookResultItem.extensionNumber;
        if (getAdressbookResultItem.phoneNumbers) {
          if (getAdressbookResultItem.phoneNumbers.length > 0) {
            getAdressbookResultItem['phoneNumber'] = getAdressbookResultItem.phoneNumbers[0].formattedPhoneNumber;
          }
        }
      })
      this.adressBook= getAdressbookResult.records.sort((a: any, b: any) => a.lastName < b.lastName ? -1 : 1).map((item)=>{
        if(item.phoneNumber){
          const phoneNumberNoF= '+' + item.phoneNumber?.replace(/[^\d]/g, '')
          return{...item, phoneNumberNF: phoneNumberNoF}
        } else {
          return item
        }
      })
      this.loading= false;
      this.adressBookDisplay= JSON.parse(JSON.stringify(this.adressBook));
      this.sortAdressbook();
      this.dropdownInputData=  JSON.parse(JSON.stringify(this.adressBook));

      //console.log('adressBook', this.adressBook)
    
    });

  }

  getRingCentralHistoryCall(){
    this.ringcentralwebphoneService.getRecentCalls().then(getRecentCallsResult => {
      this.loading =true;
      getRecentCallsResult.records.forEach(getRecentCallsResultItem => {
        if (getRecentCallsResultItem.direction == 'Outbound') {
          getRecentCallsResultItem['phoneNumber'] = getRecentCallsResultItem.to.phoneNumber;
        } else {
          getRecentCallsResultItem['phoneNumber'] = getRecentCallsResultItem.from.phoneNumber;
        }
        getRecentCallsResultItem['fromNumber'] = getRecentCallsResultItem.from.phoneNumber;
        getRecentCallsResultItem['toNumber'] = getRecentCallsResultItem.to.phoneNumber;
        getRecentCallsResultItem['start'] = getRecentCallsResultItem.startTime;
        getRecentCallsResultItem['durationFormatted'] = this.commonService.getDurationString(getRecentCallsResultItem.duration);
      });
      this.callHistory = getRecentCallsResult?.records;
      this.loading= false;

      //console.log('call History', this.callHistory)
    });
  }

  makeCall(number) {
    if (this.applicationInfoService.applicationSettings['isDemoproject'] == 'true' && !this.applicationInfoService.isDeveloper) {
      return;
    }

    this.ringcentralwebphoneService.makeCall(number);
  }

  closeCall(){
    this.ringcentralwebphoneService.hangUp()
    this.eventService.customEvent.emit({ id: 'dashboardCloseCall' });
    this.resetAllVariables();
  }

  resetAllVariables(){
    this.onCallKeyPad = false;
    this.userCall= undefined;
    this.transferCallUser= undefined;
    this.callingNumber = "";
    this.transferNumber = "";
    this.visibleTabs = [...this.tabs].filter(item => item.id != 0 && item.id!= 2);
    this.selectedTab = 1;
    this.showIncomingCall=false;
    this.searchString="";
    this.blockTransferInput=false;
 
   
  }

  makeTransferCall(number){
    this.dropdownInputData=[];
    this.ringcentralwebphoneService.transferCall(number)
    this.blockTransferInput=true;
  }


 transferAdressBookContact(contact, type){
    if(type === 'extension'){
      this.transferNumber = contact.extensionNumber
    } else {
      this.transferNumber = '+' + contact.phoneNumberNF
    }

    this.transferCallUser = {
      ...contact,
      adressBook:true,
      selectedNumber:this.transferNumber,
    }

    this.searchString= this.transferNumber;
    this.selectedTab = 2
    this.dropdownInputData=[];
    this.blockTransferInput=true;
    this.ringcentralwebphoneService.transferCall(this.transferNumber)
  
  } 

  makeCallAdressBookContact(contact, type){
    if(type === 'extension'){
      this.callingNumber = contact.extensionNumber
    } else {
      this.callingNumber = contact.phoneNumberNF
    }
    this.userCall = {
      ...contact,
      adressBook:true,
      selectedNumber:this.callingNumber,
    }

    this.dropdownInputData=[];
    this.makeCall(this.callingNumber)
  }
  
  makeCallHistoryContact(number){
    this.callingNumber =  number
    const adressBook  = this.adressBookDisplay?.find((item) =>   item.phoneNumbers?.find(i => i.phoneNumber === this.callingNumber) || item.phoneNumberNF === this.callingNumber ||  item.extensionNumber === this.callingNumber)

    if(adressBook){
      this.userCall = {
        ...adressBook,
        adressBook:true,
        selectedNumber:this.callingNumber,
      }
    } else {
      this.userCall = {
        selectedNumber: this.callingNumber,
        adressBook: false
    }
  }
  this.dropdownInputData=[];
  this.makeCall(this.callingNumber)
}

  cancelTransferCall(){
    this.transferNumber = ""
    this.ringcentralwebphoneService.stopTransferProcess()
    this.blockTransferInput=false
  }


  setCurrentTab(tab) {
    this.selectedTab = tab.id;

    if(tab.id == 4 ){


      if(this.adressBook.length === 0 ){
        this.searchString="";
        this.getRingCentralAdressBookData()
      } else {
        this.searchString="";
        this.adressBookDisplay= JSON.parse(JSON.stringify(this.adressBook));
        this.sortAdressbook();
      }
    }

    if(tab.id == 3){
      this.getRingCentralHistoryCall()
    
    }

  }


  keyPadValue(selectedTab:number, key:any) {
    //console.log('session key pad',this.ringcentralwebphoneService.session)
    if (this.ringcentralwebphoneService.session == null) {

      // * only happens on calling a new number
      if(selectedTab == 1){
        this.callingNumber = this.callingNumber + key
        this.validateNumber(this.callingNumber)
        this.dropdownInputData=[];
      } 

    } else {
      // Call active, sending 
      // * only happens on transfer
      if(selectedTab == 2){
        this.transferNumber = this.transferNumber + key
        this.ringcentralwebphoneService.sendDTMF(key)
      }

      //console.log('here if not null -> keypad', key);
 
    }
  }

  toggleOnCallKeyPad(){
    this.onCallKeyPad = !this.onCallKeyPad
  }

  keypadOnCall(key){
    this.callingNumber = this.callingNumber + key
    this.ringcentralwebphoneService.sendDTMF(key)
  }

  validateNumber(number) {
    // ! number starts with + or number 
    const regexPattern= /^[+\d]+$/;

    // ! number starts with + or number includes #
    /* const regexPattern= /^[+\d]([0-9#]*[^\s#])?$/ */
    // ! number start with + or # or number
    //const regexPattern= /^[\d+#]\d*$/;
    this.isNumberInvalid = regexPattern.test(number)
  }

  deleteKeyNumber(){
    this.callingNumber = this.callingNumber.slice(0,-1)
  }


  // ! FILTERS DATA API

  applyFilter() {
    this.adressBookDisplay = JSON.parse(JSON.stringify(this.adressBook)).filter(item =>    
    this.commonService.checkIfStringContainsString(item.firstName, this.searchString) ||
    this.commonService.checkIfStringContainsString(item.lastName, this.searchString) ||
    this.commonService.checkIfStringContainsString(item.extension, this.searchString)
   /*  this.commonService.checkIfStringContainsString(item.phoneNumber, this.searchString) ||
    this.commonService.checkIfStringContainsString(item.phoneNumberNF, this.searchString) */
    );
    this.sortAdressbook();
  }

  sortAdressbook() {
    this.adressBookDisplay.sort((a, b) => a.lastName < b.lastName ? -1 : 1);
  }

  onMouseEnter(){
    this.selectedIndex = -1;
  }

  filterInput($event, searchString:any){
    this.dropdownInputData= JSON.parse(JSON.stringify(this.adressBook)).filter(item =>
      this.commonService.checkIfStringContainsString(item.firstName, searchString) ||
      this.commonService.checkIfStringContainsString(item.lastName, searchString) ||
      this.commonService.checkIfStringContainsString(item.phoneNumber, searchString) ||
      this.commonService.checkIfStringContainsString(item.extension, searchString) ||
      this.commonService.checkIfStringContainsString(item.phoneNumberNF, searchString)
      )

      // console.log('$event', $event)

      this.validateNumber(searchString)
      if($event.key == 'ArrowUp'){
        this.selectedIndex = Math.max(this.selectedIndex -1, 0);
        const selectedItem = this.dropdownList.nativeElement.querySelector('.active')
        if(selectedItem){
          selectedItem.scrollIntoView({behavior:'instant',block: "end", inline: "nearest",})
        }
      }

      if($event.key == 'ArrowDown'){
        this.selectedIndex = Math.min(this.selectedIndex + 1, this.dropdownInputData.length -1)
        const selectedItem = this.dropdownList.nativeElement.querySelector('.active')
        if(selectedItem){
          selectedItem.scrollIntoView({behavior:'instant',block: "start", inline: "nearest",})
        }
      }

      if($event.key == 'Enter'){
     
          if(this.dropdownInputData.length === 1){
            this.setSelectedContact(this.dropdownInputData[0], searchString)
          } else if( this.dropdownInputData.length > 1){
            this.setSelectedContact(this.dropdownInputData[this.selectedIndex], searchString)
          } else {
            if(this.ringcentralwebphoneService.sessionState.startTransfer){
              this.transferNumber = searchString;
              this.transferCallUser={
                selectedNumber: this.transferNumber,
                adressBook: false
              }
              this.dropdownInputData=[];
            } else {
              this.callingNumber = searchString;
              this.userCall={
                selectedNumber: this.callingNumber,
                adressBook: false
              }
              this.dropdownInputData=[];
              this.selectedIndex=-1;
            }
          }
              
      } else {

        const adressBook= this.adressBook.find(item =>
        this.commonService.checkIfStringContainsString(item.phoneNumberNF, searchString) ||
        this.commonService.checkIfStringContainsString(item.extension, searchString))

        const extension = this.adressBook.find( item => this.commonService.checkIfStringContainsString(item.extension, searchString))
        const phoneNumber = this.adressBook.find(item => this.commonService.checkIfStringContainsString(item.phoneNumberNF, searchString))

          if(adressBook){
            if(this.ringcentralwebphoneService.sessionState.startTransfer){
              this.transferCallUser={
                ...adressBook,
                selectedNumber: extension ? extension.extension : phoneNumber.phoneNumberNF,
                adressBook:true,  
              }
            } else {
              this.userCall={
                ...adressBook,
                selectedNumber: extension ? extension.extension : phoneNumber.phoneNumberNF,
                adressBook:true,  
              }
            }
          } else {
            if(this.ringcentralwebphoneService.sessionState.startTransfer){
              this.transferCallUser={
                selectedNumber: searchString,
                adressBook:false
                
              }
            } else {
              this.userCall={
                selectedNumber: searchString,
                adressBook:false
                
            }
          }
          }
    }
}

  setSelectedContact(contact: any, searchString){
  // console.log('contact', contact)
    if(this.ringcentralwebphoneService.sessionState.startTransfer){
      this.transferNumber = this.getMatchingContactString(contact, searchString)
      this.validateNumber(this.transferNumber);
      this.transferCallUser={
        ...contact,
        adressBook:true,
        selectedNumber: this.transferNumber,
      }
      this.dropdownInputData=[]

    } else {
     this.callingNumber = this.getMatchingContactString(contact, searchString);
      this.userCall={
        ...contact,
        adressBook:true,
        selectedNumber:this.callingNumber,
      }
      this.validateNumber(this.callingNumber);
      this.dropdownInputData=[];
    }
   
  }

  getMatchingContactString(contact, searchString){
    if(this.commonService.checkIfStringContainsString(contact.firstName, searchString) || this.commonService.checkIfStringContainsString(contact.lastName, searchString) ){
      return contact.extension
    }

    if(this.commonService.checkIfStringContainsString(contact.phoneNumberNF, searchString)){
      return contact.phoneNumberNF
    }

    if(this.commonService.checkIfStringContainsString(contact.extension, searchString)){
      return contact.extension
    }

    if(this.commonService.checkIfStringContainsString(contact.phoneNumber, searchString)){
      return contact.phoneNumberNF
    }

    if(this.commonService.checkIfStringContainsString(contact.phoneNumberNF, searchString) && this.commonService.checkIfStringContainsString(contact.extension, searchString)){
      return contact.extension
    }

  }


  ngOnDestroy():void{
    if (this.customEventSubscription) {
      this.customEventSubscription.unsubscribe();
    }

    if(this.ringCentralEventSubscription){
      this.ringCentralEventSubscription.unsubscribe();

    }
  }


}

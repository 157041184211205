<div class="flexDashboard" [id]="type">
    <div class="flexDashboard__header">
        <app-component-header [header]="type === 'myvisitors' ? 'WebsiteDashboard.Label.MyVisitors' : 'WebsiteDashboard.Label.MyWebleads'"></app-component-header>

        <div class="flexDashboard__nav">
            <app-select-input
            *ngIf="type === 'myvisitors'"
            label="WebsiteDashboard.Label.Domain"
            [(ngModel)]="selectedDomain"
            (valueChange)="getMyVisitorsKPIs()"
            [options]="domains"
            [complex]="true"
            display="option"
            ></app-select-input>

            <div class="flexDashboard__inputWrapper">
                <app-select-input
                label="WebsiteDashboard.Label.IntervallLast"
                [(ngModel)]="lastXDays"
                (valueChange)="type === 'myvisitors' ? getMyVisitorsKPIs() : getMyConversionsKPIs()"
                [options]="[{value:3, label: '3' }, {value:15, label:'15'}, {value:30, label:'30'},{value:60, label:'60'}]"
                bind="value"
                display="label"
                ></app-select-input>
                <div class="flexDashboard__inputWrapper__extra">{{ 'WebsiteDashboard.Label.IntervallDays' | texttransform }}</div>
            </div>
        </div>
    </div>

    <div class="flexDashboard__inner">

        <span *ngIf="loading" class="dashboardcomponentMyVisitors__spinner">
            <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'General.Label.LoadingData' | texttransform }}
        </span>

        <div class="flexDashboard__content" *ngIf="!loading">
            <div class="flexDashboard__info" *ngIf="kpis != null">
                <section class="flexDashboard__info__inner" *ngIf="type === 'myvisitors'">
                    <div class="flexDashboard__info__leftside">
                        <div class="flexDashboard__info__row">
                            <div class="flexDashboard__info__row__label">{{ 'WebsiteDashboard.Label.Web' | texttransform }}</div>
                            <div class="flexDashboard__info__row__value">
                                {{ kpis.trend }}% <app-jj-icons type="info-fill" [width]="20" [height]="20" [tippy]="'WebsiteDashboard.Label.TrendDescription' | texttransform" [tippyOptions]="{placement:'top'}"></app-jj-icons>
                            </div>
                        </div>
                        <div class="flexDashboard__info__row">
                            <div class="flexDashboard__info__row__label">{{ 'WebsiteDashboard.Label.IntervallVisitors' | texttransform}}</div>
                            <div class="flexDashboard__info__row__value">
                                {{ kpis.activeVisitorCountInterval }}  <app-jj-icons [pointer]="false" [type]="kpis.trend > 0 ? 'trend-up' : 'trend-down'" [width]="25" [height]="25"></app-jj-icons>
                            </div>
                        </div>
                    </div>

                    <div class="flexDashboard__info__divider"></div>

                    <div class="flexDashboard__info__rightside">
                        <div class="flexDashboard__info__row">
                            <div class="flexDashboard__info__row__label">
                                {{ 'WebsiteDashboard.Label.ActiveVisitors' | texttransform }}
                            </div>
                            <div class="flexDashboard__info__row__value">
                                {{ kpis.activeUsers }}<app-jj-icons type="info-fill" [width]="20" [height]="20" [tippy]="'WebsiteDashboard.Label.ActiveVisitorsHoverText' | texttransform" [tippyOptions]="{placement:'top'}"></app-jj-icons>                     
                            </div>
                        </div>
                        <div class="flexDashboard__info__row">
                            <div class="flexDashboard__info__row__label">
                                {{ 'WebsiteDashboard.Label.IdentifiedCompanies' | texttransform }}
                            </div>
                            <div class="flexDashboard__info__row__value">
                                {{ kpis.identifiedCompanies }}<app-jj-icons type="info-fill" [width]="20" [height]="20" [tippy]="'WebsiteDashboard.Label.IdentifiedCompaniesHoverText' | texttransform" [tippyOptions]="{placement:'bottom'}"></app-jj-icons>                      
                            </div>
                        </div>
                        <div class="flexDashboard__info__row">
                            <div class="flexDashboard__info__row__label">
                                {{ 'WebsiteDashboard.Label.Duration' | texttransform }}
                            </div>
                            <div class="flexDashboard__info__row__value">
                                {{ kpis.avgPageViewTime }}s <app-jj-icons [pointer]="false" type="clock" [width]="20" [height]="20"></app-jj-icons>
                            </div>
                        </div>   

                    </div>
                </section>

                <section class="flexDashboard__info__inner" *ngIf="type === 'myconversions'">
                    <div class="flexDashboard__info__leftside">
                        <div class="flexDashboard__info__row">
                            <div class="flexDashboard__info__row__label">
                                {{ 'WebsiteDashboard.Label.Web' | texttransform }}                        
                            </div>
                            <div class="flexDashboard__info__row__value">
                                {{ kpis.trend }}%
                            </div>
                        </div>
                
                        <div class="flexDashboard__info__row">
                            <div class="flexDashboard__info__row__label">
                                <div class="ellipsisText">{{ 'WebsiteDashboard.Label.IntervallConversions' | texttransform }}</div>
                            </div>
                            <div class="flexDashboard__info__row__value">
                                {{ kpis.conversionCountInterval }} <app-jj-icons [pointer]="false" [type]="kpis.trend > 0 ? 'trend-up' : 'trend-down'" [width]="25" [height]="25"></app-jj-icons>
                            </div>
                        </div>
                    </div>

                    <div class="flexDashboard__info__divider"></div>

                    <div class="flexDashboard__info__rightside">
                        <div class="flexDashboard__info__row">
                            <div class="flexDashboard__info__row__label">
                                {{ 'WebsiteDashboard.Label.AllConversions' | texttransform }}
                            </div>
                            <div class="flexDashboard__info__row__value">
                                {{ kpis.conversionCountAllTime }}                      
                            </div>
                        </div>
                        <div class="flexDashboard__info__row">
                        </div> 

                    </div>
                </section>

            </div>


           <!--  <div class="flexDashboard__chart__header">{{ 'WebsiteDashboard.Label.Sources' | texttransform }}</div> -->
            <div class="flexDashboard__chart" *ngIf="kpis != null">
               <!--  <div class="flexDashboard__chart__header">{{ 'WebsiteDashboard.Label.Sources' | texttransform }}</div> -->
                <div class="flexDashboard__chart__inner">
                    <div *ngFor="let source of kpis.eventSources" class="flexDashboard__chart__inner__item">
                        <div class="flexDashboard__chart__inner__item__text">
                            {{ source.source | texttransform }}
                        </div>
                        <div class="flexDashboard__chart__inner__item__value">
                            <div class="flexDashboard__chart__inner__item__value__count" >{{ source.count }}</div>
                            <div class="flexDashboard__chart__inner__item__value__percentage"
                                [style.height]="type === 'myvisitors' ? getMyVisitorsPercentHeight(source) : getMyConversionsPercentHeight(source)">                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="dashboardList" *ngIf="!component">
    <div class="dashboardList__header">
        <div class="dashboardList__header__status__data noborder" *ngIf="type ==='overviewData'"></div>
        <div class="dashboardList__header__status noborder" *ngIf="type !=='overviewData'">{{ 'Report.ProjectOverview.Leadstatus' | texttransform }}</div>
        <div class="dashboardList__header__description noborder">{{'List.Label.ProcessingState' | texttransform}}</div>
        <div class="dashboardList__header__value noborder">{{'Report.ProjectOverview.Anzahl' | texttransform}} </div>
        <div class="dashboardList__header__percent noborder">%</div>
        <div class="dashboardList__header__gesamt noborder" *ngIf="type !=='overviewData'"> % {{'Report.ProjectOverview.Summe' | texttransform}}</div>
    </div>

    <div *ngFor="let row of data let index = i" class="dashboardList__row">
        <div class="dashboardList__row__inner" *ngIf="row.name !== 'result'">
            <div class="dashboardList__row__status__data" *ngIf="type==='overviewData'">
                <app-status [color]="row.color" [width]="20" [height]="20"></app-status>
            </div>
            <div class="dashboardList__row__status" *ngIf="type !=='overviewData'">{{row.lead_state}}</div>
            <div class="dashboardList__row__description" *ngIf="type == 'overviewData'"><span></span>{{row.name}}</div>
            <div class="dashboardList__row__description " *ngIf="type !== 'overviewData'">{{row.lead_state_description}}</div>
            <div class="dashboardList__row__value">{{row.anzahl}}</div>
            <div class="dashboardList__row__value">{{row.percentage}}% </div>
            <div *ngIf="type !== 'overviewData'" class="dashboardList__row__value">{{row.gesamtPercentage}}%</div>
        </div>
    </div>

    <div class="dashboardList__total">
        <div class="dashboardList__total__inner" *ngIf="result">
            <div class="dashboardList__row__empty noborder" *ngIf="type ==='overviewData'"></div>
            <div class="dashboardList__row__emptyTwo noborder" *ngIf="type !=='overviewData'"></div>
            <div class="dashboardList__row__total bold noborder">{{'Report.ProjectOverview.Summe' | texttransform }}</div>
            <div class=" dashboardList__row__value bold">{{result?.sum}}</div>
            <div class=" dashboardList__row__value bold">{{ result?.sumPercent }} %</div>
            <div  *ngIf="type !== 'overviewData'" class=" dashboardList__row__value bold">{{result?.sumGesamt}} %</div>
        </div>
    </div>
</div>




<div class="dashboardList" *ngIf="component && component === 'opportunity'">
    <div class="dashboardList__header">
        <div class="dashboardList__header__oppstatus noborder">{{'Report.Opportunity.Probability' | texttransform}}</div>
        <div class="dashboardList__header__oppamount noborder">{{'Report.Opportunity.Amount' | texttransform}}</div>
        <div class="dashboardList__header__oppvalue noborder">{{'Report.Opportunity.Volume' | texttransform}}</div>
        <div class="dashboardList__header__oppexpected noborder">{{'Report.Opportunity.ExpectedValue' | texttransform}}</div>
    </div>

    <div *ngFor="let row of data let index = i" class="dashboardList__row everleadTableRow">
        <div class="dashboardList__row__inner">
            <div class="dashboardList__header__oppstatus">{{row.default_name }}</div>
            <div class="dashboardList__header__oppamount">{{row.amount }} </div>
            <div class="dashboardList__header__oppvalue ">{{row.value | currency:'EUR':true:'1.0' }} </div>
            <div class="dashboardList__header__oppexpected">{{row.expected_value | currency:'EUR':true:'1.0' }}</div>
        </div>
    </div>

    <div class="dashboardList__total">
        <div class="dashboardList__total__inner">
            <div class="dashboardList__header__oppstatus noborder">{{'Report.ProjectOverview.Summe' | texttransform }}</div>
            <div class="dashboardList__header__oppamount noborder">{{ commonService.sumArrayValue(data, 'amount') }}</div>
            <div class="dashboardList__header__oppvalue bold noborder">{{ commonService.sumArrayValue(data, 'value')  | currency:'EUR':true:'1.0' }}</div>
            <div class=" dashboardList__header__oppexpected bold">{{ commonService.sumArrayValue(data, 'expected_value')  | currency:'EUR':true:'1.0' }}</div>
        </div>
    </div>
</div>




<!-- <div *ngIf="component && component === 'contact_Objections'" class="dashboardList borderBottom">
    <div class="dashboardList__header">
        <div class="dashboardList__header__description noborder">{{ 'Report.ProjectOverview.Leadstatus' | texttransform }} {{ leadstatus }}</div>
        <div class="dashboardList__header__value noborder">{{'Report.ProjectOverview.Anzahl' | texttransform}} </div>
    </div>

    <div *ngIf="!controlid">
        <app-accordion [title]="result?.name" [isOpen]="true" [value]="result?.total">
            <div *ngFor="let row of data let index = i" class="dashboardList__row">
                <div class="dashboardList__row__inner">
                    <div class="dashboardList__row__contact__description">{{ row.answer }}</div>
                    <div class="dashboardList__row__contact__value">{{row.anzahl}}</div> 
                </div>
            </div>
            <div class="dashboardList__total">
                <div class="dashboardList__total__inner" *ngIf="result">
                    <div class="dashboardList__row__contact__total bold noborder">{{'Report.ProjectOverview.Summe' | texttransform }}</div>
                    <div class=" dashboardList__row__contact__value bold">{{result?.total}}</div>
                </div>
            </div>
        </app-accordion>
    </div>
    <div *ngIf="controlid === 'allsteps'">
        <app-accordion *ngFor="let array of data" [title]="array[0]?.step" [value]="createSum(array, 'anzahl')">
            
            <div *ngFor="let row of array let index = i" class="dashboardList__row">
                <div class="dashboardList__row__inner">
                    <div class="dashboardList__row__contact__description">{{ row.answer }}</div>
                    <div class="dashboardList__row__contact__value">{{row.anzahl}}</div> 
                </div>
            </div>
            <div class="dashboardList__total">
                <div class="dashboardList__total__inner">
                    <div class="dashboardList__row__contact__total bold noborder">{{array[0].step}} {{'Report.ProjectOverview.Summe' | texttransform }}</div>
                    <div class="dashboardList__row__contact__value bold">{{createSum(array, 'anzahl')}}</div>
                </div>
            </div>
        </app-accordion>
    </div>
    <div class="dashboardList__total" *ngIf="controlid === 'allsteps'">
        <div class="dashboardList__total__inner contactRow">
            <div class="dashboardList__row__contact__total bold noborder">{{result.name}} {{'Report.ProjectOverview.Summe' | texttransform }}</div>
            <div class=" dashboardList__row__contact__leadValue bold">{{result.total}}</div>
        </div>
    </div>
   
</div>
 -->
<div class="defaultDashboardsWrapper">
    <div class="defaultDashboardsSelect">
        <select [(ngModel)]="selectedReport" class="maxWidth">
            <option *ngFor="let dashboard of dashboards" [ngValue]="dashboard.id">{{ dashboard.defaultName | texttransform }}</option>            
        </select>
    </div>

    <div class="defaultDashboardsContent" *ngIf="selectedReport != null">
        <app-defaultdashboard-projectoverview-account [reports]="reports" *ngIf="reports != null && selectedReport == 1"></app-defaultdashboard-projectoverview-account>
        <app-defaultdashboard-opportunity *ngIf="reports != null && selectedReport == 2"></app-defaultdashboard-opportunity>
        <app-defaultdashboard-activity *ngIf="reports != null && selectedReport == 3"></app-defaultdashboard-activity>
        <app-defaultdashboard-leadstatedevelopment *ngIf="reports != null && selectedReport == 4"></app-defaultdashboard-leadstatedevelopment>
        <app-defaultdashboard-consent *ngIf="reports != null && selectedReport == 5"></app-defaultdashboard-consent>
        <app-defaultdashboard-agentwvl *ngIf="reports != null && selectedReport == 6"></app-defaultdashboard-agentwvl>
        <app-defaultdashboard-mailshotdetail *ngIf="selectedReport == 7"></app-defaultdashboard-mailshotdetail>
    </div>
</div>
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { ImportHelperService } from '../import-helper.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { WizardMode, WizardService } from '../../../wizard/wizard.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { MatTableDataSource } from '@angular/material/table';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { SharedAPI } from 'app/shared/service/sharedAPI';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { ApolloMethod } from 'app/shared/service/graphql.service';
import { AuthService } from 'app/core/authentication/services/auth.service';

@Component({
  selector: 'app-import-main',
  templateUrl: './import-main.component.html',
  styleUrls: ['./import-main.component.scss']
})
export class ImportMainComponent implements OnInit, OnDestroy {
  @Input() mode = 2;

  displayedColumns = ['defaultName', 'date', 'accountDelivered', 'accountImported', 'contactDelivered', 'contactImported', 'notimported'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  importFileUploadedEventSubscription: Subscription = new Subscription();
  importShowPageEventSubscription: Subscription = new Subscription();
  dataImportFinishedEventSubscription: Subscription = new Subscription();
  fileSheets = null;
  importStep = 1;
  importResult = '';
  importSuccess = true;
  filterString = '';
  notImportedRecordCounts = {};

  // todo templates
  templates:any[]=[];
  selectedTemplate:any[]=[];

  highlightRowID = null

  constructor(
    private eventService: EventService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    public importHelperService: ImportHelperService,
    private wizardService: WizardService,
    public uiService: UiService,
    private commonService: CommonService,
    private sharedAPI: SharedAPI,
    private refreshValueService: RefreshValueService,
    private authService: AuthService,
  ) { }

  ngOnInit() {


    console.log('language Identifyer', this.applicationInfoService.userSettings);

    this.importShowPageEventSubscription = this.eventService.importShowPageEvent.subscribe(event => {
      this.importHelperService.sheetSelected = false;
      this.fileSheets = null;
      this.importStep = Number(event);
    });
    if (this.mode == 2) {
      this.getDataSources();
    }

    this.importFileUploadedEventSubscription = this.eventService.importFileUploadedEvent.subscribe(event => {
      this.fileSheets = event;
      this.importStep = 2;
    });

    this.eventService.dataImportFinishedEvent.subscribe(eventData => {
      this.getDataSources();
      if (eventData != null) {
        this.refreshValueService.getProjectEntities(true);
        this.refreshValueService.refreshTranslations();
      }
    });

    //console.log('viewMode: data-import', this.mode)
  }

  getImportDetails(getDataSourcesResult) {
    //console.log('getDataSourcesResult',getDataSourcesResult)
    if (getDataSourcesResult.length == 0) {
      return;
    }

    let body = [];
    getDataSourcesResult.forEach(item => {
        body.push(item.importGuid)

      
      // if (item.deliveredRecords[0]) {
      //   if (item.deliveredRecords[0].recordsDelivered > 0) {
      //     body.push(item.importGuid);
      //   }
      // }
    });
    this.sharedAPI.getNotImportedRecordCounts(body).subscribe(result => {
      if(result && result.notImportedRecordCounts){
        this.notImportedRecordCounts = result.notImportedRecordCounts
      } else {
        this.notImportedRecordCounts = {}
      }
      
    }
  );
  }

  getDataSources() {
    this.externaldatasourceService.executeExternalDataSource(77)
    .then(getDataSourcesResult => {
      this.getImportDetails(getDataSourcesResult);
      this.dataSource = new MatTableDataSource(getDataSourcesResult);
    });
  }

  ngOnDestroy() {
    if (this.importFileUploadedEventSubscription) { this.importFileUploadedEventSubscription.unsubscribe(); }
    if (this.dataImportFinishedEventSubscription) { this.dataImportFinishedEventSubscription.unsubscribe(); }
    if (this.importShowPageEventSubscription) { this.importShowPageEventSubscription.unsubscribe(); }
  }

  suggestValues() {
    this.importHelperService.suggestValuesEvent.emit();
  }

  importSheet(toTempTable: boolean = false) {
    this.importHelperService.importDataEvent.emit(toTempTable);
  }

  showWizard() {
    this.wizardService.showWizard(WizardMode.DataImport, new Object(), '95vw', '95vh');
  }

  itemIsFiltered(row) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(row.defaultName, this.filterString)) {
     returnValue = false
    }
    return returnValue;
  }

  getNotImportedCount(element) {
    return this.notImportedRecordCounts[element.importGuid];
  }

  exportData(importData) {
    let fileName = importData.defaultName;
    fileName = fileName.replace('.xlsx', '');
    this.sharedAPI.exportNotImportedData(this.applicationInfoService.projectID, importData.importGuid).subscribe(result => {
      this.commonService.downloadFile(result, 'application/' + 'xlsx',
        fileName + ' ' + this.commonService.getDateTimeString(new Date(importData.importDate))  + '_notImportetData.xlsx'
      );
    });
  }



  exportTemplate(){
  //const file = 'assets/documents/everlead_template.xlsx';
  const language= this.applicationInfoService.userSettings['LanguageIdentifier']
  const file = `assets/documents/templates_dataImport/template_${language}.xlsx`;
  this.authService.GetAssetsPathUrl(file).subscribe((response) => {
    this.commonService.downloadFile(response, 'application/xlsx', `template_${language}.xlsx`)
  })
  }

  highlightRow(id){
    //console.log('id', id)
   this.highlightRowID = id;
   //console.log('row', this.highlightRowID)
  }


}

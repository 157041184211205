import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DashboardcoreService } from '../../dashboardcore.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { EventbookingbatchService } from 'app/jollyjupiter/controls/static/eventbookingbatch/eventbookingbatch.service';

@Component({
  selector: 'app-defaultdashboard-mailshotdetail',
  templateUrl: './defaultdashboard-mailshotdetail.component.html',
  styleUrl: './defaultdashboard-mailshotdetail.component.scss'
})
export class DefaultdashboardMailshotdetailComponent implements OnInit {
  dashboardResultData = null;
  loadingData = false;
  eventBookingBatches = [];
  selectedBookingBatchId = null;
  dashboardResults = [];

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private dashboardcoreService: DashboardcoreService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventbookingbatchService: EventbookingbatchService
  ){}

  kpis = null;

  ngOnInit(): void {
    this.getEventBookingBatches();
  }

  getDashboardDetails(dashboardId, type = null): Promise<any> {
    return new Promise((getDashboardDetailsResolve, getDashboardDetailsReject) => {
      let params = this.fillParams(type);
      this.dashboardcoreService.getDashboardData(dashboardId, params, false).then(getDashboardDataArrayResult => {      
        this.loadingData = false;
        getDashboardDetailsResolve(getDashboardDataArrayResult);
      }).catch((error)=>{
        this.loadingData = false;
        getDashboardDetailsReject(error);
      });  
    });
  }

  fillParams(eventType = null) {
    const params = [];   
    params.push({ key: '@project_id', value: this.applicationInfoService.projectID });
    params.push({ key: '@event_batch_id', value: this.selectedBookingBatchId });
    if (eventType) {
      params.push({ key: '@event_type', value: eventType });
    }
    return params;
  }

  getEventBookingBatches() {
    if (this.applicationInfoService.getCompleteProjectInformatonLoaded == false) { return; }
    this.externaldatasourceService.executeExternalDataSource(this.eventbookingbatchService.getBookingBatchExternalDataSourceId).then(getEventBookingBatchesResult => { 
      this.eventBookingBatches = getEventBookingBatchesResult.filter(batch => 
        batch.batchTypeLookupId == 7421 &&
        batch.isDeleted == false
      );       
      this.eventBookingBatches.forEach(batchType => {
        batchType['wizardValueArray'] = JSON.parse(batchType.wizardValues);
      });
      if (this.applicationInfoService.campaingnModeId != null) {
        this.eventBookingBatches = this.eventBookingBatches.filter(batch => batch.wizardValueArray.campaignId == this.applicationInfoService.campaingnModeId);
      }      
    });    
  }

  getEventBookingBatchData() {
    this.getDashboardDetails(23).then(getEventBookingBatchDataResult => {
      this.kpis = null;
      setTimeout(() => {
        this.kpis = [];
        this.kpis.push(getEventBookingBatchDataResult[0][0].mail_event_statistic_by_type.find(item => item.event_type == 'Failure'));
        this.kpis.push(getEventBookingBatchDataResult[0][0].mail_event_statistic_by_type.find(item => item.event_type == 'Error'));
        this.kpis.push(getEventBookingBatchDataResult[0][0].mail_event_statistic_by_type.find(item => item.event_type == 'Complaint'));
        this.kpis.push(getEventBookingBatchDataResult[0][0].mail_event_statistic_by_type.find(item => item.event_type == 'Bounce'));
        this.kpis.push(getEventBookingBatchDataResult[0][0].mail_event_statistic_by_type.find(item => item.event_type == 'Click'));
        this.kpis.push(getEventBookingBatchDataResult[0][0].mail_event_statistic_by_type.find(item => item.event_type == 'Open'));
        this.kpis.push(getEventBookingBatchDataResult[0][0].mail_event_statistic_by_type.find(item => item.event_type == 'Delivery'));
        this.kpis.push(getEventBookingBatchDataResult[0][0].mail_event_statistic_by_type.find(item => item.event_type == 'Send'));    
      }, 200);
    });
    
    this.dashboardResults = [];   
    this.addDashboardDetail('Send'); 
    this.addDashboardDetail('Open'); 
    this.addDashboardDetail('Delivery'); 
    this.addDashboardDetail('Click'); 
    this.addDashboardDetail('Bounce'); 
    this.addDashboardDetail('Failure'); 
    this.addDashboardDetail('Complaint'); 
    this.addDashboardDetail('Error'); 
  }

  addDashboardDetail(dashboardDetail) {
    this.getDashboardDetails(24, dashboardDetail).then(getDashboardDetailsResult => { 
      this.dashboardResults[dashboardDetail] = getDashboardDetailsResult[0][0].mail_event_contact_list_by_event_type;
    });
  }
  getDashboardResults() {
    if (this.applicationInfoService.isDeveloper) {
      console.warn(this.dashboardResults);
    }    
  }
}

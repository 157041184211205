import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { isNullOrUndefined } from 'util';
import { promise } from 'protractor';
import { LoaderService } from 'app/shared/service/loader-service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/authentication/services/auth.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { JjtranslationService } from 'app/jollyjupiter/service/jjtranslation.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-design-translation',
  templateUrl: './design-translation.component.html',
  styleUrls: ['./design-translation.component.scss']
})
export class DesignTranslationComponent implements OnInit, OnDestroy {
  callInternalMethodEventSubscription: Subscription = new Subscription();
  translationViewInnerHTMLBase = '<jj-container controluiid=<0>></jj-container>';
  translationViewInnerHTML = null;
  controlId = 8270;
  translationLanguages = null;
  selectedTranslationToken: any = null;
  selectedTranslationTokenOriginal = null;
  translationValues: any[] = [];
  existingTranslationsForToken: any[] = [];
  translationValuesOriginal: any[] = [];
  groupNames = [];
  selectedGroup = null;
  projectRelatedTranslation = false;
  projects = [];

  constructor(
    private domSanitizer: DomSanitizer,
    private eventService: EventService,
    private uiService: UiService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private applicationInfoService: ApplicationInfoService,
    private messsagingService: MessagingService,
    private axivasTranslateService: AxivasTranslateService,
    private jjtranslationService: JjtranslationService,
    private commonService: CommonService,
    private externaldatasourceService: ExternaldatasourceService
  ) { }

  ngOnDestroy() {
    if (this.callInternalMethodEventSubscription) { this.callInternalMethodEventSubscription.unsubscribe(); }
  }

  ngOnInit() {
    this.controlId = this.applicationInfoService.applicationSettings['designTranslationControlId'];
    this.translationViewInnerHTML = this.domSanitizer.bypassSecurityTrustHtml(
      this.translationViewInnerHTMLBase.replace('<0>', this.controlId.toString())
    );
    this.jjtranslationService.getTranslationLanguages()
    .then(getTranslationLanguagesResult => {
      this.translationLanguages = getTranslationLanguagesResult;
    });

    this.jjtranslationService.getTranslationGroups()
    .then(getTranslationGroupsResult => {
      this.groupNames = getTranslationGroupsResult.sort((a,b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1);
    });

    this.callInternalMethodEventSubscription = this.eventService.callInternalMethodEvent.subscribe(event => {
      if (event[0] === 'showtranslationtoken') {
        this.showTranslationToken(event[1]);
      }
    });

    this.getProjects();
  }

  getProjects() {
    this.externaldatasourceService.executeExternalDataSource(1).then(result => { 
      this.projects = result.sort((a,b) => a.projectName.toLowerCase() < b.projectName.toLowerCase() ? -1 : 1); 
    });
  }

  showTranslationToken(token) {
    this.selectedTranslationToken = JSON.parse(JSON.stringify(token));
    this.selectedTranslationTokenOriginal = JSON.parse(JSON.stringify(token));
    this.translationValues = [];
    this.selectedGroup = token.group;
    this.existingTranslationsForToken = [];
    if (isNullOrUndefined(token.projectId)) {
      this.projectRelatedTranslation = false;
    } else {
      this.projectRelatedTranslation = true;
    }
    if (!isNullOrUndefined(token.translations)) {
      this.existingTranslationsForToken = token.translations;
      token.translations.forEach(translation => {
        this.translationValues[translation.language.id] = translation.translation;
      });
    }
    this.translationValuesOriginal = JSON.parse(JSON.stringify(this.translationValues));
  }

  checkIfTokenIsDirty(): boolean {
    let returnValue = false;
    if (JSON.stringify(this.selectedTranslationToken) !== JSON.stringify(this.selectedTranslationTokenOriginal)) {
      returnValue = true;
    }
    if (JSON.stringify(this.translationValues) !== JSON.stringify(this.translationValuesOriginal)) {
      returnValue = true;
    }
    return returnValue;
  }

  undoChanges() {
    this.selectedTranslationToken = JSON.parse(JSON.stringify(this.selectedTranslationTokenOriginal));
  }

  newToken() {
    this.selectedTranslationToken = { id: null, group: null, subgroup: null, field: null, tokenName: null, projectId: null };
    this.selectedTranslationTokenOriginal = JSON.parse(JSON.stringify(this.selectedTranslationToken));
    this.translationValues = [];
    this.selectedGroup = null;
    this.translationValuesOriginal = JSON.parse(JSON.stringify(this.translationValues));
    this.existingTranslationsForToken = [];
  }

  changeGroup(item: any) {
    this.selectedTranslationToken.group = item;
  }

  saveToken(resetAndNew = false) {
    if (this.jjtranslationService.checkTranslationTokenRequirements(this.selectedTranslationToken) === false) {
      return;
    }

    const promiseArray: Promise<any>[] = [];
    this.jjtranslationService.upsertTranslationToken(this.selectedTranslationToken, promiseArray);

    this.loaderService.display(true);
    Promise.all(promiseArray)
    .then(result => {
      this.selectedTranslationToken.id = result[0].id;
      this.eventService.updateControlContent(this.controlId);
      // console.warn('saveToken', result);
      this.jjtranslationService.saveTranslations(this.translationLanguages, this.translationValues, this.selectedTranslationToken,
        this.existingTranslationsForToken)
      .then(() => {
        this.externaldatasourceService.executeExternalDataSource(116, [this.selectedTranslationToken.id])
        .then(executeExternalDataSourceUpdateTokenInfoResult => {
          this.jjtranslationService.updateTranslationCache();
          this.showTranslationToken(executeExternalDataSourceUpdateTokenInfoResult);
          this.messsagingService.showDefaultSuccess('',
            this.axivasTranslateService.getTranslationTextForToken('DesignTranslation.Message.SaveSuccess'));
          if (resetAndNew) {
            let oldToken = JSON.parse(JSON.stringify(this.selectedTranslationToken));
            this.newToken();
            this.selectedTranslationToken.group = oldToken.group;
            this.selectedTranslationToken.subgroup = oldToken.subgroup;
            this.showTranslationToken(this.selectedTranslationToken);
            this.uiService.setControlFocus('designTranslationTokenNameLabel');
          }
          this.loaderService.display(false);
        })
        .catch(error => {
          this.loaderService.display(false);
        });
      })
      .catch(error => {
        this.messsagingService.showDefaultError('',
          this.axivasTranslateService.getTranslationTextForToken('DesignTranslation.Message.SaveFailed'));
        this.loaderService.display(false);
        console.error('saveTranslations', error);
      });
    });
  }

  changeProjectRelation(selectedTranslationToken) {
    this.projectRelatedTranslation = !this.projectRelatedTranslation;
    if (this.projectRelatedTranslation) {
      selectedTranslationToken.projectId = this.applicationInfoService.projectID;
    } else {
      selectedTranslationToken.projectId = null;
    }
  }
}

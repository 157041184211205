import { Component, OnInit } from '@angular/core';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { RingcentralwebphoneService } from 'app/jollyjupiter/service/ringcentralwebphone.service';
import { RefreshValueService } from 'app/jollyjupiter/signalr/refresh-value.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SettingsService } from 'app/shared/service/settings.service';
import { WizardMode, WizardService } from '../../wizard/wizard.service';
import { PackagefeatureService } from 'app/jollyjupiter/service/packagefeature.service';

@Component({
  selector: 'app-toolbar-popup',
  templateUrl: './toolbar-popup.component.html',
  styleUrls: ['./toolbar-popup.component.scss']
})
export class ToolbarPopupComponent implements OnInit {
  section = '';
  showPopup = false;
  phoneItems = [];
  arrowLeft = null;
  arrowRight = null;
  canUseDesigner = false;

  constructor(
    private eventService: EventService,
    public userService: UserService,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private awsCognitoService: AwsCognitoService,
    private settingsService: SettingsService,
    private methodService: MethodService,
    private ringcentralwebphoneService: RingcentralwebphoneService,
    private externaldatasourceService: ExternaldatasourceService,
    private refreshValueService: RefreshValueService,
    private loaderService: LoaderService,
    private wizardService: WizardService,
    private packagefeatureService: PackagefeatureService
  ) { }

  ngOnInit(): void {
    this.eventService.customEvent.subscribe(event => {
      if (event.id == 'closeToolbarPopup') {
        this.closePopup();
      }

      if (event.id == 'showDemoLandingPageNotVisitedInfo') {
        setTimeout(() => {
          const element = document.getElementById('toolbarDemoWebsiteLink');          
          if (element) {
            element.style.setProperty('display', 'flex');
            var rect= element.getBoundingClientRect();
           this.arrowLeft = ((rect.left - (rect.width / 2)) - 20) + 'px';
           this.arrowRight = '170px';
          }    
          this.section = 'showDemoLandingPageNotVisitedInfo';
          this.showPopup = true;  
        }, 2500);
      }

      if (event.id == 'calltoolbarPopup') {
        if (event.value == this.section) {
          this.closePopup();
        } else {
          this.section = event.value;
          this.arrowLeft = event.arrowLeft;
          this.arrowRight = event.arrowRight;
          this.showPopup = true;
          this.checkEvent(event.value);  
        }
      }
    });

    this.canUseDesigner = this.packagefeatureService.selectedPackageHasFeature(this.applicationInfoService.applicationSettings['featureId_uidesigner']);
    if (this.applicationInfoService.isDeveloper) {
      this.canUseDesigner = true;
    }
  }

  getAccountValue(memberName) {
    if (this.applicationInfoService.entities.Item('1').data == null) {
      return '';
    }
    let accountValue = this.applicationInfoService.entities.Item('1').data[memberName];
    if (this.commonService.isNullOrUndefined(accountValue)) {
      accountValue = '';
    }
    return accountValue;
  }

  checkEvent(section) {
    switch(section) {
      case 'phone':        
        break;
    }
  }

  getModeText(){
    return (this.applicationInfoService.designerMode ? "Designermodus deaktivieren" : "Designermodus aktivieren" )
  }


  changeExpertMode() {
    if (this.applicationInfoService.expertMode == true) {
      this.applicationInfoService.expertMode = false;
      this.settingsService.userSettings['ExpertMode'].value = 'false'
    } else {
      this.applicationInfoService.expertMode = true;
      this.settingsService.userSettings['ExpertMode'].value = 'true'
    }
    this.settingsService.setUserSettingEx(this.settingsService.userSettings['ExpertMode'])
  }

  showUserSettings() {
    this.applicationInfoService.routerHeaderLabelText = 'General.Menu.Settings';
    this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'usersettings']);
    this.closePopup();
    if (this.applicationInfoService.isMobile()) {
      this.applicationInfoService.navigationExpandedMobile = false;
    }
  }

  getNewUserMessages() {
    try {
      const unreadMessages = this.applicationInfoService.userMessages.filter(message => message.userMessages[0].readDateAt == null);
      if (this.commonService.isNullOrUndefined(unreadMessages)) {
        return 0;
      } else {
        return unreadMessages.length;
      }    
    }
    catch {
      console.error('getNewUserMessages error');
    }
  }
  
  changeDesignerMode() {
    this.applicationInfoService.changeDesignerMode();
  }

  showMailbox() {
    this.eventService.showJjPopup('UserSettings.Label.Mailbox', 'mailbox', 90);
    this.closePopup();
  }

  closePopup() {
    this.showPopup = false;
    this.section = '';
  }

  launchGoogleSearch(entityId) {
    let url =  'https://www.google.de/search?source=everlead&q=<0>';
    let replaceString = '';
    const entityDataAccount = this.applicationInfoService.entities.Item('1').data;
    const entityDataContact = this.applicationInfoService.entities.Item('4').data;
    switch(entityId) {
      case 1:
        replaceString = replaceString.concat(
          this.modifySearchString(entityDataAccount.name1), '+',
          this.modifySearchString(entityDataAccount.name2), '+',
          this.modifySearchString(entityDataAccount.street), '+',
          this.modifySearchString(entityDataAccount.zipcode), '+',
          this.modifySearchString(entityDataAccount.city)
        );
        break;
      case 4:
        replaceString = replaceString.concat(
          this.modifySearchString(entityDataContact.firstName), '+',
          this.modifySearchString(entityDataContact.lastName)
        );
        break;
    }
    
    replaceString = replaceString.replace(/&/, '');
    url = url.replace(/<0>/, replaceString);
    window.open(url);
  }

  openLinkedIn(entityId) {
    let isAccount = false;
    const entityData = this.applicationInfoService.entities.Item(entityId).data;
    let value1 = '';
    let value2 = '';
    if (Number(entityId) == 1) {
      value1 = entityData.name1;
      isAccount = true;
    } else {
      value1 = entityData.firstName;
      value2 = entityData.lastName;
    }
    if (this.commonService.isNullOrUndefined(value1)) { value1 = ''; }
    if (this.commonService.isNullOrUndefined(value2)) { value2 = ''; }
    this.commonService.openLinkedIn(value1, value2, isAccount);
  }

  modifySearchString(baseString: string): string {
    let resultString = '';
    if (baseString == null) {
      return resultString;
    }
    resultString = baseString.trim();
    resultString = resultString.replace(/ /, '+');
    return resultString;
  }

  openXing(entityId) {
    const entityData = this.applicationInfoService.entities.Item(entityId).data;
    if (Number(entityId) == 1) {
      this.commonService.openXing(entityData.name1);
    } else {
      this.commonService.openXing(entityData.lastName);
    }
  }

  newItem(entityId) {
    switch (entityId) {
      case 1:
        // this.methodService.launchInternalMethod(InternalMethodType.ChangePageContent, ['', 'newaccount']);
        this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, [
          'Supervisor.Menu.NewAccount',
          'newaccount',
          '90'
        ]);  
        break;
      case 2:
        this.methodService.launchInternalMethod(InternalMethodType.UpdateEntity, ['6', '-1']).then(() => {
          this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, [
            'Contact.Label.NewContact',
            'newcontact',
            '90'
          ]);  
        });
        break;
      case 4: 
      if (this.applicationInfoService.currentAccount == null) { 
          return; 
        }
        this.methodService.launchInternalMethod(InternalMethodType.UpdateEntity, ['15', '-1']).then(() => {
          this.methodService.launchInternalMethod(InternalMethodType.UpdateMiscSettings, ['bookingMode', '4']).then(() => {
            this.methodService.launchInternalMethod(InternalMethodType.ShowJJPopup, [
              'AccountFooter.Label.NewActivity',
              'booking'
            ]);  
          });
        });
        break;
    }
  }

  getArrowPosition() {    
    return '40px';
  }

  openURL(url) {
    this.commonService.openUrl(url, '_new');
  }

  // ProcessVisual
  createWorkflow() {
    this.loaderService.display(true);
    this.externaldatasourceService.executeExternalDataSource(530, [
      this.commonService.getModifyArrayBody({ 
        projectId: this.applicationInfoService.projectID,
        defaultName: 'New workflow',
      }, [])
    ]).then(createWorkflowResult => {
      this.refreshValueService.getWorkflows().then(() => {  
       /*  this.eventService.customEvent.emit({
          id: 'updateProcessVisualWorkflow',
          value: createWorkflowResult.id,
          duplicate: true
        }); */
        const wizardArray = this.applicationInfoService.workflows.Item(createWorkflowResult.id.toString());
        wizardArray.showDuplicateOption = true; 
        this.wizardService.showWizard(WizardMode.Process, wizardArray);
      }).catch(() => { this.loaderService.display(false); });
      this.loaderService.display(false);
    }).catch(() => { this.loaderService.display(false); });
  }



  createStarterStep(){
    console.log('click')
  }
}

<!-- <div *ngIf="!dataAvailable" class="chart__noData" [ngClass]="{ 'chartGraph__darkMode': darkmode }">
    {{ 'Chart.Label.NoDataAvailable' | texttransform }}
</div> -->

<div
    class="echart"
    echarts
    [options]="options"
    [id]="controlid"
    [style.height]="getHeight()"
    [style.width]="getWidth()"
>
</div>

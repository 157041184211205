<div *ngIf="onlyProjects == false">
    <div class="customProjectOverviewHeader d-flex jjControlDefaultColor">
        <label class="cleanFromMarginAndPadding customProjectOverviewHeaderLabel align-self-start">{{
            'ProjectOverview.Label.Header' | texttransform }}</label>

        <div class="filterIconWrapper align-self-end">
            <i class="fas fa-filter customProjectOverviewHeaderFilterIcon"></i>
            <input [(ngModel)]="filterValue" class="customProjectOverviewHeaderFilterInput">
        </div>
    </div>
    <div class="customProjectOverviewLegendDiv" *ngIf="campaignInfo != null">
        <label class="cleanFromMarginAndPadding customProjectOverviewCampaignDetailLabel"
            title="{{ 'ProjectOverview.Label.CampaignClosedAccountsMouseover' | texttransform }}">
            <div class="customProjectOverviewCampaignColorLegendBox customProjectOverviewColorClosed"></div>
            <span *ngIf="!applicationInfoService.isMobile()">{{ 'ProjectOverview.Label.CampaignClosedAccounts' |
                texttransform }}</span>
        </label>
        <label class="cleanFromMarginAndPadding customProjectOverviewCampaignDetailLabel"
            title="{{ 'ProjectOverview.Label.CampaignInProcessAccountsMouseover' | texttransform }}">
            <div class="customProjectOverviewCampaignColorLegendBox customProjectOverviewColorInProgress"></div>
            <span *ngIf="!applicationInfoService.isMobile()">{{ 'ProjectOverview.Label.CampaignInProcessAccounts' |
                texttransform }}</span>
        </label>
        <label class="cleanFromMarginAndPadding customProjectOverviewCampaignDetailLabel"
            title="{{ 'ProjectOverview.Label.CampaignOpenAccountsMouseover' | texttransform }}">
            <div class="customProjectOverviewCampaignColorLegendBox customProjectOverviewColorOpen"></div>
            <span *ngIf="!applicationInfoService.isMobile()">{{ 'ProjectOverview.Label.CampaignOpenAccounts' |
                texttransform }}</span>
        </label>
        <label class="cleanFromMarginAndPadding customProjectOverviewCampaignDetailLabel"
            title="{{ 'ProjectOverview.Label.CampaignAccountsNotAssignedMouseover' | texttransform }}">
            <div class="customProjectOverviewCampaignColorLegendBox customProjectOverviewColorNotAssigned"></div>
            <span *ngIf="!applicationInfoService.isMobile()">{{ 'ProjectOverview.Label.CampaignAccountsNotAssigned' |
                texttransform }}</span>
        </label>
    </div>
    <div *ngIf="campaignInfo != null" class="projectOverviewContainer" [style.height]="getControlHeight()">
        <div *ngFor="let project of campaignInfo" style="margin-bottom: 1px;">
            <span *ngIf="isFilterValidForProject(project.projectName)">
                <div class="customProjectOverviewProjectDiv">
                    <label class="cleanFromMarginAndPadding customProjectOverviewProjectLabel fingerCursor"
                        (click)="loadProjectAndShowActivityForm(project)">({{ project.projectId }}) {{
                        project.projectName }}</label>
                    <label class="cleanFromMarginAndPadding customProjectOverviewProjectDetailLabel">
                        {{ 'ProjectOverview.Label.MyAppointments' | texttransform }} {{ project.countFollowUps }}
                    </label>
                    <label class="cleanFromMarginAndPadding customProjectOverviewProjectDetailLabel">
                        <span *ngIf="project.minNextFollowUpDate != null">{{ 'ProjectOverview.Label.MyNextAppointment' |
                            texttransform }} {{ project.minNextFollowUpDate }}</span>
                    </label>
                    <label class="cleanFromMarginAndPadding customProjectOverviewProjectDetailLabel">
                        {{ 'ProjectOverview.Label.ProjectUnassignedAccounts' | texttransform }} {{
                        project.unassignedAccounts }}
                    </label>
                    <i class="fas fa-refresh fingerCursor floatRight" title="Update Leadstate"
                        style="margin-right: 6px; margin-top: 6px; color: blue;"
                        (click)="projectService.updateLeadStateOfProject(project.projectId)"
                        *ngIf="applicationInfoService.isDeveloper"></i>
                    <i class="fas fa-refresh fingerCursor floatRight" title="Update project overview"
                        style="margin-right: 6px; margin-top: 6px; color: green;"
                        (click)="projectService.updateProjectOverview(project.projectId)"
                        *ngIf="applicationInfoService.isDeveloper"></i>
                </div>
                <div *ngFor="let campaign of project.campaigns">
                    <!-- Campaign -->
                    <div class="customProjectOverviewCampaignDiv"
                        (click)="commonService.toggleItemExpanded(collapsedItems, campaign.campaignId);$event.stopPropagation()">
                        <div class="customProjectOverviewQuickCampaignName">
                            <label class="cleanFromMarginAndPadding customProjectOverviewCampaignLabel fingerCursor"
                                (click)="commonService.toggleItemExpanded(collapsedItems, campaign.campaignId);$event.stopPropagation()">
                                {{ campaign.campaignName }}</label>
                        </div>
                        <!-- Quick-Info -->
                        <div class="customProjectOverviewQuickInfo" *ngIf="!applicationInfoService.isMobile()">
                            <label class="cleanFromMarginAndPadding customProjectOverviewCampaignDetailLabelSmall">
                                <div
                                    class="customProjectOverviewCampaignColorLegendBox customProjectOverviewColorClosed">
                                </div>
                                {{ campaign.closedAccounts }}
                            </label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewCampaignDetailLabelSmall">
                                <div
                                    class="customProjectOverviewCampaignColorLegendBox customProjectOverviewColorInProgress">
                                </div>
                                {{ campaign.inProcessAccounts }}
                            </label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewCampaignDetailLabelSmall">
                                <div class="customProjectOverviewCampaignColorLegendBox customProjectOverviewColorOpen">
                                </div>
                                {{ campaign.openAccounts }}
                            </label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewCampaignDetailLabelSmall">
                                <div
                                    class="customProjectOverviewCampaignColorLegendBox customProjectOverviewColorNotAssigned">
                                </div>
                                {{ campaign.unasssignedAccounts }}
                            </label>
                        </div>
                        <!-- Progress -->
                        <div class="customProjectOverviewCampaignProgressContainer floatRight"
                            style="white-space: pre-line" [tippy]=getProgressBarTooltip(campaign)>
                            <label
                                class="customProjectOverviewCampaignProgressContentContainer customProjectOverviewColorClosed"
                                [style.width.%]="getAdressProgressWidth(campaign, 'closedAccounts')"></label>
                            <label
                                class="customProjectOverviewCampaignProgressContentContainer customProjectOverviewColorInProgress"
                                [style.width.%]="getAdressProgressWidth(campaign, 'inProcessAccounts')"></label>
                            <label
                                class="customProjectOverviewCampaignProgressContentContainer customProjectOverviewColorOpen"
                                [style.width.%]="getAdressProgressWidth(campaign, 'openAccounts')"></label>
                            <label
                                class="customProjectOverviewCampaignProgressContentContainer customProjectOverviewColorNotAssigned"
                                [style.width.%]="getAdressProgressWidth(campaign, 'unasssignedAccounts')"></label>
                        </div>
                        <!-- Dropdown -->
                        <div class="customProjectOverviewCampaignDropdown ">
                            <button
                                (click)="commonService.toggleItemExpanded(collapsedItems, campaign.campaignId);$event.stopPropagation()"
                                class="customProjectOverviewChevronButton floatRight" style="white-space: normal;">
                                <i class="fas fa-chevron-down fingerCursor"
                                    *ngIf="commonService.isItemExpanded(collapsedItems, campaign.campaignId)==false"></i>
                                <i class="fas fa-chevron-up fingerCursor"
                                    *ngIf="commonService.isItemExpanded(collapsedItems, campaign.campaignId)==true"></i>
                            </button>
                        </div>
                    </div>
                    <span *ngIf="commonService.isItemExpanded(collapsedItems, campaign.campaignId)==true">
                        <!-- Quick-Info -->
                        <div class="customProjectOverviewQuickInfo" *ngIf="applicationInfoService.isMobile()">
                            <label class="cleanFromMarginAndPadding customProjectOverviewCampaignDetailLabelSmall">
                                <div
                                    class="customProjectOverviewCampaignColorLegendBox customProjectOverviewColorClosed">
                                </div>
                                {{ campaign.closedAccounts }}
                            </label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewCampaignDetailLabelSmall">
                                <div
                                    class="customProjectOverviewCampaignColorLegendBox customProjectOverviewColorInProgress">
                                </div>
                                {{ campaign.inProcessAccounts }}
                            </label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewCampaignDetailLabelSmall">
                                <div class="customProjectOverviewCampaignColorLegendBox customProjectOverviewColorOpen">
                                </div>
                                {{ campaign.openAccounts }}
                            </label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewCampaignDetailLabelSmall">
                                <div
                                    class="customProjectOverviewCampaignColorLegendBox customProjectOverviewColorNotAssigned">
                                </div>
                                {{ campaign.unasssignedAccounts }}
                            </label>
                        </div>
                        <!-- AgentHeader -->
                        <div class="customProjectOverviewAgentDiv customProjectOverviewAgentDivHeader">
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.AgentName' | texttransform }}</label>
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.AgentActivities' | texttransform }}</label>
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.AgentActivitiesToday' | texttransform }}</label>
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.AgentFollowUpsPast' | texttransform }}</label>
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.AgentFollowUpsToday' | texttransform }}</label>
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.AgentFollowUpsTomorrow' | texttransform }}</label>
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.AgentFollowUpsFuture' | texttransform }}</label>
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.AgentPotential' | texttransform }}</label>
                            <button
                                (click)="commonService.toggleItemExpanded(collapsedAgents, campaign.campaignId);$event.stopPropagation()"
                                class="customProjectOverviewChevronButton floatRight" style="white-space: normal;">
                                <i class="fas fa-chevron-down fingerCursor"
                                    *ngIf="commonService.isItemExpanded(collapsedAgents, campaign.campaignId)==false"></i>
                                <i class="fas fa-chevron-up fingerCursor"
                                    *ngIf="commonService.isItemExpanded(collapsedAgents, campaign.campaignId)==true"></i>
                            </button>
                        </div>
                        <!-- Agents -->
                        <div *ngIf="commonService.isItemExpanded(collapsedAgents, campaign.campaignId)==true">
                            <div *ngFor="let agent of campaign.agents">
                                <div class="customProjectOverviewAgentDiv">
                                    <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel">{{
                                        agent.userName }}</label>
                                    <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel">{{
                                        agent.activities }}</label>
                                    <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel">{{
                                        agent.activitiesToday }}</label>
                                    <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel">{{
                                        agent.followUpsPast }}</label>
                                    <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel">{{
                                        agent.followUpsToday }}</label>
                                    <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel">{{
                                        agent.followUpsTomorrow }}</label>
                                    <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel">{{
                                        agent.followUpsFuture }}</label>
                                    <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel"
                                        [innerHTML]="getAgentPotential(agent, campaign)"></label>
                                </div>
                            </div>
                        </div>
                        <!-- Summary -->
                        <div class="customProjectOverviewAgentSummaryDiv"
                            [ngClass]="{ customProjectOverviewAgentSummaryDivTopBorder: commonService.isItemExpanded(collapsedAgents, campaign.campaignId)==true }">
                            <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel"
                                [ngClass]="{ jjBold: commonService.isItemExpanded(collapsedAgents, campaign.campaignId)==true }">{{
                                'ProjectOverview.Label.Summary' | texttransform }}</label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel"
                                [ngClass]="{ jjBold: commonService.isItemExpanded(collapsedAgents, campaign.campaignId)==true }">{{
                                campaign.activities }}</label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel"
                                [ngClass]="{ jjBold: commonService.isItemExpanded(collapsedAgents, campaign.campaignId)==true }">{{
                                campaign.activitiesToday }}</label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel"
                                [ngClass]="{ jjBold: commonService.isItemExpanded(collapsedAgents, campaign.campaignId)==true }">{{
                                campaign.followUpsPast }}</label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel"
                                [ngClass]="{ jjBold: commonService.isItemExpanded(collapsedAgents, campaign.campaignId)==true }">{{
                                campaign.followUpsToday }}</label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel"
                                [ngClass]="{ jjBold: commonService.isItemExpanded(collapsedAgents, campaign.campaignId)==true }">{{
                                campaign.followUpsTomorrow }}</label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel"
                                [ngClass]="{ jjBold: commonService.isItemExpanded(collapsedAgents, campaign.campaignId)==true }">{{
                                campaign.followUpsFuture }}</label>
                        </div>
                        <div class="">
                            <label class="customProjectOverviewPotentialLabel jjBold">{{
                                'ProjectOverview.Label.Potential.CampaignPotential' | texttransform }}</label>
                        </div>
                        <div class="customProjectOverviewAgentSummaryDiv customProjectOverviewAgentDivHeader">
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.Potential.FollowUpsPast' | texttransform }}</label>
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.Potential.FollowUpsToday' | texttransform }}</label>
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.Potential.FollowUpsTomorrow' | texttransform }}</label>
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.Potential.FollowUpsFuture' | texttransform }}</label>
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.Potential.UnasssignedAccounts' | texttransform }}</label>
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.AgentFollowUpsTomorrow' | texttransform }}</label>
                            <label class="cleanFromMarginAndPadding jjBold customProjectOverviewAgentInfoLabel">{{
                                'ProjectOverview.Label.Potential.PotentialTomorrow' | texttransform }}</label>
                        </div>
                        <div class="customProjectOverviewAgentSummaryDiv">
                            <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel">{{
                                campaign.dummyAgent.followUpsPast }}</label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel">{{
                                campaign.dummyAgent.followUpsToday }}</label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel">{{
                                campaign.dummyAgent.followUpsTomorrow }}</label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel">{{
                                campaign.dummyAgent.followUpsFuture }}</label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel">{{
                                campaign.unasssignedAccounts }}</label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel">{{
                                campaign.followUpsTomorrow }}</label>
                            <label class="cleanFromMarginAndPadding customProjectOverviewAgentInfoLabel"
                                [innerHTML]="getCampaignPotential(campaign)"></label>
                        </div>
                    </span>
                </div>
            </span>
        </div>

    </div>
    <span *ngIf="campaignInfo == null">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{ 'ProjectOverview.Label.GettingData' |
        texttransform }}

    </span>
</div>





<div *ngIf="onlyProjects == true && axivasTranslateService.translationTextArrayGlobal != null" class="onlyProjectWrapper">
        <div class="onlyProjectWrapper__head">
            <!-- <div class="onlyProjectWrapper__head__search">
                <svg-icon src="assets/images/svg/iconset/15icon-lupe.svg" class="customTextControlWrapper__baseColor"  [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"></svg-icon> 
                <input [(ngModel)]="filterValue" class="customTextControlWrapper__textbox customTextControlWrapper__oneIcon borderBottom onlyProjectWrapper__head__searchText"
                    (keyup)="applyFilter($event)" [placeholder]="'General.Label.Search' | texttransform">
            </div>
            <div class="customTextControlWrapper__baseColor onlyProjectWrapper__head__buttons">
                    <div class="onlyProjectWrapper__head__button">
                        <button class="projectSelection__typeButton" (click)="setMode(null)" [ngClass]="{
                            projectSelection__typeButton__selected: projectMode == null
                        }">
                            {{ 'ProjectOverview.Label.All' | texttransform }}
                        </button>        
                    </div>
                    <div class="onlyProjectWrapper__head__button">
                        <button class="projectSelection__typeButton" (click)="setMode(true)" [ngClass]="{
                            projectSelection__typeButton__selected: projectMode == true
                        }">
                            {{ 'ProjectOverview.Label.Active' | texttransform }}
                        </button>        
                    </div>
                    <div class="onlyProjectWrapper__head__button">
                        <button class="projectSelection__typeButton" (click)="setMode(false)" [ngClass]="{
                            projectSelection__typeButton__selected: projectMode == false
                        }">
                            {{ 'ProjectOverview.Label.Inactive' | texttransform }}
                        </button>        
                    </div>
            </div> -->
            <div class="onlyProjectHeader">
                <!-- <div class="onlyProjectHeader__button">
                    <app-main-button svg="image" (onClick)="setMode(null)" [label]="'ProjectOverview.Label.All' | texttransform"></app-main-button>
                </div>
                <div class="onlyProjectHeader__button">
                    <app-main-button svg="image" (onClick)="setMode(true)" [label]="'ProjectOverview.Label.Active' | texttransform"></app-main-button>
                </div>
                <div class="onlyProjectHeader__button">
                    <app-main-button svg="image" (onClick)="setMode(false)" [label]="'ProjectOverview.Label.Inactive' | texttransform"></app-main-button>
                </div> -->
                <div class="onlyProjectHeader__search">
                    <input class="projectSearchInput" [(ngModel)]="filterValue" (keyup)="applyFilter($event)" [placeholder]="'General.Label.Search' | texttransform">
                    <app-jj-icons [type]="'magnifying-glass'" [width]="20" [height]="20" ></app-jj-icons>                    
                </div>
                <div class="onlyProjectHeader__inactiveProjects">
                    <input type="checkbox" [(ngModel)]="showInactiveProjects">
                    <div class="onlyProjectHeader__inactiveProjects__label">Inaktive Projekte anzeigen</div>
                </div>
            </div>
        </div>
        <div class="onlyProjectWrapper__content" class="availableProjects">
            <div *ngFor="let project of availableProjects; let rowIndex = index" class=" everleadTableRow availableProject fingerCursor" [ngClass]="{ 
                hideInDom: isFiltered(project)
            }">
                <div class="availableProject__header" (click)="loadProjectAndShowActivityForm(project, true)">
                    <div class="availableProject__developerHover" *ngIf="applicationInfoService.isDeveloper"
                        (click)="$event.stopPropagation()">
                        <span class="projectId">{{ project.id }}</span>
                        <i class="fas fa-refresh fa-sm fingerCursor" title="Update Leadstate"                         
                            (click)="projectService.updateLeadStateOfProject(project.id);$event.stopPropagation()"></i>                                           
                    </div>
                    <app-component-header [header]="project.projectName"></app-component-header>    
                    <div class="availableProject__customer fingerCursor">{{ project.customer.customerName }}</div>                                
                    <div class="availableProject__customer fingerCursor" *ngIf="commonService.checkIfDateIsInFuture(project.projectStart)">
                        {{ 'ProjectOverview.Label.ProjectStartingSoon' | texttransform}}  ({{ project.projectStart | date:'medium' }})
                    </div>
                </div>                
                
                <!-- <div class="availableProject__wrapper fingerCursor" (click)="loadProjectAndShowActivityForm(project, true)"
                    [ngClass]="{ hideInDom: isFiltered(project) }"
                >                    
                    <div class="availableProjectDetails">
                        <div class="availableProject__id" *ngIf="applicationInfoService.isDeveloper">{{ project.id }}</div>
                        <div class="availableProject__projectInfo__nameAndCustomer">
                            <div class="availableProject__projectInfo__nameLine">
                                <div class="availableProject__projectInfo__name">
                                    {{ project.projectName }}
                                </div>
                            </div>
                            <div class="availableProject__projectInfo__customer">
                                {{ project.customer.customerName }}
                            </div>                                
                        </div>        
                        <i class="fas fa-refresh fa-sm fingerCursor availableProjectUpdate" title="Update Leadstate" *ngIf="applicationInfoService.isDeveloper"
                            (click)="projectService.updateLeadStateOfProject(project.id);$event.stopPropagation()"></i>                                           
                    </div>
                    <div class="availableProject__projectInfo" *ngIf="
                        commonService.checkIfDateIsBetweenTodayAndXDays(project.projectEnd, 14)
                    ">
                        <label class="availableProject__projectInfo__headerLabel">
                            {{ 'ProjectOverview.Label.ProjectEndingSoon' | texttransform}}
                        </label>
                        <div class="availableProject__projectInfo__date">
                            <div class="availableProject__projectInfo__date__label">{{ 'GeneralSettings.Setting.ProjectEnd' | texttransform}}</div>
                            <div class="availableProject__projectInfo__date__value everleadCoral">
                                {{ project.projectEnd | date: 'medium' }}
                            </div>
                        </div>
                    </div>                   
                    <div class="availableProject__projectInfo" *ngIf="commonService.checkIfDateIsInFuture(project.projectStart)">
                        <label class="availableProject__projectInfo__headerLabel">
                            {{ 'ProjectOverview.Label.ProjectStartingSoon' | texttransform}}
                        </label>
                        <div class="availableProject__projectInfo__date">
                            <div class="availableProject__projectInfo__date__label">{{ 'GeneralSettings.Setting.ProjectStart' | texttransform}}</div>
                            <div class="availableProject__projectInfo__date__value">{{ project.projectStart | date: 'medium' }}</div>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- <table mat-table #table [dataSource]="dataSource" style="width: 100%" matSort>
                <ng-container matColumnDef="projectName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Projekt</th>
                    <td mat-cell *matCellDef="let element" class="everleadProject hoverMe fingerCursor">                        
                        <i class="fas fa-refresh fa-lg fingerCursor" title="Update Leadstate"                       
                        (click)="projectService.updateLeadStateOfProject(element.id);$event.stopPropagation()"
                        *ngIf="applicationInfoService.isDeveloper"></i>
                    {{ element.projectName }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    (click)="loadProjectAndShowActivityForm(row, true)"></tr>

            </table> -->
        </div>
  
</div>
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { SettingsService } from 'app/shared/service/settings.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contentinfo',
  templateUrl: './contentinfo.component.html',
  styleUrls: ['./contentinfo.component.scss']
})
export class ContentinfoComponent implements OnInit, OnDestroy {  
  customEventSubscription: Subscription = new Subscription();
  headerText = '';
  generalTipps = [];
  tipIndex = 0;
  lastTipSection = null;
  contentItem = null;
  forceShow = false;

  constructor(
    public applicationInfoService: ApplicationInfoService,
    private settingService: SettingsService,
    private commonService: CommonService,
    public axivasTranslateService: AxivasTranslateService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
      if (event == 'showHelp') {
        this.forceShow = true;
        this.changeHelpStatus(true);

        console.log('called')
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customEventSubscription) { this.customEventSubscription.unsubscribe(); }
  }
  
  changeHelpStatus(status = false) {
    this.applicationInfoService.userSettings['contentInfo' + this.contentItem[0].tippGuid] = status;
    if (status==false) {
      this.forceShow = false;
    }
    this.settingService.updateSecurityUserSettings();
  }

  getRandomTipp() {
    this.generalTipps = this.applicationInfoService.tipps.filter(tipp => tipp.tippGuid == null);
    if (this.generalTipps.length > 0) {
      this.tipIndex = this.commonService.randomNumber(0, this.generalTipps.length -1);
    } 
  }

  previousTip() {
    if (this.tipIndex > 0) { this.tipIndex --; }
  }
  
  nexTip() {
    if (this.tipIndex < this.generalTipps.length - 1) { this.tipIndex ++; }
  }

  getContentInfo() {
    const content = this.commonService.getApplicationSectionContentTipp(window.location.href.toString());
    if (content == null) {
      return false;
    } else {
      if (content.length == 0) {
        return false;
      } else {
        this.contentItem = content;
        if (content[0].tippGuid != this.lastTipSection) {
          this.tipIndex == 0;
          this.lastTipSection = content[0].tippGuid;          
        }       
        if (this.forceShow) {
          return true;
        }
        if (this.applicationInfoService.userSettings['contentInfoDontShowInfo'] == true) {
          return false;
        }
        if (this.applicationInfoService.userSettings['contentInfo' + this.contentItem[0].tippGuid] == false) {
          return false;
        }
        return true;                
      }
    }
  }
}

import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';


@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    },

  ]
})
export class CheckboxComponent implements ControlValueAccessor{
  @Input() label: string = '';
  @Input() disabled: boolean = false;

  @Input() id : string |boolean | null = null;

  @Input() required: boolean = false;
  @Input() labelLeft: boolean = false;

  @Input() error: boolean | null = false;

  @Output() valueChange = new EventEmitter<boolean>();

  value: boolean = false;

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setValue(value: boolean) {

    if(!this.disabled){
      this.value = value;
      this.onChange(value);
      this.valueChange.emit(value); 
      this.onTouched();
    }

  }


  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


}

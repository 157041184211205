<span id="consentDesignSpan"></span>

<app-layout class="agComponentHeader__header" [header]="applicationInfoService.breadCrumbText" class="maxHeight">
    <div class="consentManagment maxHeight"
        *ngIf="userService.hasPermission('CanReadSecurity.Module.SettingPageConsent')">

    <section class="consentManagment__containerRow">
        <div class="consentManagment__containerCol">
            <div class="consentManagment__leftContainer">
                <div class="consentManagment__tabs">
                    <app-tab label="Consent.Label.Header" svg="pencil-ruler" [active]="tab === 1" (onClick)="tab=1"></app-tab>
                    <app-tab label="Consent.Label.Body" svg="pencil-ruler" [active]="tab === 2" (onClick)="tab=2"></app-tab>
                    <app-tab label="Consent.Label.Footer" svg="pencil-ruler" [active]="tab === 3" (onClick)="tab=3"></app-tab>
                    <app-tab label="Consent.Label.Texte" svg="text-aa" [active]="tab === 4" (onClick)="tab=4"></app-tab>
                </div>
           
                <div class="consentManagment__inner">
                    <div *ngIf="tab == 1">
                        <app-layout header="Consent.Label.HeaderPicture" class="containerMargin maxHeight">  
                            <div class="consentManagment__component__description"> <app-jj-icons [pointer]="false" type="image" [width]="20" [height]="20"></app-jj-icons>{{ 'Consent.Label.HeaderPictureDescription' | texttransform }}</div>
                            <div class="consentManagment__component__inner">
                                <input type="file" #file accept="image/*" placeholder="Choose file"
                                    (change)="uploadPicture($event)" style="display:none;">
    
                                <div class="consentManagment__component__headerpicture">
                                    <div class="consentManagment__component__headerpicture__imagePlaceholder">
                                        <img [src]="consentSettings.headerPicture" class="consentManagment__component__headerpicture__imagePlaceholder__image"
                                            *ngIf="consentSettings.headerPicture != null">
                                        <div *ngIf="consentSettings.headerPicture == null">
                                            <app-jj-icons type="image" [width]="20" [height]="20" (click)="file.click()"></app-jj-icons>
                                            <div>
                                                {{ 'Consent.Label.NotUploadedYet' | texttransform }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="consentManagment__component__headerpicture__fields">
                                    
                                        <app-select-input label="Consent.Label.PictureType" [(ngModel)]="consentSettings.pictureType"
                                            (valueChange)="saveConsentSettings()"
                                            [options]="[{value:'banner',label:'Consent.Label.Banner'},{value:'logo', label:'Consent.Label.Logo'}]"
                                            bind="value" display="label" placeholder="-"></app-select-input>
                                    
                                    
                                    
                                        <app-select-input *ngIf="consentSettings?.pictureType === 'logo'" label="Consent.Label.PicturePosition"
                                            [(ngModel)]="consentSettings.picturePosition" (valueChange)="saveConsentSettings()" [options]="[
                                                                                        { value: 'flex-start', label: 'Consent.Label.Left'}, 
                                                                                        { value: 'center', label:'Consent.Label.Center'},
                                                                                        { value: 'flex-end', label: 'Consent.Label.Right'}
                                                                                        ]" bind="value" display="label"></app-select-input>
                                    
                                    
                                        <app-text-input label="Consent.Label.PictureHeight" [(ngModel)]="consentSettings.pictureHeight"
                                            (valueChange)="saveConsentSettings()"></app-text-input>
                                    
                                    
                                        <app-text-input *ngIf="consentSettings?.pictureType == 'logo'" label="Consent.Label.PictureWidth"
                                            [(ngModel)]="consentSettings.pictureWidth" (valueChange)="saveConsentSettings()"></app-text-input>
                                    
                                            <div class="consentManagment__component__buttonsContainer">
                                                <app-main-button type="small" label="Consent.Label.RemovePicture" (onClick)="removePicture()"
                                                    [disabled]="!consentSettings.headerPicture" svg="trash"></app-main-button>
                                            
                                                <app-main-button type="small" label="Consent.Label.UploadPicture" (onClick)="file.click()"
                                                    svg="image"></app-main-button>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </app-layout>

                        <app-layout header="Consent.Label.Header" class="containerMargin">
                            <div class="consentManagment__component__description">
                                <app-jj-icons type="pencil-ruler" [pointer]="false" [width]="20" [height]="20"></app-jj-icons>
                                    {{ 'Consent.Label.HeaderDescription' | texttransform }}
                            </div> 
                            <div class="consentManagment__component__inner">
                                <div class="consentManagment__component__fieldsContainer">
                                    <app-color-input label="Consent.Label.BackgroundColor" [(ngModel)]="consentSettings.headerBackgroundColor" (valueChange)="saveConsentSettings()"></app-color-input>
                       
                                    <app-text-input label="Consent.Label.HeaderHeight" [(ngModel)]="consentSettings.headerHeight" (valueChange)="saveConsentSettings()"></app-text-input>

                                    <app-text-input label="Consent.Label.HeaderHorizontalPadding" [(ngModel)]="consentSettings.headerHorizontalPadding" (valueChange)="saveConsentSettings()"></app-text-input>
        
                                </div>

                            </div>
                               
                            
    
                        </app-layout>
                    </div>
    
                    <div *ngIf="tab == 2">
                        <app-layout header="Consent.Label.Body" class="containerMargin maxHeight">
                            <div class="consentManagment__component__description"> <app-jj-icons [pointer]="false" type="pencil-ruler" [width]="20" [height]="20"></app-jj-icons>{{ 'Consent.Label.BodyDescription' | texttransform }}</div>
                            <div class="consentManagment__component__inner">
                                <div class="consentManagment__component__fieldsContainer">
                                    <app-text-input label="Consent.Label.BodyWith" [(ngModel)]="consentSettings.bodyWidth" (valueChange)="saveConsentSettings()"></app-text-input>
                                    <app-text-input label="Consent.Label.FontFamily" [(ngModel)]="consentSettings.fontFamily" (valueChange)="saveConsentSettings()"></app-text-input>
                                    <app-text-input label="Consent.Label.FontSize" [(ngModel)]="consentSettings.fontSize" (valueChange)="saveConsentSettings()"></app-text-input>
                                    <app-color-input label="Consent.Label.BackgroundColor" [(ngModel)]="consentSettings.bodyBackgroundColor" (valueChange)="saveConsentSettings()"></app-color-input>
                                    <app-color-input label="Consent.Label.TextColor" [(ngModel)]="consentSettings.bodyColor" (valueChange)="saveConsentSettings()"></app-color-input>
                                </div>
                            </div>
                            
                        </app-layout>
                    </div>
    
                    <div *ngIf="tab == 3">
                        <app-layout header="Consent.Label.Footer" class="containerMargin maxHeight">
                            <div class="consentManagment__component__description"><app-jj-icons type="pencil-ruler" [pointer]="false" [width]="20" [height]="20"></app-jj-icons>{{ 'Consent.Label.FooterDescription' | texttransform }}</div>
                            <div class="consentManagment__component__inner">
                                <div class="consentManagment__component__fieldsContainer">
                                    <app-checkbox label="Consent.Label.FooterEnabled" [(ngModel)]="consentSettings.footerEnabled" (valueChange)="saveConsentSettings()"></app-checkbox>
                                    <app-color-input label="Consent.Label.BackgroundColor" [(ngModel)]="consentSettings.footerBackgroundColor" (valueChange)="saveConsentSettings()"></app-color-input>
                                    <app-color-input label="Consent.Label.TextColor" [(ngModel)]="consentSettings.footerColor" (valueChange)="saveConsentSettings()"></app-color-input>
                                    <app-text-input label="Consent.Label.Impressum" [(ngModel)]="consentSettings.impressumLink" (valueChange)="saveConsentSettings()"></app-text-input>
                                    <app-text-input label="Consent.Label.DataProtection" [(ngModel)]="consentSettings.dataProtectionLink" (valueChange)="saveConsentSettings()"></app-text-input>
                                    <app-text-input label="Consent.Label.FooterHeight" [(ngModel)]="consentSettings.footerHeight" (valueChange)="saveConsentSettings()"></app-text-input>
                                </div>
                            </div>
                        </app-layout>
                    </div>
    
                    <div *ngIf="tab== 4">
                        <app-layout header="Consent.Label.Texte" class="containerMargin">
                            <div class="consentManagment__component__description">
                                <app-jj-icons type="text-aa" [pointer]="false" [width]="20" [height]="20"></app-jj-icons>
                                {{ 'Consent.Label.TexteDescription' | texttransform }}
                            </div>
                    <!--         <div class="consentManagment__component__description borderBottom" *ngIf="consentSettings">
                                    <app-select-input
                                    *ngIf="consentSettings"
                                    label="SelectLanguage"
                                    [complex]="true"
                                    [(ngModel)]="selectedLanguage"
                                    display="name"
                                    [options]="consentSettings.languages"></app-select-input>
                            </div> -->

                            <div class="consentManagment__component__inner">
                                <div class="consentManagment__component__fieldsContainer" *ngIf="selectedLanguage">
                                    <app-select-input
                                    *ngIf="consentSettings"
                                    label="SelectLanguage"
                                    [complex]="true"
                                    [(ngModel)]="selectedLanguage"
                                    display="name"
                                    [options]="consentSettings.languages"></app-select-input>
                                    <app-checkbox label="Consent.Label.Active" [(ngModel)]="selectedLanguage.isActive" (valueChange)="saveConsentSettings()"></app-checkbox>
                                    <div *ngFor="let translation of selectedLanguage.translations" >
                                        
                                        <app-text-area-input 
                                        *ngIf="translation.type == 'text-area'" 
                                        [label]="translation.description"
                                        [(ngModel)]="translation.value"
                                        (valueChange)="saveConsentSettings()"
                                        ></app-text-area-input>
    
                                        <app-text-input
                                        *ngIf="translation.type == 'input'"
                                        [label]="translation.description"
                                        [(ngModel)]="translation.value"
                                        (valueChange)="saveConsentSettings()"></app-text-input>
                                    </div>    
                                </div>

                            </div>
                        </app-layout>
                    </div>
    
                </div>

            </div>
        </div>

        <div class="consentManagment__containerCol">
            <app-layout header="Consent.Label.Preview">
                <div class="consent">
                    <iframe [src]=iframeSource *ngIf="iFrameLoaded == true"></iframe>
                </div>
              <!--   <iframe [src]=iframeSource *ngIf="iFrameLoaded == true"></iframe> -->
            </app-layout>
        </div>

    </section>

    </div>
</app-layout>

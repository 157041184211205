import { Component } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { PackagefeatureService } from "app/jollyjupiter/service/packagefeature.service";

@Component({
  selector: 'app-featurespackagessettings',
  templateUrl: './featurespackagessettings.component.html',
  styleUrl: './featurespackagessettings.component.scss'
})
export class FeaturespackagessettingsComponent {
  expandedIds: number[] = [];

  constructor(
    public packagefeatureService: PackagefeatureService,
    public applicationInfoService: ApplicationInfoService,
  ) { }

  isExpanded(id) {
    return this.expandedIds.includes(id);
  }

  toggleExpanded(id) {
    this.isExpanded(id)
      ? this.expandedIds = this.expandedIds.filter(featuresPackageId => id !== featuresPackageId)
      : this.expandedIds.push(id);
  }

  isPackageHasFeature(featuresPackage, feature) {
    console.log('')
    return featuresPackage.featuresRelations.some(relation => (relation.featureId === feature.id));
  }

  updatePackageHasFeature(featuresPackage, featuresPackageId, featureId, value) {
    value
      ? this.addFeatureRelation(featuresPackage, featuresPackageId, featureId)
      : this.deleteFeatureRelation(featuresPackage, featuresPackageId, featureId);
      
  }

  addFeatureRelation(featuresPackage, featuresPackageId, featureId) {
    this.packagefeatureService.createPackageFeatureRelation(featuresPackageId, featureId).then(addFeatureRelationResult => {
      featuresPackage.featuresRelations.push(addFeatureRelationResult);
    })
  }

  deleteFeatureRelation(featuresPackage, featuresPackageId, featureId) {
    const relation = featuresPackage.featuresRelations.find(relation => relation.featureId == featureId && relation.packageId == featuresPackageId);
    if (relation) {
      this.packagefeatureService.deletePackageFeatureRelation(relation.id).then(() => {
        featuresPackage.featuresRelations.splice(featuresPackage.featuresRelations.indexOf(relation), 1);
      });
    }    
  }

  updateFeaturesPackage(featuresPackageId) {
    this.packagefeatureService.updatePackage(featuresPackageId);
  }

  addNewFeaturesPackage() {
    this.packagefeatureService.createPackage().then((featuresPackage) => {
      this.expandedIds.push(featuresPackage.id);
      this.packagefeatureService.getAllPackages()
    })
  }
}

<div class="campaignDashboard">
    <app-layout [header]="applicationInfoService.breadCrumbText">
        <div class="campaignDashboard__inner">

            <div class="campaignDashboard__tabs">
                <app-tab label="CampaignDashboard.Label.MyMailCampaigns" svg="funnel" [active]="page === 0"
                    (onClick)="page= 0"></app-tab>
                <app-tab label="CampaignDashboard.Label.MyMicroCampaigns" svg="chart-bar" [active]="page === 1"
                    (onClick)="page=1"></app-tab>
            </div>

            <div class="campaignDashboard__nav">
                <div class="campaignDashboard__nav__label">
                    <span *ngIf="page === 0"> {{ 'CampaignDashboard.Label.MyMailCampaigns' | texttransform }}</span>
                    <span *ngIf="page === 1">{{ 'CampaignDashboard.Label.MyMicroCampaigns' | texttransform }}</span>
                </div>

                <div class="campaignDashboard__nav__options" *ngIf="page === 0">
<!--                     <app-main-button type="small" svg="sliders-horizontal" (onClick)="showMailCampaigns()"
                        label="UserDashboard.Label.CampaignBoard"></app-main-button> -->
                    <app-main-button type="small" svg="plus" label="UserDashboard.Label.NewCampaign"
                        (onClick)="createMailshot()" [disabled]="!this.userService.hasPermission('CanCreateMailshotList')"></app-main-button>
                    <!-- <app-filter (keyUp)="mailCampaignSearchString = $event"></app-filter> -->
                </div>
            </div>

            <div *ngIf="page === 0" class="campaignDashboard__mailListContent">
                <app-campaigndashboardlist [data]="mailCampaigns" [page]="page"></app-campaigndashboardlist>
            </div>

            <div class="campaignDashboard__content" *ngIf="page === 1">
                <div class="campaignDashboard__content__left">
                    <app-campaigndashboardlist [page]="page" [data]="microCampaignOverview" (dataChange)="showMicroCampaignDetails($event)"></app-campaigndashboardlist>
                </div>

                <div class="campaignDashboard__content__right">
                    <app-campaigndashboardchart [page]="page"
                        [selectedMicroCampaign]="selectedMicroCampaign"
                        [chartData]="chartDataMicroCampaign"></app-campaigndashboardchart>
                </div>
            </div>
        </div>
    </app-layout>
</div>
<app-layout header="EntityTableFilter.Label.Header">
    <div class="entityTableFilterWrapper">
        <div class="entityTableFilterInfolabel">{{ 'EntityTableFilter.Label.SectionDescription' | texttransform }}</div>
        <div class="entityTableFilterFilterList">
            <div *ngFor="let filter of filterList" class="entityTableFilterFilterListItem__wrapper everleadTableRow">
                <div class="entityTableFilterFilterListItem">
                    <div class="entityTableFilterFilterListItem__name">{{ filter.defaultName | texttransform}}</div>
                    <div class="entityTableFilterFilterListItem__option">
                        <select [(ngModel)]="filter.operator">
                            <option [ngValue]="null">{{ 'EntityTableFilter.Label.DontUseThisFilter' | texttransform }}</option>
                            <option *ngFor="let option of getOptions(filter)" [ngValue]="option.operator">{{ option.defaultName | texttransform }}</option>
                        </select>
                    </div>
                    <div class="entityTableFilterFilterListItem__value">
                        <input *ngIf="getInputValues(filter.operator) > 0" [(ngModel)]="filter.option1" 
                            class="entityTableFilterFilterListItem__value__text">
                        <input *ngIf="getInputValues(filter.operator) > 1" 
                            [(ngModel)]="filter.option2" class="entityTableFilterFilterListItem__value__text">
                    </div>                
                </div>
                <div class="entityTableFilterFilterListItem entityTableFilterFilterListAdditionalItem__wrapper" *ngIf="filter.lookupValues?.length > 0">
                    <div class="entityTableFilterFilterListAdditionalItem" 
                        class="lookupValueItemListHeader">
                        {{ 'EntityTableFilter.Label.QuickSelect' | texttransform }}
                    </div>
                    <div class="entityTableFilterFilterListAdditionalItem lookupValueItemList">
                        <div *ngFor="let lookupValue of filter.lookupValues" class="lookupValueItem">
                            <input type="checkbox" [(ngModel)]="lookupValue.selected">
                            <div>{{ lookupValue.defaultName | texttransform }}</div>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
        <div class="entityTableFilterButtons">
            
        </div>
        <div class="entityTableFilterButtons">
            <app-main-button type="borderSmall" svg="floppy-disk" (onClick)="saveFilter()" label="EntityTableFilter.Label.SaveFilterForMyUser"></app-main-button>
            <app-main-button type="borderSmall" svg="x" (onClick)="removeFilter()" label="EntityTableFilter.Label.RemoveFilter"></app-main-button>
            <app-main-button type="small" svg="magnifying-glass" (onClick)="performSearch()" label="EntityTableFilter.Label.Search"></app-main-button>            
        </div>
    </div>
</app-layout>
import { Component, OnInit, Injector } from '@angular/core';
import { CustomControlBaseClass } from '../customcontrolbaseclass';
import { ValueType } from '../../../../jollyjupiter/service/entity.service';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss']
})
export class CustomCheckboxComponent extends CustomControlBaseClass implements OnInit {
  dropDownSelection: any = null;
  data: any[] = [];

  constructor(
    private injector: Injector ,
  ) {
    super(injector);
    this.valueType = ValueType.Boolean;
  }

  changeValue() {
    if (this.isEnabled) {
      this.controlValue = !this.controlValue;
      this.callMethod('onChange');
    }
  }

  ngOnInit() {
    if (this.entity != null) {
      this.controlValue = this.entityService.getEntityValue(this.entity, this.controlDefinition.value, 1);
      if (this.controlValue == null) {
        this.controlValue = false;
        this.entityService.insertDataValue(this.controlDefinition.uiEntityInstanceId, this.controlDefinition.value, false);
      }
    }
    this.checkAttributes();
  }

  changeWhatever($event){
    
  }


}

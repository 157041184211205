import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wizard-infobutton',
  templateUrl: './wizard-infobutton.component.html',
  styleUrls: ['./wizard-infobutton.component.scss']
})
export class WizardInfobuttonComponent implements OnInit {
  @Input() displayText = '';
  @Input() width: number = 15;
  @Input() height: number = 15;
  @Input() color: string = 'secondary'

  constructor() { }

  ngOnInit(): void {
  }

}

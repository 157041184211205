<div class="defaultDashboardOpportunityWrapper">    
        <app-layout header="Opportunity">
            <div class="defaultDashboardOpportunityContentWrapper">
            <div class="defaultDashboardOpportunityToolbar">
                <app-select-input label="Pool" [(ngModel)]="poolId" [options]="pools" display="defaultName" bind="id" (valueChange)="getDashboardDataArray(dashboardId)"></app-select-input>
            </div>
        <div class="defaultDashboardOpportunityFunnels">
            <app-layout header="Report.Opportunity.Volume" class="opportunityLayoutItem">
                <div class="opportunityFunnels">
                    <app-echarts
                        *ngIf="showFunnelData"
                        [chart]="'funnel'"
                        [chartData]="funnelValue"
                        controlid="opportunityValue"
                        [legend]="false"
                        [legendPosition]="{'bottom': 0}"
                        height="250"
                        [sort]="'none'"
                        [orient]="'vertical'"
                        [gap]="1"
                        [noFunnelBackground]="true"
                        [funnelAlign]="'center'"
                        [max]=0
                        [funnelLabelPosition]="{'position': 'center', 'show': true }"  
                        [showAsCurrency]="true"
                    ></app-echarts>
                </div>
            </app-layout>

            <app-layout header="Report.Opportunity.Amount" class="opportunityLayoutItem">
                <div class="opportunityFunnels">
                    <app-echarts
                        *ngIf="showFunnelAmount"
                        [chart]="'funnel'"
                        [chartData]="funnelAmount"
                        controlid="opportunityAmount"
                        [legend]="false"
                        [legendPosition]="{'bottom': 0}"
                        height="250"
                        [sort]="'none'"
                        [orient]="'vertical'"
                        [gap]="1"
                        [noFunnelBackground]="true"
                        [funnelAlign]="'center'"
                        [max]=0        
                        [funnelLabelPosition]="{'position': 'center', 'show': true }"  
                    ></app-echarts>
                </div>
            </app-layout>
        </div>
        <app-dashboard-list component="opportunity" [data]="tableData" controlid="opportunityList"></app-dashboard-list>
        </div>
    </app-layout>    
</div>
import { AuthService } from '../../core/authentication/services/auth.service';
import { EventEmitter, Injector } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import * as CryptoJS from 'crypto-js';
import { SessionStorageService } from 'ngx-webstorage';
import { ApplicationInfoService } from '../../core/application/application-info.service';
import { UserService } from 'app/core/authentication/services/user.service';
import { environment } from 'environments/environment';

export class SignalRBaseClass {
    appUserInformation = 'JJUserInformation';
    public hubConnection: signalR.HubConnection | undefined;
    public connectionState = 0;
    public connectedServer = 0;
    public ConnectionEstablishedEvent: EventEmitter<boolean> = new EventEmitter();

    public signalRUrl = '';
    public signalRName = '';

    applicationInfoService: ApplicationInfoService;
    authService: AuthService;
    sessionStorage: SessionStorageService;
    userService: UserService;

    constructor(
        private injectorObj: Injector,
    ) {

        this.authService = this.injectorObj.get(AuthService);
        this.sessionStorage = this.injectorObj.get(SessionStorageService);
        this.applicationInfoService = this.injectorObj.get(ApplicationInfoService);
        this.userService = this.injectorObj.get(UserService);
    }

    public InitializeDefaultConnection(): Promise<any> {
      return new Promise((InitializeDefaultConnectionResolve, InitializeDefaultConnectionReject) => {
            if (this.hubConnection != undefined) {
              if (this.hubConnection.state == 1) {
                console.log('InitializeDefaultConnection', this.signalRName, 'already connected');
                return;
              }
            }

            const url = this.signalRUrl;
            this.hubConnection = new signalR.HubConnectionBuilder().withUrl(url).build();
            this.hubConnection.keepAliveIntervalInMilliseconds = 16000;
            this.hubConnection.start().then(() => {
                console.log('InitializeDefaultConnection', this.signalRName, ': signalr default connection started');
                this.connectionState = 1;
                this.connectedServer = 1;
                this.ConnectionEstablishedEvent.emit(true);
                this.hubConnection.serverTimeoutInMilliseconds  = 60000;
                InitializeDefaultConnectionResolve(null);
            })
            .catch(err => {
                console.log('InitializeDefaultConnection', this.signalRName, ': Connection eror', err);
                this.sessionStorage.store('SignalRConnectionError', Date.now().toString());
                this.InitializeFallbackConnection().then(data => {
                  InitializeDefaultConnectionResolve(data);
                })
                .catch(error => { InitializeDefaultConnectionReject(error); });
            });
        });
      }

      public InvokeLogin(): Promise<any>  {
        return new Promise((InvokeLoginResolve, InvokeLoginReject) => {
          let invokePromise: Promise<any>;
          if (environment.newSoftPhone === 'true') {
            invokePromise = this.hubConnection.invoke('Login');
          } else {
            invokePromise = this.hubConnection.invoke('Login', this.userService.getUserId());
          }
          invokePromise.then(invokeLoginResult => {
            if (invokeLoginResult === false) {
              console.log('singalR login failed', invokeLoginResult);
              InvokeLoginReject(invokeLoginResult);
            } else {
              console.log('singalR login success', invokeLoginResult);
              InvokeLoginResolve(invokeLoginResult);
            }
          })
          .catch(error => {
            console.log('singalR login failure', error); InvokeLoginReject(error);
          });
        });
      }

      public reInitializeServer() {
        if (this.connectedServer === 2) {
          this.InitializeFallbackConnection();
        } else {
          this.InitializeDefaultConnection();
        }
      }

      public invokeInit() {
        if (this.connectionState === 1) {
          this.hubConnection.invoke('login');
        }
      }

      public InitializeFallbackConnection(): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log('InitializeFallbackConnection', this.signalRName, 'fallback connection init');
            const url = this.signalRUrl;
            let token = '';
            if (this.authService.master) {
                // token = this.authService.currentUser.access_token;
            } else {
                token = localStorage.getItem(this.appUserInformation);
            }

            this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url, { transport: signalR.HttpTransportType.LongPolling,
                    // accessTokenFactory: () => this.authService.currentUser.access_token})
                    accessTokenFactory: () => token})
            .configureLogging(signalR.LogLevel.Information)
            .build();
            // this.AddHubConnectionMethods();
            this.hubConnection.start().then((data: any) => {
                console.log('InitializeFallbackConnection', this.signalRName, 'fallback connection started');
                this.hubConnection.invoke('login');
                this.connectionState = 1;
                this.connectedServer = 2;
                this.ConnectionEstablishedEvent.emit(true);
                resolve(data);
            })
            .catch(error => {
                this.connectionState = 2;
                this.ConnectionEstablishedEvent.emit(false);
                reject(error);
            });
        });
      }

      private encryptSignalRText (text: string, iv: string): string {
        const dataIv = '000' + iv;
        const dataKey = 'efoije4rpk40rk4!';

        return this.encryptData(dataKey, dataIv, text);
      }

      private encryptData (dataKey: string, dataIv: string, txtText: string): string {
        if (txtText.length > 0 ) {
          const key = CryptoJS.enc.Utf8.parse(dataKey);
          const iv = CryptoJS.enc.Utf8.parse(dataIv);

          const encryptedData = CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(txtText),
            key,
            {
              keySize: 128 / 8,
              iv: iv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7
            }
          );

          const encodeData = encodeURIComponent(encryptedData.toString());

          return encodeData;
        }

        return '';
      }
}

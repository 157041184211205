<div *ngIf="paletteObject != null" class="palleteDesignerWrapper">
    <!-- <div class="paletteDesignerHeader">       
        <div>
            <button class="everleadCustomButton" (click)="addControl()">{{ 'Palette.Label.NewPalette' | texttransform }}</button>
        </div>
    </div> -->
    <div class="paletteDesignerControls">
        
        <div class="paletteDesignerControls__header">
            <app-component-header [header]="applicationInfoService.breadCrumbText"></app-component-header>        
        <app-main-button
            type="small"
            svg="palette"
            label="Palette.Label.NewPalette"
            (onClick)="addPalette()"
            ></app-main-button>
        </div>

        <div class="paletteDesignerControls__inner">
            <div class="paletteDesignerControls__content">
                <app-accordion *ngFor="let palette of array.controls; let index = i" [title]="palette['languageTextde'] || palette['languageTexten'] || palette['controlSettingPaletteLocation']" (click)="paletteTabId = index" [isOpen]="paletteTabId === index">
                    <div class="palleteControlItemOuter">
                        <div class="palleteControlItem">
                            <div class="palleteItemLine">
                                <label class="palleteItemLine__label">{{ 'Palette.Label.IsActive' | texttransform }}</label>
                                <div class="palleteItemLine__value">
                                    <app-checkbox [(ngModel)]="palette['isActive']" (valueChange)="savePalette()"></app-checkbox>
                                </div>
                
                            </div>
                            <div *ngFor="let language of languages">
                                <div class="palleteItemLine">
                                    <label class="palleteItemLine__label">
                                        {{ 'Palette.Label.TranslationText' | texttransform }}
                                        {{ commonService.getTranslationValueFromArray(language, 'languageName') | texttransform }}
                                    </label>
                                    <div class="palleteItemLine__value">
                                        <app-text-input [(ngModel)]="palette['languageText' + language.languageName]" (valueChange)="savePalette()"></app-text-input>
                                    </div>
                                </div>    
                            </div>
                            <div class="palleteItemLine">
                                <label class="palleteItemLine__label">{{ 'Palette.Label.ControlSettingPaletteLocation' | texttransform }}</label>
                                <div class="palleteItemLine__value">
                                    <app-select-input 
                                    [(ngModel)]="palette['controlSettingPaletteLocation']"
                                    (valueChange)="savePalette()"
                                    [options]="locations"
                                    bind="value"
                                    display="defaultName"
                                    ></app-select-input>
                                </div>
                            </div>
                            <div class="palleteItemLine isNewDeveloper" *ngIf="applicationInfoService.isDeveloper">
                                <label class="palleteItemLine__label">{{ 'Palette.Label.ControlSettingPaletteLocation' | texttransform }}</label>
                                <div class="palleteItemLine__value">
                                    <app-text-input [(ngModel)]="palette['controlSettingPaletteLocation']" (valueChange)="savePalette()"></app-text-input>
                                </div>
                            </div>
                            <div class="palleteItemLine">
                                <label class="palleteItemLine__label">{{ 'Palette.Label.WhatToDisplay' | texttransform }}</label>
                                <div class="palleteItemLine__value">
                                    <app-select-input
                                    [(ngModel)]="palette['controlSettingPaletteWhatToDisplay']"
                                    (valueChange)="savePalette()"
                                    bind="value"
                                    display="defaultName"
                                    [options]="targets"></app-select-input>
                
                                </div>
                            </div>
                            <div class="palleteItemLine">
                                <label class="palleteItemLine__label">{{ 'Palette.Label.ControlSettingPaletteLocationCheckPart' | texttransform }}</label>
                                <div class="palleteItemLine__value">
                                    <app-checkbox [(ngModel)]="palette['controlSettingPaletteLocationCheckPart']" (valueCahnge)="savePalette()"></app-checkbox>
                                </div>
                            </div>
                            <div class="palleteItemLine">
                                <label class="palleteItemLine__label">{{ 'Palette.Label.ControlSettingPaletteLocationMinX' | texttransform }}
                                    <app-wizard-infobutton displayText="Palette.Label.ControlSettingPaletteLocationMinXDesc"></app-wizard-infobutton>
                                </label>
                                <div class="palleteItemLine__value">
                                    <app-number-input [(ngModel)]="palette['controlSettingPaletteLocationMinX']" (valueChange)="savePalette()"></app-number-input>
                                </div>
                            </div>
                            <div class="palleteItemLine">
                                <label class="palleteItemLine__label">{{ 'Palette.Label.ControlSettingPaletteLocationMaxX' | texttransform }}
                                    <app-wizard-infobutton displayText="Palette.Label.ControlSettingPaletteLocationMaxXDesc"></app-wizard-infobutton>
                                </label>
                                <div class="palleteItemLine__value">
                                    <app-number-input [(ngModel)]="palette['controlSettingPaletteLocationMaxX']" (valueChange)="savePalette()"></app-number-input>
                                </div>
                            </div>
                            <div class="palleteItemLine">
                                <label class="palleteItemLine__label">{{ 'Palette.Label.ControlSettingPaletteWidth' | texttransform }}
                                    <app-wizard-infobutton displayText="Palette.Label.ControlSettingPaletteWidthDesc"></app-wizard-infobutton>
                                </label>
                                <div class="palleteItemLine__value">
                                    <app-number-input [(ngModel)]="palette['controlSettingPaletteWidth']" (valueChange)="savePalette()"></app-number-input>
                                </div>
                            </div>
                            <div class="palleteItemLineFooter">              
                                <div class="palleteItemLineFooter__options">
                                    <app-jj-icons type="copy" [width]="25" [height]="25" (click)="clonePalette(palette)" [tippy]="'Palette.Label.ClonePalette' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></app-jj-icons>
                                    <app-jj-icons type="trash" [width]="25" [height]="25" (click)="removePalette(palette)"  [tippy]="'Palette.Label.RemovePalette' | texttransform" [tippyOptions]="{theme:'everleadRed', placement:'bottom'}"></app-jj-icons>
<!--                                     <i class="fas fa-clone fingerCursor" (click)="clonePalette(palette)" [tippy]="'Palette.Label.ClonePalette' | texttransform" [tippyOptions]="{theme:'everleadBlack', placement:'bottom'}"></i> -->
                                   <!--  <i class="fas fa-trash everleadCoral fingerCursor" (click)="removePalette(palette)" [tippy]="'Palette.Label.RemovePalette' | texttransform" [tippyOptions]="{theme:'everleadRed', placement:'bottom'}"></i> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </app-accordion>
            </div>
        </div>
    </div>
</div>
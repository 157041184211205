import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { WizardService , WizardMode } from 'app/jollyjupiter/controls/static/wizard/wizard.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';

@Component({
  selector: 'app-campaigndashboardchart',
  templateUrl: './campaigndashboardchart.component.html',
  styleUrl: './campaigndashboardchart.component.scss'
})
export class CampaigndashboardchartComponent implements OnInit, OnChanges{

 constructor(
  public axivasTranslateService: AxivasTranslateService,
  private commonService: CommonService,
  private applicationInfoService: ApplicationInfoService,
  private eventService: EventService,
  private wizardService: WizardService
){}

  @Input() page: number | null = 0;
  @Input() chartData: any | null =  null;
  @Input() selectedMicroCampaign : any | null = null;


  ngOnInit(): void {
/*     console.log('charData', this.chartData);
    console.log('selectedMicroCampaign', this.selectedMicroCampaign); */
  }
  ngOnChanges(changes: SimpleChanges): void {

/*    console.log('charData', this.chartData);
    console.log('selectedMicroCampaign', this.selectedMicroCampaign); */
  }


  getSendDate(selectedMailCampaign) {
    if (!this.commonService.isNullOrUndefined(selectedMailCampaign.wizardValues)) {
      const mailShotInfo = JSON.parse(selectedMailCampaign.wizardValues);
      return mailShotInfo.followUpDate;  
    } else {
      return null;
    }
  }


  showMailshotDetails(mailshot) {
    this.applicationInfoService.miscSettings['emailbatch'] = mailshot;
    this.eventService.showJjPopup('Mailshots.Label.Details', 'emailbatchdetails', '100');
  }


  editMailCampaign(campaignOverviewItem) {
    this.wizardService.showWizard(WizardMode.Mailshot, campaignOverviewItem, '90', '90');
  }

  showCampaignUser(selectedMicroCampaign) {
    this.applicationInfoService.miscSettings['presetMicroCampaignId'] = selectedMicroCampaign.resultId;
    this.eventService.showJjPopup('', this.applicationInfoService.applicationSettings['microCampaignParticipantControlId'], '90');
  }

  getIconDefinition(legend){

    switch(legend){

      case 'Mailshots.Label.CountBounced':
        return 'warning';
      case 'Mailshots.Label.CountSend':
        return 'paper-plane-tilt';
      case 'Mailshots.Label.Clicked':
        return 'mouse-simple';
      case 'Mailshots.Label.Open':
        return 'envelope-open';
      case 'Mailshots.Label.Delivered':
        return 'receipt-x';
        default:
          return '';
    }
  }


  mailShot(){}

}

<!-- <div class="vipUsers">
    <div class="vipUsers__header">
        <label>{{ 'VipUsers.Label.Header' | texttransform }}</label>  
        <button class="everleadCustomButton" (click)="openVipSettings()">{{ 'UserSettings.Label.OpenVipSettings' | texttransform }}</button>          
    </div>
    <div class="vipUsers__description">
        {{ 'VipUsers.Label.Description' | texttransform }}
    </div>    
    <div class="everleadContentContainer" class="vipUsers__content">
        <div [innerHTML]="innerHTML" class="everleadMaxHeightOverflow"></div>
    </div>
</div> -->

<div class="vipActivity">
    <div class="vipActivity__header">
        <app-component-header  header="VipUsers.Label.Header"></app-component-header>
        <app-main-button 
        type="small"
        label="UserSettings.Label.OpenVipSettings"
        (onClick)="openVipSettings()"></app-main-button>
    </div>

    <div class="vipActivity__inner">
        <div class="vipActivity__wrapper">
            <div class="vipActivity__contentHeader">
                {{ 'VipUsers.Label.Description' | texttransform }}
            </div>
            <div class="vipActivity__content">
                <div [innerHTML]="innerHTML" class="everleadMaxHeightOverflow"></div>
            </div>
        </div>
    </div>


</div>

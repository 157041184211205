
    <div class="customOptinOptoutOverview">
        <div class="customOptinOptoutOverview__header">
            <app-component-header header="OptInOptOut.Label.OptInOptOut"></app-component-header>
            <app-main-button type="small" svg="plus" [label]="'OptinOptOut.Label.NewItem' | texttransform" (onClick)="newOptinOptOutItem()"></app-main-button>
        </div>
        <div class="customOptinOptoutOverview__inner">
            <div class="customOptinOptoutOverview__innerWrapper">
                <div class="customOptinOptoutOverview__tabs">
                    <app-tab [active]="viewMode === 1" [label]="axivasTranslationService.getTranslationTextForToken('OptinOptOut.Label.Overview')" (onClick)="viewMode = 1"></app-tab>
                    <app-tab [active]="viewMode === 2" [label]="axivasTranslationService.getTranslationTextForToken('OptinOptOut.Label.Detailview')" (onClick)="viewMode = 2"></app-tab>
                </div>
                <div *ngIf="optOutsCollected && viewMode==1">
                    <div class="optinOverviewItem optinOverviewItem__small">
                        <div class="optinOverviewItem__state"></div>
                        <div class="optinOverviewItem__name">Medium</div>
                        <div class="optinOverviewItem__type">Typ</div>
                        <div class="optinOverviewItem__startDate">{{ 'OptinOptOut.Label.LatestEntry' | texttransform }}</div>
                        <div class="optinOverviewItem__endDate">Enddatum</div>
                        <div class="optinOverviewItem__status">IP</div>
                        <div class="optinOverviewItem__status">Benutzer</div>
                        <div class="optinOverviewItem__comment"></div>            
                    </div>
                    <div class="customOptinOptoutOverview__content__inner">
                        <div *ngFor="let availableMediaItem of availableMedia">
                        <div class="optinOverviewItem">
                            <div class="optinOverviewItem__state">
                                <app-status
                                width="20px"
                                height="20px" 
                                [blue]="(getStatus(availableMediaItem)?.lookupTypeRefId == 44 && getStatus(availableMediaItem)?.endDateInPast == false)"
                                [yellow]="(getStatus(availableMediaItem)?.lookupTypeRefId == 1815 && getStatus(availableMediaItem)?.endDateInPast == false)"
                                [green]="(getStatus(availableMediaItem)?.lookupTypeRefId == 43 && getStatus(availableMediaItem)?.endDateInPast == false)"
                                [red]=" getStatus(availableMediaItem)?.endDateInPast == true"
                                ></app-status>
                            </div>
                            <div class="optinOverviewItem__name">{{ availableMediaItem.defaultName }}</div>
                            <div class="optinOverviewItem__type">
                                <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 1815">DoubleOptIn</span>
                                <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 44">OptOut</span>
                                <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 43">OptIn</span>
                            </div>
                            <div class="optinOverviewItem__startDate"><span *ngIf="getStatus(availableMediaItem).startDate != null">{{ getStatus(availableMediaItem).startDate | date:'medium' }}</span></div>
                            <div class="optinOverviewItem__endDate">
                                <span *ngIf="getStatus(availableMediaItem).endDate != null" [ngClass]="{
                                    everleadCoral: getStatus(availableMediaItem)?.endDateInPast == true
                                }">{{ getStatus(availableMediaItem).endDate | date:'medium' }}</span>
                            </div>
                            <div class="optinOverviewItem__status">
                                <div *ngIf="getStatus(availableMediaItem).ip != null">{{ getStatus(availableMediaItem).ip }}</div>
                            </div>
                            <div class="optinOverviewItem__status">
                                <div *ngIf="getStatus(availableMediaItem).userId != null" class="ellipsisText"
                                    [tippy]="getUser(getStatus(availableMediaItem).userId)">{{ getUser(getStatus(availableMediaItem).userId) }}</div>
                            </div>
                            <div class="optinOverviewItem__comment">
                                <app-jj-icons svg="note" [width]="20" *ngIf="getStatus(availableMediaItem).comment != null" [height]="20" color="secondary" [tippy]="getStatus(availableMediaItem).comment"></app-jj-icons>
    <!--                             <i class="fa-lg far fa-sticky-note" *ngIf="getStatus(availableMediaItem).comment != null" [tippy]="getStatus(availableMediaItem).comment"></i> -->
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            
                <div *ngIf="optOutsCollected && viewMode==2" class="customOptinOptoutOverview__view2">
                    <div [innerHTML]="innerHTML"></div>
                </div>
        </div>
        </div>
    </div>
 



<!-- <div class="optinOverview__wrapper" [ngClass]="{ optinOverviewDarkmode: darkMode }">
    <ng-container *ngIf="optOutsCollected == false" class="optinOverview__spinner">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px"></i>{{'General.Label.LoadingData'|texttransform}}
    </ng-container>
    <div class="radioButtonSelector">
        <div class="radioButtonSelector__buttons">
            <app-radio-button type="row" [(ngModel)]="viewMode" [options]="[{value:1, label: axivasTranslationService.getTranslationTextForToken('OptinOptOut.Label.Overview')},{ value:2, label: axivasTranslationService.getTranslationTextForToken('OptinOptOut.Label.Detailview')}]"></app-radio-button>
            <!-- <div class="radioButtonSelector__button"><input type="radio" [(ngModel)]="viewMode" [value]=1>{{ 'OptinOptOut.Label.Overview' | texttransform }}</div>
            <div class="radioButtonSelector__button"><input type="radio" [(ngModel)]="viewMode" [value]=2>{{ 'OptinOptOut.Label.Detailview' | texttransform }}</div>   
        </div>
        <div class="radioButtonSelector__new" (click)="newOptinOptOutItem()">
            <i class="fas fa-plus"></i>{{ 'OptinOptOut.Label.NewItem' | texttransform }}
        </div>
    </div>
    <div *ngIf="optOutsCollected && viewMode==1">
        <div class="optinOverviewItem optinOverviewItem__small">
            <div class="optinOverviewItem__name">Medium</div>
            <div class="optinOverviewItem__type">Typ</div>
            <div class="optinOverviewItem__startDate">{{ 'OptinOptOut.Label.LatestEntry' | texttransform }}</div>
            <div class="optinOverviewItem__endDate">Enddatum</div>
            <div class="optinOverviewItem__status">IP</div>
            <div class="optinOverviewItem__status">Benutzer</div>
            <div class="optinOverviewItem__comment"></div>            
        </div>
        <div *ngFor="let availableMediaItem of availableMedia">
            <div class="optinOverviewItem everleadShadowBox everleadShadowBox" [ngClass]="{
                    everleadCoralBackground:  (getStatus(availableMediaItem)?.lookupTypeRefId == 44 && getStatus(availableMediaItem)?.endDateInPast == false),
                    everleadGreenBackground:  (getStatus(availableMediaItem)?.lookupTypeRefId == 43 && getStatus(availableMediaItem)?.endDateInPast == false),
                    goldStatus:               (getStatus(availableMediaItem)?.lookupTypeRefId == 1815 && getStatus(availableMediaItem)?.endDateInPast == false),
                    endDateInPast:            getStatus(availableMediaItem)?.endDateInPast == true
                }">
                <div class="optinOverviewItem__name">{{ availableMediaItem.defaultName }}</div>
                <div class="optinOverviewItem__type">
                    <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 1815">DoubleOptIn</span>
                    <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 44">OptOut</span>
                    <span *ngIf="getStatus(availableMediaItem).lookupTypeRefId == 43">OptIn</span>
                </div>
                <div class="optinOverviewItem__startDate"><span *ngIf="getStatus(availableMediaItem).startDate != null">{{ getStatus(availableMediaItem).startDate | date:'medium' }}</span></div>
                <div class="optinOverviewItem__endDate">
                    <span *ngIf="getStatus(availableMediaItem).endDate != null" [ngClass]="{
                        everleadCoral: getStatus(availableMediaItem)?.endDateInPast == true
                    }">{{ getStatus(availableMediaItem).endDate | date:'medium' }}</span>
                </div>
                <div class="optinOverviewItem__status">
                    <div *ngIf="getStatus(availableMediaItem).ip != null">{{ getStatus(availableMediaItem).ip }}</div>
                </div>
                <div class="optinOverviewItem__status">
                    <div *ngIf="getStatus(availableMediaItem).userId != null" class="ellipsisText"
                        [tippy]="getUser(getStatus(availableMediaItem).userId)">{{ getUser(getStatus(availableMediaItem).userId) }}</div>
                </div>
                <div class="optinOverviewItem__comment">
                    <i class="fa-lg far fa-sticky-note" *ngIf="getStatus(availableMediaItem).comment != null" [tippy]="getStatus(availableMediaItem).comment"></i>
                </div>
            </div>
        </div>
    </div>    
    <div *ngIf="optOutsCollected && viewMode==2">
        <div [innerHTML]="innerHTML"></div>
    </div>
</div> -->
<div class="awsLoginWrapper">
    <div class="awsLoginLogo">
        <svg width="61" height="35" viewBox="0 0 61 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_814_12535)">
          <path d="M42.6821 15.2176C42.136 15.0864 41.5638 14.9815 40.9916 14.929C40.4194 14.8765 39.8473 14.8765 39.2751 14.929C34.1776 15.2438 31.9669 18.1824 29.6782 20.6224C28.5079 21.8555 28.794 21.5407 28.6899 21.6719C24.7887 25.8435 20.4194 30.5399 15.3219 30.5399C10.2244 30.5399 13.3453 31.4058 13.3453 32.5077C13.3453 33.6097 14.2296 34.4755 15.3219 34.4755C20.5755 34.4755 24.7367 31.3008 28.4038 27.6539C30.2764 29.438 32.253 31.0647 34.3856 32.3241C37.1425 33.977 40.1334 35.0265 43.4103 35.0265C46.6873 35.0265 48.3518 34.4755 50.5625 33.5047C51.8109 32.9537 52.9812 32.2453 54.0735 31.432C54.2036 31.3271 54.3336 31.2221 54.4637 31.1172C54.5157 31.0647 54.5677 31.0385 54.6197 30.986C54.8278 30.8023 55.0358 30.6449 55.2439 30.435C55.686 30.0414 56.1021 29.6217 56.4923 29.2019C56.5703 29.1232 56.6223 29.0444 56.6743 28.9395C57.1165 28.441 57.5326 27.89 57.9227 27.339C58.5209 26.447 58.3128 25.2139 57.4025 24.5842C56.5183 23.9807 55.2959 24.1906 54.6717 25.1089C53.9435 26.1846 53.0852 27.1292 52.0969 27.9425C51.1347 28.7296 50.0943 29.4118 48.95 29.9103C47.2595 30.6449 45.3869 31.0647 43.4103 31.0647C41.4337 31.0647 38.5989 30.2251 36.4142 28.9395C34.5677 27.8376 32.7992 26.3683 31.1086 24.7941L31.5508 24.3218C32.305 23.5085 31.9929 23.8233 32.5131 23.2723C34.4637 21.2259 36.3882 19.022 39.5092 18.8121C39.8993 18.8121 40.2894 18.8121 40.6275 18.8121C40.9916 18.8383 41.3557 18.917 41.7458 18.9957C42.0839 19.0744 42.396 19.1794 42.7081 19.3106C43.0202 19.4417 43.3323 19.5729 43.6184 19.7566C44.5547 20.2813 45.751 19.9402 46.2972 19.022C46.8174 18.0774 46.5053 16.8968 45.543 16.372C45.0748 16.1097 44.6327 15.8998 44.1386 15.7161C43.6444 15.5325 43.1503 15.375 42.6561 15.2438L42.6821 15.2176Z" fill="#5465FF"/>
          <path d="M47.7536 4.61769C50.3024 5.50975 52.5391 7.13643 54.1515 9.26162C55.504 11.0195 56.4142 13.1447 56.7523 15.4273C56.7523 15.5322 56.7783 15.6372 56.8043 15.7421C56.8303 16.0307 56.8824 16.3193 56.8824 16.6342C56.8824 16.9228 56.9344 17.2114 56.9344 17.5C56.9344 18.2609 56.8824 19.0217 56.7523 19.7826C56.5703 20.8583 57.2985 21.8816 58.3648 22.0652C58.4688 22.0652 58.5729 22.0915 58.7029 22.0915C59.6392 22.0915 60.4714 21.4093 60.6275 20.4385C60.7835 19.4678 60.8616 18.497 60.8616 17.5C60.8616 16.503 60.8616 16.7129 60.8095 16.2931C60.8095 16.2406 60.8095 16.1882 60.8095 16.1619C60.8095 15.8471 60.7575 15.506 60.7055 15.1912C60.7055 15.0075 60.6535 14.8501 60.6275 14.6664C60.6015 14.4828 60.5755 14.2991 60.5235 14.1154C60.4714 13.9055 60.4454 13.6694 60.3934 13.4595C60.3934 13.4595 60.3934 13.4333 60.3934 13.407C59.8212 10.9933 58.7809 8.78936 57.3245 6.87407C55.2439 4.14543 52.383 2.04648 49.08 0.892057C48.872 0.813346 48.6639 0.787109 48.4298 0.787109C47.6236 0.787109 46.8434 1.28561 46.5833 2.09895C46.2192 3.12219 46.7653 4.22414 47.8056 4.59146L47.7536 4.61769Z" fill="#5465FF"/>
          <path d="M7.62349 32.1662C8.55977 32.7434 9.75613 32.4548 10.3283 31.5365C10.9005 30.6182 10.6144 29.4113 9.70411 28.8341C8.19566 27.8895 6.94728 26.6039 6.08903 25.056C5.25678 23.5867 4.78864 21.8551 4.78864 20.0185C4.78864 17.1587 5.95899 14.5612 7.83155 12.6984C9.70411 10.8356 12.3309 9.65491 15.1918 9.65491C18.0526 9.65491 18.3127 10.1009 19.7431 10.8618C20.8094 11.4128 21.8237 12.1212 22.838 12.9345C22.3179 13.4855 21.7977 14.0365 21.3556 14.5087C20.1853 15.7156 19.0929 16.6339 17.8706 17.2636C16.6742 17.8671 15.3218 18.2344 13.6313 18.3656C12.539 18.4443 11.7327 19.3888 11.8107 20.4645C11.8888 21.5402 12.825 22.3536 13.9174 22.2749C16.154 22.1174 18.0006 21.6189 19.6911 20.7531C21.3556 19.9135 22.786 18.7329 24.2425 17.2111C24.9187 16.5027 25.5429 15.8468 26.167 15.1647C29.288 11.8063 32.643 8.21188 36.3101 6.00799C37.2984 5.43077 38.3127 4.93227 39.353 4.53872C40.3673 4.1714 41.4076 3.93527 42.4999 3.85656C43.5923 3.77785 44.3985 2.83332 44.3205 1.75761C44.2425 0.734372 43.3842 -0.0527344 42.3699 -0.0527344H42.3439C42.2919 -0.0527344 42.2659 -0.0527344 42.2139 -0.0527344C40.7574 0.0522132 39.379 0.367056 38.0266 0.83932C36.7262 1.28535 35.4778 1.91503 34.2555 2.64966C31.0565 4.59119 28.2217 7.26736 25.5689 10.0222C24.3205 8.99899 22.9941 8.05446 21.6157 7.34607C19.6391 6.32283 17.5325 5.69314 15.2438 5.69314C11.2906 5.69314 7.6755 7.29359 5.10073 9.86481C2.44793 12.5147 0.835449 16.0829 0.835449 20.0447C0.835449 24.0065 1.48564 24.9248 2.656 26.9713C3.85235 29.0964 5.56887 30.8805 7.62349 32.1662Z" fill="#5465FF"/>
          </g>
          <defs>
          <clipPath id="clip0_814_12535">
          <rect width="60" height="35" fill="white" transform="translate(0.835449)"/>
          </clipPath>
          </defs>
        </svg>
        <svg width="228" height="29" viewBox="0 0 228 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M210.522 22.5447H207.33L210.152 6.45656H213.372C215.538 6.45656 217.219 6.99662 218.416 8.04833C219.584 9.10003 220.183 10.6065 220.183 12.5394C220.183 14.4722 220.126 13.7048 219.983 14.5007C219.556 17.0304 218.501 19.0201 216.849 20.4413C215.167 21.8626 213.058 22.5732 210.522 22.5732M214.825 0.714844H204.481L199.608 28.2865H209.952C212.83 28.2865 215.481 27.718 217.874 26.5526C220.268 25.3872 222.263 23.767 223.83 21.692C225.398 19.5886 226.395 17.201 226.88 14.5007C227.051 13.3353 227.165 12.3973 227.165 11.6298C227.165 9.44112 226.68 7.50826 225.683 5.88807C224.685 4.23946 223.289 2.98879 221.437 2.07921C219.556 1.16963 217.362 0.714844 214.825 0.714844ZM176.525 18.3095L181.797 8.10517L183.421 18.3095H176.525ZM184.988 28.2865H192.113L187.012 0.714844H179.232L164.356 28.2865H171.395L173.903 23.4259H184.219L185.017 28.2865H184.988ZM160.623 6.00177L161.564 0.714844H144.636L139.763 28.2865H156.691L157.631 22.9995H147.429L148.54 16.8314H157.545L158.457 11.7719H149.452L150.45 6.0302H160.652L160.623 6.00177ZM126.027 0.714844H119.302L114.429 28.2865H129.989L130.9 23.1701H122.095L126.027 0.714844Z" fill="#5465FF"/>
          <path d="M103.8 8.61681C103.458 10.5497 102.574 12.113 101.149 13.2784C99.7245 14.4438 97.8152 15.0407 95.3644 15.0407H89.4654L91.7452 2.24976H97.5587C101.805 2.24976 103.942 3.84152 103.942 6.99662C103.942 10.1517 103.885 8.10517 103.771 8.61681M97.8722 0.714844H90.2634L85.3333 28.2865H87.1002L89.2375 16.5472H95.2504L100.237 28.2865H102.375L97.2167 16.4051C99.582 16.0924 101.463 15.2397 102.916 13.8753C104.341 12.5109 105.253 10.7486 105.652 8.64524C105.766 8.1336 105.823 7.56511 105.823 6.9682C105.823 5.06377 105.167 3.55728 103.857 2.4203C102.546 1.31176 100.551 0.743268 97.8722 0.743268M76.7271 2.16449L77.0121 0.714844H63.0769L58.1468 28.2865H72.1105L72.3955 26.8368H60.1986L62.2789 15.1828H73.2504L73.5354 13.7616H62.5069L64.5587 2.19291H76.7271V2.16449ZM29.0225 0.714844L34.6365 28.2865H36.6028L52.0484 0.714844H50.0821L36.0328 26.0409L31.0458 0.714844H29.051H29.0225ZM18.8774 2.16449L19.1624 0.714844H5.22717L0.297119 28.2865H14.2608L14.5458 26.8368H2.34893L4.42924 15.1828H15.4007L15.6857 13.7616H4.65722L6.70903 2.16449H18.8774Z" fill="#5465FF"/>
        </svg>                 
    </div>

    <div class="awsLoginData" *ngIf="activationGuid != null">
        
    </div>

    
    <div class="awsLoginData" *ngIf="activationGuid == null">
      <div class="signInHeader">Sign in with your username and password</div>
        <div class="awsLoginLine">            
            <div class="awsLoginLine__value">
              <app-text-input
                label="Username" 
                [(ngModel)]="applicationInfoService.awsUserName" 
                (valueChange)="checkUserName()"
                placeholder="firstname.lastname@domain.com"
                [disabled]="pageMode === pageModes.externalUserNewPasswordRequired || pageMode === pageModes.externalUserSetupMFA || pageMode === pageModes.externalUserTotpRequired"
                class="maxWidth">
              </app-text-input>                
            </div>
        </div>

        @if (pageMode == pageModes.externalUserLogin && userChecked == true && isAGUser == false) {
          <div class="awsLoginLine">
              <app-text-input 
                label="Password" 
                [(ngModel)]="applicationInfoService.awsPassword" 
                class="maxWidth"
                type="password">
              </app-text-input>
          </div>
          <div class="awsLoginLine">
              <div class="awsLoginLine__label"></div>
              <div class="awsLoginLine__value">
                  <div class="awsLoginForgotPassword" (click)="openPasswordReset()">Forgot your password?</div>
              </div>
          </div>
        }
        
        @else if (pageMode === pageModes.externalUserNewPasswordRequired) {
          <div class="newPasswortHeader">Enter new password:</div>
          <div class="awsLoginLine">
              <app-text-input 
                label="New password" 
                [(ngModel)]="newPassword" 
                class="maxWidth"
                type="password">
              </app-text-input>              
          </div>
          <div class="awsLoginLine">
            <app-text-input 
              label="Confirm new password" 
              [(ngModel)]="newPasswordConfirm" 
              class="maxWidth"
              type="password">
            </app-text-input>            
          </div>
          <div class="newPasswortHeader">Setup multi-factor authentication:</div>
          <div class="awsLoginCheckbox">
            <label><input type="checkbox" [(ngModel)]="isMfaEnabled" [disabled]="isMfaRequired">Enable MFA</label>
          </div>
        }

        @else if (pageMode === pageModes.externalUserSetupMFA) {
          <h4>Setup MFA:</h4>
          <div class="text-center">
            <p>Scan this code with your auth app</p>
            <qrcode
              [qrdata]="'otpauth://totp/EVERLEAD:' + applicationInfoService.awsUserName + '?secret=' + setupMfaSecretCode + '&issuer=EVERLEAD'"
              [width]="256"
              [errorCorrectionLevel]="'M'"
            ></qrcode>
          </div>
          <div class="awsLoginLine">
              <div class="awsLoginLine__label">MFA Code:</div>
              <div class="awsLoginLine__value">
                <input class="maxWidth" [(ngModel)]="setupMfaAnswer" type="text" >
              </div>
          </div>
        }

        @else if (pageMode === pageModes.externalUserTotpRequired) {
          <div class="awsLoginLine">
              <div class="awsLoginLine__label">MFA Code:</div>
              <div class="awsLoginLine__value">
                <input class="maxWidth" [(ngModel)]="totpCode" type="text" >
              </div>
          </div>
        }
    </div>

    <div class="activationGuidFailed" *ngIf="activationGuidFailed == true">
      Activation failed
    </div>

    <span *ngIf="activationGuid == null">
      @if (pageMode == pageModes.internalUser) {
        <div class="awsLoginButton">
            <button class="everleadCustomButton awsLoginButton__button" (click)="nextPage()" [disabled]="applicationInfoService.awsUserName?.length < 5">
                <span *ngIf="isAGUser == true">Login</span>
                <span *ngIf="isAGUser == false">Next</span>
            </button>
        </div>
      }
      @else if (pageMode == pageModes.externalUserLogin && userChecked == true && isAGUser == false) {
        <div class="awsLoginButton">
            <button class="everleadCustomButton awsLoginButton__button" (click)="loginUser()" [disabled]="loading">Login</button>
        </div>
      }
      @else if (pageMode === pageModes.externalUserNewPasswordRequired) {
        <div class="awsLoginButton">
            <button class="everleadCustomButton awsLoginButton__button" (click)="updatePassword()" [disabled]="loading">Save</button>
        </div>
      }
      @else if (pageMode === pageModes.externalUserSetupMFA) {
        <div class="awsLoginButton">
            <button class="everleadCustomButton awsLoginButton__button" (click)="finishMfaSetup()" [disabled]="loading">Submit</button>
        </div>
      }
      @else if (pageMode === pageModes.externalUserTotpRequired) {
        <div class="awsLoginButton">
            <button class="everleadCustomButton awsLoginButton__button" (click)="sendMfaCode()" [disabled]="loading">Submit</button>
        </div>
      }
    </span>
</div>

<span id="emailcontentDesignSpan"></span>

<div class="component">
    <div class="component__header">
        <app-component-header [header]="applicationInfoService.breadCrumbText"></app-component-header>

        <div class="component__nav">
            <app-main-button
            class="buttonMargin"
            type="small"
            svg="magic-wand"
            (onClick)="createNewContent()"
            label="EMailContent.Label.New"></app-main-button>
            <app-filter (keyUp)="filterString = $event"></app-filter>
        </div>
    </div>

    <div class="component__inner">
        <div class="emailContentList">
            <div *ngFor="let emailContent of emailContents"  class="emailContentList__row" [ngClass]="{
                hideInDom: checkIfItemIsFiltered(emailContent),
                fingerCursor: userService.hasPermission('CanUpdateEmailTemplateContent')
            }" (click)="showWizard(emailContent)">
                <div class="emailContentList__content">
                    <div class="emailContentList__content__id">{{ emailContent.id }}</div>
                    <div class="emailContentList__content__name ">{{ emailContent.defaultName }}</div>
                </div>
                <!-- <div class="nonExpertView__bonus" [title]="'General._.Edit' | texttransform">
                    <i class="fas fa-edit fingerCursor dataRightsItem__buttons__button"></i>
                </div> -->
            </div>

        </div>
    </div>
</div>
<div [ngStyle]="setControlStyle('control')">
    <div class="customCheckBox__wrapper">
        <input   
            class="customCheckBox__input"
            [disabled]="!checkIfControlIsEnabled()"
            (onChange)="callMethod('onChange')"
            type="checkbox" 
            [(ngModel)]="controlValue" 
            (click)="changeValue()"
            >            
        <label class="customCheckBox__label" 
            (click)="changeValue()">
                {{ controlDefinition.displayText | texttransform  }}
        </label>            
    </div>
<!--     <app-checkbox 
    [disabled]="!checkIfControlIsEnabled()" 
    [label]="controlDefinition.displayText | texttransform" 
    [(ngModel)]="controlValue"
    (change)="changeWhatever($event)"
    ></app-checkbox> -->
</div>     
<app-layout [header]="'Reporting.Label.Header'">
    <div class="reporting">
        <div class="reporting__tabs">
            <app-tab svg="file" [active]="mode === 1" label="Reporting.Label.AvailableTemplates" (onClick)="mode = 1"></app-tab>
            <app-tab svg="file" [active]="mode === 2" label="Reporting.Label.ReportToDownload" (onClick)="mode = 2"></app-tab>
        </div>

        <section class="reporting__content">

            <app-layout header="Reporting.Label.ReportToDownload" *ngIf="mode==2" class="modeSection">
                <div class="downloadContentItem">
                    <div class="downloadContentItem__name">Name</div>        
                    <div class="downloadContentItem__date">Datum</div>
                    <div class="downloadContentItem__download"></div>
                </div>
                <div *ngFor="let downloadContentItem of downloadContent" class="downloadContentItem everleadShadowBox" [ngClass]="{
                    hideInDom: downloadContentItem.contentLibraryFileId == null
                }">
                    <div class="downloadContentItem__name ellipsisText">{{ downloadContentItem?.contentLibraryFile?.name }}</div>        
                    <div class="downloadContentItem__date">{{ downloadContentItem.finishedAt | date:'medium' }}</div>
                    <div class="downloadContentItem__download"><i class="fas fa-download fingerCursor" (click)="downloadFile(downloadContentItem)"></i></div>
                </div>
            </app-layout>

            <div *ngIf="selectedTemplate == null && mode == 1" class="reportListWrapper">
                <app-layout [header]="'Reporting.Label.AvailableTemplates' | texttransform">
                    <div class="reportListList">
                        <div class="everleadShadowBox reportBox fingerCursor" *ngFor="let template of templates" (click)="initReportTemplate(template)">
                            <div class="reportBox__icon">
                                <app-jj-icons [type]="'file-xls'" [width]="60" [height]="60"></app-jj-icons>                     
                            </div>
                            <div class="reportBox__label">
                                <div class="reportBox__label__name">
                                    {{ commonService.getTranslationValueFromArray(template, 'defaultName') | texttransform }}
                                </div>
                                <div class="reportBox__label__description" *ngIf="template.descriptionTranslationTokenId != null">
                                    {{ axivasTranslateService.getTranslationTextForId(template.descriptionTranslationTokenId) | texttransform }}
                                </div>                
                            </div>
                        </div>
                    </div>
                </app-layout>   
            </div>

            <app-layout *ngIf="selectedTemplate != null && mode == 1" 
                [header]="commonService.getTranslationValueFromArray(selectedTemplate, 'defaultName') | texttransform">
                <app-layout class="reportingDetails ">                 
                    <div class="" *ngIf="selectedTemplate?.reportParameters.length == 0">
                        {{ 'Reporting.Label.ThisReportHasNoParameters' | texttransform }}
                    </div>
                    <div class="" *ngIf="selectedTemplate?.reportParameters.length > 0">
                        <div *ngFor="let parameter of selectedTemplate?.reportParameters">
                            <div *ngIf="parameter.isUserParameter" class="reportingParameter">                       
                                <label [id]="getSubControlId(parameter, 'label')" *ngIf="parameter?.nameTranslationToken != null" [tippy]="commonService.getTooltip(parameter)" [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}">
                                    {{ parameter?.nameTranslationToken.tokenFullName | texttransform }}</label>
                                <label [id]="getSubControlId(parameter, 'label')" class="jjReportingDynamicControlLabel"
                                    *ngIf="parameter?.nameTranslationToken == null" [tippy]="commonService.getTooltip(parameter)" [tippyOptions]="{placement:'bottom', theme:'everleadBlue'}">
                                    {{ parameter?.defaultName | texttransform }}</label>
                                <input [id]="getSubControlId(parameter)" [(ngModel)]="ngValues[parameter.id]" *ngIf="parameter.parameterTypeLookup?.defaultName == 'integer' && parameter.externalDataSource == null" type="number">
                                
                                <select [id]="getSubControlId(parameter)" [(ngModel)]="ngValues[parameter.id]"
                                    *ngIf="parameter.parameterTypeLookup?.defaultName == 'integer' && parameter.externalDataSource != null"
                                    type="number" class="jjReportingDynamicControl">
                                    <option [ngValue]="item[parameter.externalDataSource.dataKey]"
                                        *ngFor="let item of paramterExternalDataSourceResults[parameter.externalDataSource.id]">
                                        {{ item[parameter.externalDataSource.dataValue] }}
                                    </option>
                                </select>
                                <!-- <app-select-input [id]="getSubControlId(parameter)" [label]="parameter.defaultName | texttransform" 
                                    *ngIf="parameter.parameterTypeLookup.defaultName == 'integer' && parameter.externalDataSource != null"
                                    class="maxWidth"
                                    [(ngModel)]="ngValues[parameter.id]" [options]="paramterExternalDataSourceResults[parameter.externalDataSource.id]" 
                                    [display]="item[parameter.externalDataSource.dataValue]" [bind]="item[parameter.externalDataSource.dataKey]"
                                ></app-select-input> -->
                                
                                <input [id]="getSubControlId(parameter)" [(ngModel)]="ngValues[parameter.id]" *ngIf="parameter.parameterTypeLookup?.defaultName == 'boolean'" type="checkbox" style="width: 10px">
                                <input [id]="getSubControlId(parameter)" [(ngModel)]="ngValues[parameter.id]" *ngIf="parameter.parameterTypeLookup?.defaultName == 'string'" type="text">
                                <span *ngIf="parameter.parameterTypeLookup?.defaultName == 'date'" class="reportingParameter__dateSpan">
                                    <input [(ngModel)]="ngValues[parameter.id]" [owlDateTimeTrigger]="dt7" [owlDateTime]="dt7" 
                                        readonly [id]="getSubControlId(parameter)" appendTo="body" aria-modal="true">
                                    <owl-date-time #dt7></owl-date-time>    
                                </span>
                                <!-- <i class="fas fa-times-circle jjReportingLeftSpacer"
                                    *ngIf="parameter.parameterTypeLookup?.defaultName != 'boolean'"
                                    (click)=" clearSelectedValue(parameter.id)"></i> -->
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="reportButtons">
                        <button class="everleadCustomButton" (click)="executeReporting()">{{ 'Reporting.Label.RequestReport' | texttransform }}</button>
                        <button class="everleadCustomButton" (click)="selectedTemplate = null">{{ 'General.Label.Back' | texttransform }}</button>
                    </div>
                </app-layout> 
            </app-layout>
     
        </section>

    </div>

</app-layout>

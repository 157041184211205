<div class="blueEtiquette" *ngIf="!field" [ngClass]="{
    blueEtiquetteDisabled: disabled
}">
    <span class="label" *ngIf="label">{{label | texttransform}}</span>
    <app-jj-icons *ngIf="svg" [type]="svg" [width]="svgW ? svgW : 14" [height]="svgH ? svgH : 14" color="primaryLight" [style.margin-top]="marginTop"></app-jj-icons>
</div>

<div *ngIf="field" class="fieldEtiquette" [class.active]="toggle">
    <span class="label" *ngIf="label">{{label | texttransform}}</span>
    <app-jj-icons *ngIf="svg" [type]="svg" [width]="svgW ? svgW : 14" [height]="svgH ? svgH : 14" color="primaryLight" [style.margin-top]="marginTop"></app-jj-icons>
</div>
import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-entitytable-additionaltoolbaritem',
  templateUrl: './entitytable-additionaltoolbaritem.component.html',
  styleUrl: './entitytable-additionaltoolbaritem.component.scss'
})

export class EntitytableAdditionaltoolbaritemComponent implements OnInit {
  @Input() label = '';
  @Input() value = false;

  constructor() {

  }

  ngOnInit(): void {
    
  }
}

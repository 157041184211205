import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { fakecontactProtectionDATA } from './fakedata';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { Subscription } from 'rxjs';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ContactProtectionService } from 'app/jollyjupiter/service/contact-protection.service';



@Component({
  selector: 'app-contact-protection',
  templateUrl: './contact-protection.component.html',
  styleUrl: './contact-protection.component.scss'
})
export class ContactProtectionComponent implements OnInit, OnDestroy{
constructor(
  private axivasTranslateService:AxivasTranslateService,
  private paginatorIntl: MatPaginatorIntl,
  public applicationInfoService: ApplicationInfoService,
  private commonService: CommonService,
  private contactProtectionService: ContactProtectionService, 
  private eventService: EventService,

 ){}
 
 displayedColumns = ['icon','contact','medium', 'frequency', 'quantity', 'comment','options']

  intervalDefinition =[];
  mediumDefinition=[];


  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  customEventSubscription: Subscription = new Subscription();

  @Input() viewMode: number= 0;


  data = fakecontactProtectionDATA;
  filteredDataArray=[]

  searchValue:string = ""
  contactData
  highlightRowId: number | string;
  hightlightName: string;
  loader:boolean= false;
  projectId=0;
  accountContactsArray:any[] = [];
  rowcontactId:number = 0;
  editor = false;
  apiError:boolean = false;
  listData:boolean = false;



  ngOnInit(){

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => {
     if(event == 'getCompleteProjectInformaton'){
      this.getApiData()
  
     }

     if(event.id == 'pageContentChanged'){
        this.getApiData()

     }

     if(event.id == 'JJ-ContactProtectionPOPUP-closed'){
      this.getApiData()

     }

    })

    this.getApiData()

    //console.log('this:view', this.viewMode)
  }

  ngAfterViewInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
}

  getApiData(){
    this.mediumDefinition = this.contactProtectionService.getUnfilteredMediumDefinition();
    this.intervalDefinition = this.contactProtectionService.getIntervalDefinition();
    //this.projectId = this.applicationInfoService.currentAccount.projectId 
    this.loader= true
    this.listData= false;

    if(this.viewMode == 1){
      this.projectId = this.applicationInfoService.projectID;
      this.displayedColumns =  ['medium', 'frequency', 'quantity','options'];
      //console.log('projectId', this.projectId);
      this.contactProtectionService.getProjectContactProtection(this.projectId).then(projectProtection => {
        //console.log('projectProtection result', projectProtection)
        this.setProjectData(projectProtection);
      }).catch(error => {
        console.log(error);
        this.apiError = true;
        this.loader = false;
        this.listData = false;
      })
    }
    
    if(this.viewMode == 0){
      this.projectId = this.applicationInfoService.currentAccount.projectId 
      this.contactProtectionService.getContactProtections(this.projectId).then(contactProtection =>{
        this.setData(contactProtection);
      }).catch(error => {
        //console.log(error)
        this.apiError = true;
        this.loader = false;
        this.listData = false;
      })
  }
  
}

setProjectData(array){
  //console.log('result',array)
  this.loader = false

  if(array.length === 0){
    this.listData = false 
  } else {
    this.listData = true
  }


  this.dataSource = new MatTableDataSource(array);
  this.data = array;
}


  setData(data){
    if(data.length === 0){
      this.listData = false
    } else {
      this.listData = true
    }

    this.loader = false

    const array = data.map(item => {
      const medium = this.mediumDefinition.find(medium => medium.id == item.mediumTypeId);
      const frequency = this.intervalDefinition.find(freq => freq.id == item.frequencyLookupId);
      
      return {
        ...item,
        mediumForTable: this.axivasTranslateService.getTranslationTextForToken(medium.nameTranslationToken.tokenFullName) || medium?.defaultName,
        freqForTable: this.axivasTranslateService.getTranslationTextForToken(frequency.nameTranslationToken.tokenFullName) || frequency?.defaultName,
      }
    })
 
    this.dataSource = new MatTableDataSource(array);
    this.filteredDataArray = array
    this.data = array;
    this.setTable();
  }

  setTable(){
    this.paginatorIntl.itemsPerPageLabel = this.axivasTranslateService.getTranslationTextForToken('General.Label.Paginator')
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }




  filterContactList($event, searchValue){
    if(searchValue){
      const filtered = this.filteredDataArray.filter((item) => 
        this.commonService.checkIfStringContainsString(item.firstName, searchValue) ||
      this.commonService.checkIfStringContainsString(item.lastName, searchValue))
      this.dataSource.data = filtered
    } else {
      this.dataSource.data = this.filteredDataArray
    }

  }

  sortOnChange($event) {
    if (this.dataSource && this.dataSource.sort) {
      this.dataSource.sortData(this.dataSource.data, this.dataSource.sort);
    }
  }

  highlightRow(row){
    this.highlightRowId = row.id
  }

  deselectRow(){
    this.highlightRowId=null;
  }

  addContactProtection(){
    this.applicationInfoService.miscSettings["ContactProtectionMode"] = this.viewMode;
    this.applicationInfoService.miscSettings["ContactProtectionType"] = 'add';
    this.eventService.showJjPopup(this.axivasTranslateService.getTranslationTextForToken('ContactProtection.Label.AddContact'),
      "contactprotectionpopup",
      "800px; 550px"
      );
  }

  editContact(element){
    const { contact, account, mediumForTable, freqForTable , ...rest } = element
    this.applicationInfoService.miscSettings["ContactProtectionMode"] = this.viewMode;
    this.applicationInfoService.miscSettings["ContactProtectionType"] = 'update';
    this.applicationInfoService.miscSettings["ContactProtectionData"] = rest;
    this.eventService.showJjPopup(this.axivasTranslateService.getTranslationTextForToken('ContactProtection.Label.UpdateContact'),
    "contactprotectionpopup",
    "800px; 550px"
    );
  
  }

  deleteContact(element){
    const { mediumForTable, freqForTable , ...rest } = element
    this.applicationInfoService.miscSettings["ContactProtectionMode"] = this.viewMode;
    this.applicationInfoService.miscSettings["ContactProtectionType"] = 'delete';
    this.applicationInfoService.miscSettings["ContactProtectionData"] = rest;
    this.eventService.showJjPopup(this.axivasTranslateService.getTranslationTextForToken('ContactProtection.Label.DeleteContact'),
      "contactprotectionpopup",
      "600px; 250px"
      );
  }


  createProjectProtection(element){
    //console.log('this view mode', this.viewMode)
    this.applicationInfoService.miscSettings["ContactProtectionMode"] = this.viewMode;
    this.applicationInfoService.miscSettings["ContactProtectionType"] = 'add';
    this.eventService.showJjPopup(this.axivasTranslateService.getTranslationTextForToken('ContactProtection.Label.AddProjectEntry'),
      "contactprotectionpopup",
      "500px; 350px"
      );
  }

  editProjectProtection(element){
    this.applicationInfoService.miscSettings["ContactProtectionMode"] = this.viewMode;
    this.applicationInfoService.miscSettings["ContactProtectionType"] = 'update';
    this.applicationInfoService.miscSettings["ContactProtectionData"] = element;
    this.eventService.showJjPopup(this.axivasTranslateService.getTranslationTextForToken('ContactProtection.Label.UpdateProjectEntry'),
    "contactprotectionpopup",
    "500px; 350px"
    );

  }

  deleteProjectProtection(element){
    this.applicationInfoService.miscSettings["ContactProtectionMode"] = this.viewMode;
    this.applicationInfoService.miscSettings["ContactProtectionType"] = 'delete';
    this.applicationInfoService.miscSettings["ContactProtectionData"] = element;
    this.eventService.showJjPopup(this.axivasTranslateService.getTranslationTextForToken('ContactProtection.Label.DeleteProjectEntry'),
    "contactprotectionpopup",
    "600px; 250px");
  }


  ngOnDestroy(){
    if(this.customEventSubscription){
      this.customEventSubscription.unsubscribe()
    }
  }

}

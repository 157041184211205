import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { WizardMode, WizardService, WizardType } from '../../wizard/wizard.service';
import { UserService } from 'app/core/authentication/services/user.service';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit, OnDestroy {
  wizardEventSubscription: Subscription = new Subscription();

  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['defaultName', 'isActive', 'campaignStart', 'campaignEnd', 'nettoCallLimit', 'ses', 'followUps', 'campaignId', 'campaignName'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  showOnlyActive = true;
  configSets = [];
  filterString = '';
  campaigns = [];
  loadingData = false;

  constructor(
    private externaldatasourceService: ExternaldatasourceService,
    private loaderService: LoaderService,
    public applicationInfoService: ApplicationInfoService,
    private axivasTranslateService: AxivasTranslateService,
    private commonService: CommonService,
    private wizardService: WizardService,
    private uiService: UiService,
    public userService: UserService
  ) { }

  ngOnDestroy() {
    if (this.wizardEventSubscription) { this.wizardEventSubscription.unsubscribe(); }
  }

  ngOnInit() {
    this.getCampaigns();
    this.getConfigSets();

    if (!this.userService.hasPermission('CanUseNonSelfServiceNaviagionItem')) {
      this.displayedColumns = ['defaultName', 'isActive', 'campaignStart', 'campaignEnd', 'nettoCallLimit', 'followUps', 'campaignId', 'campaignName'];
    }

    this.wizardEventSubscription = this.wizardService.wizardFinishedEvent.subscribe(event => {
      if (event.wizard == WizardMode.Campaign) {
        if (event.type == WizardType.Create) {
          this.getCampaigns();
        } else if (event.type == WizardType.Update) {
          this.getCampaigns();
        }
      }
    })
  }

  getCampaigns() {
    this.loadingData = true;
    this.externaldatasourceService.executeExternalDataSource(940)
    .then(getCampaignsResult => {
      this.campaigns = getCampaignsResult;
      this.dataSource = new MatTableDataSource(getCampaignsResult);
      this.loadingData = false;
    })
    .catch(error => {
      this.loadingData = false;
    });
  }

  getConfigSets() {
    this.externaldatasourceService.executeExternalDataSource(482, []).then(result => this.configSets = result );
  }

  createNewCampaign() {
    this.externaldatasourceService.executeExternalDataSource(136, [
      this.commonService.getModifyArrayBody({
        isActive: true,
        netcallLimit:  null,
        projectId: this.applicationInfoService.projectID,
        defaultName: this.axivasTranslateService.getTranslationTextForToken('CampaignDesign.Label.NewCampaign')
      }, [])
    ])
    .then(() => {
      this.getCampaigns();
    });
  }

  saveCampaign(campaign) {
    this.externaldatasourceService.executeExternalDataSource(137, [ this.commonService.getModifyArrayBody(campaign, [])])
    .then(() => { })
    .catch(error => { console.error('saveCampaigns', error); });
  }

  showWizard(array) {
    this.wizardService.showWizard(WizardMode.Campaign, array);
  }

  createNewWizardCampaign() {
    this.wizardService.showWizard(WizardMode.Campaign, { defaultName: 'Campaign', projectId: this.applicationInfoService.projectID });
  }

  getTableHeight() {
    let tableSize = Number(this.uiService.getDesignSpanPosition('projectSettingsDesignSpan', 40));
    return tableSize + 'px';
  }

  itemIsFiltered(row) {
    let returnValue = true;
    if (this.commonService.checkIfStringContainsString(row.defaultName, this.filterString)) {
     returnValue = false
    }
    return returnValue;
  }
}

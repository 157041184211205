import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { ContactProtectionService } from 'app/jollyjupiter/service/contact-protection.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-protection-popup',
  templateUrl: './contact-protection-popup.component.html',
  styleUrl: './contact-protection-popup.component.scss'
})
export class ContactProtectionPopupComponent implements OnInit{

   newContact = {
    contactId:null,
    mediumTypeId:null,
    frequencyLookupId:null,
    quantity:0,
    accountId:null,
    comment:'',
   }

   mediumDefinition: any[] = [];
   intervalDefinition: any[] = [];
   accountContactsArray: any[] = [];
   projectId = 261;
   type:string="";
   deleteContact;
   
   project={
    mediumTypeId: null,
    frequencyTypeId:null,
    quantity: 0,
    id: null,
    projectId:null,
    //isGlobal:true
   };

   viewMode:number=0;
   selectedProjects;
   requiredField :boolean =  false;

   
  constructor(
    private axivasTranslateService: AxivasTranslateService,
    private contactProtectionService: ContactProtectionService,
    private applicationInfoService: ApplicationInfoService,
    public eventService:  EventService,
  ){

  }


  ngOnInit(): void {
  this.getSelectValuesLookupTable()
  this.getAccountContacts();
  this.getTypeAndData();

  }


  getProjectSelectValues(){
    this.mediumDefinition = this.contactProtectionService.getUnfilteredMediumDefinition().map((item)=>{
      return{
        ...item,
        displayName: item.nameTranslationToken.tokenFullName ? item.nameTranslationToken.tokenFullName : item.defaultName
      }
    });
    this.intervalDefinition =  this.contactProtectionService.getIntervalDefinition().map((item) =>{
      return{
        ...item,
        displayName: item.nameTranslationToken.tokenFullName ? item.nameTranslationToken.tokenFullName : item.defaultName
      }
    })
    console.log('interval', this.intervalDefinition);
    console.log('this.medium Definition', this.mediumDefinition);
  }

  getSelectValuesLookupTable(){
    this.mediumDefinition = this.contactProtectionService.getUnfilteredMediumDefinition().map((item)=>{
      return{
        ...item,
        displayName: item.nameTranslationToken.tokenFullName ? item.nameTranslationToken.tokenFullName : item.defaultName
      }
    });

    this.intervalDefinition = this.contactProtectionService.getIntervalDefinition().map((item) =>{
      return{
        ...item,
        displayName: item.nameTranslationToken.tokenFullName ? item.nameTranslationToken.tokenFullName : item.defaultName
      }
    })
    console.log('interval', this.intervalDefinition);
    console.log('this.medium Definition', this.mediumDefinition);
  }

  getTypeAndData(){

    this.viewMode =  this.applicationInfoService.miscSettings["ContactProtectionMode"]
    this.type =  this.applicationInfoService.miscSettings["ContactProtectionType"]


    if(this.viewMode == 0){
      this.getSelectValuesLookupTable()
      this.getAccountContacts();
      this.newContact.accountId = this.applicationInfoService.currentAccount.id;
      this.projectId = this.applicationInfoService.currentAccount.projectId;

      if(this.type == 'update'){
        this.newContact =  this.applicationInfoService.miscSettings["ContactProtectionData"]
      }
  
      if(this.type == 'delete'){
        this.deleteContact = this.applicationInfoService.miscSettings["ContactProtectionData"]
        //console.log('deleteContact', this.deleteContact)
      }
    }

    if(this.viewMode == 1){
      this.projectId = this.applicationInfoService.projectID;
      this.project.projectId = this.projectId
      //this.selectedProjects = this.getFilteredProjects(this.applicationInfoService.user.userProjects);    
      this.getProjectSelectValues();

      if(this.type == 'update'){
        const object =  this.applicationInfoService.miscSettings["ContactProtectionData"]
        this.project.frequencyTypeId = object.frequencyType.id
        this.project.mediumTypeId = object.mediumType.id
        this.project.quantity = object.quantity
        this.project.id = object.id
        this.project.projectId = object.projectId
        } 

        if(this.type == 'delete'){
          this.project = this.applicationInfoService.miscSettings["ContactProtectionData"]
        }
      }
  

    }



  getAccountContacts(){
    this.contactProtectionService.getAccountContacts(this.projectId).then(accountContactsResult => {
       this.setAccountSelectInputValue(accountContactsResult)
    }).catch(error => {
      console.log('get Accounts error', error)
    })
  }

  setAccountSelectInputValue(data){
    console.log('data', data)
    this.accountContactsArray = data
  }


  createNewContact(){

    this.contactProtectionService.createNewContactProtection( this.projectId , this.newContact).then(result =>{
      this.eventService.closeJjPopup()
      this.eventService.customEvent.emit({id: 'JJ-ContactProtectionPOPUP-closed'})
    }).catch(error => {
      console.log('create New Contact Entry',error)
    })
  }

  updateContact(){
     this.contactProtectionService.updateContactProtection(this.newContact).then(contactResult =>{
      this.eventService.closeJjPopup();
      this.eventService.customEvent.emit({id: 'JJ-ContactProtectionPOPUP-closed'}) 
     }).catch(error => {
       console.log('Update Contact Entry',error);
     
     })
   
   }
 
   deleteConfirm(){
     this.contactProtectionService.deleteContactProtection(this.deleteContact.id).then(result => {
      this.eventService.closeJjPopup();
      this.eventService.customEvent.emit({id: 'JJ-ContactProtectionPOPUP-closed'})
     }).catch(error => {
       console.log('Delete contact entry',error)
     })
   }

  getAccountSelectInputValue(data){
    this.accountContactsArray = data
  }




  createNewProjectProtection(){
    const { id, ...rest} = this.project
      this.contactProtectionService.createProjectProtection(this.projectId, rest).then(result => {
        //console.log(result)
        this.eventService.customEvent.emit({id: 'JJ-ContactProtectionPOPUP-closed'})
        this.eventService.closeJjPopup()
      }).catch(error => {
        console.log('create Project Protection', error)
      })
  }


  updateProjectProtection(){
    this.contactProtectionService.updateProjectProtection(this.project).then(result => {
      this.eventService.customEvent.emit({id: 'JJ-ContactProtectionPOPUP-closed'})
      this.eventService.closeJjPopup()
    }).catch(error => {
      console.log('update project protection', error)
    })

  }

  deleteProjectProtection(){
    console.log('delete Project',this.project)
    this.contactProtectionService.deleteProjectProtection(this.project.id).then(result => {
      this.eventService.customEvent.emit({id:'JJ-ContactProtectionPOPUP-closed'})
      this.eventService.closeJjPopup()
    }).catch(error => {
      console.log('delete project protection', error)
    })
  }


}

<div *ngIf="mode == 1" class="externalUsersWrapper">
    <div class="externalUserHeader">
        {{ 'ExternalUser.Label.Description' | texttransform }}
    </div>
    <div>
        <app-text-input label="ExternalUser.Label.FirstName" [(ngModel)]="newUser.firstName"></app-text-input>
        <app-text-input label="ExternalUser.Label.LastName" [(ngModel)]="newUser.lastName"></app-text-input>
        <app-text-input label="ExternalUser.Label.EMail" [(ngModel)]="newUser.email"></app-text-input>
        <app-select-input label="General._.Language" [(ngModel)]="newUser.languageId"
            [options]="languages" display="defaultName" bind="id"></app-select-input>
        <app-select-input [label]="'General.User.UserRole' | texttransform" [(ngModel)]="newUser.roleId" class="maxWidth"
            [options]="roleData" display="defaultName" bind="id"></app-select-input>
    </div>

    <div class="externalUserRow__buttons">
        <app-main-button type="small" svg="plus" (onClick)="createExternalUser()" label="ExternalUser.Label.CreateExternalUser"></app-main-button>
    </div>
</div>

<div *ngIf="mode == 2">
    <div *ngIf="userAlreadyExists" class="everleadShadowBox">
        {{ 'ExternalUser.Label.UserAlreadyExists' | texttransform }}
    </div>
    <div *ngIf="!userAlreadyExists" class="everleadShadowBox">
        <div>{{ 'ExternalUser.Label.UserCreated' | texttransform }}</div>
      <!--   <div>{{ 'ExternalUser.Label.UserCreatedDescriptionVersionTwo' | texttransform }}</div> -->
        <div>{{ 'ExternalUser.Label.UserCreatedLinktoEmail' | texttransform }}</div>
        <br>
        <div><b>{{ newUser.givenName }} {{ newUser.familyName }}</b></div>
        <div *ngIf="resultUser.email">{{ resultUser.email }}</div>
        <div *ngIf="resultUser.password">{{ resultUser.password }}</div>
    </div>
    <!-- <div class="externalUserAdditionalInfo">{{ 'ExternalUser.Label.CreateAnotherUser' | texttransform }}</div> -->
    <app-main-button type="small" svg="plus" (onClick)="createAnotherUser()" label="ExternalUser.Label.CreateAnotherUser"></app-main-button>
    <!-- <button class="jjButtonColor jjButtonStyle fingerCursor" (click)="createAnotherUser()">
        {{ 'ExternalUser.Label.CreateAnotherUser' | texttransform }}</button> -->
</div>
